import { useSelector } from 'react-redux'
import { getFeatureFlags, State } from 'src/redux/store/features'

export type HierarchyEnabledFlags = State

const useHierarchyEnabledFlags = () => {
  return useSelector(getFeatureFlags)
}

export default useHierarchyEnabledFlags
