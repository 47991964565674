
const awsmobile = {
  aws_project_region: "__AWS_REGION__",
  aws_cloud_logic: "enable",
  aws_cloud_logic_custom: [
    {
      id: "__AWS_VISITOR_LOG_OPERATIONS_API_ID__",
      name: "visitorLogOperations",
      description: "",
      endpoint: "https://__AWS_VISITOR_LOG_OPERATIONS_API_ID__.execute-api.__AWS_REGION__.amazonaws.com/LATEST",
      region: "__AWS_REGION__",
      paths: ["/signOutVisitors", "/signOutVisitors/123", "/deleteLogEntries", "/deleteLogEntries/123", "/captureVisitorBadgeNumber", "/captureVisitorBadgeNumber/123", "/revertSignOut", "revertSignOut/123", "/updateAssetSerialNumber", "/updateAssetSerialNumber/123"],
    },
    {
      id: "__AWS_PROFILE_OPERATIONS_API_ID__",
      name: "profileOperations",
      description: "",
      endpoint: "https://__AWS_PROFILE_OPERATIONS_API_ID__.execute-api.__AWS_REGION__.amazonaws.com/LATEST",
      region: "__AWS_REGION__",
      paths: ["/generateVisitorAgreement", "/generateVisitorAgreement/123", "/searchExistingProfile", "/searchExistingProfile/123"],
    },
    {
      id: "__AWS_WORKER_LOG_OPERATIONS_API_ID__",
      name: "workerLogOperations",
      description: "",
      endpoint: "https://__AWS_WORKER_LOG_OPERATIONS_API_ID__.execute-api.__AWS_REGION__.amazonaws.com/LATEST",
      region: "__AWS_REGION__",
      paths: ["/captureWorkerClockEntry", "/captureWorkerClockEntry/123"],
    },
    {
      id: "__AWS_WORKER_BIOMETRIC_OPERATIONS_API_ID__",
      name: "workerBiometricOperations",
      description: "",
      endpoint: "https://__AWS_WORKER_BIOMETRIC_OPERATIONS_API_ID__.execute-api.__AWS_REGION__.amazonaws.com/LATEST",
      region: "__AWS_REGION__",
      paths: ["/acceptLogImage", "/acceptLogImage/123", "/rejectLogImage", "/rejectLogImage/123", "/removeReferenceImage", "/removeReferenceImage/123"],
    },
    {
      id: "__AWS_USER_OPERATIONS_API_ID__",
      name: "userOperations",
      description: "",
      endpoint: "https://__AWS_USER_OPERATIONS_API_ID__.execute-api.__AWS_REGION__.amazonaws.com/LATEST",
      region: "__AWS_REGION__",
      paths: ["/retrieveSubscribeCredential", "/retrieveSubscribeCredential/123", "/retrieveEntityHierarchyAccessCredential", "/retrieveEntityHierarchyAccessCredential/123"],
    },
    {
      id: "__AWS_HIERARCHY_OPERATIONS_API_ID__",
      name: "hierarchyOperations",
      description: "",
      endpoint: "https://__AWS_HIERARCHY_OPERATIONS_API_ID__.execute-api.__AWS_REGION__.amazonaws.com/LATEST",
      region: "__AWS_REGION__",
      paths: ["/retrieveSubscribeCredential", "/retrieveSubscribeCredential/123", "/retrieveEntityHierarchyAccessCredential", "/retrieveEntityHierarchyAccessCredential/123", "/manageAPIKeys", "/manageAPIKeys/123"],
    },
    {
      id: "__AWS_INTEGRATION_OPERATIONS_API_ID__",
      name: "integrationOperations",
      description: "",
      endpoint: "https://__AWS_INTEGRATION_OPERATIONS_API_ID__.kenai.co.za/integration",
      region: "__AWS_REGION__",
      paths: ["/retrieveEventSubscribeCredential", "/retrieveEventSubscribeCredential/123", "/hydrateVisitorEvents", "/hydrateVisitorEvents/123"],
    },
    {
      id: "__AWS_DIRECTORY_OPERATIONS_API_ID__",
      name: "directoryOperations",
      description: "",
      endpoint: "https://__AWS_DIRECTORY_OPERATIONS_API_ID__.execute-api.__AWS_REGION__.amazonaws.com/LATEST",
      region: "__AWS_REGION__",
      paths: ["/updateHostDirectory", "/updateHostDirectory/123"],
    },
    {
      id: "__AWS_INVITE_OPERATIONS_API_ID__",
      name: "inviteOperations",
      description: "",
      endpoint: "https://__AWS_INVITE_OPERATIONS_API_ID__.execute-api.__AWS_REGION__.amazonaws.com/LATEST",
      region: "__AWS_REGION__",
      paths: ["/retrieveInviteLog", "/retrieveInviteLog/123"],
    },
    {
      id: "__AWS_SEARCH_OPERATIONS_API_ID__",
      name: "searchOperations",
      description: "",
      endpoint: "https://__AWS_SEARCH_OPERATIONS_API_ID__.execute-api.__AWS_REGION__.amazonaws.com/LATEST",
      region: "__AWS_REGION__",
      paths: ["/searchHosts","/searchHosts/search"],
    },
    {
      id: "__AWS_PRESCREENING_OPERATIONS_API_ID__",
      name: 'preScreeningOperations',
      description: '',
      endpoint: "https://__AWS_PRESCREENING_OPERATIONS_API_ID__.execute-api.__AWS_REGION__.amazonaws.com/LATEST",
      region: "__AWS_REGION__",
      paths: ['/preScreeningAppProcessor'],
    },
    {
      id: "__AWS_PROVISIONING_OPERATIONS_API_ID__",
      name: 'provisioningOperations',
      description: '',
      endpoint: "https://__AWS_PROVISIONING_OPERATIONS_API_ID__.execute-api.__AWS_REGION__.amazonaws.com/LATEST",
      region: "__AWS_REGION__",
      paths: ['/logFeatureRequest'],
    },
    {
      id: "__AWS_VERIFICATION_OPERATIONS_API_ID__",
      name: 'verificationOperations',
      description: '',
      endpoint: "https://__AWS_VERIFICATION_OPERATIONS_API_ID__.execute-api.__AWS_REGION__.amazonaws.com/LATEST",
      region: "__AWS_REGION__",
      paths: ['/requestOTPCode', '/verifyOTPCode'],
    },
    {
      id: "__AWS_PARKING_OPERATIONS_API_ID__",
      name: 'parkingOperations',
      description: '',
      endpoint: "https://__AWS_PARKING_OPERATIONS_API_ID__.execute-api.__AWS_REGION__.amazonaws.com/LATEST",
      region: "__AWS_REGION__",
      paths: ['/parkingBookingLogRetrieval', '/processParkingBooking'],
    },
    {
      id: "__AWS_EVAC_OPERATIONS_API_ID__",
      name: 'evacOperations',
      description: '',
      endpoint: "https://__AWS_EVAC_OPERATIONS_API_ID__.execute-api.__AWS_REGION__.amazonaws.com/LATEST",
      region: "__AWS_REGION__",
      paths: ['/updateEvacStatus'],
    }
  ],
  aws_cognito_region: "__AWS_REGION__",
  aws_dynamodb: "enable",
  aws_dynamodb_all_tables_region: "__AWS_REGION__",
  aws_dynamodb_table_schemas: [
    { tableName: "__AWS_DASH_ENVIRONMENT__mdConfigFeatures" },
    { tableName: "__AWS_DASH_ENVIRONMENT__mdConfigImages" },
    { tableName: "__AWS_DASH_ENVIRONMENT__mdConfigBrandingColors" },
    { tableName: "__AWS_DASH_ENVIRONMENT__mdConfigTexts" },
    { tableName: "__AWS_DASH_ENVIRONMENT__mdConfigInduction" },
    { tableName: "__AWS_DASH_ENVIRONMENT__mdConfigI18NTexts" },
    { tableName: "__AWS_DASH_ENVIRONMENT__mdConfigCustomFields" },
    { tableName: "__AWS_DASH_ENVIRONMENT__mdConfigCustomNotifications" },
    { tableName: "__AWS_DASH_ENVIRONMENT__mdConfigFlowTypes" },
    { tableName: "__AWS_DASH_ENVIRONMENT__mdSearchSourceHostLinking" },
    { tableName: "__AWS_DASH_ENVIRONMENT__mdWorkerOrgProfiles" },
    { tableName: "__AWS_DASH_ENVIRONMENT__mdVerifiedOrgProfiles" },
    { tableName: "__AWS_DASH_ENVIRONMENT__tdActiveEvacuations" },
    { tableName: "__AWS_DASH_ENVIRONMENT__tdWorkerEvents" },
    { tableName: "__AWS_DASH_ENVIRONMENT__tdEventInvitationInstances" },
    { tableName: "__AWS_DASH_ENVIRONMENT__tdOrganizationUserLog" },
    { tableName: "__AWS_DASH_ENVIRONMENT__tdParkingEvents" },
    { tableName: "__AWS_DASH_ENVIRONMENT__tdParkingBookings" },
    { tableName: "__AWS_DASH_ENVIRONMENT__tdDeviceConnectionState" },
    { tableName: "__AWS_DASH_ENVIRONMENT__tdCompletedInductions" },
    { tableName: "__AWS_DASH_ENVIRONMENT__mdConfigDAF" },
  ],
  aws_user_files: "enable",
  aws_user_files_s3_bucket_region: "__AWS_REGION__",
  aws_user_files_s3_bucket: "__AWS_DASH_ENVIRONMENT__userfiles",
  aws_worker_files_s3_bucket_region: "__AWS_REGION__",
  aws_worker_files_s3_bucket: "__AWS_DASH_ENVIRONMENT__workerfiles",
  aws_parking_files_s3_bucket_region: "__AWS_REGION__",
  aws_parking_files_s3_bucket: "__AWS_DASH_ENVIRONMENT__parkingfiles",
  aws_entity_binary_content_s3_bucket_region: "__AWS_REGION__",
  aws_entity_binary_content_s3_bucket: "__AWS_DASH_ENVIRONMENT__entitybinarycontent",
  aws_iot_pubsub_endpoint: "__AWS_IOT_PUBSUB_ENDPOINT__"
}

export default awsmobile
