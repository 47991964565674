import { Switch } from 'antd'
import React from 'react'
import { cssvars } from 'src/styles'
import styled from 'styled-components'
import { useTheme } from './ThemeProvider'

const Wrapper = styled.div`
  .theme-switch {
    &.center {
      margin: auto;
    }
    .ant-switch-inner {
      margin-top: 2px;
    }
    &.ant-switch-checked {
      background: ${cssvars.disabledColor};
    }
  }
`

const ThemeSwitch = (props) => {
  const { switchTheme, mode } = useTheme()

  return (
    <Wrapper className={'flex justify-between items-center py-1 px-2 '}>
      {!props.collapsed && (
        <div className='theme-text text-vars-textColor' onClick={switchTheme}>
          {mode === 'dark' ? 'Light Mode' : 'Dark Mode'}
        </div>
      )}
      <Switch
        className={`theme-switch ${props.collapsed ? 'center' : ''}`}
        checked={mode === 'light'}
        onChange={switchTheme}
        aria-label={mode === 'dark' ? 'Switch to light mode' : 'Switch to dark mode'}
      />
    </Wrapper>
  )
}

export default ThemeSwitch
