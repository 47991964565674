import { Skeleton } from 'antd'
import { SkeletonParagraphProps } from 'antd/lib/skeleton/Paragraph'
import React from 'react'
import withDefaults from 'src/utils/with-defaults'
import Fieldset from '.'

interface Props {
  loading?: boolean
  className?: string
  title?: boolean
  extra?: boolean
  subtitle?: boolean
  content?: boolean | number
  paragraph?: SkeletonParagraphProps
}

const defaultProps = {
  className: '',
  paragraph: {
    rows: 4,
  } as SkeletonParagraphProps,
}

export type FieldsetLoaderProps = Props & Omit<typeof defaultProps, keyof Props>

const FieldsetContent: React.FC<React.PropsWithChildren<FieldsetLoaderProps>> = ({
  className,
  loading,
  children,
  title,
  extra,
  subtitle,
  paragraph,
  content = true,
}) => {
  if (!loading) return <div className={className}>{children}</div>
  return (
    <Fieldset className={className}>
      {(title || extra || subtitle) && (
        <Fieldset.Content className={content ? 'pb-4' : ''}>
          {(title || extra) && loading && (
            <div className={`flex items-center justify-between ${subtitle ? 'pb-2' : ''}`}>
              <div className='flex-grow w-full'>{title && <Skeleton.Input active className='max-w-lg' />}</div>
              {extra && (
                <div className='pl-2'>
                  <Skeleton.Button active />
                </div>
              )}
            </div>
          )}
          {subtitle && <Skeleton.Input active className='h-4 max-w-3xl' size='small' />}
        </Fieldset.Content>
      )}
      {content &&
        (() => {
          if (typeof content === 'number') {
            const contentItems: JSX.Element[] = []

            for (let index = 0; index < content; index++) {
              contentItems.push(
                <Fieldset.Content>
                  <Skeleton active loading title={false} paragraph={paragraph} round />
                </Fieldset.Content>
              )
            }

            return contentItems
          }
          return (
            <Fieldset.Content>
              <Skeleton active loading title={false} paragraph={paragraph} round />
            </Fieldset.Content>
          )
        })()}
    </Fieldset>
  )
}

export default withDefaults(FieldsetContent, defaultProps)
