import { darken, invert, lighten, transparentize } from 'polished'
import { getContrast } from 'src/utils/getContrast'
import logo from 'src/visual/image/kenai-logo.svg'
import logoSmall from 'src/visual/image/icon-kenai-bot.svg'
import { palette } from '..'
import config from 'src/config'

const textColor = '#242225'
const itemActiveBackground = (primaryColor) => transparentize(0.8, primaryColor)
const disabledColor = lighten(0.6, textColor)
const componentBackground = '#ffffff'
const borderColor = '#eaeaea'

const lightTheme = (primaryColor = config.primaryColor) => ({
  primaryColor,
  primaryHover: darken(0.1, primaryColor),
  primaryColorA04: transparentize(0.4, primaryColor),
  primaryColorA07: transparentize(0.7, primaryColor),
  primaryContrast: lighten(0.3, primaryColor),
  secondaryColor: '#000',
  secondaryHover: '#fff',
  bodyBackground: '#fafafa',
  tabsGray: '#ACB6BF',
  componentBackground,
  contrastBackground: '#fafafa',
  textColor,
  textColorSecondary: darken(0.4, textColor),
  textColorInvert: invert(textColor),
  textColorHover: lighten(0.7, textColor),
  textColorA015: transparentize(0.15, textColor),
  textColorA075: transparentize(0.75, textColor),
  borderColor,
  borderColorA06: transparentize(0.6, borderColor),
  disabledColor,
  disabledColorA04: transparentize(0.4, disabledColor),
  disabledColorA06: transparentize(0.6, disabledColor),
  itemActiveBackground: itemActiveBackground(primaryColor),
  itemHoverBackground: transparentize(0.9, primaryColor),
  itemActiveColor: getContrast(itemActiveBackground(primaryColor), textColor) > 7 ? textColor : invert(textColor),
  logo: `url(${logo})`,
  logoSmall: `url(${logoSmall})`,
  errorColor: palette.error[0],
  error100: palette.error[1],
  errorA08: transparentize(0.8, palette.error[2]),
  errorBackground: transparentize(0.95, palette.error[0]),
  errorBorder: transparentize(0.5, palette.error[0]),
  warningText: palette.warning[0],
  warningBackground: transparentize(0.95, palette.warning[0]),
  warningBorder: transparentize(0.5, palette.warning[0]),
  pickerDropdown: componentBackground,
  crispBlack: '#000',
  basicBlack: '#1D1D1D',
})

export default lightTheme
