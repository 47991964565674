import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import configureWindow from 'src/utils/configureWindow'
import configureSentry from 'src/utils/configureSentry'
import polyfillLocales from 'src/i18n/pollyfill'
import { PublicClientApplication } from '@azure/msal-browser'

import 'src/styles/main.css'
import 'src/styles/global/index.scss'
import getMsalConfig from './utils/getMsalConfig'

polyfillLocales()
configureWindow()
configureSentry()

const msalConfig = getMsalConfig()
const msalInstance = new PublicClientApplication(msalConfig)

ReactDOM.render(<App msalInstance={msalInstance} />, document.getElementById('root'))
// ReactDOM.render(<App />, document.getElementById('root'))
