import { Button, Form, Modal, Space, Typography, notification } from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import React, { ReactNode, useState } from 'react'
import { useDispatch } from 'react-redux'
import { EvacUpdateStatus, EvacUpdateUserData } from 'src/api/evacOperations'
import { ActionCreators } from 'src/redux/sagas/evacuation/evacuation'

interface EvacuationUpdateProps {
  children: ReactNode
  userData: EvacUpdateUserData[] | EvacUpdateUserData
  content?: ReactNode
}

export const EvacuationUpdate = ({ userData, content, children }: EvacuationUpdateProps) => {
  const [form] = Form.useForm()
  const [loading, setLoading] = useState<'SAFE' | 'NOT_SAFE' | false>(false)
  const dispatch = useDispatch()
  const [open, setOpen] = useState(false)

  const arrayUserData = Array.isArray(userData) ? userData : [userData]

  const updateEvacuationStatus = (status: EvacUpdateStatus) => {
    setLoading(status)
    dispatch(
      ActionCreators.SagaUpdateEvacuationStatus.withCallback({
        payload: {
          userData: arrayUserData,
          status: status,
          comment: form.getFieldValue('comment'),
        },
        callback: (res) => {
          setLoading(false)
          if (res.status === 'success') {
            notification.success({
              message: 'Evacuation Status Updated',
            })
            form.resetFields()
            setOpen(false)
          } else {
            notification.error({
              message: 'Evacuation Status Update Failed',
            })
          }
        },
      })
    )
  }

  return (
    <>
      <Modal
        visible={open}
        onCancel={() => setOpen(false)}
        title={
          <Typography.Title className='my-0' level={4}>
            Update evacuation status
          </Typography.Title>
        }
        centered
        closable
        maskClosable
        bodyStyle={{ paddingTop: '1rem' }}
        footer={null}
      >
        <Space direction='vertical' className='w-full'>
          <Form form={form}>
            <Form.Item name='comment'>
              <TextArea placeholder='Comment (optional)' />
            </Form.Item>
            <div className='mt-6'>{content}</div>
          </Form>

          <Space className='ml-auto w-full justify-end'>
            <Button
              type='primary'
              danger
              onClick={async () => updateEvacuationStatus('NOT_SAFE')}
              loading={loading === 'NOT_SAFE'}
              disabled={loading === 'SAFE'}
            >
              NOT SAFE
            </Button>
            <Button
              type='primary'
              onClick={async () => updateEvacuationStatus('SAFE')}
              loading={loading === 'SAFE'}
              disabled={loading === 'NOT_SAFE'}
            >
              SAFE
            </Button>
          </Space>
        </Space>
      </Modal>
      <div onClick={() => setOpen(true)}>{children}</div>
    </>
  )
}
