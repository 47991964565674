import update from 'immutability-helper'
import { useSelector } from 'react-redux'
import { createSelector } from 'reselect'
import { ConfigDAF } from 'src/api/hierarchyOperations/retrieveConfigDAF'
import { RootState } from '../rootReducer'
import { ActionCreator } from '../store-action-creator'

// Action Creators
export const ActionCreators = {
  StoreSetConfigDAF: new ActionCreator<'StoreSetDafConfig', Partial<ConfigDAF[]>>('StoreSetDafConfig'),
  ClearAllStates: new ActionCreator<'ClearAllStates', Partial<void>>('ClearAllStates'),
}

export type Action = typeof ActionCreators[keyof typeof ActionCreators]

export type State = {
  config: ConfigDAF[]
}

export const initialState = (): State => ({
  config: [],
})

// Reducer
export default function reducer(state: State = initialState(), action) {
  switch (action.type) {
    case ActionCreators.StoreSetConfigDAF.type:
      return update(state, {
        config: { $set: action.payload },
      })

    case ActionCreators.ClearAllStates.type:
      return initialState()
    default:
      return state
  }
}

// Selectors
const selectDafState = (state: RootState) => state.employees.daf

// Hooks
export const useConfigDaf = (filter?: (item: ConfigDAF) => boolean) => {
  return useSelector(createSelector(selectDafState, (state) => (filter ? state.config.filter(filter) : state.config)))
}
