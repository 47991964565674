import { VisitorLogItem } from 'src/redux/store/visitors/types/log'

export default function calculateVisitorSearchString(item: VisitorLogItem) {
  let searchString = ''

  if (item.visitorFullDetails) {
    for (const prop in item.visitorFullDetails) {
      const value = item.visitorFullDetails[prop]
      if (value !== 'boolean') {
        searchString += ` ${item.visitorFullDetails[prop]}`
      }
    }
  }
  if (item.hostDetails) {
    for (const prop in item.hostDetails) {
      const value = item.hostDetails[prop]
      if (value !== 'boolean' || value !== 'Not on list') {
        searchString += ` ${item.hostDetails[prop]}`
      }
    }
  }
  if (item.badgeNumber) {
    searchString += ` ${item.badgeNumber}`
  }
  if (item.assetSerialNumber) {
    searchString += ` ${item.assetSerialNumber}`
  }
  if (item.checkInFieldValues) {
    for (const key in item.checkInFieldValues) {
      searchString += ` ${item.checkInFieldValues[key]}`
    }
  }
  if (item.inviteDetails?.bookedParkingData) {
    searchString += ` ${item.inviteDetails.bookedParkingData.parkingLicensePlate}`
  }
  if (item.inviteDetails?.accessPassCode) {
    searchString += ` ${item.inviteDetails.accessPassCode}`
  }
  return searchString.toLowerCase()
}
