export function requiredRule(fieldname: string) {
  return {
    required: true,
    message: `${fieldname} is required!`,
  }
}

export function normalizeToUpperCase(value) {
  return value ? `${value}`.toUpperCase() : value
}
