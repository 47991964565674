import React, { useMemo } from 'react'
import withDefaults from 'src/utils/with-defaults'
import { useTheme } from './theme/ThemeProvider'
import { palette, cssvars } from 'src/styles'
import { tuple } from 'src/utils/tuple'

const normalTypes = tuple('default', 'secondary', 'success', 'warning', 'error')
const normalSizes = tuple('mini', 'small', 'medium', 'large')
export type NormalSizes = typeof normalSizes[number]
export type NormalTypes = typeof normalTypes[number]

interface Props {
  size?: NormalSizes
  type?: NormalTypes
  color?: string
  width?: string
  height?: string
}

const defaultProps = {
  size: 'medium' as NormalSizes,
  type: 'default' as NormalTypes,
}

type NativeAttrs = Omit<React.HTMLAttributes<any>, keyof Props>
export type LoadingProps = Props & typeof defaultProps & NativeAttrs

const getIconSize = (size: NormalSizes) => {
  const sizes: { [key in NormalSizes]: string } = {
    mini: '2px',
    small: '3px',
    medium: '4px',
    large: '5px',
  }
  return sizes[size]
}

const getIconBgColor = (type: NormalTypes, theme: ReturnType<typeof useTheme>['theme'], color?: string) => {
  const colors: { [key in NormalTypes]: string } = {
    default: theme.disabledColor,
    secondary: theme.secondaryColor,
    success: theme.primaryColor,
    warning: palette.warning[0],
    error: palette.error[0],
  }

  return color ? color : colors[type]
}

const Loading: React.FC<React.PropsWithChildren<LoadingProps>> = ({ children, size, type, color }) => {
  const { theme } = useTheme()
  const width = useMemo(() => getIconSize(size), [size])
  const bgColor = useMemo(() => getIconBgColor(type, theme, color), [type, theme, color])

  return (
    <div className='loading-container'>
      <span className='loading'>
        {children && <label>{children}</label>}
        <i />
        <i />
        <i />
      </span>
      <style jsx>{`
        .loading-container {
          display: inline-flex;
          align-items: center;
          position: relative;
          width: 100%;
          height: 100%;
          min-width: 30px;
          min-height: 16px;
          line-height: inherit;
        }

        label {
          margin-right: 3px;
          color: ${cssvars.disabledColor};
        }

        label :global(*) {
          margin: 0;
        }

        .loading {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: transparent;
          user-select: none;
        }

        i {
          width: ${width};
          height: ${width};
          border-radius: 50%;
          background-color: ${bgColor};
          margin: 0 1px;
          display: inline-block;
          animation: loading-blink 1.4s infinite both;
        }

        i:nth-child(2) {
          animation-delay: 0.2s;
        }

        i:nth-child(3) {
          animation-delay: 0.4s;
        }

        @keyframes loading-blink {
          0% {
            opacity: 0.2;
          }

          20% {
            opacity: 1;
          }

          100% {
            opacity: 0.2;
          }
        }
      `}</style>
    </div>
  )
}

const DotsLoading = withDefaults(React.memo(Loading), defaultProps)

export default DotsLoading
