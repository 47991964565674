import { ColumnFilterItem } from 'antd/lib/table/interface'

/**
 *  Returns a unique array of filter options for table columns
 */
export function uniqueFilterArray(arr: ColumnFilterItem[]) {
  return arr.filter((v, i, a) => a.findIndex((t) => t?.text === v?.text) === i).filter(Boolean)
}

export function filterByValue(value: string | number | boolean, input?: string | number | boolean) {
  if (!input) {
    return false
  }

  return input?.toString().toLowerCase()?.includes(value.toString().toLowerCase())
}
