import { isNil, omitBy, snakeCase } from 'lodash'
import { RawIntercomProps } from 'react-use-intercom'
import { getSessionStorage } from './getJSONfromStorage'
import { TokenDetails } from './token'
import updateIntercom from './updateIntercom'
import { HierarchyDeepStructure, EntityHierarchyConfig } from 'src/typings/kenai/configuration/entity-hierarchy'
// import { defaultTokenValues } from './token'

type SelectedHierarchy = {
  hierarchyStructure: 'ALL' | string
  role: HierarchyDeepStructure['role']
}

class AccessManagerClass {
  idToken: any = undefined
  tokenDetails?: TokenDetails = undefined
  selectedHierarchy: SelectedHierarchy = {
    hierarchyStructure: 'ALL',
    role: 'DBUSER',
  }
  profileAccess: AccountProfile

  constructor() {
    this.selectedHierarchy = {
      hierarchyStructure: 'ALL',
      role: 'DBUSER',
    }
  }

  /// PRE-MIGRATION START
  /**
   * Returns the current OrganizationId from the tokenDetails
   *
   * - `tokenDetails.kna_OrganizationId`
   * - `this.tokenDetails['https://app.kenai.co.za/app_metadata'].amOrganizationId`
   */
  get organizationId() {
    return this.tokenDetails?.kna_OrganizationId ?? this.tokenDetails?.['https://app.kenai.co.za/app_metadata']?.amOrganizationId
  }

  /**
   * Return the bearer token for the current user
   *
   * - `Bearer ${this.idToken}`
   */
  get Authorization() {
    return `Bearer ${this.idToken}`
  }

  /// PRE-MIGRATION END

  clearAllStates = () => {
    this.idToken = undefined
    this.tokenDetails = undefined
    this.selectedHierarchy = {
      hierarchyStructure: 'ALL',
      role: 'DBUSER',
    }
  }

  setToken = (idToken, tokenDetails) => {
    //process idToken
    this.idToken = idToken
    this.tokenDetails = tokenDetails
    this.setProfileAccess(tokenDetails)
    this.updateIntercom()
  }

  setSelectedEntityHierarchy = (selectedHierarchy: HierarchyDeepStructure | 'ALL' = 'ALL') => {
    if (selectedHierarchy === 'ALL') {
      localStorage.setItem('cachedHierarchy', selectedHierarchy)
      this.selectedHierarchy = {
        hierarchyStructure: 'ALL',
        role: 'DBUSER',
      }
    } else {
      localStorage.setItem('cachedHierarchy', selectedHierarchy.hierarchyStructure)
      this.selectedHierarchy = {
        hierarchyStructure: selectedHierarchy.hierarchyStructure,
        role: selectedHierarchy.role,
      }
    }
  }

  updateIntercom = () => {
    const tokenDetails = this.tokenDetails
    const { role, hierarchyStructure: company_id } = this.selectedHierarchy

    const reduceCustomAttributes = (data) => {
      return Object.keys(data).reduce(
        (acc: Record<any, any>, key) => {
          const value = data?.[key]
          if (value) {
            return { ...acc, [`dbuser_${snakeCase(key)}`]: value }
          } else {
            return acc
          }
        },
        { role }
      )
    }

    const getCompanyName = (selectedHierarchy: string) => {
      if (selectedHierarchy !== 'ALL') {
        const ehConfig = getSessionStorage<EntityHierarchyConfig>('entityHierarchyConfiguration')
        if (ehConfig) {
          const tenantFlags = ehConfig?.tenantFlags.find((tf) => tf.EntityHierarchy === selectedHierarchy)
          if (tenantFlags) {
            return tenantFlags.entityLocationData.companyName + ' - ' + tenantFlags.entityLocationData.locationShortDescription
          }
        }
      } else {
        return undefined
      }
    }

    if (tokenDetails?.['https://app.kenai.co.za/app_metadata']) {
      const appMetaData = tokenDetails?.['https://app.kenai.co.za/app_metadata']

      const newSettings: RawIntercomProps = {
        name: tokenDetails?.['https://app.kenai.co.za/user_metadata']?.umName,
        email: tokenDetails.email,
        user_id: tokenDetails.email,
        user_hash: tokenDetails['https://app.kenai.co.za/app_metadata']?.['icUserHash'],
        phone: appMetaData?.amNumber || '',
        ...(company_id !== 'all' ? { company: { company_id, name: getCompanyName(company_id) } } : {}),
        customAttributes: reduceCustomAttributes(appMetaData),
      }

      // console.log('new intercom settings', omitBy(newSettings, isNil))

      updateIntercom({
        ...window.intercomSettings,
        ...omitBy(newSettings, isNil),
      })
    }
    if (tokenDetails?.kna_Authorizations) {
      const knaAuthorizations = JSON.parse(atob(tokenDetails.kna_Authorizations))
      const flattenedAuths = {
        amMTHA: knaAuthorizations.amMTHA,
        ...knaAuthorizations.authFlags,
      }

      const newSettings: RawIntercomProps = {
        name: tokenDetails.kna_DisplayName,
        email: tokenDetails.sub,
        user_id: tokenDetails.sub,
        user_hash: knaAuthorizations.authFlags?.icUserHash,
        phone: '',
        ...(company_id !== 'all' ? { company: { company_id, name: getCompanyName(company_id) } } : {}),
        customAttributes: reduceCustomAttributes(flattenedAuths),
      }

      // console.log('new intercom settings', omitBy(newSettings, isNil))

      updateIntercom({
        ...window.intercomSettings,
        ...omitBy(newSettings, isNil),
      })
    }
  }
  hasAccess(key: Exclude<keyof AccountProfile, 'umName' | 'umEmail' | 'icUserHash'>) {
    return this.profileAccess[key]
  }
  private setProfileAccess(tokenDetails: TokenDetails) {
    const userMetadata = tokenDetails['https://app.kenai.co.za/user_metadata']
    const appMetadata = tokenDetails['https://app.kenai.co.za/app_metadata']

    if (userMetadata && appMetadata) {
      this.profileAccess = {
        umEmail: tokenDetails.email,
        ...userMetadata,
        ...appMetadata,
      }
    }

    if (tokenDetails.kna_Authorizations && tokenDetails.kna_DisplayName && tokenDetails.kna_OrganizationId) {
      const authData = JSON.parse(atob(AccessManager.tokenDetails.kna_Authorizations))
      this.profileAccess = {
        umEmail: tokenDetails.sub,
        umName: tokenDetails.kna_DisplayName,
        ...authData.authFlags,
      }
    }
  }
}

const AccessManager = new AccessManagerClass()

window.AccessManager = AccessManager

export default AccessManager
