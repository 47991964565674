import { RawIntercomProps } from 'react-use-intercom'

export default function updateIntercom(props: RawIntercomProps) {
  try {
    const hasUserHash = props.user_hash || window?.intercomSettings?.user_hash
    if (hasUserHash) {
      if ('Intercom' in window) {
        window?.Intercom?.('update', {
          ...window.intercomSettings,
          ...props,
        })
        window.intercomSettings = props
      } else {
        throw new Error('Intercom is still not defined')
      }
    } else {
      throw new Error(
        'Intercom Messenger error: Missing user_hash. A valid user_hash is required to authenticate users when Identity Verification is enabled. For more details, see https://docs.intercom.com/configure-intercom-for-your-product-or-site/staying-secure/enable-identity-verification-on-your-web-product.'
      )
    }
  } catch (e) {
    console.error(e)
  }
}
