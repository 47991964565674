import { faBook, faCalendarCheck, faChartBar, faParking } from '@fortawesome/pro-light-svg-icons'
import React, { lazy } from 'react'
import { FormattedMessage } from 'react-intl'
import { HierarchyEnabledFlags } from 'src/hooks/use-hierarchy-enabled-flags'
import { ROUTE } from 'src/typings/kenai/dashboard-router'
import { conditionalArrayItem } from 'src/utils/conditionalReturn'
import { showRoute } from './show-route'

const ParkingAnalytics = lazy(() => import('src/containers/Parking/Analytics'))
const ParkingLog = lazy(() => import('src/containers/Parking/Log'))
const ParkingBookings = lazy(() => import('src/containers/Parking/Bookings'))
const CreateOnDemandBooking = lazy(() => import('src/containers/Parking/Bookings/Create/OnDemand'))
const CreateProvisionedBooking = lazy(() => import('src/containers/Parking/Bookings/Create/Provisioned'))

const DisabledView = lazy(() => import('src/components/disabled-view'))

export default function getParkingRoutes(hierarchyEnabledFlags: HierarchyEnabledFlags, parkingEnabled, isLegacyLayout: boolean) {
  const enabled: Record<string, (val: ROUTE) => ROUTE[]> = {
    parkingLog: (val) => conditionalArrayItem(showRoute(hierarchyEnabledFlags.parkingLog, isLegacyLayout), val),
    bookingLog: (val) => conditionalArrayItem(showRoute(hierarchyEnabledFlags.parkingBookingLog, isLegacyLayout), val),
    analytics: (val) => conditionalArrayItem(showRoute(hierarchyEnabledFlags.parkingAnalytics, isLegacyLayout), val),
  }
  const basePath = hierarchyEnabledFlags.parkingRouteType.path

  const parkingRoute: ROUTE = {
    icon: faParking,
    label: hierarchyEnabledFlags.parkingRouteType.singularLabel,
    path: basePath,
    sidebar: true,
    component: parkingEnabled ? undefined : DisabledView,
    children: parkingEnabled
      ? [
          ...enabled.parkingLog({
            path: `${basePath}/log`,
            label: <FormattedMessage id='kenai.sidebar.log' />,
            icon: faBook,
            component: hierarchyEnabledFlags.parkingLog ? ParkingLog : DisabledView,
            sidebar: true,
          }),
          ...enabled.bookingLog({
            path: `${basePath}/bookings`,
            label: 'Bookings',
            icon: faCalendarCheck,
            component: hierarchyEnabledFlags.parkingBookingLog ? ParkingBookings : DisabledView,
            sidebar: true,
            exact: true,
          }),
          ...enabled.bookingLog({
            path: `${basePath}/bookings/create/on-demand`,
            component: hierarchyEnabledFlags.parkingBookingLog ? CreateOnDemandBooking : DisabledView,
            sidebar: false,
            exact: true,
          }),
          ...enabled.bookingLog({
            path: `${basePath}/bookings/create/provisioned`,
            component: hierarchyEnabledFlags.parkingBookingLog ? CreateProvisionedBooking : DisabledView,
            sidebar: false,
            exact: true,
          }),
          ...enabled.analytics({
            path: `${basePath}/analytics`,
            label: <FormattedMessage id='kenai.sidebar.analytics' />,
            icon: faChartBar,
            component: hierarchyEnabledFlags.parkingAnalytics ? ParkingAnalytics : DisabledView,
            sidebar: true,
          }),
        ]
      : undefined,
  }
  return parkingRoute
}
