import { API as AmplifyAPI } from 'aws-amplify'
import AccessManager from 'src/utils/AccessManager'
import { RequireExactlyOne } from 'type-fest'

type eventKeys = 'OPERATION_PROCESSED' | 'OPERATION_FAILED'

export type errorKeys = 'NOTIFICATION_NOT_FOUND' | 'NOTIFICATION_INSTANCE_OBSOLETE' | 'unknown'

type APIResponse<T extends Record<string, unknown>, K = any> =
  | (T & {
      key: K
    })
  | undefined

type ResponseBody = RequireExactlyOne<
  {
    error?: errorKeys
    notificationResult?: ConfigValueCustomNotification
  },
  'error' | 'notificationResult'
>

type RESPONSE = APIResponse<ResponseBody, eventKeys>

export type operations = 'addNotification' | 'editNotification' | 'deleteNotification'

type notificationDetails = { EntityHierarchy: string; notificationId?: string } & Partial<Omit<ConfigValueCustomNotification, 'fieldId'>>

export async function customNotificationProcessor(operation: operations, notificationDetails: notificationDetails) {
  try {
    return (await AmplifyAPI.post('provisioningOperations', '/customNotificationProcessor', {
      headers: {
        Authorization: `Bearer ${AccessManager.idToken}`,
      },
      body: {
        operation,
        notificationDetails,
      },
    })) as RESPONSE
  } catch (err) {
    console.error(err)
    return undefined
  }
}
