import { ActionCreator } from '../store-action-creator'
import update from 'immutability-helper'
import { createSelector } from 'reselect'
import { RootState } from '../rootReducer'
import { HostLinkConfig } from './types/manageInvites'

// Action Creators
export const ActionCreators = {
  StoreInviteHostSearchSetAvailableHostLinkingConfig: new ActionCreator<
    'StoreInviteHostSearchSetAvailableHostLinkingConfig',
    HostLinkConfigContainer
  >('StoreInviteHostSearchSetAvailableHostLinkingConfig'),
  StoreInviteHostSearchUpdateHostsResults: new ActionCreator<'StoreInviteHostSearchUpdateHostsResults', any[]>(
    'StoreInviteHostSearchUpdateHostsResults'
  ),
  StoreInviteHostSearchClearAllSearchResults: new ActionCreator<'StoreInviteHostSearchClearAllSearchResults', void>(
    'StoreInviteHostSearchClearAllSearchResults'
  ),
  ClearAllStates: new ActionCreator<'ClearAllStates', void>('ClearAllStates'),
}

export type Action = typeof ActionCreators[keyof typeof ActionCreators]

export type HostLinkConfigContainer = {
  [key: string]: HostLinkConfig[]
}
// State
export type State = {
  readonly hostLinkConfig: HostLinkConfigContainer
  readonly searchResultObjects: any[]
}

export const initialState = (): State => ({
  hostLinkConfig: {},
  searchResultObjects: [],
})

export default function reducer(state: State = initialState(), action: Action) {
  if (action.type === ActionCreators.StoreInviteHostSearchSetAvailableHostLinkingConfig.type) {
    return update(state, {
      hostLinkConfig: {
        $set: action.payload,
      },
    })
  }
  if (action.type === ActionCreators.StoreInviteHostSearchUpdateHostsResults.type) {
    return update(state, {
      searchResultObjects: {
        $set: action.payload,
      },
    })
  }
  if (action.type === ActionCreators.StoreInviteHostSearchClearAllSearchResults.type) {
    return update(state, {
      searchResultObjects: {
        $set: [],
      },
    })
  }
  if (action.type === ActionCreators.ClearAllStates.type) {
    return update(state, {
      $set: initialState(),
    })
  }
  return state
}

const getInviteHostSearchState = (state: RootState) => state.visitors.manageInvites.searchResultObjects
export const getInviteHostSearch = createSelector<any, any, State['searchResultObjects']>(
  [getInviteHostSearchState],
  (state: State['searchResultObjects']) => {
    return state
  }
)

const getInviteHostLinkingConfigState = (state: RootState) => state.visitors.manageInvites.hostLinkConfig
export const getInviteHostLinkingConfig = createSelector<any, any, State['hostLinkConfig']>(
  [getInviteHostLinkingConfigState],
  (state: State['hostLinkConfig']) => {
    return state
  }
)
