import { cloneDeep } from 'lodash'
import { all, fork, put, select, takeLeading } from 'redux-saga/effects'
import { inductionAPI } from 'src/api/settings/settings-general-account'
import { refreshApiCredentials } from 'src/redux/sagas/authentication/refreshApiCredentials'
import ActionCreator from 'src/redux/sagas/saga-action-creator'
import {
  ActionCreators as StoreActionCreators,
  VideoProcessing,
  VideoCopyOp,
} from 'src/redux/store/settings/general-account/induction-store'
import { ActionCreators as VisitorLogActionCreators, getVisitorsLogConfig } from 'src/redux/store/visitors/log'

// Action Creators
export const ActionCreators = {
  SagaRetrieveInductionConfig: new ActionCreator<'SagaRetrieveAccountInductionConfig', any>('SagaRetrieveAccountInductionConfig'),
  SagaSaveInductionConfiguration: new ActionCreator<
    'SagaSaveInductionConfiguration',
    {
      submission: AccountInductionSettings
      preRegistrationVideoProcessing?: VideoProcessing
      kioskVideoProcessing?: VideoProcessing
      employeeLinkVideoProcessing?: VideoProcessing
      preRegistrationVideoVersionCopyOp?: VideoCopyOp
      kioskVideoVersionCopyOp?: VideoCopyOp
      employeeLinkVideoVersionCopyOp?: VideoCopyOp
    }
  >('SagaSaveInductionConfiguration'),
}

function* processSagaRetrieveInductionConfig(action: typeof ActionCreators['SagaRetrieveInductionConfig']) {
  try {
    yield refreshApiCredentials()
    const inductionConfig: AccountSettings['induction'] = yield inductionAPI.retrieveInductionConfig()

    const compatInductionConfig = cloneDeep(inductionConfig)

    const legacyKioskValidity = (inductionConfig.channels.kiosk as any).validity as number | undefined
    const legacyScreeningRoomValidity = (inductionConfig.channels.screeningRoom as any).validity as number | undefined

    // This is to cater for backwards compatibility of validity type configurations
    // see https://kenai.atlassian.net/browse/KPD-32?focusedCommentId=15432
    if (typeof legacyKioskValidity === 'number' && !compatInductionConfig.channels.kiosk.validityType) {
      compatInductionConfig.channels.kiosk.validityType = {
        visitor: legacyKioskValidity,
        employee: legacyKioskValidity,
      }
      delete (inductionConfig.channels.kiosk as any).validity
    }

    if (typeof legacyScreeningRoomValidity === 'number' && !compatInductionConfig.channels.screeningRoom.validityType) {
      compatInductionConfig.channels.screeningRoom.validityType = {
        visitor: legacyScreeningRoomValidity,
        employee: legacyScreeningRoomValidity,
      }
      delete (inductionConfig.channels.screeningRoom as any).validity
    }

    yield put(StoreActionCreators.StoreSetInductionConfig.create({ dirty: false, ...compatInductionConfig }))
    if (action.callback) action.callback({ status: 'success' })
  } catch (e) {
    console.log(e)
    if (action.callback) action.callback({ status: 'failed' })
  }
}

function* processSaveInductionConfiguration(action: typeof ActionCreators['SagaSaveInductionConfiguration']) {
  try {
    yield refreshApiCredentials()
    const {
      submission,
      preRegistrationVideoProcessing,
      kioskVideoProcessing,
      employeeLinkVideoProcessing,
      preRegistrationVideoVersionCopyOp,
      kioskVideoVersionCopyOp,
      employeeLinkVideoVersionCopyOp,
    } = action.payload
    if (preRegistrationVideoProcessing) {
      const newVersion = submission.channels.preRegistration.video.version === 0 ? 1 : submission.channels.preRegistration.video.version
      yield inductionAPI.saveInductionVideo(preRegistrationVideoProcessing, newVersion, 'preRegistration')
      submission.channels.preRegistration.video.version = newVersion
      submission.channels.preRegistration.video.duration = preRegistrationVideoProcessing.metadata.duration
      submission.channels.preRegistration.video.state = 'ACTIVATED'
    }
    if (kioskVideoProcessing) {
      const newVersion = submission.channels.kiosk.video.version === 0 ? 1 : submission.channels.kiosk.video.version
      yield inductionAPI.saveInductionVideo(kioskVideoProcessing, newVersion, 'kiosk')
      submission.channels.kiosk.video.version = newVersion
      submission.channels.kiosk.video.duration = kioskVideoProcessing.metadata.duration
      submission.channels.kiosk.video.state = 'ACTIVATED'
    }
    if (employeeLinkVideoProcessing) {
      const newVersion = submission.channels.employeeLink.video.version === 0 ? 1 : submission.channels.employeeLink.video.version
      yield inductionAPI.saveInductionVideo(employeeLinkVideoProcessing, newVersion, 'employeeLink')
      submission.channels.employeeLink.video.version = newVersion
      submission.channels.employeeLink.video.duration = employeeLinkVideoProcessing.metadata.duration
      submission.channels.employeeLink.video.state = 'ACTIVATED'
    }
    if (preRegistrationVideoVersionCopyOp) {
      yield inductionAPI.copyInductionVideo(preRegistrationVideoVersionCopyOp, 'preRegistration')
    }
    if (kioskVideoVersionCopyOp) {
      yield inductionAPI.copyInductionVideo(kioskVideoVersionCopyOp, 'kiosk')
    }
    if (employeeLinkVideoVersionCopyOp) {
      yield inductionAPI.copyInductionVideo(employeeLinkVideoVersionCopyOp, 'employeeLink')
    }

    const inductionUpdate = yield inductionAPI.saveConfigInduction(submission)
    yield put(StoreActionCreators.StoreSetInductionConfig.create({ dirty: false, ...submission }))
    const visitorLogConfigs = yield select(getVisitorsLogConfig)
    const newVisitorLogConfigs = cloneDeep(visitorLogConfigs)
    // TODO support typed inductions here.
    newVisitorLogConfigs.induction[inductionUpdate.EntityHierarchy] = inductionUpdate
    yield put(VisitorLogActionCreators.StoreSetVisitorLogConfigs.create(newVisitorLogConfigs))
    if (action.callback) action.callback({ status: 'success' })
  } catch (e) {
    console.error(e)
    if (action.callback) action.callback({ status: 'failed' })
  }
}

// Saga triggers
function* watchAccountInductionConfigSagas() {
  yield takeLeading(ActionCreators.SagaRetrieveInductionConfig.type, processSagaRetrieveInductionConfig)
  yield takeLeading(ActionCreators.SagaSaveInductionConfiguration.type, processSaveInductionConfiguration)
  yield null
}

// Saga hooks
export default function* accountInductionConfigSaga() {
  yield all([fork(watchAccountInductionConfigSagas)])
}
