import logoSmall from 'src/visual/image/icon-kenai-bot.svg'
import logo from 'src/visual/image/kenai-logo.svg'
import logoSmallLight from 'src/visual/image/icon-kenai-bot-light.svg'
import logoLight from 'src/visual/image/kenai-logo-light.svg'
import cssvars from './cssvars'
import { lightTheme, darkTheme } from './themes'

const size = {
  xs: 480,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1600,
}

const antPrefix = 'ant'

const palette = {
  primary: [
    '#4CB75C', // 0: Default
    '#46af56', // 2: Darken 5%
    'rgba(76, 183, 92, 0.2)', // 3: Fade 20%
    '#54ba64', // 4: Lighten 3%
    'rgba(76, 183, 92, 0.75)', // 5: Fade 75%
  ],
  secondary: '#000',
  info: [
    '#2db7f5', // 0: Info
  ],
  warning: [
    '#ffbf00', // 0: Warning
  ],
  success: [
    '#4CB75C', // 0: Success
  ],
  error: [
    '#f64744', // 0: Error
    '#EC3D3A', // 1: Darken 4%
    '#FF5B58', // 2: Lighten 8%
  ],
}

const kenaiBrand = {
  logoSmall: {
    light: logoSmall,
    dark: logoSmallLight,
  },
  logo: {
    light: logo,
    dark: logoLight,
  },
}

export { size, antPrefix, palette, kenaiBrand, cssvars, lightTheme, darkTheme }
