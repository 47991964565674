import { all, fork, takeLeading, put } from 'redux-saga/effects'
import ActionCreator from '../saga-action-creator'
import { ActionCreators as StoreSidebarActionCreations } from 'src/redux/store/dashboard/sidebar'

// Action Creators
export const ActionCreators = {
  SagaToggleSidebarCollapse: new ActionCreator<'SagaToggleSidebarCollapse', void>('SagaToggleSidebarCollapse'),
}

export type ViewTypes = 'MobileView' | 'DesktopView' | 'TabView'

export function getView(width) {
  let newView = 'MobileView'
  if (width > 1220) {
    newView = 'DesktopView'
  } else if (width > 767) {
    newView = 'TabView'
  }
  return newView as ViewTypes
}

function* processCollapseToggle(_action) {
  try {
    yield put(StoreSidebarActionCreations.StoreToggleSidebarCollapse.create())
  } catch (e) {
    console.log(e)
  }
}

// Saga triggers
function* watchSidebarSagas() {
  yield takeLeading(ActionCreators.SagaToggleSidebarCollapse.type, processCollapseToggle)
  yield null
}

// Saga hooks
export default function* sidebarSagas() {
  yield all([fork(watchSidebarSagas)])
}
