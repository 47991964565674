import { Space } from 'antd'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import LocaleSwitch from 'src/components/locale-switch'
import { cn } from 'src/utils/classnames'
import useBrand from 'src/hooks/use-brand'
import { useLegacyLayout } from 'src/components/legacy-layout-provider'

const WelcomeScreen = () => {
  const brand = useBrand()
  const { isLegacyLayout } = useLegacyLayout()
  const { logoSmall, name } = brand

  return (
    <div className='w-full h-full max-w-lg m-auto text-center '>
      <Space direction='vertical' className='items-center justify-center w-full h-full p-5 m-auto' size='small'>
        <h1 className='mb-2 text-2xl md:text-4xl'>
          <FormattedMessage id='kenai.welcome.title' values={{ COMPANY_NAME: name }} />
        </h1>
        <img
          src={logoSmall}
          alt={`${name} Logo`}
          aria-label={`${name} Logo`}
          className={cn('logo max-w-full w-auto bg-contain bg-no-repeat bg-center mb-2 h-40', {
            'bg-vars-bodyBackground': isLegacyLayout,
          })}
        />
        <h4 className='mb-2 text-4xl md:text-5xl'>
          <FormattedMessage id='kenai.welcome.subtitle' />
        </h4>
        <h6 className='mb-1 text-lg text-vars-primaryColor md:text-2xl'>
          <FormattedMessage id='kenai.welcome.moto' />
        </h6>
        <p className='md:text-md'>
          <FormattedMessage id='kenai.welcome.description' values={{ COMPANY_NAME: name }} />
        </p>
        <LocaleSwitch />
      </Space>
    </div>
  )
}

export default WelcomeScreen
