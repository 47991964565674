import { TableProps } from 'antd'
import { ColumnGroupType, ColumnType, FilterValue, SorterResult } from 'antd/lib/table/interface'
import { DataIndex } from 'rc-table/lib/interface'
import { useCallback, useState } from 'react'
import { ExportableColumnProps } from 'src/utils/exportableColumns'

type FilteredColumns<T> = ((ColumnType<T> | ColumnGroupType<T> | ExportableColumnProps<T>) & {
  dataIndex?: DataIndex
})[]

export const useTableFilters = <T extends any>() => {
  const [filteredInfo, setFilteredInfo] = useState<Record<string, FilterValue | null>>({})
  const [sortedInfo, setSortedInfo] = useState<SorterResult<T>>({})
  const [currentDataSource, setCurrentDataSource] = useState<T[]>([])

  const onChange: TableProps<T>['onChange'] = (pagination, filters, sorter, { currentDataSource }) => {
    console.log({ pagination, filters, sorter })
    setFilteredInfo(filters)
    setSortedInfo(sorter as SorterResult<T>)
    setCurrentDataSource(currentDataSource)
  }

  /**
   * Resets all the filters
   */
  const clearFilters = () => {
    setFilteredInfo({})
    setCurrentDataSource([])
  }

  /**
   * Resets all the filters and sorters
   */
  const clearAll = () => {
    setFilteredInfo({})
    setSortedInfo({})
    setCurrentDataSource([])
  }

  /**
   * This function is used to apply the filters and sorters to the columns
   */
  const applyFiltersToColumns = useCallback(
    (columns: FilteredColumns<T>): FilteredColumns<T> => {
      return columns.map((column) => {
        let updatedColumn = { ...column }

        if ('onFilter' in column) {
          // Apply the filter to the column
          let filteredValue = null
          if ('key' in column) {
            filteredValue = filteredInfo[column.key]
            // TODO handle array dataIndex (see how the filteredInfo is set)
          } else if ('dataIndex' in column) {
            const dataIndex = Array.isArray(column.dataIndex) ? column.dataIndex.join('.') : column.dataIndex.toString()
            filteredValue = filteredInfo[dataIndex]
          }

          updatedColumn = {
            ...updatedColumn,
            filteredValue: filteredValue || null,
          }
        }

        if ('sorter' in column) {
          let sortOrder = null
          if ('key' in column && sortedInfo.columnKey === column.key) {
            sortOrder = sortedInfo.order
          } else if ('dataIndex' in column) {
            const dataIndex = Array.isArray(column.dataIndex) ? column.dataIndex.join('.') : column.dataIndex.toString()

            if (sortedInfo.columnKey === dataIndex) {
              sortOrder = sortedInfo.order
            }
          }

          // Apply the sorter to the column
          updatedColumn = { ...updatedColumn, sortOrder: sortOrder || null }
        }

        if ('children' in updatedColumn && updatedColumn?.children?.length) {
          // Apply the filter to the children columns
          updatedColumn = {
            ...updatedColumn,
            children: applyFiltersToColumns(updatedColumn.children),
          }
        }

        if ('filters' in updatedColumn && !updatedColumn.filters.length) {
          // Avoid returning the filter icon if there are no filters
          delete updatedColumn.filters
        }

        return updatedColumn
      })
    },

    [filteredInfo, sortedInfo.columnKey, sortedInfo.order]
  )

  const applyFiltersToData = (data: T[]) => {
    return currentDataSource.length ? currentDataSource : data
  }

  const hasFilters = Boolean(!!Object.values(filteredInfo).filter(Boolean).length || !!Object.values(sortedInfo).filter(Boolean).length)

  return {
    currentDataSource,
    filteredInfo,
    sortedInfo,
    hasFilters,
    onChange,
    clearFilters,
    clearAll,
    applyFiltersToColumns,
    applyFiltersToData,
  }
}
