import React from 'react'
import { Button, Space } from 'antd'

const disabled = {
  desc: 'To mark a button as disabled, add the disabled property to the Button.',
  component: (
    <Space direction='vertical'>
      <Space>
        <Button type='primary'>Primary</Button>
        <Button type='primary' disabled>
          Primary(disabled)
        </Button>
      </Space>
      <Space>
        <Button>Default</Button>
        <Button disabled>Default(disabled)</Button>
      </Space>
      <Space>
        <Button type='dashed'>Dashed</Button>
        <Button type='dashed' disabled>
          Dashed(disabled)
        </Button>
      </Space>
      <Space>
        <Button type='link'>Link</Button>
        <Button type='link' disabled>
          Link(disabled)
        </Button>
      </Space>
      <Space>
        <Button type='link' danger>
          Danger Link
        </Button>
        <Button type='link' danger disabled>
          Danger Link(disabled)
        </Button>
      </Space>
      <Space>
        <Button danger>Secondary Default</Button>
        <Button danger disabled>
          Secondary Default(disabled)
        </Button>
        <Button danger ghost>
          Secondary Ghost
        </Button>
        <Button danger ghost disabled>
          Secondary Ghost(disabled)
        </Button>
      </Space>
      <Space>
        <Button ghost>Ghost</Button>
        <Button ghost disabled>
          Ghost(disabled)
        </Button>
      </Space>
    </Space>
  ),
}

export default disabled
