import { parsePhoneNumberFromString } from 'libphonenumber-js'
import getIntl from 'src/i18n'
import isEmail from 'validator/es/lib/isEmail'
const intl = getIntl()

export const checkValidIntPhone = (value: string) => {
  if (!value) return true // if nothing is entered it should not return error
  const phoneNumber = parsePhoneNumberFromString(value || '')
  if (phoneNumber && phoneNumber.isValid()) return true
  return false
}

export const checkValidEmail = (value: string) => {
  if (!value) return true // if nothing is entered it should not return error
  if (value && isEmail(value)) return true
  return false
}

export const emailValidator = (_rule?: any, value?: any) => {
  if (!value) Promise.resolve() // if nothing is entered it should not return error
  if (!checkValidEmail(value)) return Promise.reject(intl.formatMessage({ id: 'validations.email.invalid' }))
  return Promise.resolve()
}

export const phoneValidator = (_rule?: any, value?: any) => {
  if (!value) Promise.resolve() // if nothing is entered it should not return error
  if (!checkValidIntPhone(value)) return Promise.reject(intl.formatMessage({ id: 'kenai.components.validators.phone' }))
  return Promise.resolve()
}

export const isGSMAlphabet = (value: string) => {
  const regexp = new RegExp(
    '^[A-Za-z0-9 \\r\\n@£$¥èéùìòÇØøÅå\u0394_\u03A6\u0393\u039B\u03A9\u03A0\u03A8\u03A3\u0398\u039EÆæßÉ!"#$%&\'()*+,\\-./:;<=>?¡ÄÖÑÜ§¿äöñüà^{}\\\\\\[~\\]|\u20AC]*$'
  )
  const test = regexp.test(value)
  console.log(test)
  return regexp.test(value)
}

export const GSMValidator = (_rule?: any, value?: any) => {
  if (!value) Promise.resolve() // if nothing is entered it should not return error
  if (!isGSMAlphabet(value)) return Promise.reject('Special characters are not permitted')
  return Promise.resolve()
}
