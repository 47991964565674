/*eslint no-throw-literal: 0*/
import { all, fork, put, takeLeading } from 'redux-saga/effects'
import { retrieveEntityDevices } from 'src/api/hierarchyOperations/retrieveEntityDevices'
import { ActionCreators as AuthenticationActionCreators } from 'src/redux/sagas/authentication'
import { ActionCreators as Actions } from 'src/redux/store/visitors/devices'
import { call } from 'typed-redux-saga'
import ActionCreator from '../saga-action-creator'

type RetrieveDevicesPayload = string

// Action Creators
export const ActionCreators = {
  SagaRetrieveDevices: new ActionCreator<'SagaRetrieveDevices', RetrieveDevicesPayload>('SagaRetrieveDevices'),
}

function* processRetrieveVisitorDeviceSaga(action: typeof ActionCreators['SagaRetrieveDevices']) {
  try {
    yield put(AuthenticationActionCreators.SagaRefreshApiCredentials.create())
    const result = yield* call(retrieveEntityDevices, action.payload)

    yield put(Actions.StoreSetDevices.create(result))

    if (action.callback) action.callback({ status: 'success' })
  } catch (e) {
    if (e) console.error(e)
    if (action.callback) action.callback({ status: 'failed' })
  }
}

// Saga triggers
function* watchVisitorDeviceSagas() {
  yield takeLeading(ActionCreators.SagaRetrieveDevices.type, processRetrieveVisitorDeviceSaga)
  yield null
}

// Saga hooks
export default function* visitorDeviceSaga() {
  yield all([fork(watchVisitorDeviceSagas)])
}
