import React from 'react'
import { Button, Space } from 'antd'

const ghost = {
  desc: "ghost property will make button's background transparent, it is commonly used in colored background.",

  component: (
    <Space>
      <Button type='primary' ghost>
        Primary
      </Button>
      <Button ghost>Default</Button>
      <Button type='dashed' ghost>
        link
      </Button>
      <Button type='link' ghost>
        link
      </Button>
    </Space>
  ),
}

export default ghost
