import { getTableNameFromAwsExports, queryDDBWithBatchLimits } from '../utils'
import { Key } from 'aws-sdk/clients/dynamodb'
import { ConfigValueCustomField } from 'src/typings/kenai/configuration/custom-fields'

export async function getSettingsCustomFields(requestedHierarchy: string) {
  const params = (batchHierarchy: string, ExclusiveStartKey?: Key) => ({
    ExpressionAttributeValues: {
      ':hk': batchHierarchy,
      ':isDeleted': false,
    },
    FilterExpression: 'deleted = :isDeleted',
    KeyConditionExpression: 'EntityHierarchy = :hk',
    TableName: getTableNameFromAwsExports('mdConfigCustomFields'),
    ExclusiveStartKey,
  })

  return await queryDDBWithBatchLimits<Omit<ConfigValueCustomField, 'fieldType'>>(requestedHierarchy, params)
}

export async function getSettingsCustomFieldTranslations(requestedHierarchy: string) {
  const params = (batchHierarchy: string, ExclusiveStartKey?: Key) => ({
    ExpressionAttributeValues: {
      ':hk': batchHierarchy,
    },
    ConsistentRead: true,
    KeyConditionExpression: 'EntityHierarchy = :hk',
    ProjectionExpression: 'textId, texts',
    TableName: getTableNameFromAwsExports('mdConfigI18NTexts'),
    ExclusiveStartKey,
  })

  return await queryDDBWithBatchLimits<ConfigValueI18NText>(requestedHierarchy, params)
}
