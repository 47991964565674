import moment from 'moment'
import { RangePickerNumberValue } from 'src/typings/vendor/rangepicker'

export type RangeTypes = 'today' | 'tomorrow' | 'yesterday' | 'this-week' | 'this-month' | 'this-year' | 'last-month' | 'date-select'

const valueOf = (date: moment.Moment) => {
  return date.valueOf()
}

export const getTimeRange: (type: RangeTypes | number) => RangePickerNumberValue = (type) => {
  const startRefMoment = moment().startOf('day')
  const endRefMoment = moment().endOf('day')
  let range: RangePickerNumberValue = [valueOf(startRefMoment), valueOf(endRefMoment)]
  if (type === 'tomorrow') {
    range = [valueOf(startRefMoment.add(1, 'days')), valueOf(endRefMoment.add(1, 'days'))]
  } else if (type === 'yesterday') {
    range = [valueOf(startRefMoment.subtract(1, 'days')), valueOf(endRefMoment.subtract(1, 'days'))]
  } else if (type === 'this-week') {
    range = [valueOf(startRefMoment.startOf('week')), valueOf(endRefMoment.endOf('week'))]
  } else if (type === 'this-month') {
    range = [valueOf(startRefMoment.startOf('month')), valueOf(endRefMoment.endOf('month'))]
  } else if (type === 'last-month') {
    range = [valueOf(startRefMoment.startOf('month').subtract(1, 'month')), valueOf(endRefMoment.endOf('month').subtract(1, 'month'))]
  } else if (type === 'this-year') {
    range = [valueOf(startRefMoment.startOf('year')), valueOf(endRefMoment.endOf('year'))]
  } else if (typeof type === 'number') {
    range = [valueOf(startRefMoment.subtract(type, 'days')), valueOf(endRefMoment)]
  }
  return range
}

export function returnBeginEndAndSubscription(ranges: RangePickerNumberValue) {
  if (ranges) {
    const [start, end] = ranges
    const beginTime = start || moment().startOf('day').valueOf()
    const endTime = end || moment().endOf('day').valueOf()
    // const shouldSubscribeToUpdates = isEqual(getTimeRange('today'), [beginTime, endTime])
    const shouldSubscribeToUpdates = true // always on
    return {
      beginTime,
      endTime,
      shouldSubscribeToUpdates,
    }
  } else {
    return {
      beginTime: moment().startOf('day').valueOf(),
      endTime: moment().endOf('day').valueOf(),
      shouldSubscribeToUpdates: true,
    }
  }
}
