import { find } from 'lodash'
import { EntityHierarchyConfig, TenantFlags } from 'src/typings/kenai/configuration/entity-hierarchy'

export const getTenantLocationName = (tenantFlag: TenantFlags) => {
  let location = tenantFlag.EntityHierarchy
  if (tenantFlag.entityLocationData) {
    location = `${tenantFlag.entityLocationData.companyName} - ${tenantFlag.entityLocationData.locationShortDescription}`
  }
  return location
}

export const getEntityLocationName = (entityHierarchyConfig: EntityHierarchyConfig, EntityHierarchy?: string) => {
  let location = ` `
  if (EntityHierarchy && entityHierarchyConfig.tenantFlags) {
    const tenantFlag = find(entityHierarchyConfig.tenantFlags, { EntityHierarchy })
    if (tenantFlag) {
      location = getTenantLocationName(tenantFlag)
    }
  }
  return location
}
