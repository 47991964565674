import React from 'react'
import { DownOutlined, LoadingOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import { ButtonProps } from 'antd/lib/button'
import { cssvars } from 'src/styles'

interface DropdownButtonProps extends ButtonProps {
  open?: boolean
}

const blockStyle = {
  alignContent: 'center',
  paddingLeft: 10,
  paddingRight: 10,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}

const defaultStyle = {
  alignContent: 'center',
  paddingLeft: 10,
  paddingRight: 10,
  alignItems: 'center',
}

const DropdownButton = React.forwardRef<HTMLElement, DropdownButtonProps>(
  ({ children, loading, disabled, open, className = '', ...other }, ref) => {
    const styles = {
      ...(open ? { borderColor: cssvars.primaryColor, color: cssvars.primaryColor } : {}),
      ...(other.block ? blockStyle : defaultStyle),
      ...(other.style ? other.style : {}),
    }
    return (
      <Button
        ref={ref}
        style={styles}
        className={`${open ? 'text-vars-primaryColor border-vars-primaryColor' : ''} ${className}`}
        {...other}
        disabled={disabled}
      >
        <div className='flex justify-between items-center flex-grow'>
          <div className='mr-2 overflow-x-hidden truncate align-top w-full'>{children}</div>
          <span className='select-none relative' unselectable='on'>
            {loading ? (
              <LoadingOutlined spin />
            ) : (
              <DownOutlined
                // className={`ant-select-arrow-icon`}
                style={{ transform: open ? 'rotate(180deg)' : '', transition: 'all 0.3s ease' }}
              />
            )}
          </span>
        </div>
      </Button>
    )
  }
)
export default DropdownButton
