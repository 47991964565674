import Auth0Lock from 'auth0-lock'
import Amplify from 'aws-amplify'
import { config as AWSConfig } from 'aws-sdk'
import Fingerprint2 from 'fingerprintjs2'
import moment from 'moment'
import { all, call, delay, fork, put, select, takeLeading } from 'redux-saga/effects'
import API from 'src/api'
import { ActionCreators as SagaCredentialsActionCreators } from 'src/redux/sagas/credentials'
import { ActionCreators as AuthenticationActionCreators } from 'src/redux/store/authentication'
import { ActionCreators as ConfigurationActionCreators } from 'src/redux/store/configuration'
// Action Creators
import { ActionCreators as CredentialsActionCreators, getAuthCredentials } from 'src/redux/store/credentials'
import { ActionCreators as ProfileActionCreators } from 'src/redux/store/profile'
import { ActionCreators as ReportingActionCreators } from 'src/redux/store/reporting'
import { getIsLockVisible, getServiceCredentials } from 'src/redux/store/selectors'
import AccessManager from 'src/utils/AccessManager'
import CONSTANTS from 'src/utils/constants'
import getStorage from 'src/utils/getStorage'
import verifyTokenSync from 'src/utils/token'
import ActionCreator from '../saga-action-creator'
import { PublicClientApplication } from '@azure/msal-browser'
import uuid from 'uuid'
import { getNextLocationURL } from 'src/utils/getNextLocationURL'
import { CredentialsReduxState } from 'src/redux/store/credentials/types/credentials'

export const ActionCreators = {
  SagaSignIn: new ActionCreator('SagaSignIn'),
  SagaSignOut: new ActionCreator('SagaSignOut'),
  SagaHideLock: new ActionCreator('SagaHideLock'),
  SagaRefreshApiCredentials: new ActionCreator('SagaRefreshApiCredentials'),
  SagaApiCredentialsRefreshUpdate: new ActionCreator('SagaApiCredentialsRefreshUpdate'),
  SagaLogUserlogin: new ActionCreator('SagaLogUserlogin'),
}

// Functions
const AWSHelper = new API.AWSHelpers()

let isProcessingSignOut = false

function randomString(length) {
  const bytes = new Uint8Array(length)
  const random = window.crypto.getRandomValues(bytes)
  const result: any = []
  const charset = '0123456789ABCDEFGHIJKLMNOPQRSTUVXYZabcdefghijklmnopqrstuvwxyz-._~'
  random.forEach(function (c) {
    result.push(charset[c % charset.length])
  })
  return result.join('')
}

function getTerminalId() {
  //eslint-disable-next-line
  return new Promise((resolve, _reject) => {
    try {
      const lsKey = `${window.location.hostname}-terminalId`
      const persistedTerminalId = window.localStorage.getItem(lsKey)
      if (persistedTerminalId && persistedTerminalId.length > 0) {
        resolve(persistedTerminalId)
      } else {
        //eslint-disable-next-line
        new Fingerprint2().get(function (result, _components) {
          window.localStorage.setItem(lsKey, result)
          resolve(result)
        })
      }
    } catch (e) {
      resolve('UNKNOWN')
    }
  })
}

let msalInstance: PublicClientApplication | undefined = undefined

export function setMSALInstance(instance: PublicClientApplication) {
  msalInstance = instance
}

// Saga handlers
// @ts-ignore
// window.LOG_LEVEL = 'DEBUG' //debug AWS
function* processRefreshAPICredentials(action: any) {
  try {
    const currentUserCredentials = yield select(getAuthCredentials)
    const providedCredentials = action.payload
    const toProcessCreds =
      providedCredentials && providedCredentials.idToken && providedCredentials.idToken.length
        ? providedCredentials
        : currentUserCredentials
    if (toProcessCreds) {
      const tokenDetails = verifyTokenSync(toProcessCreds)
      if (tokenDetails.isValid) {
        AccessManager.setToken(toProcessCreds.idToken, tokenDetails) //for global access
        const currentServiceCredentials = yield select(getServiceCredentials)
        // eslint-disable-next-line no-async-promise-executor
        const { serviceCredentials, refreshed } = yield new Promise(async (resolve, reject) => {
          try {
            let requiresRefresh = true
            const expiryMoment = moment(Date.now() + 5 * 60 * 1000) // always check expiry against 5 minutes ago so that we early cycle the credentials
            //use the set expiryTime flag
            if (
              !!currentServiceCredentials &&
              currentServiceCredentials.hasOwnProperty('serviceCredentialExpiry') &&
              currentServiceCredentials.serviceCredentialExpiry !== 0 &&
              moment(currentServiceCredentials.serviceCredentialExpiry).isAfter(expiryMoment)
            ) {
              requiresRefresh = false
            }
            if (requiresRefresh) {
              const newCredential = await AWSHelper.retrieveEntityHierarchyAccessCredential('s3|ddb')
              if (newCredential.key === 'CREDENTIAL_RETRIEVED') {
                resolve({
                  serviceCredentials: {
                    serviceCredentialExpiry: newCredential.credentials.s3[0].credential.serviceCredentialExpiry,
                    ...newCredential.credentials,
                  },
                  refreshed: true,
                })
              } else {
                throw new Error(newCredential.key)
              }
            } else {
              resolve({ serviceCredentials: currentServiceCredentials, refreshed: false })
            }
          } catch (e) {
            reject(e)
          }
        })
        const currentAPIServiceCredentials = yield API.getCurrentServiceCredentials()
        if (refreshed || !currentAPIServiceCredentials) {
          yield API.setCurrentServiceCredentials(serviceCredentials)
          yield put(CredentialsActionCreators.StoreSetServiceCredentials.create(serviceCredentials))
          yield put(SagaCredentialsActionCreators.SetEntityHierarchyConfiguration.create())
        }
        yield put(
          ActionCreators.SagaApiCredentialsRefreshUpdate.create({
            error: false,
          })
        )
      } else {
        if (CONSTANTS.AUTH_PROVIDER === 'AUTH0') {
          if (!window.location.pathname.includes('/authenticate')) {
            // debugger
            yield call(processSignOut)
          }
        } else if (CONSTANTS.AUTH_PROVIDER === 'AADB2C') {
          const nonce = uuid.v4().replace(/-/g, '')
          const processMsalReauth = () => {
            return new Promise((resolve) => {
              const accounts = msalInstance?.getAllAccounts()
              if (accounts?.length) {
                const [account] = accounts
                msalInstance
                  ?.acquireTokenSilent({
                    scopes: [CONSTANTS.AADB2CAUTH.SCOPE_ACCESS],
                    account,
                    forceRefresh: true,
                  })
                  .then((result) => {
                    const opts = {
                      grant_type: 'password',
                      username: result.idTokenClaims['sub'],
                      password: result.accessToken,
                      client_id: CONSTANTS.AADB2CAUTH.CLIENTID_AUTHZ,
                      scope: `${CONSTANTS.AADB2CAUTH.SCOPE_AUTHZ}`,
                      response_type: 'token',
                      nonce,
                    }
                    //for consistency across repos use manual form param build approach vs browser urlparams obj
                    const formBody: any = []
                    for (const property in opts) {
                      const encodedKey = encodeURIComponent(property)
                      const encodedValue = encodeURIComponent(opts[property])
                      formBody.push(encodedKey + '=' + encodedValue)
                    }
                    const request = new Request(
                      `https://${CONSTANTS.AADB2CAUTH.DOMAIN}/${CONSTANTS.AADB2CAUTH.TENANT}/${CONSTANTS.AADB2CAUTH.POLICY_AUTHZ}/oauth2/v2.0/token`,
                      {
                        method: 'POST',
                        headers: {
                          'Content-Type': 'application/x-www-form-urlencoded',
                        },
                        body: formBody.join('&'),
                      }
                    )
                    fetch(request)
                      .then(function (response) {
                        return response.json()
                      })
                      .then((json) => {
                        if (json?.access_token) {
                          const atClaims = JSON.parse(atob(json.access_token.split('.')[1]))
                          resolve({
                            hasValidAuth: true,
                            idToken: json.access_token, //we use at but in legacy kenai it was in idToken prop - keep it there
                            idTokenPayload: atClaims,
                          })
                        } else {
                          throw json
                        }
                      })
                      .catch((err) => {
                        throw err
                      })
                  })
                  .catch((e) => {
                    console.error(e)
                    resolve({
                      hasValidAuth: false,
                    })
                  })
              } else {
                resolve({
                  hasValidAuth: false,
                })
              }
            })
          }
          const authState = yield call(processMsalReauth)
          if (authState.hasValidAuth) {
            const updatedAuthCreds = {
              idToken: authState.idToken,
              idTokenPayload: authState.idTokenPayload,
              nonce,
            }
            yield put(CredentialsActionCreators.StoreSetAuthCredentials.create(updatedAuthCreds))
            yield call(processRefreshAPICredentials, updatedAuthCreds) //reprocess with valid auths
          } else {
            yield call(processSignOut)
          }
        }
      }
    } else {
      if (!window.location.pathname.includes('/authenticate')) {
        // debugger
        yield call(processSignOut)
      }
    }
  } catch (e) {
    if (e.code && e.code === 'NotAuthorizedException' && e.message && e.message.indexOf("Logins don't match") > -1) {
      // debugger
      if (AWSConfig.credentials) {
        AWSConfig.credentials = undefined
      }
      yield call(processRefreshAPICredentials, action)
    } else {
      if (!window.location.pathname.includes('/authenticate')) {
        // debugger
        yield call(processSignOut)
      }
    }
  }
}

function* logUserlogin(action) {
  try {
    const { sub, email, amOrganizationId, amMTHA } = action.payload
    let waitingForCredentials = true
    let currentServiceCredentials: CredentialsReduxState['serviceCredentials'] | null = null
    const logTime = Date.now()
    const terminalId = yield call(getTerminalId)
    while (waitingForCredentials) {
      yield delay(250)
      currentServiceCredentials = yield select(getServiceCredentials)
      if (currentServiceCredentials && currentServiceCredentials.serviceCredentialExpiry) {
        waitingForCredentials = false
      } else {
        yield delay(2000)
      }
    }
    let attemptingToLog = true
    let attempCount = 0
    while (attemptingToLog && attempCount < 10) {
      try {
        yield call([AWSHelper, AWSHelper.logUserlogEvent], 'USER_LOGIN', logTime, terminalId, sub, email, amOrganizationId, amMTHA)
        attemptingToLog = false
      } catch (e) {
        attempCount++
        yield delay(500)
      }
    }
  } catch (e) {
    console.error(e)
  }
}

// function* setHeapUserInfo() {
//   try {
//     if (AccessManager.tokenDetails && AccessManager.tokenDetails.isValid) {
//       const terminalId = yield call(getTerminalId)
//       const userData = { terminal: terminalId }
//       yield put(
//         HeapActionCreators.StoreSetAnalyticsCredentials.create({
//           userId: AccessManager.tokenDetails.sub,
//           userData: userData,
//         })
//       )
//     } else {
//       const terminalId = yield call(getTerminalId)
//       yield put(
//         HeapActionCreators.StoreSetAnalyticsCredentials.create({
//           userId: undefined,
//           userData: { terminal: terminalId },
//         })
//       )
//     }
//   } catch (e) {
//     console.log(e)
//   }
// }

let lock

function* processSignInAuth0() {
  const nonce = randomString(16)
  let allowedConnections = ['Username-Password-Authentication'] //all non PRD environments
  if (window.location.host.indexOf('dashboard') > -1) {
    allowedConnections = ['Username-Password-Authentication', 'DeloitteAAD', 'SaicaADFS', 'NedbankSSO', 'RCSEngSSO'] //temp add to dashboard - in future all aad will have sub domain
  } else if (window.location.pathname.indexOf('/accenture') > -1) {
    allowedConnections = ['acn-password-db']
  } else if (window.location.host.indexOf('deloitte') > -1) {
    allowedConnections = ['Username-Password-Authentication', 'DeloitteAAD']
  } else if (window.location.host.indexOf('saica') > -1) {
    allowedConnections = ['Username-Password-Authentication', 'SaicaADFS']
  } else if (window.location.host.indexOf('nedbank') > -1) {
    allowedConnections = ['Username-Password-Authentication', 'NedbankSSO']
  } else if (window.location.host.indexOf('kenaiaad') > -1) {
    allowedConnections = ['Username-Password-Authentication', 'KenaiAAD', 'kenaiadfs']
  } else if (window.location.host.indexOf('kenaidbstaging.protocode.io') > -1) {
    allowedConnections = ['Username-Password-Authentication', 'ProtocodeSSO']
  }
  console.log('XX 4', { location: window.location.pathname })
  lock = new Auth0Lock(CONSTANTS.AUTH.CLIENTID, CONSTANTS.AUTH.DOMAIN, {
    languageDictionary: { title: 'Kenai Dashboard' },
    // @ts-ignore
    allowedConnections: allowedConnections,
    theme: {
      logo: window['KENAIConfigLogo'] ? window['KENAIConfigLogo'] : '/favicon.ico',
      primaryColor: window['KENAIConfigPrimaryColor'] ? window['KENAIConfigPrimaryColor'] : '#4cb75b',
    },
    oidcConformant: true,
    allowSignUp: false,
    closable: false,
    autofocus: true,
    auth: {
      redirect: false,
      params: {
        scope: 'openid email',
        nonce: nonce,
      },
      sso: false,
      redirectUrl: `${window.location.origin}/${getNextLocationURL('authenticate')}`,
      responseType: 'id_token',
    },
    configurationBaseUrl: 'https://cdn.eu.auth0.com',
    avatar: null,
    rememberLastLogin: true,
  })
  const showLock = () => {
    return new Promise((resolve, reject) => {
      lock.on('hide', () => reject('Lock closed'))

      lock.on('authenticated', (authResult) => {
        lock.hide()
        resolve(authResult.idToken)
      })

      lock.on('unrecoverable_error', (error) => {
        // debugger
        console.log('SHOW ERROR PAGE HERE')
        lock.hide()
        reject(error)
      })

      lock.on('authorization_error', function (error) {
        // debugger
        lock.show({
          flashMessage: {
            type: 'error',
            text: error.errorDescription ? error.errorDescription : 'Error signing in',
          },
        })
      })
      if (!isProcessingSignOut) {
        lock.show()
      }
    })
  }
  try {
    const authCredentials: CredentialsReduxState['authCredentials'] = yield select(getAuthCredentials)
    const tokenDetails = verifyTokenSync(authCredentials)
    if (tokenDetails.isValid) {
      if (!tokenDetails.email.includes('kenai.co.za')) {
        yield fork(logUserlogin, {
          payload: {
            sub: tokenDetails.sub,
            email: tokenDetails.email,
            amOrganizationId: tokenDetails['https://app.kenai.co.za/app_metadata'].amOrganizationId,
            amMTHA: tokenDetails['https://app.kenai.co.za/app_metadata'].amMTHA,
          },
        })
      }

      yield put(AuthenticationActionCreators.StoreSignInSuccess.create())
      yield put(ActionCreators.SagaRefreshApiCredentials.create())
    } else {
      yield put(AuthenticationActionCreators.StoreSignIn.create())
      const idToken = yield call(showLock) as any

      const idTokenPayload = verifyTokenSync({ idToken: idToken, nonce }) //standardise - we look at token valus, not anything parsed from auth0 loock libs

      if (!idTokenPayload.email.includes('kenai.co.za')) {
        yield fork(logUserlogin, {
          payload: {
            sub: tokenDetails.sub,
            email: tokenDetails.email,
            amOrganizationId: tokenDetails['https://app.kenai.co.za/app_metadata'].amOrganizationId,
            amMTHA: tokenDetails['https://app.kenai.co.za/app_metadata'].amMTHA,
          },
        })
      }
      yield put(AuthenticationActionCreators.StoreSignInSuccess.create())

      const localDashboard = localStorage.getItem('dashboard')
      const _dashboard = localDashboard && localDashboard !== null ? JSON.parse(localDashboard) : {}

      const authCheck = idTokenPayload && idTokenPayload.sub ? true : false
      if (authCheck) {
        if (_dashboard.users) {
          // User settings present,
          if (!_dashboard.users[idTokenPayload.sub]) {
            // Matching user settings not present
            _dashboard.users = {
              ..._dashboard.users,
              [idTokenPayload.sub]: {},
            }
            localStorage.setItem('dashboard', JSON.stringify(_dashboard))
          }
        } else {
          // User Settings not present so create
          _dashboard.users = {
            ..._dashboard.users,
            [idTokenPayload.sub]: {},
          }
          localStorage.setItem('dashboard', JSON.stringify(_dashboard))
        }
      }
      const creds = {
        idToken,
        idTokenPayload,
        nonce,
      }
      yield put(CredentialsActionCreators.StoreSetAuthCredentials.create(creds))
      // yield call(processRefreshAPICredentials, creds)
      // yield fork(
      //   processRefreshAPICredentials,
      //   creds
      // )
      yield put(ActionCreators.SagaRefreshApiCredentials.create(creds))
    }
    const lockElements = document.querySelector('.auth0-lock-container')
    if (lockElements) {
      lockElements.remove()
    }
  } catch (error) {
    const lockElements = document.querySelector('.auth0-lock-container')
    if (lockElements) {
      lockElements.remove()
    }

    yield put(AuthenticationActionCreators.StoreSignInFailure.create(error))
  }
}

// let msalInstance
function* processSignInAADB2C() {
  //no-op
}
function* processSignIn() {
  if (CONSTANTS.AUTH_PROVIDER === 'AUTH0') {
    yield call(processSignInAuth0)
  } else if (CONSTANTS.AUTH_PROVIDER === 'AADB2C') {
    yield call(processSignInAADB2C)
  }
}

const clearCookies = () => {
  const cookies = document.cookie.split('; ')
  for (let c = 0; c < cookies.length; c++) {
    const d = window.location.hostname.split('.')
    while (d.length > 0) {
      const cookieBase =
        encodeURIComponent(cookies[c].split(';')[0].split('=')[0]) +
        '=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=' +
        d.join('.') +
        ' ;path='
      const p = window.location.pathname.split('/')
      document.cookie = cookieBase + '/'
      while (p.length > 0) {
        document.cookie = cookieBase + p.join('/')
        p.pop()
      }
      d.shift()
    }
  }
}

function* processSignOut(processAttempt = 0) {
  try {
    isProcessingSignOut = true
    if (CONSTANTS.AUTH_PROVIDER === 'AADB2C') {
      const existingAuthCreds = yield select(getAuthCredentials)
      const processMsalLogout = () => {
        return new Promise((resolve) => {
          const accounts = msalInstance?.getAllAccounts()
          if (accounts?.length) {
            msalInstance
              ?.logout({
                account: accounts[0],
                idTokenHint: existingAuthCreds.idToken,
              })
              .then(resolve)
              .catch(resolve)
          }
          resolve('')
        })
      }
      yield call(processMsalLogout)
    }
    yield put(ReportingActionCreators.StoreClearAllReportingData.create())
    yield put(ConfigurationActionCreators.StoreClearAllConfiguration.create())
    yield put(ProfileActionCreators.StoreClearAllProfileData.create())
    yield put(CredentialsActionCreators.StoreClearAllCredentials.create())
    yield put(AuthenticationActionCreators.StoreSignOut.create())
    yield put(AuthenticationActionCreators.ClearAllStates.create())
    yield call([Amplify.Auth, Amplify.Auth.signOut])
    AWSConfig.update({
      credentials: null,
    })
    let isWaitingForStoreClear = true
    while (isWaitingForStoreClear) {
      const currentUserCredentials = yield select(getAuthCredentials)
      if (!currentUserCredentials.idToken) {
        isWaitingForStoreClear = false
      } else {
        yield delay(500)
      }
    }
    // yield call(purgePersistor)
    // yield call([localforage, localforage.dropInstance], { name: 'imageStore' })

    //clear data stores
    const { credentialStore, imageStore, localforage } = getStorage()
    if (credentialStore) {
      yield call([credentialStore, credentialStore.clear])
    }
    if (imageStore) {
      yield call([imageStore, imageStore.clear])
    }
    if (localforage) {
      yield call([localforage, localforage.clear])
    }

    //clear cookies
    clearCookies()

    //clear local storage
    if (window.localStorage.clear && window.localStorage.clear) {
      window.localStorage.clear()
    }
    if (CONSTANTS.AUTH_PROVIDER === 'AUTH0' || CONSTANTS.AUTH_PROVIDER === 'AADB2C') {
      //reload
      window.location.reload()
    }
  } catch (e) {
    console.error(e)
    if (processAttempt < 3) {
      yield delay(500)
      yield call(processSignOut, processAttempt + 1)
    } else {
      window.location.reload()
    }
  }
}

function* processHideLock() {
  try {
    const isLockVisible = yield select(getIsLockVisible)
    if (isLockVisible === true && lock) {
      yield call(lock.hide)
    }
    yield put(AuthenticationActionCreators.StoreSignInHideLock.create())
    const lockElements = document.querySelector('.auth0-lock-container')
    if (lockElements) {
      lockElements.remove()
    }
  } catch (e) {
    // do nothing as lock is already hidden
  }
}

// Saga triggers
function* watchAuthenticationSagas() {
  yield takeLeading(ActionCreators.SagaSignIn.type, processSignIn)
  yield takeLeading(ActionCreators.SagaSignOut.type, processSignOut)
  yield takeLeading(ActionCreators.SagaHideLock.type, processHideLock)
  yield takeLeading(ActionCreators.SagaRefreshApiCredentials.type, processRefreshAPICredentials)
  yield takeLeading(ActionCreators.SagaLogUserlogin.type, logUserlogin)
  // yield takeLeading(ActionCreators.SagaHeapRefreshUpdate.type, setHeapUserInfo)
  yield null
}

// Saga hooks
export default function* authenticationSagas() {
  yield all([fork(watchAuthenticationSagas)])
}
