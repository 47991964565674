import { SyncOutlined } from '@ant-design/icons'
import { Button, Result } from 'antd'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { ActionCreators } from 'src/redux/sagas/authentication'
import { getAuthentication } from 'src/redux/store/authentication'
import { getAuthCredentials } from 'src/redux/store/credentials'
import verifyTokenSync from 'src/utils/token'
import CONSTANTS from 'src/utils/constants'
import Cookies from 'js-cookie'

// const mapDispatchToProps = {
//   signIn: ActionCreators.SagaSignIn.create,
//   hideLock: ActionCreators.SagaHideLock.create,
// }

const AuthenticationContainer = () => {
  const authCredentials = useSelector(getAuthCredentials)
  const authentication = useSelector(getAuthentication)
  const [redirect, setRedirect] = useState(false)
  const dispatch = useDispatch()

  useEffect(() => {
    if (CONSTANTS.AUTH_PROVIDER === 'AUTH0') {
      const hideLock = () => {
        const localContainer = document.querySelector('.auth0-lock-container')
        if (localContainer) localContainer.remove()
        dispatch(ActionCreators.SagaSignIn.create(undefined))
      }
      if (authCredentials.nonce && authCredentials.idToken) {
        const tokenDetails = verifyTokenSync(authCredentials)
        if (!tokenDetails.isValid) {
          setRedirect(false)
          if (authentication.isLockVisible === false) {
            hideLock()
          }
        } else {
          setRedirect(true)
        }
      } else {
        if (authentication.isLockVisible === false) {
          hideLock()
        }
      }
      return () => {
        dispatch(ActionCreators.SagaHideLock.create())
      }
    } else {
      //no-op for b2c
    }
  }, [authentication.isLockVisible, authCredentials, dispatch])

  const { isLockVisible, isSignedIn, error } = authentication
  const hasError = !isLockVisible && !isSignedIn && error
  const code = error?.code ?? 'Unknown Error'
  const offline = code === 'sync'

  if (hasError) {
    return (
      <div className='center-xy'>
        <Result
          status={500}
          title={offline ? 'Connection Offline' : code}
          subTitle={offline ? 'An error occurred when fetching client data, your connection might be offline' : error?.message ?? ''}
          extra={
            <Button
              ghost
              type='primary'
              icon={<SyncOutlined />}
              onClick={() => {
                dispatch(ActionCreators.SagaSignOut.create())
                window.localStorage.clear()
                window.location.reload()
              }}
            >
              Reload
            </Button>
          }
        />
      </div>
    )
  }

  if (redirect === true) {
    return <Redirect to='/' />
  }
  return CONSTANTS.AUTH_PROVIDER === 'AUTH0' ? (
    <div
      onClick={() => {
        Cookies.set('kenai-auth-provider', 'AADB2C')
        window.location.reload()
      }}
      style={{ position: 'absolute', color: 'rgba(76, 183, 91, 0)', zIndex: 9999999, bottom: 0 }}
    >
      +
    </div>
  ) : null
}

export default AuthenticationContainer
