import { Space } from 'antd'
import React from 'react'

interface ToolbarProps {
  left?: React.ReactNode
  right?: React.ReactNode
  className?: string
}

const Toolbar: React.FC<ToolbarProps> = ({ left = [], right = [], children, className = '', ...props }) => (
  <div className={'flex flex-wrap justify-between mb-2 ' + className} {...props}>
    <Space>{left}</Space>
    <Space>{children || right}</Space>
  </div>
)

export default Toolbar
