import { ColumnsSelector } from './table-columns-selector'
import TableColumnFilterIcon from './table-column-filter-icon'
import TableColumnResizeableTitle from './table-column-resizeable-title'
import TableColumnFilter, { filterColumnOptions } from './table-column-filter'
import TableColumnSearch, { searchColumnOptions } from './table-column-search'
import TableColumnStringTitle from './table-column-string-title'

export {
  ColumnsSelector,
  TableColumnFilterIcon,
  TableColumnResizeableTitle,
  TableColumnSearch,
  TableColumnStringTitle,
  TableColumnFilter,
  filterColumnOptions,
  searchColumnOptions,
}

const Column = {
  Selector: ColumnsSelector,
  FilterIcon: TableColumnFilterIcon,
  ResizeableTitle: TableColumnResizeableTitle,
  Search: TableColumnSearch,
  StringTitle: TableColumnStringTitle,
}

export default Column
