//node
import { Button, Table } from 'antd'
import React, { useState } from 'react'
import Fieldset from 'src/components/fieldset'
import Page from 'src/components/page'

const cardData = [
  {
    property: 'title',
    subtitle: 'Title of the the card',
    type: 'string',
    default: '-',
  },
  {
    property: 'cta',
    subtitle: 'Call To Action aligned on the right side of title',
    type: 'string | ReactNode',
    default: '-',
  },
  {
    property: 'children',
    subtitle: 'Card content body',
    type: 'string | ReactNode',
    default: '-',
  },
]
const titleData = [
  {
    property: 'title | children',
    subtitle: 'Title of the the page',
    type: 'string',
    default: '-',
  },
  {
    property: 'cta',
    subtitle: 'Call To Action aligned on the right side of title',
    type: 'string | ReactNode',
    default: '-',
  },
]

const apiColumns = [
  {
    title: 'Property',
    dataIndex: 'property',
    key: 'property',
    width: 200,
    render: (value) => <b>{value}</b>,
  },
  {
    title: 'Description',
    dataIndex: 'subtitle',
    key: 'subtitle',
  },
  {
    title: 'Type',
    dataIndex: 'type',
    key: 'type',
    width: 200,
    render: (value) => <span style={{ color: '#c41d7f' }}>{value}</span>,
  },
  {
    title: 'Default',
    dataIndex: 'default',
    key: 'default',
  },
]

const ExampleCards = () => {
  const [edit, setEdit] = useState(false)
  return (
    <>
      <Page title='Cards'>
        <p>Cards used in pages</p>
        <br />
        <Fieldset.Title>Examples</Fieldset.Title>
        <Fieldset title='Card Title' extra={<Button type='primary'>Card CTA</Button>} subtitle='This is my card subtitle'>
          CARD CONTENT
        </Fieldset>
        <Fieldset
          // className={edit ? '' : 'opacity-25'}
          title='Card Title'
          extra={
            edit ? (
              [
                <Button type='primary' key='cancel' onClick={() => setEdit(false)}>
                  cancel
                </Button>,
                <Button type='primary' key='save' onClick={() => setEdit(false)}>
                  save
                </Button>,
              ]
            ) : (
              <Button type='primary' onClick={() => setEdit(true)}>
                edit
              </Button>
            )
          }
          subtitle='This is my card subtitle'
        >
          CARD CONTENT
        </Fieldset>
      </Page>

      <Page title='API'>
        <h3>Card</h3>
        <Table dataSource={cardData} columns={apiColumns} bordered pagination={false} scroll={{ x: '100vw' }} />
        <br />
        <h3>Card Title</h3>
        <Table dataSource={titleData} columns={apiColumns} bordered pagination={false} scroll={{ x: '100vw' }} />
      </Page>
    </>
  )
}

export default ExampleCards
