import { faUserEdit, faUserMinus } from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Descriptions, List, Typography } from 'antd'
import React from 'react'
import { useIntl } from 'react-intl'
import useHierarchyEnabledFlags from 'src/hooks/use-hierarchy-enabled-flags'
import { buildUpsertLists } from '../../utils/upload'
import { returnFullName } from './import-utils'

interface UpdateListProps {
  list: ReturnType<typeof buildUpsertLists>['updateList']
}

const checkIfChanged = (a, b, isOld) => {
  if (a !== b) return isOld ? 'text-error' : 'text-info'
  return ''
}

const UpdateList = ({ list }: UpdateListProps) => {
  const intl = useIntl()
  const { employeeRouteType, employeeAlternatePersonalIdNr } = useHierarchyEnabledFlags()
  const translatedRouteTypeSingular = intl.formatMessage({ id: `common.${employeeRouteType}-singular` })
  const Item = ({ compare, old, ...props }) => {
    return (
      <Typography>
        <span className={checkIfChanged(props.fullName, compare.fullName, old)}>{props.fullName}</span> <br />
        <span className={checkIfChanged(props.personalIdentificationNr, compare.personalIdentificationNr, old)}>
          {props.personalIdentificationNr}
        </span>{' '}
        <br />
        {employeeAlternatePersonalIdNr && (
          <>
            <span className={checkIfChanged(props.alternatePersonalIdNr, compare.alternatePersonalIdNr, old)}>
              {props.alternatePersonalIdNr}
            </span>{' '}
            <br />
          </>
        )}
        <span className={checkIfChanged(props.organizationIdValue, compare.organizationIdValue, old)}>{props.organizationIdValue}</span>{' '}
        <br />
        {props.phoneNumber && (
          <>
            <span className={checkIfChanged(props.phoneNumber, compare.phoneNumber, old)}>{props.phoneNumber}</span>
            <br />
          </>
        )}
        {props.email && (
          <>
            <span className={checkIfChanged(props.email, compare.email, old)}>{props.email}</span>
            <br />
          </>
        )}
      </Typography>
    )
  }

  return (
    <List
      bordered={false}
      {...(list.length > 3 && {
        pagination: {
          pageSize: 3,
        },
      })}
      dataSource={list}
      renderItem={(entries) => {
        const newEntry = {
          fullName: returnFullName(entries.new),
          ...entries.new,
        }
        const oldEntry = {
          fullName: returnFullName(entries.old),
          ...entries.old,
        }
        return (
          <>
            <Descriptions bordered column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 1, xs: 1 }} layout='vertical'>
              <Descriptions.Item
                label={
                  <span>
                    <FontAwesomeIcon icon={faUserMinus} className='mr-1 text-error' /> {intl.formatMessage({ id: 'common.old' })}{' '}
                    {translatedRouteTypeSingular}
                    data
                  </span>
                }
              >
                <Item {...oldEntry} compare={newEntry} old />
              </Descriptions.Item>
              <Descriptions.Item
                label={
                  <span>
                    <FontAwesomeIcon icon={faUserEdit} className='mr-1 text-info' /> {intl.formatMessage({ id: 'common.new' })}{' '}
                    {translatedRouteTypeSingular}
                    data
                  </span>
                }
              >
                <Item {...newEntry} compare={oldEntry} old={false} />
              </Descriptions.Item>
            </Descriptions>
            <br />
          </>
        )
      }}
    />
  )
}

export default UpdateList
