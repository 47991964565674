import AccessManager from 'src/utils/AccessManager'
import {
  getTableNameFromAwsExports,
  getCredentialedDDBDocumentClientForRead,
  getCredentialedDDBDocumentClientForMutate,
  getCredentialedS3,
} from 'src/api/utils'
import { initialState as getInitialInductionState } from 'src/redux/store/settings/general-account/induction-store'
import aws_exports from 'src/aws-exports'

const retrieveInductionConfig = async () => {
  return new Promise((resolve, reject) => {
    try {
      if (AccessManager.selectedHierarchy.hierarchyStructure === 'ALL') throw new Error('No hierarchy selected')

      const params = {
        RequestItems: {
          [getTableNameFromAwsExports('mdConfigInduction')]: {
            Keys: [{ EntityHierarchy: AccessManager.selectedHierarchy.hierarchyStructure }],
          },
        },
      }
      const ddbDocumentClient = getCredentialedDDBDocumentClientForRead(AccessManager.selectedHierarchy.hierarchyStructure)
      ddbDocumentClient.batchGet(params, async (err, data) => {
        if (err) {
          console.error(err)
          reject(err)
        } else {
          const initialState = { ...getInitialInductionState() }
          if (data && data.Responses) {
            for (const key in data.Responses) {
              if (data.Responses.hasOwnProperty(key)) {
                const response = data.Responses[key]

                if (key.indexOf('mdConfigInduction') > -1) {
                  if (response.length > 0) {
                    const responseValues = response[0]
                    for (const valueKey in initialState) {
                      if (initialState.hasOwnProperty(valueKey) && responseValues.hasOwnProperty(valueKey)) {
                        initialState[valueKey] = responseValues[valueKey]
                      }
                    }
                  }
                }
              }
            }
          }
          resolve(initialState)
        }
      })
    } catch (e) {
      console.error(e)
      reject(e)
    }
  })
}

const saveInductionVideo = async (inductionVideoProcessing, newVersion, videoProcessingChannel) => {
  return new Promise((resolve, reject) => {
    const params = {
      Body: new Buffer(inductionVideoProcessing.localVideoSrc.replace(/^data:video\/\w+;base64,/, ''), 'base64'),
      Bucket: aws_exports.aws_entity_binary_content_s3_bucket,
      Key: `inductionvideos/${AccessManager.selectedHierarchy.hierarchyStructure.replace(
        /#/g,
        '_'
      )}_${videoProcessingChannel}_${newVersion}.mp4`,
      Metadata: {
        EntityHierarchy: AccessManager.selectedHierarchy.hierarchyStructure,
        duration: `${inductionVideoProcessing.metadata.duration}`,
        version: `${newVersion}`,
      },
      Tagging: `EntityHierarchy=${AccessManager.selectedHierarchy.hierarchyStructure.replace(/#/g, '/')}`,
    }
    const s3 = getCredentialedS3(AccessManager.selectedHierarchy.hierarchyStructure)
    s3.putObject(params, function (err) {
      if (err) {
        reject(err)
      } else {
        resolve()
      }
    })
  })
}

const copyInductionVideo = async (videoVersionCopyOp, videoProcessingChannel) => {
  return new Promise((resolve, reject) => {
    const imageCopyOperation = {
      Bucket: aws_exports.aws_entity_binary_content_s3_bucket,
      CopySource: `/${
        aws_exports.aws_entity_binary_content_s3_bucket
      }/inductionvideos/${AccessManager.selectedHierarchy.hierarchyStructure.replace(/#/g, '_')}_${videoProcessingChannel}_${
        videoVersionCopyOp.fromVersion
      }.mp4`,
      Key: `inductionvideos/${AccessManager.selectedHierarchy.hierarchyStructure.replace(/#/g, '_')}_${videoProcessingChannel}_${
        videoVersionCopyOp.toVersion
      }.mp4`,
      Metadata: {
        EntityHierarchy: AccessManager.selectedHierarchy.hierarchyStructure,
        duration: `${videoVersionCopyOp.duration}`,
        version: `${videoVersionCopyOp.toVersion}`,
      },
      MetadataDirective: 'COPY',
    }
    const s3 = getCredentialedS3(AccessManager.selectedHierarchy.hierarchyStructure)
    s3.copyObject(imageCopyOperation, function (err) {
      if (err) {
        reject(err)
      } else {
        resolve()
      }
    })
  })
}

const saveConfigInduction = (inductionSubmission) => {
  return new Promise((resolve, reject) => {
    const inductionParams = {
      Item: {
        EntityHierarchy: AccessManager.selectedHierarchy.hierarchyStructure,
        lmt: Date.now(),
        inductionEnabled: inductionSubmission.inductionEnabled,
        channels: inductionSubmission.channels,
      },
      TableName: getTableNameFromAwsExports('mdConfigInduction'),
      ReturnValues: 'NONE',
    }
    const ddbDocumentClient = getCredentialedDDBDocumentClientForMutate(AccessManager.selectedHierarchy.hierarchyStructure)
    ddbDocumentClient.put(inductionParams, function (err) {
      if (err) {
        reject(err)
      } else {
        resolve(inductionParams.Item)
      }
    })
  })
}

export const inductionAPI = {
  retrieveInductionConfig,
  saveInductionVideo,
  copyInductionVideo,
  saveConfigInduction,
}
