import {
  all,
  fork,
  takeLeading,
  call,
  put,
  select,
  delay,
  take, // call, take, select
} from 'redux-saga/effects'
import { ActionCreators as EmployeesActionCreators, getEmployeesDirectory } from 'src/redux/store/employees/directory-store'
import ActionCreator from '../saga-action-creator'
import API from 'src/api'
import { EmployeeDetails } from 'src/redux/store/employees/types/directory'
import AccessManager from 'src/utils/AccessManager'
import { unionBy, cloneDeep } from 'lodash'
import { ActionCreators as AuthenticationActionCreators } from 'src/redux/sagas/authentication'

// Action Creators
export const ActionCreators = {
  SagaRetrieveEmployeesDirectoryData: new ActionCreator<'SagaRetrieveEmployeesDirectoryData', void>('SagaRetrieveEmployeesDirectoryData'),
  SagaAddSingleEmployee: new ActionCreator<'SagaAddSingleEmployee', EmployeeDetails>('SagaAddSingleEmployee'),
  SagaEditEmployee: new ActionCreator<'SagaEditEmployee', EmployeeDetails>('SagaEditEmployee'),
  SagaUpsertMultipleEmployees: new ActionCreator<'SagaUpsertMultipleEmployees', EmployeeDetails[]>('SagaUpsertMultipleEmployees'),
  SagaUpdateMultipleEmployees: new ActionCreator<'SagaUpdateMultipleEmployees', EmployeeDetails[]>('SagaUpdateMultipleEmployees'),
  SagaDeleteEmployees: new ActionCreator<'SagaDeleteEmployees', EmployeeDetails[]>('SagaDeleteEmployees'),
  SagaDeleteEntityEmployees: new ActionCreator<'SagaDeleteEntityEmployees', string>('SagaDeleteEntityEmployees'),
  // SagaResetSyncStatus: new ActionCreator<'SagaResetSyncStatus', HostKeyRef>('SagaResetSyncStatus'),
}

const AWSHelper = new API.AWSHelpers()

function* processRetrieveEmployeesDirectoryData(action: typeof ActionCreators.SagaRetrieveEmployeesDirectoryData) {
  try {
    yield put(EmployeesActionCreators.StoreSetLoading.create(true))
    yield put(AuthenticationActionCreators.SagaRefreshApiCredentials.create())
    yield take(AuthenticationActionCreators.SagaApiCredentialsRefreshUpdate.type)
    const entityHierarchy = AccessManager.selectedHierarchy.hierarchyStructure
    const employeesDirectory: EmployeeDetails[] = yield call(
      [AWSHelper, AWSHelper.employeeApi.directory.getEmployeesDirectory],
      entityHierarchy
    )
    yield delay(0)
    yield put(EmployeesActionCreators.StoreSetEmployeeDirectoryData.create(employeesDirectory))
    yield put(EmployeesActionCreators.StoreSetLoading.create(false))
    if (action.callback) action.callback({ status: 'success' })
  } catch (e) {
    yield put(EmployeesActionCreators.StoreSetLoading.create(false))
    if (action.callback) action.callback({ status: 'failed' })
    if (e) console.error(e)
  }
}

function* processSagaAddSingleEmployee(action: typeof ActionCreators.SagaAddSingleEmployee) {
  try {
    yield put(AuthenticationActionCreators.SagaRefreshApiCredentials.create())
    yield take(AuthenticationActionCreators.SagaApiCredentialsRefreshUpdate.type)
    const EntityHierarchy = AccessManager.selectedHierarchy.hierarchyStructure
    const singleHost = {
      ...action.payload,
      EntityHierarchy,
    }
    const result = yield call([AWSHelper, AWSHelper.employeeApi.directory.addEmployees], EntityHierarchy, [singleHost])
    if (result.key === 'OPERATIONS_PROCESSED') {
      const employeesDirectoryData: EmployeeDetails[] = yield select(getEmployeesDirectory)
      const unionEmployees = unionBy([singleHost], employeesDirectoryData, 'personalIdentificationNr')
      yield put(EmployeesActionCreators.StoreSetEmployeeDirectoryData.create(unionEmployees))
      if (action.callback) action.callback({ status: 'success' })
    } else {
      throw new Error(`Error while adding employee: ${result.key}`)
    }
  } catch (e) {
    if (e) console.error(e)
    if (action.callback) action.callback({ status: 'failed' })
  }
}

const removeEmpty = (item) => {
  Object.keys(item).forEach((key) => {
    if (item[key] === undefined || item[key] === null || item[key] === '') {
      delete item[key]
    }
  })
  return item
}
function* processSagaUpsertMultipleEmployees(action: typeof ActionCreators.SagaUpsertMultipleEmployees) {
  try {
    yield put(AuthenticationActionCreators.SagaRefreshApiCredentials.create())
    yield take(AuthenticationActionCreators.SagaApiCredentialsRefreshUpdate.type)
    const EntityHierarchy = AccessManager.selectedHierarchy.hierarchyStructure
    const employeesToAdd = action.payload.map((employee) =>
      removeEmpty({
        ...cloneDeep(employee),
        EntityHierarchy,
      })
    )
    console.log('upserting before api')
    const result = yield call([AWSHelper, AWSHelper.employeeApi.directory.addEmployees], EntityHierarchy, employeesToAdd)
    if (result.key === 'OPERATIONS_PROCESSED') {
      const employeesDirectoryData: EmployeeDetails[] = yield select(getEmployeesDirectory)
      const unionEmployees = unionBy(employeesToAdd, employeesDirectoryData, 'personalIdentificationNr')
      console.log(unionEmployees)
      yield put(EmployeesActionCreators.StoreSetEmployeeDirectoryData.create(unionEmployees))
      if (action.callback) action.callback({ status: 'success', isAsyncProcessing: result.isAsyncProcessing })
    } else {
      throw new Error(`Error while upserting multiple employees: ${result.key}`)
    }
  } catch (e) {
    if (e) {
      if (action.callback) action.callback({ status: 'failed', message: e.message })
      console.error(e)
    } else if (action.callback) action.callback({ status: 'failed', message: 'Failed to add employee' })
  }
}

function* processSagaEditEmployee(action: typeof ActionCreators.SagaEditEmployee) {
  try {
    yield put(AuthenticationActionCreators.SagaRefreshApiCredentials.create())
    yield take(AuthenticationActionCreators.SagaApiCredentialsRefreshUpdate.type)
    const EntityHierarchy = AccessManager.selectedHierarchy.hierarchyStructure
    const singleHost = {
      ...action.payload,
      EntityHierarchy,
    }
    const result = yield call([AWSHelper, AWSHelper.employeeApi.directory.addEmployees], EntityHierarchy, [singleHost])
    if (result.key === 'OPERATIONS_PROCESSED') {
      const employeesDirectoryData: EmployeeDetails[] = yield select(getEmployeesDirectory)
      const employeeChangeIndex = employeesDirectoryData.findIndex(
        (employee) =>
          employee.EntityHierarchy === singleHost.EntityHierarchy &&
          employee.personalIdentificationNr === singleHost.personalIdentificationNr
      )
      const unionEmployees = cloneDeep(employeesDirectoryData)
      unionEmployees[employeeChangeIndex] = {
        ...singleHost,
      }

      yield put(EmployeesActionCreators.StoreSetEmployeeDirectoryData.create(unionEmployees))
      if (action.callback) action.callback({ status: 'success', isAsyncProcessing: result.isAsyncProcessing })
    } else {
      throw new Error(`Error while editing employee: ${result.key}`)
    }
  } catch (e) {
    if (e) {
      console.error(e)
      if (action.callback) action.callback({ status: 'failed', message: e.message })
    } else {
      if (action.callback) action.callback({ status: 'failed', message: 'Failed to edit employee' })
    }
  }
}

function* processSagaDeleteEmployees(action: typeof ActionCreators.SagaDeleteEmployees) {
  try {
    yield put(AuthenticationActionCreators.SagaRefreshApiCredentials.create())
    yield take(AuthenticationActionCreators.SagaApiCredentialsRefreshUpdate.type)
    const EntityHierarchy = AccessManager.selectedHierarchy.hierarchyStructure
    console.log(action.payload)
    const employeesToDelete = action.payload.map(({ personalIdentificationNr }) => ({
      EntityHierarchy,
      personalIdentificationNr,
    }))
    const result = yield call([AWSHelper, AWSHelper.employeeApi.directory.deleteEmployees], EntityHierarchy, employeesToDelete)
    if (result.key === 'OPERATIONS_PROCESSED') {
      const employeesDirectoryData = yield select(getEmployeesDirectory)
      const unionEmployees = cloneDeep(employeesDirectoryData).filter(
        (employee) =>
          employeesToDelete.findIndex(
            (employeeToDelete) =>
              employeeToDelete.EntityHierarchy === employee.EntityHierarchy &&
              employeeToDelete.personalIdentificationNr === employee.personalIdentificationNr
          ) === -1
      )
      yield put(EmployeesActionCreators.StoreSetEmployeeDirectoryData.create(unionEmployees))
      if (action.callback) action.callback({ status: 'success', isAsyncProcessing: result.isAsyncProcessing })
    } else {
      throw new Error(`Error while deleting employee: ${result.key}`)
    }
  } catch (e) {
    if (e) {
      console.error(e)
      if (action.callback) action.callback({ status: 'failed', message: e.message })
    } else {
      if (action.callback) action.callback({ status: 'failed', message: 'Failed to delete employee' })
    }
  }
}

function* processSagaDeleteEntityEmployees(action: typeof ActionCreators.SagaDeleteEntityEmployees) {
  try {
    yield put(AuthenticationActionCreators.SagaRefreshApiCredentials.create())
    yield take(AuthenticationActionCreators.SagaApiCredentialsRefreshUpdate.type)
    const EntityHierarchy = AccessManager.selectedHierarchy.hierarchyStructure
    const result = yield call([AWSHelper, AWSHelper.employeeApi.directory.deleteEntityEmployeesList], EntityHierarchy, action.payload)
    if (result.key === 'OPERATIONS_PROCESSED') {
      yield put(EmployeesActionCreators.StoreSetEmployeeDirectoryData.create([]))
      if (action.callback) action.callback({ status: 'success' })
    } else {
      throw new Error(`Error while deleting employee list: ${result.key}`)
    }
  } catch (e) {
    if (e) {
      console.error(e)
      if (action.callback) action.callback({ status: 'failed', message: e.message })
    } else {
      if (action.callback) action.callback({ status: 'failed', message: 'Failed to delete employee' })
    }
  }
}

// function* processSagaResetSyncStatus(action) {
//   try {
//     yield put(AuthenticationActionCreators.SagaRefreshApiCredentials.create())
//     yield take(AuthenticationActionCreators.SagaApiCredentialsRefreshUpdate.type)
//     const { EntityHierarchy, personalIdentificationNr } = action.payload
//     const result = yield call([AWSHelper, AWSHelper.employeeApi.directory.resetHostSyncStatus], EntityHierarchy, personalIdentificationNr)
//     if (result.key === 'OPERATIONS_PROCESSED') {
//       const employeesDirectoryData: EmployeeDetails[] = yield select(getEmployeesDirectory)
//       const employeeChangeIndex = employeesDirectoryData.findIndex(
//         employee => employee.EntityHierarchy === EntityHierarchy && employee.personalIdentificationNr === personalIdentificationNr
//       )
//       const unionEmployees = cloneDeep(employeesDirectoryData)
//       unionEmployees[employeeChangeIndex].dataSource = HOST_DATA_SOURCE.ADHOSTSYNC
//       yield put(EmployeesActionCreators.StoreSetEmployeeDirectoryData.create(unionEmployees))
//       if(action.callback) action.callback({ status: 'success' })
//     } else {
//       throw new Error(`Error while resetting sync status: ${result.key}`)
//     }
//   } catch (e) {
//     if (e) {
//       console.error(e)
//       if(action.callback) action.callback({ status: 'failed', message: e.message })
//     } else {
//       if(action.callback) action.callback({ status: 'failed', message: 'Failed to reset sync status' })
//     }
//   }
// }

// Saga triggers
function* watchReportingSagas() {
  yield takeLeading(ActionCreators.SagaRetrieveEmployeesDirectoryData.type, processRetrieveEmployeesDirectoryData)
  yield takeLeading(ActionCreators.SagaAddSingleEmployee.type, processSagaAddSingleEmployee)
  yield takeLeading(ActionCreators.SagaUpsertMultipleEmployees.type, processSagaUpsertMultipleEmployees)
  yield takeLeading(ActionCreators.SagaEditEmployee.type, processSagaEditEmployee)
  yield takeLeading(ActionCreators.SagaDeleteEmployees.type, processSagaDeleteEmployees)
  yield takeLeading(ActionCreators.SagaDeleteEntityEmployees.type, processSagaDeleteEntityEmployees)
  // yield takeLeading(ActionCreators.SagaResetSyncStatus.type, processSagaResetSyncStatus)

  yield null
}

// Saga hooks
export default function* reportingSagas() {
  yield all([fork(watchReportingSagas)])
}
