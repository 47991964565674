import { VisitorLogItem } from 'src/redux/store/visitors/types/log'
import { EmployeeLogItemEventPair } from 'src/redux/store/employees/types/employee-log'
import { ParkingLogSearchableEventSet } from 'src/typings/kenai/parking/log'

export const getVisitorOutOfScopeForEvac = (record: VisitorLogItem, data: VisitorLogItem[]) => {
  const targetHierarchy = record.EntityHierarchy
  const targetProfileId = record.ProfileId
  const targetTimeStamp = record.evtTimeStamp

  for (let i = 0; i < data.length; i++) {
    const entry = data[i]
    if (
      entry.EntityHierarchy === targetHierarchy &&
      entry.ProfileId === targetProfileId &&
      typeof targetTimeStamp !== 'string' &&
      entry.evtTimeStamp > targetTimeStamp
    ) {
      return true
    }
  }

  return false
}

export const getParkingOutOfScopeForEvac = (record: ParkingLogSearchableEventSet, data: ParkingLogSearchableEventSet[]) => {
  const targetHierarchy = record.EntityHierarchy
  const targetProfileId = record.evtTimeStampUniqueAttributeValue
  const targetTimeStamp = record.evtTimeStamp

  for (let i = 0; i < data.length; i++) {
    const entry = data[i]
    if (
      entry.EntityHierarchy === targetHierarchy &&
      entry.evtTimeStampUniqueAttributeValue === targetProfileId &&
      typeof targetTimeStamp !== 'string' &&
      entry.evtTimeStamp > targetTimeStamp
    ) {
      return true
    }
  }

  return false
}

export const getEmployeeOutOfScopeForEvac = (clockInEvent: EmployeeLogItemEventPair['clockInEvent'], data: EmployeeLogItemEventPair[]) => {
  const targetHierarchy = clockInEvent.EntityHierarchy
  const targetProfileId = clockInEvent.ProfileId
  const targetTimeStamp = clockInEvent.evtTimeStamp

  for (let i = 0; i < data.length; i++) {
    const entry = data[i].clockInEvent
    if (entry.EntityHierarchy === targetHierarchy && entry.ProfileId === targetProfileId && entry.evtTimeStamp > targetTimeStamp) {
      return true
    }
  }

  return false
}
