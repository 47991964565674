import { ConfigValueFlowType, EntityHierarchyConfig, TenantFlags } from 'src/typings/kenai/configuration/entity-hierarchy'
import { FLOW_NATURES_ENUM } from 'src/typings/kenai/enums'
import AccessManager from 'src/utils/AccessManager'
import { getFullySpecifiedEntityFlagValue, getHierarchicalEntityFlagValue } from 'src/utils/entityFlagProcessor'
import slugify from 'slugify'

type AccountRole = typeof AccessManager.selectedHierarchy.role

export const tenantFlagHelper = {
  getVisitorLogEnabled: (tenantFlag: TenantFlags) => {
    return (
      tenantFlag?.hasOwnProperty('availableIdentities') &&
      tenantFlag.availableIdentities.findIndex((identity) => identity.flowType === FLOW_NATURES_ENUM.VISITOR) > -1
    )
  },

  getHostTagsEnabled: (ehConfig: EntityHierarchyConfig, EntityHierarchy: string) => {
    return getFullySpecifiedEntityFlagValue(ehConfig, 'hosts.dashboard.hostTagsEnabled', EntityHierarchy)
  },

  getInviteLogEnabled: (tenantFlag: TenantFlags) => {
    return tenantFlag?.hasOwnProperty('inviteConfig') &&
      tenantFlag.inviteConfig.dashboard &&
      tenantFlag.inviteConfig.dashboard.logVisible === true
      ? true
      : false
  },

  getInviteCreateEnabled: (tenantFlag: TenantFlags) => {
    return tenantFlag?.hasOwnProperty('inviteConfig') &&
      tenantFlag.inviteConfig.dashboard &&
      tenantFlag.inviteConfig.dashboard.createEnabled === true
      ? true
      : false
  },

  getInvitecCreateWithOptionalEmailEnabled: (tenantFlag: TenantFlags) => {
    return tenantFlag?.hasOwnProperty('inviteConfig') &&
      tenantFlag.inviteConfig.dashboard &&
      tenantFlag.inviteConfig.dashboard.createWithOptionalEmailEnabled === true
      ? true
      : false
  },

  getInviteLogResendEnabled: (tenantFlag: TenantFlags) => {
    return tenantFlag?.hasOwnProperty('inviteConfig') &&
      tenantFlag.inviteConfig.dashboard &&
      tenantFlag.inviteConfig.dashboard.logResendEnabled === true
      ? true
      : false
  },

  getInviteLogDeleteEnabled: (tenantFlag: TenantFlags) => {
    return tenantFlag?.hasOwnProperty('inviteConfig') &&
      tenantFlag.inviteConfig.dashboard &&
      tenantFlag.inviteConfig.dashboard.logDeleteEnabled === true
      ? true
      : false
  },

  getInviteInternalNoteCreatable: (tenantFlag: TenantFlags) => {
    return tenantFlag?.hasOwnProperty('inviteConfig') &&
      tenantFlag.inviteConfig.dashboard &&
      tenantFlag.inviteConfig.dashboard.internalNoteCreatable === true
      ? true
      : false
  },

  getInviteInternalNoteEditable: (tenantFlag: TenantFlags) => {
    return tenantFlag?.hasOwnProperty('inviteConfig') &&
      tenantFlag.inviteConfig.dashboard &&
      tenantFlag.inviteConfig.dashboard.internalNoteEditable === true
      ? true
      : false
  },

  getEmployeeLogEnabled: (tenantFlag, flowTypes: ConfigValueFlowType[], role: AccountRole) => {
    if (role === 'HOST') return false //not relevant to host role
    let enabled = false
    if (
      tenantFlag?.hasOwnProperty('availableIdentities') &&
      tenantFlag.availableIdentities.findIndex((identity) => identity.flowType === FLOW_NATURES_ENUM.WORKER) > -1
    ) {
      enabled = true
    }
    if (flowTypes) {
      const flows = flowTypes.filter((flowType) => flowType.flowNature === FLOW_NATURES_ENUM.WORKER)
      for (const flowType of flows) {
        if (
          flowType.flowVariables.hasOwnProperty('assistedCheckIn') ||
          flowType.flowVariables.hasOwnProperty('clockIn') ||
          flowType.flowVariables.hasOwnProperty('clockOut')
        ) {
          enabled = true
          break
        }
      }
    }
    return enabled
  },

  getEmployeeScreeningLogEnabled: (tenantFlag, flowTypes: ConfigValueFlowType[], role: AccountRole) => {
    if (role === 'HOST') return false //not relevant to host role
    let enabled = false
    if (tenantFlag?.hasOwnProperty('preScreening') && tenantFlag?.preScreening.active === true) {
      enabled = true
    }
    if (flowTypes) {
      const flows = flowTypes.filter((flowType) => flowType.flowNature === FLOW_NATURES_ENUM.WORKER)
      for (const flowType of flows) {
        if (flowType.flowVariables.hasOwnProperty('preScreening')) {
          enabled = true
          break
        }
      }
    }
    return enabled
  },

  getEmployeeRouteType: (tenantFlag, role: AccountRole) => {
    if (role === 'HOST') return false //not relevant to host role
    return tenantFlag?.employees?.fieldType?.routeType ?? 'employees'
  },

  getParkingLogEnabled: (tenantFlag: TenantFlags, role: AccountRole) => {
    return role !== 'HOST' && tenantFlag?.hasOwnProperty('parking') && tenantFlag.parking.dashboard && tenantFlag.parking.dashboard.log //not relevant to host role
  },

  getParkingBookingLogEnabled: (tenantFlag: TenantFlags, role: AccountRole) => {
    return (
      role !== 'HOST' && tenantFlag?.hasOwnProperty('parking') && tenantFlag.parking.dashboard && tenantFlag.parking.dashboard.bookingLog
    )
  },

  getParkingBookingProvisioningEnabled: (tenantFlag: TenantFlags, role: AccountRole) => {
    return (
      role !== 'HOST' && tenantFlag?.hasOwnProperty('parking') && tenantFlag.parking.dashboard && tenantFlag.parking.dashboard.provisioning
    )
  },

  getHostMaintenanceEnabled: (profile: AccountProfile, ehConfig: EntityHierarchyConfig, EntityHierarchy: string, role: AccountRole) => {
    return (
      role !== 'HOST' &&
      profile.amHostMaintenanceEnabled === true &&
      getFullySpecifiedEntityFlagValue(ehConfig, 'hosts.dashboard.hostMaintenanceEnabled', EntityHierarchy)
    )
  },

  getParkingAnalyticsEnabled: (profile: AccountProfile, ehConfig: EntityHierarchyConfig, EntityHierarchy: string, role: AccountRole) => {
    return role !== 'HOST' && getFullySpecifiedEntityFlagValue(ehConfig, 'parking.dashboard.analytics', EntityHierarchy)
  },

  getEmployeeMaintenanceEnabled: (profile: AccountProfile, ehConfig: EntityHierarchyConfig, EntityHierarchy: string) => {
    return (
      profile.amEmployeeMaintenanceEnabled === true &&
      getFullySpecifiedEntityFlagValue(ehConfig, 'employees.dashboard.employeeMaintenanceEnabled', EntityHierarchy)
    )
  },
  getEmployeeLineManagerMaintenanceEnabled: (ehConfig: EntityHierarchyConfig, EntityHierarchy: string) => {
    return getFullySpecifiedEntityFlagValue(ehConfig, 'employees.dashboard.lineManagerMaintenanceEnabled', EntityHierarchy)
  },
  getEmployeeTagsEnabled: (ehConfig: EntityHierarchyConfig, EntityHierarchy: string) => {
    return getFullySpecifiedEntityFlagValue(ehConfig, 'employees.dashboard.employeeTagsEnabled', EntityHierarchy)
  },
  getEmployeeAlternatePersonalIdNrEnabledMaintenanceEnabled: (ehConfig: EntityHierarchyConfig, EntityHierarchy: string) => {
    return getFullySpecifiedEntityFlagValue(ehConfig, 'employees.dashboard.alternatePersonalIdNrEnabled', EntityHierarchy)
  },
  getEmployeeDuplicateMetaFieldsAllowed: (ehConfig: EntityHierarchyConfig, EntityHierarchy: string, flowId: string) => {
    const duplicateAllowedFlows = getHierarchicalEntityFlagValue(ehConfig, 'employees.duplicateMetaFieldsAllowedForFlow', EntityHierarchy)
    return duplicateAllowedFlows?.includes?.(flowId) ?? (duplicateAllowedFlows || false)
  },
  getEmployeeBadgeLinkingEnabled: (ehConfig: EntityHierarchyConfig, EntityHierarchy: string) => {
    return getFullySpecifiedEntityFlagValue(ehConfig, 'employees.dashboard.badgeLinkingEnabled', EntityHierarchy)
  },
  getMultiTenantEvacTriggerEnabled: (tenantFlag: TenantFlags) => {
    return tenantFlag.evacuation?.multiTenantEvacTriggerEnabled || false
  },
  getEvacuationAudience: (tenantFlag: TenantFlags) => {
    if (!tenantFlag.evacuation?.audienceEnablement) {
      return {
        onSiteVisitorsEvacEnabled: true,
        onSiteEmployeeEvacEnabled: true,
        onSiteParkingEvacEnabled: false,
        employeeDirectoryEvacEnabled: false,
      }
    }

    return {
      onSiteVisitorsEvacEnabled: tenantFlag.evacuation.audienceEnablement?.onSiteVisitorsEvacEnabled ?? false,
      onSiteEmployeeEvacEnabled: tenantFlag.evacuation.audienceEnablement?.onSiteEmployeeEvacEnabled ?? false,
      onSiteParkingEvacEnabled: tenantFlag.evacuation.audienceEnablement?.onSiteParkingEvacEnabled ?? false,
      employeeDirectoryEvacEnabled: tenantFlag.evacuation.audienceEnablement?.employeeDirectoryEvacEnabled ?? false,
    }
  },
  getDAFEnabled: (ehConfig: EntityHierarchyConfig, EntityHierarchy: string) => {
    return getHierarchicalEntityFlagValue(ehConfig, 'documentsAndFiles.enabled', EntityHierarchy)
  },
  getInductionLogEnabled: (ehConfig: EntityHierarchyConfig, EntityHierarchy: string) => {
    return getHierarchicalEntityFlagValue(ehConfig, 'induction.kioskConfigAvailable', EntityHierarchy)
  },
  getInductionCrossLocationStatusEnabled: (ehConfig: EntityHierarchyConfig, EntityHierarchy: string) => {
    return getHierarchicalEntityFlagValue(ehConfig, 'induction.crossLocationInductionStatus', EntityHierarchy)
  },
  getParkingRouteTypeOverride: (ehConfig: EntityHierarchyConfig, EntityHierarchy: string) => {
    return parkingRouteConfig(getHierarchicalEntityFlagValue(ehConfig, 'parking.routeTypeOverride', EntityHierarchy))
  },
}

export const parkingRouteConfig = (routeTypeOverride?: { singularLabel: string; pluralLabel: string }) => {
  const { singularLabel, pluralLabel } = routeTypeOverride || { singularLabel: 'Parking', pluralLabel: 'Parkings' }

  return {
    singularLabel: singularLabel,
    pluralLabel: pluralLabel,
    path: slugify(singularLabel).toLowerCase(),
  }
}
