import { API as AmplifyAPI } from 'aws-amplify'
import { EmployeeLogItem } from 'src/redux/store/employees/types/employee-log'
import AccessManager from 'src/utils/AccessManager'
import getEventTiming from 'src/utils/getEventTiming'
import { getCredentialedDDBDocumentClientForMutate, getTableNameFromAwsExports } from '../utils'
import { getRecursiveHierarchyList } from 'src/utils/getRecursiveHierarchyList'

interface ResolveType<T> {
  key: T
}

const employeeLogAPI = {
  getEmployeeLogEvents(beginTime, endTime, entityHierarchy, entityHierarchyConfiguration, stronglyConsistent, ProjectionExpression?) {
    return (async () => {
      // PRE-MIGRATION START
      const hierarchies: string[] = getRecursiveHierarchyList(entityHierarchyConfiguration, entityHierarchy)
      // PRE-MIGRATION END
      const options = {
        headers: {
          Authorization: `Bearer ${AccessManager.idToken}`,
        },
        body: {
          query: {
            hierarchies,
            beginTime,
            endTime,
            stronglyConsistent,
            ProjectionExpression,
          },
        },
      }
      try {
        let response = await AmplifyAPI.post('workerLogOperations', '/employeeLogRetrieval', options)
        if (response.key === 'ASYNC_RESPONSE_PAYLOAD') {
          const asyncResponsePayload = await fetch(response.s3PresignedURL)
          response = await asyncResponsePayload.json()
        }
        return response.logItems
      } catch (e) {
        console.error('[API] employee api returned an error', e)
        return []
      }
    })()
  },

  getSelectedEmployeeLogs(itemsToRetrieve) {
    return (async () => {
      try {
        const options = {
          headers: {
            Authorization: `Bearer ${AccessManager.idToken}`,
          },
          body: {
            query: {
              getItemKeys: itemsToRetrieve,
            },
          },
        }
        try {
          let response = await AmplifyAPI.post('workerLogOperations', '/employeeLogRetrieval', options)
          if (response.key === 'ASYNC_RESPONSE_PAYLOAD') {
            const asyncResponsePayload = await fetch(response.s3PresignedURL)
            response = await asyncResponsePayload.json()
          }
          return response.logItems
        } catch (e) {
          console.error('[API] employee api returned an error', e)
          return []
        }
      } catch (e) {
        console.error('[API] employee api returned an error', e)
        return []
      }
    })()
  },

  acceptEmployeeLogImage(EntityHierarchy, evtTimeStampProfileID, dateToReturn) {
    return new Promise<ResolveType<'IMAGE_ACCEPTED' | 'UNDEFINED_RESPONSES'>>((resolve, reject) => {
      const options = {
        headers: {
          Authorization: `Bearer ${AccessManager.idToken}`,
        },
        body: {
          EntityHierarchy,
          evtTimeStampProfileID,
          dateToReturn,
        },
      }
      AmplifyAPI.post('workerBiometricOperations', '/acceptLogImage', options)
        .then((data) => {
          resolve(data)
        })
        .catch((err) => {
          if (err && err.response && err.response.data) {
            reject(err.response)
          } else {
            reject(err)
          }
        })
    })
  },

  removeEmployeeReferenceImage(EntityHierarchy, evtTimeStampProfileID, ProfileId, s3ImageKey, dateToReturn) {
    return new Promise<'REF_IMAGE_REMOVED' | 'UNDEFINED_RESPONSES'>((resolve, reject) => {
      const options = {
        headers: {
          Authorization: `Bearer ${AccessManager.idToken}`,
        },
        body: {
          EntityHierarchy,
          evtTimeStampProfileID,
          ProfileId,
          s3ImageKey,
          dateToReturn,
        },
      }
      AmplifyAPI.post('workerBiometricOperations', '/removeReferenceImage', options)
        .then((data) => {
          resolve(data)
        })
        .catch((err) => {
          if (err && err.response && err.response.data) {
            reject(err.response)
          } else {
            reject(err)
          }
        })
    })
  },

  rejectEmployeeLogImage(workerDetails) {
    return new Promise<{
      key: 'REJECT_SUCCESS' | 'UNDEFINED_RESPONSES'
      rejectedLogs: EmployeeLogItem[]
    }>((resolve, reject) => {
      const options = {
        headers: {
          Authorization: `Bearer ${AccessManager.idToken}`,
        },
        body: {
          workerDetails,
        },
      }
      AmplifyAPI.post('workerBiometricOperations', '/rejectLogImage', options)
        .then((data) => {
          resolve(data)
        })
        .catch((err) => {
          if (err && err.response && err.response.data) {
            reject(err.response)
          } else {
            reject(err)
          }
        })
    })
  },

  correctionConvertClockInToClockOut(correctionData) {
    const dynamodbDocumentClient = getCredentialedDDBDocumentClientForMutate(correctionData.clockInEvent.EntityHierarchy)
    let correctionsArray = correctionData.clockInEvent.correctionsHistory
    if (!correctionsArray) {
      correctionsArray = []
    }
    correctionsArray.push({
      correctionType: correctionData.correctionType,
      correctionComment: correctionData.correctionComment,
      user: AccessManager.tokenDetails?.sub || '',
      email: AccessManager.tokenDetails?.email || '',
      correctionTiming: getEventTiming(),
      preCorrectionValues: {
        eventType: 'CLOCK_IN',
        lmt: correctionData.clockInEvent.lmt,
      },
    })
    const tdWorkerEvents_update_params = {
      TableName: getTableNameFromAwsExports('tdWorkerEvents'),
      Key: {
        EntityHierarchy: correctionData.clockInEvent.EntityHierarchy,
        evtTimeStampProfileID: correctionData.clockInEvent.evtTimeStampProfileID,
      },
      UpdateExpression: 'set #a = :a, #b = :b, #c = :c',
      ExpressionAttributeNames: { '#a': 'eventType', '#b': 'lmt', '#c': 'correctionsHistory' },
      ExpressionAttributeValues: {
        ':a': 'CLOCK_OUT',
        ':b': Date.now(),
        ':c': correctionsArray,
      },
      ReturnValues: 'NONE',
      ConditionExpression: 'attribute_exists(EntityHierarchy) AND attribute_exists(evtTimeStampProfileID)',
    }
    return dynamodbDocumentClient.update(tdWorkerEvents_update_params).promise()
  },

  correctionAdjustClockInTime(correctionData) {
    const dynamodbDocumentClient = getCredentialedDDBDocumentClientForMutate(correctionData.clockInEvent.EntityHierarchy)
    let correctionsArray = correctionData.clockInEvent.correctionsHistory
    if (!correctionsArray) {
      correctionsArray = []
    }
    correctionsArray.push({
      correctionType: correctionData.correctionType,
      correctionComment: correctionData.correctionComment,
      user: AccessManager.tokenDetails?.sub || '',
      email: AccessManager.tokenDetails?.email || '',
      correctionTiming: getEventTiming(),
      preCorrectionValues: {
        evtTimeStamp: correctionData.clockInEvent.evtTimeStamp,
        evtTimeStampProfileID: correctionData.clockInEvent.evtTimeStampProfileID,
        lmt: correctionData.clockInEvent.lmt,
      },
    })
    const newEvtTimeStamp = correctionData.clockInMoment.valueOf()
    const correctedItem = {
      ...correctionData.clockInEvent,
      evtTimeStamp: newEvtTimeStamp,
      evtTimeStampProfileID: `${newEvtTimeStamp}#${correctionData.clockInEvent.ProfileId}`,
      lmt: Date.now(),
      personalIdentificationNrevtTimeStamp: `${correctionData.clockInEvent.workerFullDetails.personalIdentificationNr}#${newEvtTimeStamp}`,
      ProfileIDevtTimeStamp: `${correctionData.clockInEvent.ProfileId}#${newEvtTimeStamp}`,
      correctionsHistory: correctionsArray,
    }

    const tdWorkerEvents_correctionTransaction_params = {
      TransactItems: [
        {
          Delete: {
            TableName: getTableNameFromAwsExports('tdWorkerEvents'),
            Key: {
              EntityHierarchy: correctionData.clockInEvent.EntityHierarchy,
              evtTimeStampProfileID: correctionData.clockInEvent.evtTimeStampProfileID,
            },
          },
        },
        {
          Put: {
            TableName: getTableNameFromAwsExports('tdWorkerEvents'),
            Item: correctedItem,
            ConditionExpression: 'attribute_not_exists(EntityHierarchy) and attribute_not_exists(evtTimeStampProfileID)',
          },
        },
      ],
    }
    return dynamodbDocumentClient
      .transactWrite(tdWorkerEvents_correctionTransaction_params)
      .promise()
      .then((transactionResult) => ({ transactionResult, correctedItem }))
  },

  async correctionAdjustClockOutTime(correctionData) {
    const dynamodbDocumentClient = getCredentialedDDBDocumentClientForMutate(correctionData.clockOutEvent.EntityHierarchy)
    let correctionsArray = correctionData.clockOutEvent.correctionsHistory
    if (!correctionsArray) {
      correctionsArray = []
    }
    correctionsArray.push({
      correctionType: correctionData.correctionType,
      correctionComment: correctionData.correctionComment,
      user: AccessManager.tokenDetails?.sub || '',
      email: AccessManager.tokenDetails?.email || '',
      correctionTiming: getEventTiming(),
      preCorrectionValues: {
        evtTimeStamp: correctionData.clockOutEvent.evtTimeStamp,
        evtTimeStampProfileID: correctionData.clockOutEvent.evtTimeStampProfileID,
        lmt: correctionData.clockOutEvent.lmt,
      },
    })
    const newEvtTimeStamp = correctionData.clockOutMoment.valueOf()
    const correctedItem = {
      ...correctionData.clockOutEvent,
      evtTimeStamp: newEvtTimeStamp,
      evtTimeStampProfileID: `${newEvtTimeStamp}#${correctionData.clockOutEvent.ProfileId}`,
      lmt: Date.now(),
      personalIdentificationNrevtTimeStamp: `${correctionData.clockOutEvent.workerFullDetails.personalIdentificationNr}#${newEvtTimeStamp}`,
      ProfileIDevtTimeStamp: `${correctionData.clockOutEvent.ProfileId}#${newEvtTimeStamp}`,
      correctionsHistory: correctionsArray,
    }

    const tdWorkerEvents_correctionTransaction_params = {
      TransactItems: [
        {
          Delete: {
            TableName: getTableNameFromAwsExports('tdWorkerEvents'),
            Key: {
              EntityHierarchy: correctionData.clockOutEvent.EntityHierarchy,
              evtTimeStampProfileID: correctionData.clockOutEvent.evtTimeStampProfileID,
            },
          },
        },
        {
          Put: {
            TableName: getTableNameFromAwsExports('tdWorkerEvents'),
            Item: correctedItem,
            ConditionExpression: 'attribute_not_exists(EntityHierarchy) and attribute_not_exists(evtTimeStampProfileID)',
          },
        },
      ],
    }
    return dynamodbDocumentClient
      .transactWrite(tdWorkerEvents_correctionTransaction_params)
      .promise()
      .then((transactionResult) => ({
        transactionResult,
        correctedItem,
      }))
  },

  correctionCreateClockOut(correctionData) {
    const dynamodbDocumentClient = getCredentialedDDBDocumentClientForMutate(correctionData.clockInEvent.EntityHierarchy)
    const correctionsArray: any[] = []
    correctionsArray.push({
      correctionType: correctionData.correctionType,
      correctionComment: correctionData.correctionComment,
      user: AccessManager.tokenDetails?.sub || '',
      email: AccessManager.tokenDetails?.email || '',
      correctionTiming: getEventTiming(),
      preCorrectionValues: {
        evtTimeStamp: correctionData.clockInEvent.evtTimeStamp,
        evtTimeStampProfileID: correctionData.clockInEvent.evtTimeStampProfileID,
        lmt: correctionData.clockInEvent.lmt,
      },
    })
    const newEvtTimeStamp = correctionData.clockOutMoment.valueOf()
    const correctedItem = {
      ...correctionData.clockInEvent,
      evtTimeStamp: newEvtTimeStamp,
      evtTimeStampProfileID: `${newEvtTimeStamp}#${correctionData.clockInEvent.ProfileId}`,
      lmt: Date.now(),
      personalIdentificationNrevtTimeStamp: `${correctionData.clockInEvent.workerFullDetails.personalIdentificationNr}#${newEvtTimeStamp}`,
      ProfileIDevtTimeStamp: `${correctionData.clockInEvent.ProfileId}#${newEvtTimeStamp}`,
      correctionsHistory: correctionsArray,
      eventType: 'CLOCK_OUT',
      actualEvtTimeStamp: newEvtTimeStamp,
    }

    const tdWorkerEvents_correctionTransaction_params = {
      TransactItems: [
        {
          Put: {
            TableName: getTableNameFromAwsExports('tdWorkerEvents'),
            Item: correctedItem,
            ConditionExpression: 'attribute_not_exists(EntityHierarchy) and attribute_not_exists(evtTimeStampProfileID)',
          },
        },
      ],
    }

    return dynamodbDocumentClient
      .transactWrite(tdWorkerEvents_correctionTransaction_params)
      .promise()
      .then((transactionResult) => ({ transactionResult, correctedItem }))
  },

  correctionCreateNewTimePair(correctionData) {
    const dynamodbDocumentClient = getCredentialedDDBDocumentClientForMutate(correctionData.clockInEvent.EntityHierarchy)
    const correctionsArray: any[] = []
    correctionsArray.push({
      correctionType: correctionData.correctionType,
      correctionComment: correctionData.correctionComment,
      user: AccessManager.tokenDetails?.sub || '',
      email: AccessManager.tokenDetails?.email || '',
      correctionTiming: getEventTiming(),
      preCorrectionValues: {
        fromLogEvtTimeStampProfileID: correctionData.clockInEvent.evtTimeStampProfileID,
      },
    })
    const newEvtTimeStampIn = correctionData.clockInMoment.valueOf()
    const newClockIn = {
      ...correctionData.clockInEvent,
      evtTimeStamp: newEvtTimeStampIn,
      evtTimeStampProfileID: `${newEvtTimeStampIn}#${correctionData.clockInEvent.ProfileId}`,
      lmt: Date.now(),
      personalIdentificationNrevtTimeStamp: `${correctionData.clockInEvent.workerFullDetails.personalIdentificationNr}#${newEvtTimeStampIn}`,
      ProfileIDevtTimeStamp: `${correctionData.clockInEvent.ProfileId}#${newEvtTimeStampIn}`,
      correctionsHistory: correctionsArray,
      eventType: 'CLOCK_IN',
      actualEvtTimeStamp: newEvtTimeStampIn,
    }
    const newEvtTimeStampOut = correctionData.clockOutMoment.valueOf()
    const newClockOut = {
      ...(correctionData.clockOutEvent
        ? {
            ...correctionData.clockOutEvent,
          }
        : {
            ...correctionData.clockInEvent,
          }),
      evtTimeStamp: newEvtTimeStampOut,
      evtTimeStampProfileID: `${newEvtTimeStampOut}#${correctionData.clockInEvent.ProfileId}`,
      lmt: Date.now(),
      personalIdentificationNrevtTimeStamp: `${correctionData.clockInEvent.workerFullDetails.personalIdentificationNr}#${newEvtTimeStampOut}`,
      ProfileIDevtTimeStamp: `${correctionData.clockInEvent.ProfileId}#${newEvtTimeStampOut}`,
      correctionsHistory: correctionsArray,
      eventType: 'CLOCK_OUT',
      actualEvtTimeStamp: newEvtTimeStampOut,
    }

    const tdWorkerEvents_correctionTransaction_params = {
      TransactItems: [
        {
          Put: {
            TableName: getTableNameFromAwsExports('tdWorkerEvents'),
            Item: newClockIn,
            ConditionExpression: 'attribute_not_exists(EntityHierarchy) and attribute_not_exists(evtTimeStampProfileID)',
          },
        },
        {
          Put: {
            TableName: getTableNameFromAwsExports('tdWorkerEvents'),
            Item: newClockOut,
            ConditionExpression: 'attribute_not_exists(EntityHierarchy) and attribute_not_exists(evtTimeStampProfileID)',
          },
        },
      ],
    }
    return dynamodbDocumentClient
      .transactWrite(tdWorkerEvents_correctionTransaction_params)
      .promise()
      .then((transactionResult) => ({ transactionResult, newClockIn, newClockOut }))
  },

  correctionAddComment(correctionData) {
    const dynamodbDocumentClient = getCredentialedDDBDocumentClientForMutate(correctionData.selectedEvent.EntityHierarchy)
    let correctionsArray = [...new Set(correctionData?.selectedEvent?.correctionsHistory)]
    if (!correctionsArray) {
      correctionsArray = []
    }
    correctionsArray.push({
      correctionType: correctionData.correctionType,
      correctionComment: correctionData.correctionComment,
      user: AccessManager.tokenDetails?.sub || '',
      email: AccessManager.tokenDetails?.email || '',
      correctionTiming: getEventTiming(),
      preCorrectionValues: {
        lmt: correctionData.selectedEvent.lmt,
      },
    })
    const tdWorkerEvents_update_params = {
      TableName: getTableNameFromAwsExports('tdWorkerEvents'),
      Key: {
        EntityHierarchy: correctionData.selectedEvent.EntityHierarchy,
        evtTimeStampProfileID: correctionData.selectedEvent.evtTimeStampProfileID,
      },
      UpdateExpression: 'set #a = :a, #b = :b',
      ExpressionAttributeNames: { '#a': 'lmt', '#b': 'correctionsHistory' },
      ExpressionAttributeValues: {
        ':a': Date.now(),
        ':b': correctionsArray,
      },
      ReturnValues: 'NONE',
      ConditionExpression: 'attribute_exists(EntityHierarchy) AND attribute_exists(evtTimeStampProfileID)',
    }
    return dynamodbDocumentClient.update(tdWorkerEvents_update_params).promise()
  },

  linkBadge(body: { EntityHierarchy: string; evtTimeStampProfileID: string; badgeNumber: string }) {
    return new Promise<{
      key: 'REJECT_SUCCESS' | 'UNDEFINED_RESPONSES' | 'BADGE_NR_UPDATED'
    }>((resolve, reject) => {
      const options = {
        headers: {
          Authorization: `Bearer ${AccessManager.idToken}`,
        },
        body,
      }
      AmplifyAPI.post('workerLogOperations', '/captureWorkerBadgeNumber', options)
        .then((data) => {
          resolve(data)
        })
        .catch((err) => {
          if (err && err.response && err.response.data) {
            reject(err.response)
          } else {
            reject(err)
          }
        })
    })
  },
}

export default employeeLogAPI
