import React from 'react'

const withDefaults = <P, DP>(component: React.ComponentType<P>, defaultProps: DP) => {
  type Props = Partial<DP> & Omit<P, keyof DP>
  ;(component.defaultProps as any) = defaultProps
  //   @ts-ignore
  return component as React.ComponentType<Props>
}

export const defaultProps = withDefaults

export default withDefaults
