import { notification } from 'antd'
import _, { forOwn, groupBy, orderBy } from 'lodash'
import { EmployeeLogItem, EmployeeLogItemEventPair } from 'src/redux/store/employees/types/employee-log'
import resolveEmployeeCheckInFieldTexts from './resolveEmployeeCheckInFieldTexts'
import { translate } from 'src/i18n'
import { EntityHierarchyConfig } from 'src/typings/kenai/configuration/entity-hierarchy'

export function timePairedEmployeeLogEvents(employeeLogData: EmployeeLogItem[]) {
  const timePairs: EmployeeLogItemEventPair[] = []
  let pendingEntriesCount = 0
  forOwn(groupBy(orderBy(employeeLogData, ['evtTimeStamp'], ['asc']), 'ProfileId'), (ProfileIdEvents) => {
    const getInitialTimePair = (): Partial<EmployeeLogItemEventPair> => {
      return {
        referenceTimeStamp: undefined,
        clockInEvent: undefined,
        clockOutEvent: undefined,
      }
    }
    let currentTimePair = getInitialTimePair()
    ProfileIdEvents.forEach((entry, index) => {
      if (entry.biometricProcessingStatus !== 'PENDING') {
        if (currentTimePair.referenceTimeStamp === undefined) {
          //assign current time value
          currentTimePair.referenceTimeStamp = entry.evtTimeStamp
          if (entry.eventType === 'CLOCK_IN') {
            resolveEmployeeCheckInFieldTexts(entry as any) //resolve check in field texts (legacy)
            currentTimePair.clockInEvent = entry as any
          } else {
            //return this so that we can flag it as hidden (due to missing clock in)
            currentTimePair.clockOutEvent = entry as any
          }
        } else {
          if (entry.eventType === 'CLOCK_IN' && currentTimePair.clockInEvent !== undefined && currentTimePair.clockOutEvent === undefined) {
            //we have 2 consective clocks - log the first incomplete one and create a new time pair
            timePairs.push(currentTimePair as EmployeeLogItemEventPair)
            currentTimePair = getInitialTimePair()
            currentTimePair.referenceTimeStamp = entry.evtTimeStamp
            resolveEmployeeCheckInFieldTexts(entry as any) //resolve check in field texts (legacy)
            currentTimePair.clockInEvent = entry as any
          } else if (
            entry.eventType === 'CLOCK_IN' &&
            currentTimePair.clockInEvent !== undefined &&
            currentTimePair.clockOutEvent !== undefined
          ) {
            //we have a new clock in with an existing completed time pair
            //we will never get this as completed pairs will be logged and timepair initialised as soon as a completed pair is created
            console.log('Invalid scenario', entry)
            ProfileIdEvents.splice(index, 1)

            return false
            // throw new Error("Invalid scenario")
          } else if (
            entry.eventType === 'CLOCK_OUT' &&
            currentTimePair.clockInEvent !== undefined &&
            currentTimePair.clockOutEvent === undefined
          ) {
            //we have a clock out following a clock in
            //check whether they are on the same day
            //we do not need to even check constraints here as a clock out wont be allowed to be created if constraints arnet passed
            currentTimePair.clockOutEvent = entry as any
            timePairs.push(currentTimePair as EmployeeLogItemEventPair)
            currentTimePair = getInitialTimePair()
          }
        }
      } else {
        if (!entry.biometricProcessingStatus.contais('_N/A')) {
          pendingEntriesCount++
        }
      }
    })
    if (currentTimePair.referenceTimeStamp !== undefined && currentTimePair.clockInEvent !== undefined) {
      //for now just hide logs that have missing clocked in entries
      timePairs.push(currentTimePair as EmployeeLogItemEventPair)
      currentTimePair = getInitialTimePair()
    } else if (
      currentTimePair.clockInEvent === undefined &&
      currentTimePair.clockOutEvent !== undefined &&
      !currentTimePair.clockOutEvent.biometricProcessingStatus.contais('_N/A')
    ) {
      pendingEntriesCount++
    }
  })
  if (pendingEntriesCount > 0) {
    notification.warn({
      message: 'Biometric Pending',
      description: `${pendingEntriesCount} employee log ${
        pendingEntriesCount > 1 ? 'entries are' : 'entry is'
      } pending biometric analysis and ${pendingEntriesCount > 1 ? 'are' : 'is'} not visible`,
    })
  }
  return timePairs
}

export function updateEmployeeLogItem(oldData: EmployeeLogItemEventPair[], newData: EmployeeLogItem[]) {
  const clonedWorkerLogItems = _.cloneDeep(oldData)
  const newValues = _.map(clonedWorkerLogItems, (entry) => {
    if (entry.clockInEvent) {
      const newEvent = _.assign(
        entry.clockInEvent,
        _.find(newData, {
          evtTimeStampProfileID: entry.clockInEvent.evtTimeStampProfileID,
        })
      )
      resolveEmployeeCheckInFieldTexts(newEvent) //resolve check in field texts (legacy)
      entry.clockInEvent = newEvent
    }
    if (entry.clockOutEvent) {
      entry.clockOutEvent = _.assign(
        entry.clockOutEvent,
        _.find(newData, {
          evtTimeStampProfileID: entry.clockOutEvent.evtTimeStampProfileID,
        })
      )
    }
    return entry
  })

  return newValues
}

export function getSelectedEmployeeLogs(employeeLogData: EmployeeLogItem[], beginTime: number) {
  //split logs into previous and current day
  let previousDayLogs = employeeLogData.filter((log) => log.evtTimeStamp < beginTime) //we are only interested in previous day clock ins - maybe we need to check clock_outs as well
  let selectedLogs = employeeLogData.filter((log) => log.evtTimeStamp >= beginTime) //all logs for selection range incl clock ins and outs
  //sort previous day logs descending so that we get the latest clock in first
  previousDayLogs = orderBy(previousDayLogs, ['evtTimeStamp'], ['desc'])
  const mappedProfileIdClockIns = {} //map of processed profile ID's for clock ins
  const mappedProfileIdsClockOuts = {} //map of processed profile ID's for clock ins
  const previousDayClockIns = previousDayLogs.filter((previousDayLog) => {
    if (previousDayLog.eventType === 'CLOCK_OUT') {
      if (!mappedProfileIdsClockOuts.hasOwnProperty(previousDayLog.ProfileId)) mappedProfileIdsClockOuts[previousDayLog.ProfileId] = true
      return false //we only want to return clock-ins
    } else {
      if (!mappedProfileIdsClockOuts.hasOwnProperty(previousDayLog.ProfileId)) {
        //we don't have a clock out for this clock in yet
        if (!mappedProfileIdClockIns.hasOwnProperty(previousDayLog.ProfileId)) {
          //we don't have a clock in for this clock in yet
          mappedProfileIdClockIns[previousDayLog.ProfileId] = true //map the profile ID as found
          return true //a clock in on the previous day without a subsequent clock out on the previous day and up to now not mapped yet
        } else return false //already have a clock in
      }
      return false //already clocked out - don't include
    }
  })
  selectedLogs = selectedLogs.concat(previousDayClockIns) //add the previous day clock ins that don't have matching time pairs
  //sort descending
  selectedLogs = orderBy(selectedLogs, ['evtTimeStamp'], ['desc'])

  return selectedLogs
}

const resolveCustomFieldTexts = (customFields, EntityHierarchy, entityHierarchyConfiguration: EntityHierarchyConfig) => {
  const resolvedTexts: string[] = []
  Object.keys(customFields).forEach((fieldId) => {
    const fieldDefinition = entityHierarchyConfiguration.additionalConfigs.customFields[EntityHierarchy]?.find(
      (customField) => customField.fieldId === fieldId
    )
    if (fieldDefinition) {
      const value: any = customFields[fieldId]
      if (fieldDefinition.inputType === 'staticDropDownListSingleSelect') {
        const staticItem = fieldDefinition?.staticDropDownListItems?.find((staticItem) => staticItem.itemId === value)
        if (staticItem) {
          resolvedTexts.push(translate(staticItem.optionTextId))
        }
      } else if (fieldDefinition.inputType === 'staticDropDownListMultiSelect') {
        const parts = value.split(';')
        const resolvedParts: any = []
        parts.forEach((part) => {
          const staticItem = fieldDefinition?.staticDropDownListItems?.find((staticItem) => staticItem.itemId === part)
          if (staticItem) {
            resolvedParts.push(translate(staticItem.optionTextId))
          }
        })
        if (resolvedParts.length) {
          resolvedTexts.push(...resolvedParts)
        }
      } else if (value) {
        resolvedTexts.push(value) //text or number
      }
    }
  })

  return resolvedTexts.map((text) => (text && text.toLowerCase ? text.toLowerCase() : ''))
}
export function generateEmployeeLogPairSearchString(
  employeeLogData: EmployeeLogItemEventPair[],
  entityHierarchyConfiguration: EntityHierarchyConfig
) {
  const entrySearchString = (clockInEvent: typeof employeeLogData[0]['clockInEvent']) => {
    //TODO - add resolved values from clockInEvent.customFields
    try {
      const tags = clockInEvent?.workerFullDetails?.tags?.map?.((tag) => tag.toLowerCase()) || []
      const ss = [
        clockInEvent?.workerFullDetails?.firstName?.toLowerCase() ?? '',
        clockInEvent?.workerFullDetails?.lastName?.toLowerCase() ?? '',
        clockInEvent?.workerFullDetails?.phoneNumber?.toLowerCase() ?? '',
        clockInEvent?.workerFullDetails?.email?.toLowerCase() ?? '',
        clockInEvent?.workerFullDetails?.personalIdentificationNr?.toLowerCase() ?? '',
        clockInEvent?.workerFullDetails?.organizationIdValue?.toLowerCase() ?? '',
        clockInEvent?.additionalFlowDataCapturingData?.toLowerCase() ?? '',
        ...tags,
        ...(clockInEvent?.customFields
          ? [...resolveCustomFieldTexts(clockInEvent.customFields, clockInEvent.EntityHierarchy, entityHierarchyConfiguration)]
          : []),
      ]
      return ss.join(' ')
    } catch (e) {
      console.error(e)
      return ' '
    }
  }
  return employeeLogData.map((entry) => ({
    searchString: entrySearchString(entry.clockInEvent),
    ...entry,
  }))
}
