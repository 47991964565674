import Amplify from 'aws-amplify'
import awsmobile from '../aws-exports'

export default function configureAWS() {
  Amplify.configure({
    ...awsmobile,
    Auth: {
      // REQUIRED - Amazon Cognito Region
      region: awsmobile.aws_cognito_region,
      // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
      mandatorySignIn: true,
    },
  })
}
