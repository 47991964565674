import update from 'immutability-helper'
import { createSelector } from 'reselect'
import { RootState } from '../rootReducer'
import { ActionCreator } from '../store-action-creator'
import { InviteLogItem } from './types/invites'

export interface State {
  initialData?: InviteLogItem
}

// Action Creators
export const ActionCreators = {
  StoreSetInitialData: new ActionCreator<'StoreSetInitialData', State['initialData']>('StoreSetInitialData'),
  ClearAllStates: new ActionCreator<'ClearAllStates', void>('ClearAllStates'),
}
export const InviteLogStoreActionCreators = ActionCreators

type Action = typeof ActionCreators[keyof typeof ActionCreators]

export const initialState = (): State => ({
  initialData: undefined,
})

// Reducer
export default function reducer(state: State = initialState(), action: Action) {
  switch (action.type) {
    case ActionCreators.StoreSetInitialData.type:
      return update(state, {
        initialData: { $set: action.payload },
      })
    case ActionCreators.ClearAllStates.type:
      return initialState()
    default:
      return state
  }
}

const getState = (value: keyof State) => (state: RootState) => state.visitors.assistedCheckIn?.[value]

export const getInitialData = createSelector<any, State['initialData'], State['initialData']>([getState('initialData')], (value) => value)
