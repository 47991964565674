import { ApiKeysDataType } from 'src/typings/kenai/api-keys'
import AccessManager from 'src/utils/AccessManager'
import { RequireExactlyOne } from 'type-fest'
import { API as AmplifyAPI } from 'aws-amplify'
import { ApiKeyTypes } from 'src/typings/kenai/types'

type eventKeys = 'KEYS_RETRIEVED' | 'OPERATION_FAILED' | 'OPERATION_PROCESSED' | 'GENERIC_ERROR' | 'INVALID_REQUEST'

export type errorKeys = ''

type APIResponse<T extends Record<string, unknown>, K = any> =
  | (T & {
      key: K
    })
  | undefined

type ResponseBody = RequireExactlyOne<
  {
    error?: errorKeys
    apiKeyItems?: ApiKeysDataType[]
  },
  'error' | 'apiKeyItems'
>

type RESPONSE = APIResponse<ResponseBody, eventKeys>

export async function retrieveApiKeys(EntityHierarchy: string) {
  try {
    return (await AmplifyAPI.post('userOperations', '/retrieveAPIKeys', {
      headers: {
        Authorization: `Bearer ${AccessManager.idToken}`,
      },
      body: {
        query: {
          hierarchies: [EntityHierarchy],
        },
      },
    })) as RESPONSE
  } catch (err) {
    console.error(err)
    return undefined
  }
}

export async function addNewApiKey(body: { keyType: ApiKeyTypes; label: string; EntityHierarchy: string; includedHierarchies: string[] }) {
  try {
    return (await AmplifyAPI.post('userOperations', '/manageAPIKeys', {
      headers: {
        Authorization: `Bearer ${AccessManager.idToken}`,
      },
      body: { ...body, operation: 'GENERATE_NEW' },
    })) as APIResponse<{ updatedLmt: number; apiKeyValue: string; keyId: string }, eventKeys>
  } catch (err) {
    console.error(err)
    return undefined
  }
}

export async function removeApiKey(body: { keyId: string; lmt: number }) {
  try {
    return (await AmplifyAPI.post('userOperations', '/manageAPIKeys', {
      headers: {
        Authorization: `Bearer ${AccessManager.idToken}`,
      },
      body: { ...body, operation: 'DELETE_EXISTING' },
    })) as APIResponse<any, eventKeys>
  } catch (err) {
    console.error(err)
    return undefined
  }
}

export async function updateApiKey(body: { keyId: string; label: string; includedHierarchies: string[]; lmt: number }) {
  try {
    return (await AmplifyAPI.post('userOperations', '/manageAPIKeys', {
      headers: {
        Authorization: `Bearer ${AccessManager.idToken}`,
      },
      body: { ...body, operation: 'UPDATE_EXISTING' },
    })) as APIResponse<{ updatedLmt?: number }, eventKeys>
  } catch (err) {
    console.error(err)
    return undefined
  }
}
