import { Button } from 'antd'
import { push } from 'connected-react-router'
import Nprogress from 'nprogress'
import 'nprogress/nprogress.css'
import React, { lazy, ReactNode, Suspense, useEffect, useRef, useState } from 'react'
import 'react-placeholder/lib/reactPlaceholder.css'
import { useDispatch } from 'react-redux'
import { Route as RouterRoute, RouteProps, Switch } from 'react-router-dom'
import ErrorBoundary from 'src/components/error-boundary'
import ErrorFallback from 'src/components/error-fallback'
import Page from 'src/components/page'
import PageLoader from 'src/components/page/page-loader'
import { useRouter } from 'src/hooks/use-router'
import { ROUTE } from 'src/typings/kenai/dashboard-router'
import useRoutes from './routes/useRoutes'
import { GlobalChannelSagaActionCreators } from 'src/redux/sagas/subscriptions/globalEvent'
import { getGlobalEventSubscriptionCredential } from 'src/redux/store/credentials'
import { ActionCreators as GlobalEventsStoreActionCreators } from 'src/redux/store/dashboard/globalevents'
import useSubscriptionHandler from 'src/hooks/useSubscriptionHandler'
import EvacuationProvider from 'src/components/evacuation/evacuation-provider'
// import SubscriptionHandler from './subscriptionHandler'

const NoMatch = lazy(() => import('src/components/page-error-404'))

const BuggyComponent = () => {
  const [count, setCount] = useState(0)
  if (count === 2) throw new Error('Testing error event')
  return <Button onClick={() => setCount((c) => c + 1)}>[ {count} ] Click me to trigger error</Button>
}

const TestError = () => (
  <Page title='Test Error Boundary'>
    <ErrorBoundary>
      <ErrorFallback message='This is my error message' />
      <BuggyComponent />
    </ErrorBoundary>
  </Page>
)

function Route(props: RouteProps) {
  useEffect(() => {
    Nprogress.done()
    return () => {
      Nprogress.start()
    }
  }, [])

  const path = Array.isArray(props.path) ? props.path.join('/') : props.path

  return (
    <ErrorBoundary
      beforeCapture={
        path
          ? (scope) => {
              scope.setTag('location', path)
            }
          : undefined
      }
    >
      <RouterRoute {...props} />
    </ErrorBoundary>
  )
}

function renderRoute(route: ROUTE): ReactNode {
  if (route.sidebar === false) {
    return <Route key={`/${route.path}`} exact={route.exact} path={`/${route.path}`} component={route.component} />
  } else if (route.children) {
    if (route.component) {
      const routes = [
        <Route key={`/${route.path}`} exact={route.exact} path={`/${route.path}`} component={route.component} />,
        ...route.children.map((childRoute) => renderRoute(childRoute)),
      ]
      return routes
    } else {
      return route.children.map((childRoute) => renderRoute(childRoute))
    }
  } else {
    return <Route key={`/${route.path}`} exact={route.exact} path={`/${route.path}`} component={route.component} />
  }
}

const checkHasExistingRoute = (currentPath: string, route: ROUTE) => {
  if (currentPath === '/test-error') return true

  // Function to compare two paths
  const pathsMatch = (path1: string, path2: string): boolean => {
    const segments1 = path1.split('/').filter(Boolean) // Split and remove empty segments
    const segments2 = path2.split('/').filter(Boolean)

    if (segments1.length !== segments2.length) {
      return false
    }

    return segments1.every((segment, index) => {
      return segment === segments2[index] || segments2[index].startsWith(':')
    })
  }

  const path = currentPath.startsWith('/') ? currentPath.slice(1) : currentPath // Remove prefix "/"
  if (!path || pathsMatch(path, route.path) || pathsMatch(currentPath, route.path)) {
    return true
  }

  const hasRoute: boolean =
    route.children?.some((childRoute) => {
      return checkHasExistingRoute(currentPath, childRoute)
    }) ?? false

  return hasRoute
}

const GlobalEventListener = () => {
  const dispatch = useRef(useDispatch())
  const subscriptionHandler = useSubscriptionHandler({
    topic: 'globalEvents',
    refresh: GlobalChannelSagaActionCreators.SagaRefreshGlobalSubscriptionCredential.create,
    update: GlobalChannelSagaActionCreators.SagaGlobalLiveUpdateHandler.create,
    connect: GlobalEventsStoreActionCreators.ClearErrors.create,
    postUpdate: GlobalEventsStoreActionCreators.ClearErrors.create,
    refreshContextCallback: () => {
      /*
      triger any global channel refreshes e.g. pulling of evacs, 
      however not necassary as if we navigate to visitor log evac will be pulled 
      and if we are on visitor log already the visitor log channel will refresh evacs
      */
    },
    errorCallback: () => {
      dispatch.current(
        GlobalChannelSagaActionCreators.SagaGlobalSubscriptionErrors.create([
          'We could not subscribe to Kenai channels, evacuation mode live updates are disabled',
        ])
      )
    },
    offlineCallback: () => {
      dispatch.current(
        GlobalChannelSagaActionCreators.SagaGlobalSubscriptionErrors.create([
          'We could not subscribe to Kenai channels, evacuation mode live updates are disabled',
        ])
      )
    },
    getSubscriptionCredentials: getGlobalEventSubscriptionCredential,
    shouldSubscribeToUpdates: true,
  })

  return subscriptionHandler
}

const DashboardRouter = () => {
  const routes = useRoutes()
  const router = useRouter()
  const [mounted, setMounted] = useState(false)
  const dispatch = useRef(useDispatch())

  useEffect(() => {
    setMounted(true)
  }, [])

  useEffect(() => {
    if (mounted && window['KENAIFramePath']) {
      const hasExistingRoute = routes.some((route) => checkHasExistingRoute(router.pathname, route))
      if (!hasExistingRoute) {
        dispatch.current(push(window['KENAIFramePath'] ? window['KENAIFramePath'] : '/')) //authenticated - switch to frame path or root
      }
    }
  }, [routes, router.pathname, mounted])

  return (
    <>
      <EvacuationProvider>
        <Suspense fallback={<PageLoader />}>
          <Switch>
            {routes.map((parentRoute) => renderRoute(parentRoute))}
            <Route exact={true} path={`/test-error`} component={TestError} />
            <Route component={NoMatch} />
          </Switch>
        </Suspense>
      </EvacuationProvider>
      <GlobalEventListener />
    </>
  )
}

export default DashboardRouter
