import React, { useState, useEffect } from 'react'
import styled, { keyframes } from 'styled-components'

import { LoadingOutlined } from '@ant-design/icons'
import { useTheme } from 'src/components/theme/ThemeProvider'
import { cssvars } from 'src/styles'
import { ReactComponent as LogoDark } from 'src/visual/image/icon-kenai-bot-light.svg'
import { ReactComponent as LogoLight } from 'src/visual/image/icon-kenai-bot.svg'

import Texty from 'rc-texty'
import 'rc-texty/assets/index.css'
import TweenOne from 'rc-tween-one'

const LoaderText = ({ visible, onEnd }) => {
  const geInterval = (e) => {
    switch (e.index) {
      case 0:
        return 0
      case 1:
        return 150
      case 2:
      case 3:
      case 4:
      case 5:
      case 6:
        return 150 + 450 + (e.index - 2) * 10
      default:
        return 150 + 450 + (e.index - 6) * 150
    }
  }
  const getEnter = (e) => {
    const t = {
      opacity: 0,
      scale: 0.8,
      y: '-100%',
    }
    if (e.index >= 2 && e.index <= 6) {
      return { ...t, y: '-30%', duration: 150 }
    }
    return t
  }

  const getSplit = (e) => {
    const t = e.split(' ')
    const c: any[] = []
    t.forEach((str, i) => {
      c.push(<span key={`${str}-${i}`}>{str}</span>)
      if (i < t.length - 1) {
        c.push(<span key={` -${i}`}> </span>)
      }
    })
    return c
  }

  return (
    <div className='combined-wrapper'>
      {visible && (
        <div className='combined'>
          <div className='combined-shape'>
            <div className='shape-left'>
              <TweenOne
                animation={[
                  { x: 158, type: 'from', ease: 'easeInOutQuint', duration: 600 },
                  { x: -158, ease: 'easeInOutQuart', duration: 450, delay: -150 },
                ]}
              />
            </div>
            <div className='shape-right'>
              <TweenOne
                animation={[
                  { x: -158, type: 'from', ease: 'easeInOutQuint', duration: 600 },
                  { x: 158, ease: 'easeInOutQuart', duration: 450, delay: -150 },
                ]}
              />
            </div>
          </div>
          <Texty
            className='title'
            type='mask-top'
            delay={400}
            enter={getEnter}
            interval={geInterval}
            component={TweenOne}
            componentProps={{
              animation: [
                { x: 130, type: 'set' },
                { x: 100, delay: 500, duration: 450 },
                {
                  ease: 'easeOutQuart',
                  duration: 300,
                  x: 0,
                },
                {
                  letterSpacing: 0,
                  delay: -300,
                  scale: 0.9,
                  ease: 'easeInOutQuint',
                  duration: 1000,
                },
                { scale: 1, width: '100%', delay: -300, duration: 1000, ease: 'easeInOutQuint' },
              ],
            }}
          >
            Kenai Dashboard
          </Texty>
          <TweenOne className='combined-bar' animation={{ delay: 2000, width: 0, x: 158, type: 'from', ease: 'easeInOutExpo' }} />
          <Texty className='content' type='bottom' split={getSplit} delay={2200} interval={30} onEnd={onEnd}>
            The Logbook of the Future
          </Texty>
        </div>
      )}
    </div>
  )
}

const jumpy = keyframes`
 0%,
  100% {
    transform: translate3d(0,0,0);
  }

  10% {
    transform: translate3d(0,0,0) scaleX(1.25) scaleY(.65);
  }

  35% {
    transform: translate3d(0,-120px,0) scaleX(.85) scaleY(1.2);
  }

  55% {
    transform: translate3d(0,-40,0) scaleX(1) scaleY(1);
  }

  65% {
    transform: translate3d(0,0,0) scaleX(1.15) scaleY(.65);
  }
  95% { transform: translate3d(0, 0,0) ;}
`

const Container = styled.div`
  z-index: 999;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  height: 100vh;
  width: 100vw;
  background: ${cssvars.bodyBackground};
  /* > div {
    margin-top: -100px;
    transform: translateY(50%);
  } */
  > i {
    margin-top: 45vh;
    margin-left: 47vw;
  }
  .logo-container {
    width: 150px;
    height: 150px;
    &.active .loading-logo {
      opacity: 1;
      animation: ${jumpy} 1.6s cubic-bezier(0.56, -0.06, 0.24, 0.71) infinite;
    }
  }
  .loading-logo {
    width: 100%;
    height: 100%;
    opacity: 0;
    transform-origin: 50% 100%;
    transition: opacity 0.8s ease;
    animation-delay: 1.3s !important;
  }

  .combined-wrapper {
    padding: 0;
    height: 130px;
    position: relative;
    width: 100%;
  }
  .combined-reload {
    position: absolute;
    bottom: 16px;
    right: 16px;
  }
  .combined {
    color: ${cssvars.textColor};
    width: 316px;
    height: 100px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
  }
  .combined-shape {
    position: relative;
  }
  .combined-shape > * {
    width: 50%;
    height: 44px;
    position: absolute;
    overflow: hidden;
  }
  .combined-shape > * > * {
    width: 100%;
    height: 100%;
    background: ${cssvars.textColor};
  }
  .combined-shape .shape-left {
    left: 0;
  }
  .combined-shape .shape-right {
    right: 0;
  }
  .combined .title {
    font-size: 36px;
    letter-spacing: 8px;
    position: absolute;
    width: 350px;
    overflow: hidden;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
  }
  .combined-bar {
    width: 100%;
    height: 2px;
    background: ${cssvars.textColor};
    position: absolute;
    top: 40px;
  }
  .combined .content {
    font-size: 18px;
    text-align: center;
    position: absolute;
    top: 48px;
    width: 100%;
  }
`
const LoaderLogo = ({ visible }) => {
  const Logo = useTheme().mode === 'dark' ? LogoDark : LogoLight
  return (
    <div className={`logo-container mx-auto relative ${visible ? 'active' : ''}`}>
      <Logo className='loading-logo' />
    </div>
  )
}

const SplashLoading = (props) => {
  const [mounted, setMounted] = useState(false)
  const [showLogo, setShowLogo] = useState(false)

  useEffect(() => {
    setMounted(true)
  }, [])

  if (window['KENAIConfigPrimaryColor']) {
    return (
      <Container>
        <LoadingOutlined spin style={{ fontSize: '5vw', color: window['KENAIConfigPrimaryColor'] }} />
      </Container>
    )
  } else {
    return (
      <Container>
        <div className='w-full  center-xy text-center'>
          <LoaderLogo visible={showLogo} />
          <LoaderText visible={mounted} onEnd={() => setShowLogo(true)} />
          {/* <Logo className='loading-logo ' />
          <h1>LOADING</h1> */}
          {props.children}
        </div>
      </Container>
    )
  }
}

export default SplashLoading
