import { ModalProps } from 'antd/lib/modal'
import React, { useEffect, useState } from 'react'
import { Prompt } from 'react-router-dom'
import withDefaults from 'src/utils/with-defaults'
import Guard from '.'

interface GuardRouteProps {
  when: boolean
  shouldBlockNavigation: (location?: string) => boolean
  modalProps: ModalProps
  navigate: (location: string) => void
}

const defaultProps = {
  modalProps: { centered: true, okButtonProps: {}, okText: 'Yes' } as ModalProps,
}

const GuardRoute: React.FC<GuardRouteProps> = ({ when, shouldBlockNavigation, navigate, modalProps }) => {
  const [modalVisible, setModalVisible] = useState(false)
  const [lastLocation, setLastLocation] = useState<any>(false)
  const [confirmedNavigation, setConfirmedNavigation] = useState(false)

  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      navigate(lastLocation.pathname)
    }
  }, [confirmedNavigation, navigate, lastLocation])

  const showModal = (location: boolean) => {
    setModalVisible(true)
    setLastLocation(location)
  }
  const closeModal = (callback) => {
    setModalVisible(false)
    if (callback && typeof callback === 'function') callback()
  }

  const handleBlockedNavigation = (nextLocation) => {
    if (!confirmedNavigation && shouldBlockNavigation(nextLocation)) {
      showModal(nextLocation)
      return false
    }
    return true
  }

  const handleConfirmNavigationClick = () => {
    closeModal(() => {
      if (lastLocation) {
        setConfirmedNavigation(true)
        navigate(lastLocation.pathname)
      }
    })
  }

  const { centered = true, okButtonProps, okText, ...rest } = modalProps

  return (
    <>
      <Prompt when={when} message={handleBlockedNavigation} />
      <Guard.Modal
        centered={centered}
        width='400px'
        {...rest}
        okText={okText}
        visible={modalVisible}
        onCancel={closeModal}
        onOk={handleConfirmNavigationClick}
      />
    </>
  )
}

export default withDefaults(GuardRoute, defaultProps)
