import {
  cloneDeep,
  //  orderBy, findIndex, cloneDeep
  map as _map,
} from 'lodash'
import moment from 'moment'
import { all, call, fork, put, select, take, takeLeading } from 'redux-saga/effects'
import API from 'src/api'
import { verificationString } from 'src/containers/Visitors/Log/utils/verificationString'
import { checkIfTimeExists } from 'src/redux/helpers/functions'
import { ActionCreators as AuthenticationActionCreators } from 'src/redux/sagas/authentication'
import { ActionCreators as ConfigActionCreators } from 'src/redux/sagas/configuration'
import { ActionCreators as CredentialsActionCreators, getEntityHierarchyConfiguration } from 'src/redux/store/credentials'
import { ActionCreators as AnalyticsActionCreators } from 'src/redux/store/visitors/analytics'
import { AllVisitDataType, EmployeeDataType } from 'src/redux/store/visitors/types/analytics'
import { VisitorLogItem } from 'src/redux/store/visitors/types/log'
import AccessManager from 'src/utils/AccessManager'
import ActionCreator from '../saga-action-creator'

// Action Creators
export const ActionCreators = {
  SagaRetrieveAnalyticsData: new ActionCreator<'SagaRetrieveAnalyticsData', { beginTime: string; endTime: string }>(
    'SagaRetrieveAnalyticsData'
  ),
}

const AWSHelper = new API.AWSHelpers()

function* processRetrieveAnalyticsData(action) {
  try {
    yield put(AnalyticsActionCreators.StoreSetLoading.create(true))
    yield put(AuthenticationActionCreators.SagaRefreshApiCredentials.create())
    yield take(AuthenticationActionCreators.SagaApiCredentialsRefreshUpdate.type)

    const entityHierarchyConfiguration = yield select(getEntityHierarchyConfiguration)

    const entityHierarchy = AccessManager.selectedHierarchy.hierarchyStructure

    if (entityHierarchy !== 'ALL') yield put(ConfigActionCreators.SagaRetrieveCurrentConfiguration.create())

    const beginTime = checkIfTimeExists(action, 'beginTime') ? action.payload.beginTime.valueOf() : moment().startOf('day').valueOf()
    const endTime = checkIfTimeExists(action, 'endTime') ? action.payload.endTime.valueOf() : 9999999999999
    if (`${beginTime}`.slice(-5) !== '00000' || `${endTime}`.slice(-5) !== '99999') {
      console.error('invalid analytics moments - not at limits', beginTime, endTime)
    }
    const sProjectionExpression = 'EntityHierarchy, evtTimeStampProfileID, evtTimeStamp, ProfileId, sourceOfIdentification, hostDetails'

    const getResult = yield call(
      [AWSHelper, AWSHelper.getVisitorlogEvents],
      beginTime,
      endTime,
      entityHierarchy,
      entityHierarchyConfiguration,
      sProjectionExpression
    )

    if (getResult.key === 'LOG_RETRIEVED') {
      const visitorlogData: VisitorLogItem[] = getResult.logItems
      const visitorAnalyticData: AllVisitDataType[] = _map(visitorlogData, (log: VisitorLogItem) => {
        return {
          evtTimeStamp: log.evtTimeStamp,
          evtDate: moment(log.evtTimeStamp).format('DD-MM-YYYY'),
          evtDateTs: moment(log.evtTimeStamp).startOf('day').valueOf(),
          evtTimeStampProfileID: log.evtTimeStampProfileID,
          ProfileId: log.ProfileId,
          hostDetails: log.hostDetails,
          visitorRecognitionState: verificationString(log.sourceOfIdentification),
        } as AllVisitDataType
      })

      //Fill empty dates in the range with zero values
      const fillEmptyDates = (targetDate) => {
        if (visitorAnalyticData.findIndex((item) => item.evtDateTs === targetDate) === -1) {
          visitorAnalyticData.push({
            evtTimeStamp: targetDate,
            evtDate: moment(targetDate).format('DD-MM-YYYY'),
            evtDateTs: targetDate,
            evtTimeStampProfileID: `${targetDate}#INTERVAL_FILL`,
            ProfileId: '',
            hostDetails: '',
            // @ts-ignore
            visitorRecognitionState: '',
            INTERVAL_FILL: true,
          })
        }
      }
      const selectionEndDate = moment(endTime).startOf('day').valueOf()
      const selectionStartDate = moment(beginTime).startOf('day').valueOf()
      const processDates = (inProcessDate) => {
        fillEmptyDates(inProcessDate)
        if (inProcessDate < selectionEndDate) {
          processDates(moment(inProcessDate).add(1, 'day').startOf('day').valueOf())
        }
      }
      processDates(selectionStartDate)

      const employeeData: EmployeeDataType[] = []

      visitorAnalyticData
        .filter((item) => !item.INTERVAL_FILL)
        .forEach((log: AllVisitDataType) => {
          if (log.hostDetails && log.hostDetails.hostNotOnList) {
            return
          }
          employeeData.push({
            evtTimeStamp: log.evtTimeStamp,
            name: (log.hostDetails && log.hostDetails.name) || undefined,
            uniqueAttributeValue: log.hostDetails && log.hostDetails.uniqueAttributeValue,
          })
        })
      yield put(AnalyticsActionCreators.StoreClearAnalyticData.create())
      yield put(AnalyticsActionCreators.StoreSetVisitData.create(visitorAnalyticData))
      yield put(AnalyticsActionCreators.StoreSetEmployeeData.create(employeeData))
      yield put(AnalyticsActionCreators.StoreDateRanges.create([beginTime, endTime]))
      if (getResult.evacuations && !!getResult.evacuations.length) {
        const hierarchyConfig = yield select(getEntityHierarchyConfiguration)
        const evacuationConfig = cloneDeep(hierarchyConfig.additionalConfigs.evacuation)
        getResult.evacuations.forEach((evacuation) => {
          const currentEvac = evacuationConfig[evacuation.EntityHierarchy]
          if (!currentEvac || (!!currentEvac && currentEvac.lmt < evacuation.lmt)) {
            evacuationConfig[evacuation.EntityHierarchy] = evacuation
          }
        })
        yield put(CredentialsActionCreators.StoreSetConfigEvacuation.create(evacuationConfig))
      }
      yield put(AnalyticsActionCreators.StoreSetLoading.create(false))
    } else {
      throw getResult
    }
  } catch (e) {
    if (e) {
      yield put(AnalyticsActionCreators.StoreSetLoading.create(false))
      console.error(e)
    }
  }
}

// Saga triggers
function* watchAnalyticsSagas() {
  yield takeLeading(ActionCreators.SagaRetrieveAnalyticsData.type, processRetrieveAnalyticsData)
  yield null
}

// Saga hooks
export default function* analyticsSagas() {
  yield all([fork(watchAnalyticsSagas)])
}
