import analytics, { State as analyticsState } from './analytics'
import hosts, { State as hostsState } from './hosts'
import log, { State as parkingState } from './log'
import invites, { State as invitesState } from './invites'
import manageInvites, { State as manageInvitesState } from './manageInvites'
import devices, { State as devicesState } from './devices'
import assistedCheckIn, { State as assistedCheckInState } from './assisted-checkin'
import { combineReducers } from 'redux'

export interface State {
  analytics: analyticsState
  hosts: hostsState
  log: parkingState
  invites: invitesState
  manageInvites: manageInvitesState
  devices: devicesState
  assistedCheckIn: assistedCheckInState
}

export default combineReducers<State>({
  analytics,
  hosts,
  log,
  invites,
  manageInvites,
  devices,
  assistedCheckIn,
})
