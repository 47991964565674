import getEventTiming from 'src/utils/getEventTiming'
import AccessManager from 'src/utils/AccessManager'
import { API as AmplifyAPI } from 'aws-amplify'
import { ArchivedEvacuationEvent, ActiveEvacuationEvent, EvacuationAudience, EvacuationResponse } from 'src/containers/Messaging/Evacuation'

export type EvacUpdateUserData = {
  EntityHierarchy: string
  id: string
  type: 'VISITOR' | 'EMPLOYEE' | 'PARKING' | 'EMPLOYEEDIRECTORY'
}
export type EvacUpdateStatus = 'SAFE' | 'NOT_SAFE'
export type EvacUpdateType = EvacUpdateUserData['type']

const updateEvacStatus = async (
  userData: EvacUpdateUserData[],
  status: EvacUpdateStatus,
  comment: string = ''
  // eslint-disable-next-line @typescript-eslint/ban-types
): Promise<{ key: 'EVAC_UPDATED' | (string & {}); evtTimeStampOut: number }> => {
  const { timeSinceEpoch: evtTimeStampOut, timezoneOffset: evtTZOffsetOut, timeZone: evtTimeZoneOut } = getEventTiming(new Date())

  const result = await AmplifyAPI.post('evacOperations', '/updateEvacStatus', {
    headers: {
      Authorization: `Bearer ${AccessManager.idToken}`,
    },
    body: {
      evacStatusUpdate: {
        userData: userData.map((user) => {
          return {
            EntityHierarchy: user.EntityHierarchy,
            type: user.type,
            ...(user.type === 'PARKING' ? { evtTimeStampUniqueAttributeValue: user.id } : { evtTimeStampProfileID: user.id }),
          }
        }),
        responseChannel: 'dashboard',
        status,
        comment,
        evtTimeStampOut,
        evtTZOffsetOut,
        evtTimeZoneOut,
      },
    },
  })

  return {
    key: result.key,
    evtTimeStampOut,
  }
}

const activateEvacuation = async (options: { audience: EvacuationAudience; hierarchies: string[] }) => {
  const lmt = Date.now()
  const result = await Promise.all<{
    key: 'EVAC_ACTIVATION_UPDATED' | 'EXISTING_ACTIVE_EVACUATION'
    evacuationEvent: ActiveEvacuationEvent
  }>(
    options.hierarchies.map((activationHierarchy) => {
      return AmplifyAPI.post('evacOperations', '/updateEvacActivation', {
        headers: {
          Authorization: `Bearer ${AccessManager.idToken}`,
        },
        body: {
          evacActivationOperation: {
            audience: options.audience,
            EntityHierarchy: activationHierarchy,
            active: true,
            activatedTimestamp: lmt,
            lmt,
          },
        },
      })
    })
  )

  return result
    .filter((entry) => entry.key === 'EVAC_ACTIVATION_UPDATED' || entry.key === 'EXISTING_ACTIVE_EVACUATION')
    .map((entry) => entry.evacuationEvent)
}

const deactivateEvacuation = async (options: { hierarchies: { EntityHierarchy: string; activatedTimestamp: number }[] }) => {
  const lmt = Date.now()
  const result = await Promise.all<{
    key: 'EVAC_ACTIVATION_UPDATED' | 'EXISTING_ARCHIVED_EVACUATION'
    evacuationEvent: ArchivedEvacuationEvent
  }>(
    options.hierarchies.map((entry) => {
      return AmplifyAPI.post('evacOperations', '/updateEvacActivation', {
        headers: {
          Authorization: `Bearer ${AccessManager.idToken}`,
        },
        body: {
          evacDeactivationOperation: {
            EntityHierarchy: entry.EntityHierarchy,
            activatedTimestamp: entry.activatedTimestamp,
            lmt,
          },
        },
      })
    })
  )

  return result
    .filter((entry) => entry.key === 'EVAC_ACTIVATION_UPDATED' || entry.key === 'EXISTING_ARCHIVED_EVACUATION')
    .map((entry) => entry.evacuationEvent)
}

const retrieveEvacLog = async (query: { hierarchies: string[]; beginTime: number; endTime: number; ProjectionExpression?: string }) => {
  const result = (await AmplifyAPI.post('evacOperations', '/retrieveEvacLog', {
    headers: {
      Authorization: `Bearer ${AccessManager.idToken}`,
    },
    body: {
      query,
    },
  })) as {
    key: 'LOG_RETRIEVED'
    archivedEvacuations: ArchivedEvacuationEvent[]
    activeEvacuations: ActiveEvacuationEvent[]
  }

  return {
    ...result,
    archivedEvacuations: result.archivedEvacuations.sort((a, b) => {
      return b.activatedTimestamp - a.activatedTimestamp
    }),
    activeEvacuations: result.activeEvacuations.sort((a, b) => {
      return b.activatedTimestamp - a.activatedTimestamp
    }),
  }
}

type EvacResponsesLogRetrieved = {
  key: 'LOG_RETRIEVED'
  logItems: EvacuationResponse[]
}

type EvacResponse =
  | EvacResponsesLogRetrieved
  | {
      key: 'ASYNC_RESPONSE_PAYLOAD'
      s3PresignedURL: string
    }

// Assuming `EvacuationResponse` and other related types are defined elsewhere

const retrieveEvacResponses = async (query: {
  hierarchies: string[]
  activatedTimestamp: number
  ProjectionExpression?: string
}): Promise<{ logItems: EvacuationResponse[] }> => {
  const apiResponse = (await AmplifyAPI.post('evacOperations', '/retrieveEvacResponses', {
    headers: {
      Authorization: `Bearer ${AccessManager.idToken}`,
    },
    body: {
      query,
    },
  })) as EvacResponse

  let finalLogItems: EvacuationResponse[] = []

  if (apiResponse.key === 'LOG_RETRIEVED') {
    finalLogItems = apiResponse.logItems
  } else if (apiResponse.key === 'ASYNC_RESPONSE_PAYLOAD') {
    const asyncResponse = await fetch(apiResponse.s3PresignedURL)
    const logResponse: EvacResponsesLogRetrieved = await asyncResponse.json()
    finalLogItems = logResponse.logItems
  }

  return {
    logItems: finalLogItems.map((item) => ({
      ...item,
      responses: item.responses.sort((a, b) => a.responseTimestamp - b.responseTimestamp),
    })),
  }
}

export const evacOperations = {
  updateEvacStatus,
  activateEvacuation,
  deactivateEvacuation,
  retrieveEvacLog,
  retrieveEvacResponses,
}
