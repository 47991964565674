import { css, FlattenSimpleInterpolation } from 'styled-components'

// Transition
export function transition(timing: number | undefined = 0.2) {
  return css`
    -webkit-transition: all ${timing}s;
    -moz-transition: all ${timing}s;
    -ms-transition: all ${timing}s;
    -o-transition: all ${timing}s;
    transition: all ${timing}s;
  `
}

// Border Radius
export function borderRadius(radius: string | undefined = '0px') {
  return css`
    -webkit-border-radius: ${radius};
    -moz-border-radius: ${radius};
    -ms-border-radius: ${radius};
    -o-border-radius: ${radius};
    border-radius: ${radius};
  `
}

// Box Shadow
export function boxShadow(shadow: string | undefined = 'none') {
  return css`
    -webkit-box-shadow: ${shadow};
    -moz-box-shadow: ${shadow};
    box-shadow: ${shadow};
  `
}

//No easing
export function constant(duration: number, range: number) {
  return duration / range
}

// //Linear easing
export function linear(duration: number, range: number, current: number) {
  return ((duration * 2) / Math.pow(range, 2)) * current
}

// //Quadratic easing
export function quadratic(duration: number, range: number, current: number) {
  return ((duration * 3) / Math.pow(range, 3)) * Math.pow(current, 2)
}

// Input Placeholder
export function placeholder(cssData: FlattenSimpleInterpolation) {
  return css`
    &::-webkit-input-placeholder {
      ${cssData}
    }
    &:-ms-input-placeholder {
      ${cssData}
    }
    &::placeholder {
      ${cssData}
    }
  `
}
