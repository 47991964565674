import { API as AmplifyAPI } from 'aws-amplify'
import moment from 'moment'
import { IntlShape } from 'react-intl'
import AccessManager from 'src/utils/AccessManager'

export interface CreateBody {
  operation: 'create'
  entityHierarchy: string
  email: string
  eventStartTime: number
  eventEndTime: number
  eventTimeZone: string
  parkingLicensePlate: string
  sectionId: string
  allocationGroup: string
  bypassOverlappingCheck?: boolean
}
export interface CheckBody {
  operation: 'check'
  entityHierarchy: string
  email: string
  eventStartTime: number
  eventEndTime: number
  eventTimeZone: string
  bypassOverlappingCheck?: boolean
}

export interface CancelBody {
  operation: 'cancel'
  entries: Array<{ EntityHierarchy: string; email: string; BookingRefCode: string }>
}

type QueryBody = CreateBody | CheckBody | CancelBody
export type OmitOperation<T = any> = Omit<T, 'operation'>

interface QueryResponse<T = any> {
  key: 'OPERATION_FAILED' | 'OPERATION_PROCESSED' | 'GENERIC_ERROR' | 'INVALID_REQUEST'
  bookingResult?: T
  message?: string
}

export default function processParkingBooking<T = any>() {
  const headers = {
    Authorization: `Bearer ${AccessManager.idToken}`,
  }

  const query = (bookingOperation: QueryBody) => {
    return AmplifyAPI.post('parkingOperations', '/processParkingBooking', {
      headers,
      body: {
        bookingOperation,
      },
    }) as Promise<QueryResponse<T>>
  }

  return {
    create: (body: OmitOperation<CreateBody>) => query({ operation: 'create', ...body }),
    check: (body: OmitOperation<CheckBody>) => query({ operation: 'check', ...body }),
    cancel: (body: OmitOperation<CancelBody>) => query({ operation: 'cancel', ...body }),
  }
}

export type CheckScheduleResponseMessage = {
  /**
   * - S : success  - should continue, can also have warnings
   * - W : warning  - should allow to continue but display warning
   * - E : error    - should block further processing and also show warnings
   */
  msgType: 'W' | 'S' | 'E'
  msgClass: 'ONDEMAND_PARKING_BOOKING'
  msgNumber: number
}

export const ONDEMAND_PARKING_BOOKING_MESSAGES = (intl: IntlShape) => {
  const time = (time) => moment(parseInt(time)).format('LL')
  const sum = (value) => value.split(',').reduce((a, b) => parseInt(a) + parseInt(b), 0)
  return {
    1: (options) =>
      intl.formatMessage(
        {
          id: 'ONDEMAND_PARKING_BOOKING_MESSAGES-1',
          defaultMessage: `Insufficient space available on <time>{msgv1}</time> - requested: {msgv2} available (normal and overallocation): <sum>{msgv3},{msgv4}</sum>`,
        },
        { ...options, time, sum }
      ),
  }
}
