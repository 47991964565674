import { faCube, faCubes, faKey } from '@fortawesome/pro-light-svg-icons'
import React, { lazy } from 'react'
import { FormattedMessage } from 'react-intl'
import { ROUTE } from 'src/typings/kenai/dashboard-router'

const IntegrationsGuides = lazy(() => import('src/containers/Integrations/integration-guides'))
const ApiKeys = lazy(() => import('src/containers/Integrations/api-keys'))

export default function getIntegrationRoutes() {
  const integrationsRoutes: ROUTE = {
    icon: faCube,
    label: <FormattedMessage id='common.integrations' />,
    path: 'integrations-old',
    sidebar: true,
    children: [
      {
        path: 'integrations/api-keys',
        label: 'API Keys',
        icon: faKey,
        component: ApiKeys,
        sidebar: true,
      },
      {
        path: 'integrations/guides',
        label: <FormattedMessage id='kenai.sidebar.guides' />,
        icon: faCubes,
        component: IntegrationsGuides,
        sidebar: true,
      },
    ],
  }

  return integrationsRoutes
}
