import { InviteLogItem } from 'src/redux/store/visitors/types/invites'
import { EntityHierarchyConfig } from 'src/typings/kenai/configuration/entity-hierarchy'

export default function resolveInviteCheckInFieldTexts(item: InviteLogItem, entityHierarchyConfiguration: EntityHierarchyConfig) {
  const flagsForitem = entityHierarchyConfiguration.tenantFlags.find((flags) => flags.EntityHierarchy === item.EntityHierarchy)
  const flowIdentityTypeForItem = flagsForitem?.availableIdentities.find(
    (availableIdentity) => availableIdentity.flowIndex === item.setupProcessingDetails?.setupVisitorFlowIndex
  )
  if (item.checkInFieldValues && flowIdentityTypeForItem?.additionalFlowDataCapturing?.checkIn) {
    const activeCheckInFields = flowIdentityTypeForItem.additionalFlowDataCapturing.checkIn.filter(
      (checkInField) =>
        checkInField.deleted !== true &&
        checkInField.disabled !== true &&
        checkInField.dashboardSettings.visible === true &&
        checkInField.inviteSettings &&
        (checkInField.inviteSettings.preRegistration ||
          checkInField.inviteSettings.postRegistration ||
          checkInField.inviteSettings.devicedBasedRegistration)
    )
    activeCheckInFields.forEach((activeCheckInField) => {
      if (
        activeCheckInField.staticDropDownListItems &&
        (activeCheckInField.inputSettings.inputType === 'staticDropDownListMultiSelect' ||
          activeCheckInField.inputSettings.inputType === 'staticDropDownListSingleSelect')
      ) {
        if (item.checkInFieldValues && item.checkInFieldValues[activeCheckInField.id]) {
          const resolvedParts: string[] = []
          const processStaticItem = (itemDataKey) => {
            const staticDropDownListItem = activeCheckInField.staticDropDownListItems?.find(
              (staticDropDownListItem) => staticDropDownListItem.dataKey === itemDataKey
            )
            if (staticDropDownListItem) {
              resolvedParts.push(staticDropDownListItem.text)
            }
          }
          item.checkInFieldValues[activeCheckInField.id].split(';').forEach((itemDataKey) => processStaticItem(itemDataKey))
          item.checkInFieldValues[activeCheckInField.id] = resolvedParts.join(';')
        }
      }
    })
  }
}
