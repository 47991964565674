import { map, partialRight, pick } from 'lodash'
import { HierarchyEnabledFlags } from 'src/hooks/use-hierarchy-enabled-flags'
import getIntl from 'src/i18n'
import { EmployeeDetails } from 'src/redux/store/employees/types/directory'
import { notification } from 'src/utils'
import { csvExporter } from 'src/utils/csv-exporter'
import { genericLabel } from 'src/utils/generic-label'
import { EntityHierarchyConfig } from 'src/typings/kenai/configuration/entity-hierarchy'

type ExportItem = Pick<
  EmployeeDetails & { employeeType: string },
  | 'firstName'
  | 'lastName'
  | 'personalIdentificationNr'
  | 'alternatePersonalIdNr'
  | 'organizationIdValue'
  | 'email'
  | 'phoneNumber'
  | 'employeeType'
  | 'lineManagers'
  | 'tags'
>

export default function exportDirectory(
  itemsToExport: EmployeeDetails[],
  entityHierarchyConfig: EntityHierarchyConfig,
  hierarchyEnabledFlags: HierarchyEnabledFlags
) {
  const intl = getIntl()
  notification('info', 'Export', `Exporting ${itemsToExport.length} ${itemsToExport.length > 1 ? 'entries' : 'entry'}`)
  const columnKeys: (keyof ExportItem)[] = [
    'firstName',
    'lastName',
    'personalIdentificationNr',
    ...(hierarchyEnabledFlags.employeeAlternatePersonalIdNr ? ['alternatePersonalIdNr' as const] : []),
    'organizationIdValue',
    'phoneNumber',
    'email',
    'employeeType',
    ...(hierarchyEnabledFlags.employeeLineManagers ? ['lineManagers' as const] : []),
    ...(hierarchyEnabledFlags.employeeTags ? ['tags' as const] : []),
  ]

  const flowTypeText = (EntityHierarchy, flowId) => {
    const flow = entityHierarchyConfig.additionalConfigs.flowTypes[EntityHierarchy].find(
      (flow) => flow.disabled !== true && flow.flowNature === 1 && flow.flowId === flowId
    )
    if (flow) return intl.formatMessage({ id: flow.texts.flowDescriptionTextId })
    return 'N/A'
  }

  const items = itemsToExport.map((item) => ({
    firstName: item.firstName || ' ',
    lastName: item.lastName || ' ',
    personalIdentificationNr: item.personalIdentificationNr?.toUpperCase() || ' ',
    ...(hierarchyEnabledFlags.employeeAlternatePersonalIdNr
      ? { alternatePersonalIdNr: item.alternatePersonalIdNr?.toUpperCase() || ' ' }
      : {}),
    organizationIdValue: item.organizationIdValue?.toUpperCase() || ' ',
    phoneNumber: item.phoneNumber || ' ',
    email: item.email || ' ',
    employeeType: flowTypeText(item.EntityHierarchy, item.flowTypeRef?.flowId),
    ...(hierarchyEnabledFlags.employeeLineManagers ? { lineManagers: item?.lineManagers?.[0] ? item?.lineManagers.join(' | ') : ' ' } : {}),
    ...(hierarchyEnabledFlags.employeeTags ? { tags: item?.tags?.[0] ? item?.tags.join(' | ') : ' ' } : {}),
  }))

  const filename = `${intl.formatMessage({ id: `common.${hierarchyEnabledFlags.employeeRouteType}-singular` }).toLowerCase()}_directory`
  const data = map(items, partialRight(pick, columnKeys))
  const columns = columnKeys.map((key) => ({
    label: genericLabel(key),
    value: key,
  }))
  csvExporter(filename, columns, data)
}
