import { createGlobalStyle } from 'styled-components'
import cssvars, { styledVars } from 'src/styles/cssvars'

export default createGlobalStyle`
  ${styledVars}
  &&{
    body, h1, h2, h3, h4, h5, h6 {  /* sets body and heading color */
      color:${cssvars.textColor};
    }
    a {
      color: ${cssvars.primaryColor};
      text-decoration: none;
      border-bottom: 1px solid transparent;
      transition: border 0.2s ease;


      &.no-underline:hover {
        color: ${cssvars.primaryHover};
      }

    }
    #nprogress {
      .bar {
        background: ${cssvars.primaryColor};
      }
      .spinner {
        display: none;
      }
    }

    .scrollbar-track,
    .scrollbar-thumb {
      &-y {
        width: 5px !important;
      }
      &-x {
        height: 5px !important;
      }
    }

    .scrollbar-thumb {
      border-radius: 0 !important;
    }

    * {
      &:focus,
      &:active {
        // outline: none !important;
      }
    }

    .column-drag-line {
      border-color: ${cssvars.primaryColor}!important;
      opacity: 0.5;
    }

    .corrections-modal .ant-modal-confirm-body > .anticon + .ant-modal-confirm-title + .ant-modal-confirm-content{
      margin-left:0px;
    }

    .auth0-lock-container .auth0-lock.auth0-lock {
      .auth0-lock {
        &-overlay {
          background: ${cssvars.bodyBackground};
          opacity: 1 !important;
        }
        &-header-bg {
          background: transparent;
          &-blur {
            background-image: none !important;
          }
        }
        &-input-wrap {
          background: transparent;
        }
      }
      &.auth0-lock-opened {
        .auth0-lock-center {
          .auth0-lock-widget {
            box-shadow: 0 0 5px -2px #111118;
          }
        }
      }
    }

    .center{
      &-x {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
      }
      &-y {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
      }
      &-xy {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    .ant-form{
      &-explain, &-extra{
        /* font-update */
        font-size: 12px!important;
      }
      &-item-with-help{
        /* margin-bottom: 20px!important; */
      }
    }


    .ant-badge{
      &.hide-badge-dot{
        .ant-badge-status-dot{
          opacity:0;
        }
      }
    }

    .no-header-border{
      .ant-list-header{
      border-bottom:none!important;
      }
    }

    ::selection{
      background:${cssvars.primaryColor}!important;
      color:white!important;
    }

    div#_hj_feedback_container{
      display: ${window.location.hostname.indexOf('frames') > -1 ? 'none' : 'block'}
    }

    [data-transition='none'] *{
      transition: none!important;
    }

    .white-filter {
      filter: grayscale(1) brightness(100);
    }
  }
`
