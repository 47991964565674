import { DownloadOutlined } from '@ant-design/icons'
import { Alert, Badge, Button, Form, Input, List, Result, Select, Space } from 'antd'
import FileSaver from 'file-saver'
import moment from 'moment'
import React from 'react'
import Fieldset from 'src/components/fieldset'
import PhoneNumberInput from 'src/components/phone-number-input'
import config from 'src/config'
import useHierarchyEnabledFlags from 'src/hooks/use-hierarchy-enabled-flags'
import getIntl from 'src/i18n'
import { cssvars } from 'src/styles'
import { conditionalProps } from 'src/utils/conditionalReturn'
import { checkValidEmail, emailValidator, phoneValidator } from 'src/utils/validation'
import { requiredRule } from '../../utils/form'
import { EmployeeImportResponse } from '../../utils/import'

const formLayout = {
  labelCol: { xs: 24, sm: 6 },
  wrapperCol: { xs: 24, sm: 18 },
  labelAlign: 'left',
} as any

const InvalidEmployees = ({ data, setData }: { data: EmployeeImportResponse; setData: (data: EmployeeImportResponse) => void }) => {
  const intl = getIntl()
  const { employeeRouteType, employeeLineManagers, employeeAlternatePersonalIdNr, employeeTags } = useHierarchyEnabledFlags()
  const translatedRouteType = intl.formatMessage({ id: `common.${employeeRouteType}` })

  function downloadInvalidList(invalidData: EmployeeImportResponse['invalidData']) {
    const intl = getIntl()
    const stringy = (value?: string) => `"${value || ''}"`
    const labels = [
      stringy(intl.formatMessage({ id: 'common.first-name' })),
      stringy(intl.formatMessage({ id: 'common.last-name' })),
      stringy(intl.formatMessage({ id: 'personIDValue' })),
      stringy(intl.formatMessage({ id: 'organizationIdValue' })),
      ...(invalidData.some((e) => !!e?.data?.lineManagers) ? [stringy('Line Managers')] : []),
      stringy(intl.formatMessage({ id: 'common.email' })),
      stringy(intl.formatMessage({ id: 'common.phone-number' })),
    ].join(', ')

    const downloadData = invalidData.map((e) => {
      return [
        stringy(e.data.firstName),
        stringy(e.data.lastName),
        stringy(e.data.personalIdentificationNr),
        ...(e.data?.alternatePersonalIdNr ? stringy(e.data.alternatePersonalIdNr) : []),
        stringy(e.data.organizationIdValue),
        ...(e.data?.lineManagers ? [stringy(e.data?.lineManagers?.join('|') || ' ')] : []),
        stringy(e.data?.email || ' '),
        stringy(e.data?.phoneNumber || ' '),
      ].join(', ')
    })

    const csv = [labels, ...downloadData].join('\n').replace(/(^\[)|(\]$)/gm, '')

    const exportBlob = new Blob([csv], { type: 'text/csv;charset=utf-8;' })
    FileSaver.saveAs(
      exportBlob,
      `invalid_${
        employeeRouteType === 'students' ? 'student' : employeeRouteType === 'candidates' ? 'candidate' : 'employee'
      }_import_${moment(new Date()).format('DD-MM-YYYY_HH-MM-SS')}.csv`
    )
  }

  const handleFixEmployee = (values, invalidIndex: number) => {
    const invalidData = data.invalidData.map((e, i) => (i === invalidIndex ? { ...e, fixed: true } : e))
    const validData = [
      ...data.validData,
      {
        update: false,
        data: values,
      } as typeof data.validData[number],
    ]

    const updatedState = {
      error: data.error,
      invalidData,
      validData,
    }

    setData(updatedState)
  }

  return (
    <Space direction='vertical' className='mt-4 w-full'>
      <Alert
        type='warning'
        message={
          <>
            <p>If you decide to continue, the invalid {translatedRouteType} will be ignored.</p>
            You can{' '}
            <Button type='link' onClick={() => downloadInvalidList(data.invalidData.filter((e) => !e.fixed))} className='h-auto px-1 py-0'>
              <DownloadOutlined className='-mr-1' /> download
            </Button>{' '}
            the invalid list data as a CSV for further reference.{' '}
            {config.helpCenter && (
              <a href='#help-center' className='whitespace-no-wrap' target='_blank' rel='noopener nofollow'>
                learn more
              </a>
            )}
          </>
        }
      />

      <List
        dataSource={data.invalidData}
        {...conditionalProps(data.invalidData.length > 4, {
          pagination: {
            pageSize: 4,
          },
        })}
        renderItem={(record, i) => {
          const errors = Object.keys(record.errors).map((key, i) => (
            <Badge key={i} status='error' text={<span className='text-vars-error100'>{record.errors[key]}</span>} />
          ))
          return (
            <List.Item>
              <Form {...formLayout} className=' w-full' onFinish={(values) => handleFixEmployee(values, i)}>
                <Fieldset
                  title={`${i + 1}. Invalid ${intl.formatMessage({ id: `common.${employeeRouteType}-singular` })}`}
                  extra={
                    <Form.Item shouldUpdate>
                      {({ isFieldsTouched }) => {
                        return (
                          <Button disabled={!isFieldsTouched()} size='small' type='primary' htmlType='submit'>
                            Validate
                          </Button>
                        )
                      }}
                    </Form.Item>
                  }
                  className='relative'
                >
                  {record.fixed && (
                    <Result
                      status='success'
                      title='Entry Fixed'
                      className='absolute left-0 top-0 w-full h-full z-10'
                      style={{ background: cssvars.borderColorA06 }}
                    />
                  )}
                  <Form.Item
                    name='firstName'
                    label='First Name'
                    initialValue={record?.data?.firstName}
                    rules={[requiredRule('First name')]}
                    className='pt-4'
                  >
                    <Input disabled={!record?.errors?.firstName} />
                  </Form.Item>

                  <Form.Item name='lastName' label='Last Name' initialValue={record?.data?.lastName} rules={[requiredRule('Last name')]}>
                    <Input disabled={!record?.errors?.lastName} />
                  </Form.Item>

                  <Form.Item
                    name='personalIdentificationNr'
                    label='ID / Passport Number'
                    initialValue={record?.data?.personalIdentificationNr}
                    rules={[requiredRule('ID / Passport Number')]}
                  >
                    <Input disabled={!record?.errors?.personalIdentificationNr} />
                  </Form.Item>
                  {employeeAlternatePersonalIdNr && (
                    <Form.Item
                      name='alternatePersonalIdNr'
                      label='Alternate Personal identification number'
                      initialValue={record?.data?.alternatePersonalIdNr}
                      rules={[requiredRule('Alternate Personal identification number')]}
                    >
                      <Input disabled={!record?.errors?.alternatePersonalIdNr} />
                    </Form.Item>
                  )}
                  <Form.Item
                    name='organizationIdValue'
                    label='Organization ID'
                    initialValue={record?.data?.organizationIdValue}
                    rules={[requiredRule('Organization ID')]}
                  >
                    <Input disabled={!record?.errors?.organizationIdValue} />
                  </Form.Item>

                  {employeeLineManagers && record?.data?.lineManagers && (
                    <Form.Item
                      name='lineManagers'
                      label='Line Managers'
                      initialValue={record?.data?.lineManagers}
                      rules={[
                        {
                          validator: (_, values) => {
                            if (values.some((e) => values.indexOf(e) !== i)) Promise.reject('Duplicate emails found')
                            return Promise.resolve()
                          },
                        },
                        {
                          validator: (_, values) => {
                            if (values.some((e) => !checkValidEmail(e))) Promise.reject('Found a malformatted email')
                            return Promise.resolve()
                          },
                        },
                      ]}
                    >
                      <Select mode='tags' disabled={!record?.errors?.lineManagers || record.errors?.lineManagers?.length === 0} />
                    </Form.Item>
                  )}

                  {employeeTags && record?.data?.tags && (
                    <Form.Item
                      name='tags'
                      label='Tags'
                      initialValue={record?.data?.tags}
                      rules={[
                        {
                          validator: (_, values) => {
                            if (values.some((e) => values.indexOf(e) !== i)) Promise.reject('Duplicate tags found')
                            return Promise.resolve()
                          },
                        },
                      ]}
                    >
                      <Select mode='tags' disabled={!record?.errors?.tags?.[0]} />
                    </Form.Item>
                  )}

                  {record.data.email && (
                    <Form.Item name='email' label='Email' initialValue={record?.data?.email} rules={[{ validator: emailValidator }]}>
                      <Input disabled={!record?.errors?.email} />
                    </Form.Item>
                  )}
                  {record.data.phoneNumber && (
                    <Form.Item
                      name='phoneNumber'
                      label='Phone number'
                      initialValue={record?.data?.phoneNumber}
                      rules={[{ validator: phoneValidator }]}
                    >
                      <PhoneNumberInput disabled={!record?.errors?.phoneNumber} />
                    </Form.Item>
                  )}

                  <Fieldset.Footer>
                    <Space direction='vertical' size={0}>
                      {errors}
                    </Space>
                  </Fieldset.Footer>
                </Fieldset>
              </Form>
            </List.Item>
          )
        }}
      />
    </Space>
  )
}

export default InvalidEmployees
