import { darken } from 'polished'
import React, { useEffect, useRef } from 'react'
import { IntercomProvider as Provider } from 'react-use-intercom'
import config from '../config'
import { useTheme } from './theme/ThemeProvider'

const injectIframeStyle = (color) => {
  if (!config.staging) {
    const injectMessengerStyle = setInterval(function () {
      const iframe = (document.getElementsByName('intercom-messenger-frame')?.[0] as HTMLIFrameElement) ?? undefined
      if (iframe) {
        const container = document.getElementsByClassName('intercom-messenger-frame')?.[0]
        container?.classList?.add?.('styling')
        const head = iframe.contentDocument?.getElementsByTagName('head')?.[0]
        if (head) {
          const style = iframe.contentDocument?.createElement?.('style')
          if (style) {
            head.appendChild(style)
            style.innerHTML = `
            .intercom-messenger-header {
              background:${color}!important;
              background:linear-gradient(135deg, ${color} 0%, ${darken(0.15, color) || color} 100%)!important;
            }
            .new-conversation-button {
              background:${color}!important;
            }
            .intercom-messenger .intercom-anchor, .intercom-messenger .view-all-conversations {
              color: ${color} !important;
            }
            .intercom-messenger svg{
              fill: ${color} !important;
            }
          `
            container?.classList?.remove?.('styling')
            container?.classList?.add?.('styling-done')
          }
        }

        clearInterval(injectMessengerStyle)
      }
    }, 100) // check every 100ms

    const injectButtonStyle = setInterval(function () {
      const iframe = (document.getElementsByName('intercom-launcher-frame')?.[0] as HTMLIFrameElement) ?? undefined
      if (iframe) {
        const head = iframe.contentDocument?.getElementsByTagName('head')?.[0]
        if (head) {
          const style = iframe.contentDocument?.createElement?.('style')
          if (style) {
            head.appendChild(style)
            style.innerHTML = `
          .intercom-launcher {
            background:${color}!important;
          }
        `
          }
        }
        clearInterval(injectButtonStyle)
      }
    }, 100) // check every 100ms
  }
}

export default function Intercom(props: { children: any }) {
  const { brand } = useTheme()
  const primaryColor = useRef(brand.primaryColor)

  useEffect(() => {
    primaryColor.current = brand.primaryColor
    injectIframeStyle(brand.primaryColor)
  }, [brand.primaryColor])

  return (
    <Provider appId={config.staging ? 'h2av8vi6' : 'wh5i293i'} autoBoot onShow={() => injectIframeStyle(primaryColor.current)}>
      {props.children}
      {!config.staging && (
        <style jsx global>{`
          .intercom-launcher,
          .intercom-launcher-frame {
            background: var(--primaryColor) !important;
          }

          .intercom-messenger-frame {
            transition-delay: 5ms;
          }

          .intercom-messenger-frame:not(.styling-done) {
            opacity: 0 !important;
          }

          .intercom-messenger-frame.styling-done {
            opacity: 1;
          }
        `}</style>
      )}
    </Provider>
  )
}
