import { getTableNameFromAwsExports, queryDDBWithBatchLimits } from '../utils'
import { Key } from 'aws-sdk/clients/dynamodb'
import { ConfigValueFlowType } from 'src/typings/kenai/configuration/entity-hierarchy'

export async function retrieveConfigFlowTypes(requestedHierarchy: string) {
  const params = (batchHierarchy: string, ExclusiveStartKey?: Key) => ({
    ExpressionAttributeValues: {
      ':hk': batchHierarchy,
      ':deleted': false,
    },
    FilterExpression: 'deleted = :deleted',
    KeyConditionExpression: 'EntityHierarchy = :hk',
    TableName: getTableNameFromAwsExports('mdConfigFlowTypes'),
    ExclusiveStartKey,
  })

  return await queryDDBWithBatchLimits<ConfigValueFlowType>(requestedHierarchy, params)
}
