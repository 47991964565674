import update from 'immutability-helper'
import { createSelector } from 'reselect'
import { getTimeRange } from 'src/utils/getTimeRange'
import { RootState } from '../rootReducer'
import { ActionCreator } from '../store-action-creator'
import { EmployeeLogItem, EmployeeLogItemEventPair } from './types/employee-log'
import { RangePickerNumberValue } from 'src/typings/vendor/rangepicker'

// Action Creators
export const ActionCreators = {
  StoreSetEmployeeLogData: new ActionCreator<'StoreSetEmployeeLogData', State['data']>('StoreSetEmployeeLogData'),
  StoreSetEmployeeLogEventPairData: new ActionCreator<
    'StoreSetEmployeeLogEventPairData',
    {
      data: State['eventPairData']
      shouldSubscribeToUpdates?: State['shouldSubscribeToUpdates']
    }
  >('StoreSetEmployeeLogEventPairData'),
  StoreSetEmployeeLogDateRange: new ActionCreator<'StoreSetEmployeeLogDateRange', State['dateRanges']>('StoreSetEmployeeLogDateRange'),
  StoreSetEmployeeLogLoading: new ActionCreator<'StoreSetEmployeeLogLoading', State['loading']>('StoreSetEmployeeLogLoading'),
  StoreClearAllEmployeeLogData: new ActionCreator<'StoreClearAllEmployeeLogData', void>('StoreClearAllEmployeeLogData'),
  ClearAllStates: new ActionCreator<'ClearAllStates', void>('ClearAllStates'),
}

export type Action = typeof ActionCreators[keyof typeof ActionCreators]

export type State = {
  readonly data: EmployeeLogItem[]
  readonly eventPairData: EmployeeLogItemEventPair[]
  readonly loading: boolean
  readonly dateRanges: RangePickerNumberValue
  readonly shouldSubscribeToUpdates: boolean
}

export const initialState = (): State => ({
  data: [],
  eventPairData: [],
  loading: false,
  dateRanges: getTimeRange('today'),
  shouldSubscribeToUpdates: false,
})

// Reducer
export default function reducer(state = initialState(), action: Action) {
  switch (action.type) {
    case ActionCreators.StoreSetEmployeeLogData.type:
      return update(state, {
        data: { $set: action.payload },
      })
    case ActionCreators.StoreSetEmployeeLogEventPairData.type:
      return update(state, {
        eventPairData: { $set: action.payload.data },
        shouldSubscribeToUpdates: { $set: action.payload.shouldSubscribeToUpdates ? true : false },
      })
    case ActionCreators.StoreSetEmployeeLogLoading.type:
      return update(state, {
        loading: { $set: action.payload },
      })
    case ActionCreators.StoreSetEmployeeLogDateRange.type:
      return update(state, {
        dateRanges: { $set: action.payload },
      })
    case ActionCreators.StoreClearAllEmployeeLogData.type:
      return initialState()
    //   return update(state, {
    //     data: { $set: initialState().data },
    //     eventPairData: { $set: initialState().eventPairData },
    //   })
    case ActionCreators.ClearAllStates.type:
      return initialState()
    default:
      return state
  }
}

// Selectors
const getEmployeeLogState = (state: RootState) => state.employees.employeeLog
export const getEmployeeLog = createSelector<any, State, State>([getEmployeeLogState], (state) => {
  return state
})

const getEmployeeLogDataState = (state: RootState) => state.employees.employeeLog.data
export const getEmployeeLogData = createSelector<any, State['data'], State['data']>([getEmployeeLogDataState], (state) => {
  return state
})

const getEmployeeEventPairDataState = (state: RootState) => state.employees.employeeLog.eventPairData
export const getEmployeeEventPairData = createSelector<any, State['eventPairData'], State['eventPairData']>(
  [getEmployeeEventPairDataState],
  (state) => {
    return state
  }
)

const getEmployeeLogDateRangesState = (state: RootState) => state.employees.employeeLog.dateRanges
export const getEmployeeLogDateRanges = createSelector<any, State['dateRanges'], State['dateRanges']>(
  [getEmployeeLogDateRangesState],
  (state) => {
    return state
  }
)

const getEmployeeLogLoadingState = (state: RootState) => state.employees.employeeLog.loading
export const getEmployeeLogLoading = createSelector<any, State['loading'], State['loading']>([getEmployeeLogLoadingState], (state) => {
  return state
})

const getEmployeeLogShouldSubscribeToUpdatesState = (state: RootState) => state.employees.employeeLog.shouldSubscribeToUpdates
export const getEmployeeLogShouldSubscribe = createSelector<any, State['shouldSubscribeToUpdates'], State['shouldSubscribeToUpdates']>(
  [getEmployeeLogShouldSubscribeToUpdatesState],
  (state) => {
    return state
  }
)

export const EmployeeLogSelectors = {
  getEmployeeLog,
  getEmployeeLogData,
  getEmployeeEventPairData,
  getEmployeeLogDateRanges,
  getEmployeeLogLoading,
  getEmployeeLogShouldSubscribe,
}
