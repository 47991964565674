import { faAddressCard, faBook, faUserChart, faUserTie, faUserGraduate, faUsers, faFolderOpen } from '@fortawesome/pro-light-svg-icons'
import { lazy } from 'react'
import getIntl from 'src/i18n'
import { conditionalArrayItem } from 'src/utils/conditionalReturn'
import AccessManager from 'src/utils/AccessManager'
import { showRoute } from './show-route'

import EmployeesDirectory from 'src/containers/Employees/Directory'
import { ROUTE, ROUTE_EMPLOYEE_AVAILABLE_TYPES } from 'src/typings/kenai/dashboard-router'

// const EmployeesBase = lazy(() => import('src/containers/Employees'))
const EmployeesLog = lazy(() => import('src/containers/Employees/EmployeeLog'))
const EmployeeScreeningLog = lazy(() => import('src/containers/Employees/ScreeningLog'))
const AssistedCheckIn = lazy(() => import('src/containers/Employees/AssistedCheckIn'))

const EmployeeDisabledView = lazy(() => import('src/containers/Employees/disabled-view'))
const EmployeeLogDisabledView = lazy(() => import('src/containers/Employees/disabled-view'))
const EmployeePrescreeningLogDisabledView = lazy(() => import('src/containers/Employees/ScreeningLog/disabled-view'))
const EmployeeDirectoryDisabledView = lazy(() => import('src/containers/Employees/Directory/disabled-view'))
// const EmployeeLogDisabledView = lazy(() => import('src/containers/Employees/disabled-view'))
const DisabledView = lazy(() => import('src/components/disabled-view'))

// PRE-MIGRATION START
const DocumentsAndFilesLog = lazy(() => import('src/containers/Employees/DocumentsAndFiles/log'))
const DocumentsAndFilesCaptureSingle = lazy(() => import('src/containers/Employees/DocumentsAndFiles/capture-single'))
// PRE-MIGRATION END

export default function getEmployeeRoutes(
  employeeLog,
  screeningLog,
  employeeDirectory,
  employeesEnabled,
  dafEnabled,
  routeType: ROUTE_EMPLOYEE_AVAILABLE_TYPES,
  isLegacyLayout: boolean
) {
  const isAdmin = AccessManager.selectedHierarchy.role === 'ADMIN'
  const intl = getIntl()
  const translate = (id: string) => intl.formatMessage({ id })
  const enabled: Record<string, (val: ROUTE) => ROUTE[]> = {
    employeeLog: (val) => conditionalArrayItem(showRoute(employeeLog, isLegacyLayout), val),
    screeningLog: (val) => conditionalArrayItem(showRoute(screeningLog, isLegacyLayout), val),
    filesAndDocuments: (val) => conditionalArrayItem(showRoute(dafEnabled, isLegacyLayout), val),
  }

  const employeesRoute: ROUTE = {
    icon: routeType === 'students' ? faUserGraduate : routeType === 'candidates' ? faUsers : faUserTie,
    label: translate(`common.${routeType}`),
    path: routeType,
    sidebar: true,
    exact: true,
    component: !employeesEnabled && isAdmin ? EmployeeDisabledView : undefined,
    children:
      !employeesEnabled && isAdmin
        ? []
        : [
            ...enabled.employeeLog({
              path: `${routeType}/${routeType}-log`,
              label: translate(`kenai.${routeType}.log.title`),
              icon: faBook,
              component: employeeLog ? EmployeesLog : EmployeeLogDisabledView,
              sidebar: true,
            }),
            ...enabled.screeningLog({
              path: `${routeType}/screening-log`,
              label: 'Screening Log',
              icon: faUserChart,
              component: screeningLog ? EmployeeScreeningLog : EmployeePrescreeningLogDisabledView,
              sidebar: true,
            }),
            // PRE-MIGRATION START
            // TODO add enablement check for documents and files
            ...enabled.filesAndDocuments({
              path: `${routeType}/documents-and-files`,
              label: 'Documents & Files',
              icon: faFolderOpen,
              component: DocumentsAndFilesLog,
              sidebar: true,
              exact: true,
            }),
            ...enabled.filesAndDocuments({
              path: `${routeType}/documents-and-files/capture`,
              component: DocumentsAndFilesCaptureSingle,
              sidebar: false,
              exact: true,
            }),
            // PRE-MIGRATION END
            ...enabled.employeeLog({
              path: `${routeType}/assisted-check-in`,
              component: employeeLog ? AssistedCheckIn : DisabledView,
              sidebar: false,
            }),
          ].filter(Boolean),
  }

  if (employeesEnabled && employeesRoute?.children) {
    employeesRoute?.children?.push({
      path: `${routeType}/directory`,
      label: 'Directory',
      icon: faAddressCard,
      component: employeeDirectory ? EmployeesDirectory : EmployeeDirectoryDisabledView,
      sidebar: true,
    })
  }

  return employeesRoute
}
