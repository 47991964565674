import induction, { State as inductionState } from './induction-store'

import { combineReducers } from 'redux'

export interface State {
  induction: inductionState
}

export default combineReducers<State>({
  induction,
})
