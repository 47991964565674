import { all, fork } from 'redux-saga/effects'
import analytics from './analytics'
import hosts from './hosts'
import log from './log'
import invites from './invites'
import manageInvites from './manageInvites'
import devices from './devices'
import assistedCheckIn from './assisted-checkin'

export default function* visitors() {
  yield all([fork(analytics), fork(hosts), fork(log), fork(invites), fork(manageInvites), fork(devices), fork(assistedCheckIn)])
}
