import moment from 'moment'
import { all, fork, put, take, takeLeading, select } from 'redux-saga/effects'
import { inductionLogRetrieval } from 'src/api/inductionOperations/inductionLogRetrieval'
import { checkIfTimeExists } from 'src/redux/helpers/functions'
import { ActionCreators as AuthenticationActionCreators } from 'src/redux/sagas/authentication'
import { ActionCreators as InductionLogActionCreators } from 'src/redux/store/induction/log'
import { InductionLogData } from 'src/typings/kenai/induction-log'
import { call } from 'typed-redux-saga'
import ActionCreator from '../saga-action-creator'
import AccessManager from 'src/utils/AccessManager'
import { getEntityHierarchyConfiguration } from 'src/redux/store/credentials'

// Action Creators
export const ActionCreators = {
  SagaRetrieveInductionLogData: new ActionCreator<
    'SagaRetrieveInductionLogData',
    { EntityHierarchy: string; beginTime: number; endTime: number }
  >('SagaRetrieveInductionLogData'),
}

const generateSearchString = (data: InductionLogData[]) => {
  return data.map((item) => {
    let searchString = ''
    searchString += ` ${[
      item.userDetails.firstName,
      item.userDetails.lastName,
      item.userDetails.email,
      item.userDetails.phoneNumber,
      item.userDetails.personalIdentificationNr,
    ]
      .filter((item) => !!item)
      .join(' ')} `
    searchString += Object.keys(item.inductionType)
    searchString += item.isVerified ? ' verified ' : ' unverified not verified '
    return {
      ...item,
      searchString: searchString.toLowerCase(),
    }
  })
}

const generateAvatarLink = (data: InductionLogData[]) => {
  return data.map((item) => {
    const userImage = {
      s3ImagePath: 'public/verifiedavatars/',
      s3ImageKey: `${item.ProfileId}.jpeg`,
      EntityHierarchy: 'unkeyed',
    }
    return {
      ...item,
      userImage,
    }
  })
}

function* processRetrieveInductionLogData(action: typeof ActionCreators['SagaRetrieveInductionLogData']) {
  try {
    yield put(AuthenticationActionCreators.SagaRefreshApiCredentials.create())
    yield take(AuthenticationActionCreators.SagaApiCredentialsRefreshUpdate.type)
    const beginTime = checkIfTimeExists(action, 'beginTime') ? action.payload.beginTime.valueOf() : moment().startOf('day').valueOf()
    const endTime = checkIfTimeExists(action, 'endTime') ? action.payload.endTime.valueOf() : 9999999999999

    const entityHierarchyConfiguration = yield select(getEntityHierarchyConfiguration)

    const data = yield* call(
      inductionLogRetrieval,
      beginTime,
      endTime,
      AccessManager.selectedHierarchy.hierarchyStructure,
      entityHierarchyConfiguration
    )

    const sortedData = data.sort((a, b) => b.evtTimeStamp - a.evtTimeStamp)

    yield put(InductionLogActionCreators.StoreSetInductionLogData.create(generateAvatarLink(generateSearchString(sortedData))))
    action?.callback?.({ status: 'success' })
  } catch (e) {
    console.error(e || 'Error retrieving log')
    action?.callback?.({ status: 'failed' })
  }
}

// Saga triggers
function* watchParkingBookingSagas() {
  yield takeLeading(ActionCreators.SagaRetrieveInductionLogData.type, processRetrieveInductionLogData)
  yield null
}

// Saga hooks
export default function* parkingBookingSagas() {
  yield all([fork(watchParkingBookingSagas)])
}
