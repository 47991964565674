import React, { ReactNode } from 'react'

interface FormFieldProps {
  label?: string
  subTitle?: string
  heading?: string
  description?: ReactNode | string
  help?: ReactNode | string
  htmlFor?: string
  className?: string
  inline?: boolean
  verticalCenterLabel?: boolean
  inputCenteredLabel?: boolean
}

const FormField: React.FC<FormFieldProps> = ({
  htmlFor,
  label = '',
  subTitle,
  heading,
  description,
  help,
  children,
  className = '',
  inline = true,
  verticalCenterLabel = false,
  inputCenteredLabel = false,
  ...other
}) => {
  return (
    <div {...other} className='mb-2 custom-form-item'>
      <div className={`flex flex-wrap ${verticalCenterLabel ? 'items-center' : 'items-start'} ${className} `}>
        <div
          className={`w-full form-item-label text-vars-tabsGray ${inline ? 'w-full md:w-1/2 lg:w-1/4' : 'mb-1'} ${
            inputCenteredLabel ? 'pt-3' : ''
          }`}
        >
          {label && (
            <label htmlFor={htmlFor} className='text-size-label text-vars-tabsGray' aria-label={htmlFor || label}>
              {label}:
            </label>
          )}
          {subTitle && (
            <div style={{ textTransform: 'none' }} className='inline ml-2 font-medium sm:block sm:ml-0'>
              {subTitle}
            </div>
          )}
        </div>
        <div className='flex-1 form-item-content'>
          {heading && <div className='text-vars-disabledColor'>{heading}</div>}
          {description && <div className='mb-2 text-vars-disabledColor'>{description}</div>}
          <div className={!heading && !description ? '' : ''}>{children}</div>
          {help && <div className='text-vars-disabledColor'>{help}</div>}
        </div>
      </div>
    </div>
  )
}

export default FormField
