import { cloneDeep, findIndex, orderBy } from 'lodash'
import moment from 'moment'
import { actionChannel, all, call, fork, put, select, take, takeLeading } from 'redux-saga/effects'
import API from 'src/api'
import { API_PRE_SCREENING_ADMIN_RESET, API_PRE_SCREENING_LOG_EVENTS } from 'src/api/workerLogOperations/screening-log-api'
import { actionHasKey } from 'src/redux/helpers/functions'
import { ActionCreators as AuthenticationActionCreators } from 'src/redux/sagas/authentication'
import { ActionCreators as CredentialsStoreActionCreators, getEntityHierarchyConfiguration } from 'src/redux/store/credentials'
import {
  ActionCreators as StoreActionCreators,
  EmployeeScreeningLogSelectors as StoreSelectors,
  getEmployeeScreeningLogData,
} from 'src/redux/store/employees/screening-log-store'
import { EmployeePreScreeningLogSearchableEventSet } from 'src/redux/store/employees/types/screening-log'
import { getEmployeePreScreeningSubscriptionCredential } from 'src/redux/store/selectors'
import { notification } from 'src/utils'
import AccessManager from 'src/utils/AccessManager'
import ActionCreator from '../saga-action-creator'
import { calculateSearchStringForItem, convertDBToLogItem, convertToLogItems } from './helpers/screening-log'
import { getLiveUpdateEarliestTimestamp } from 'src/redux/store/dashboard/globalevents'
import { SCREENING_STATUS } from 'src/typings/kenai/enums/enums'

interface LiveUpdateHandlerEvent extends Pick<EmployeePreScreeningLogSearchableEventSet, 'EntityHierarchy' | 'preScreeningDateProfileID'> {
  eventType: 'REMOVE' | 'MODIFY' | 'INSERT'
}

// Action Creators
export const ActionCreators = {
  SagaRetrieveEmployeeScreeningLogData: new ActionCreator<
    'SagaRetrieveEmployeeScreeningLogData',
    { beginTime: number; endTime: number; shouldSubscribeToUpdates?: boolean; stronglyConsistent?: boolean }
  >('SagaRetrieveEmployeeScreeningLogData'),
  SagaRetrieveEmployeeScreeningTransferEvent: new ActionCreator<
    'SagaRetrieveEmployeeScreeningTransferEvent',
    { EntityHierarchy: string; transferredPreScreeningDateProfileID: string; currentPreScreeningDateProfileID: string }
  >('SagaRetrieveEmployeeScreeningTransferEvent'),
  SagaRefreshEmployeeScreeningLogSubscriptionCredential: new ActionCreator<'SagaRefreshEmployeeScreeningLogSubscriptionCredential', string>(
    'SagaRefreshEmployeeScreeningLogSubscriptionCredential'
  ),
  SagaEmployeeScreeningLiveUpdateHandler: new ActionCreator<
    'SagaEmployeeScreeningLiveUpdateHandler',
    { messageReceivedTimeStamp: number; events: LiveUpdateHandlerEvent[] }
  >('SagaEmployeeScreeningLiveUpdateHandler'),
  SagaAdminResetScreening: new ActionCreator<
    'SagaAdminResetScreening',
    {
      EntityHierarchy: string
      preScreeningDateProfileID: string
      lmt: number
      resetNote: string
      shouldDeleteInstance: boolean
      screeningStatus: SCREENING_STATUS
      shouldDeleteToken: boolean
    }
  >('SagaAdminResetScreening'),
}

const AWSHelper = new API.AWSHelpers()

function* processRetrieveEmployeeScreeningLogData(action: typeof ActionCreators.SagaRetrieveEmployeeScreeningLogData) {
  //SagaRetrieveEmployeeScreeningLogData
  try {
    yield put(StoreActionCreators.StoreSetEmployeeScreeningLogLoading.create(true))

    yield put(AuthenticationActionCreators.SagaRefreshApiCredentials.create())
    yield take(AuthenticationActionCreators.SagaApiCredentialsRefreshUpdate.type)
    const entityHierarchyConfiguration = yield select(getEntityHierarchyConfiguration)
    const entityHierarchy = AccessManager.selectedHierarchy.hierarchyStructure
    const beginTime = actionHasKey(action, 'beginTime') ? action.payload.beginTime : moment().startOf('day').valueOf()
    const endTime = actionHasKey(action, 'endTime') ? action.payload.endTime : 9999999999999
    const shouldSubscribeToUpdates = actionHasKey(action, 'shouldSubscribeToUpdates') ? action.payload.shouldSubscribeToUpdates : false
    const stronglyConsistent = action.payload.stronglyConsistent
    const response: API_PRE_SCREENING_LOG_EVENTS = yield call(
      [AWSHelper, AWSHelper.employeeApi.screeningLog.getPreScreeningLogEvents],
      beginTime,
      endTime,
      entityHierarchy,
      entityHierarchyConfiguration,
      stronglyConsistent
    )

    if (!response || response.key !== 'LOG_RETRIEVED' || !response.logItems) throw new Error('Failed to retrieve screening log data')

    const selectionLogs = response.logItems.filter((log) => log.preScreeningDate >= beginTime)

    const orderedEvents = orderBy(
      selectionLogs,
      ['preScreeningDate', 'leadingSubmission.processingTimeStamp', 'employeeDetails.lastName', 'employeeDetails.firstName'],
      ['desc', 'desc', 'asc', 'asc']
    )

    const searchableConvertedLogItems = convertToLogItems(orderedEvents, entityHierarchyConfiguration)

    yield put(StoreActionCreators.StoreSetEmployeeScreeningLogData.create(searchableConvertedLogItems))

    yield put(StoreActionCreators.StoreSetShouldSubscribeToUpdates.create(!!shouldSubscribeToUpdates))

    yield put(StoreActionCreators.StoreSetEmployeeScreeningLogLoading.create(false))
    if (action.callback) action.callback({ status: 'success' })
  } catch (e) {
    if (e) console.error(e)
    yield put(StoreActionCreators.StoreSetEmployeeScreeningLogLoading.create(false))
    if (action.callback)
      action.callback({
        status: 'failed',
        message: 'We had an issue retrieving the employee screening logs. Please try again or contact support.',
      })
  }
}

function* processRetrieveEmployeeScreeningTransferEvent(action: typeof ActionCreators.SagaRetrieveEmployeeScreeningTransferEvent) {
  //SagaRetrieveEmployeeScreeningLogData
  try {
    // yield put(StoreActionCreators.StoreClearAllEmployeeLogData.create())
    yield put(AuthenticationActionCreators.SagaRefreshApiCredentials.create())
    yield take(AuthenticationActionCreators.SagaApiCredentialsRefreshUpdate.type)
    const entityHierarchyConfiguration = yield select(getEntityHierarchyConfiguration)

    const response: API_PRE_SCREENING_LOG_EVENTS = yield call(
      [AWSHelper, AWSHelper.employeeApi.screeningLog.getSelectedPreScreeningLogs],

      [
        {
          EntityHierarchy: action.payload.EntityHierarchy,
          preScreeningDateProfileID: action.payload.transferredPreScreeningDateProfileID,
        },
      ]
    )

    if (!response || response.key !== 'LOG_RETRIEVED' || !response.logItems) throw new Error('Failed to retrieve screening log data')

    const transferredEvent = response.logItems[0]
    const previousData: EmployeePreScreeningLogSearchableEventSet[] = yield select(getEmployeeScreeningLogData)

    const nextData: typeof previousData = []

    previousData.forEach((item) => {
      const logItem = cloneDeep(item)
      if (logItem.preScreeningDateProfileID === action.payload.currentPreScreeningDateProfileID) {
        const enrichedPreviousSubmissionItem = convertDBToLogItem(transferredEvent, entityHierarchyConfiguration)
        delete enrichedPreviousSubmissionItem.previousSubmissions //a previous submission item does not need previous submissions of its own
        const additionalSearchString = calculateSearchStringForItem(
          transferredEvent,
          enrichedPreviousSubmissionItem.leadingSubmission.submissionValues,
          entityHierarchyConfiguration
        )
        if (!logItem.previousSubmissions) logItem.previousSubmissions = []
        logItem.previousSubmissions.push(enrichedPreviousSubmissionItem)
        logItem.previousSubmissions.forEach((previousSubmission) => {
          if (
            previousSubmission.leadingSubmission.transferredPreScreeningDateProfileID ===
            action.payload.transferredPreScreeningDateProfileID
          ) {
            previousSubmission.alreadyFetchedTransfer = true
          }
        })
        logItem.alreadyFetchedTransfer = true
        logItem.searchString += additionalSearchString
      }
      nextData.push(logItem)
    })

    console.log(nextData)

    yield put(StoreActionCreators.StoreSetEmployeeScreeningLogData.create(nextData))
    if (action.callback) action.callback({ status: 'success' })
  } catch (e) {
    if (e) console.error(e)
    if (action.callback)
      action.callback({
        status: 'failed',
        message: 'We had an issue retrieving the employee screening logs. Please try again or contact support.',
      })
  }
}

function* processRefreshEmployeeScreeningLogSubscriptionCredential(
  action: typeof ActionCreators.SagaRefreshEmployeeScreeningLogSubscriptionCredential
) {
  try {
    yield put(AuthenticationActionCreators.SagaRefreshApiCredentials.create())
    yield take(AuthenticationActionCreators.SagaApiCredentialsRefreshUpdate.type)
    const currentSubscriptionCredential = yield select(getEmployeePreScreeningSubscriptionCredential)
    const clientId = action.payload
    let shouldRetrieveNewCredentials = true
    if (currentSubscriptionCredential && currentSubscriptionCredential.Expiration) {
      const expiry = moment(currentSubscriptionCredential.Expiration)
      const now = moment()
      const diff = expiry.diff(now)
      if (diff / 1000 > 60 * 5) {
        //allow for 5 minute clock skew
        shouldRetrieveNewCredentials = false
      }
    }
    if (shouldRetrieveNewCredentials) {
      const entityHierarchyConfiguration = yield select(getEntityHierarchyConfiguration)
      const topics: Array<string> = []
      entityHierarchyConfiguration.hierarchy.deepStructure.forEach((hierarchy) => {
        //just subscribe to the root
        const topicWithOutTrailingSlash = `preScreeningEvents/${hierarchy.hierarchyStructure.replace(/#/g, '/')}`
        if (topics.length < 8) {
          //never subscribe to more than 8
          topics.push(topicWithOutTrailingSlash)
        }
      })

      const subscriptionCredential = yield call([AWSHelper, AWSHelper.retrieveSubscribeCredential], topics, clientId)
      if (subscriptionCredential.key === 'CREDENTIAL_RETRIEVED') {
        yield put(
          CredentialsStoreActionCreators.StoreSetEmployeePreScreeningSubscriptionCredential.create({
            ...subscriptionCredential.credential,
            ts: Date.now(),
          })
        )
      } else {
        yield put(CredentialsStoreActionCreators.StoreSetEmployeePreScreeningSubscriptionCredential.create(undefined))
      }
    } else if (!shouldRetrieveNewCredentials && currentSubscriptionCredential) {
      yield put(
        CredentialsStoreActionCreators.StoreSetEmployeePreScreeningSubscriptionCredential.create({
          ...currentSubscriptionCredential,
          ts: Date.now(),
        })
      ) //triggers gDSfP in subscription component
    }
  } catch (e) {
    if (e) {
      console.error(e)
    }
    yield put(CredentialsStoreActionCreators.StoreSetEmployeePreScreeningSubscriptionCredential.create(undefined))
  }
}

function* processEmployeeScreeningLiveUpdateHandler(action: typeof ActionCreators.SagaEmployeeScreeningLiveUpdateHandler) {
  try {
    const eventPayload = action.payload
    const liveUpdateEarliestTimestamp = yield select(getLiveUpdateEarliestTimestamp)
    if (eventPayload.messageReceivedTimeStamp > liveUpdateEarliestTimestamp) {
      // let beginTime = moment().startOf('day').valueOf()
      const entityHierarchyConfiguration = yield select(getEntityHierarchyConfiguration)
      const availableHierarchies = new Map()
      const addRecursive = (additionalChildren) => {
        for (const prop of additionalChildren) {
          availableHierarchies.set(prop.hierarchyStructure, true)
          if (prop.children.length > 0) {
            addRecursive(prop.children)
          }
        }
      }
      if (AccessManager.selectedHierarchy.hierarchyStructure === 'ALL') {
        addRecursive(entityHierarchyConfiguration.hierarchy.deepStructure)
      } else {
        addRecursive([entityHierarchyConfiguration.hierarchy.flatStructure[AccessManager.selectedHierarchy.hierarchyStructure]])
      }
      const deletions = new Map()
      const changes = new Map()
      const newEntries = new Map()
      eventPayload.events.forEach((logEvent) => {
        if (logEvent.eventType === 'REMOVE' && availableHierarchies.get(logEvent.EntityHierarchy) === true) {
          deletions.set(`${logEvent.preScreeningDateProfileID}-${logEvent.EntityHierarchy}`, logEvent)
        } else if (logEvent.eventType === 'MODIFY' && availableHierarchies.get(logEvent.EntityHierarchy) === true) {
          changes.set(`${logEvent.preScreeningDateProfileID}-${logEvent.EntityHierarchy}`, logEvent)
        } else if (logEvent.eventType === 'INSERT' && availableHierarchies.get(logEvent.EntityHierarchy) === true) {
          newEntries.set(`${logEvent.preScreeningDateProfileID}-${logEvent.EntityHierarchy}`, logEvent)
        }
      })
      const itemsToRetrieve: Pick<EmployeePreScreeningLogSearchableEventSet, 'EntityHierarchy' | 'preScreeningDateProfileID'>[] = []
      let retrievedItems: API_PRE_SCREENING_LOG_EVENTS = undefined
      newEntries.forEach((value) => {
        if (
          itemsToRetrieve.findIndex(
            (itemToRetrieve) =>
              itemToRetrieve.EntityHierarchy === value.EntityHierarchy &&
              itemToRetrieve.preScreeningDateProfileID === value.preScreeningDateProfileID
          ) === -1
        ) {
          itemsToRetrieve.push({
            EntityHierarchy: value.EntityHierarchy,
            preScreeningDateProfileID: value.preScreeningDateProfileID,
          })
        }
      })
      changes.forEach((value) => {
        if (
          itemsToRetrieve.findIndex(
            (itemToRetrieve) =>
              itemToRetrieve.EntityHierarchy === value.EntityHierarchy &&
              itemToRetrieve.preScreeningDateProfileID === value.preScreeningDateProfileID
          ) === -1
        ) {
          itemsToRetrieve.push({
            EntityHierarchy: value.EntityHierarchy,
            preScreeningDateProfileID: value.preScreeningDateProfileID,
          })
        }
      })
      if (itemsToRetrieve.length > 0) {
        yield put(AuthenticationActionCreators.SagaRefreshApiCredentials.create())
        yield take(AuthenticationActionCreators.SagaApiCredentialsRefreshUpdate.type)
        retrievedItems = yield call([AWSHelper, AWSHelper.employeeApi.screeningLog.getSelectedPreScreeningLogs], itemsToRetrieve)
      }
      const nextLogData = cloneDeep(yield select(StoreSelectors.getEmployeeScreeningLogData))
      deletions.forEach((value) => {
        const indexDelete = findIndex(nextLogData, {
          EntityHierarchy: value.EntityHierarchy,
          preScreeningDateProfileID: value.preScreeningDateProfileID,
        })
        if (indexDelete !== -1) {
          nextLogData.splice(indexDelete, 1)
        }
      })
      if (retrievedItems && retrievedItems.key === 'LOG_RETRIEVED' && retrievedItems.logItems) {
        retrievedItems.logItems.forEach((retrievedItem) => {
          const changedEntry = changes.get(`${retrievedItem.preScreeningDateProfileID}-${retrievedItem.EntityHierarchy}`)
          const newEntry = newEntries.get(`${retrievedItem.preScreeningDateProfileID}-${retrievedItem.EntityHierarchy}`)
          if (changedEntry) {
            const indexCurrent = findIndex(nextLogData, {
              EntityHierarchy: retrievedItem.EntityHierarchy,
              preScreeningDateProfileID: retrievedItem.preScreeningDateProfileID,
            })
            if (indexCurrent !== -1) {
              nextLogData.splice(indexCurrent, 1, convertDBToLogItem(retrievedItem, entityHierarchyConfiguration))
            } else if (!deletions.get(`${retrievedItem.preScreeningDateProfileID}-${retrievedItem.EntityHierarchy}`)) {
              nextLogData.push(convertDBToLogItem(retrievedItem, entityHierarchyConfiguration))
            }
          } else if (newEntry) {
            const indexCurrent = findIndex(nextLogData, {
              EntityHierarchy: retrievedItem.EntityHierarchy,
              preScreeningDateProfileID: retrievedItem.preScreeningDateProfileID,
            })
            if (indexCurrent === -1) {
              nextLogData.push(convertDBToLogItem(retrievedItem, entityHierarchyConfiguration))
            }
          }
        })
      }
      const orderedEvents = orderBy(
        nextLogData,
        ['preScreeningDate', 'leadingSubmission.processingTimeStamp', 'employeeDetails.lastName', 'employeeDetails.firstName'],
        ['desc', 'desc', 'asc', 'asc']
      )

      yield put(StoreActionCreators.StoreSetEmployeeScreeningLogData.create(orderedEvents))
    }
    if (action.callback) action.callback({ status: 'success' })
  } catch (e) {
    if (e) console.log(e)
    if (action.callback) action.callback({ status: 'failed', message: e?.message ?? 'could live update employee log' })
  }
}

function* processSagaAdminResetScreening(action: typeof ActionCreators.SagaAdminResetScreening) {
  //SagaRetrieveEmployeeScreeningLogData
  try {
    // yield put(StoreActionCreators.StoreClearAllEmployeeLogData.create())
    yield put(AuthenticationActionCreators.SagaRefreshApiCredentials.create())
    yield take(AuthenticationActionCreators.SagaApiCredentialsRefreshUpdate.type)
    const entityHierarchyConfiguration = yield select(getEntityHierarchyConfiguration)

    const response: API_PRE_SCREENING_ADMIN_RESET = yield call(
      [AWSHelper, AWSHelper.employeeApi.screeningLog.adminResetScreening],
      action.payload.EntityHierarchy,
      action.payload.preScreeningDateProfileID,
      action.payload.lmt,
      action.payload.resetNote,
      action.payload.shouldDeleteInstance,
      action.payload.screeningStatus,
      action.payload.shouldDeleteToken
    )
    if (response && (response.key === 'ADMIN_RESET_SCREENING_SUCCESSFUL' || response.key === 'ADMIN_DELETE_SCREENING_SUCCESSFUL')) {
      if (response.key === 'ADMIN_DELETE_SCREENING_SUCCESSFUL') {
        const nextLogData = cloneDeep(yield select(getEmployeeScreeningLogData))
        yield put(
          StoreActionCreators.StoreSetEmployeeScreeningLogData.create(
            nextLogData.filter(
              (logItem) =>
                (logItem.EntityHierarchy === action.payload.EntityHierarchy &&
                  logItem.preScreeningDateProfileID !== action.payload.preScreeningDateProfileID) ||
                logItem.EntityHierarchy !== action.payload.EntityHierarchy
            )
          )
        )
        notification('success', 'The status has successfully been reset, the screening can be processed again')
        if (action.callback) action.callback({ status: 'success' })
      } else if (response.key === 'ADMIN_RESET_SCREENING_SUCCESSFUL' && response.logItems) {
        const resetItem = response.logItems[0]
        const nextLogData = cloneDeep(yield select(getEmployeeScreeningLogData))
        const indexCurrent = findIndex(nextLogData, {
          EntityHierarchy: resetItem.EntityHierarchy,
          preScreeningDateProfileID: resetItem.preScreeningDateProfileID,
        })
        if (indexCurrent !== -1) {
          nextLogData.splice(indexCurrent, 1, convertDBToLogItem(resetItem, entityHierarchyConfiguration))
        }

        yield put(StoreActionCreators.StoreSetEmployeeScreeningLogData.create(nextLogData))
        notification('success', 'The status has successfully been reset, the screening can be processed again')
        if (action.callback) action.callback({ status: 'success' })
      } else {
        throw new Error('Failed to reset screening status')
      }
    } else {
      if (response && response.key === 'PROCESS_INSTANCE_OBSOLETE') {
        action.callback({
          status: 'failed',
          message: 'The log item is outdated - please refresh the log and try again',
        })
      } else {
        throw new Error('Failed to reset screening status')
      }
    }
  } catch (e) {
    if (e) console.error(e)
    if (action.callback)
      action.callback({
        status: 'failed',
        message: 'We had an issue resetting the screening status. Please try again or contact support.',
      })
  }
}
// Saga triggers
function* watchEmployeeLogSagas() {
  yield takeLeading(ActionCreators.SagaRetrieveEmployeeScreeningLogData.type, processRetrieveEmployeeScreeningLogData)
  yield takeLeading(ActionCreators.SagaRetrieveEmployeeScreeningTransferEvent.type, processRetrieveEmployeeScreeningTransferEvent)
  yield takeLeading(
    ActionCreators.SagaRefreshEmployeeScreeningLogSubscriptionCredential.type,
    processRefreshEmployeeScreeningLogSubscriptionCredential
  )
  yield takeLeading(ActionCreators.SagaAdminResetScreening.type, processSagaAdminResetScreening)

  yield null
}

function* watchEmployeeLiveUpdateChannel() {
  const SagaEmployeeScreeningLiveUpdateHandlerChannel = yield actionChannel(ActionCreators.SagaEmployeeScreeningLiveUpdateHandler.type)
  while (true) {
    const action = yield take(SagaEmployeeScreeningLiveUpdateHandlerChannel)
    yield call(processEmployeeScreeningLiveUpdateHandler, action)
  }
}

// Saga hooks
export default function* screeningLogSagas() {
  yield all([fork(watchEmployeeLogSagas), fork(watchEmployeeLiveUpdateChannel)])
}
