import { filterColumnOptions } from '../table/table-column'
import { EvacResponse, EntityHierarchyConfig } from 'src/typings/kenai/configuration/entity-hierarchy'
import { getEvacuationStatusConfig } from './utils/get-evacuation-status-config'
import { getEvacuationStatus } from './evacuation-cell'
// import { uniqBy } from 'lodash'
import { getEmployeeOutOfScopeForEvac, getVisitorOutOfScopeForEvac, getParkingOutOfScopeForEvac } from './utils/get-out-of-scope-for-evac'
import { EmployeeLogItemEventPair } from 'src/redux/store/employees/types/employee-log'
import { VisitorLogItem } from 'src/redux/store/visitors/types/log'
import { ParkingLogSearchableEventSet } from 'src/typings/kenai/parking/log'
import { EvacuationResponse } from 'src/containers/Messaging/Evacuation'

interface RecordType {
  EntityHierarchy: string
  currentEvacResponse?: EvacResponse
  evtTimeStampOut: 'still on premises' | number
}

interface FilterColumnOptions<Record> {
  data: Record[]
  evacuationConfig: EntityHierarchyConfig['additionalConfigs']['evacuation']
  normalizeRecord: (record: Record) => RecordType
}

type Status = ReturnType<typeof getEvacuationStatus>

const getStatusText = (status: Status) => {
  if (status === 'NOT_SAFE') {
    return 'Not Safe'
  }
  if (status === 'SAFE') {
    return 'Safe'
  }
  if (status === 'SIGNED_OUT') {
    return 'Signed Out'
  }
  if (status === 'NOTIFIED') {
    return 'Notified'
  }
  if (status === 'NO_RESPONSE') {
    return 'Notified'
  }
  return status
}

const uniqueFilters = [
  { text: 'N/A', value: 'N/A' as const },
  { text: 'Signed Out', value: 'Signed Out' as const },
  { text: 'Safe', value: 'Safe' as const },
  { text: 'Not Safe', value: 'Not Safe' as const },
  { text: 'Notified', value: 'Notified' as const },
]

export const createEvacColumnFilter = <
  Record extends EmployeeLogItemEventPair | VisitorLogItem | ParkingLogSearchableEventSet | EvacuationResponse
>(
  options: FilterColumnOptions<Record>,
  activeFilters?: typeof uniqueFilters[number]['value'][]
) => {
  const filters = activeFilters ? uniqueFilters.filter((filter) => activeFilters.includes(filter.value)) : uniqueFilters

  return filterColumnOptions<Record>({
    onFilter: (value: Status, record) => {
      if (!value) {
        return true
      }

      const evacRecord = options.normalizeRecord(record)
      const { evacuationEvent } = getEvacuationStatusConfig(options.evacuationConfig, evacRecord.EntityHierarchy)

      let isOutOfScope = false

      if ('clockInEvent' in record) {
        isOutOfScope = getEmployeeOutOfScopeForEvac(
          (record as EmployeeLogItemEventPair).clockInEvent,
          options.data as EmployeeLogItemEventPair[]
        )
      } else if ('leadingEvent' in record) {
        isOutOfScope = getParkingOutOfScopeForEvac(record as ParkingLogSearchableEventSet, options.data as ParkingLogSearchableEventSet[])
      } else if ('visitorFullDetails' in record) {
        isOutOfScope = getVisitorOutOfScopeForEvac(record as VisitorLogItem, options.data as VisitorLogItem[])
      }

      const status = isOutOfScope
        ? 'N/A'
        : getEvacuationStatus(evacRecord.currentEvacResponse, evacRecord.evtTimeStampOut, evacuationEvent?.activatedTimestamp)

      return getStatusText(status) === value
    },
    filters,
  })
}
