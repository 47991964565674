import * as Sentry from '@sentry/react'
import { ErrorBoundaryProps } from '@sentry/react/dist/errorboundary'
// import { Alert, Typography } from 'antd'
import React, { ReactNode } from 'react'
import ErrorFallback from './error-fallback'
// import config from 'src/config'
// import { cssvars } from 'src/styles'

// const ReportErrorButton = ({ eventId, message }) => {
//   const [loading, setLoading] = useState(false)

//   const handleShowDialog = () => {
//     setLoading(true)
//     Sentry.showReportDialog({ eventId: eventId, onLoad: () => setLoading(false) })
//   }

//   return (
//     <Typography.Paragraph>
//       {message}
//       You can{' '}
//       <Typography.Link onClick={handleShowDialog} disabled={loading}>
//         send a report
//       </Typography.Link>{' '}
//       to help our team debug this error
//     </Typography.Paragraph>
//   )
// }

// export const reportSentryError = (error, errorInfo?: Record<string, any>, callback?: (id: string) => void) => {
//   Sentry.withScope((scope) => {
//     if (errorInfo) scope.setExtras(errorInfo)
//     const eventId = Sentry.captureException(error)
//     if (callback) callback(eventId)
//   })
// }

// class ErrorBoundary extends Component<{ hideButton?: boolean; message?: string; name?: string }, any> {
//   constructor(props) {
//     super(props)
//     this.state = { hasError: false, eventId: null, hide: false }
//   }

//   static getDerivedStateFromError() {
//     return { hasError: true }
//   }

//   componentDidCatch(error, errorInfo) {
//     if (config.enableSentry) {
//       reportSentryError(error, errorInfo, (eventId) => this.setState({ eventId }))
//     }
//   }

//   render() {
//     if (this.state.hasError) {
//       //render fallback UI
//       return (
//         <Alert.ErrorBoundary>
//           <div className='pb-2 text-center error-card'>
//             {this.props.message ? (
//               <>
//                 <Typography.Paragraph>{this.props.message}</Typography.Paragraph>
//                 <ReportErrorButton eventId={this.state.eventId} message='' />
//               </>
//             ) : config.enableSentry && !this.props.hideButton ? (
//               <ReportErrorButton
//                 eventId={this.state.eventId}
//                 message={`An error occurred with ${this.props.name ? this.props.name : 'this component'}, `}
//               />
//             ) : (
//               <Typography.Paragraph>
//                 An error occurred with {this.props.name ? this.props.name : 'this component'}{' '}
//                 {config.enableSentry && ', our team has been notified'}.
//               </Typography.Paragraph>
//             )}
//           </div>
//           <style jsx>{`
//             .error-card {
//               position: relative;
//               margin-top: 20px;
//               border-color: red;
//               border-radius: 3px;
//               background-clip: padding-box;
//               padding: 0.9375rem;
//               background-color: ${cssvars.contrastBackground};
//               border: 1px solid red;
//             }
//             .error-card::before,
//             .error-card::after {
//               background-clip: padding-box;
//               position: absolute;
//               left: 50%;
//             }
//             .error-card::before {
//               border-radius: 40px 40px 0 0;
//               content: '';
//               top: -20px;
//               width: 40px;
//               height: 20px;
//               margin-left: -20px;
//               background-color: ${cssvars.contrastBackground};
//               border: 1px solid red;
//               border-bottom: 0;
//             }
//             .error-card::after {
//               font-family: museo_sans500, Helvetica, sans-serif;
//               font-weight: 700;
//               font-style: normal;
//               border-radius: 25px;
//               content: '!';
//               top: -12.5px;
//               width: 25px;
//               height: 25px;
//               margin-left: -12.5px;
//               line-height: 1;
//               font-size: 1.5625rem;
//               text-align: center;
//               color: red;
//               border: 1px solid red;
//             }
//           `}</style>
//         </Alert.ErrorBoundary>
//       )
//     }

//     //when there's not an error, render children untouched
//     return this.props.children
//   }
// }
// // palette.error[0]
// export default ErrorBoundary

const ErrorBoundary = ({
  children,
  message = '',
  ...props
}: ErrorBoundaryProps & { name?: string; message?: string; children: ReactNode }) => {
  return (
    <Sentry.ErrorBoundary
      fallback={<ErrorFallback message={message || 'An error occurred with this component.'} />}
      {...props}
      beforeCapture={(scope, error, componentStack) => {
        scope.setTag('message', message || 'An error occurred with this component.')
        props?.beforeCapture?.(scope, error, componentStack)
      }}
    >
      {children}
    </Sentry.ErrorBoundary>
  )
}

export default ErrorBoundary
