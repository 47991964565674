import { ActionCreator } from '../store-action-creator'
import { createSelector } from 'reselect'
import update from 'immutability-helper'
import { RootState } from '../rootReducer'
import { CredentialsReduxState } from './types/credentials'

// Action Creators
export const ActionCreators = {
  StoreSetAuthCredentials: new ActionCreator<'StoreSetAuthCredentials', Partial<State['authCredentials']>>('StoreSetAuthCredentials'),
  StoreSetServiceCredentials: new ActionCreator<'ServiceCredentials', Partial<State['serviceCredentials']>>('ServiceCredentials'),
  StoreClearAllCredentials: new ActionCreator<'StoreClearAllCredentials', Partial<void>>('StoreClearAllCredentials'),
  StoreSetEntityHierarchyConfiguration: new ActionCreator<
    'StoreSetEntityHierarchyConfiguration',
    Partial<State['entityHierarchyConfiguration']>
  >('StoreSetEntityHierarchyConfiguration'),
  StoreSetConfigI18nTexts: new ActionCreator<
    'StoreSetConfigI18nTexts',
    Partial<State['entityHierarchyConfiguration']['additionalConfigs']['i18nTexts']>
  >('StoreSetConfigI18nTexts'),
  StoreSetConfigCustomFields: new ActionCreator<
    'StoreSetConfigCustomFields',
    Partial<State['entityHierarchyConfiguration']['additionalConfigs']['customFields']>
  >('StoreSetConfigCustomFields'),
  StoreSetConfigCustomNotifications: new ActionCreator<
    'StoreSetConfigCustomNotifications',
    Partial<State['entityHierarchyConfiguration']['additionalConfigs']['customNotifications']>
  >('StoreSetConfigCustomNotifications'),
  StoreSetConfigFlowTypes: new ActionCreator<
    'StoreSetConfigFlowTypes',
    Partial<State['entityHierarchyConfiguration']['additionalConfigs']['flowTypes']>
  >('StoreSetConfigFlowTypes'),
  StoreSetConfigEvacuation: new ActionCreator<
    'StoreSetConfigEvacuation',
    Partial<State['entityHierarchyConfiguration']['additionalConfigs']['evacuation']>
  >('StoreSetConfigEvacuation'),
  StoreSetVisitorSubscriptionCredential: new ActionCreator<
    'StoreSetVisitorSubscriptionCredential',
    Partial<State['visitorSubscriptionCredential']>
  >('StoreSetVisitorSubscriptionCredential'),
  StoreSetWorkerSubscriptionCredential: new ActionCreator<
    'StoreSetWorkerSubscriptionCredential',
    Partial<State['workerSubscriptionCredential']>
  >('StoreSetWorkerSubscriptionCredential'),
  StoreSetParkingSubscriptionCredential: new ActionCreator<
    'StoreSetParkingSubscriptionCredential',
    Partial<State['parkingSubscriptionCredential']>
  >('StoreSetParkingSubscriptionCredential'),
  StoreSetParkingBookingSubscriptionCredential: new ActionCreator<
    'StoreSetParkingBookingSubscriptionCredential',
    Partial<State['parkingSubscriptionCredential']>
  >('StoreSetParkingBookingSubscriptionCredential'),
  StoreSetEmployeePreScreeningSubscriptionCredential: new ActionCreator<
    'StoreSetEmployeePreScreeningSubscriptionCredential',
    Partial<State['preScreeningSubscriptionCredential']>
  >('StoreSetEmployeePreScreeningSubscriptionCredential'),
  StoreSetGlobalEventSubscriptionCredential: new ActionCreator<
    'StoreSetGlobalEventSubscriptionCredential',
    Partial<State['globalEventSubscriptionCredential']>
  >('StoreSetGlobalEventSubscriptionCredential'),
  StoreSetEvacResponsesSubscriptionCredential: new ActionCreator<
    'StoreSetEvacResponsesSubscriptionCredential',
    Partial<State['evacResponsesSubscriptionCredential']>
  >('StoreSetEvacResponsesSubscriptionCredential'),
  ClearAllStates: new ActionCreator<'ClearAllStates', Partial<void>>('ClearAllStates'),
}

export type Action = typeof ActionCreators[keyof typeof ActionCreators]

export type State = CredentialsReduxState

const initialState = (): State => ({
  authCredentials: {
    idToken: '',
    idTokenPayload: undefined,
    nonce: '',
  },
  serviceCredentials: {
    serviceCredentialExpiry: 0,
    s3: undefined,
    ddb: undefined,
  },
  entityHierarchyConfiguration: {
    hasBeenLoaded: false,
    loadingNonce: 0,
    tenantFlags: [],
    hierarchy: {
      deepStructure: [],
      flatStructure: {},
    },
    additionalConfigs: {
      deltaProfileFields: {},
      induction: {},
      i18nTexts: {},
      flowTypes: {},
      customFields: {},
      customNotifications: {},
      evacuation: {},
      hostLinkingFields: {},
    },
  },
  visitorSubscriptionCredential: undefined,
  workerSubscriptionCredential: undefined,
  parkingSubscriptionCredential: undefined,
  parkingBookingSubscriptionCredential: undefined,
  preScreeningSubscriptionCredential: undefined,
  globalEventSubscriptionCredential: undefined,
})

// Reducer
export default function reducer(state: State = initialState(), action) {
  const updateConfigAttachedToWindow = (config: Record<any, any>) => {
    try {
      if ('sessionStorage' in window) {
        const currentConfig = sessionStorage.getItem('entityHierarchyConfiguration')
        if (currentConfig) {
          const currentConfigObject = JSON.parse(currentConfig)
          sessionStorage.setItem(
            'entityHierarchyConfiguration',
            JSON.stringify({
              ...currentConfigObject,
              ...config,
            })
          )
        } else {
          sessionStorage.setItem('entityHierarchyConfiguration', JSON.stringify(config))
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  switch (action.type) {
    case ActionCreators.StoreSetAuthCredentials.type:
      return update(state, { authCredentials: { $set: action.payload } })
    case ActionCreators.StoreSetEntityHierarchyConfiguration.type:
      updateConfigAttachedToWindow(action.payload)
      return update(state, {
        entityHierarchyConfiguration: {
          $set: {
            ...action.payload,
            hasBeenLoaded: true,
          },
        },
      })
    case ActionCreators.StoreSetConfigI18nTexts.type:
      updateConfigAttachedToWindow({ i18nTexts: action.payload })
      return update(state, {
        entityHierarchyConfiguration: {
          additionalConfigs: {
            i18nTexts: {
              $set: action.payload,
            },
          },
        },
      })
    case ActionCreators.StoreSetConfigCustomFields.type:
      updateConfigAttachedToWindow({ customFields: action.payload })
      return update(state, {
        entityHierarchyConfiguration: {
          additionalConfigs: {
            customFields: {
              $set: action.payload,
            },
          },
        },
      })
    case ActionCreators.StoreSetConfigCustomNotifications.type:
      updateConfigAttachedToWindow({ customNotifications: action.payload })
      return update(state, {
        entityHierarchyConfiguration: {
          additionalConfigs: {
            customNotifications: {
              $set: action.payload,
            },
          },
        },
      })
    case ActionCreators.StoreSetConfigEvacuation.type:
      updateConfigAttachedToWindow({ evacuation: action.payload })
      return update(state, {
        entityHierarchyConfiguration: {
          additionalConfigs: {
            evacuation: {
              $set: action.payload,
            },
          },
        },
      })
    case ActionCreators.StoreSetVisitorSubscriptionCredential.type:
      return update(state, { visitorSubscriptionCredential: { $set: action.payload } })
    case ActionCreators.StoreSetWorkerSubscriptionCredential.type:
      return update(state, { workerSubscriptionCredential: { $set: action.payload } })
    case ActionCreators.StoreSetParkingSubscriptionCredential.type:
      return update(state, { parkingSubscriptionCredential: { $set: action.payload } })
    case ActionCreators.StoreSetParkingBookingSubscriptionCredential.type:
      return update(state, { parkingBookingSubscriptionCredential: { $set: action.payload } })
    case ActionCreators.StoreSetEmployeePreScreeningSubscriptionCredential.type:
      return update(state, { preScreeningSubscriptionCredential: { $set: action.payload } })
    case ActionCreators.StoreSetGlobalEventSubscriptionCredential.type:
      return update(state, { globalEventSubscriptionCredential: { $set: action.payload } })
    case ActionCreators.StoreSetEvacResponsesSubscriptionCredential.type:
      return update(state, { evacResponsesSubscriptionCredential: { $set: action.payload } })
    case ActionCreators.StoreSetServiceCredentials.type:
      return update(state, { serviceCredentials: { $set: action.payload } })
    case ActionCreators.StoreClearAllCredentials.type || ActionCreators.ClearAllStates.type:
      return initialState()
    default:
      return state
  }
}

const getEntityHierarchyConfigurationState = (state: RootState) => state.credentials.entityHierarchyConfiguration
export const getEntityHierarchyConfiguration = createSelector<
  RootState,
  State['entityHierarchyConfiguration'],
  State['entityHierarchyConfiguration']
>([getEntityHierarchyConfigurationState], (state) => state)

const getAuthCredentialsReduxState = (state: RootState) => state.credentials.authCredentials
export const getAuthCredentials = createSelector<RootState, State['authCredentials'], State['authCredentials']>(
  [getAuthCredentialsReduxState],
  (state: State['authCredentials']) => state
)

const getVisitorSubscriptionCredentialState = (state: RootState) => state.credentials.visitorSubscriptionCredential
export const getVisitorSubscriptionCredential = createSelector<
  RootState,
  State['visitorSubscriptionCredential'],
  State['visitorSubscriptionCredential']
>([getVisitorSubscriptionCredentialState], (state) => state)

const getGlobalEventSubscriptionCredentialState = (state: RootState) => state.credentials.globalEventSubscriptionCredential
export const getGlobalEventSubscriptionCredential = createSelector<
  RootState,
  State['globalEventSubscriptionCredential'],
  State['globalEventSubscriptionCredential']
>([getGlobalEventSubscriptionCredentialState], (state) => state)

const getEvacResponsesSubscriptionCredentialState = (state: RootState) => state.credentials.evacResponsesSubscriptionCredential
export const getEvacResponsesSubscriptionCredential = createSelector<
  RootState,
  State['evacResponsesSubscriptionCredential'],
  State['evacResponsesSubscriptionCredential']
>([getEvacResponsesSubscriptionCredentialState], (state) => state)

const geti18nTextConfigurationState = (state: RootState) => state.credentials.entityHierarchyConfiguration.additionalConfigs.i18nTexts
export const getEntityConfigI18nTexts = createSelector<
  RootState,
  State['entityHierarchyConfiguration']['additionalConfigs']['i18nTexts'],
  State['entityHierarchyConfiguration']['additionalConfigs']['i18nTexts']
>([geti18nTextConfigurationState], (state) => state)

const getCustomFieldsConfigurationState = (state: RootState) =>
  state.credentials.entityHierarchyConfiguration.additionalConfigs.customFields
export const getEntityConfigCustomFields = createSelector<
  RootState,
  State['entityHierarchyConfiguration']['additionalConfigs']['customFields'],
  State['entityHierarchyConfiguration']['additionalConfigs']['customFields']
>([getCustomFieldsConfigurationState], (state) => state)

const getCustomNotificationsConfigurationState = (state: RootState) =>
  state.credentials.entityHierarchyConfiguration.additionalConfigs.customNotifications
export const getEntityConfigCustomNotifications = createSelector<
  RootState,
  State['entityHierarchyConfiguration']['additionalConfigs']['customNotifications'],
  State['entityHierarchyConfiguration']['additionalConfigs']['customNotifications']
>([getCustomNotificationsConfigurationState], (state) => state)

const getFlowTypesConfigurationState = (state: RootState) => state.credentials.entityHierarchyConfiguration.additionalConfigs.flowTypes
export const getEntityConfigFlowTypes = createSelector<
  RootState,
  State['entityHierarchyConfiguration']['additionalConfigs']['flowTypes'],
  State['entityHierarchyConfiguration']['additionalConfigs']['flowTypes']
>([getFlowTypesConfigurationState], (state) => state)

const getEvacuationState = (state: RootState) => state.credentials.entityHierarchyConfiguration.additionalConfigs.evacuation
export const getEvacuationConfig = createSelector<
  RootState,
  State['entityHierarchyConfiguration']['additionalConfigs']['evacuation'],
  State['entityHierarchyConfiguration']['additionalConfigs']['evacuation']
>([getEvacuationState], (state) => state)
