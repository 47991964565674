import directory from './directory-api'
import employeeLog from './employee-log-api'
import screeningLog from './screening-log-api'
import s3 from './s3'

export default {
  directory,
  employeeLog,
  screeningLog,
  s3,
}
