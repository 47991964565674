import React from 'react'

import withDefaults from 'src/utils/with-defaults'
import { Typography } from 'antd'

interface Props {
  className?: string
}

const defaultProps = {
  className: '',
}

type NativeAttrs = Omit<React.HTMLAttributes<HTMLHeadingElement>, keyof Props>
export type PageSubtitleProps = Props & typeof defaultProps & NativeAttrs

const PageSubtitle: React.FC<PageSubtitleProps> = ({ className, children, ...props }) => {
  return (
    <Typography.Paragraph className={className} {...props}>
      {children}
    </Typography.Paragraph>
  )
}

const MemoPageSubtitle = React.memo(PageSubtitle)

export default withDefaults(MemoPageSubtitle, defaultProps)
