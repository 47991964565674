import { DynamoDB } from 'aws-sdk'
import { getTableNameFromAwsExports, queryDDBWithBatchLimits } from '../utils'
import { Key } from 'aws-sdk/clients/dynamodb'

export async function retrieveConfigCustomNotifications(requestedHierarchy: string, notificationTypes?: NOTIFICATION_TYPES[]) {
  const typesObject = {}
  let index = 0
  notificationTypes?.forEach(function (value) {
    index++
    typesObject[`:nt${index}`] = value
  })
  const params = (batchHierarchy: string, ExclusiveStartKey?: Key): DynamoDB.DocumentClient.QueryInput => ({
    ExpressionAttributeValues: {
      ':hk': batchHierarchy,
      ...typesObject,
    },
    KeyConditionExpression: `EntityHierarchy = :hk`,
    FilterExpression: Object.keys(typesObject).length > 0 ? `notificationType IN (${Object.keys(typesObject).join(', ')})` : undefined,
    TableName: getTableNameFromAwsExports('mdConfigCustomNotifications'),
    ExclusiveStartKey,
  })

  return await queryDDBWithBatchLimits<ConfigValueCustomNotification>(requestedHierarchy, params)
}
