/**
 * @param condition: boolean
 * @param element: condition met element
 * @param fallback?: condition not met element if undefined return `array`
 */

import { ColumnType } from 'antd/lib/table'

export function conditionalArrayItem<T>(condition, element: T, fallback?: T) {
  return condition ? ([element] as T[]) : fallback ? ([fallback] as T[]) : ([] as T[])
}

/**
 * @param condition: boolean
 * @param element: condition met element
 * @param fallback?: condition not met element if undefined return `object`
 */
export function conditionalObjectProperty<T>(condition, element: T, fallback?: T): any {
  return condition ? element : fallback ? fallback : {}
}

/**
 * @param condition: boolean
 * @param string: condition met string
 * @param fallback: condition not met string if undefined return `string`
 */
export function conditionalString(condition, string: string, fallback?: string) {
  return condition ? `${string}` : fallback ? `${fallback}` : ''
}

/**
 * @param condition: boolean
 * @param props: condition met props
 */
export function conditionalProps<T>(condition, props: T, fallbackProps?: T) {
  return { ...(condition ? { ...props } : fallbackProps ? { ...fallbackProps } : {}) }
}

/**
 * @param condition: boolean
 * @param element: condition met element
 * @param fallback?: condition not met element if undefined return `array`
 */

export function conditionalTableColumn<T, Column = ColumnType<T>>(condition, element: Column, fallback?: Column): Column[] {
  return condition ? [element] : fallback ? [fallback] : []
}
