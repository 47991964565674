import { PaperClipOutlined } from '@ant-design/icons'
import { Button, Modal } from 'antd'
import React, { useState } from 'react'
import API from 'src/api'
import { AgreementData } from 'src/redux/store/visitors/types/log'

const AWSHelper = new API.AWSHelpers()

interface AgreementCell {
  agreementData?: AgreementData
  EntityHierarchy: string
  ProfileId: string
}

export default function AgreementCell({ agreementData, EntityHierarchy, ProfileId }: AgreementCell) {
  const [loading, setLoading] = useState(false)
  const [modal, modelcontext] = Modal.useModal()

  if (!agreementData || (!agreementData?.AgreementVersion ?? true)) {
    return (
      <Button type='link' danger disabled icon={<PaperClipOutlined />} ghost style={{ paddingLeft: '0' }}>
        Not Signed
      </Button>
    )
  }

  const handleShowAgreement = async () => {
    setLoading(true)
    const agreementResponse = await AWSHelper.generateVisitorAgreementRequest(
      EntityHierarchy,
      ProfileId,
      agreementData.AgreementVersion,
      agreementData.signatureTimeStamp
    )
    if (agreementResponse.pdfBase64 && agreementResponse.pdfBase64.length > 0) {
      const buffer = Buffer.from(agreementResponse.pdfBase64, 'base64')
      const pdfBlob = new Blob([new Uint8Array(buffer)], { type: 'application/pdf' })
      const url = window.URL.createObjectURL(pdfBlob)
      setLoading(false)
      modal.info({
        width: '75vw',
        title: 'Visitor Agreement',
        maskClosable: true,
        style: { top: 0 },
        content: (
          <div id='Agreement-Container' className='ng-scope pdfobject-container'>
            <iframe
              title='Agreement'
              src={url}
              style={{
                overflow: 'auto',
                width: '100%',
                height: '75vh',
              }}
            />
          </div>
        ),
      })
    } else {
      setLoading(false)
      modal.error({ title: 'The agreement could not be fetched, please try again.', width: '500px' })
    }
  }

  return (
    <Button type='link' icon={<PaperClipOutlined />} ghost loading={loading} onClick={handleShowAgreement}>
      Signed
      {modelcontext}
    </Button>
  )
}
