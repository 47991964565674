import { faBriefcase } from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Popover, Tree as AntTree } from 'antd'
import { TreeProps } from 'antd/lib/tree'
import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import DropdownButton from 'src/components/dropdown-button'
import ErrorBoundary from 'src/components/error-boundary'
import { ActionCreators as BrandingActions } from 'src/redux/sagas/dashboard/branding'
import { getEntityHierarchyConfiguration } from 'src/redux/store/credentials'
import { FeaturesActionCreators } from 'src/redux/store/features'
import cssvars from 'src/styles/cssvars'
import { EntityHierarchyConfig, HierarchyDeepStructure } from 'src/typings/kenai/configuration/entity-hierarchy'
import AccessManager from 'src/utils/AccessManager'
import { conditionalArrayItem } from 'src/utils/conditionalReturn'
import { getEntityLocationName } from 'src/utils/getLocationName'
import updateIntercom from 'src/utils/updateIntercom'
import { ReactComponent as IconBuilding } from 'src/visual/image/custom-icons/building.svg'
import { ReactComponent as IconMultiBuilding } from 'src/visual/image/custom-icons/multi-building.svg'
import styled from 'styled-components'
import { useRouter } from 'src/hooks/use-router'
import { push } from 'connected-react-router'

const Tree = styled(AntTree)`
  && {
    .ant-tree-iconEle {
      background: ${cssvars.componentBackground};
    }
    .ant-tree-switcher-line-icon.anticon-file {
      opacity: 0;
    }
    .ant-tree-show-line .ant-tree-switcher {
      background: transparent !important;
    }
    .ant-tree-node-content-wrapper:hover {
      background-color: transparent !important;
      color: ${cssvars.primaryColor};
    }
    .ant-tree-node-selected {
      background-color: transparent !important;
      color: ${cssvars.primaryColor};
    }
    .ant-tree-indent-unit-start:not(.ant-tree-indent-unit-end) {
      position: relative;
      height: 100%;
      &::before {
        position: absolute;
        top: calc(100% - 4px);
        right: -41px;
        bottom: -6px;
        border-right: 1px solid ${cssvars.borderColor};
        content: '';
      }
    }
  }
`
type DataNode = import('rc-tree/lib/interface').DataNode
const getHierarchyTree = (entityHierarchyConfiguration: EntityHierarchyConfig) => {
  const getRecursiveTrees = (hierarchy: any) => {
    let title = hierarchy.hierarchyStructure
    const ehFlags = entityHierarchyConfiguration.tenantFlags.find((flags) => flags.EntityHierarchy === hierarchy.hierarchyStructure)
    if (ehFlags && ehFlags.entityLocationData && ehFlags.entityLocationData.locationShortDescription) {
      title = `${ehFlags.entityLocationData.companyName} - ${ehFlags.entityLocationData.locationShortDescription}`
    }
    const children: TreeProps['treeData'] = []
    for (const prop of hierarchy.children) {
      if (entityHierarchyConfiguration.tenantFlags.find((flags) => flags.EntityHierarchy === prop.hierarchyStructure)) {
        //only where we have flags
        const item = getRecursiveTrees(prop)
        if (item) children.push(item)
      }
    }
    // styling checks
    const hasChildren = children.length > 0
    let childrenHasChildren = false
    if (hasChildren) {
      for (const prop of hierarchy.children) {
        if (prop.children && prop.children.length > 0) {
          childrenHasChildren = true
        }
      }
    }
    const BuildingIcons = () => (childrenHasChildren ? <IconMultiBuilding /> : <IconBuilding />)
    return {
      title: title,
      key: hierarchy.hierarchyStructure,
      icon: hasChildren ? <BuildingIcons /> : <FontAwesomeIcon icon={faBriefcase} />,
      className: 'pb-1',
      children,
    } as DataNode
  }
  const ResultStructure: TreeProps['treeData'] = []
  for (const prop of entityHierarchyConfiguration.hierarchy.deepStructure) {
    if (entityHierarchyConfiguration.tenantFlags.find((flags) => flags.EntityHierarchy === prop.hierarchyStructure)) {
      //only where we have flags
      const item = getRecursiveTrees(prop)
      if (item) ResultStructure.push(item)
    }
  }
  return ResultStructure
}
interface EntityLocationTreeProps {
  handleSelectLocation: TreeProps['onSelect']
  activeLink: string
  entityHierarchyConfiguration: EntityHierarchyConfig
}
const EntityLocationTree: React.FC<EntityLocationTreeProps> = ({ handleSelectLocation, activeLink, entityHierarchyConfiguration }) => {
  const intl = useIntl()
  const structuredTreeData = getHierarchyTree(entityHierarchyConfiguration)
  const treeData: TreeProps['treeData'] = [
    ...conditionalArrayItem(entityHierarchyConfiguration.hierarchy.deepStructure.length > 1, {
      title:
        activeLink !== 'ALL'
          ? intl.formatMessage({ id: 'kenai.components.hierarchy-select.select-all-locations' })
          : intl.formatMessage({ id: 'kenai.components.hierarchy-select.all-locations' }),
      key: 'ALL',
      className: 'pb-1',
      icon: <IconMultiBuilding className='mr-1' />,
    }),
    ...structuredTreeData,
  ]
  return <Tree onSelect={handleSelectLocation} showIcon selectedKeys={[activeLink]} defaultExpandAll treeData={treeData} />
}
const returnActiveLink = (entityHierarchyConfiguration: EntityHierarchyConfig) => {
  return entityHierarchyConfiguration &&
    entityHierarchyConfiguration.hierarchy &&
    entityHierarchyConfiguration.hierarchy.deepStructure &&
    entityHierarchyConfiguration.hierarchy.deepStructure.length === 1
    ? (entityHierarchyConfiguration.hierarchy.deepStructure[0].hierarchyStructure as any)
    : AccessManager.selectedHierarchy.hierarchyStructure
}
const HierarchySelect = (props) => {
  const [visible, setVisible] = useState(false)
  const entityHierarchyConfiguration = useSelector(getEntityHierarchyConfiguration)
  // const [activeLink, setActiveLink] = useState('ALL')
  const [activeLink, setActiveLink] = useState<string>(returnActiveLink(entityHierarchyConfiguration))
  const dispatch = useDispatch()
  const intl = useIntl()
  const { location } = useRouter()

  const selectedHierarchyStructure = AccessManager.selectedHierarchy.hierarchyStructure
  const locationName = getEntityLocationName(entityHierarchyConfiguration, selectedHierarchyStructure)

  useEffect(() => {
    setActiveLink(selectedHierarchyStructure)
  }, [selectedHierarchyStructure])

  useEffect(() => {
    updateIntercom({
      ...window.intercomSettings,
      company: {
        company_id: selectedHierarchyStructure,
        name: locationName,
      },
    })
  }, [selectedHierarchyStructure, locationName])

  const handleSelectLocation = (evt) => {
    let selectedEH: any = activeLink
    if (evt && evt.length && evt.length > 0) {
      selectedEH = evt[0]
    }

    if (entityHierarchyConfiguration.hierarchy && entityHierarchyConfiguration.hierarchy.flatStructure) {
      const selectedHierarchy: HierarchyDeepStructure = entityHierarchyConfiguration.hierarchy.flatStructure[selectedEH] as any
      AccessManager.setSelectedEntityHierarchy(selectedHierarchy)
      dispatch(BrandingActions.SagaSetBranding.create())
      dispatch(FeaturesActionCreators.StoreSetFeatures.create({ ehConfig: entityHierarchyConfiguration }))
    }

    const supportedReloading = ['/messaging/evacuation', '/messaging/evacuation/responses', '/visitors/log']
    if (supportedReloading.includes(location.pathname)) {
      dispatch(push(location))
    } else {
      dispatch(push('/'))
    }

    handleVisibleChange()
  }

  const handleVisibleChange = () => {
    setVisible(!visible)
  }

  let currentLocation = ''
  if (activeLink === 'ALL') {
    currentLocation = intl.formatMessage({ id: 'kenai.components.hierarchy-select.all-locations' })
  } else {
    const ehFlags = entityHierarchyConfiguration.tenantFlags.find((flags) => flags.EntityHierarchy === activeLink)
    if (ehFlags && ehFlags.entityLocationData && ehFlags.entityLocationData.locationShortDescription) {
      currentLocation = `${ehFlags.entityLocationData.companyName} - ${ehFlags.entityLocationData.locationShortDescription}`
    } else {
      currentLocation = activeLink
    }
  }
  return (
    <div className='relative'>
      <Popover
        content={
          <div className='px-3 py-4 bg-vars-componentBackground'>
            <ErrorBoundary>
              <EntityLocationTree {...{ handleSelectLocation, activeLink, entityHierarchyConfiguration }} />{' '}
            </ErrorBoundary>
          </div>
        }
        trigger='click'
        visible={visible}
        onVisibleChange={handleVisibleChange}
        placement='bottom'
        overlayClassName={`${props.className || ''} no-padding`}
      >
        <DropdownButton open={visible}>
          <span>
            <IconBuilding style={{ width: '1rem', height: '1rem', marginRight: 5, marginBottom: '-3px' }} />
            {currentLocation}
          </span>
        </DropdownButton>
      </Popover>
      {process.env.NODE_ENV === 'development' && (
        <span className='absolute text-vars-disabledColor' style={{ right: '-90%' }}>
          {/* DEV: <b>{activeLink}</b> */}
        </span>
      )}
    </div>
  )
}

export default HierarchySelect
