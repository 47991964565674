function isObject(value: any): value is Record<string, any> {
  return typeof value === 'object' && value !== null && !Array.isArray(value)
}

function isNumeric(value: string): boolean {
  return !isNaN(value as any) && !isNaN(parseFloat(value)) && isFinite(value as any)
}

function sanitizeValue(value: string): string {
  // Remove leading spaces first to accurately check the first character
  value = value.trim()

  // RULE 1: Check if value is numeric, if so, return it as is
  if (isNumeric(value)) {
    return value
  }

  // RULE 3: Check for leading '=' and remove it
  if (value.startsWith('=')) {
    value = value.substring(1)
  }

  // RULE 4: Prepend "'" to values starting with '+', '-', or '@'
  else if (/^[+\-@]/.test(value)) {
    value = `'${value}`
  }

  // CONFIRMATION 1: Replace CSV separator "," with " | "
  value = value.replace(/,/g, '|')

  // CONFIRMATION 2: Escape double quotes
  value = value.replace(/"/g, '""')

  return value
}

function sanitizeExcelData<T>(data: T): T {
  if (typeof data === 'string') {
    return (sanitizeValue(data) as unknown) as T
  }

  if (Array.isArray(data)) {
    return (data.map((item) => {
      if (isObject(item)) {
        const sanitizedObject: Record<string, any> = {}
        Object.keys(item).forEach((key) => {
          sanitizedObject[key] = sanitizeExcelData(item[key])
        })
        return sanitizedObject
      } else {
        return sanitizeExcelData(item)
      }
    }) as unknown) as T
  }

  if (isObject(data)) {
    const sanitizedObject: Record<string, any> = {}
    Object.keys(data).forEach((key) => {
      sanitizedObject[key] = sanitizeExcelData(data[key])
    })
    return sanitizedObject as T
  }

  return data
}

export { sanitizeExcelData }
