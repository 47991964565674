
const CONSTANTS = {
  ENV_QUALIFIER: 'prd',
  AUTH_PROVIDER: '__AUTH_PROVIDER__',
  AUTH: {
    DOMAIN: 'auth.kenai.co.za',
    CLIENTID: '4ux3tGix2uTlQv8cFl4R4AG5qf805n3X',
    ISS: 'https://auth.kenai.co.za/',
    AUD: '4ux3tGix2uTlQv8cFl4R4AG5qf805n3X',
  },
  AADB2CAUTH: {
    DOMAIN: 'login.kenai.co.za',
    CLIENTID_ACCESS: '939e26af-3e4a-4f7a-b179-3c41638736a6',
    CLIENTID_AUTHZ: '7d7c1cad-f619-4991-b1ac-f431abb085f4',
    ISS: 'https://login.kenai.co.za/7628b694-0cb0-4e02-bae8-70d330cec209/v2.0/',
    AUD: '7d7c1cad-f619-4991-b1ac-f431abb085f4',
    POLICY_ACCESS: 'B2C_1A_KenaiWCL',
    POLICY_AUTHZ: 'B2C_1A_KenaiATZ',
    TENANT: 'login.kenai.co.za',
    SCOPE_ACCESS: 'https://login.kenai.co.za/kenai/access/token',
    SCOPE_AUTHZ: 'https://login.kenai.co.za/kenai/authz/token',
    AUTO_ACTIVATE_DOMAINS: '__ADB2C_AUTO_ACTIVATE_DOMAINS__'
  },
  ANALYTICS: {
    HEAP_APP_ID: '3232262804',
    HOTJAR_APP_ID: '915386'
  },
}

export default CONSTANTS
