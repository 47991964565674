import { faUserEdit, faUserPlus } from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Table, TableProps as AntTableProps, Tag } from 'antd'
import React, { useMemo } from 'react'
import useHierarchyEnabledFlags from 'src/hooks/use-hierarchy-enabled-flags'
import { cn } from 'src/utils/classnames'
import { conditionalProps } from 'src/utils/conditionalReturn'
import { EmployeeImportResponse } from '../../utils/import'
import { returnFullName } from './import-utils'

type TableProps = AntTableProps<EmployeeImportResponse['validData'][number]>

const ValidEmployees = (props: { data: EmployeeImportResponse['validData'] }) => {
  const { employeeLineManagers, employeeAlternatePersonalIdNr, employeeTags } = useHierarchyEnabledFlags()

  const columns = useMemo((): TableProps['columns'] => {
    return [
      {
        dataIndex: 'update',
        title: '',
        render: (update) => (
          <FontAwesomeIcon
            icon={update ? faUserEdit : faUserPlus}
            className={cn('text-4xl opacity-75 mt-1', update ? 'text-info' : 'text-primary')}
          />
        ),
      },
      { key: '', title: 'Full Name', render: (_, record) => returnFullName(record) },
      { dataIndex: ['data', 'personalIdentificationNr'], title: 'ID / Passport Number' },
      { dataIndex: ['data', 'organizationIdValue'], title: 'Organization ID' },
      { dataIndex: ['data', 'alternatePersonalIdNr'], title: 'Alternate Personal identification number' },
      { dataIndex: ['data', 'phoneNumber'], title: 'Phone number' },
      { dataIndex: ['data', 'email'], title: 'Email' },
      {
        dataIndex: ['data', 'lineManagers'],
        title: 'Line Managers',
        render: (lineManagers = []) => lineManagers.map((val, i) => <Tag key={i}>{val}</Tag>),
      },
      { dataIndex: ['data', 'tags'], title: 'Tags', render: (tags = []) => tags.map((val, i) => <Tag key={i}>{val}</Tag>) },
    ].filter(({ dataIndex = '' }) => {
      if (!employeeLineManagers && dataIndex === 'lineManagers') {
        return false
      }

      if (!employeeAlternatePersonalIdNr && dataIndex === 'alternatePersonalIdNr') {
        return false
      }

      if (!employeeTags && dataIndex === 'tags') {
        return false
      }

      return true
    })
  }, [employeeLineManagers, employeeAlternatePersonalIdNr, employeeTags])
  console.log(props.data)

  return (
    <Table
      tableLayout='auto'
      scroll={{ x: 'max-content' }}
      size='small'
      columns={columns}
      dataSource={props.data}
      {...conditionalProps(props.data.length > 4, {
        pagination: {
          pageSize: 4,
        },
      })}
    />
  )
}

export default ValidEmployees
