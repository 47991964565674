import { faAddressBook, faBook, faChartBar, faEnvelopeOpen, faStreetView } from '@fortawesome/pro-light-svg-icons'
import React, { lazy } from 'react'
import { FormattedMessage } from 'react-intl'
import { HierarchyEnabledFlags } from 'src/hooks/use-hierarchy-enabled-flags'
import { conditionalArrayItem } from 'src/utils/conditionalReturn'
import AccessManager from 'src/utils/AccessManager'
import { showRoute } from './show-route'
import { ROUTE } from 'src/typings/kenai/dashboard-router'

const VisitorsAnalytics = lazy(() => import('src/containers/Visitors/Analytics'))
const VisitorsHost = lazy(() => import('src/containers/Visitors/Hosts'))
const InvitesLog = lazy(() => import('src/containers/Visitors/Invites/Log'))
const InviteManagement = lazy(() => import('src/containers/Visitors/Invites/Management'))
const VisitorsLog = lazy(() => import('src/containers/Visitors/Log'))
const AssistedCheckIn = lazy(() => import('src/containers/Visitors/AssistedCheckIn'))

const VisitorsDisabledView = lazy(() => import('src/containers/Visitors/disabled-view'))
const VisitorInvitesDisabledView = lazy(() => import('src/containers/Visitors/Invites/disabled-view'))
const HostDisabledView = lazy(() => import('src/containers/Visitors/Hosts/disabled-view'))

export default function getVisitorRoutes(hierarchyEnabledFlags: HierarchyEnabledFlags, visitorsEnabled: boolean, isLegacyLayout: boolean) {
  const isAdmin = AccessManager.selectedHierarchy.role === 'ADMIN'

  const enabled = {
    inviteLog: (val) => conditionalArrayItem(showRoute(hierarchyEnabledFlags.inviteLog, isLegacyLayout), val),
    hostDirectory: (val) => conditionalArrayItem(showRoute(hierarchyEnabledFlags.hostDirectory, isLegacyLayout), val),
    assistedCheckIn: (val) => conditionalArrayItem(showRoute(true, isLegacyLayout), val),
  }

  const visitorRoute: ROUTE = {
    icon: faStreetView,
    label: <FormattedMessage id='common.visitors' />,
    path: 'visitors',
    sidebar: true,
    component: !visitorsEnabled && isAdmin ? VisitorsDisabledView : undefined,
    children:
      !visitorsEnabled && isAdmin
        ? undefined
        : [
            {
              path: 'visitors/log',
              label: <FormattedMessage id='kenai.sidebar.log' />,
              icon: faBook,
              component: VisitorsLog,
              sidebar: true,
            },
            ...enabled.inviteLog({
              path: 'visitors/invites',
              label: <FormattedMessage id='kenai.sidebar.invites' />,
              icon: faEnvelopeOpen,
              component: hierarchyEnabledFlags.inviteLog ? InvitesLog : VisitorInvitesDisabledView,
              sidebar: true,
              exact: true,
            }),
            ...enabled.hostDirectory({
              path: 'visitors/hosts',
              label: <FormattedMessage id='kenai.sidebar.hosts' />,
              icon: faAddressBook,
              component: hierarchyEnabledFlags.hostDirectory ? VisitorsHost : HostDisabledView,
              sidebar: true,
            }),
            ...enabled.assistedCheckIn({
              path: `visitors/assisted-check-in`,
              component: AssistedCheckIn,
              sidebar: false,
            }),
          ],
  }
  if (visitorRoute.children?.length) {
    if (hierarchyEnabledFlags.visitorAnalytics) {
      visitorRoute.children?.push({
        path: 'visitors/analytics',
        label: <FormattedMessage id='kenai.sidebar.analytics' />,
        icon: faChartBar,
        component: VisitorsAnalytics,
        sidebar: true,
      })
    }

    if (visitorsEnabled && hierarchyEnabledFlags.inviteLog && hierarchyEnabledFlags.inviteCreate) {
      visitorRoute.children?.push({
        path: 'visitors/invites/add',
        component: InviteManagement,
        sidebar: false,
      })
    }
  }
  return visitorRoute
}
