import { EmployeeClockInEvent } from 'src/redux/store/employees/types/employee-log'

export default function resolveEmployeeCheckInFieldTexts(item: EmployeeClockInEvent) {
  if (item.checkInFieldValues && item.flowIdentityType?.additionalFlowDataCapturing?.checkIn?.length) {
    const activeCheckInFields = item.flowIdentityType.additionalFlowDataCapturing.checkIn.filter(
      (checkInField) => checkInField.deleted !== true && checkInField.disabled !== true
    )
    activeCheckInFields.forEach((activeCheckInField) => {
      if (
        activeCheckInField.staticDropDownListItems &&
        (activeCheckInField.inputSettings.inputType === 'staticDropDownListMultiSelect' ||
          activeCheckInField.inputSettings.inputType === 'staticDropDownListSingleSelect')
      ) {
        if (item.checkInFieldValues && item.checkInFieldValues[activeCheckInField.id]) {
          const resolvedParts: string[] = []
          const processStaticItem = (itemDataKey) => {
            const staticDropDownListItem = activeCheckInField.staticDropDownListItems?.find(
              (staticDropDownListItem) => staticDropDownListItem.dataKey === itemDataKey
            )
            if (staticDropDownListItem) {
              resolvedParts.push(staticDropDownListItem.text)
            }
          }
          item.checkInFieldValues[activeCheckInField.id].split(';').forEach((itemDataKey) => processStaticItem(itemDataKey))
          item.checkInFieldValues[activeCheckInField.id] = resolvedParts.join(';')
        }
      }
    })
  }
}
