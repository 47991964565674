import { ActionCreator } from 'src/redux/store/store-action-creator'
import { RootState } from 'src/redux/store/rootReducer'
import { createSelector } from 'reselect'
import update from 'immutability-helper'
import { ConfigValueCustomField } from 'src/typings/kenai/configuration/custom-fields'

// Action Creators
export const ActionCreators = {
  StoreSetCustomFields: new ActionCreator<'StoreSetCustomFields', ConfigValueCustomField[]>('StoreSetCustomFields'),
  StoreSetCustomFieldsLoading: new ActionCreator<'StoreSetCustomFieldsLoading', boolean>('StoreSetCustomFieldsLoading'),
  StoreClearCustomFields: new ActionCreator<'StoreClearCustomFields', undefined>('StoreClearCustomFields'),
  ClearAllStates: new ActionCreator<'ClearAllStates', Partial<void>>('ClearAllStates'),
}

export type Action = typeof ActionCreators[keyof typeof ActionCreators]
export type State = {
  data: ConfigValueCustomField[]
  loading: boolean
}

export const initialState = (): State => ({
  data: [],
  loading: false,
})

// Reducer
export default function reducer(state: State = initialState(), action: Action) {
  switch (action.type) {
    case ActionCreators.StoreSetCustomFields.type:
      return update(state, {
        data: { $set: action.payload },
      })
    case ActionCreators.StoreSetCustomFieldsLoading.type:
      return update(state, {
        loading: { $set: action.payload },
      })
    case ActionCreators.StoreClearCustomFields.type:
      return initialState()
    case ActionCreators.ClearAllStates.type:
      return initialState()
    default:
      return state
  }
}

// Selectors
const getCustomFieldsState = (state: RootState) => state.settings.general.resources.customFields.data
export const getCustomFields = createSelector<RootState, State['data'], State['data']>([getCustomFieldsState], (state) => state)

const getCustomFieldsLoadingState = (state: RootState) => state.settings.general.resources.customFields.loading
export const getCustomFieldsLoading = createSelector<RootState, State['loading'], State['loading']>(
  [getCustomFieldsLoadingState],
  (state) => state
)
