import moment from 'moment'
import { sanitizeExcelData } from './sanitizeExcelData'

export function csvExporter(
  filename: string,
  columns: Array<{
    label: string
    value: string
  }>,
  data: any[]
) {
  import('json2csv').then(({ Parser }) => {
    const json2csvParser = new Parser({ fields: sanitizeExcelData(columns) })
    const exportData = sanitizeExcelData(data)
    const csv = json2csvParser.parse(exportData)

    const exportBlob = new Blob(['\ufeff', csv], {
      type: 'text/csv; charset=utf-8',
    })
    import('file-saver').then((FileSaver) => {
      FileSaver.saveAs(exportBlob, `${filename}_${moment(new Date()).format('DDMMYYYYHHMMSS')}.csv`)
    })
  })
}
