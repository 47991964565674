import { all, fork } from 'redux-saga/effects'
import authentication from './authentication'
import configuration from './configuration'
import credentials from './credentials'
import dashboard from './dashboard'
import reporting from './reporting'
import employees from './employees'
import visitors from './visitors'
import parking from './parking'
import subscriptions from './subscriptions'
import settings from './settings'
import integrations from './integrations'
import induction from './induction'
import evacuation from './evacuation'

export default function* root() {
  yield all([
    fork(authentication),
    fork(credentials),
    fork(configuration),
    fork(dashboard),
    fork(reporting),
    fork(employees),
    fork(visitors),
    fork(parking),
    fork(subscriptions),
    fork(settings),
    fork(integrations),
    fork(induction),
    fork(evacuation),
  ])
}
