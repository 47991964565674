import React from 'react'

import FieldsetFooterStatus from './fieldset-footer-status'
import FieldsetFooterActions from './fieldset-footer-actions'
import { cssvars } from 'src/styles'

interface Props {
  className?: string
}

const defaultProps = {
  className: '',
}

type NativeAttrs = Omit<React.HTMLAttributes<any>, keyof Props>
export type FieldsetFooterProps = Props & typeof defaultProps & NativeAttrs

const FieldsetFooter: React.FC<React.PropsWithChildren<FieldsetFooterProps>> = ({ className, children, ...props }) => {
  return (
    <footer className={className} {...props}>
      {children}
      <style jsx>{`
        footer {
          background-color: ${cssvars.bodyBackground};
          border-top: 1px solid ${cssvars.borderColor};
          border-bottom-left-radius: 5px;
          border-bottom-right-radius: 5px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          overflow: hidden;
          color: ${cssvars.secondaryColor};
          padding: 3px 16pt;
          font-size: 0.875rem;
          min-height: 2.875rem;
          box-sizing: border-box;
        }
      `}</style>
    </footer>
  )
}

FieldsetFooter.defaultProps = defaultProps

type FieldsetFooterComponent<P = Record<any, any>> = React.FC<P> & {
  Status: typeof FieldsetFooterStatus
  Actions: typeof FieldsetFooterActions
}

type ComponentProps = Partial<typeof defaultProps> & Omit<Props, keyof typeof defaultProps> & NativeAttrs

export default FieldsetFooter as FieldsetFooterComponent<ComponentProps>
