import aws_exports from 'src/aws-exports'
import { getCredentialedS3 } from '../utils'

function getPresignedS3URLForEmployeeFilesGet(path, key, entityHierarchy) {
  return new Promise((resolve, reject) => {
    const imagePath = `${path}${key}`
    const paramsImg = {
      Bucket: aws_exports.aws_worker_files_s3_bucket,
      Key: imagePath,
    }
    const s3 = getCredentialedS3(entityHierarchy)
    s3.getSignedUrl('getObject', paramsImg, (err, url) => {
      if (err || !url || typeof url !== 'string') {
        reject(err)
      } else {
        resolve(url)
      }
    })
  })
}

export default {
  getPresignedS3URLForEmployeeFilesGet,
}
