export default function polyfillLocales() {
  if (!Intl.PluralRules) {
    require('@formatjs/intl-pluralrules/polyfill')
    // require(`@formatjs/intl-pluralrules/dist/locale-data/af`)
    // require(`@formatjs/intl-pluralrules/dist/locale-data/de`)
    // require(`@formatjs/intl-pluralrules/dist/locale-data/en`)
    // require(`@formatjs/intl-pluralrules/dist/locale-data/es`)
    // require(`@formatjs/intl-pluralrules/dist/locale-data/fr`)
    // require(`@formatjs/intl-pluralrules/dist/locale-data/nl`)
    // require(`@formatjs/intl-pluralrules/dist/locale-data/ru`)
    // require(`@formatjs/intl-pluralrules/dist/locale-data/zu`)
  }
  // @ts-ignore
  if (!Intl.RelativeTimeFormat) {
    require('@formatjs/intl-relativetimeformat/polyfill')
    // require(`@formatjs/intl-relativetimeformat/dist/locale-data/af`)
    // require(`@formatjs/intl-relativetimeformat/dist/locale-data/de`)
    // require(`@formatjs/intl-relativetimeformat/dist/locale-data/en`)
    // require(`@formatjs/intl-relativetimeformat/dist/locale-data/es`)
    // require(`@formatjs/intl-relativetimeformat/dist/locale-data/fr`)
    // require(`@formatjs/intl-relativetimeformat/dist/locale-data/nl`)
    // require(`@formatjs/intl-relativetimeformat/dist/locale-data/ru`)
    // require(`@formatjs/intl-relativetimeformat/dist/locale-data/zu`)
  }
}
