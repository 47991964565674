import { combineReducers } from 'redux'
import { persistReducer } from 'redux-persist'
import createEncryptor from 'redux-persist-transform-encrypt'
import getStorage from 'src/utils/getStorage'
import { connectRouter, RouterState } from 'connected-react-router'

import authentication, { State as AuthenticationState } from './authentication'
import configuration, { State as ConfigurationState } from './configuration'
import credential, { State as CredentialsState } from './credentials'
import dashboard, { State as DashboardStore } from './dashboard'
import profile, { State as ProfileState } from './profile'
import employees, { State as EmployeesState } from './employees'
import parking, { State as ParkingState } from './parking'
import visitors, { State as VisitorsState } from './visitors'
import settings, { State as SettingsState } from './settings'
import integrations, { State as IntegrationsState } from './integrations'
import induction, { State as InductionState } from './induction'
import features, { State as FeaturesState } from './features'
import messaging, { State as MessagingState } from './messaging'

import reporting from './reporting'
import CONSTANTS from 'src/utils/constants'

const encryptor = createEncryptor({
  secretKey: 'kenai-super-secret-key',
})

const credentialStore = getStorage().credentialStore
const credentialPersistConfig = {
  key: 'credentials',
  storage: credentialStore,
  transforms: [encryptor],
  blacklist: ['apiCredentials', 'entityHierarchyConfiguration'],
}

if (CONSTANTS.AUTH_PROVIDER === 'AADB2C') {
  credentialPersistConfig.blacklist = [
    'apiCredentials',
    'authCredentials',
    'serviceCredentials',
    'entityHierarchyConfiguration',
    'visitorSubscriptionCredential',
    'workerSubscriptionCredential',
    'parkingSubscriptionCredential',
    'preScreeningSubscriptionCredential',
    'globalEventSubscriptionCredential',
  ]
}

export type RootState = {
  router: RouterState
  authentication: AuthenticationState
  configuration: ConfigurationState
  credentials: CredentialsState
  dashboard: DashboardStore
  profile: ProfileState
  reporting: any
  employees: EmployeesState
  parking: ParkingState
  visitors: VisitorsState
  settings: SettingsState
  integrations: IntegrationsState
  induction: InductionState
  features: FeaturesState
  messaging: MessagingState
}

const rootReducer = (history: any) =>
  combineReducers<RootState>({
    router: connectRouter(history),
    authentication: authentication,
    configuration,
    credentials: persistReducer(credentialPersistConfig, credential),
    dashboard,
    profile,
    reporting,
    employees,
    visitors,
    parking,
    settings,
    integrations,
    induction,
    features,
    messaging,
  } as Record<keyof RootState, any>)

export default rootReducer
