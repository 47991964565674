import { DeleteOutlined } from '@ant-design/icons'
import { Button, message, Modal, Table } from 'antd'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { ActionCreators as SagaActions } from 'src/redux/sagas/employees/directory-saga'
import { EmployeeDetails } from 'src/redux/store/employees/types/directory'
import getIntl from 'src/i18n'

export default function DeleteEmployees({
  data,
  intl,
  hierarchyEnabledFlags,
  link,
  className = '',
}: {
  data: EmployeeDetails[]
  intl: any
  hierarchyEnabledFlags: any
  link?: boolean
  className?: string
}) {
  const [loading, setLoading] = useState(false)
  const [visible, setVisible] = useState(false)
  const dispatch = useDispatch()

  const handleDelete = (data) => {
    setLoading(true)
    dispatch(
      SagaActions.SagaDeleteEmployees.withCallback({
        payload: data,
        callback: (res) => {
          setLoading(false)
          setVisible(false)
          if (res.status === 'success' && res.isAsyncProcessing)
            message.warn(
              'The directory is being updated asyncronously due to the large upload size. Please check back in a few minutes to confirm complete processing.',
              5
            )
          if (res.status !== 'success' && res.message) message.error(res.message)
        },
      })
    )
  }

  return (
    <>
      <Button
        type={link ? 'link' : 'default'}
        icon={!link && <DeleteOutlined />}
        onClick={() => setVisible(true)}
        loading={loading}
        className={className}
      >
        {loading ? 'Deleting' : 'Delete'}
      </Button>
      <Modal
        title={`Are you sure you want to delete the selected ${intl
          .formatMessage({ id: `common.${hierarchyEnabledFlags.employeeRouteType}-singular` })
          .toLowerCase()}(s)?`}
        visible={visible}
        confirmLoading={loading}
        onOk={() => handleDelete(data)}
        onCancel={() => setVisible(false)}
        okText='Delete'
        cancelButtonProps={{ danger: true, disabled: loading }}
        closable={!loading}
        maskClosable={!loading}
      >
        <Table
          dataSource={data}
          size='small'
          pagination={data.length > 20 && { pageSize: 20 }}
          rowKey={(record) => record.personalIdentificationNr}
        >
          <Table.Column
            title='Full name'
            key='fullName'
            render={(_text, record: EmployeeDetails) => `${record.firstName} ${record.lastName}`}
          />
          <Table.Column
            title={getIntl().formatMessage({ id: 'personIDValue' })}
            dataIndex='personalIdentificationNr'
            key='personalIdentificationNr'
          />
        </Table>
      </Modal>
    </>
  )
}
