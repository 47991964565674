type result = {
  status: 'success' | 'failed'
  message?: string
  details?: string
  isAsyncProcessing?: boolean
  data?: any
}
type callback = (res: result) => void

declare interface WithCallback<P> {
  payload?: P
  callback: callback
}

class ActionCreator<T extends string, P> {
  readonly type: T

  readonly payload!: P
  readonly callback!: callback

  constructor(type: T) {
    this.type = type
  }

  create = (payload?: P, callback?: callback) => ({
    type: this.type,
    payload,
    callback,
  })
  withCallback = ({ payload, callback }: WithCallback<P>) => ({ type: this.type, payload, callback })
}

export { ActionCreator }
export default ActionCreator
