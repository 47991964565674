import { Modal } from 'antd'
import { all, fork, put, take, takeLeading } from 'redux-saga/effects'
import API from 'src/api'
import { ActionCreators as DashboardBrandingActionCreators } from 'src/redux/sagas/dashboard/branding'
import { ActionCreators as ConfigurationActionCreators } from 'src/redux/store/configuration'
import { ActionCreators as AuthenticationActionCreators } from '../authentication'
import ActionCreator from '../saga-action-creator'

// type InductionConfig = ConfigurationState['values']['induction']

// interface InductionPayload extends InductionConfig {
//   inductionSubmission?: ConfigState['values']['induction']
//   inductionVideoProcessing?: ConfigState['inductionVideoProcessing']
// }

type ActionSaveTextConfig = Partial<ConfigurationState['values']['texts']>
type ActionSaveFeatureConfig = Partial<ConfigurationState['values']['featureConfig']>
type ActionSaveImagesConfig = Partial<ConfigurationState['values']['images']>
// type ActionSaveInductionConfig = Partial<InductionPayload>
type ActionSaveBrandingConfig = Partial<ConfigurationState['values']['brandingColors']>
type ActionSaveLocationConfig = Partial<ConfigurationState['values']['locationConfig']>

// Action Creators
export const ActionCreators = {
  SagaRetrieveCurrentConfiguration: new ActionCreator<'SagaRetrieveCurrentConfiguration', void>('SagaRetrieveCurrentConfiguration'),
  SagaSaveTextsConfiguration: new ActionCreator<'SagaSaveTextsConfiguration', ActionSaveTextConfig>('SagaSaveTextsConfiguration'),
  SagaSaveFeaturesConfiguration: new ActionCreator<'SagaSaveFeaturesConfiguration', ActionSaveFeatureConfig>(
    'SagaSaveFeaturesConfiguration'
  ),
  SagaSaveImagesConfiguration: new ActionCreator<'SagaSaveImagesConfiguration', ActionSaveImagesConfig>('SagaSaveImagesConfiguration'),
  SagaSaveBrandingColorsConfiguration: new ActionCreator<'SagaSaveBrandingColorsConfiguration', ActionSaveBrandingConfig>(
    'SagaSaveBrandingColorsConfiguration'
  ),
  SagaSaveLocationConfiguration: new ActionCreator<'SagaSaveLocationConfiguration', ActionSaveLocationConfig>(
    'SagaSaveLocationConfiguration'
  ),
}

const AWSHelper = new API.AWSHelpers()

function* processRetrieveCurrentConfiguration() {
  try {
    yield put(
      ConfigurationActionCreators.StoreSetLoadingState.create({
        texts: true,
        featureConfig: true,
        images: true,
        induction: true,
        brandingColors: true,
        locationConfig: true,
        transactionProcessing: false,
      })
    )
    yield put(AuthenticationActionCreators.SagaRefreshApiCredentials.create())
    yield take(AuthenticationActionCreators.SagaApiCredentialsRefreshUpdate.type)
    const entityConfig = yield AWSHelper.retrieveEntityConfig()
    yield put(ConfigurationActionCreators.StoreSetFullValueConfiguration.create(entityConfig.values))
    yield put(ConfigurationActionCreators.StoreSetFullConfigurabilityConfiguration.create(entityConfig.configurability))
    yield put(
      ConfigurationActionCreators.StoreSetLoadingState.create({
        texts: false,
        featureConfig: false,
        images: false,
        induction: false,
        brandingColors: false,
        locationConfig: false,
        transactionProcessing: false,
      })
    )
  } catch (e) {
    console.error(e)
    Modal.error({
      title: 'Error',
      content: 'Failed to load configuration - please refresh the page or contact support.',
    })
  }
}

function* processSaveTextsConfiguration(action) {
  try {
    const payload = action.payload
    yield put(
      ConfigurationActionCreators.StoreSetLoadingState.create({
        texts: true,
        transactionProcessing: true,
      })
    )
    yield put(AuthenticationActionCreators.SagaRefreshApiCredentials.create())
    yield take(AuthenticationActionCreators.SagaApiCredentialsRefreshUpdate.type)
    yield AWSHelper.saveConfigTexts(payload)
    yield put(ConfigurationActionCreators.StoreSetTextsConfiguration.create(payload))
    yield put(DashboardBrandingActionCreators.SagaSetBranding.create()) //TODO - WHITELABELING : set dashboard white labeling if flag enabled
    yield put(
      ConfigurationActionCreators.StoreSetLoadingState.create({
        texts: false,
        transactionProcessing: false,
      })
    )
  } catch (e) {
    console.error(e)
    yield put(
      ConfigurationActionCreators.StoreSetLoadingState.create({
        texts: false,
        transactionProcessing: false,
      })
    )
    yield put(
      ConfigurationActionCreators.StoreSetErrorState.create({
        texts: true,
      })
    )
  }
}

function* processSaveFeaturesConfiguration(action) {
  try {
    const payload = action.payload
    yield put(
      ConfigurationActionCreators.StoreSetLoadingState.create({
        featureConfig: true,
        transactionProcessing: true,
      })
    )
    yield put(AuthenticationActionCreators.SagaRefreshApiCredentials.create())
    yield take(AuthenticationActionCreators.SagaApiCredentialsRefreshUpdate.type)
    yield AWSHelper.saveConfigFeatures(payload)
    yield put(ConfigurationActionCreators.StoreSetFeaturesConfiguration.create(payload))
    yield put(
      ConfigurationActionCreators.StoreSetLoadingState.create({
        featureConfig: false,
        transactionProcessing: false,
      })
    )
  } catch (e) {
    console.error(e)
    yield put(
      ConfigurationActionCreators.StoreSetLoadingState.create({
        featureConfig: false,
        transactionProcessing: false,
      })
    )
    yield put(
      ConfigurationActionCreators.StoreSetErrorState.create({
        featureConfig: true,
      })
    )
  }
}

function* processSaveImagesConfiguration(action) {
  try {
    const payload = action.payload
    yield put(
      ConfigurationActionCreators.StoreSetLoadingState.create({
        images: true,
        transactionProcessing: true,
      })
    )
    yield put(AuthenticationActionCreators.SagaRefreshApiCredentials.create())
    yield take(AuthenticationActionCreators.SagaApiCredentialsRefreshUpdate.type)
    const dn = Date.now()
    yield AWSHelper.saveLogoImage(payload.logoImage)
    yield AWSHelper.saveConfigImages(dn)
    const logoImageURL = yield AWSHelper.getPresignedS3URLForLogoImageGet()
    yield put(
      ConfigurationActionCreators.StoreSetImagesConfiguration.create({
        logoImage: logoImageURL,
        lmt: dn,
      })
    )
    yield put(DashboardBrandingActionCreators.SagaSetBranding.create()) //TODO - WHITELABELING : set dashboard white labeling if flag enabled
    yield put(
      ConfigurationActionCreators.StoreSetLoadingState.create({
        images: false,
        transactionProcessing: false,
      })
    )
    if (action.callback) action.callback({ status: 'success' })
  } catch (e) {
    console.error(e)
    yield put(
      ConfigurationActionCreators.StoreSetLoadingState.create({
        images: false,
        transactionProcessing: false,
      })
    )
    yield put(
      ConfigurationActionCreators.StoreSetErrorState.create({
        images: true,
      })
    )
    if (action.callback) action.callback({ status: 'failed' })
  }
}

function* processSaveBrandingColorsConfiguration(action) {
  try {
    const payload = action.payload
    yield put(
      ConfigurationActionCreators.StoreSetLoadingState.create({
        brandingColors: true,
        transactionProcessing: true,
      })
    )
    yield put(AuthenticationActionCreators.SagaRefreshApiCredentials.create())
    yield take(AuthenticationActionCreators.SagaApiCredentialsRefreshUpdate.type)
    yield AWSHelper.saveConfigBrandingColors(payload)
    yield put(ConfigurationActionCreators.StoreSetBrandingColorsConfiguration.create(payload))
    yield put(DashboardBrandingActionCreators.SagaSetBranding.create()) //TODO - WHITELABELING : set dashboard white labeling if flag enabled
    yield put(
      ConfigurationActionCreators.StoreSetLoadingState.create({
        brandingColors: false,
        transactionProcessing: false,
      })
    )
  } catch (e) {
    console.error(e)
    yield put(
      ConfigurationActionCreators.StoreSetLoadingState.create({
        brandingColors: false,
        transactionProcessing: false,
      })
    )
    yield put(
      ConfigurationActionCreators.StoreSetErrorState.create({
        brandingColors: true,
      })
    )
  }
}

function* processSaveLocationConfiguration(action) {
  try {
    const payload = action.payload
    yield put(
      ConfigurationActionCreators.StoreSetLoadingState.create({
        locationConfig: true,
        transactionProcessing: true,
      })
    )
    yield put(AuthenticationActionCreators.SagaRefreshApiCredentials.create())
    yield take(AuthenticationActionCreators.SagaApiCredentialsRefreshUpdate.type)
    yield AWSHelper.saveConfigLocation(payload)
    yield put(ConfigurationActionCreators.StoreSetLocationConfiguration.create(payload))
    yield put(
      ConfigurationActionCreators.StoreSetLoadingState.create({
        locationConfig: false,
        transactionProcessing: false,
      })
    )
  } catch (e) {
    console.error(e)
    yield put(
      ConfigurationActionCreators.StoreSetLoadingState.create({
        locationConfig: false,
        transactionProcessing: false,
      })
    )
    yield put(
      ConfigurationActionCreators.StoreSetErrorState.create({
        locationConfig: true,
      })
    )
  }
}

// Saga triggers
function* watchConfigurationSagas() {
  yield takeLeading(ActionCreators.SagaRetrieveCurrentConfiguration.type, processRetrieveCurrentConfiguration)
  yield takeLeading(ActionCreators.SagaSaveTextsConfiguration.type, (action) => processSaveTextsConfiguration(action))
  yield takeLeading(ActionCreators.SagaSaveFeaturesConfiguration.type, (action) => processSaveFeaturesConfiguration(action))
  yield takeLeading(ActionCreators.SagaSaveImagesConfiguration.type, (action) => processSaveImagesConfiguration(action))
  yield takeLeading(ActionCreators.SagaSaveBrandingColorsConfiguration.type, (action) => processSaveBrandingColorsConfiguration(action))
  yield takeLeading(ActionCreators.SagaSaveLocationConfiguration.type, (action) => processSaveLocationConfiguration(action))
  yield null
}

// Saga hooks
export default function* configurationSagas() {
  yield all([fork(watchConfigurationSagas)])
}
