import { all, fork, takeLeading, put } from 'redux-saga/effects'
import ActionCreator from '../saga-action-creator'
import { ActionCreators as StoreBrandingActionCreations, State as BrandingState } from 'src/redux/store/dashboard/branding'
// import { getEntityHierarchyConfiguration } from 'src/redux/store/credentials'
// import API from 'src/api'
import config from 'src/config'

// Functions
// const AWSHelper = new API.AWSHelpers()

// Action Creators
export const ActionCreators = {
  SagaSetBranding: new ActionCreator<'SagaSetBranding', undefined>('SagaSetBranding'),
}

function* processSetBranding(action) {
  try {
    if (window['KENAIConfigWhiteLabel'] === true) {
      // IFRAME BRANDING
      const logo = window?.['KENAIConfigLogo'] ?? config.brand.logo.light
      const logoSmall = window?.['KENAIConfigLogo'] ?? config.brand.logoSmall.light
      const brandConfig: BrandingState = {
        enabled: true,
        primaryColor: window?.['KENAIConfigPrimaryColor'] ?? config.primaryColor,
        name: '',
        logo,
        logoSmall,
      }
      console.log(brandConfig)
      yield put(StoreBrandingActionCreations.StoreSetBranding.create(brandConfig))
      if (action.callback) return action.callback({ status: 'success', brandConfig })
    } else {
      // EHCONFIG BRANDING
      // const entityConfigState: ConfigurationState = yield AWSHelper.retrieveEntityConfig() ?? undefined
      // if (entityConfigState) {
      //   const logo = entityConfigState.values.images.logoImage || config.brand.logo.light
      //   const logoSmall = entityConfigState.values.images.logoImage || config.brand.logoSmall.light
      //   const brandConfig: BrandingState = {
      //     enabled: true,
      //     primaryColor: entityConfigState.values.brandingColors.primary || config.primaryColor,
      //     name: entityConfigState.values.texts.companyName || 'Kenai',
      //     logo,
      //     logoSmall,
      //   }
      //   yield put(StoreBrandingActionCreations.StoreSetBranding.create(brandConfig))
      //   if (action.callback) return action.callback({ status: 'success', brandConfig })
      // } else {
      yield put(StoreBrandingActionCreations.StoreDisableBranding.create())
      if (action.callback) return action.callback({ status: 'failed' })
      // }
    }
  } catch (e) {
    console.log(e)
  }
}

// Saga triggers
function* watchSidebarSagas() {
  yield takeLeading(ActionCreators.SagaSetBranding.type, processSetBranding)
  yield null
}

// Saga hooks
export default function* sidebarSagas() {
  yield all([fork(watchSidebarSagas)])
}
