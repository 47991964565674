import AppStyles from './App.style'
import AppRouter from './AppRouter'
import { MsalProvider, useIsAuthenticated, useMsal, useMsalAuthentication } from '@azure/msal-react'
import { ConnectedRouter } from 'connected-react-router'
import Cookies from 'js-cookie'
import React, { useEffect, useState } from 'react'
import { Provider as ReduxProvider } from 'react-redux'
import { PersistGate } from 'redux-persist/es/integration/react'
import { LocaleProvider } from './i18n/locale-provider'
import configureStore, { history } from './redux/store/configureStore'
import ThemeProvider from './components/theme/ThemeProvider'
import { ThemeModes } from './typings/vendor/styled'
import { ProvideAuth } from './hooks/use-auth'
import LegacyLayoutProvider from './components/legacy-layout-provider'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { Modal } from 'antd'
import { Title } from './components/presentational/NoEntries/noentries.style'
import IntercomProvider from 'components/intercom-provider'
import { InteractionType } from '@azure/msal-browser'
import CONSTANTS from './utils/constants'
import { setMSALInstance } from './redux/sagas/authentication'
import configureAWSAmplify from 'src/utils/configureAWSAmplify'
import awsExports from 'aws-exports'
import { EnvironmentSelector } from './components/env-selector'
import config from './config'

const { store, persistor } = configureStore(/* provide initial state if any */)

function setupEnv(environment) {
  let constantsJSON = JSON.stringify(CONSTANTS)
  let awsExportsJSON = JSON.stringify(awsExports)
  Object.keys(environment).forEach((key) => {
    constantsJSON = constantsJSON.replace(new RegExp(`__${key}__`, 'g'), environment[key])
    awsExportsJSON = awsExportsJSON.replace(new RegExp(`__${key}__`, 'g'), environment[key])
  })
  const updatedConstants = JSON.parse(constantsJSON)
  const updatedawsExports = JSON.parse(awsExportsJSON)
  Object.keys(updatedConstants).forEach((key) => {
    CONSTANTS[key] = updatedConstants[key]
  })
  Object.keys(updatedawsExports).forEach((key) => {
    awsExports[key] = updatedawsExports[key]
  })

  CONSTANTS.AUTH_PROVIDER = (() => {
    if (CONSTANTS.AUTH_PROVIDER === '__AUTH_PROVIDER__') {
      let autoActiveB2C = false
      if (CONSTANTS.AADB2CAUTH.AUTO_ACTIVATE_DOMAINS) {
        const domains = CONSTANTS.AADB2CAUTH.AUTO_ACTIVATE_DOMAINS.split('|')
        domains.forEach((domain) => {
          if (window.location.host.split(':')[0] === domain) {
            autoActiveB2C = true
          }
        })
      }
      if (autoActiveB2C) {
        return 'AADB2C'
      } else {
        const authProviderCookie = Cookies.get('kenai-auth-provider')
        if (authProviderCookie) {
          return authProviderCookie
        } else {
          return 'AUTH0'
        }
      }
    } else {
      return CONSTANTS.AUTH_PROVIDER
    }
  })()
}
const LoadingComponent = () => {
  return <p>Authentication in progress...</p>
}

const MainContentAADB2C = ({ initialMode }) => {
  const { instance, inProgress } = useMsal()
  const { error } = useMsalAuthentication(InteractionType.Redirect, {
    scopes: [CONSTANTS.AADB2CAUTH.SCOPE_ACCESS],
  })
  const isAADB2CAuthenticated = useIsAuthenticated()

  useEffect(() => {
    if (isAADB2CAuthenticated) {
      instance.setActiveAccount(instance.getAllAccounts()[0])
    } else {
      if (instance.getActiveAccount()) {
        instance.setActiveAccount(null)
      }
    }
  }, [instance, isAADB2CAuthenticated, inProgress])

  useEffect(() => {
    if (error) {
      console.error(error)
      Modal.destroyAll()
      if (error?.errorCode === 'invalid_grant' || error?.errorCode === 'silent_sso_error') {
        localStorage.clear()
        window.location.reload()
      } else {
        Modal.confirm({
          title: <Title className='my-0'>Error</Title>,
          content: 'There was an error while trying to sign you in. Would you like to retry?',
          maskClosable: false,
          onOk: () => {
            localStorage.clear()
            window.location.reload()
          },
          okText: 'Retry',
          cancelButtonProps: { style: { display: 'none' } },
          centered: true,
        })
      }
    }
  }, [error])

  if (isAADB2CAuthenticated) {
    return (
      <div className='App'>
        <ReduxProvider store={store}>
          <LocaleProvider>
            <div className='app-container'>
              <LegacyLayoutProvider>
                <DndProvider backend={HTML5Backend}>
                  <ThemeProvider initialMode={initialMode}>
                    <AppStyles />
                    <ConnectedRouter history={history}>
                      <PersistGate loading={null} persistor={persistor}>
                        <IntercomProvider>
                          <ProvideAuth>
                            <AppRouter />
                          </ProvideAuth>
                        </IntercomProvider>
                      </PersistGate>
                    </ConnectedRouter>
                  </ThemeProvider>
                </DndProvider>
              </LegacyLayoutProvider>
            </div>
          </LocaleProvider>
        </ReduxProvider>
      </div>
    )
  } else {
    return <LoadingComponent />
  }
}

const MainContentAUTH0 = ({ initialMode }) => {
  return (
    <div className='App'>
      <ReduxProvider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <LocaleProvider>
            <div className='app-container'>
              <LegacyLayoutProvider>
                <DndProvider backend={HTML5Backend}>
                  <ThemeProvider initialMode={initialMode}>
                    <AppStyles />
                    <ConnectedRouter history={history}>
                      <IntercomProvider>
                        <ProvideAuth>
                          <AppRouter />
                        </ProvideAuth>
                      </IntercomProvider>
                    </ConnectedRouter>
                  </ThemeProvider>
                </DndProvider>
              </LegacyLayoutProvider>
            </div>
          </LocaleProvider>
        </PersistGate>
      </ReduxProvider>
    </div>
  )
}

const Application = ({ msalInstance }) => {
  const [envRetrieved, setEnvRetrieved] = useState(false)
  useEffect(() => {
    const fetchEnvironment = async () => {
      try {
        const presetEnvironment = config.staging && Cookies.get(EnvironmentSelector.cookie)
        const envLookup = presetEnvironment || `dashboard-${CONSTANTS.ENV_QUALIFIER}-${window.location.host.split(':')[0]}`
        fetch(`https://envdiscovery.kenai.co.za/${envLookup}.json?x=${Date.now()}`)
          .then((res) => {
            return res.json()
          })
          .then((environment) => {
            setupEnv(environment)
            configureAWSAmplify()
            setEnvRetrieved(true)
          })
          .catch(() => {
            const envLookupFallback = `dashboard-${CONSTANTS.ENV_QUALIFIER}-default`
            fetch(`https://envdiscovery.kenai.co.za/${envLookupFallback}.json?x=${Date.now()}`)
              .then((res) => {
                return res.json()
              })
              .then((environment) => {
                setupEnv(environment)
                configureAWSAmplify()
                setEnvRetrieved(true)
              })
              .catch((e) => {
                console.log(e)
                Modal.destroyAll()
                Modal.confirm({
                  title: <Title className='my-0'>Error</Title>,
                  content: 'There was an error loading configuration for the dashboard. Would you like to retry?',
                  maskClosable: false,
                  onOk: () => {
                    window.location.reload()
                  },
                  okText: 'Retry',
                  cancelButtonProps: { style: { display: 'none' } },
                  centered: true,
                })
              })
          })
      } catch (e) {
        console.log(e)
        Modal.destroyAll()
        Modal.confirm({
          title: <Title className='my-0'>Error</Title>,
          content: 'There was an error loading configuration for the dashboard. Would you like to retry?',
          maskClosable: false,
          onOk: () => {
            window.location.reload()
          },
          okText: 'Retry',
          cancelButtonProps: { style: { display: 'none' } },
          centered: true,
        })
      }
    }
    fetchEnvironment()
  }, [setEnvRetrieved])

  useEffect(() => {
    setMSALInstance(msalInstance)
  }, [msalInstance])

  if (navigator.userAgent.indexOf('MSIE') !== -1 || !!document.documentMode === true) {
    return <div>The app can not render in IE</div>
  }

  const initialMode = (() => {
    const cookiesTheme = Cookies.get('kenai-dashboard-theme')

    const setDocumentClass = (theme) => {
      if (theme === 'dark') document.documentElement.classList.add('dark')
      else document.documentElement.classList.remove('dark')
    }

    if (cookiesTheme) {
      document.documentElement.setAttribute('data-theme', cookiesTheme)
      setDocumentClass(cookiesTheme)
      return cookiesTheme as ThemeModes
    } else {
      const nextMode = window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : ('light' as ThemeModes)
      document.documentElement.setAttribute('data-theme', nextMode)
      setDocumentClass(nextMode)
      return nextMode
    }
  })()

  if (envRetrieved) {
    return CONSTANTS.AUTH_PROVIDER === 'AADB2C' ? (
      // <MainContent initialMode={initialMode} />
      <MsalProvider instance={msalInstance}>
        <MainContentAADB2C initialMode={initialMode} />
      </MsalProvider>
    ) : (
      <MainContentAUTH0 initialMode={initialMode} />
    )
  } else {
    return null
  }
}

export default function App({ msalInstance }) {
  return (
    <>
      <EnvironmentSelector />
      <Application msalInstance={msalInstance} />
    </>
  )
}
