import AccessManager from 'src/utils/AccessManager'

export const showRoute = (flag, isLegacyLayout) => {
  const isAdmin = AccessManager.selectedHierarchy.role === 'ADMIN'
  if (isLegacyLayout) {
    return flag
  } else {
    if (isAdmin) return true
    return flag
  }
}
