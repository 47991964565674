import React, { ReactNode, useEffect, useMemo, useState } from 'react'

import FieldsetTitle from './fieldset-title'
import FieldsetSubtitle from './fieldset-subtitle'
import FieldsetFooter from './fieldset-footer'
import FieldsetGroup from './fieldset-group'
import FieldsetContent from './fieldset-content'
import { hasChild, pickChild } from 'src/utils/collections'
import { useFieldset } from './fieldset-context'

import { cssvars } from 'src/styles'
import returnWarning from 'src/utils/returnWarning'
import fieldsetLoader from './fieldset-loader'
import { Spin } from 'antd'

interface Props {
  value?: string
  label?: string
  title?: string | ReactNode
  extra?: string | ReactNode
  subtitle?: string | ReactNode
  className?: string
  loading?: boolean
}

const defaultProps = {
  value: '',
  label: '',
  disabled: false,
  title: '' as string | ReactNode,
  subtitle: '' as string | ReactNode,
  className: '',
  loading: false,
}

type NativeAttrs = Omit<React.FieldsetHTMLAttributes<any>, keyof Props>
export type FieldsetProps = Props & typeof defaultProps & NativeAttrs

const Fieldset: React.FC<React.PropsWithChildren<FieldsetProps>> = ({
  className,
  title,
  subtitle,
  children,
  value,
  label,
  extra,
  loading,
  ...props
}) => {
  const { inGroup, currentValue, register } = useFieldset()
  const [hidden, setHidden] = useState<boolean>(inGroup)

  const [withoutFooterChildren, FooterChildren] = pickChild(children, FieldsetFooter)
  const hasTitle = hasChild(withoutFooterChildren, FieldsetTitle)
  const hasSubtitle = hasChild(withoutFooterChildren, FieldsetSubtitle)
  const hasContent = hasChild(withoutFooterChildren, FieldsetContent)

  useEffect(() => {
    register && register({ value, label })
  }, [register, value, label])

  useEffect(() => {
    if (!currentValue || currentValue === '') return
    setHidden(currentValue !== value)
  }, [currentValue, value])

  if (inGroup) {
    if (!label) {
      returnWarning('Props "label" is required when in a group.', 'Fieldset Group')
    }
    if (!value || value === '') {
      value = label
    }
  }

  const content = useMemo(
    () => (
      <>
        {!hasTitle && title && <FieldsetTitle extra={extra}>{title}</FieldsetTitle>}
        {!hasSubtitle && subtitle && <FieldsetSubtitle>{subtitle}</FieldsetSubtitle>}
        {withoutFooterChildren}
      </>
    ),
    [withoutFooterChildren, hasTitle, hasSubtitle, title, subtitle, extra]
  )

  return (
    <Spin spinning={loading} wrapperClassName='fieldset-spinner'>
      <div className={`fieldset ${className}`} {...props}>
        {hasContent ? content : <FieldsetContent>{content}</FieldsetContent>}
        {FooterChildren && FooterChildren}
        <style jsx global>{`
          .fieldset {
            background-color: ${cssvars.componentBackground};
            border: 1px solid ${cssvars.borderColor};
            border-radius: 5px;
            overflow: hidden;
            display: ${hidden ? 'none' : 'block'};
          }
          .fieldset-spinner {
            border-radius: 5px;
            overflow: hidden;
          }
        `}</style>
      </div>
    </Spin>
  )
}

Fieldset.defaultProps = defaultProps

// eslint-disable-next-line @typescript-eslint/ban-types
type FieldsetComponent<P = {}> = React.FC<P> & {
  Title: typeof FieldsetTitle
  Subtitle: typeof FieldsetSubtitle
  Footer: typeof FieldsetFooter
  Group: typeof FieldsetGroup
  Content: typeof FieldsetContent
  Body: typeof FieldsetContent
  Loader: typeof fieldsetLoader
}

type ComponentProps = Partial<typeof defaultProps> & Omit<Props, keyof typeof defaultProps> & NativeAttrs

export default Fieldset as FieldsetComponent<ComponentProps>
