import React, { useMemo } from 'react'
import SubscriptionHandler, { SubscriptionHandlerProps, validateProps } from 'src/components/SubscriptionHandler'

const useSubscriptionHandler = ({
  shouldSubscribeToUpdates = false,
  ...props
}: { shouldSubscribeToUpdates: boolean } & SubscriptionHandlerProps) => {
  useMemo(() => validateProps(props), [props])

  return useMemo(() => {
    if (!shouldSubscribeToUpdates) {
      return null
    }

    return <SubscriptionHandler {...props} />
  }, [shouldSubscribeToUpdates, props])
}

export default useSubscriptionHandler
