import { ConfigProvider } from 'antd'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { RawIntlProvider } from 'react-intl'
import { useSelector } from 'react-redux'
import { getEntityConfigI18nTexts } from 'src/redux/store/credentials'
import getIntl, { setConfigI18NTexts } from '.'
import { antdLocales, defaultLocale } from './locales'
import getInitialLocale, { Locale, parseLocale } from './utils'

interface ContextProps {
  readonly locale: Locale
  readonly setLocale: (locale: Locale) => void
  readonly setIntl: (intl: ReturnType<typeof getIntl>) => void
}

const LocaleContext = React.createContext<ContextProps>({
  locale: defaultLocale,
  setLocale: () => null,
  setIntl: () => null,
})

export const useLocale = () => React.useContext<ContextProps>(LocaleContext)

export const LocaleProvider: React.FC = (props) => {
  // const initialEntityLocale = parseMessageLocale(props.entityDefaultLocale) // future when locales are configurable
  const initialDefaultLocale = parseLocale(getInitialLocale()) // fallback to default locale if all else fails
  const [locale, setLocale] = React.useState<Locale>(initialDefaultLocale)
  const [intl, setIntl] = useState(getIntl(locale))
  const i18nTexts = useSelector(getEntityConfigI18nTexts)

  useEffect(() => {
    if (locale !== localStorage.getItem('kenai-dashboard-locale')) localStorage.setItem('kenai-dashboard-locale', locale)
    const doc = document?.getElementsByTagName('html')?.[0] || undefined
    if (doc) doc.lang = locale
    moment.locale(locale)
  }, [locale])

  useEffect(() => {
    if (i18nTexts) {
      Object.keys(i18nTexts).forEach((eh) => {
        setConfigI18NTexts(i18nTexts[eh], locale)
      })
    }
    setIntl(getIntl(locale))
  }, [locale, i18nTexts])

  return (
    <RawIntlProvider value={intl}>
      <LocaleContext.Provider value={{ locale, setLocale, setIntl }}>
        <ConfigProvider locale={antdLocales[locale]}>{props.children}</ConfigProvider>
      </LocaleContext.Provider>
    </RawIntlProvider>
  )
}
