/*eslint no-throw-literal: 0*/
import { all, fork, put, takeLeading } from 'redux-saga/effects'
import ActionCreator from '../saga-action-creator'
import { InviteLogItem } from 'src/redux/store/visitors/types/invites'
import { push } from 'connected-react-router'
import { ActionCreators as StoreActionCreators } from 'src/redux/store/visitors/assisted-checkin'

// Action Creators
export const ActionCreators = {
  SagaInitialAssistedCheckIn: new ActionCreator<'SagaInitialAssistedCheckIn', InviteLogItem>('SagaInitialAssistedCheckIn'),
}

function* processInitialAssistedCheckIn(action: typeof ActionCreators['SagaInitialAssistedCheckIn']) {
  yield put(StoreActionCreators.StoreSetInitialData.create(action.payload))
  yield put(push('/visitors/assisted-check-in'))
}

// Saga triggers
function* watchSagas() {
  yield takeLeading(ActionCreators.SagaInitialAssistedCheckIn.type, processInitialAssistedCheckIn)
  yield null
}

// Saga hooks
export default function* saga() {
  yield all([fork(watchSagas)])
}
