import update from 'immutability-helper'
import { createSelector } from 'reselect'
import { RootState } from '../rootReducer'
import { ActionCreator } from '../store-action-creator'

// Action Creators
export const ActionCreators = {
  StoreSetErrors: new ActionCreator<'StoreSetErrors', string[]>('StoreSetErrors'),
  StoreSetLiveUpdateEarliestTimestamp: new ActionCreator<'StoreSetLiveUpdateEarliestTimestamp', number>(
    'StoreSetLiveUpdateEarliestTimestamp'
  ),
  ClearErrors: new ActionCreator<'ClearErrors', Partial<void>>('ClearErrors'),
  ClearAllStates: new ActionCreator<'ClearAllStates', Partial<void>>('ClearAllStates'),
}

export type Action = typeof ActionCreators[keyof typeof ActionCreators]

export type State = {
  errors: string[]
  liveUpdateEarliestTimestamp: number
}

export const initialState = (): State => ({
  errors: [],
  liveUpdateEarliestTimestamp: 0,
})

// Reducer
export default function reducer(state: State = initialState(), action: Action) {
  switch (action.type) {
    case ActionCreators.StoreSetErrors.type:
      return update(state, { errors: { $set: action.payload } })
    case ActionCreators.ClearErrors.type:
      return update(state, { errors: { $set: [] } })
    case ActionCreators.StoreSetLiveUpdateEarliestTimestamp.type:
      return update(state, { liveUpdateEarliestTimestamp: { $set: action.payload } })

    case ActionCreators.ClearAllStates.type:
      return initialState()
    default:
      return state
  }
}

// Selectors
const getGlobalEventsErrorState = (state: RootState) => state.dashboard.globalevents.errors
export const getGlobalEventsErrors = createSelector<RootState, State['errors'], State['errors']>(
  [getGlobalEventsErrorState],
  (state) => state
)

const getLiveUpdateEarliestTimestampState = (state: RootState) => state.dashboard.globalevents.liveUpdateEarliestTimestamp
export const getLiveUpdateEarliestTimestamp = createSelector<
  RootState,
  State['liveUpdateEarliestTimestamp'],
  State['liveUpdateEarliestTimestamp']
>([getLiveUpdateEarliestTimestampState], (state) => state)
