import { IntlShape } from 'react-intl'
import { EntityHierarchyConfig } from 'src/typings/kenai/configuration/entity-hierarchy'

export function getEmployeeFlowType(
  entityHierarchyConfig: EntityHierarchyConfig,
  EntityHierarchy: string,
  flowId: string,
  intl: IntlShape
) {
  if (!flowId) return intl.formatMessage({ id: 'common.na' })
  const flowType = entityHierarchyConfig.additionalConfigs.flowTypes[EntityHierarchy].find((flow) => flow.flowId === flowId)
  if (!flowType) return intl.formatMessage({ id: 'common.na' })
  return intl.formatMessage({ id: flowType.texts.flowDescriptionTextId })
}
