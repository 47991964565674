import { faColumns } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Select } from 'antd'
import { DropDownProps } from 'antd/lib/dropdown'
import React, { useState } from 'react'
import DropdownButton from 'src/components/dropdown-button'
import useClickAway from 'src/hooks/use-click-away'
import OverflowText from './table-overflow-text'
import { cn } from 'src/utils/classnames'

export interface ColumnFilterProps {
  columns: Array<{
    key: any
    title?: any
  }>
  activeKeys: any[]
  className?: string
  disabled?: boolean
  onChange?: (keys: any[]) => void
  // trigger?: DropDownProps['trigger']
  placement?: DropDownProps['placement']
}

export const defaultActiveKeys = (key: string, fallback: any[] | undefined) => {
  try {
    const storedColumns = localStorage.getItem(key)
    if (!storedColumns) throw new Error('not valid item columns, choosing fallback')
    const columns = JSON.parse(storedColumns)
    if (!columns.length) throw new Error('not valid array item, choosing fallback')
    return columns
  } catch (error) {
    return fallback
  }
}

function ColumnsSelector(props: ColumnFilterProps) {
  const { columns, activeKeys, onChange, className = '', disabled } = props
  const [open, setOpen] = useState(false)
  const ref = React.useRef(null)

  useClickAway(ref, () => setOpen(false))

  if (columns.length === 0) return null

  const options = columns
    .filter((c) => c.key !== 'expand-icon-placeholder')
    .map((column) => ({
      label: (
        <div style={{ width: 150 }} className='pr-2'>
          <OverflowText placement='left'>{getColumnTitle(column)}</OverflowText>
        </div>
      ),
      value: column.key,
    }))

  return (
    <div ref={ref} id='column-select' className={cn('relative', className)}>
      <DropdownButton open={open} onClick={() => setOpen((flag) => !flag)} id='open-button' disabled={disabled}>
        <FontAwesomeIcon icon={faColumns} />
      </DropdownButton>

      <Select
        value={activeKeys}
        mode='multiple'
        onChange={onChange}
        options={options}
        dropdownMatchSelectWidth={false}
        showArrow={true}
        showSearch={false}
        tagRender={() => <div className='w-0' />}
        open={open}
        data-tip='Select input used for virtual list'
        aria-hidden
        className='opacity-0 absolute center-xy pointer-events-none w-full h-full overflow-hidden'
        dropdownAlign={{
          points: ['tr', 'br'],
          offset: [0, 0],
        }}
        dropdownRender={(menu) => {
          return (
            <div className='-mb-1'>
              <div className='flex justify-between w-full -mt-1 px-3 bg-vars-contrastBackground border-b border-solid border-l-0 border-r-0 border-t-0 border-vars-borderColor space-x-4'>
                <Button
                  onClick={(e) => {
                    e.preventDefault()
                    onChange?.([])
                  }}
                  className='rounded-t-none px-0 text-vars-textColor hover:text-vars-primaryColor'
                  type='link'
                >
                  Clear
                </Button>
                <Button
                  onClick={(e) => {
                    e.preventDefault()
                    onChange?.(columns.map((column) => column.key))
                  }}
                  className='rounded-t-none px-0 text-vars-textColor hover:text-vars-primaryColor'
                  type='link'
                >
                  Select All
                </Button>
              </div>
              {menu}
            </div>
          )
        }}
      />
    </div>
  )
}

ColumnsSelector.defaultActiveKeys = defaultActiveKeys

export { ColumnsSelector }

function getColumnTitle(column: { key: any; title?: any }): string {
  if (typeof column.title !== 'string' && column.title?.props && !column.title?.props?.string) {
    throw new Error(`------------------------------------------------------------------------------------------------------------------------
* Your title needs to be a string
* Alternatively you need to wrap the column title with the 'components/tables/TitleComponent.tsx' component,
* You may also use your own component including 'string' as a prop
------------------------------------------------------------------------------------------------------------------------
EXAMPLE:
<TitleComponent string={columns.title}>
  <div>{columns.title}</div>
</TitleComponent>
  `)
  }
  return typeof column.title === 'string' ? column.title : column.title?.props?.string ? column.title.props.string : column.key
}
