import update from 'immutability-helper'
import { createSelector } from 'reselect'
import { RootState } from '../rootReducer'
import { ActionCreator } from '../store-action-creator'
import { InviteLogItem } from './types/invites'

export interface State {
  dataSource: InviteLogItem[]
  loading: boolean

  shouldSubscribeToUpdates: boolean
}

// Action Creators
export const ActionCreators = {
  StoreSetInviteLogData: new ActionCreator<'StoreSetInviteLogData', State['dataSource']>('StoreSetInviteLogData'),
  StoreSetInviteLogShouldSubscribe: new ActionCreator<'StoreSetInviteLogShouldSubscribe', boolean>('StoreSetInviteLogShouldSubscribe'),
  StoreSetInviteLogLoading: new ActionCreator<'StoreSetInviteLogLoading', State['loading']>('StoreSetInviteLogLoading'),
  StoreClearAllInviteLogData: new ActionCreator<'StoreClearAllInviteLogData', void>('StoreClearAllInviteLogData'),
  ClearAllStates: new ActionCreator<'ClearAllStates', void>('ClearAllStates'),
}
export const InviteLogStoreActionCreators = ActionCreators

type Action = typeof ActionCreators[keyof typeof ActionCreators]

export const initialState = (): State => ({
  dataSource: [],
  loading: false,

  shouldSubscribeToUpdates: false,
})

// Reducer
export default function reducer(state: State = initialState(), action: Action) {
  switch (action.type) {
    case ActionCreators.StoreSetInviteLogData.type:
      return update(state, {
        dataSource: { $set: action.payload },
      })
    case ActionCreators.StoreSetInviteLogShouldSubscribe.type:
      return update(state, {
        shouldSubscribeToUpdates: { $set: action.payload },
      })
    case ActionCreators.StoreSetInviteLogLoading.type:
      return update(state, {
        loading: { $set: action.payload },
      })
    case ActionCreators.StoreClearAllInviteLogData.type:
      return update(state, {
        dataSource: { $set: initialState().dataSource },
      })
    case ActionCreators.ClearAllStates.type:
      return initialState()
    default:
      return state
  }
}

const getInviteLogDataSourceState = (state: RootState) => state.visitors.invites.dataSource
export const getInviteLogData = createSelector<any, State['dataSource'], any>([getInviteLogDataSourceState], (value) => value)

const getShouldSubscribeToInvitesUpdatesState = (state: RootState) => state.visitors.invites.shouldSubscribeToUpdates
export const getShouldSubscribeToInvitesUpdates = createSelector<RootState, State['shouldSubscribeToUpdates'], any>(
  [getShouldSubscribeToInvitesUpdatesState],
  (value) => value
)

const getInviteLogLoadingState = (state: RootState) => state.visitors.invites.loading
export const getInviteLogLoading = createSelector<any, State['loading'], any>([getInviteLogLoadingState], (value) => value)
