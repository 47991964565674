import React from 'react'
import moment from 'moment'
import { FormattedMessage } from 'react-intl'

interface DateCell {
  date: string | number
  format?: string
}

const format = (date: string | number, format?: string) => {
  if (!date) {
    return ''
  }
  return moment(date).format(typeof format === 'string' ? format : 'MMM D, YYYY - HH:mm')
}

export default function DateCell({ date, format: customFormat }: DateCell) {
  if (typeof date === 'string') {
    return <span style={{ marginLeft: '-100%', color: '#b7bec9 ' }}>{date}</span>
  }
  if (typeof date !== 'number') return <FormattedMessage id='common.invalid-date' tagName='span' />

  return <span>{format(date, customFormat)}</span>
}

DateCell.format = format
