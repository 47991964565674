import getIntl from 'src/i18n'

import {
  EmployeePreScreeningLogSearchableEventSet,
  EmployeePreScreeningDBItem,
  PreScreeningSubmissionData,
} from 'src/redux/store/employees/types/screening-log'
import { EntityHierarchyConfig } from 'src/typings/kenai/configuration/entity-hierarchy'
import { ConfigValueCustomField } from 'src/typings/kenai/configuration/custom-fields'

const calculateSearchStringForSubmissionValues = (entityHierarchyConfiguration, EntityHierarchy, submissionValues) => {
  const intl = getIntl()
  let searchString = ''
  if (Object.keys(submissionValues || {}).length) {
    const customFields = entityHierarchyConfiguration.additionalConfigs?.customFields?.[EntityHierarchy]
    Object.keys(submissionValues).forEach((key) => {
      const fieldDefinition: ConfigValueCustomField | undefined = customFields.find((customField) => customField.fieldId === key)
      if (fieldDefinition) {
        if (fieldDefinition.inputSettings.inputType === 'number' || fieldDefinition.inputSettings.inputType === 'text') {
          searchString += ` ${submissionValues[key]}`
        } else if (fieldDefinition.inputType === 'staticDropDownListSingleSelect') {
          const sddlItem = fieldDefinition.staticDropDownListItems?.find(
            (staticDropDownListItem) => staticDropDownListItem.itemId === submissionValues[key]
          )
          if (sddlItem) {
            searchString += ` ${intl.formatMessage({ id: sddlItem.optionTextId })}`
          }
        } else if (fieldDefinition.inputType === 'staticDropDownListMultiSelect') {
          const parts = submissionValues[key].split(';')
          parts.forEach((part) => {
            const sddlItem = fieldDefinition.staticDropDownListItems?.find(
              (staticDropDownListItem) => staticDropDownListItem.itemId === part
            )
            if (sddlItem) {
              searchString += ` ${intl.formatMessage({ id: sddlItem.optionTextId })}`
            }
          })
        }
      } else {
        searchString += ` ${submissionValues[key]}`
      }
    })
  }
  return searchString
}

export const calculateSearchStringForItem = (
  item: EmployeePreScreeningDBItem,
  submissionValues: Record<string, string>,
  entityHierarchyConfiguration: EntityHierarchyConfig
) => {
  let searchString = ''
  if (item.employeeDetails) {
    for (const prop in item.employeeDetails) {
      const value = item.employeeDetails[prop]
      if (typeof value === 'string' || typeof value === 'number') searchString += ` ${value}`
      if (Array.isArray(value)) {
        const arrayValues = value.filter((arrayValue) => typeof arrayValue === 'string' || typeof value === 'number').join(' ')
        if (arrayValues?.[0]) searchString += ` ${arrayValues}`
      }
    }
  }

  searchString += calculateSearchStringForSubmissionValues(entityHierarchyConfiguration, item.EntityHierarchy, submissionValues)

  if (item.hasOwnProperty('previousSubmissions') && item.previousSubmissions.length > 0) {
    item.previousSubmissions.forEach((previousSubmission) => {
      searchString += calculateSearchStringForSubmissionValues(
        entityHierarchyConfiguration,
        item.EntityHierarchy,
        previousSubmission.submissionValues
      )
    })
  }

  return searchString.toLowerCase()
}

export const enrichPreviousSubmissions = (
  item: EmployeePreScreeningDBItem,
  previousSubmissions: PreScreeningSubmissionData[],
  entityHierarchyConfiguration: EntityHierarchyConfig
): EmployeePreScreeningLogSearchableEventSet[] => {
  const enrichedPreviousSubmissions: EmployeePreScreeningLogSearchableEventSet[] = []
  previousSubmissions.forEach((previousSubmission) => {
    const enrichedPreviousSubmission: EmployeePreScreeningLogSearchableEventSet = {
      EntityHierarchy: item.EntityHierarchy,
      preScreeningDateProfileID: item.preScreeningDateProfileID,
      lmt: item.lmt,
      preScreeningDate: item.preScreeningDate,
      flowTypeRef: item.flowTypeRef,
      employeeDetails: item.employeeDetails,
      leadingSubmission: previousSubmission,
      searchString: calculateSearchStringForItem(item, previousSubmission?.submissionValues, entityHierarchyConfiguration),
      alreadyFetchedTransfer: false,
    }
    enrichedPreviousSubmissions.push(enrichedPreviousSubmission)
  })

  return enrichedPreviousSubmissions
}

export const convertDBToLogItem = (
  item: EmployeePreScreeningDBItem,
  entityHierarchyConfiguration: EntityHierarchyConfig
): EmployeePreScreeningLogSearchableEventSet => {
  const returnItem: EmployeePreScreeningLogSearchableEventSet = {
    EntityHierarchy: item.EntityHierarchy,
    preScreeningDateProfileID: item.preScreeningDateProfileID,
    lmt: item.lmt,
    preScreeningDate: item.preScreeningDate,
    flowTypeRef: item.flowTypeRef,
    employeeDetails: item.employeeDetails,
    leadingSubmission: item.leadingSubmission,
    searchString: calculateSearchStringForItem(item, item.leadingSubmission.submissionValues, entityHierarchyConfiguration),
    previousSubmissions: enrichPreviousSubmissions(item, item.previousSubmissions, entityHierarchyConfiguration),
    alreadyFetchedTransfer: false,
  }
  return returnItem
}

export function convertToLogItems(
  data: EmployeePreScreeningDBItem[],
  entityHierarchyConfiguration: EntityHierarchyConfig
): EmployeePreScreeningLogSearchableEventSet[] {
  const convertedLogItems: EmployeePreScreeningLogSearchableEventSet[] = []
  data.forEach((item) => {
    convertedLogItems.push(convertDBToLogItem(item, entityHierarchyConfiguration))
  })
  return convertedLogItems
}
