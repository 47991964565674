import { COMPARISON_OPERATORS, JOIN_OPERATORS } from '@kenai/utils.filter-processor'

export const FLOW_NATURES_ENUM = {
  VISITOR: 0,
  WORKER: 1,
  PARKING: 2,
} as const

export const PARKING_PROCESS_TYPES_ENUM = {
  ENTRANCE_PROCESS: 'entrance',
  EXIT_PROCESS: 'exit',
} as const

export const PARKING_PROCESS_TYPES_LEGACY_ENUM = {
  ENTRANCE_PROCESS: 'ENTRANCE_PROCESS',
  EXIT_PROCESS: 'EXIT_PROCESS',
} as const

export const PARKING_PROCESS_MODES_ENUM = {
  PEDESTRIAN: 'pedestrian',
  VEHICLE: 'vehicle',
} as const

export const PARKING_UNIQUE_ATTRIBUTE_TYPES_ENUM = {
  VEHICLE_LICENSE_PLATE_NR: 'VEHICLE_LICENSE_PLATE_NR',
  PERSONAL_IDENTIFICATION_NUMBER: 'PERSONAL_IDENTIFICATION_NUMBER',
} as const

export const PARKING_VEHICLE_STATUS_TYPES_ENUM = {
  ON_SITE: 'ON SITE',
  OFF_SITE: 'OFF SITE',
  UNKNOWN: 'UNKNOWN',
} as const

export const PARKING_VISITOR_STATUS_TYPES_ENUM = {
  CHECKED_IN: 'CHECKED IN',
  CHECKED_OUT: 'CHECKED OUT',
  UNKNOWN: 'UNKNOWN',
} as const

export const PARKINGLOG_KEYS_ENUM = {
  VISITOR: 'visitor',
  VEHICLE: 'vehicle',
  PROCESS_TYPE: 'processType',
  PROCESS_MODE: 'processMode',
  FLOW_TYPE: 'flowType',
  HOST: 'host',
  PHONE_NUMBER: 'phoneNumber',
  TENANT: 'tenant',
  COMPANY: 'company',
  PASSENGERS: 'passengers',
  VISITOR_STATUS: 'visitorStatus',
  VEHICLE_STATUS: 'vehicleStatus',
  TIME: 'time',
  GENDER: 'gender',
  VALIDUNTIL: 'validUntil',
  BOOKING_DETAILS: 'bookingDetails',
  EVACUATION_STATUS: 'evacuationStatus',
} as const

/** Keys used to set the logs active columns */
export const PARKINGLOG_ACTIVE_KEYS_ENUM = {
  VISITOR: 'visitor',
  VEHICLE: 'vehicle',
  HOST: 'host',
  PHONE_NUMBER: 'phoneNumber',
  TENANT: 'tenant',
  COMPANY: 'company',
  PASSENGERS: 'passengers',
  VISITOR_STATUS: 'visitor-status',
  VEHICLE_STATUS: 'vehicle-status',
  TIME: 'time',
  BOOKING_DETAILS: 'bookingDetails',
} as const

export const PARKING_EVENT_TYPE_ENUM = {
  NO_SHOW: 'no-show',
  BOOKED: 'booked',
  OVER_STAY: 'over-stay',
  DRIVE_IN: 'drive-in',
} as const

export const SCREENING_STATUS_ENUM = {
  PASSED: 'PASSED',
  FAILED: 'FAILED',
  INCOMPLETE: 'INCOMPLETE',
  NONE: 'NONE',
} as const

export const SCREENING_PREVIOUS_SUBMISSION_EVENTS_ENUM = {
  EMPLOYEE_PRE_SCREENING_BACKEND_DATA_RECEIVED: 'EMPLOYEE_PRE_SCREENING_BACKEND_DATA_RECEIVED',
} as const

export const PROCESS_TYPES_ENUM = {
  SIGN_IN: 0,
  SIGN_OUT: 1,
} as const

export const VISITORLOG_KEYS_ENUM = {
  VISITOR: 'visitor',
  NAME: 'name',
  HOST: 'host',
  BADGE: 'badge',
  TYPE: 'type',
  ID_PASSPORT: 'id_passport',
  ASN: 'asn',
  SIGN_IN: 'sign_in',
  HOST_APPROVAL: 'host_approval',
  SIGN_OUT: 'sign_out',
  INDUCTION: 'induction',
  COMPANY: 'company',
  LOCATION: 'location',
  EMAIL: 'email',
  PHONE_NUMBER: 'phone_number',
  AGREEMENT: 'agreement',
  DELTA_V1: 'deltaV1',
  EVAC_STATUS: 'evac_status',
  INVITE: 'invite',
  ACCESS_PASS: 'access_pass',
} as const

/** Keys used to set the logs active columns */
export const VISITORLOG_ACTIVE_KEYS_ENUM = {
  VISITOR: 'visitor',
  NAME: 'name',
  HOST: 'host',
  BADGE: 'badge',
  TYPE: 'type',
  ID_PASSPORT: 'id_passport',
  ASN: 'asn',
  SIGN_IN: 'sign_in',
  HOST_APPROVAL: 'host_approval',
  SIGN_OUT: 'sign_out',
  INDUCTION: 'induction',
  COMPANY: 'company',
  LOCATION: 'location',
  EMAIL: 'email',
  PHONE_NUMBER: 'phone_number',
  AGREEMENT: 'agreement',
  PARKING_BOOKING: 'parking_booking',
  DELTA_V1: 'deltaV1',
  INVITE: 'invite',
  ACCESS_PASS: 'access_pass',
} as const

export const VISITORLOG_EVENT_TYPES_ENUM = {
  VISITOR_IDENTIFIED: 'VISITOR_IDENTIFIED',
} as const

export const BIO_FILTER_TYPES_ENUM = {
  RECOGNISED: 'recognised',
  NOT_RECOGNISED: 'not_recognised',
  HOST_SIGNOUT: 'host_signout',
  DASHBOARD_SIGNOUT: 'dashboard_signout',
  NA: 'N/A',
  TOUCHLESS_ACCESS_PASS: 'TOUCHLESS_ACCESS_PASS',
  TOUCHLESS_UNVERIFIED: 'TOUCHLESS_UNVERIFIED',
  EXTERNAL_EVENT: 'EXTERNAL_EVENT',
} as const

export const INDUCTION_TYPES_ENUM = {
  PRE_REGISTRATION: 'preRegistration',
  SCREENING_ROOM: 'screeningRoom',
  EMPLOYEE_LINK: 'employeeLink',
  KIOSK: 'kiosk',
} as const

export const INDUCTION_STATUS_ENUM = {
  INCOMPLETE: 'incomplete',
  COMPLETED: 'completed',
  EXPIRED: 'expired',
  SKIPPED: 'skipped',
  NA: 'N/A',
} as const

export const HOST_APPROVAL_ENUM = {
  PENDING: 'pending',
  REJECTED: 'rejected',
  APPROVED: 'approved',
  NA: 'N/A',
} as const

export const HOST_DATA_SOURCE_ENUM = {
  ADHOSTSYNC: 'ADHOSTSYNC',
  DASHBOARD: 'DASHBOARD',
  ADHOSTSYNC_DASHBOARD: 'ADHOSTSYNC|DASHBOARD',
} as const

export const BIOMETRIC_PROCESSING_STATUS_ENUM = {
  PASSED: 'PASSED',
  FAILED: 'FAILED',
  REJECTED: 'REJECTED',
  PENDING: 'PENDING',
  'DASHBOARD_N/A': 'DASHBOARD_N/A',
} as const

// export const FIELD_CONDITION_COMPARISON_OPERATORS = {
//   EQ: 'EQ',
//   NE: 'NE',
//   GT: 'GT',
//   LT: 'LT',
//   GE: 'GE',
//   LE: 'LE',
//   CONTAINS: 'CONTAINS',
//   NOTCONTAINS: 'NOTCONTAINS',
// } as const

export const FIELD_CONDITION_COMPARISON_OPERATORS_ENUM = COMPARISON_OPERATORS

export const FIELD_CONDITION_JOIN_OPERATORS_ENUM = JOIN_OPERATORS

export const INPUT_TYPE_NAMES_ENUM = {
  text: 'Text',
  number: 'Number',
  staticDropDownListMultiSelect: 'Multiple Select',
  staticDropDownListSingleSelect: 'Dropdown',
  slider: 'Slider',
} as const

export const enum AvailableViewTypes_ENUM {
  CONFIRMATION,
  ACCESS_DENIED,
}
