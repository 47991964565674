import { CloudUploadOutlined, DownloadOutlined, LoadingOutlined } from '@ant-design/icons'
import { Button, Space, Typography } from 'antd'
import Dragger, { DraggerProps } from 'antd/lib/upload/Dragger'
import React from 'react'
import { cn } from 'src/utils/classnames'
import download from 'src/utils/downloadLink'

interface TemplateUploadProps extends DraggerProps {
  downloadLink: string
  loading?: boolean
  border?: boolean
}

export const TemplateUpload = ({ downloadLink, loading, border = true, ...draggerProps }: TemplateUploadProps) => {
  return (
    <div
      className={cn('w-full text-center', {
        'border border-solid border-vars-borderColor rounded-md': border,
      })}
    >
      <Space direction='vertical' className='p-6'>
        <Typography.Paragraph>You need to fill out the template to do a batch upload</Typography.Paragraph>

        <Button onClick={() => download(downloadLink)} icon={<DownloadOutlined />} danger className='mx-auto'>
          Download Template
        </Button>
      </Space>

      <Dragger
        className={cn(
          'p-6 border border-b-0 border-l-0 border-r-0 border-dashed rounded-b-md  bg-vars-bodyBackground transition-all-default text-vars-textColor border-vars-disabledColor',
          {
            'hover:bg-vars-contrastBackground cursor-pointer': !loading,
            'opacity-25 cursor-wait': loading,
            'opacity-50 pointer-events-none': draggerProps.disabled,
          }
        )}
        name='file'
        accept='.xlsx'
        showUploadList={false}
        {...draggerProps}
      >
        <Typography.Paragraph className='ant-upload-drag-icon'>
          {loading ? <LoadingOutlined spin className='text-6xl ' /> : <CloudUploadOutlined className='text-6xl text-vars-primaryColor' />}
        </Typography.Paragraph>
        <Typography.Paragraph className='mb-1 text-lg ant-upload-text'>
          Click or drag file to this area to start importing
        </Typography.Paragraph>
        <Typography.Paragraph className='text-sm font-hairline ant-upload-hint mb-0 pb-0'>Supports a single xlsx file</Typography.Paragraph>
      </Dragger>
    </div>
  )
}
