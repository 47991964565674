import { faRedo } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from 'antd'
import { ButtonProps } from 'antd/lib/button'
import React from 'react'

type RefreshButtonProps = ButtonProps

const RefreshButton: React.FC<RefreshButtonProps> = ({ ...other }) => (
  <Button
    key='reload'
    type='text'
    shape='circle'
    className='no-border custom-icon-btn'
    ghost
    icon={<FontAwesomeIcon icon={faRedo} />}
    {...other}
  />
)

export default RefreshButton
