import { Tag } from 'antd'
import { isArray } from 'lodash'
import React from 'react'
import Suppress from 'src/components/Suppress'
import OverflowText from 'src/components/table/table-overflow-text'

// .replace(/\s/g, '').split(/[|,;]+/)
const LineManagersCell = (props: { value?: string[] }) => {
  if (!props.value || !isArray(props.value)) return <Tag className='bg-transparent border-none my-1'>N/A</Tag>
  const tags = props.value.filter(Boolean)
  if (tags?.length === 0) return <Tag className='bg-transparent border-none my-1'>No line managers</Tag>
  return (
    <OverflowText>
      <div className='w-full'>
        {tags.map((tag, i) => (
          <Tag key={i} className='bg-vars-componentBackground my-1'>
            <Suppress>{tag}</Suppress>
          </Tag>
        ))}
      </div>
    </OverflowText>
  )
}

export default LineManagersCell
