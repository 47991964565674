import { API as AmplifyAPI } from 'aws-amplify'
import { AWSError } from 'aws-sdk'
import { DocumentClient, QueryOutput } from 'aws-sdk/clients/dynamodb'
import { PromiseResult } from 'aws-sdk/lib/request'
import LZUTF8 from 'lzutf8'
import { EmployeeDetails } from 'src/redux/store/employees/types/directory'
import { FLOW_NATURES_ENUM } from 'src/typings/kenai/enums'
import AccessManager from 'src/utils/AccessManager'
import { getCredentialedDDBDocumentClientForRead, getTableNameFromAwsExports } from '../utils'

const employeeDirectoryAPI = {
  getEmployeesDirectory: async (requestedHierarchy: string) => {
    const createBatch = (batchHierarchy, batchUniqueAttributeValue) => {
      const paramsEvent = {
        ExpressionAttributeValues: {
          ':hk': batchHierarchy,
          ':rk': batchUniqueAttributeValue,
        },
        KeyConditionExpression: 'EntityHierarchy = :hk AND ProfileId > :rk',
        IndexName: 'EntityHierarchyHASH-ProfileIdRANGE',
        TableName: getTableNameFromAwsExports('mdWorkerOrgProfiles'),
      }
      const dynamodbDocumentClient = getCredentialedDDBDocumentClientForRead(batchHierarchy)
      return dynamodbDocumentClient.query(paramsEvent).promise()
    }

    const processBatch = async (batch, depth) => {
      let batchResults: EmployeeDetails[] = []
      const nextBatch: Promise<PromiseResult<QueryOutput, AWSError>>[] = []
      const asyncResults: PromiseResult<DocumentClient.QueryOutput, AWSError>[] = await Promise.all(batch)
      asyncResults.forEach((asyncResult) => {
        batchResults = batchResults.concat(asyncResult.Items as any)
        if (asyncResult.hasOwnProperty('LastEvaluatedKey')) {
          const { EntityHierarchy, ProfileId } = asyncResult.LastEvaluatedKey as {
            EntityHierarchy: string
            ProfileId: string
          }
          nextBatch.push(createBatch(EntityHierarchy, ProfileId))
        }
      })
      if (nextBatch.length > 0) {
        const nextBatchResults = await processBatch(nextBatch, depth + 1)
        batchResults = batchResults.concat(nextBatchResults)
      }
      return batchResults
    }

    try {
      const initialBatch: Promise<PromiseResult<QueryOutput, AWSError>>[] = []
      initialBatch.push(createBatch(requestedHierarchy, '0'))
      return await processBatch(initialBatch, 1)
    } catch (e) {
      console.error(e)
      return []
    }
  },

  addEmployees: (EntityHierarchy: string, employeesToAdd: EmployeeDetails[]) => {
    return new Promise((resolve, reject) => {
      const options = {
        headers: {
          Authorization: `Bearer ${AccessManager.idToken}`,
        },
        body: {
          operations: {
            EntityHierarchy,
            upserts: LZUTF8.compress(JSON.stringify(employeesToAdd), {
              outputEncoding: 'StorageBinaryString',
            }),
          },
        },
      }
      AmplifyAPI.post('directoryOperations', '/updateEmployeeDirectory', options)
        .then((data) => {
          resolve(data)
        })
        .catch((err) => {
          if (err && err.response && err.response.data) {
            reject(err.response)
          } else {
            reject(err)
          }
        })
    })
  },

  deleteEmployees: (
    EntityHierarchy: string,
    employeesToDelete: Pick<EmployeeDetails, 'EntityHierarchy' | 'personalIdentificationNr'>[]
  ) => {
    return new Promise((resolve, reject) => {
      const options = {
        headers: {
          Authorization: `Bearer ${AccessManager.idToken}`,
        },
        body: {
          operations: {
            EntityHierarchy,
            deletes: LZUTF8.compress(JSON.stringify(employeesToDelete), {
              outputEncoding: 'StorageBinaryString',
            }),
          },
        },
      }
      AmplifyAPI.post('directoryOperations', '/updateEmployeeDirectory', options)
        .then((data) => {
          resolve(data)
        })
        .catch((err) => {
          if (err && err.response && err.response.data) {
            reject(err.response)
          } else {
            reject(err)
          }
        })
    })
  },

  deleteEntityEmployeesList: (EntityHierarchy: string, flowId: string) => {
    return new Promise((resolve, reject) => {
      const options = {
        headers: {
          Authorization: `Bearer ${AccessManager.idToken}`,
        },
        body: {
          operations: {
            EntityHierarchy,
            deleteEntityEmployeeList: true,
            destinationFlowTypeRef: {
              flowId: flowId,
              flowNature: FLOW_NATURES_ENUM.WORKER,
            },
          },
        },
      }
      AmplifyAPI.post('directoryOperations', '/updateEmployeeDirectory', options)
        .then((data) => {
          resolve(data)
        })
        .catch((err) => {
          if (err && err.response && err.response.data) {
            reject(err.response)
          } else {
            reject(err)
          }
        })
    })
  },

  resetEmployeeSyncStatus(EntityHierarchy, personalIdentificationNr) {
    return new Promise((resolve, reject) => {
      const options = {
        headers: {
          Authorization: `Bearer ${AccessManager.idToken}`,
        },
        body: {
          operations: {
            EntityHierarchy,
            resetPersonalIdentificationNr: personalIdentificationNr,
          },
        },
      }
      AmplifyAPI.post('directoryOperations', '/updateEmployeeDirectory', options)
        .then((data) => {
          resolve(data)
        })
        .catch((err) => {
          if (err && err.response && err.response.data) {
            reject(err.response)
          } else {
            reject(err)
          }
        })
    })
  },

  copyDirectoryFromEntity(props: {
    source: { EntityHierarchy: string; flowId: string }
    destination: { EntityHierarchy: string; flowId: string }
  }) {
    const getBodyFromProps = (type: 'source' | 'destination', data: { EntityHierarchy: string; flowId: string }) => {
      return {
        [`${type}Hierarchy`]: data.EntityHierarchy,
        [`${type}FlowTypeRef`]: {
          flowId: data.flowId,
          flowNature: FLOW_NATURES_ENUM.WORKER,
        },
      }
    }

    const options = {
      headers: {
        Authorization: `Bearer ${AccessManager.idToken}`,
      },
      body: {
        operations: {
          copyFromSourceHierarchy: true,
          ...getBodyFromProps('source', props.source),
          ...getBodyFromProps('destination', props.destination),
        },
      },
    }
    return AmplifyAPI.post('directoryOperations', '/updateEmployeeDirectory', options).then((res) => {
      return res
    })
  },
}
export default employeeDirectoryAPI
