import { faBook, faStreetView } from '@fortawesome/pro-light-svg-icons'
import { lazy } from 'react'
import { ROUTE } from 'src/typings/kenai/dashboard-router'
import AccessManager from 'src/utils/AccessManager'

const InductionLog = lazy(() => import('src/containers/Induction/Log'))
const InductionCapture = lazy(() => import('src/containers/Induction/Capture'))

const InductionDisabledView = lazy(() => import('src/containers/Induction/disabled-view'))

export default function getInductionRoutes(inductionEnabled: boolean) {
  const isAdmin = AccessManager.selectedHierarchy.role === 'ADMIN'

  const disabled = !inductionEnabled && isAdmin

  const inductionRoutes: ROUTE = {
    icon: faStreetView,
    label: 'Induction',
    path: 'induction',
    sidebar: true,
    component: disabled ? InductionDisabledView : undefined,
    children: disabled
      ? undefined
      : [
          {
            path: 'induction/log',
            label: 'Log',
            icon: faBook,
            component: InductionLog,
            sidebar: true,
          },
          {
            path: 'induction/capture',
            component: InductionCapture,
            sidebar: false,
          },
        ],
  }

  return inductionRoutes
}
