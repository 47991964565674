import styled from 'styled-components'

const Container = styled.div`
  width: 100%;
  padding: 35px;
  height: calc(100vh - 130px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 700px;
  text-align: center;
  margin: auto;

  h1,
  h4 {
  }
  h1 {
    font-size: 2.125rem;
  }
  h4 {
    font-size: 2.8125rem;
  }
  h6 {
    font-size: 1.5rem;
  }
  p {
    font-size: 0.875rem;
  }
`

const Image = styled.div`
  svg {
    max-width: 600px;
    width: 70vw;
  }
`

const Title = styled.h2`
  margin-top: 20px;
`

export { Container, Image, Title }
