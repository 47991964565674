import update from 'immutability-helper'
import { createSelector } from 'reselect'
import { RootState, ActionCreator } from 'src/redux/store'

// Action Creators
export const ActionCreators = {
  StoreSetInductionDirty: new ActionCreator<'StoreSetInductionDirty', State['dirty']>('StoreSetInductionDirty'),

  StoreSetVideoProcessing: new ActionCreator<
    'StoreSetInductionVideoProcessing',
    {
      processedVideo?: VideoProcessing
      channel: string
    }
  >('StoreSetInductionVideoProcessing'),

  StoreSetInductionConfig: new ActionCreator<'StoreSetInductionConfig', State>('StoreSetInductionConfig'),

  StoreSetInductionEnabled: new ActionCreator<'StoreSetInductionEnabled', State['inductionEnabled']>('StoreSetInductionEnabled'),

  //APP
  StoreSetAppPreRegistrationEnabled: new ActionCreator<
    'StoreSetInductionAppPreRegistrationEnabled',
    State['channels']['preRegistration']['enabled']
  >('StoreSetInductionAppPreRegistrationEnabled'),
  StoreSetAppKioskEnabled: new ActionCreator<'StoreSetAppKioskEnabled', State['channels']['kiosk']['enabled']>('StoreSetAppKioskEnabled'),
  StoreSetAppEmployeeLinkEnabled: new ActionCreator<'StoreSetAppEmployeeLinkEnabled', State['channels']['employeeLink']['enabled']>(
    'StoreSetAppEmployeeLinkEnabled'
  ),
  StoreSetPreRegistrationValidity: new ActionCreator<'StoreSetPreRegistrationValidity', State['channels']['preRegistration']['validity']>(
    'StoreSetPreRegistrationValidity'
  ),
  StoreSetPreRegQuestionsEnabled: new ActionCreator<
    'StoreSetPreRegQuestionsEnabled',
    State['channels']['preRegistration']['questions']['enabled']
  >('StoreSetPreRegQuestionsEnabled'),
  StoreSetKioskQuestionsEnabled: new ActionCreator<'StoreSetKioskQuestionsEnabled', State['channels']['kiosk']['questions']['enabled']>(
    'StoreSetKioskQuestionsEnabled'
  ),
  StoreSetKioskValidity: new ActionCreator<'StoreSetKioskValidity', State['channels']['kiosk']['validityType']>('StoreSetKioskValidity'),
  StoreSetEmployeeLinkQuestionsEnabled: new ActionCreator<
    'StoreSetEmployeeLinkQuestionsEnabled',
    State['channels']['employeeLink']['questions']['enabled']
  >('StoreSetEmployeeLinkQuestionsEnabled'),
  StoreSetEmployeeLinkValidity: new ActionCreator<'StoreSetEmployeeLinkValidity', State['channels']['employeeLink']['validity']>(
    'StoreSetEmployeeLinkValidity'
  ),
  //SCREENING ROOM
  StoreSetScreeningRoomEnabled: new ActionCreator<'StoreSetInductionScreeningRoomEnabled', State['channels']['screeningRoom']['enabled']>(
    'StoreSetInductionScreeningRoomEnabled'
  ),

  StoreSetScreeningRoomValidity: new ActionCreator<
    'StoreSetInductionScreeningValidity',
    State['channels']['screeningRoom']['validityType']
  >('StoreSetInductionScreeningValidity'),
  StoreSetScreeningRoomVersion: new ActionCreator<'StoreSetInductionScreeningVersion', State['channels']['screeningRoom']['version']>(
    'StoreSetInductionScreeningVersion'
  ),
  StoreSetPreRegVersion: new ActionCreator<'StoreSetPreRegVersion', State['channels']['preRegistration']['video']['version']>(
    'StoreSetPreRegVersion'
  ),
  StoreSetKioskVersion: new ActionCreator<'StoreSetKioskVersion', State['channels']['kiosk']['video']['version']>('StoreSetKioskVersion'),
  StoreSetEmployeeLinkVersion: new ActionCreator<'StoreSetEmployeeLinkVersion', State['channels']['employeeLink']['video']['version']>(
    'StoreSetEmployeeLinkVersion'
  ),
  StoreDisablePreRegVideo: new ActionCreator<'StoreDisablePreRegVideo', void>('StoreDisablePreRegVideo'),
  StoreDisableKioskVideo: new ActionCreator<'StoreDisableKioskVideo', void>('StoreDisableKioskVideo'),
  StoreDisableEmployeeLinkVideo: new ActionCreator<'StoreDisableEmployeeLinkVideo', void>('StoreDisableEmployeeLinkVideo'),
  StoreClearAllConfiguration: new ActionCreator<'StoreClearAllConfiguration', void>('StoreClearAllConfiguration'),
  ClearAllStates: new ActionCreator<'ClearAllStates', void>('ClearAllStates'),
}

export type Action = typeof ActionCreators[keyof typeof ActionCreators]
export interface VideoProcessing {
  localVideoSrc: string
  metadata: { duration: number }
  hasVideoChange: boolean
}

export interface VideoCopyOp {
  fromVersion: number
  toVersion: number
  duration: number
}

export type State = AccountInductionSettings & {
  dirty: boolean
  preRegistrationVideoProcessing?: VideoProcessing
  kioskVideoProcessing?: VideoProcessing
  employeeLinkVideoProcessing?: VideoProcessing
  preRegistrationVideoVersionCopyOp?: VideoCopyOp
  kioskVideoVersionCopyOp?: VideoCopyOp
  employeeLinkVideoVersionCopyOp?: VideoCopyOp
}

// State
export const initialState = (): State => {
  return {
    dirty: false,
    inductionEnabled: false,
    channels: {
      kiosk: {
        enabled: false,
        validityType: {
          visitor: 100,
          employee: 100,
        },
        video: {
          duration: 0,
          version: 1,
          state: 'INITIAL',
        },
        questions: {
          enabled: false,
          randomOrder: false,
          amountToAsk: 0,
          minimumPassCount: 0,
          maximumRetryBeforeRewatch: 0,
        },
      },
      preRegistration: {
        enabled: false,
        validity: 100,
        video: {
          duration: 0,
          version: 1,
          state: 'INITIAL',
        },
        questions: {
          enabled: false,
          randomOrder: false,
          amountToAsk: 0,
          minimumPassCount: 0,
          maximumRetryBeforeRewatch: 0,
        },
      },
      employeeLink: {
        enabled: false,
        validity: 100,
        video: {
          duration: 0,
          version: 1,
          state: 'INITIAL',
        },
        questions: {
          enabled: false,
          randomOrder: false,
          amountToAsk: 0,
          minimumPassCount: 0,
          maximumRetryBeforeRewatch: 0,
        },
      },
      screeningRoom: {
        enabled: false,
        validityType: {
          visitor: 365,
          employee: 365,
        },
        version: 1,
      },
    },
    preRegistrationVideoProcessing: undefined,
    kioskVideoProcessing: undefined,
    employeeLinkVideoProcessing: undefined,
    preRegistrationVideoVersionCopyOp: undefined,
    kioskVideoVersionCopyOp: undefined,
    employeeLinkVideoVersionCopyOp: undefined,
  }
}

// Reducer
export default function reducer(state: State = initialState(), action: Action) {
  switch (action.type) {
    case ActionCreators.StoreSetInductionDirty.type:
      return update(state, { dirty: { $set: action.payload } })
    case ActionCreators.StoreSetVideoProcessing.type:
      return update(state, {
        [`${action.payload.channel}VideoProcessing`]: { $set: action.payload.processedVideo },
      })
    case ActionCreators.StoreSetInductionConfig.type:
      return update(state, { $set: action.payload })
    case ActionCreators.StoreSetInductionEnabled.type:
      return update(state, {
        inductionEnabled: { $set: action.payload },
      })
    //APP
    case ActionCreators.StoreSetAppPreRegistrationEnabled.type:
      return update(state, {
        channels: {
          preRegistration: {
            enabled: { $set: action.payload },
          },
        },
      })
    case ActionCreators.StoreSetAppKioskEnabled.type:
      return update(state, {
        channels: {
          kiosk: {
            enabled: { $set: action.payload },
          },
        },
      })
    case ActionCreators.StoreSetAppEmployeeLinkEnabled.type:
      return update(state, {
        channels: {
          employeeLink: {
            enabled: { $set: action.payload },
          },
        },
      })
    case ActionCreators.StoreSetPreRegistrationValidity.type:
      return update(state, {
        channels: {
          preRegistration: {
            validity: { $set: action.payload },
          },
        },
      })
    case ActionCreators.StoreSetPreRegQuestionsEnabled.type:
      return update(state, {
        channels: {
          preRegistration: {
            questions: {
              enabled: { $set: action.payload },
            },
          },
        },
      })
    case ActionCreators.StoreSetKioskQuestionsEnabled.type:
      return update(state, {
        channels: {
          kiosk: {
            questions: {
              enabled: { $set: action.payload },
            },
          },
        },
      })
    case ActionCreators.StoreSetEmployeeLinkQuestionsEnabled.type:
      return update(state, {
        channels: {
          employeeLink: {
            questions: {
              enabled: { $set: action.payload },
            },
          },
        },
      })
    case ActionCreators.StoreSetKioskValidity.type:
      return update(state, {
        channels: {
          kiosk: {
            validityType: { $set: action.payload },
          },
        },
      })
    case ActionCreators.StoreSetEmployeeLinkValidity.type:
      return update(state, {
        channels: {
          employeeLink: {
            validity: { $set: action.payload },
          },
        },
      })
    //Screening Room
    case ActionCreators.StoreSetScreeningRoomEnabled.type:
      return update(state, {
        channels: {
          screeningRoom: {
            enabled: { $set: action.payload },
          },
        },
      })

    case ActionCreators.StoreSetScreeningRoomValidity.type:
      return update(state, {
        channels: {
          screeningRoom: {
            validityType: { $set: action.payload },
          },
        },
      })
    case ActionCreators.StoreSetScreeningRoomVersion.type:
      return update(state, {
        channels: {
          screeningRoom: {
            version: { $set: action.payload },
          },
        },
      })
    case ActionCreators.StoreSetPreRegVersion.type: {
      let preRegistrationVideoVersionCopyOp: VideoCopyOp | undefined = undefined
      if (state.channels.preRegistration.video.state === 'ACTIVATED' && !state.preRegistrationVideoProcessing) {
        if (!state.preRegistrationVideoVersionCopyOp) {
          preRegistrationVideoVersionCopyOp = {
            fromVersion: state.channels.preRegistration.video.version,
            toVersion: action.payload,
            duration: state.channels.kiosk.video.duration,
          }
        } else {
          if (state.preRegistrationVideoVersionCopyOp.fromVersion !== action.payload) {
            preRegistrationVideoVersionCopyOp = {
              ...state.preRegistrationVideoVersionCopyOp,
              toVersion: action.payload,
            }
          }
        }
      }
      return update(state, {
        channels: {
          preRegistration: {
            video: {
              version: { $set: action.payload },
            },
          },
        },
        preRegistrationVideoVersionCopyOp: {
          $set: preRegistrationVideoVersionCopyOp,
        },
      })
    }
    case ActionCreators.StoreSetKioskVersion.type: {
      let kioskVideoVersionCopyOp: VideoCopyOp | undefined = undefined
      if (state.channels.kiosk.video.state === 'ACTIVATED' && !state.kioskVideoProcessing) {
        if (!state.kioskVideoVersionCopyOp) {
          kioskVideoVersionCopyOp = {
            fromVersion: state.channels.kiosk.video.version,
            toVersion: action.payload,
            duration: state.channels.kiosk.video.duration,
          }
        } else {
          if (state.kioskVideoVersionCopyOp.fromVersion !== action.payload) {
            kioskVideoVersionCopyOp = {
              ...state.kioskVideoVersionCopyOp,
              toVersion: action.payload,
            }
          }
        }
      }
      return update(state, {
        channels: {
          kiosk: {
            video: {
              version: { $set: action.payload },
            },
          },
        },
        kioskVideoVersionCopyOp: {
          $set: kioskVideoVersionCopyOp,
        },
      })
    }
    case ActionCreators.StoreSetEmployeeLinkVersion.type: {
      let employeeLinkVideoVersionCopyOp: VideoCopyOp | undefined = undefined
      if (state.channels.employeeLink.video.state === 'ACTIVATED' && !state.employeeLinkVideoProcessing) {
        if (!state.employeeLinkVideoVersionCopyOp) {
          employeeLinkVideoVersionCopyOp = {
            fromVersion: state.channels.employeeLink.video.version,
            toVersion: action.payload,
            duration: state.channels.employeeLink.video.duration,
          }
        } else {
          if (state.employeeLinkVideoVersionCopyOp.fromVersion !== action.payload) {
            employeeLinkVideoVersionCopyOp = {
              ...state.employeeLinkVideoVersionCopyOp,
              toVersion: action.payload,
            }
          }
        }
      }
      return update(state, {
        channels: {
          employeeLink: {
            video: {
              version: { $set: action.payload },
            },
          },
        },
        employeeLinkVideoVersionCopyOp: {
          $set: employeeLinkVideoVersionCopyOp,
        },
      })
    }
    case ActionCreators.StoreDisablePreRegVideo.type:
      return update(state, {
        channels: {
          preRegistration: {
            video: {
              state: { $set: 'INITIAL' },
            },
          },
        },
        preRegistrationVideoProcessing: {
          $set: undefined,
        },
      })
    case ActionCreators.StoreDisableKioskVideo.type:
      return update(state, {
        channels: {
          kiosk: {
            video: {
              state: { $set: 'INITIAL' },
            },
          },
        },
        kioskVideoProcessing: {
          $set: undefined,
        },
      })
    case ActionCreators.StoreDisableEmployeeLinkVideo.type:
      return update(state, {
        channels: {
          employeeLink: {
            video: {
              state: { $set: 'INITIAL' },
            },
          },
        },
        employeeLinkVideoProcessing: {
          $set: undefined,
        },
      })
    case ActionCreators.StoreClearAllConfiguration.type:
      return initialState()
    case ActionCreators.ClearAllStates.type:
      return initialState()
    default:
      return state
  }
}

//selectors
const getAccountInductionConfigState = (state: RootState) => state.settings.general.account.induction
export const getAccountInductionConfig = createSelector<RootState, State, State>([getAccountInductionConfigState], (state) => state)

const getAccountInductionDirtyState = (state: RootState) => state.settings.general.account.induction.dirty
export const getAccountInductionDirty = createSelector<RootState, State['dirty'], State['dirty']>(
  [getAccountInductionDirtyState],
  (state) => state
)
