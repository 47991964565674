import customFields, { State as customFieldsState } from './custom-fields'

import { combineReducers } from 'redux'

export interface State {
  customFields: customFieldsState
}

export default combineReducers<State>({
  customFields,
})
