import { EmployeeDetails } from 'src/redux/store/employees/types/directory'

export function filterEmployeeSearch(data: EmployeeDetails[], searchValue: string) {
  if (searchValue.length > 0) {
    return data.filter((employee) => {
      let includeInResult = false
      for (const key in employee) {
        if (employee.hasOwnProperty(key)) {
          const value = employee[key]
          if ((typeof value === 'string' || typeof value === 'number') && `${value}`.toLowerCase().includes(searchValue.toLowerCase())) {
            includeInResult = true
            break
          }
          if (Array.isArray(value)) {
            const hasValueInArray = value
              .filter((arrayValue) => typeof arrayValue === 'string' || typeof arrayValue === 'number')
              .some((arrayValue) => `${arrayValue}`.toLowerCase().includes(searchValue.toLowerCase()))
            if (hasValueInArray) {
              includeInResult = true
              break
            }
          }
        }
      }
      return includeInResult
    })
  }
  return data
}
