import { take, fork, cancel } from 'redux-saga/effects'
import { Store } from 'redux'
import rootSaga from './rootSaga'
import { RootState } from 'src/redux/store/rootReducer'
import { SagaMiddleware } from 'redux-saga'

const sagas = [rootSaga]

export const CANCEL_SAGAS_HMR = 'CANCEL_SAGAS_HMR'

function createAbortableSaga(saga: typeof rootSaga) {
  if (process.env.NODE_ENV === 'development') {
    return function* main() {
      const sagaTask = yield fork(saga)

      yield take(CANCEL_SAGAS_HMR)
      yield cancel(sagaTask)
    }
  } else {
    return saga
  }
}

const SagaManager = {
  startSagas(sagaMiddleware: SagaMiddleware) {
    sagas.map(createAbortableSaga).forEach((saga) => sagaMiddleware.run(saga))
  },

  cancelSagas(store: Store<RootState>) {
    store.dispatch({
      type: CANCEL_SAGAS_HMR,
    })
  },
}

export default SagaManager
