import ObsoleteRefresh from 'containers/Settings/Notifications/utils/obsolete-refresh'
import { all, fork, put, take, takeEvery, takeLeading } from 'redux-saga/effects'
import { retrieveConfigCustomNotifications } from 'src/api/hierarchyOperations'
import { customNotificationProcessor } from 'src/api/provisioningOperations/customNotificationProcessor'
import { notificationHelpers } from 'src/containers/Settings/Notifications/utils/notificationTypes'
import { refreshApiCredentials } from 'src/redux/sagas/authentication/refreshApiCredentials'
import { ActionCreators as SagaCredentialsActionCreators } from 'src/redux/sagas/credentials'
import ActionCreator from 'src/redux/sagas/saga-action-creator'
import { ActionCreators as StoreActionCreators, getSettingsCustomNotifications } from 'src/redux/store/settings/general-notifications'
import { notification } from 'src/utils'
import { call, select } from 'typed-redux-saga'
import { ActionCreators as CredentialsActionCreators } from 'redux/store/credentials'
type Payload = { EntityHierarchy: string; eventType: NOTIFICATION_EVENT_TYPES }

type AddPayload = Payload & Partial<ConfigValueNewCustomNotification>
type RemovePayload = Payload & { lmt: number; notificationId: string }
type EditPayload = Payload & Partial<ConfigValueCustomNotification | ConfigValueNewCustomNotification>

// Action Creators
export const ActionCreators = {
  SagaRetrieveNotifications: new ActionCreator<'SagaRetrieveNotifications', Payload>('SagaRetrieveNotifications'),
  SagaAddNotification: new ActionCreator<'SagaAddNotification', AddPayload>('SagaAddNotification'),
  SagaRemoveNotification: new ActionCreator<'SagaRemoveNotification', RemovePayload>('SagaRemoveNotification'),
  SagaEditNotification: new ActionCreator<'SagaEditNotification', EditPayload>('SagaEditNotification'),
  SagaRefreshNotificationConfigs: new ActionCreator<'SagaRefreshNotificationConfigs', Payload>('SagaRefreshNotificationConfigs'),
  SagaRefreshNotificationConfigsUpdate: new ActionCreator<'SagaRefreshNotificationConfigsUpdate', any>(
    'SagaRefreshNotificationConfigsUpdate'
  ),
}

function* processSagaRetrieveNotifications(action: typeof ActionCreators['SagaRetrieveNotifications']) {
  try {
    yield refreshApiCredentials()

    const eventsKeys = notificationHelpers.getEventsForType(action.payload.eventType)?.map((event) => event.notificationType)

    const results = yield* call(retrieveConfigCustomNotifications, action.payload.EntityHierarchy, eventsKeys)

    if (results) {
      yield put(
        StoreActionCreators.StoreSetSettingsCustomNotifications.create({
          key: action.payload.eventType,
          data: results,
        })
      )
    }

    if (action.callback) action.callback({ status: 'success' })
  } catch (e) {
    console.error(e)
    notification('error', e?.key || e?.message || JSON.stringify(e))
    if (action.callback) action.callback({ status: 'failed' })
  }
}

function* processSagaAddNotification(action: typeof ActionCreators['SagaAddNotification']) {
  try {
    yield refreshApiCredentials()

    const { eventType, ...payload } = action.payload
    const result = yield* call(customNotificationProcessor, 'addNotification', payload)
    if (result?.key !== 'OPERATION_PROCESSED') throw new Error(result?.error ?? 'The operation failed')
    if (result.notificationResult) {
      yield put(ActionCreators.SagaRefreshNotificationConfigs.create(action.payload))
      yield take(ActionCreators.SagaRefreshNotificationConfigsUpdate.type)
      const allNotifications: Record<NOTIFICATION_EVENT_TYPES, ConfigValueCustomNotification[]> = yield* select(
        getSettingsCustomNotifications
      )
      yield put(
        StoreActionCreators.StoreSetSettingsCustomNotifications.create({
          key: eventType,
          data: allNotifications[eventType].map((entry) =>
            entry.notificationId === payload.notificationId ? result.notificationResult : entry
          ),
        })
      )
    }
    if (action.callback) action.callback({ status: 'success' })
  } catch (e) {
    console.error(e)
    notification('error', e?.key || e?.message || JSON.stringify(e))
    if (action.callback) action.callback({ status: 'failed' })
  }
}

function* processSagaRemoveNotification(action: typeof ActionCreators['SagaRemoveNotification']) {
  try {
    yield refreshApiCredentials()

    const { eventType, ...payload } = action.payload
    const result = yield* call(customNotificationProcessor, 'deleteNotification', payload)

    if (result?.key !== 'OPERATION_PROCESSED') throw new Error(result?.error ?? 'The operation failed')

    const customNotifications = yield* select(getSettingsCustomNotifications)
    yield put(
      StoreActionCreators.StoreSetSettingsCustomNotifications.create({
        key: action.payload.eventType,
        data: customNotifications[eventType].filter((e) => e.notificationId !== action.payload.notificationId),
      })
    )

    if (action.callback) action.callback({ status: 'success' })
  } catch (e) {
    console.error(e)
    notification('error', ObsoleteRefresh.getMessage(e?.message) || 'We could not process your request, please try again')
    if (action.callback) action.callback({ status: 'failed', message: e.message })
  }
}

function* processSagaEditNotification(action: typeof ActionCreators['SagaEditNotification']) {
  try {
    yield refreshApiCredentials()

    const { eventType, ...payload } = action.payload
    const result = yield* call(customNotificationProcessor, 'editNotification', payload)

    if (result?.key !== 'OPERATION_PROCESSED') throw new Error(result?.error ?? 'The operation failed')

    if (result.notificationResult) {
      yield put(ActionCreators.SagaRefreshNotificationConfigs.create(action.payload))
      yield take(ActionCreators.SagaRefreshNotificationConfigsUpdate.type)
      const allNotifications: Record<NOTIFICATION_EVENT_TYPES, ConfigValueCustomNotification[]> = yield* select(
        getSettingsCustomNotifications
      )
      yield put(
        StoreActionCreators.StoreSetSettingsCustomNotifications.create({
          key: action.payload.eventType,
          data: allNotifications[action.payload.eventType].map((entry) =>
            entry.notificationId === payload.notificationId ? result.notificationResult : entry
          ),
        })
      )
    }

    if (action.callback) action.callback({ status: 'success' })
  } catch (e) {
    console.error(e)
    notification('error', ObsoleteRefresh.getMessage(e?.message) || 'We could not process your request, please try again')
    if (action.callback) action.callback({ status: 'failed', message: e.message })
  }
}

function* processSagaRefreshNotificationConfigs(action: typeof ActionCreators['SagaRefreshNotificationConfigs']) {
  try {
    yield refreshApiCredentials()
    const EntityHierarchy = action.payload.EntityHierarchy
    yield put(
      ActionCreators.SagaRetrieveNotifications.create({
        EntityHierarchy,
        eventType: action.payload.eventType,
      })
    )
    yield take(StoreActionCreators.StoreSetSettingsCustomNotifications.type)
    yield put(SagaCredentialsActionCreators.SagaGetConfigI18nTexts.create(EntityHierarchy))
    yield take(CredentialsActionCreators.StoreSetConfigI18nTexts.type)
    yield put(ActionCreators.SagaRefreshNotificationConfigsUpdate.create())
    if (action.callback) action.callback({ status: 'success' })
  } catch (e) {
    console.error(e)
    notification('error', e?.key || e?.message || JSON.stringify(e))
    if (action.callback) action.callback({ status: 'failed', message: e.key ?? e.message })
  }
}

// Saga triggers
function* watchCustomFieldsSagas() {
  yield takeEvery(ActionCreators.SagaRetrieveNotifications.type, processSagaRetrieveNotifications)
  yield takeLeading(ActionCreators.SagaAddNotification.type, processSagaAddNotification)
  yield takeLeading(ActionCreators.SagaRemoveNotification.type, processSagaRemoveNotification)
  yield takeLeading(ActionCreators.SagaEditNotification.type, processSagaEditNotification)
  yield takeLeading(ActionCreators.SagaRefreshNotificationConfigs.type, processSagaRefreshNotificationConfigs)
  yield null
}

// Saga hooks
export default function* customFieldsSaga() {
  yield all([fork(watchCustomFieldsSagas)])
}
