import { cloneDeep, findIndex, orderBy } from 'lodash'
import moment from 'moment'
import { actionChannel, all, fork, put, select, take, takeLeading } from 'redux-saga/effects'
import AWSHelpers from 'src/api/AWSHelpers'
import parkingBookingLogRetrieval from 'src/api/parkingOperations/parkingBookingLogRetrieval'
import processParkingBooking, {
  OmitOperation,
  CreateBody,
  CancelBody,
  CheckBody,
} from 'src/api/parkingOperations/processOnDemandParkingBooking'
import { checkIfTimeExists } from 'src/redux/helpers/functions'
import { ActionCreators as AuthenticationActionCreators } from 'src/redux/sagas/authentication'
import { ActionCreators as CredentialsStoreActionCreators, getEntityHierarchyConfiguration } from 'src/redux/store/credentials'
import { getParkingBookingSubscriptionCredential } from 'src/redux/store/selectors'
import { ActionCreators as ParkingBookingActionCreators, getParkingBookingData } from 'src/redux/store/parking/booking'
import { ParkingBookingLogItem } from 'src/typings/kenai/parking/booking'
import { RangePickerNumberValue } from 'src/typings/vendor/rangepicker'
import AccessManager from 'src/utils/AccessManager'
import { call } from 'typed-redux-saga'
import ActionCreator from '../saga-action-creator'
import { getLiveUpdateEarliestTimestamp } from 'src/redux/store/dashboard/globalevents'
import { tuple } from 'src/utils/tuple'

const AWSHelper = new AWSHelpers()

// Action Creators
export const ActionCreators = {
  SagaRetrieveParkingBookingData: new ActionCreator<
    'SagaRetrieveParkingBookingData',
    { beginTime: number; endTime: number; shouldSubscribeToUpdates?: boolean; bookingsType: 'ONDEMAND' | 'PROVISIONED' | 'all' }
  >('SagaRetrieveParkingBookingData'),
  SagaCreateParkingBookings: new ActionCreator<'SagaCreateParkingBookings', OmitOperation<CreateBody>>('SagaCreateParkingBookings'),
  SagaCheckParkingBookings: new ActionCreator<'SagaCheckParkingBookings', OmitOperation<CheckBody>>('SagaCheckParkingBookings'),
  SagaCancelParkingBookings: new ActionCreator<'SagaCancelParkingBookings', OmitOperation<CancelBody>>('SagaCancelParkingBookings'),
  SagaSetParkingDateRange: new ActionCreator<'SagaSetParkingDateRange', RangePickerNumberValue>('SagaSetParkingDateRange'),
  SagaRefreshParkingBookingSubscriptionCredential: new ActionCreator<'SagaRefreshParkingBookingSubscriptionCredential', string>(
    'SagaRefreshParkingBookingSubscriptionCredential'
  ),
  SagaParkingBookingLiveUpdateHandler: new ActionCreator<'SagaParkingBookingLiveUpdateHandler', { beginTime: number; endTime: number }>(
    'SagaParkingBookingLiveUpdateHandler'
  ),
}

const generateSearchString = (data: ParkingBookingLogItem[]) => {
  const commonType = (item) => {
    return typeof item === 'string' || item === 'number'
  }
  return data.map((item) => {
    let searchString = ''
    const filterKeys = ['BookingRefCode', 'company', 'email', 'phoneNumber']
    searchString += ` ${[item.firstName, item.lastName].join(' ')} `
    Object.keys(item).forEach((key) => {
      if (filterKeys.includes(key) && commonType(item[key])) {
        searchString += ` ${item[key]} `
      }
    })

    if (item.parkingData) {
      searchString += Object.keys(item.parkingData)
        .filter((key) => commonType(item.parkingData[key]))
        .map((key) => item.parkingData[key])
        .join(' ')
    }

    searchString += ` ${
      item.parkingProviderRefs?.parkingAllocationType === 'SECTION'
        ? item.parkingProviderRefs?.assignedParkingSection
        : item.parkingProviderRefs?.assignedParkingBay
    } `
    return {
      ...item,
      searchString: searchString.toLowerCase(),
    }
  })
}

function* processRetrieveParkingBookingData(action: typeof ActionCreators['SagaRetrieveParkingBookingData']) {
  try {
    yield put(AuthenticationActionCreators.SagaRefreshApiCredentials.create())
    yield take(AuthenticationActionCreators.SagaApiCredentialsRefreshUpdate.type)
    const beginTime = checkIfTimeExists(action, 'beginTime') ? action.payload.beginTime.valueOf() : moment().startOf('day').valueOf()
    const endTime = checkIfTimeExists(action, 'endTime') ? action.payload.endTime.valueOf() : 9999999999999

    const bookingTypes =
      action.payload.bookingsType === 'all' ? tuple('ONDEMAND', 'PROVISIONED') : [action.payload.bookingsType || 'ONDEMAND']

    const entityHierarchy: string = AccessManager.selectedHierarchy.hierarchyStructure

    const entityHierarchyConfiguration = yield select(getEntityHierarchyConfiguration)

    const response = yield* call(
      parkingBookingLogRetrieval,
      beginTime,
      endTime,
      entityHierarchy,
      entityHierarchyConfiguration,
      bookingTypes
    )

    if (response.key !== 'LOG_RETRIEVED') {
      throw response
    }

    yield put(ParkingBookingActionCreators.StoreSetParkingBookingData.create(generateSearchString(response.logItems)))

    action?.callback?.({ status: 'success' })
  } catch (e) {
    console.error(e || 'Error retrieving log')
    action?.callback?.({ status: 'failed' })
  }
}

function* processCheckParkingBooking(action: typeof ActionCreators['SagaCheckParkingBookings']) {
  try {
    yield put(AuthenticationActionCreators.SagaRefreshApiCredentials.create())
    yield take(AuthenticationActionCreators.SagaApiCredentialsRefreshUpdate.type)
    const checkApi = processParkingBooking().check

    const response = yield* call(checkApi, action.payload)

    // API returned an error
    if (response.key !== 'OPERATION_PROCESSED') {
      throw new Error(response.message)
    }

    // // It has items to upsert
    // if (response.bookingResult?.[0]) {
    //   const currentData = yield select(getParkingBookingData)
    //   const upsertData = generateSearchString(response.bookingResult)
    //   yield put(ParkingBookingActionCreators.StoreSetParkingBookingData.create([...upsertData, ...currentData]))
    // }

    // All actions have been processed lets run our callback magic
    action?.callback?.({ status: 'success' })
  } catch (e) {
    console.error(e || 'Error checking booking')
    const message = e?.response?.data?.error?.message
      ? e.response.data.error.message
      : e?.error?.message
      ? e.error.message
      : e?.message
      ? e.message
      : undefined
    action?.callback?.({ status: 'failed', message })
  }
}

function* processCreateParkingBooking(action: typeof ActionCreators['SagaCreateParkingBookings']) {
  try {
    yield put(AuthenticationActionCreators.SagaRefreshApiCredentials.create())
    yield take(AuthenticationActionCreators.SagaApiCredentialsRefreshUpdate.type)

    const response = yield* call(processParkingBooking<ParkingBookingLogItem[]>().create, action.payload)

    // API returned an error
    if (response.key !== 'OPERATION_PROCESSED') {
      throw response
    }

    // It has items to upsert
    if (response.bookingResult?.[0]) {
      const currentData = yield select(getParkingBookingData)
      const upsertData = generateSearchString(response.bookingResult)
      yield put(ParkingBookingActionCreators.StoreSetParkingBookingData.create([...upsertData, ...currentData]))
    }

    // All actions have been processed lets run our callback magic
    action?.callback?.({ status: 'success' })
  } catch (e) {
    console.error(e || 'Error creating booking')
    const message = e?.response?.data?.error?.message
      ? e.response.data.error.message
      : e?.error?.message
      ? e.error.message
      : e?.message
      ? e.message
      : undefined
    const details = e?.response?.data?.error?.details
      ? e.response.data.error.details
      : e?.error?.details
      ? e.error.details
      : e?.details
      ? e.details
      : undefined
    action?.callback?.({ status: 'failed', message, details })
  }
}

function* processCancelParkingBooking(action: typeof ActionCreators['SagaCancelParkingBookings']) {
  try {
    yield put(AuthenticationActionCreators.SagaRefreshApiCredentials.create())
    yield take(AuthenticationActionCreators.SagaApiCredentialsRefreshUpdate.type)

    const response = yield* call(processParkingBooking().cancel, action.payload)

    if (response.key !== 'OPERATION_PROCESSED') {
      throw response
    }

    // Lets filter out all the cancelled bookings from our local data
    const { entries } = action.payload

    const currentData: ParkingBookingLogItem[] = (yield select(getParkingBookingData)).filter(
      (record) => !entries.some((entry) => entry.BookingRefCode === record.BookingRefCode)
    )

    yield put(ParkingBookingActionCreators.StoreSetParkingBookingData.create(currentData))

    action?.callback?.({ status: 'success' })
  } catch (e) {
    console.error(e || 'Error cancelling booking')
    const message = e?.response?.data?.error?.message
      ? e.response.data.error.message
      : e?.error?.message
      ? e.error.message
      : e?.message
      ? e.message
      : undefined
    action?.callback?.({ status: 'failed', message })
  }
}

function* processRefreshParkingBookingSubscriptionCredential(action) {
  try {
    yield put(AuthenticationActionCreators.SagaRefreshApiCredentials.create())
    yield take(AuthenticationActionCreators.SagaApiCredentialsRefreshUpdate.type)
    const currentSubscriptionCredential = yield select(getParkingBookingSubscriptionCredential)
    const clientId = action.payload
    let shouldRetrieveNewCredentials = true
    if (currentSubscriptionCredential && currentSubscriptionCredential.Expiration) {
      const expiry = moment(currentSubscriptionCredential.Expiration)
      const now = moment()
      const diff = expiry.diff(now)
      if (diff / 1000 > 60 * 5) {
        //allow for 5 minute clock skew
        shouldRetrieveNewCredentials = false
      }
    }
    if (shouldRetrieveNewCredentials) {
      const entityHierarchyConfiguration = yield select(getEntityHierarchyConfiguration)
      const topics: Array<string> = []
      entityHierarchyConfiguration.hierarchy.deepStructure.forEach((hierarchy) => {
        //just subscribe to the root
        const topicWithOutTrailingSlash = `parkingBookings/${hierarchy.hierarchyStructure.replace(/#/g, '/')}`
        if (topics.length < 8) {
          //never subscribe to more than 8
          topics.push(topicWithOutTrailingSlash)
        }
      })
      const subscriptionCredential = yield call([AWSHelper, AWSHelper.retrieveSubscribeCredential], topics, clientId)
      if (subscriptionCredential.key === 'CREDENTIAL_RETRIEVED') {
        yield put(
          CredentialsStoreActionCreators.StoreSetParkingBookingSubscriptionCredential.create({
            ...subscriptionCredential.credential,
            ts: Date.now(),
          })
        )
      } else {
        yield put(CredentialsStoreActionCreators.StoreSetParkingBookingSubscriptionCredential.create(undefined))
      }
    } else if (!shouldRetrieveNewCredentials && currentSubscriptionCredential) {
      yield put(
        CredentialsStoreActionCreators.StoreSetParkingBookingSubscriptionCredential.create({
          ...currentSubscriptionCredential,
          ts: Date.now(),
        })
      ) //triggers gDSfP in subscription component
    }
  } catch (e) {
    if (e) {
      console.error(e)
    }
    yield put(CredentialsStoreActionCreators.StoreSetParkingBookingSubscriptionCredential.create(undefined))
  }
}

function* processParkingBookingLiveUpdateHandler(action) {
  try {
    const eventPayload = action.payload
    const liveUpdateEarliestTimestamp = yield select(getLiveUpdateEarliestTimestamp)
    if (eventPayload.messageReceivedTimeStamp > liveUpdateEarliestTimestamp) {
      const entityHierarchyConfiguration = yield select(getEntityHierarchyConfiguration)
      const availableHierarchies = new Map()
      const addRecursive = (additionalChildren) => {
        for (const prop of additionalChildren) {
          availableHierarchies.set(prop.hierarchyStructure, true)
          if (prop.children.length > 0) {
            addRecursive(prop.children)
          }
        }
      }

      if (AccessManager.selectedHierarchy.hierarchyStructure === 'ALL') {
        addRecursive(entityHierarchyConfiguration.hierarchy.deepStructure)
      } else {
        addRecursive([entityHierarchyConfiguration.hierarchy.flatStructure[AccessManager.selectedHierarchy.hierarchyStructure]])
      }

      const deletions = new Map()
      const changes = new Map()
      const newEntries = new Map()
      eventPayload.events.forEach((logEvent) => {
        if (logEvent.eventType === 'REMOVE' && availableHierarchies.get(logEvent.EntityHierarchy) === true) {
          deletions.set(logEvent.BookingRefCode, logEvent)
        } else if (logEvent.eventType === 'MODIFY' && availableHierarchies.get(logEvent.EntityHierarchy) === true) {
          changes.set(logEvent.BookingRefCode, logEvent)
        } else if (logEvent.eventType === 'INSERT' && availableHierarchies.get(logEvent.EntityHierarchy) === true) {
          newEntries.set(logEvent.BookingRefCode, logEvent)
        }
      })

      const itemsToRetrieve: Array<{
        ManagementHierarchy: string
        BookingRefCode: string
      }> = []
      let retrievedItems: ParkingBookingLogItem[] = []
      changes.forEach((value) => {
        itemsToRetrieve.push({
          ManagementHierarchy: value.EntityHierarchy,
          BookingRefCode: value.BookingRefCode,
        })
      })
      newEntries.forEach((value) => {
        itemsToRetrieve.push({
          ManagementHierarchy: value.EntityHierarchy,
          BookingRefCode: value.BookingRefCode,
        })
      })
      if (itemsToRetrieve.length > 0) {
        yield put(AuthenticationActionCreators.SagaRefreshApiCredentials.create())
        yield take(AuthenticationActionCreators.SagaApiCredentialsRefreshUpdate.type)

        retrievedItems = yield call([AWSHelper, AWSHelper.getSelectedParkingBookings], itemsToRetrieve)
      }

      const currentLogData = yield select(getParkingBookingData)
      const parkinglogData = cloneDeep(currentLogData)

      deletions.forEach((value) => {
        const indexDelete = findIndex(parkinglogData, {
          BookingRefCode: value.BookingRefCode,
        })
        if (indexDelete !== -1) {
          parkinglogData.splice(indexDelete, 1)
        }
      })
      retrievedItems.forEach((retrievedItem) => {
        const changedEntry = changes.get(retrievedItem.BookingRefCode)
        const newEntry = newEntries.get(retrievedItem.BookingRefCode)
        if (changedEntry) {
          const indexCurrent = findIndex(parkinglogData, {
            BookingRefCode: retrievedItem.BookingRefCode,
          })
          if (indexCurrent !== -1) {
            parkinglogData.splice(indexCurrent, 1, retrievedItem)
          }
        } else if (newEntry) {
          const indexCurrent = findIndex(parkinglogData, {
            BookingRefCode: retrievedItem.BookingRefCode,
          })
          if (indexCurrent === -1) {
            parkinglogData.push(retrievedItem)
          }
        }
      })
      const orderedEventPairs = orderBy(parkinglogData, ['eventStartTime'], ['asc'])
      const upsertData = generateSearchString(orderedEventPairs)
      yield put(ParkingBookingActionCreators.StoreSetParkingBookingData.create(upsertData))
    }
  } catch (e) {
    console.log(e)
  }
}

// Saga triggers
function* watchParkingBookingSagas() {
  yield takeLeading(ActionCreators.SagaRetrieveParkingBookingData.type, processRetrieveParkingBookingData)
  yield takeLeading(ActionCreators.SagaCheckParkingBookings.type, processCheckParkingBooking)
  yield takeLeading(ActionCreators.SagaCreateParkingBookings.type, processCreateParkingBooking)
  yield takeLeading(ActionCreators.SagaCancelParkingBookings.type, processCancelParkingBooking)
  yield takeLeading(ActionCreators.SagaRefreshParkingBookingSubscriptionCredential.type, processRefreshParkingBookingSubscriptionCredential)
  yield null
}

function* watchParkingBookingLiveUpdateChannel() {
  const SagaParkingLogLiveUpdateHandlerChannel = yield actionChannel(ActionCreators.SagaParkingBookingLiveUpdateHandler.type)
  while (true) {
    const action = yield take(SagaParkingLogLiveUpdateHandlerChannel)
    yield call(processParkingBookingLiveUpdateHandler, action)
  }
}

// Saga hooks
export default function* parkingBookingSagas() {
  yield all([fork(watchParkingBookingSagas), fork(watchParkingBookingLiveUpdateChannel)])
}
