import { getTenantLocationName } from './getLocationName'
import { EntityHierarchyConfig, TenantFlags, HierarchyDeepStructure } from 'src/typings/kenai/configuration/entity-hierarchy'

export interface NestedHierarchyTree extends TenantFlags {
  key: string
  title: string
  children?: NestedHierarchyTree[]
}

export const getNestedHierarchyTree = (entityHierarchyConfiguration: EntityHierarchyConfig, EntityHierarchy?: string) => {
  const getItemsWithFlags = (hierarchy: HierarchyDeepStructure) => {
    if (entityHierarchyConfiguration.tenantFlags.find((tenantFlag) => tenantFlag.EntityHierarchy === hierarchy.hierarchyStructure)) {
      //only where we have flags
      const item = getRecursiveTrees(hierarchy)
      if (item) return item
    }
  }

  const getRecursiveTrees = (hierarchy: HierarchyDeepStructure) => {
    const tenantFlag = entityHierarchyConfiguration.tenantFlags.find((tenant) => tenant.EntityHierarchy === hierarchy.hierarchyStructure)
    const children: NestedHierarchyTree[] = []
    for (const prop of hierarchy.children) {
      const item = getItemsWithFlags(prop)
      if (item) children.push(item)
    }
    if (tenantFlag) {
      return {
        key: tenantFlag.EntityHierarchy,
        ...tenantFlag,
        title: getTenantLocationName(tenantFlag),
        children,
      }
    }
  }

  const ResultStructure: NestedHierarchyTree[] = []

  for (const prop of entityHierarchyConfiguration.hierarchy.deepStructure) {
    // todo - filter EntityHierarchy
    if (EntityHierarchy && prop.hierarchyStructure === EntityHierarchy) {
      const item = getItemsWithFlags(prop)
      if (item) ResultStructure.push(item)
    } else if (!EntityHierarchy) {
      const item = getItemsWithFlags(prop)
      if (item) ResultStructure.push(item)
    }
  }
  return ResultStructure
}

export default getNestedHierarchyTree
