import update from 'immutability-helper'
import { createSelector } from 'reselect'
import { ApiKeysDataType } from 'src/typings/kenai/api-keys'
import { RootState } from '../rootReducer'
import { ActionCreator } from '../store-action-creator'

// Action Creators
export const ActionCreators = {
  StoreSetApiKeysData: new ActionCreator<'StoreSetApiKeysData', any[]>('StoreSetApiKeysData'),
  ClearAllStates: new ActionCreator<'ClearAllStates', void>('ClearAllStates'),
}

export type Action = typeof ActionCreators[keyof typeof ActionCreators]

export type State = {
  data: ApiKeysDataType[]
}

export const initialState = (): State => {
  return {
    data: [],
  }
}

// Reducer
export default function reducer(state: State = initialState(), action: Action) {
  switch (action.type) {
    case ActionCreators.StoreSetApiKeysData.type:
      return update(state, {
        data: { $set: action.payload },
      })
    case ActionCreators.ClearAllStates.type:
      return initialState()
    default:
      return state
  }
}

// Selectors
const getApiKeysDataState = (state: RootState) => state.integrations.apiKeys.data
export const getApiKeysData = createSelector<RootState, State['data'], State['data']>([getApiKeysDataState], (state) => state)
