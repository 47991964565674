import { getTableNameFromAwsExports, queryDDBWithBatchLimits } from '../utils'
import { Key } from 'aws-sdk/clients/dynamodb'
import { ConfigValueCustomField } from 'src/typings/kenai/configuration/custom-fields'

export async function retrieveConfigCustomFields(requestedHierarchy: string) {
  const params = (batchHierarchy: string, ExclusiveStartKey?: Key) => ({
    ExpressionAttributeValues: {
      ':hk': batchHierarchy,
      ':deleted': false,
    },
    FilterExpression: 'deleted = :deleted',
    KeyConditionExpression: 'EntityHierarchy = :hk',
    TableName: getTableNameFromAwsExports('mdConfigCustomFields'),
    ExclusiveStartKey,
  })

  return await queryDDBWithBatchLimits<Omit<ConfigValueCustomField, 'fieldType'>>(requestedHierarchy, params)
}
