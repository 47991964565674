import Page from './page'
import PageTitle from './page-title'
import PageContent from './page-content'
import PageSubtitle from './page-subtitle'
import PageLoader from './page-loader'

Page.Title = PageTitle
Page.Subtitle = PageSubtitle
Page.Content = PageContent
Page.Body = PageContent
Page.Loader = PageLoader

export default Page
