import { Skeleton } from 'antd'
import React from 'react'
import Page from '.'
import Toolbar from 'src/components/r-toolbar'

export default function PageLoader() {
  return (
    <Page>
      <Toolbar left={[<Skeleton.Input active={true} style={{ width: '300px' }} key='preload-search' />]} className='mb-20'>
        <Skeleton.Button active={true} key='preload-action' />
      </Toolbar>
      <Skeleton active paragraph={{ rows: 6 }} title={false} />
    </Page>
  )
}
