import evacuation, { State as EvacuationState } from './evacuation'
import { combineReducers } from 'redux'

export interface State {
  evacuation: EvacuationState
}

export default combineReducers<State>({
  evacuation,
})
