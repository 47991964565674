import React from 'react'
import { Button, Space } from 'antd'

const basic = {
  desc: 'There are primary button, default button, dashed button and link button in antd.',
  component: (
    <Space>
      <Button type='primary'>Primary</Button>
      <Button>Default</Button>
      <Button type='dashed'>Dashed</Button>
      <Button type='link'>Link</Button>
    </Space>
  ),
}

export default basic
