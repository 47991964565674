export function getSessionStorage<T>(storageKey): T {
  const storage = sessionStorage.getItem(storageKey)
  if (storage) {
    try {
      return JSON.parse(storage)
    } catch (error) {
      return storage as any
    }
  } else {
    return null as any
  }
}

export function getLocalStorage<T = any>(storageKey): T {
  const storage = sessionStorage.getItem(storageKey)
  if (storage) {
    try {
      return JSON.parse(storage)
    } catch (error) {
      return storage as any
    }
  } else {
    return null as any
  }
}
