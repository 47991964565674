/*eslint no-throw-literal: 0*/
import { cloneDeep } from 'lodash'
import moment from 'moment'
import { all, call, fork, put, select, take, takeEvery, takeLeading } from 'redux-saga/effects'
import API from 'src/api'
import { checkIfTimeExists } from 'src/redux/helpers/functions'
import { ActionCreators as AuthenticationActionCreators } from 'src/redux/sagas/authentication'
import { getEntityHierarchyConfiguration } from 'src/redux/store/credentials'
import { ActionCreators as InvitesActionCreators, getInviteLogData } from 'src/redux/store/visitors/invites'
import AccessManager from 'src/utils/AccessManager'
import ActionCreator from '../saga-action-creator'
import calculateInviteSearchString from './helpers/calculateInviteSearchString'
import resolveInviteCheckInFieldTexts from './helpers/resolveInviteCheckInFieldTexts'
import { InviteLogItem } from 'src/redux/store/visitors/types/invites'
import { upsertDashboardInvites } from 'src/api/inviteOperations/upsertDashboardInvites'

type UpdatePayload<T = Record<string, unknown>> = {
  EntityHierarchy: string
  EventUidEmail: string
} & T

// Action Creators
export const ActionCreators = {
  SagaRetrieveInviteLogData: new ActionCreator<
    'SagaRetrieveInviteLogData',
    { beginTime: number; endTime: number; shouldSubscribeToUpdates?: boolean; readFromArchive?: boolean }
  >('SagaRetrieveInviteLogData'),

  SagaUpdateInternalNote: new ActionCreator<'SagaUpdateInternalNote', UpdatePayload<{ internalNote: string }>[]>('SagaUpdateInternalNote'),
  SagaMarkArrivedInvitee: new ActionCreator<'SagaMarkArrivedInvitee', any>('SagaMarkArrivedInvitee'),
  SagaUnmarkArrivedInvitee: new ActionCreator<'SagaUnmarkArrivedInvitee', any>('SagaUnmarkArrivedInvitee'),
  SagaResendInvites: new ActionCreator<'SagaResendInvites', { EntityHierarchy: string; EventUidEmail: string }[]>('SagaResendInvites'),
  SagaDeleteInvites: new ActionCreator<'SagaDeleteInvites', { EntityHierarchy: string; EventUidEmail: string }[]>('SagaDeleteInvites'),
}

type Action = typeof ActionCreators[keyof typeof ActionCreators]
type ActionType<key extends keyof typeof ActionCreators> = typeof ActionCreators[key]

const AWSHelper = new API.AWSHelpers()

function* processRetrieveInviteLogData(action: Action) {
  try {
    yield put(InvitesActionCreators.StoreSetInviteLogLoading.create(true))
    yield put(InvitesActionCreators.StoreClearAllInviteLogData.create())
    yield put(AuthenticationActionCreators.SagaRefreshApiCredentials.create())
    yield take(AuthenticationActionCreators.SagaApiCredentialsRefreshUpdate.type)
    const entityHierarchyConfiguration = yield select(getEntityHierarchyConfiguration)
    const entityHierarchy = AccessManager.selectedHierarchy.hierarchyStructure
    const beginTime = checkIfTimeExists(action, 'beginTime') ? action.payload.beginTime : moment().startOf('day').valueOf()
    const endTime = checkIfTimeExists(action, 'endTime') ? action.payload.endTime : 9999999999999
    const shouldSubscribeToUpdates = checkIfTimeExists(action, 'shouldSubscribeToUpdates') ? action.payload.shouldSubscribeToUpdates : false
    const getResult = yield call(
      [AWSHelper, AWSHelper.getEventInvitationInstances],
      beginTime,
      endTime,
      entityHierarchy,
      entityHierarchyConfiguration,
      action.payload.readFromArchive
    )
    const invitelogData = getResult.Items
    if (invitelogData) {
      invitelogData.forEach((item) => {
        item.searchString = calculateInviteSearchString(item)
        resolveInviteCheckInFieldTexts(item, entityHierarchyConfiguration)
      })
      yield put(InvitesActionCreators.StoreSetInviteLogShouldSubscribe.create(shouldSubscribeToUpdates))
      yield put(InvitesActionCreators.StoreSetInviteLogData.create(invitelogData))
      yield put(InvitesActionCreators.StoreSetInviteLogLoading.create(false))
      if (action.callback) action.callback({ status: 'success' })
    } else throw getResult
  } catch (e) {
    if (e) console.error(e)
    yield put(InvitesActionCreators.StoreSetInviteLogLoading.create(false))
    if (action.callback) action.callback({ status: 'failed' })
  }
}

function* processSagaUpdateInternalNote(action: ActionType<'SagaUpdateInternalNote'>) {
  try {
    yield put(AuthenticationActionCreators.SagaRefreshApiCredentials.create())
    yield take(AuthenticationActionCreators.SagaApiCredentialsRefreshUpdate.type)
    const entityHierarchyConfiguration = yield select(getEntityHierarchyConfiguration)

    const response: APIResponse<{ visitorRefs: InviteLogItem[] }> = yield call([AWSHelper, upsertDashboardInvites], {
      operation: 'update',
      fields: action.payload,
    })
    if (response.key !== 'OPERATION_PROCESSED') {
      throw new Error(response.key)
    }

    const dataSource: InviteLogItem[] = cloneDeep(yield select(getInviteLogData))
    const updatedLogs = dataSource.map((record) => {
      const updatedRecord = response.visitorRefs.find((item) => item.EventUidEmail === record.EventUidEmail)
      if (updatedRecord) {
        updatedRecord.searchString = calculateInviteSearchString(updatedRecord)
        resolveInviteCheckInFieldTexts(updatedRecord, entityHierarchyConfiguration)
        return updatedRecord
      }

      return record
    })
    yield put(InvitesActionCreators.StoreSetInviteLogData.create(updatedLogs))

    if (action.callback) action.callback({ status: 'success' })
  } catch (e) {
    if (e === 'No credentials') {
      yield put(AuthenticationActionCreators.SagaRefreshApiCredentials.create())
      yield take(AuthenticationActionCreators.SagaApiCredentialsRefreshUpdate.type)
      yield call(processSagaMarkArrivedInvitee, action)
    } else {
      console.log(e)
      if (action.callback) action.callback({ status: 'failed' })
    }
  }
}

function* processSagaMarkArrivedInvitee(action: Action) {
  try {
    yield put(AuthenticationActionCreators.SagaRefreshApiCredentials.create())
    yield take(AuthenticationActionCreators.SagaApiCredentialsRefreshUpdate.type)
    const inviteeData = action.payload
    const dn = Date.now()
    const updateData = yield call([AWSHelper, AWSHelper.markArrivedInvitee], inviteeData, dn)
    if (updateData.key === 'MARK_ARRIVED_SUCCESS') {
      const dataSource: InviteLogItem[] = yield select(getInviteLogData)
      const clonedInviteLogItems = cloneDeep(dataSource)
      const updatedLogs = clonedInviteLogItems.map((record) => {
        if (record.EventUidEmail === inviteeData.EventUidEmail) {
          record.evtTimeStampArrival = updateData.eventTiming.timeSinceEpoch
          record.evtTZOffsetArrival = updateData.eventTiming.timezoneOffset
          record.evtTimeZoneArrival = updateData.eventTiming.timeZone
          record.searchString = calculateInviteSearchString(record)
          record.arrivalSource = 'DASHBOARD'
          record.lmt = dn
        }
        return record
      })
      yield put(InvitesActionCreators.StoreSetInviteLogData.create(updatedLogs))
      if (action.callback) action.callback({ status: 'success' })
    } else {
      throw new Error(updateData.key)
    }
  } catch (e) {
    if (e === 'No credentials') {
      yield put(AuthenticationActionCreators.SagaRefreshApiCredentials.create())
      yield take(AuthenticationActionCreators.SagaApiCredentialsRefreshUpdate.type)
      yield call(processSagaMarkArrivedInvitee, action)
    } else {
      console.log(e)
      if (action.callback) action.callback({ status: 'failed' })
    }
  }
}

function* processSagaUnmarkArrivedInvitee(action: Action) {
  try {
    yield put(AuthenticationActionCreators.SagaRefreshApiCredentials.create())
    yield take(AuthenticationActionCreators.SagaApiCredentialsRefreshUpdate.type)
    const inviteeData = action.payload
    const dn = Date.now()
    const invitelogData = yield call([AWSHelper, AWSHelper.unmarkArrivedInvitee], inviteeData, dn)
    if (invitelogData.key === 'UNMARK_ARRIVED_SUCCESS') {
      const dataSource = yield select(getInviteLogData)
      const clonedInviteLogItems = cloneDeep(dataSource)
      const updatedLogs = clonedInviteLogItems.map((record) => {
        if (record.EventUidEmail === inviteeData.EventUidEmail) {
          delete record['evtTimeStampArrival']
          delete record['evtTZOffsetArrival']
          delete record['evtTimeZoneArrival']
          record.searchString = calculateInviteSearchString(record)
          record.lmt = dn
        }
        return record
      })
      yield put(InvitesActionCreators.StoreSetInviteLogData.create(updatedLogs))
      if (action.callback) action.callback({ status: 'success' })
    } else {
      throw new Error(invitelogData.key)
    }
  } catch (e) {
    if (e === 'No credentials') {
      yield put(AuthenticationActionCreators.SagaRefreshApiCredentials.create())
      yield take(AuthenticationActionCreators.SagaApiCredentialsRefreshUpdate.type)
      yield call(processSagaUnmarkArrivedInvitee, action)
    } else {
      console.log(e)
      if (action.callback) action.callback({ status: 'failed' })
    }
  }
}

function* processSagaResendInvites(action: typeof ActionCreators['SagaResendInvites']) {
  try {
    yield put(AuthenticationActionCreators.SagaRefreshApiCredentials.create())
    yield take(AuthenticationActionCreators.SagaApiCredentialsRefreshUpdate.type)
    const inviteKeys = action?.payload ?? []
    const results = yield all(inviteKeys.map((inviteKey) => call([AWSHelper, AWSHelper.resendInvite], inviteKey)))

    const success = results.filter((result) => result.key === 'OPERATION_PROCESSED')?.length
    const failed = results.filter((result) => result.key !== 'OPERATION_PROCESSED')?.length

    if (success) {
      if (action.callback)
        action.callback({
          status: 'success',
          data: {
            success,
            failed,
          },
        })
    } else {
      throw new Error('Failed to resend all invites')
    }
  } catch (e) {
    if (e === 'No credentials') {
      yield put(AuthenticationActionCreators.SagaRefreshApiCredentials.create())
      yield take(AuthenticationActionCreators.SagaApiCredentialsRefreshUpdate.type)
      yield call(processSagaUnmarkArrivedInvitee, action)
    } else {
      console.log(e)
      if (action.callback) action.callback({ status: 'failed' })
    }
  }
}
function* processSagaDeleteInvites(action: typeof ActionCreators['SagaDeleteInvites']) {
  try {
    yield put(AuthenticationActionCreators.SagaRefreshApiCredentials.create())
    yield take(AuthenticationActionCreators.SagaApiCredentialsRefreshUpdate.type)
    const inviteKeys = action?.payload ?? []
    const results = yield all(inviteKeys.map((inviteKey) => call([AWSHelper, AWSHelper.deleteInvite], inviteKey)))

    const success = results.filter((result) => result.key === 'OPERATION_PROCESSED')
    const failed = results.filter((result) => result.key !== 'OPERATION_PROCESSED')

    if (success.length) {
      const entriesToRemove = inviteKeys.filter((_key, index) => success[index]?.key === 'OPERATION_PROCESSED')
      const dataSource: InviteLogItem[] = cloneDeep(yield select(getInviteLogData))
      const updatedLogs = dataSource.filter((record) => !entriesToRemove.some((search) => {
        return record.EventUidEmail === search.EventUidEmail && record.EntityHierarchy === search.EntityHierarchy
      }))
      yield put(InvitesActionCreators.StoreSetInviteLogData.create(updatedLogs))
      if (action.callback)
        action.callback({
          status: 'success',
          data: {
            success: success.length,
            failed: failed.length,
          },
        })
    } else {
      throw new Error('Failed to delete invites')
    }
  } catch (e) {
    if (e === 'No credentials') {
      yield put(AuthenticationActionCreators.SagaRefreshApiCredentials.create())
      yield take(AuthenticationActionCreators.SagaApiCredentialsRefreshUpdate.type)
      yield call(processSagaUnmarkArrivedInvitee, action)
    } else {
      console.log(e)
      if (action.callback) action.callback({ status: 'failed' })
    }
  }
}

// Saga triggers
function* watchInviteLogSagas() {
  yield takeLeading(ActionCreators.SagaRetrieveInviteLogData.type, processRetrieveInviteLogData)
  yield takeEvery(ActionCreators.SagaUpdateInternalNote.type, processSagaUpdateInternalNote)
  yield takeEvery(ActionCreators.SagaMarkArrivedInvitee.type, processSagaMarkArrivedInvitee)
  yield takeEvery(ActionCreators.SagaUnmarkArrivedInvitee.type, processSagaUnmarkArrivedInvitee)
  yield takeEvery(ActionCreators.SagaResendInvites.type, processSagaResendInvites)
  yield takeEvery(ActionCreators.SagaDeleteInvites.type, processSagaDeleteInvites)
  yield null
}

// Saga hooks
export default function* inviteHostSearchSagas() {
  yield all([fork(watchInviteLogSagas)])
}
