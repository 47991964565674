import React, { DetailedHTMLProps, ThHTMLAttributes } from 'react'
import { Resizable, ResizableProps } from 'react-resizable'

interface ResizableTitleProps extends DetailedHTMLProps<ThHTMLAttributes<HTMLTableHeaderCellElement>, HTMLTableHeaderCellElement> {
  onResize: ResizableProps['onResize']
  width: ResizableProps['width']
  height?: ResizableProps['height']
  resizableProps: Omit<ResizableProps, 'onResize' | 'width'>
}

function ResizableTitle(props: ResizableTitleProps) {
  const { onResize, width = undefined, resizableProps, ...restProps } = props

  if (!width) {
    return <th {...restProps} />
  }

  return (
    <Resizable
      width={width}
      handle={
        <span
          className='react-resizable-handle'
          onClick={(e) => {
            e.stopPropagation()
          }}
        />
      }
      onResize={onResize}
      draggableOpts={{ enableUserSelectHack: false }}
      {...resizableProps}
      height={resizableProps?.height || 0}
    >
      <th {...restProps} />
    </Resizable>
  )
}

export default ResizableTitle
