import moment from 'moment'
import { all, call, fork, put, select, take, takeLeading } from 'redux-saga/effects'
import API from 'src/api'
import { checkIfTimeExists } from 'src/redux/helpers/functions'
// import { ActionCreators as DashboardActionCreators } from 'src/redux/store/dashboard'
import { ActionCreators as AuthenticationActionCreators } from 'src/redux/sagas/authentication'
import { getEntityHierarchyConfiguration } from 'src/redux/store/credentials'
import { ActionCreators as ReportingActionCreators } from 'src/redux/store/reporting'
import AccessManager from 'src/utils/AccessManager'
import ActionCreator from '../saga-action-creator'
import { generateInviteLogSearchString } from './helper/generateInviteSearchString'

// Action Creators
export const ActionCreators = {
  SagaRetrieveUserlogData: new ActionCreator('SagaRetrieveUserlogData'),
  SagaRetrieveInvitelogData: new ActionCreator('[dep]_SagaRetrieveInvitelogData'),
  SagaMarkArrivedInvitee: new ActionCreator('[deprecated]_SagaMarkArrivedInvitee'),
  SagaRevertMarkArrivedInvitee: new ActionCreator('[deprecated]_SagaRevertMarkArrivedInvitee'),
  SagaUnmarkArrivedInvitee: new ActionCreator('[deprecated]_SagaUnmarkArrivedInvitee'),
}

const AWSHelper = new API.AWSHelpers()

function* processRetrieveUserlogData(action) {
  try {
    yield put(ReportingActionCreators.StoreClearAllReportingData.create())
    yield put(AuthenticationActionCreators.SagaRefreshApiCredentials.create())
    yield take(AuthenticationActionCreators.SagaApiCredentialsRefreshUpdate.type)
    const entityHierarchy = AccessManager.selectedHierarchy.hierarchyStructure
    let beginTime = 0
    let endTime = 9999999999999
    const userlogData = yield call([AWSHelper, AWSHelper.getUserlogEvents], beginTime, endTime, entityHierarchy)
    let userlogItems = []
    userlogData.map((item, index) => {
      var momentRef = moment(new Date(parseInt(`${item.evtTimeStamp}000`, 10)))
      return userlogItems.push({
        key: index,
        date: momentRef.format('YYYY/MM/DD'),
        time: momentRef.format('HH:mm'),
        email: item.userEmail,
        terminalid: item.userTerminalId,
      })
    })
    userlogItems.sort((a, b) => {
      const aMoment = moment(`${a.date} ${a.time}`, 'YYYY-MM-DD HH:mm')
      const bMoment = moment(`${b.date} ${b.time}`, 'YYYY-MM-DD HH:mm')
      if (aMoment.isAfter(bMoment)) {
        return -1
      } else {
        return 1
      }
    })
    yield put(ReportingActionCreators.StoreSetUserlogData.create(userlogItems))
    if (action.callback) action.callback({ status: 'success' })
  } catch (e) {
    if (e) {
      console.error(e)
    }
    if (action.callback) action.callback({ status: 'failed' })
  }
}

function* processRetrieveInvitelogData(action) {
  try {
    yield put(ReportingActionCreators.StoreClearAllReportingData.create())
    yield put(AuthenticationActionCreators.SagaRefreshApiCredentials.create())
    yield take(AuthenticationActionCreators.SagaApiCredentialsRefreshUpdate.type)
    const entityHierarchyConfiguration = yield select(getEntityHierarchyConfiguration)
    const entityHierarchy = AccessManager.selectedHierarchy.hierarchyStructure
    let beginTime = checkIfTimeExists(action, 'beginTime') ? action.payload.beginTime : moment().startOf('day').valueOf()
    let endTime = checkIfTimeExists(action, 'endTime') ? action.payload.endTime : 9999999999999

    const getResult = yield call(
      [AWSHelper, AWSHelper.getEventInvitationInstances],
      beginTime,
      endTime,
      entityHierarchy,
      entityHierarchyConfiguration
    )

    const invitelogData = getResult.Items
    if (invitelogData) {
      generateInviteLogSearchString(invitelogData)
      yield put(ReportingActionCreators.StoreSetInvitelogData.create(invitelogData))
      if (action.callback) action.callback({ status: 'success' })
    } else {
      throw getResult
    }
  } catch (e) {
    if (e) {
      console.error(e)
    }
    if (action.callback) action.callback({ status: 'failed' })
  }
}

function* processSagaMarkArrivedInvitee(action) {
  try {
    yield put(
      ReportingActionCreators.UpdateLogStates.create({
        logType: 'invitelog',
        state: 'arrival',
        loading: true,
        result: null,
      })
    )
    yield put(AuthenticationActionCreators.SagaRefreshApiCredentials.create())
    yield take(AuthenticationActionCreators.SagaApiCredentialsRefreshUpdate.type)

    const inviteeData = action.payload
    const dn = Date.now()
    const invitelogData = yield call([AWSHelper, AWSHelper.markArrivedInvitee], inviteeData, dn)

    if (invitelogData.key === 'MARK_ARRIVED_SUCCESS') {
      yield put(
        ReportingActionCreators.UpdateLogStates.create({
          logType: 'invitelog',
          state: 'arrival',
          loading: false,
          result: 'success',
        })
      )
      yield put(
        ReportingActionCreators.UpdateInviteMarkArrivedlogData.create({
          ...inviteeData,
          arrivalSource: 'DASHBOARD',
          lmt: dn,
          evtTimeStampArrival: invitelogData.eventTiming.timeSinceEpoch,
          evtTZOffsetArrival: invitelogData.eventTiming.timezoneOffset,
          evtTimeZoneArrival: invitelogData.eventTiming.timeZone,
        })
      )
      if (action.callback) action.callback({ status: 'success' })
    } else {
      throw new Error(invitelogData.key)
    }
    if (action.callback) action.callback({ status: 'success' })
  } catch (e) {
    if (e === 'No credentials') {
      yield put(AuthenticationActionCreators.SagaRefreshApiCredentials.create())
      yield take(AuthenticationActionCreators.SagaApiCredentialsRefreshUpdate.type)
      yield call(processSagaMarkArrivedInvitee, action)
    } else {
      yield put(
        ReportingActionCreators.UpdateLogStates.create({
          logType: 'invitelog',
          state: 'arrival',
          loading: false,
          result: 'failed',
        })
      )
      console.log(e)
      if (action.callback) action.callback({ status: 'failed' })
    }
    if (action.callback) action.callback({ status: 'failed' })
  }
}

function* processSagaUnmarkArrivedInvitee(action) {
  try {
    yield put(
      ReportingActionCreators.UpdateLogStates.create({
        logType: 'invitelog',
        state: 'arrivalrevert',
        loading: true,
        result: null,
      })
    )
    yield put(AuthenticationActionCreators.SagaRefreshApiCredentials.create())
    yield take(AuthenticationActionCreators.SagaApiCredentialsRefreshUpdate.type)

    const inviteeData = action.payload
    const dn = Date.now()
    const invitelogData = yield call([AWSHelper, AWSHelper.unmarkArrivedInvitee], inviteeData, dn)
    if (invitelogData.key === 'UNMARK_ARRIVED_SUCCESS') {
      yield put(
        ReportingActionCreators.UpdateLogStates.create({
          logType: 'invitelog',
          state: 'arrivalrevert',
          loading: false,
          result: 'success',
        })
      )

      yield put(
        ReportingActionCreators.UpdateInviteUnmarkArrivedlogData.create({
          ...inviteeData,
          lmt: dn,
        })
      )
      if (action.callback) action.callback({ status: 'success' })
    } else {
      throw new Error(invitelogData.key)
    }
    if (action.callback) action.callback({ status: 'success' })
  } catch (e) {
    if (e === 'No credentials') {
      yield put(AuthenticationActionCreators.SagaRefreshApiCredentials.create())
      yield take(AuthenticationActionCreators.SagaApiCredentialsRefreshUpdate.type)
      yield call(processSagaUnmarkArrivedInvitee, action)
    } else {
      yield put(
        ReportingActionCreators.UpdateLogStates.create({
          logType: 'invitelog',
          state: 'arrivalrevert',
          loading: false,
          result: 'failed',
        })
      )
      console.log(e)
      if (action.callback) action.callback({ status: 'failed' })
    }
    if (action.callback) action.callback({ status: 'failed' })
  }
}

// Saga triggers
function* watchReportingSagas() {
  yield takeLeading(ActionCreators.SagaRetrieveUserlogData.type, processRetrieveUserlogData)
  yield takeLeading(ActionCreators.SagaRetrieveInvitelogData.type, processRetrieveInvitelogData)
  yield takeLeading(ActionCreators.SagaMarkArrivedInvitee.type, processSagaMarkArrivedInvitee)
  yield takeLeading(ActionCreators.SagaUnmarkArrivedInvitee.type, processSagaUnmarkArrivedInvitee)

  yield null
}

// Saga hooks
export default function* reportingSagas() {
  yield all([fork(watchReportingSagas)])
}
