import { combineReducers } from 'redux'
import directoryReducer, { State as directoryState } from './directory-store'
import employeeLogReducer, { State as employeeLogState } from './employee-log-store'
import screeningLogReducer, { State as screeningLogState } from './screening-log-store'
import dafReducer, { State as dafState } from './daf-store'

export interface State {
  directory: directoryState
  employeeLog: employeeLogState
  screeningLog: screeningLogState
  daf: dafState
}

export default combineReducers<State>({
  directory: directoryReducer,
  employeeLog: employeeLogReducer,
  screeningLog: screeningLogReducer,
  daf: dafReducer,
})
