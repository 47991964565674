import React, { useEffect } from 'react'
import { Select } from 'antd'
import Cookies from 'js-cookie'
import config from 'src/config'

// A selector component which allows you to select the environment you want to use.
// The selector will be rendered in the bottom right corner of the screen.

const useOptions = () => {
  const [options, setOptions] = React.useState<string[]>([])
  const [selectedOption, setSelectedOption] = React.useState<string | undefined>(undefined)
  useEffect(() => {
    const storedEnvironment = Cookies.get(cookie) ?? 'dashboard-stg-default'

    fetch(`https://envdiscovery.kenai.co.za/api/dashboard-stg`)
      .then((res) => res.json())
      .then((data) => {
        setOptions(data)
        if (data.includes(storedEnvironment)) {
          console
          setSelectedOption(storedEnvironment)
        }
      })
  }, [])

  return { options, selectedOption }
}

const cookie = 'kenai-dashboard-env'

const Selector = React.memo(() => {
  const { options, selectedOption } = useOptions()

  const handleSetEnv = (env) => {
    if (env === 'clear') {
      Cookies.set(cookie, undefined)
    } else {
      Cookies.set(cookie, env)
    }
    window.location.reload()
  }

  return (
    <div id='environment-selector' className='absolute bottom-0 right-0 opacity-5 hover:opacity-100' style={{ zIndex: 1000 - 1 }}>
      <Select<string>
        onChange={handleSetEnv}
        className='w-64'
        placeholder='Select Environment'
        dropdownStyle={{ zIndex: 9999999 }}
        value={selectedOption}
      >
        <Select.Option value='clear'>Reset environment</Select.Option>
        {options.map((option) => (
          <Select.Option key={option} value={option}>
            <span className='opacity-40'>dashboard-stg-</span>
            {option.replace('dashboard-stg-', '')}
          </Select.Option>
        ))}
      </Select>
    </div>
  )
})

export const EnvironmentSelector = () => {
  if (process.env.NODE_ENV === 'development' || config.staging) {
    return <Selector />
  } else {
    return null
  }
}

EnvironmentSelector.cookie = cookie
