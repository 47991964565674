import { EntityHierarchyConfig } from 'src/typings/kenai/configuration/entity-hierarchy'

/**
 * Returns a list of all the hierarchies that are available for the given EntityHierarchy
 *
 * So for example if the EntityHierarchy is `'ALL'` then it will return all the hierarchies that are available for the tenant
 *
 * If the EntityHierarchy is `'1000005'` then it will return:
 * ```
 * ['100005', '1000005#2000001', `1000005#2000001#2000003`, '1000005#2000003', '1000005#2000004']
 * ```
 *
 * If the EntityHierarchy is `'1000005#2000001'` then it will return
 * ```
 * ['1000005#2000001', `1000005#2000001#2000003`]
 * ```
 *
 */
export function getRecursiveHierarchyList(ehConfig: EntityHierarchyConfig, EntityHierarchy: string) {
  let hierarchies: string[] = []
  if (EntityHierarchy === 'ALL' && ehConfig && ehConfig.tenantFlags) {
    hierarchies = ehConfig.tenantFlags.map((availableEntity) => availableEntity.EntityHierarchy)
  } else if (ehConfig && ehConfig.hierarchy.flatStructure[EntityHierarchy].children.length > 0) {
    const addRecursive = (additionalChildren) => {
      for (const prop of additionalChildren) {
        hierarchies.push(prop.hierarchyStructure)
        if (prop.children.length > 0) {
          addRecursive(prop.children)
        }
      }
    }
    hierarchies.push(EntityHierarchy)
    addRecursive(ehConfig.hierarchy.flatStructure[EntityHierarchy].children)
  } else {
    hierarchies.push(EntityHierarchy)
  }

  return hierarchies
}
