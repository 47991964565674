import { useIsAuthenticated, useMsal } from '@azure/msal-react'
import { Modal } from 'antd'
import { push } from 'connected-react-router'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Route, Switch } from 'react-router-dom'
import { ActionCreators as CredentialsActionCreators } from 'src/redux/store/credentials'
import uuid from 'uuid'
// components
import MaintenancePage from './components/presentational/MaintenancePage'
import { Title } from './components/presentational/NoEntries/noentries.style'
import config from './config'
// containers
import AuthenticationContainer from './containers/Authentication'
import Dashboard from './containers/Dashboard'
import Development from './containers/Development'
import { useAuth } from './hooks/use-auth'
import { useRouter } from './hooks/use-router'
import { ActionCreators as AuthenticationSagaActionCreators } from './redux/sagas/authentication'
import { getAuthCredentials } from './redux/store/credentials'
import CONSTANTS from './utils/constants'
import { getNextLocationURL } from './utils/getNextLocationURL'
import verifyTokenSync from './utils/token'

const AppRouter = (props) => {
  const isAADB2CAuthenticated = useIsAuthenticated()
  const auth = useAuth()
  const { instance, inProgress } = useMsal()
  const authCredentials = useSelector(getAuthCredentials)
  // const entityHierarchyConfiguration = useSelector(getEntityHierarchyConfiguration, shallowEqual)
  const [isFirstRenderComplete, setIsFirstRenderComplete] = useState(false)
  const dispatch = useRef(useDispatch())
  const { location } = useRouter()

  const isAuthenticationPath = location?.pathname?.includes('/authenticate')

  useEffect(() => {
    if (isFirstRenderComplete) {
      if (CONSTANTS.AUTH_PROVIDER === 'AUTH0') {
        const tokenDetails = verifyTokenSync(authCredentials)
        if (!tokenDetails.isValid) {
          if (auth.isAuthenticated) {
            auth.setAuthenticatedState(false)
          }
          if (isAuthenticationPath) {
            dispatch.current(push(getNextLocationURL('/authenticate'))) //go to authentication container - it will deal with sign out
          }
        } else {
          if (!auth.isAuthenticated) {
            auth.setAuthenticatedState(true)
          }
          if (isAuthenticationPath) {
            dispatch.current(push(window['KENAIFramePath'] ? window['KENAIFramePath'] : '/')) //switch to frame path or authenticated route handling in dashboard container
          }
        }
      } else if (CONSTANTS.AUTH_PROVIDER === 'AADB2C') {
        const tokenDetails = verifyTokenSync(authCredentials)
        if (!tokenDetails.isValid) {
          if (inProgress === 'none') {
            //we only want to do something once MSAL is doing nothing e.g. what ever flows might have been happening are complete
            if (!auth.isAuthenticated && isAADB2CAuthenticated) {
              auth.setAuthenticatedState(isAADB2CAuthenticated)
              const account = instance.getAllAccounts()[0]
              instance
                .acquireTokenSilent({
                  scopes: [CONSTANTS.AADB2CAUTH.SCOPE_ACCESS],
                  account,
                  forceRefresh: true,
                })
                .then((result) => {
                  const nonce = uuid.v4().replace(/-/g, '')
                  const opts = {
                    grant_type: 'password',
                    username: result.idTokenClaims['sub'],
                    password: result.accessToken,
                    client_id: CONSTANTS.AADB2CAUTH.CLIENTID_AUTHZ,
                    scope: `${CONSTANTS.AADB2CAUTH.SCOPE_AUTHZ}`,
                    response_type: 'token',
                    nonce,
                  }
                  //for consistency across repos use manual form param build approach vs browser urlparams obj
                  const formBody: any = []
                  for (const property in opts) {
                    const encodedKey = encodeURIComponent(property)
                    const encodedValue = encodeURIComponent(opts[property])
                    formBody.push(encodedKey + '=' + encodedValue)
                  }
                  const request = new Request(
                    `https://${CONSTANTS.AADB2CAUTH.DOMAIN}/${CONSTANTS.AADB2CAUTH.TENANT}/${CONSTANTS.AADB2CAUTH.POLICY_AUTHZ}/oauth2/v2.0/token`,
                    {
                      method: 'POST',
                      headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                      },
                      body: formBody.join('&'),
                    }
                  )
                  fetch(request)
                    .then(function (response) {
                      return response.json()
                    })
                    .then((json) => {
                      if (json?.access_token) {
                        const atClaims = JSON.parse(atob(json.access_token.split('.')[1]))
                        const knaAuthorizations = JSON.parse(atob(atClaims.kna_Authorizations))
                        if (!atClaims.sub.includes('kenai.co.za')) {
                          dispatch.current({
                            type: AuthenticationSagaActionCreators.SagaLogUserlogin.type,
                            payload: {
                              sub: atClaims.sub,
                              email: atClaims.sub,
                              amOrganizationId: atClaims.kna_OrganizationId,
                              amMTHA: knaAuthorizations.amMTHA,
                            },
                          })
                        }
                        const localDashboard = localStorage.getItem('dashboard')
                        const _dashboard = localDashboard && localDashboard !== null ? JSON.parse(localDashboard) : {}
                        const authCheck = atClaims && atClaims.sub ? true : false
                        if (authCheck) {
                          if (_dashboard.users) {
                            // User settings present,
                            if (!_dashboard.users[atClaims.sub]) {
                              // Matching user settings not present
                              _dashboard.users = {
                                ..._dashboard.users,
                                [atClaims.sub]: {},
                              }
                              localStorage.setItem('dashboard', JSON.stringify(_dashboard))
                            }
                          } else {
                            // User Settings not present so create
                            _dashboard.users = {
                              ..._dashboard.users,
                              [atClaims.sub]: {},
                            }
                            localStorage.setItem('dashboard', JSON.stringify(_dashboard))
                          }
                        }
                        dispatch.current({
                          type: CredentialsActionCreators.StoreSetAuthCredentials.type,
                          payload: {
                            idToken: json.access_token, //we use at but in legacy kenai it was in idToken prop - keep it there
                            idTokenPayload: atClaims,
                            nonce: nonce,
                          },
                        })
                        dispatch.current({
                          type: AuthenticationSagaActionCreators.SagaRefreshApiCredentials.type,
                        }) //authenticated - trigger API credential refresh
                      } else {
                        throw json
                      }
                    })
                    .catch((err) => {
                      console.error(err)
                      Modal.destroyAll()
                      if (err?.errorCode === 'invalid_grant' || err?.errorCode === 'silent_sso_error') {
                        localStorage.clear()
                        window.location.reload()
                      } else {
                        Modal.confirm({
                          title: <Title className='my-0'>Error</Title>,
                          content: 'There was an error while trying to sign you in. Would you like to retry?',
                          maskClosable: false,
                          onOk: () => {
                            localStorage.clear()
                            window.location.reload()
                          },
                          okText: 'Retry',
                          cancelButtonProps: { style: { display: 'none' } },
                          centered: true,
                        })
                      }
                    })
                })
                .catch((e) => {
                  console.error(e)
                  Modal.destroyAll()
                  if (e?.errorCode === 'invalid_grant' || e?.errorCode === 'silent_sso_error') {
                    localStorage.clear()
                    window.location.reload()
                  } else {
                    Modal.confirm({
                      title: <Title className='my-0'>Error</Title>,
                      content: 'There was an error while trying to sign you in. Would you like to retry?',
                      maskClosable: false,
                      onOk: () => {
                        localStorage.clear()
                        window.location.reload()
                      },
                      okText: 'Retry',
                      cancelButtonProps: { style: { display: 'none' } },
                      centered: true,
                    })
                  }
                })
            } else {
              auth.setAuthenticatedState(isAADB2CAuthenticated)
            }
          }
        } else {
          if (!auth.isAuthenticated) {
            auth.setAuthenticatedState(true)
          }
          dispatch.current({
            type: AuthenticationSagaActionCreators.SagaRefreshApiCredentials.type,
          }) //authenticated - trigger API credential refresh
          if (isAuthenticationPath) {
            dispatch.current(push(window['KENAIFramePath'] ? window['KENAIFramePath'] : '/')) //switch to frame path or authenticated route handling in dashboard container
          }
        }
      }
    }
  }, [isFirstRenderComplete, isAADB2CAuthenticated, inProgress, authCredentials, auth, instance, isAuthenticationPath])
  /**
  First run is for handling a case where we page refresh and have authCredentials already e.g. above hook would not run in this case.
  Also to note this runs even on an unauthenticated page load e.g. initial load -
  hence the route pushes are slightly different to the effect above as they deal with different input auth states
  */
  useEffect(() => {
    if (!isFirstRenderComplete) {
      setIsFirstRenderComplete(true)
      if (CONSTANTS.AUTH_PROVIDER === 'AUTH0') {
        const tokenDetails = verifyTokenSync(authCredentials)
        if (!tokenDetails.isValid) {
          if (auth.isAuthenticated) {
            auth.setAuthenticatedState(false)
          }
          if (!isAuthenticationPath) {
            dispatch.current(push(getNextLocationURL('/authenticate'))) //not authenticated = change location to authentication container
          }
        } else {
          if (!auth.isAuthenticated) {
            auth.setAuthenticatedState(true)
            dispatch.current({ type: AuthenticationSagaActionCreators.SagaRefreshApiCredentials.type }) //authenticated - trigger API credential refresh
          }
        }
      } else if (CONSTANTS.AUTH_PROVIDER === 'AADB2C') {
        const tokenDetails = verifyTokenSync(authCredentials)
        if (!tokenDetails.isValid) {
          if (auth.isAuthenticated) {
            auth.setAuthenticatedState(false)
          }
          if (!isAuthenticationPath) {
            dispatch.current(push(getNextLocationURL('/authenticate'))) //not authenticated = change location to authentication container
          }
        } else {
          //Don't handle - this will be handled in previous effect
          // if (!auth.isAuthenticated) {
          //   auth.setAuthenticatedState(true)
          //   dispatch.current({ type: AuthenticationSagaActionCreators.SagaRefreshApiCredentials.type }) //authenticated - trigger API credential refresh
          // }
        }
      }
    }
  }, [isFirstRenderComplete, auth, authCredentials, isAuthenticationPath])

  if (location.pathname.includes('/components')) {
    return <Development />
  }

  if (config.maintenance && !window.location.search.includes('?dev=true')) {
    return <MaintenancePage />
  }

  return (
    <Switch location={location}>
      {auth.isAuthenticated ? (
        <Route exact path='*' component={Dashboard} {...props} />
      ) : (
        <Route exact path='/authenticate' component={AuthenticationContainer} />
      )}
    </Switch>
  )
}
export default AppRouter
