import { ActionCreator } from '../store-action-creator'

import { RootState } from '../rootReducer'
import { createSelector } from 'reselect'

// Action Creators
export const ActionCreators = {
  StoreSetProfileValues: new ActionCreator<'StoreSetProfileValues', AccountProfile>('StoreSetProfileValues'),
  StoreClearAllProfileData: new ActionCreator<'StoreClearAllProfileData', Partial<void>>('StoreClearAllProfileData'),
  ClearAllStates: new ActionCreator<'ClearAllStates', Partial<void>>('ClearAllStates'),
}

export type Action = typeof ActionCreators[keyof typeof ActionCreators]

export type State = AccountProfile

export const initialState = (): State => ({
  umEmail: '',
  umName: '',
  amTimeAdmin: false,
  amApiAdminForTypes: '',
  amAnalyticsEnabled: false,
  amHostMaintenanceEnabled: false,
  amInductionAdmin: false,
  amOrganizationId: '',
  amEvacAdmin: false,
  amDBFullDateAccess: false,
  amEmployeeMaintenanceEnabled: false,
  amEmployeeTagsNoTagAccess: false,
  amEmployeeTagsAuthorisedTags: '',
  amPreScreeningAdmin: false,
  amPreScreeningSuperAdmin: false,
  amNotificationsAdmin: false,
  amResourceAdmin: false,
  amDisableGlobalExport: false,
  amDisableVisitorModule: false,
  amDisableVisitorExport: false,
  amDisableEmployeeModule: false,
  amDisableEmployeeExport: false,
  amDisableParkingModule: false,
  amDisableParkingExport: false,
  amDisableInductionModule: false,
  amDisableInductionExport: false,

  // PRE-MIGRATION START
  amDisableDAFModule: false,
  amDisableDAFLog: false,

  amBlocklistMaintenanceEnabled: false,
  // PRE-MIGRATION END
})

// Reducer
export default function reducer(state: State = initialState(), action: Action) {
  switch (action.type) {
    case ActionCreators.StoreSetProfileValues.type:
      return action.payload
    case ActionCreators.StoreClearAllProfileData.type || ActionCreators.ClearAllStates.type:
      return initialState()
    default:
      return state
  }
}

// Selectors
const getUserProfileState = (state: RootState) => state.profile
export const getUserProfile = createSelector<RootState, any, State>([getUserProfileState], (state: State) => {
  return state
})
