import { isArray, uniq } from 'lodash'
import getIntl from 'src/i18n'
import type { EmployeeDetails } from 'src/redux/store/employees/types/directory'
import { checkValidEmail as checkValidEmailFormat, checkValidIntPhone } from 'src/utils/validation'
import parseSheet from 'src/utils/parse-sheet'
import { UploadProps } from 'antd'

const intl = getIntl()

type ImportedEmployeeData = Pick<
  EmployeeDetails,
  | 'firstName'
  | 'lastName'
  | 'personalIdentificationNr'
  | 'alternatePersonalIdNr'
  | 'organizationIdValue'
  | 'email'
  | 'phoneNumber'
  | 'lineManagers'
  | 'tags'
  | 'flowTypeRef'
>

function extractEmails(text) {
  // match 99.99% of emails see: https://emailregex.com/ (scroll down to Javascript)
  return text.match(
    /(?:(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,})))/gi // eslint-disable-line
  )
}

export function parseSheetLineManagers(value?: string | string[]) {
  if (!value) return undefined
  if (isArray(value)) return value
  return extractEmails(value)
}
function parseSheetTags(value?: string | string[]) {
  if (!value) return undefined
  if (isArray(value)) return value
  return value.split(/[|,;]+/)
}

function checkIsUniqueTags(values: string[]) {
  const hasDuplication = values.some((entry, i) => {
    if (values.indexOf(entry) !== i) return true
    return false
  })
  return !hasDuplication
}

function checkValidEmailTags(values: string[]) {
  const hasFaultyEmail = values.some((entry, i) => {
    if (values.indexOf(entry) !== i) return true
    if (!checkValidEmailFormat(entry)) return true
    return false
  })
  return !hasFaultyEmail
}

function checkValidEntry(
  entry: ImportedEmployeeData,
  validData: ValidEntry[],
  duplicateMetaFieldsAllowed: boolean,
  employeeAlternatePersonalIdNr: boolean
) {
  if (!entry) {
    return false
  }

  if (
    !entry.firstName ||
    !entry.lastName ||
    !entry.personalIdentificationNr ||
    (employeeAlternatePersonalIdNr ? !entry?.alternatePersonalIdNr : false) ||
    !entry.organizationIdValue
  ) {
    return false // missing entry key
  }
  if (entry.email && !checkValidEmailFormat(entry.email)) return false
  if (entry.phoneNumber && !checkValidIntPhone(entry.phoneNumber)) return false
  if (entry.lineManagers && !checkValidEmailTags(entry.lineManagers)) return false
  if (entry.tags && !checkIsUniqueTags(entry.tags)) return false
  if (entry.personalIdentificationNr.length === 0) return false
  if (
    employeeAlternatePersonalIdNr &&
    ((entry.alternatePersonalIdNr && entry.alternatePersonalIdNr.length === 0) || !entry.alternatePersonalIdNr)
  )
    return false
  if (entry.organizationIdValue?.length === 0) return false
  if (
    validData.findIndex(
      (item) =>
        item.data.personalIdentificationNr === entry.personalIdentificationNr ||
        item.data.organizationIdValue === entry.organizationIdValue ||
        (employeeAlternatePersonalIdNr && item.data.alternatePersonalIdNr === entry.alternatePersonalIdNr) ||
        (!duplicateMetaFieldsAllowed && item.data.phoneNumber && item.data.phoneNumber === entry.phoneNumber) ||
        (!duplicateMetaFieldsAllowed && item.data.email && item.data.email === entry.email)
    ) > -1
  ) {
    return false
  }
  return true
}

interface ValidEntry {
  update: false | ImportedEmployeeData
  data: ImportedEmployeeData
}

interface InvalidEntry {
  fixed?: boolean
  errors: { [key in keyof ImportedEmployeeData]?: string[] }
  data: ImportedEmployeeData
}

export interface EmployeeImportResponse {
  error?: string
  validData: ValidEntry[]
  invalidData: InvalidEntry[]
}

function parseData(sheet: ImportedEmployeeData[], currentData: EmployeeDetails[], allowDupMetaFields: boolean, employeeAltPID: boolean) {
  const validData: EmployeeImportResponse['validData'] = []
  const invalidData: EmployeeImportResponse['invalidData'] = []

  sheet.forEach((entry: ImportedEmployeeData) => {
    entry.personalIdentificationNr = entry.personalIdentificationNr ? `${entry.personalIdentificationNr}`.toLocaleUpperCase() : ''
    if (employeeAltPID) {
      entry.alternatePersonalIdNr = entry.alternatePersonalIdNr ? `${entry.alternatePersonalIdNr}`.toLocaleUpperCase() : ''
    }
    entry.organizationIdValue = entry.organizationIdValue ? `${entry.organizationIdValue}`.toLocaleUpperCase() : ''
    entry.phoneNumber = entry.phoneNumber
      ? `${entry.phoneNumber}`.indexOf('+') === 0
        ? `${entry.phoneNumber}`
        : `+${entry.phoneNumber}`
      : ''
    entry.lineManagers = uniq(parseSheetLineManagers(entry.lineManagers)?.map((entry) => entry.toLowerCase()))
    entry.tags = uniq(parseSheetTags(entry.tags)?.map((entry) => entry.toUpperCase()))
    entry.email = entry.email?.toLowerCase().replace(/\s/g, '')

    if (!checkValidEntry(entry, validData, allowDupMetaFields, employeeAltPID)) {
      const errorEntry: InvalidEntry = {
        errors: {},
        data: entry,
      }

      if (!entry.firstName) errorEntry.errors.firstName = [intl.formatMessage({ id: 'validations.first-name.required' })]
      if (!entry.lastName) errorEntry.errors.lastName = [intl.formatMessage({ id: 'validations.last-name.required' })]
      if (!entry.personalIdentificationNr) {
        errorEntry.errors.personalIdentificationNr = [
          intl.formatMessage({ id: 'validations.field.required' }, { field: intl.formatMessage({ id: 'personIDValue' }) }),
        ]
      }
      if (employeeAltPID) {
        if (!entry.alternatePersonalIdNr) {
          errorEntry.errors.alternatePersonalIdNr = [
            intl.formatMessage({ id: 'validations.field.required' }, { field: intl.formatMessage({ id: 'alternatePersonalIdNr' }) }),
          ]
        }
      }
      if (!entry.organizationIdValue) {
        errorEntry.errors.organizationIdValue = [
          intl.formatMessage({ id: 'validations.field.required' }, { field: intl.formatMessage({ id: 'organizationIdValue' }) }),
        ]
      }
      if (entry.phoneNumber && !checkValidIntPhone(entry.phoneNumber)) {
        errorEntry.errors.phoneNumber = [intl.formatMessage({ id: 'validations.phone.invalid' })]
      }
      if (entry.email && !checkValidEmailFormat(entry.email)) {
        errorEntry.errors.email = [intl.formatMessage({ id: 'validations.email.invalid' })]
      }
      if (validData.findIndex((item) => item.data.personalIdentificationNr === entry.personalIdentificationNr) > -1) {
        if (errorEntry.errors.personalIdentificationNr) {
          errorEntry.errors.personalIdentificationNr.push(
            intl.formatMessage({ id: 'validations.field.duplicate' }, { field: intl.formatMessage({ id: 'personIDValue' }) })
          )
        } else {
          errorEntry.errors.personalIdentificationNr = [
            intl.formatMessage({ id: 'validations.field.duplicate' }, { field: intl.formatMessage({ id: 'personIDValue' }) }),
          ]
        }
      }
      if (employeeAltPID) {
        if (validData.findIndex((item) => item.data.alternatePersonalIdNr === entry.alternatePersonalIdNr) > -1) {
          if (errorEntry.errors.alternatePersonalIdNr) {
            errorEntry.errors.alternatePersonalIdNr.push(
              intl.formatMessage({ id: 'validations.field.duplicate' }, { field: intl.formatMessage({ id: 'alternatePersonalIdNr' }) })
            )
          } else {
            errorEntry.errors.alternatePersonalIdNr = [
              intl.formatMessage({ id: 'validations.field.duplicate' }, { field: intl.formatMessage({ id: 'alternatePersonalIdNr' }) }),
            ]
          }
        }
      }
      if (validData.findIndex((item) => item.data.organizationIdValue === entry.organizationIdValue) > -1) {
        if (errorEntry.errors.organizationIdValue) {
          errorEntry.errors.organizationIdValue.push(
            intl.formatMessage({ id: 'validations.field.duplicate' }, { field: intl.formatMessage({ id: 'organizationIdValue' }) })
          )
        } else {
          errorEntry.errors.organizationIdValue = [
            intl.formatMessage({ id: 'validations.field.duplicate' }, { field: intl.formatMessage({ id: 'organizationIdValue' }) }),
          ]
        }
      }

      if (entry.lineManagers?.length && !checkValidEmailTags(entry.lineManagers)) {
        if (!checkValidEmailTags(entry.lineManagers)) {
          entry.lineManagers.forEach((val, i) => {
            if (entry.lineManagers && entry.lineManagers.indexOf(val) !== i) {
              errorEntry.errors.lineManagers?.push(`The email (${val}) has been duplicated.`)
            }
            if (!checkValidEmailFormat(val)) {
              errorEntry.errors.lineManagers?.push(`The email (${val}) is not a valid format.`)
            }
          })
        }
      }

      if (entry.tags?.length && !checkIsUniqueTags(entry.tags)) {
        if (!checkIsUniqueTags(entry.tags)) {
          entry.tags.forEach((val, i) => {
            if (entry.tags && entry.tags.indexOf(val) !== i) {
              errorEntry.errors.tags?.push(`The tag (${val}) has been duplicated.`)
            }
          })
        }
      }

      if (!allowDupMetaFields) {
        if (validData.findIndex((item) => item.data.phoneNumber && item.data.phoneNumber === entry.phoneNumber) > -1) {
          if (errorEntry.errors.phoneNumber) {
            errorEntry.errors.phoneNumber.push(intl.formatMessage({ id: 'validations.phone.duplicate' }))
          } else {
            errorEntry.errors.phoneNumber = [intl.formatMessage({ id: 'validations.phone.duplicate' })]
          }
        }
        if (validData.findIndex((item) => item.data.email && item.data.email?.toLowerCase?.() === entry.email?.toLowerCase?.()) > -1) {
          if (errorEntry.errors.email) {
            errorEntry.errors.email.push(intl.formatMessage({ id: 'validations.email.duplicate' }))
          } else {
            errorEntry.errors.email = [intl.formatMessage({ id: 'validations.email.duplicate' })]
          }
        }
      }
      invalidData.push(errorEntry)
    } else {
      const updateEntry = (function getExistingEntry(pid: string) {
        const existingEntry = currentData.filter(({ personalIdentificationNr }) => personalIdentificationNr === pid)
        if (existingEntry.length > 0) return existingEntry[0]
        return false
      })(entry.personalIdentificationNr)

      validData.push({
        update: updateEntry,
        data: {
          ...entry,
          ...(updateEntry ? { ProfileId: updateEntry.ProfileId } : {}),
        },
      })
    }
  })

  return {
    validData,
    invalidData,
  }
}

export async function parseImport(
  file: Parameters<UploadProps['customRequest']>[0]['file'],
  currentData: EmployeeDetails[],
  dupMetaAllowed: boolean,
  altEmployeeNumb: boolean
) {
  try {
    const sheet = await parseSheet<ImportedEmployeeData>(file, 'EmployeeSheet')
    if (sheet?.[0]) {
      const { validData, invalidData } = parseData(sheet, currentData, dupMetaAllowed, altEmployeeNumb)

      const error = (function getError() {
        return validData.length === 0 && invalidData.length === 0
          ? 'We could not seem to parse any data inside the imported sheet'
          : undefined
      })()

      return {
        error,
        validData,
        invalidData,
      }
    } else {
      return {
        error: 'The imported sheet seems to be empty.',
        validData: [],
        invalidData: [],
      }
    }
  } catch (error) {
    console.error(error)
    return {
      error: error?.message || 'We seem to have run into a problem while loading your sheet',
      validData: [],
      invalidData: [],
    }
  }
}
