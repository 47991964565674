import { ExportOutlined } from '@ant-design/icons'
import { Button, ButtonProps } from 'antd'
import { FormattedMessage } from 'react-intl'
import React from 'react'

/** Common button used for exports */
export default function ExportButton(props: ButtonProps) {
  return (
    <Button ghost icon={<ExportOutlined />} type='text' {...props}>
      <FormattedMessage id='common.export' tagName='span' />
    </Button>
  )
}
