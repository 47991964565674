import { Skeleton } from 'antd'
import React, { HTMLAttributes, ReactNode, useMemo } from 'react'
import { cssvars } from 'src/styles'
import { cn } from 'src/utils/classnames'
import { hasChild } from 'src/utils/collections'
import PageContent from './page-content'
import PageHeader, { PageHeaderProps } from './page-header'
import PageSubtitle from './page-subtitle'
import PageTitle from './page-title'
import { useLegacyLayout } from '../legacy-layout-provider'
import PageLoader from './page-loader'

interface Props {
  value?: string
  label?: string
  title?: string | ReactNode
  subtitle?: string | ReactNode
  className?: string
  extra?: ReactNode
  inner?: boolean
  loading?: boolean
  back?: PageHeaderProps['back']
  breadcrumbs?: PageHeaderProps['breadcrumbs']
}

const defaultProps = {
  value: '',
  label: '',
  disabled: false,
  inner: false,
  title: '' as string | ReactNode,
  subtitle: '' as string | ReactNode,
  extra: null as ReactNode,
  className: '',
  loading: false,
}

type NativeAttrs = Omit<HTMLAttributes<HTMLDivElement>, keyof Props>
export type PageProps = Props & typeof defaultProps & NativeAttrs

const Page: React.FC<React.PropsWithChildren<PageProps>> = ({
  className,
  title,
  subtitle,
  children,
  value,
  extra,
  label,
  inner,
  loading,
  back,
  breadcrumbs,
  ...props
}) => {
  if (back && breadcrumbs) {
    throw new Error('Both `breadcrumbs` and `back` props passed to `Page` component, only 1 can be used.')
  }

  const hasHeader = hasChild(children, PageHeader)
  const hasTitle = hasChild(children, PageTitle)
  const hasSubtitle = hasChild(children, PageSubtitle)
  const hasContent = hasChild(children, PageContent)
  const { isLegacyLayout } = useLegacyLayout()

  const loadingContent = (
    <>
      <PageHeader
        className='mb-8'
        title={title && <Skeleton.Input active={true} style={{ width: '300px' }} size='large' />}
        subtitle={subtitle && <Skeleton.Input active={true} style={{ width: '200px' }} size='small' />}
        extra={extra && <Skeleton.Button active={true} key='preload-action' size='large' />}
      />
      <Skeleton active paragraph={{ rows: 6 }} title={false} />
    </>
  )

  const content = useMemo(() => {
    return (
      <>
        {!hasHeader && (
          <>
            <PageHeader
              back={back}
              breadcrumbs={breadcrumbs}
              title={!hasTitle && title && <PageTitle>{title}</PageTitle>}
              subtitle={!hasSubtitle && subtitle && <PageSubtitle>{subtitle}</PageSubtitle>}
              extra={extra}
            />
          </>
        )}
        {children}
      </>
    )
  }, [children, hasTitle, hasSubtitle, hasHeader, extra, title, subtitle, back, breadcrumbs])

  if (isLegacyLayout) {
    return (
      <div
        className={cn('m-5 page p-8', className, {
          'lg:px-32 lg:py-12': inner,
        })}
        {...props}
      >
        {hasContent ? content : <PageContent>{loading ? loadingContent : content}</PageContent>}
        <style jsx>{`
          .page {
            background-color: ${cssvars.componentBackground};
            border: 1px solid ${cssvars.borderColor};
            border-radius: 5px;
            overflow: hidden;
          }
        `}</style>
      </div>
    )
  }

  return (
    <div
      className={cn('page p-8 h-full', className, {
        'lg:px-32 lg:py-12': inner,
      })}
      {...props}
    >
      {hasContent ? content : <PageContent>{loading ? loadingContent : content}</PageContent>}
      <style jsx>{`
        .page {
          background-color: ${cssvars.componentBackground};
          overflow-y: auto;
        }
      `}</style>
    </div>
  )
}

Page.defaultProps = defaultProps

type PageComponent<P = any> = React.FC<P> & {
  Header: typeof PageHeader
  Title: typeof PageTitle
  Content: typeof PageContent
  Subtitle: typeof PageSubtitle
  Body: typeof PageContent
  Loader: typeof PageLoader
}

type ComponentProps = Partial<typeof defaultProps> & Omit<Props, keyof typeof defaultProps> & NativeAttrs

export default Page as PageComponent<ComponentProps>
