import { API as AmplifyAPI } from 'aws-amplify'
import { ParkingBookingLogItem } from 'src/typings/kenai/parking/booking'
import AccessManager from 'src/utils/AccessManager'
import { EntityHierarchyConfig } from 'src/typings/kenai/configuration/entity-hierarchy'

interface Response {
  key: 'LOG_RETRIEVED' | ''
  logItems: ParkingBookingLogItem[]
}

export default async function parkingBookingLogRetrieval(
  beginTime: string | number,
  endTime: string | number,
  entityHierarchy: string,
  ehConfig: EntityHierarchyConfig,
  bookingTypes: ('ONDEMAND' | 'PROVISIONED')[]
): Promise<Response> {
  try {
    let hierarchies: string[] = []
    if (entityHierarchy === 'ALL' && ehConfig && ehConfig.tenantFlags) {
      hierarchies = ehConfig.tenantFlags.map((availableEntity) => availableEntity.EntityHierarchy)
    } else if (ehConfig && ehConfig.hierarchy.flatStructure[entityHierarchy].children.length > 0) {
      const addRecursive = (additionalChildren) => {
        for (const prop of additionalChildren) {
          hierarchies.push(prop.hierarchyStructure)
          if (prop.children && prop.children.length > 0) {
            addRecursive(prop.children)
          }
        }
      }
      hierarchies.push(entityHierarchy)
      addRecursive(ehConfig.hierarchy.flatStructure[entityHierarchy].children)
    } else {
      hierarchies.push(entityHierarchy)
    }
    const options = {
      headers: {
        Authorization: `Bearer ${AccessManager.idToken}`,
      },
      body: {
        query: {
          hierarchies,
          beginTime,
          endTime,
          bookingTypes,
        },
      },
    }

    let response = await AmplifyAPI.post('parkingOperations', '/parkingBookingLogRetrieval', options)
    if (response.key === 'ASYNC_RESPONSE_PAYLOAD') {
      const asyncResponsePayload = await fetch(response.s3PresignedURL)
      response = await asyncResponsePayload.json()
    }
    return response
  } catch (error) {
    console.error(error)
    return { key: '', logItems: [] }
  }
}
