import React from 'react'
import withDefaults from 'src/utils/with-defaults'
import { cn } from 'src/utils/classnames'

interface Props {
  className?: string
}

const defaultProps = {
  className: '',
}

type NativeAttrs = Omit<React.HTMLAttributes<HTMLHeadingElement>, keyof Props>
export type PageTitleProps = Props & typeof defaultProps & NativeAttrs

const PageTitle: React.FC<PageTitleProps> = ({ className, children, ...props }) => {
  return (
    <>
      <h3 className={cn('text-lg-title font-normal text-vars-textColor mb-0', className)} {...props}>
        {children}
      </h3>
      <style jsx>{`
        h3 {
          line-height: initial;
        }
      `}</style>
    </>
  )
}

const MemoPageTitle = React.memo(PageTitle)

export default withDefaults(MemoPageTitle, defaultProps)
