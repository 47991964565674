import { uniq } from 'lodash'
import { HierarchyDeepStructure } from 'src/typings/kenai/configuration/entity-hierarchy'

export const getUniqueRecursiveStructure = (source: HierarchyDeepStructure[]) => {
  const allObjects: HierarchyDeepStructure['hierarchyStructure'][] = []
  const getRecursive = (structure: typeof source) => {
    for (const prop of structure) {
      allObjects.push(prop.hierarchyStructure)
      if (prop.children && prop.children.length > 0) {
        getRecursive(prop.children)
      }
    }
  }
  getRecursive(source)
  return uniq(allObjects)
}
