import AccessManager from 'src/utils/AccessManager'
import { EntityHierarchyConfig, EvacuationEntry } from 'src/typings/kenai/configuration/entity-hierarchy'
import { TokenDetails } from 'src/utils/token'

type EvacuationStatus<active extends boolean> = {
  /**
   * Whether the entity is currently being evacuated
   */
  evacuationActive: active
  /**
   * Whether the current user can change the evacuation status
   */
  evacuationMutable: boolean
  /**
   * The evacuation data for the entity
   */
  evacuationEvent: active extends true ? EvacuationEntry : null
}

export function getEvacuationStatusConfig(
  evacuations: EntityHierarchyConfig['additionalConfigs']['evacuation'],
  hierarchy: string,
  currentUser: TokenDetails = AccessManager.tokenDetails,
  hasLaterEntry?: boolean
) {
  //TODO - should also check if children are active @willem @paul
  const evacuationEvent = evacuations?.[hierarchy] || null
  if (!evacuationEvent || !AccessManager.hasAccess('amEvacAdmin') || hasLaterEntry) {
    return {
      evacuationActive: false,
      evacuationMutable: AccessManager.hasAccess('amEvacAdmin'),
      evacuationEvent: {} as null,
    } as EvacuationStatus<false>
  }

  const evacuationActive: boolean = evacuationEvent.active
  const evacuationMutable: boolean = evacuationActive
    ? evacuationEvent.initiator === currentUser.sub
    : AccessManager.hasAccess('amEvacAdmin')

  return {
    evacuationActive,
    evacuationMutable,
    evacuationEvent,
  } as EvacuationStatus<true>
}
