import React from 'react'
import { Tooltip } from 'antd'
import { TooltipProps } from 'antd/lib/tooltip'
import { cssvars } from 'src/styles'
import { useTheme } from 'src/components/theme/ThemeProvider'

const Content = ({ mode, children }) => (
  <div className={`${mode !== 'dark' ? 'text-white' : ''} overflow-popover-container`}>
    {children}
    <style jsx>{`
      :global(.overflow-popover-container .ant-btn-link) {
        color: ${cssvars.textColorInvert} !important;
      }
    `}</style>
  </div>
)

export default function OverflowText({ children, placement = 'top' }: { children: any; placement?: TooltipProps['placement'] }) {
  const { mode } = useTheme()
  return (
    <Tooltip placement={placement} title={<Content mode={mode}>{children}</Content>} trigger={['hover', 'click']}>
      <div className='w-full overflow-hidden whitespace-no-wrap truncate'>{children}</div>
    </Tooltip>
  )
}
