import { Typography } from 'antd'
import React from 'react'
import { IntlProvider } from 'react-intl'

const defaultRichTextElements: IntlProvider['props']['defaultRichTextElements'] = {
  b: (...chunks) => <Typography.Text strong>{chunks}</Typography.Text>,
  p: (...chunks) => <Typography.Paragraph>{chunks}</Typography.Paragraph>,
  highlight: (...chunks) => <Typography.Text className='text-vars-primaryColor'>{chunks}</Typography.Text>,
}

export default defaultRichTextElements
