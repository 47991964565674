const indicator = {
  v: '5.1.1',
  fr: 24,
  ip: 0,
  op: 192,
  w: 300,
  h: 300,
  nm: 'ANIMACION ',
  ddd: 0,
  assets: [],
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 4,
      nm: 'Capa de formas 2',
      parent: 5,
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            { i: { x: [0.667], y: [1] }, o: { x: [0.167], y: [0] }, n: ['0p667_1_0p167_0'], t: 48, s: [0], e: [100] },
            { i: { x: [0.833], y: [1] }, o: { x: [0.333], y: [0] }, n: ['0p833_1_0p333_0'], t: 55, s: [100], e: [100] },
            { i: { x: [0.833], y: [1] }, o: { x: [0.167], y: [0] }, n: ['0p833_1_0p167_0'], t: 136, s: [100], e: [0] },
            { t: 144 },
          ],
          ix: 11,
        },
        r: { a: 0, k: -180, ix: 10 },
        p: { a: 0, k: [-131.319, 22.733, 0], ix: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1 },
        s: { a: 0, k: [133.333, 133.333, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              d: 1,
              ty: 'el',
              s: { a: 0, k: [252.061, 252.061], ix: 2 },
              p: { a: 0, k: [0, 0], ix: 3 },
              nm: 'Trazado elíptico 1',
              mn: 'ADBE Vector Shape - Ellipse',
              hd: false,
            },
            {
              ty: 'tm',
              s: { a: 0, k: 61, ix: 1 },
              e: { a: 0, k: 68, ix: 2 },
              o: { a: 0, k: 0, ix: 3 },
              m: 1,
              ix: 2,
              nm: 'Recortar trazados 1',
              mn: 'ADBE Vector Filter - Trim',
              hd: false,
            },
            {
              ty: 'st',
              c: { a: 0, k: [0.302, 0.718, 0.365, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 3, ix: 5 },
              lc: 2,
              lj: 2,
              nm: 'Trazo 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [0.756862984452, 0.776471007104, 0.784313964844, 1], ix: 4 },
              o: { a: 0, k: 0, ix: 5 },
              r: 1,
              nm: 'Relleno 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [10.03, 22.03], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: {
                a: 0,
                k: -3960,
                ix: 6,
                x: "var $bm_rt;\n$bm_rt = mul(thisComp.layer('Centro').content('Elipse 1').transform.rotation, 3);",
              },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transformar',
            },
          ],
          nm: 'Elipse 1',
          np: 4,
          cix: 2,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 192,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 2,
      ty: 4,
      nm: 'Capa de formas 1',
      parent: 5,
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            { i: { x: [0.667], y: [1] }, o: { x: [0.167], y: [0] }, n: ['0p667_1_0p167_0'], t: 48, s: [0], e: [100] },
            { i: { x: [0.833], y: [1] }, o: { x: [0.333], y: [0] }, n: ['0p833_1_0p333_0'], t: 55, s: [100], e: [100] },
            { i: { x: [0.833], y: [1] }, o: { x: [0.167], y: [0] }, n: ['0p833_1_0p167_0'], t: 136, s: [100], e: [0] },
            { t: 144 },
          ],
          ix: 11,
        },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [-158.066, -36.015, 0], ix: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1 },
        s: { a: 0, k: [133.333, 133.333, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              d: 1,
              ty: 'el',
              s: { a: 0, k: [252.061, 252.061], ix: 2 },
              p: { a: 0, k: [0, 0], ix: 3 },
              nm: 'Trazado elíptico 1',
              mn: 'ADBE Vector Shape - Ellipse',
              hd: false,
            },
            {
              ty: 'tm',
              s: { a: 0, k: 61, ix: 1 },
              e: { a: 0, k: 68, ix: 2 },
              o: { a: 0, k: 0, ix: 3 },
              m: 1,
              ix: 2,
              nm: 'Recortar trazados 1',
              mn: 'ADBE Vector Filter - Trim',
              hd: false,
            },
            {
              ty: 'st',
              c: { a: 0, k: [0.302, 0.718, 0.365, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 3, ix: 5 },
              lc: 2,
              lj: 2,
              nm: 'Trazo 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [0.756862984452, 0.776471007104, 0.784313964844, 1], ix: 4 },
              o: { a: 0, k: 0, ix: 5 },
              r: 1,
              nm: 'Relleno 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [10.03, 22.03], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6, x: "var $bm_rt;\n$bm_rt = mul(thisComp.layer('Centro').content('Elipse 1').transform.rotation, 3);" },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transformar',
            },
          ],
          nm: 'Elipse 1',
          np: 4,
          cix: 2,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 192,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 3,
      ty: 4,
      nm: 'TRAZADO contornos',
      parent: 5,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [-144.692, -5.692, 0], ix: 2 },
        a: { a: 0, k: [200, 200, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [2.032, -4.873],
                    [2.071, 0.571],
                    [9.15, 2.372],
                    [-0.28, 1.941],
                    [-0.635, 4.258],
                    [2.231, 1.146],
                    [4.682, 2.938],
                    [1.465, -1.156],
                    [4.91, -3.683],
                    [7.893, 7.903],
                    [-3.306, 4.119],
                    [1.002, 1.623],
                    [2.654, 4.989],
                    [1.804, -0.247],
                    [4.504, -0.652],
                    [0.477, 1.821],
                    [2.5, 9.113],
                    [-1.643, 0.697],
                    [-4.936, 2.131],
                    [0.183, 6.629],
                    [0.949, 0.45],
                    [4.08, 1.999],
                    [-0.202, 0.898],
                    [-2.527, 10.346],
                    [-4.828, -0.767],
                    [-1.334, 2.876],
                    [-2.737, 4.227],
                    [1.576, 1.907],
                    [3.577, 4.8],
                    [-7.953, 7.963],
                    [-3.989, -3.212],
                    [-2.071, 1.346],
                    [-4.722, 2.353],
                    [0.351, 2.322],
                    [0.616, 4.136],
                    [-2.087, 0.541],
                    [-9.184, 2.236],
                    [-0.455, -0.913],
                    [-1.578, -4.131],
                    [-2.302, 0.101],
                    [-5.403, -0.191],
                    [-0.782, 2.13],
                    [-1.517, 3.892],
                    [-2.563, -0.721],
                    [-8.911, -2.32],
                    [0.317, -2.137],
                    [0.653, -4.379],
                    [-1.839, -0.977],
                    [-4.675, -2.95],
                    [-1.599, 1.295],
                    [-3.757, 2.571],
                    [-0.641, -0.498],
                    [-6.619, -8.585],
                    [3.309, -4.095],
                    [-1.067, -1.716],
                    [-2.54, -4.908],
                    [-1.929, 0.27],
                    [-5.371, 0.655],
                    [-1.863, -9.408],
                    [3.736, -1.163],
                    [3.421, -1.666],
                    [0.027, -0.959],
                    [0, -6.727],
                    [-5.643, -2.456],
                    [3.896, -9.732],
                    [0.865, 0.065],
                    [4.502, 0.659],
                    [1.047, -1.987],
                    [2.925, -4.691],
                    [-1.193, -1.499],
                    [-3.702, -4.936],
                    [7.887, -7.884],
                    [4.129, 3.292],
                    [1.663, -1.039],
                    [4.996, -2.64],
                    [-0.259, -1.847],
                    [-0.601, -4.512],
                    [1.939, -0.502],
                    [9.11, -2.518],
                    [0.709, 1.7],
                    [2.067, 4.78],
                  ],
                  o: [
                    [-2.082, 4.803],
                    [-0.717, 1.718],
                    [-9.112, -2.512],
                    [-1.915, -0.497],
                    [0.613, -4.262],
                    [0.328, -2.198],
                    [-4.913, -2.523],
                    [-1.689, -1.06],
                    [-4.142, 3.267],
                    [-7.911, -7.921],
                    [3.729, -4.95],
                    [1.224, -1.526],
                    [-2.972, -4.809],
                    [-0.943, -1.771],
                    [-4.509, 0.618],
                    [-2, 0.29],
                    [-2.398, -9.141],
                    [-0.576, -2.098],
                    [4.857, -2.059],
                    [0, -6.705],
                    [-0.026, -0.947],
                    [-4.108, -1.948],
                    [-0.96, -0.471],
                    [2.26, -10.029],
                    [5.796, 0.695],
                    [2.768, 0.44],
                    [2.113, -4.554],
                    [1.258, -1.944],
                    [-3.279, -3.966],
                    [7.847, -7.857],
                    [4.722, 3.513],
                    [1.79, 1.441],
                    [4.427, -2.877],
                    [2.381, -1.187],
                    [-0.627, -4.133],
                    [-0.307, -2.056],
                    [9.152, -2.37],
                    [0.915, -0.222],
                    [1.969, 3.955],
                    [0.768, 2.006],
                    [5.399, -0.239],
                    [2.174, 0.077],
                    [1.439, -3.919],
                    [0.834, -2.138],
                    [8.864, 2.49],
                    [2.015, 0.525],
                    [-0.65, 4.379],
                    [-0.303, 2.034],
                    [4.88, 2.596],
                    [1.799, 1.135],
                    [3.538, -2.864],
                    [0.744, -0.509],
                    [8.292, 6.442],
                    [-3.211, 4.299],
                    [-1.356, 1.677],
                    [2.92, 4.696],
                    [1.014, 1.959],
                    [5.112, -0.714],
                    [3.794, 9.05],
                    [0.721, 3.644],
                    [-3.621, 1.128],
                    [-0.951, 0.463],
                    [-0.182, 6.628],
                    [5.675, 2.469],
                    [-1.27, 10.773],
                    [-0.308, 0.768],
                    [-4.536, -0.342],
                    [-2.137, -0.313],
                    [-2.577, 4.889],
                    [-1.03, 1.651],
                    [3.287, 4.133],
                    [-7.908, 7.905],
                    [-4.97, -3.729],
                    [-1.498, -1.194],
                    [-4.792, 2.997],
                    [-1.761, 0.931],
                    [0.633, 4.508],
                    [0.251, 1.885],
                    [-9.151, 2.37],
                    [-2.09, 0.578],
                    [-2.034, -4.874],
                    [0, 0],
                  ],
                  v: [
                    [-10.882, 116.27],
                    [-17.139, 130.773],
                    [-20.729, 132.724],
                    [-48.139, 125.456],
                    [-50.356, 122.003],
                    [-48.768, 109.182],
                    [-51.024, 104.52],
                    [-65.376, 96.206],
                    [-69.568, 96.497],
                    [-82.893, 106.547],
                    [-106.471, 82.941],
                    [-96.34, 69.586],
                    [-96.149, 65.395],
                    [-104.649, 50.716],
                    [-108.421, 48.817],
                    [-121.977, 50.472],
                    [-125.387, 48.198],
                    [-132.668, 20.798],
                    [-130.619, 17.206],
                    [-115.992, 10.905],
                    [-116.089, -9.066],
                    [-118.193, -11.782],
                    [-130.673, -17.293],
                    [-132.574, -20.449],
                    [-125.281, -50.798],
                    [-109.777, -48.888],
                    [-104.065, -51.75],
                    [-96.522, -64.843],
                    [-96.643, -69.922],
                    [-106.439, -82.934],
                    [-82.938, -106.465],
                    [-70.029, -96.786],
                    [-64.917, -96.409],
                    [-51.197, -104.344],
                    [-48.781, -109.244],
                    [-50.335, -121.694],
                    [-48.021, -125.452],
                    [-20.568, -132.566],
                    [-17.357, -130.869],
                    [-12.079, -118.695],
                    [-8.092, -115.99],
                    [8.132, -116.003],
                    [12.206, -118.578],
                    [17.055, -130.138],
                    [21.376, -132.585],
                    [48.07, -125.477],
                    [50.497, -121.779],
                    [48.868, -108.594],
                    [51.012, -104.585],
                    [65.357, -96.251],
                    [69.853, -96.549],
                    [80.833, -104.677],
                    [83.918, -104.805],
                    [106.148, -82.353],
                    [96.581, -69.652],
                    [96.439, -65.141],
                    [104.743, -50.777],
                    [108.788, -48.755],
                    [124.404, -50.689],
                    [132.524, -23.05],
                    [128.883, -16.256],
                    [118.362, -11.816],
                    [116.266, -9.067],
                    [116.169, 10.924],
                    [133.097, 18.289],
                    [125.144, 48.832],
                    [122.5, 50.361],
                    [108.932, 48.834],
                    [104.624, 51.084],
                    [96.297, 65.43],
                    [96.538, 69.618],
                    [106.62, 82.975],
                    [83.044, 106.542],
                    [69.656, 96.43],
                    [65.496, 96.199],
                    [50.818, 104.698],
                    [48.857, 108.422],
                    [50.504, 121.98],
                    [48.285, 125.451],
                    [20.878, 132.728],
                    [17.303, 130.754],
                    [11.066, 116.27],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Trazado 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'tm',
              s: {
                a: 1,
                k: [{ i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, n: ['0p667_1_0p333_0'], t: 24, s: [0], e: [100] }, { t: 48 }],
                ix: 1,
              },
              e: { a: 0, k: 0, ix: 2 },
              o: { a: 0, k: 0, ix: 3 },
              m: 1,
              ix: 2,
              nm: 'Recortar trazados 1',
              mn: 'ADBE Vector Filter - Trim',
              hd: false,
            },
            {
              ty: 'st',
              c: { a: 0, k: [0.302, 0.718, 0.365, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 4, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 10,
              nm: 'Trazo 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [199.869, 199.953], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6, x: "var $bm_rt;\n$bm_rt = thisComp.layer('Centro').content('Elipse 1').transform.rotation;" },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transformar',
            },
          ],
          nm: 'Grupo 1',
          np: 3,
          cix: 2,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 192,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 4,
      ty: 4,
      nm: 'Centro 2',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 654.043, ix: 10 },
        p: { a: 0, k: [148.687, 150.396, 0], ix: 2 },
        a: { a: 0, k: [-143, -5, 0], ix: 1 },
        s: { a: 0, k: [75, 75, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              d: 1,
              ty: 'el',
              s: { a: 0, k: [115.615, 115.615], ix: 2 },
              p: { a: 0, k: [0, 0], ix: 3 },
              nm: 'Trazado elíptico 1',
              mn: 'ADBE Vector Shape - Ellipse',
              hd: false,
            },
            {
              ty: 'st',
              c: { a: 0, k: [0.302, 0.718, 0.365, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 4, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 4,
              nm: 'Trazo 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [0.756862984452, 0.776471007104, 0.784313964844, 1], ix: 4 },
              o: { a: 0, k: 0, ix: 5 },
              r: 1,
              nm: 'Relleno 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [-144.692, -5.692], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [90.185, 90.185], ix: 3 },
              r: {
                a: 1,
                k: [
                  { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, n: ['0p833_0p833_0p167_0p167'], t: 48, s: [0], e: [360] },
                  { t: 143 },
                ],
                ix: 6,
              },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transformar',
            },
          ],
          nm: 'Elipse 1',
          np: 3,
          cix: 2,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'tm',
          s: {
            a: 1,
            k: [
              { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, n: ['0p833_0p833_0p167_0p167'], t: 0, s: [100], e: [0] },
              { t: 24 },
            ],
            ix: 1,
          },
          e: { a: 0, k: 100, ix: 2 },
          o: { a: 0, k: 0, ix: 3 },
          m: 1,
          ix: 2,
          nm: 'Recortar trazados 1',
          mn: 'ADBE Vector Filter - Trim',
          hd: false,
        },
      ],
      ip: 0,
      op: 192,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 5,
      ty: 4,
      nm: 'Centro',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 654.043, ix: 10 },
        p: { a: 0, k: [148.687, 150.396, 0], ix: 2 },
        a: { a: 0, k: [-143, -5, 0], ix: 1 },
        s: { a: 0, k: [75, 75, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              d: 1,
              ty: 'el',
              s: { a: 0, k: [186.615, 186.615], ix: 2 },
              p: { a: 0, k: [0, 0], ix: 3 },
              nm: 'Trazado elíptico 1',
              mn: 'ADBE Vector Shape - Ellipse',
              hd: false,
            },
            {
              ty: 'st',
              c: { a: 0, k: [0.302, 0.718, 0.365, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 4, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 4,
              nm: 'Trazo 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [0.756862984452, 0.776471007104, 0.784313964844, 1], ix: 4 },
              o: { a: 0, k: 0, ix: 5 },
              r: 1,
              nm: 'Relleno 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [-144.692, -5.692], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [90.185, 90.185], ix: 3 },
              r: {
                a: 1,
                k: [
                  { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, n: ['0p833_0p833_0p167_0p167'], t: 48, s: [0], e: [360] },
                  { t: 143 },
                ],
                ix: 6,
              },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transformar',
            },
          ],
          nm: 'Elipse 1',
          np: 3,
          cix: 2,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'tm',
          s: { a: 0, k: 0, ix: 1 },
          e: {
            a: 1,
            k: [{ i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, n: ['0p667_1_0p333_0'], t: 0, s: [0], e: [100] }, { t: 24 }],
            ix: 2,
          },
          o: { a: 0, k: 0, ix: 3 },
          m: 1,
          ix: 2,
          nm: 'Recortar trazados 1',
          mn: 'ADBE Vector Filter - Trim',
          hd: false,
        },
      ],
      ip: 0,
      op: 192,
      st: 0,
      bm: 0,
    },
  ],
  markers: [],
}

export default indicator
