import update from 'immutability-helper'
import { createSelector } from 'reselect'
import { EntityHierarchyConfig, HierarchyDeepStructure, TenantFlags } from 'src/typings/kenai/configuration/entity-hierarchy'
import { ROUTE_EMPLOYEE_AVAILABLE_TYPES } from 'src/typings/kenai/dashboard-router'
import AccessManager from 'src/utils/AccessManager'
import { RootState } from '../rootReducer'
import { ActionCreator } from '../store-action-creator'
import { parkingRouteConfig, tenantFlagHelper } from './tenant-flag-helper'

interface Payload {
  ehConfig: EntityHierarchyConfig
}

// Action Creators
export const FeaturesActionCreators = {
  StoreSetFeatures: new ActionCreator<'StoreSetFeatures', Payload>('StoreSetFeatures'),
  ClearAllStates: new ActionCreator<'ClearAllStates', void>('ClearAllStates'),
}

export type Action = typeof FeaturesActionCreators[keyof typeof FeaturesActionCreators]

export const initialState = () => ({
  visitorLog: false,
  visitorAnalytics: false,
  hostTagsEnabled: false,
  inviteLog: false,
  inviteCreate: false,
  inviteCreateWithOptionalEmail: false,
  inviteLogDelete: false,
  inviteLogResend: false,
  inviteInternalNoteCreatable: false,
  inviteInternalNoteEditable: false,
  employeeLog: false,
  employeeScreeningLog: false,
  employeeDirectory: false,
  employeeRouteType: 'employees' as ROUTE_EMPLOYEE_AVAILABLE_TYPES,
  parkingRouteType: parkingRouteConfig(),
  employeeLineManagers: false,
  employeeTags: false,
  employeeAlternatePersonalIdNr: false,
  employeeBadgeLinking: false,
  hostDirectory: false,
  dafLog: false,
  inductionLog: false,
  crossLocationInductionStatus: false,
  parkingLog: false,
  parkingBookingLog: false,
  parkingAnalytics: false,
  parkingConfiguration: false,
  parkingBookingProvisioning: false,
  userLog: false,
  integrations: false,
  tenantConfiguration: false,
  settingsNotifications: false,
  settingsResources: false,
  loading: true,
  blocklist: false,
  multiTenantEvacTriggerEnabled: false,
  evacuation: {
    audienceEnablement: {
      onSiteVisitorsEvacEnabled: true,
      onSiteEmployeeEvacEnabled: true,
      onSiteParkingEvacEnabled: false,
      employeeDirectoryEvacEnabled: false,
    } as NonNullable<TenantFlags['evacuation']['audienceEnablement']>,
  },
  messaging: {
    evacuation: false,
  },
})

export type State = ReturnType<typeof initialState>
export type FeatureFlags = State

const getFeaturesFlagsState = (payload: Payload) => {
  const { ehConfig } = payload
  const role = AccessManager.selectedHierarchy.role
  const selectedEh = AccessManager.selectedHierarchy.hierarchyStructure
  const profile = AccessManager.profileAccess

  const flagsToSet = initialState()

  const handleSetGlobalTenantFlags = (tenantFlag: TenantFlags) => {
    // Disable all visitor routes if the user has no access to the visitor module
    if (!profile.amDisableVisitorModule) {
      if (!flagsToSet.visitorLog) {
        flagsToSet.visitorLog = tenantFlagHelper.getVisitorLogEnabled(tenantFlag)
      }
      if (!flagsToSet.hostTagsEnabled) {
        flagsToSet.hostTagsEnabled = tenantFlagHelper.getHostTagsEnabled(ehConfig, tenantFlag.EntityHierarchy)
      }

      if (!flagsToSet.inviteLog) {
        flagsToSet.inviteLog = tenantFlagHelper.getInviteLogEnabled(tenantFlag)
        if (!flagsToSet.inviteCreate) {
          flagsToSet.inviteCreate = tenantFlagHelper.getInviteCreateEnabled(tenantFlag)
        }
        if (!flagsToSet.inviteCreateWithOptionalEmail) {
          flagsToSet.inviteCreateWithOptionalEmail = tenantFlagHelper.getInvitecCreateWithOptionalEmailEnabled(tenantFlag)
        }
        if (!flagsToSet.inviteLogResend) {
          flagsToSet.inviteLogResend = tenantFlagHelper.getInviteLogResendEnabled(tenantFlag)
        }
        if (!flagsToSet.inviteLogDelete) {
          flagsToSet.inviteLogDelete = tenantFlagHelper.getInviteLogDeleteEnabled(tenantFlag)
        }
        if (!flagsToSet.inviteInternalNoteCreatable) {
          flagsToSet.inviteInternalNoteCreatable = tenantFlagHelper.getInviteInternalNoteCreatable(tenantFlag)
        }
        if (!flagsToSet.inviteInternalNoteEditable) {
          flagsToSet.inviteInternalNoteEditable = tenantFlagHelper.getInviteInternalNoteEditable(tenantFlag)
        }
      }
    }
    // Disable all employee routes if the user has no access to the employee module
    if (!profile.amDisableEmployeeModule) {
      if (!flagsToSet.employeeLog) {
        flagsToSet.employeeLog = tenantFlagHelper.getEmployeeLogEnabled(
          tenantFlag,
          ehConfig.additionalConfigs.flowTypes[tenantFlag.EntityHierarchy],
          AccessManager.selectedHierarchy.role
        )
      }
      if (!flagsToSet.employeeScreeningLog) {
        flagsToSet.employeeScreeningLog = tenantFlagHelper.getEmployeeScreeningLogEnabled(
          tenantFlag,
          ehConfig.additionalConfigs.flowTypes[tenantFlag.EntityHierarchy],
          AccessManager.selectedHierarchy.role
        )
      }
      //Always set route type based on hierarchy, never look at previous flag or default
      flagsToSet.employeeRouteType = tenantFlagHelper.getEmployeeRouteType(tenantFlag, AccessManager.selectedHierarchy.role)
    }

    if (!profile.amDisableParkingModule) {
      if (!flagsToSet.parkingLog) {
        flagsToSet.parkingLog = tenantFlagHelper.getParkingLogEnabled(tenantFlag, AccessManager.selectedHierarchy.role)
      }
      if (!flagsToSet.parkingBookingLog) {
        flagsToSet.parkingBookingLog = tenantFlagHelper.getParkingBookingLogEnabled(tenantFlag, AccessManager.selectedHierarchy.role)
      }
      if (!flagsToSet.parkingBookingProvisioning) {
        flagsToSet.parkingBookingProvisioning = tenantFlagHelper.getParkingBookingProvisioningEnabled(
          tenantFlag,
          AccessManager.selectedHierarchy.role
        )
      }

      flagsToSet.parkingRouteType = tenantFlagHelper.getParkingRouteTypeOverride(
        ehConfig,
        AccessManager.selectedHierarchy.hierarchyStructure
      )
    }

    if (profile.amBlocklistMaintenanceEnabled) {
      flagsToSet.blocklist = true
    }

    if (!flagsToSet.multiTenantEvacTriggerEnabled) {
      flagsToSet.multiTenantEvacTriggerEnabled = tenantFlagHelper.getMultiTenantEvacTriggerEnabled(tenantFlag)
    }

    flagsToSet.evacuation.audienceEnablement = tenantFlagHelper.getEvacuationAudience(tenantFlag)
  }

  if (role && ehConfig.hasBeenLoaded === true && ehConfig.tenantFlags && ehConfig.hierarchy && ehConfig.hierarchy.flatStructure) {
    flagsToSet.loading = false
    const selectedEhStructure = ehConfig.hierarchy.flatStructure[selectedEh]
    ehConfig.tenantFlags.forEach((tenantFlag: TenantFlags) => {
      if (selectedEh === 'ALL' || selectedEh === tenantFlag.EntityHierarchy) {
        if (!profile.amDisableInductionModule) {
          if (!flagsToSet.inductionLog) {
            flagsToSet.inductionLog = tenantFlagHelper.getInductionLogEnabled(ehConfig, selectedEh)
          }
          if (!flagsToSet.crossLocationInductionStatus) {
            flagsToSet.crossLocationInductionStatus = tenantFlagHelper.getInductionCrossLocationStatusEnabled(ehConfig, selectedEh)
          }
        }
        if (!profile.amDisableDAFModule) {
          if (!flagsToSet.dafLog) {
            flagsToSet.dafLog = tenantFlagHelper.getDAFEnabled(ehConfig, selectedEh)
          }
        }
      }

      if (selectedEh === 'ALL') {
        // ALL ENTITIES SELECTED
        handleSetGlobalTenantFlags(tenantFlag)
      } else if (selectedEh === tenantFlag.EntityHierarchy) {
        // ONE ENTITY SELECTED
        handleSetGlobalTenantFlags(tenantFlag)
        // Disable all employee routes if the user has no access to the employee module

        if (!profile.amDisableVisitorModule) {
          flagsToSet.hostTagsEnabled = tenantFlagHelper.getHostTagsEnabled(ehConfig, selectedEh)
        }

        if (!profile.amDisableEmployeeModule) {
          if (!flagsToSet.hostDirectory) {
            flagsToSet.hostDirectory = tenantFlagHelper.getHostMaintenanceEnabled(
              profile,
              ehConfig,
              selectedEh,
              AccessManager.selectedHierarchy.role
            )
          }
          if (!flagsToSet.employeeDirectory) {
            flagsToSet.employeeDirectory = tenantFlagHelper.getEmployeeMaintenanceEnabled(profile, ehConfig, selectedEh)
          }
          if (!flagsToSet.employeeLineManagers) {
            flagsToSet.employeeLineManagers = tenantFlagHelper.getEmployeeLineManagerMaintenanceEnabled(ehConfig, selectedEh)
          }
          if (!flagsToSet.employeeTags) {
            flagsToSet.employeeTags = tenantFlagHelper.getEmployeeTagsEnabled(ehConfig, selectedEh)
          }
          if (!flagsToSet.employeeAlternatePersonalIdNr) {
            flagsToSet.employeeAlternatePersonalIdNr = tenantFlagHelper.getEmployeeAlternatePersonalIdNrEnabledMaintenanceEnabled(
              ehConfig,
              selectedEh
            )
          }
          if (!flagsToSet.employeeAlternatePersonalIdNr) {
            flagsToSet.employeeAlternatePersonalIdNr = tenantFlagHelper.getEmployeeAlternatePersonalIdNrEnabledMaintenanceEnabled(
              ehConfig,
              selectedEh
            )
          }
          if (!flagsToSet.employeeBadgeLinking) {
            flagsToSet.employeeBadgeLinking = tenantFlagHelper.getEmployeeBadgeLinkingEnabled(ehConfig, selectedEh)
          }
        }

        if (!flagsToSet.parkingAnalytics) {
          flagsToSet.parkingAnalytics = tenantFlagHelper.getParkingAnalyticsEnabled(
            profile,
            ehConfig,
            selectedEh,
            AccessManager.selectedHierarchy.role
          )
        }

        flagsToSet.parkingRouteType = tenantFlagHelper.getParkingRouteTypeOverride(ehConfig, selectedEh)

        if (!flagsToSet.multiTenantEvacTriggerEnabled) {
          flagsToSet.multiTenantEvacTriggerEnabled = tenantFlagHelper.getMultiTenantEvacTriggerEnabled(tenantFlag)
        }

        flagsToSet.evacuation.audienceEnablement = tenantFlagHelper.getEvacuationAudience(tenantFlag)
      } else if (selectedEhStructure?.children?.length > 0) {
        // PARENT ENTITY SELECTED
        const checkRecursive = (additionalChildren: HierarchyDeepStructure['children']) => {
          for (const prop of additionalChildren) {
            if (prop.hierarchyStructure === tenantFlag.EntityHierarchy) {
              const childTenantFlag = ehConfig.tenantFlags.find((tenant) => tenant.EntityHierarchy === prop.hierarchyStructure)
              if (childTenantFlag) {
                handleSetGlobalTenantFlags(childTenantFlag)
              }
            } else if (prop.children.length > 0) {
              checkRecursive(prop.children)
            }
          }
        }
        checkRecursive(selectedEhStructure.children)
      }
    })

    if (role === 'HOST') {
      flagsToSet.employeeLog = false
      flagsToSet.employeeScreeningLog = false
      flagsToSet.employeeDirectory = false
      flagsToSet.employeeRouteType = 'employees'
      flagsToSet.employeeLineManagers = false
      flagsToSet.employeeTags = false
      flagsToSet.employeeAlternatePersonalIdNr = false
      flagsToSet.employeeBadgeLinking = false
      flagsToSet.hostDirectory = false
      flagsToSet.inductionLog = false
      flagsToSet.parkingLog = false
      flagsToSet.parkingBookingLog = false
      flagsToSet.parkingAnalytics = false
      flagsToSet.parkingConfiguration = false
      flagsToSet.parkingBookingProvisioning = false
      flagsToSet.userLog = false
      flagsToSet.integrations = false
      flagsToSet.tenantConfiguration = false
      flagsToSet.settingsNotifications = false
      flagsToSet.settingsResources = false
    }

    if (role === 'ADMIN') {
      flagsToSet.tenantConfiguration = true
      flagsToSet.userLog = true
      if (profile.amAnalyticsEnabled === true) {
        flagsToSet.visitorAnalytics = true
      }
      if (profile.amApiAdminForTypes) {
        flagsToSet.integrations = true
      }
      if (profile.amNotificationsAdmin === true) {
        flagsToSet.settingsNotifications = true
      }
      if (profile.amResourceAdmin === true) {
        flagsToSet.settingsResources = true
      }
      if (profile.amEvacAdmin) {
        flagsToSet.messaging.evacuation = true
      }
    }
  }

  if (profile.amDisableVisitorModule) {
    flagsToSet.visitorLog = false
    flagsToSet.inviteLog = false
    flagsToSet.inviteCreate = false
    flagsToSet.visitorAnalytics = false
    flagsToSet.hostDirectory = false
  }

  if (profile.amDisableEmployeeModule) {
    flagsToSet.employeeLog = false
    flagsToSet.employeeScreeningLog = false
    flagsToSet.employeeDirectory = false
    flagsToSet.employeeRouteType = 'employees'
    flagsToSet.employeeLineManagers = false
    flagsToSet.employeeTags = false
    flagsToSet.employeeAlternatePersonalIdNr = false
    flagsToSet.employeeBadgeLinking = false
  }

  if (profile.amDisableParkingModule) {
    flagsToSet.parkingLog = false
    flagsToSet.parkingBookingLog = false
    flagsToSet.parkingAnalytics = false
    flagsToSet.parkingConfiguration = false
    flagsToSet.parkingBookingProvisioning = false
  }

  if (profile.amDisableInductionModule) {
    flagsToSet.inductionLog = false
  }

  return flagsToSet
}

// Reducer
export default function reducer(state: State = initialState(), action: Action) {
  switch (action.type) {
    case FeaturesActionCreators.StoreSetFeatures.type:
      return update(state, { $set: getFeaturesFlagsState(action.payload) })
    case FeaturesActionCreators.ClearAllStates.type:
      return initialState()
    default:
      return state
  }
}

// Selectors
const getFeatureFlagsState = (state: RootState) => state.features
export const getFeatureFlags = createSelector<any, State, State>([getFeatureFlagsState], (state) => {
  return state
})
