import React from 'react'
import { Typography } from 'antd'
import { ModalProps } from 'antd/lib/modal'
import Modal from '../modal/modal-center'

export default function GuardModal(props: ModalProps) {
  const { title, ...modalProps } = props

  return (
    <Modal
      title={title ? title : 'Leave Page'}
      okText='Continue'
      centered
      okButtonProps={{ size: 'middle' }}
      cancelButtonProps={{ size: 'middle' }}
      {...modalProps}
    >
      <Typography.Paragraph>You will lose any unsaved changes to this document. Are you sure you want to continue?</Typography.Paragraph>
    </Modal>
  )
}
