/// PRE-MIGRATION START
import { getCredentialedDDBDocumentClientGeneric, getTableNameFromAwsExports } from '../utils'
import { DocumentClient } from 'aws-sdk/clients/dynamodb'
import AccessManager from 'src/utils/AccessManager'

export interface ConfigDAF {
  /**
   * The OrganizationId of the organization that this configuration belongs to
   */
  OrganizationId: string
  /**
   * The DocumentTypeId of the document type
   */
  DocumentTypeId: string
  /**
   * The description of this document type
   */
  documentDescription: string
  /**
   * Whether this document type is active or not
   */
  isActive: boolean
  documentSpecification: {
    attachments: {
      /**
       * Whether attachments can be created, and if so, whether this is optional or mandatory
       */
      required: 'DISABLED' | 'OPTIONAL' | 'MANDATORY'
    }
    /**
     * Hierarchies where a global document applies or were a local document can be set to during creation
     */
    availableHierarchies: string[]
    validity: {
      /**
       * Validity specifier e.g. GLOBAL at all available hierarchies or local at selected hierarchy
       */
      mode: 'GLOBAL' | 'LOCAL'
      /**
       * Validity version, bumping this will invalidate prior documents irrespective of their validity period
       */
      version: number
      /**
       * Timezone used for validity period
       */
      baseTimeZone: string
    }
  }
}

export async function retrieveConfigDAF() {
  const params: DocumentClient.QueryInput = {
    ExpressionAttributeValues: {
      ':hk': AccessManager.organizationId,
    },
    ConsistentRead: true,
    KeyConditionExpression: 'OrganizationId = :hk',
    TableName: getTableNameFromAwsExports('mdConfigDAF'),
  }

  return await getCredentialedDDBDocumentClientGeneric('organization')
    .query(params)
    .promise()
    .then((data) => {
      return data.Items as ConfigDAF[]
    })
}
/// PRE-MIGRATION END
