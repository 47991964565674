import update from 'immutability-helper'
import { createSelector } from 'reselect'
import { VISITORLOG_ACTIVE_KEYS_ENUM, VISITORLOG_KEYS_ENUM } from 'src/typings/kenai/enums'
import { VISITORLOG_KEYS } from 'src/typings/kenai/enums/enums'
import { RangePickerNumberValue } from 'src/typings/vendor/rangepicker'
import { getTimeRange } from 'src/utils/getTimeRange'
import { RootState } from '../rootReducer'
import { ActionCreator } from '../store-action-creator'
import { VisitorLogItem } from './types/log.d'

// Action Creators
export const ActionCreators = {
  StoreSetVisitorLogFilteredData: new ActionCreator<'StoreSetVisitorLogFilteredData', State['filteredData']>(
    'StoreSetVisitorLogFilteredData'
  ),
  StoreSetVisitorLogData: new ActionCreator<'StoreSetVisitorLogData', State['data']>('StoreSetVisitorLogData'),
  StoreSetVisitorLogShouldSubscribe: new ActionCreator<'StoreSetVisitorLogShouldSubscribe', boolean>('StoreSetVisitorLogShouldSubscribe'),
  StoreSetVisitorsLogDateRange: new ActionCreator<'StoreSetVisitorsLogDateRange', State['dateRanges']>('StoreSetVisitorsLogDateRange'),
  StoreSetVisitorsLogActiveColumnKeys: new ActionCreator<'StoreSetVisitorsLogActiveColumnKeys', State['activeColumnKeys']>(
    'StoreSetVisitorsLogActiveColumnKeys'
  ),
  StoreSetVisitorsLogColumnList: new ActionCreator<'StoreSetVisitorsLogColumnList', State['config']['columnList']>(
    'StoreSetVisitorsLogColumnList'
  ),
  StoreSetVisitorLogNotifications: new ActionCreator<'StoreSetVisitorLogNotifications', State['notifications']>(
    'StoreSetVisitorLogNotifications'
  ),
  StoreSetVisitorLogConfigs: new ActionCreator<'StoreSetVisitorLogConfigs', Partial<State['config']>>('StoreSetVisitorLogConfigs'),
  StoreUpdateVisitorLogStates: new ActionCreator<'StoreUpdateVisitorLogStates', Partial<State['status']>>('StoreUpdateVisitorLogStates'),
  UpdateVisitorLogStates: new ActionCreator<
    'UpdateVisitorLogStates',
    {
      logType: 'visitorlog'
      loading: boolean
      result: string
      state: string
    }
  >('UpdateVisitorLogStates'),
  StoreClearAllVisitorsLogData: new ActionCreator<'StoreClearAllVisitorsLogData', void>('StoreClearAllVisitorsLogData'),
  StoreClearAllVisitorLogFilters: new ActionCreator<'StoreClearAllVisitorLogFilters', void>('StoreClearAllVisitorLogFilters'),
  ClearAllStates: new ActionCreator<'ClearAllStates', void>('ClearAllStates'),
}

export type Action = typeof ActionCreators[keyof typeof ActionCreators]

export interface VisitorLogConfig {
  induction: { [key: string]: any }
  deltaProfileFields: { [key: string]: any }
  columnList: Array<{
    key: VISITORLOG_KEYS
    title: string
    nested: boolean
  }>
}

export type State = {
  filteredData: VisitorLogItem[]
  data: VisitorLogItem[]
  notifications: []
  dateRanges: RangePickerNumberValue
  activeColumnKeys: VISITORLOG_KEYS[]
  visitorShouldSubscribeToUpdates: boolean
  config: VisitorLogConfig
  status: {
    signout: boolean
    signoutrevert: boolean
    delete: boolean
    badgelinking: boolean
    idUpdates: boolean
    inductionCompletion: boolean
    assetSerialNumberUpdates: boolean
    fetching: boolean
    filtering: boolean
  }
}

export function initialVisitorLogActivekeys() {
  // check if filtering has been done and saved to localStorage else falls back to available keys in enum
  // should also be able to pass in 'dynamic' keys once we integrate the log config
  const storedActiveKeys = localStorage.getItem('visitorlog-column-filter')
  let activeKeys: VISITORLOG_KEYS[] = []
  if (storedActiveKeys) {
    activeKeys = JSON.parse(storedActiveKeys)
  } else {
    for (const key in VISITORLOG_ACTIVE_KEYS_ENUM) {
      activeKeys.push(VISITORLOG_ACTIVE_KEYS_ENUM[key])
    }
  }
  return activeKeys
}

const returnTitleKey = (key: string) => `common.titles.${key}`

export const initialState = (): State => {
  return {
    filteredData: [],
    data: [],
    notifications: [],
    dateRanges: getTimeRange('today'),
    activeColumnKeys: initialVisitorLogActivekeys(),
    visitorShouldSubscribeToUpdates: false,
    config: {
      induction: {},
      deltaProfileFields: {},
      columnList: [
        // default columns
        { key: VISITORLOG_KEYS_ENUM.VISITOR, title: returnTitleKey('visitor'), nested: false },
        { key: VISITORLOG_KEYS_ENUM.NAME, title: returnTitleKey('name'), nested: false },
        { key: VISITORLOG_KEYS_ENUM.HOST, title: returnTitleKey('host'), nested: false },
        { key: VISITORLOG_KEYS_ENUM.BADGE, title: returnTitleKey('badge'), nested: false },
        { key: VISITORLOG_KEYS_ENUM.TYPE, title: returnTitleKey('type'), nested: false },
        { key: VISITORLOG_KEYS_ENUM.ID_PASSPORT, title: returnTitleKey('id-passport'), nested: false },
        { key: VISITORLOG_KEYS_ENUM.ASN, title: returnTitleKey('asset-serial-number'), nested: false },
        { key: VISITORLOG_KEYS_ENUM.SIGN_IN, title: returnTitleKey('sign-in'), nested: false },
        { key: VISITORLOG_KEYS_ENUM.HOST_APPROVAL, title: returnTitleKey('host-approval'), nested: false },
        { key: VISITORLOG_KEYS_ENUM.SIGN_OUT, title: returnTitleKey('sign-out'), nested: false },
        { key: VISITORLOG_KEYS_ENUM.INDUCTION, title: returnTitleKey('induction'), nested: false },
        { key: VISITORLOG_KEYS_ENUM.EVAC_STATUS, title: returnTitleKey('evac-status'), nested: false },
        // default nested columns
        { key: VISITORLOG_KEYS_ENUM.COMPANY, title: returnTitleKey('company'), nested: true },
        { key: VISITORLOG_KEYS_ENUM.LOCATION, title: returnTitleKey('location'), nested: true },
        { key: VISITORLOG_KEYS_ENUM.EMAIL, title: returnTitleKey('email'), nested: true },
        { key: VISITORLOG_KEYS_ENUM.PHONE_NUMBER, title: returnTitleKey('phone-number'), nested: true },
        { key: VISITORLOG_KEYS_ENUM.AGREEMENT, title: returnTitleKey('agreement'), nested: true },
        { key: VISITORLOG_KEYS_ENUM.DELTA_V1, title: returnTitleKey('delta-v1'), nested: true },
      ],
    },

    status: {
      signout: false,
      signoutrevert: false,
      delete: false,
      badgelinking: false,
      idUpdates: false,
      inductionCompletion: false,
      assetSerialNumberUpdates: false,
      fetching: false,
      filtering: false,
    },
  }
}

// Reducer
export default function reducer(state: State = initialState(), action: Action) {
  switch (action.type) {
    case ActionCreators.StoreSetVisitorLogFilteredData.type:
      return update(state, {
        filteredData: { $set: action.payload },
      })
    case ActionCreators.StoreSetVisitorLogData.type:
      return update(state, {
        data: { $set: action.payload },
      })
    case ActionCreators.StoreSetVisitorLogShouldSubscribe.type:
      return update(state, {
        visitorShouldSubscribeToUpdates: { $set: action.payload },
      })
    case ActionCreators.StoreSetVisitorsLogDateRange.type:
      return update(state, {
        dateRanges: { $set: action.payload },
      })
    case ActionCreators.StoreSetVisitorsLogActiveColumnKeys.type:
      return update(state, {
        activeColumnKeys: { $set: action.payload },
      })

    case ActionCreators.StoreSetVisitorsLogColumnList.type:
      return update(state, {
        config: {
          columnList: { $set: action.payload },
        },
      })
    case ActionCreators.StoreSetVisitorLogNotifications.type:
      return update(state, {
        notifications: { $set: action.payload },
      })
    case ActionCreators.StoreSetVisitorLogConfigs.type:
      return update(state, {
        config: { $set: { ...state.config, ...action.payload } },
      })
    case ActionCreators.StoreUpdateVisitorLogStates.type:
      return update(state, {
        status: { $set: { ...state.status, ...action.payload } },
      })
    case ActionCreators.StoreClearAllVisitorsLogData.type:
      return update(state, {
        data: { $set: initialState().data },
      })

    case ActionCreators.ClearAllStates.type:
      return initialState()
    default:
      return state
  }
}

// Selectors
const getVisitorsLogFilteredDataState = (state: RootState) => state.visitors.log.filteredData
export const getVisitorsLogFilteredData = createSelector<RootState, any, State['filteredData']>(
  [getVisitorsLogFilteredDataState],
  (state: State['filteredData']) => {
    return state
  }
)

const getVisitorsLogDataState = (state: RootState) => state.visitors.log.data
export const getVisitorsLogData = createSelector<RootState, any, State['data']>([getVisitorsLogDataState], (state: State['data']) => {
  return state
})

const getVisitorsLogStatusState = (state: RootState) => state.visitors.log.status
export const getVisitorsLogStatus = createSelector<RootState, any, State['status']>(
  [getVisitorsLogStatusState],
  (state: State['status']) => {
    return state
  }
)

const getVisitorsDateRangesState = (state: RootState) => state.visitors.log.dateRanges
export const getVisitorsDateRanges = createSelector<RootState, any, State['dateRanges']>(
  [getVisitorsDateRangesState],
  (state: State['dateRanges']) => {
    return state
  }
)

const getVisitorsActiveColumnKeysState = (state: RootState) => state.visitors.log.activeColumnKeys
export const getVisitorsActiveKeys = createSelector<RootState, any, State['activeColumnKeys']>(
  [getVisitorsActiveColumnKeysState],
  (state: State['activeColumnKeys']) => {
    return state
  }
)

const getVisitorsColumnListState = (state: RootState) => state.visitors.log.config.columnList
export const getVisitorsColumnList = createSelector<RootState, any, State['config']['columnList']>(
  [getVisitorsColumnListState],
  (state: State['config']['columnList']) => {
    return state
  }
)

const getVisitorsLogConfigState = (state: RootState) => state.visitors.log.config
export const getVisitorsLogConfig = createSelector<RootState, any, State['config']>(
  [getVisitorsLogConfigState],
  (state: State['config']) => {
    return state
  }
)

const getVisitorsLogNotificationsItemsState = (state: RootState) => state.visitors.log.notifications
export const getVisitorsLogNotifications = createSelector<RootState, any, State['notifications']>(
  [getVisitorsLogNotificationsItemsState],
  (state: State['notifications']) => {
    return state
  }
)

const getShouldSubscribeToVisitorUpdatesState = (state: RootState) => state.visitors.log.visitorShouldSubscribeToUpdates
export const getShouldSubscribeToVisitorUpdates = createSelector<RootState, any, State['visitorShouldSubscribeToUpdates']>(
  [getShouldSubscribeToVisitorUpdatesState],
  (shouldSubscribeToUpdates: State['visitorShouldSubscribeToUpdates']) => {
    return shouldSubscribeToUpdates
  }
)
