import React from 'react'

interface ErrorFallbackProps {
  message: string
}

export default function ErrorFallback(props: ErrorFallbackProps) {
  return (
    <div
      className='relative border-solid border-t-4 border-b-0 border-l-0 border-r-0 border-orange-500 dark:text-orange-300 text-orange-700 px-4 py-3 shadow-md'
      role='alert'
    >
      <div className='flex'>
        <div className='py-1'>
          <svg className='fill-current h-4 w-4 text-teal-500 mr-2' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'>
            <path d='M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z' />
          </svg>
        </div>
        <div>
          <p className='font-bold mb-0 text-xl leading-relaxed'>Oops!</p>
          <p className='text-sm mb-0'>{props.message}</p>
        </div>
      </div>
      <div className='dark:bg-orange-900 bg-orange-100 absolute w-full h-full left-0 top-0 opacity-20' />
    </div>
  )
}
