import React from 'react'
import styled from 'styled-components'
import Lottie from 'react-lottie'
import animationData from './gears.js'
import MaintenanceImg from './backgrounBase64.js'

const MaintenanceContent = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: #16263d;
  background-image: url(${MaintenanceImg});
  background-repeat: no-repeat;
  background-size: cover;
  .landing-details {
    width: 550px;
    max-width: 100vw;
    padding: 0 60px;
    * {
      color: white !important;
      text-shadow: #16263d 1px 1px 2px;
      font-size: 1rem;
    }
    h1 {
      font-size: 3rem;
    }
  }
  .error-img {
    max-width: 100%;
  }
  @media screen and (max-width: 500px) {
    .landing-details {
      margin: 20px 0;
      padding: 0 5px;
    }
    h1 {
      font-size: 1.5rem;
    }
  }
`

const lottieOptions = {
  loop: false,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
}

const MaintenancePageSFC = (props) => {
  const dims = window.innerWidth * 0.75 < 400 ? window.innerWidth * 0.75 : 200
  return (
    <MaintenanceContent className={props.className}>
      <div className='landing-details'>
        <h1>We’ll be back soon!</h1>
        <p>Sorry for the inconvenience. We’re performing some maintenance at the moment and we’ll be back up shortly!</p>
      </div>
      <Lottie options={lottieOptions} height={dims} width={dims} isStopped={false} isPaused={false} className='error-img' />
    </MaintenanceContent>
  )
}

export default MaintenancePageSFC
