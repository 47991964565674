import { useMemo } from 'react'
import AccessManager from 'src/utils/AccessManager'
import useHierarchyEnabledFlags from './use-hierarchy-enabled-flags'

export function useSelectedHierarchy() {
  const flags = useHierarchyEnabledFlags()
  return useMemo(() => {
    return AccessManager.selectedHierarchy.hierarchyStructure
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flags])
}
