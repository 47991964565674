import { API as AmplifyAPI } from 'aws-amplify'
import AccessManager from 'src/utils/AccessManager'
import LZUTF8 from 'lzutf8'
import { InsertInviteAction, UpdateInviteAction } from 'src/redux/store/visitors/types/manageInvites'

type UpsertData = InsertInviteAction | UpdateInviteAction

const isUpsertInvite = (data: UpsertData): data is UpdateInviteAction => {
  return data.operation === 'update'
}

export const upsertDashboardInvites = (data: UpsertData) => {
  return new Promise((resolve, reject) => {
    const options = {
      headers: {
        Authorization: `Bearer ${AccessManager.idToken}`,
      },
      body: {
        upsert: isUpsertInvite(data)
          ? {
              ...data,
              lmt: Date.now(),
              entityHierarchy: data.entityHierarchy || AccessManager.selectedHierarchy.hierarchyStructure,
              fields: LZUTF8.compress(JSON.stringify(data.fields), {
                outputEncoding: 'StorageBinaryString',
              }),
            }
          : {
              ...data,
              visitors: LZUTF8.compress(JSON.stringify(data.visitors), {
                outputEncoding: 'StorageBinaryString',
              }),
            },
      },
    }
    AmplifyAPI.post('inviteOperations', '/upsertDashboardInvites', options)
      .then((data) => {
        resolve(data)
      })
      .catch((err) => {
        console.error(isUpsertInvite(data) ? 'upsertDashboardInvites: ' : 'upsertDashboardVisitorsInvites', data)
        if (err && err.response && err.response.data) {
          reject(err.response)
        } else {
          reject(err)
        }
      })
  })
}
