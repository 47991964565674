/*eslint no-throw-literal: 0*/
import {
  all,
  fork,
  takeLeading,
  call,
  put,
  delay,
  take,
  race, // call, take, select
} from 'redux-saga/effects'
import ActionCreator from '../saga-action-creator'
import { ActionCreators as SearchInviteHostsStoreActionCreators } from 'src/redux/store/visitors/manageInvites'
import API from 'src/api'
import { HostSearchRequest, InsertInviteAction } from 'src/redux/store/visitors/types/manageInvites'
import { pickBy } from 'lodash'
import { ActionCreators as AuthenticationActionCreators } from 'src/redux/sagas/authentication'
import { upsertDashboardInvites } from 'src/api/inviteOperations/upsertDashboardInvites'
// Action Creators
export const ActionCreators = {
  SagaInviteHostSearchHosts: new ActionCreator<'SagaInviteHostSearchHosts', HostSearchRequest>('SagaInviteHostSearchHosts'),
  SagaAddInvites: new ActionCreator<'SagaAddInvites', InsertInviteAction>('SagaAddInvites'),
}

const AWSHelper = new API.AWSHelpers()

function* executeSagaVisitorSearchHosts(action) {
  try {
    yield put(AuthenticationActionCreators.SagaRefreshApiCredentials.create())
    yield take(AuthenticationActionCreators.SagaApiCredentialsRefreshUpdate.type)
    const searchResult: any = yield call(
      [AWSHelper, AWSHelper.searchHosts],
      action.payload.hostLinkingField.searchFieldName,
      action.payload.value,
      action.payload.entityHierarchy
    )

    let resultObjects: Array<any> = []
    if (
      searchResult.responses &&
      searchResult.responses.length > 0 &&
      searchResult.responses[0].hits &&
      searchResult.responses[0].hits.hits &&
      searchResult.responses[0].hits.hits.length > 0
    ) {
      resultObjects = searchResult.responses[0].hits.hits.map((searchResult) => searchResult._source)
    }

    resultObjects = resultObjects.sort((a, b) => (a.name >= b.name ? 1 : -1))
    yield put(SearchInviteHostsStoreActionCreators.StoreInviteHostSearchUpdateHostsResults.create(resultObjects))
    if (action.callback) action.callback({ status: 'success' })
  } catch (e) {
    if (e) {
      console.error(e)
    }
    //TODO - Align error codes and timeout & network checks with browser approach
    const errorMessage = e && e.message ? e.message : 'undefined error message'
    const errorCode = e && e.code ? e.code : 0
    if (errorMessage.toLowerCase().indexOf('network error') > -1) {
      throw {
        message: 'offline',
        code: 999,
      }
    } else if (errorMessage.toLowerCase().indexOf('timeout') > -1) {
      throw {
        message: 'timeout',
        code: 999,
      }
    } else {
      throw {
        message: errorMessage,
        code: errorCode,
      }
    }
  }
}

function* processInviteHostSearch(action) {
  try {
    const { timeout } = yield race({
      process: call(executeSagaVisitorSearchHosts, action),
      timeout: delay(10000),
    })
    if (timeout) {
      throw {
        message: 'timeout',
        code: 999,
      }
    }
  } catch (e) {
    const errorMessage = e && e.message ? e.message : ''
    // const errorCode = e && e.code ? e.code : 0
    const resultObjects: Array<any> = []
    if (errorMessage.toLowerCase().indexOf('offline') > -1 || errorMessage.toLowerCase().indexOf('network error') > -1) {
      resultObjects.push({
        name: 'Network unavailable',
        uniqueAttributeValue: '00000000',
        errorItem: true,
      }) //add not on list item
    } else if (errorMessage.toLowerCase().indexOf('timeout') > -1) {
      resultObjects.push({
        name: 'Search timed out',
        uniqueAttributeValue: '00000000',
        errorItem: true,
      }) //add not on list item
    }
    if (action.callback)
      action.callback({
        status: 'failed',
        message: resultObjects.length > 0 ? JSON.stringify(resultObjects) : errorMessage.length > 0 && errorMessage,
      })
  }
}

function* processAddInvites(action) {
  try {
    yield put(AuthenticationActionCreators.SagaRefreshApiCredentials.create())
    yield take(AuthenticationActionCreators.SagaApiCredentialsRefreshUpdate.type)
    const dashboardEvent = action.payload
    const removeUndefinedValues = (object) => {
      return pickBy(object, (v: any) => !(v === undefined || v.length === 0))
    }
    const upsertObject = {
      ...dashboardEvent,
      host: removeUndefinedValues(dashboardEvent.host),
      visitors: dashboardEvent.visitors.map((visitor) => removeUndefinedValues(visitor)).filter(Boolean),
    }

    const result = yield call([AWSHelper, upsertDashboardInvites], upsertObject)
    if (result.key === 'OPERATION_PROCESSED') {
      if (action.callback) action.callback({ status: 'success' })
    } else {
      throw result
    }
  } catch (e) {
    if (e) console.error(e)
    if (action.callback) {
      action.callback({
        status: 'failed',
        message: e?.data?.message ? e.data.message : undefined,
      })
    }
  }
}

// Saga triggers
function* watchInviteHostSearchSagas() {
  yield takeLeading(ActionCreators.SagaInviteHostSearchHosts.type, processInviteHostSearch)
  yield takeLeading(ActionCreators.SagaAddInvites.type, processAddInvites)
  yield null
}

// Saga hooks
export default function* inviteHostSearchSagas() {
  yield all([fork(watchInviteHostSearchSagas)])
}
