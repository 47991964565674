import { Avatar, Col, Divider, Input, Row, Select } from 'antd'
import React from 'react'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import ErrorBoundary from 'src/components/error-boundary'
import Fieldset from 'src/components/fieldset'
import FormField from 'src/components/r-form/form-field'
import { useLegacyLayout } from 'src/components/legacy-layout-provider'
import LocaleSwitch from 'src/components/locale-switch'
import Page from 'src/components/page'
import config from 'src/config'
import { getUserProfile } from 'src/redux/store/profile'
import { cssvars } from 'src/styles'
import { getProfileInitials } from '../Dashboard/Header/User'
export default function Profile() {
  const { umName, umEmail } = useSelector(getUserProfile)
  const intl = useIntl()
  const { currentLayout, setCurrentLayout } = useLegacyLayout()

  const handleLegacySwitch = (value: any) => {
    setCurrentLayout(value)
  }

  return (
    <ErrorBoundary>
      <Page>
        <Fieldset style={{ width: 500, maxWidth: '100%', margin: 'auto' }}>
          <Row justify='center' align='middle'>
            <Col xs={18}>
              <Fieldset.Title title='Account Settings' />
            </Col>
            <Col xs={6} style={{ textAlign: 'right' }}>
              <Avatar
                style={{
                  color: cssvars.textColor,
                  background: cssvars.bodyBackground,
                  width: 100,
                  height: 100,
                  fontSize: 40,
                  lineHeight: '100px',
                }}
              >
                {getProfileInitials(umName)}
              </Avatar>
            </Col>
          </Row>
          <Divider />

          <FormField label={intl.formatMessage({ id: 'common.titles.name' })}>
            <Input size='large' disabled value={umName} style={{ width: '100%' }} />
          </FormField>
          <FormField label={intl.formatMessage({ id: 'common.email' })}>
            <Input size='large' disabled value={umEmail} style={{ width: '100%' }} />
          </FormField>
          {config.localeEnabled ? (
            <FormField label={intl.formatMessage({ id: 'common.titles.language' })}>
              <LocaleSwitch />
            </FormField>
          ) : null}

          <FormField label='Layout'>
            <Select defaultValue={currentLayout} value={currentLayout} onSelect={handleLegacySwitch} size='large'>
              <Select.Option value='legacy'>Legacy</Select.Option>
              <Select.Option value='latest'>Latest</Select.Option>
            </Select>
          </FormField>
        </Fieldset>
      </Page>
    </ErrorBoundary>
  )
}
