//node
import { faCode } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Divider, Table, Tabs } from 'antd'
import React, { useState } from 'react'
import Fieldset from 'src/components/fieldset'
import Page from 'src/components/page'
import Markdown from 'src/components/markdown'

const { TabPane } = Tabs

const pageMarkdown = `
\`\`\`jsx
import Page from 'src/components/page'

<Page title='Page Title'>
  <Tabs defaultActiveKey='1'>
    <TabPane tab='Tab 1' key='1'>
      Content of Tab Pane 1
    </TabPane>
    <TabPane tab='Tab 2' key='2'>
      Content of Tab Pane 2
    </TabPane>
    <TabPane tab='Tab 3' key='3'>
      Content of Tab Pane 3
    </TabPane>
  </Tabs>
</Page>
\`\`\`

`

const pageData = [
  {
    property: 'title',
    description: 'Title of the the page',
    type: 'string',
    default: '-',
  },
  {
    property: 'cta',
    description: 'Call To Action on the right side aligned to title',
    type: 'string | ReactNode',
    default: '-',
  },
  {
    property: 'children',
    description: 'Page content body',
    type: 'string | ReactNode',
    default: '-',
  },
]
const pageTitleData = [
  {
    property: 'title | children',
    description: 'Title of the the page',
    type: 'string',
    default: '-',
  },
  {
    property: 'cta',
    description: 'Call To Action on the right side aligned to title',
    type: 'string | ReactNode',
    default: '-',
  },
]

const apiColumns = [
  {
    title: 'Property',
    dataIndex: 'property',
    key: 'property',
    width: 200,
    render: (value) => <b>{value}</b>,
  },
  {
    title: 'Description',
    dataIndex: 'description',
    key: 'description',
  },
  {
    title: 'Type',
    dataIndex: 'type',
    key: 'type',
    width: 200,
    render: (value) => <span style={{ color: '#c41d7f' }}>{value}</span>,
  },
  {
    title: 'Default',
    dataIndex: 'default',
    key: 'default',
  },
]

const PageWithTabs = () => {
  const [codeVisible, setVisible] = useState(false)
  return (
    <>
      <Page title='Page Title'>
        <Tabs defaultActiveKey='1'>
          <TabPane tab='Tab 1' key='1'>
            Content of Tab Pane 1
          </TabPane>
          <TabPane tab='Tab 2' key='2'>
            Content of Tab Pane 2
          </TabPane>
          <TabPane tab='Tab 3' key='3'>
            Content of Tab Pane 3
          </TabPane>
        </Tabs>
      </Page>
      <div className={'flex align-middle ' + (codeVisible ? 'justify-end' : 'justify-center')}>
        <button
          onClick={() => setVisible((flag) => !flag)}
          className='p-2 border-none rounded cursor-pointer bg-vars-bodyBackground hover:text-primary transition-color'
        >
          <FontAwesomeIcon icon={faCode} />
          <span className='ml-1'>{codeVisible ? 'hide' : 'show'} code</span>
        </button>
      </div>
      {codeVisible && <Markdown source={pageMarkdown} />}
    </>
  )
}

const PageWithCta = () => (
  <Page title='Page Title' extra={<Button type='primary'>Page CTA</Button>}>
    My page content here
  </Page>
)

const ExamplePages = () => {
  return (
    <>
      <Page title='Page'>
        <p>Layout pages used as parent in each view</p>
        <br />
        <Fieldset>
          <Fieldset.Title>Examples</Fieldset.Title>
          <Divider orientation='left'>TABS</Divider>
          <PageWithTabs />
          <br />
          <Divider orientation='left'>CTA</Divider>
          <PageWithCta />
        </Fieldset>
      </Page>

      <Page title='API'>
        <h3>Page</h3>
        <Table dataSource={pageData} columns={apiColumns} bordered pagination={false} scroll={{ x: '100vw' }} />
        <br />
        <h3>Page Header</h3>
        <Table dataSource={pageTitleData} columns={apiColumns} bordered pagination={false} scroll={{ x: '100vw' }} />
      </Page>
    </>
  )
}

export default ExamplePages
