/* eslint-disable no-async-promise-executor */
/* eslint-disable @typescript-eslint/no-unused-vars */

import { API as AmplifyAPI } from 'aws-amplify'
import LZUTF8 from 'lzutf8'
import aws_exports from 'src/aws-exports'
import { initialState as getInitialConfigurationState } from 'src/redux/store/configuration'
import AccessManager from 'src/utils/AccessManager'
import getEventTiming from 'src/utils/getEventTiming'
import {
  getCredentialedDDBDocumentClientForMutate,
  getCredentialedDDBDocumentClientForRead,
  getCredentialedDDBDocumentClientGeneric,
  getCredentialedParentS3,
  getCredentialedS3,
  getTableNameFromAwsExports,
} from './utils'
import employeeApi from './workerLogOperations'

export { getCurrentServiceCredentials, setCurrentServiceCredentials } from './utils'

export default class AWSHelpers {
  retrieveHierarchyConfiguration() {
    return new Promise(async (resolve, reject) => {
      try {
        let options = {
          headers: {
            Authorization: `Bearer ${AccessManager.idToken}`,
          },
          body: {},
        }
        AmplifyAPI.post('hierarchyOperations', '/retrieveHierarchyConfiguration', options)
          .then((data) => {
            resolve(data)
          })
          .catch((err) => {
            if (err && err.response && err.response.data) {
              reject(err.response)
            } else {
              reject(err)
            }
          })
      } catch (e) {
        reject(e)
      }
    })
  }

  retrieveEntityConfig() {
    return new Promise((resolve, reject) => {
      try {
        if (AccessManager.selectedHierarchy.hierarchyStructure !== 'ALL') {
          const params = {
            RequestItems: {
              [getTableNameFromAwsExports('mdConfigTexts')]: {
                Keys: [{ EntityHierarchy: AccessManager.selectedHierarchy.hierarchyStructure }],
              },
              [getTableNameFromAwsExports('mdConfigFeatures')]: {
                Keys: [{ EntityHierarchy: AccessManager.selectedHierarchy.hierarchyStructure }],
              },
              [getTableNameFromAwsExports('mdConfigImages')]: {
                Keys: [{ EntityHierarchy: AccessManager.selectedHierarchy.hierarchyStructure }],
              },
              [getTableNameFromAwsExports('mdConfigBrandingColors')]: {
                Keys: [{ EntityHierarchy: AccessManager.selectedHierarchy.hierarchyStructure }],
              },
              [getTableNameFromAwsExports('mdConfigInduction')]: {
                Keys: [{ EntityHierarchy: AccessManager.selectedHierarchy.hierarchyStructure }],
              },
              [getTableNameFromAwsExports('tdActiveEvacuations')]: {
                Keys: [{ EntityHierarchy: AccessManager.selectedHierarchy.hierarchyStructure }],
              },
            },
          }
          const ddbDocumentClient = getCredentialedDDBDocumentClientForRead(AccessManager.selectedHierarchy.hierarchyStructure)
          ddbDocumentClient.batchGet(params, async (err, data) => {
            if (err) {
              reject(err)
            } else {
              const initialState = getInitialConfigurationState()
              let tenantConfig = {
                values: initialState.values,
                configurability: initialState.configurability,
              }
              if (data && data.Responses) {
                for (let key in data.Responses) {
                  if (data.Responses.hasOwnProperty(key)) {
                    const response = data.Responses[key]
                    if (key.indexOf('mdConfigTexts') > -1) {
                      if (response.length > 0) {
                        const responseValues = response[0]
                        for (let valueKey in tenantConfig.values.texts) {
                          if (tenantConfig.values.texts.hasOwnProperty(valueKey) && responseValues.hasOwnProperty(valueKey)) {
                            tenantConfig.values.texts[valueKey] = responseValues[valueKey]
                          }
                        }
                      }
                    }
                    if (key.indexOf('mdConfigFeatures') > -1) {
                      if (response.length > 0) {
                        const responseValues = response[0]
                        for (let valueKey in tenantConfig.values.featureConfig) {
                          if (tenantConfig.values.featureConfig.hasOwnProperty(valueKey) && responseValues.hasOwnProperty(valueKey)) {
                            tenantConfig.values.featureConfig[valueKey] = responseValues[valueKey]
                          }
                        }
                      }
                    }
                    if (key.indexOf('mdConfigImages') > -1) {
                      if (response.length > 0) {
                        const responseValues = response[0]
                        for (let valueKey in tenantConfig.values.images) {
                          if (tenantConfig.values.images.hasOwnProperty(valueKey) && responseValues.hasOwnProperty(valueKey)) {
                            tenantConfig.values.images[valueKey] = responseValues[valueKey]
                          }
                        }
                      }
                    }
                    if (key.indexOf('mdConfigBrandingColors') > -1) {
                      if (response.length > 0) {
                        const responseValues = response[0]
                        for (let valueKey in tenantConfig.values.brandingColors) {
                          if (tenantConfig.values.brandingColors.hasOwnProperty(valueKey) && responseValues.hasOwnProperty(valueKey)) {
                            tenantConfig.values.brandingColors[valueKey] = responseValues[valueKey]
                          }
                        }
                      }
                    }
                    if (key.indexOf('mdConfigInduction') > -1) {
                      if (response.length > 0) {
                        const responseValues = response[0]
                        for (let valueKey in tenantConfig.values.induction) {
                          if (tenantConfig.values.induction.hasOwnProperty(valueKey) && responseValues.hasOwnProperty(valueKey)) {
                            tenantConfig.values.induction[valueKey] = responseValues[valueKey]
                          }
                        }
                      }
                    }
                    if (key.indexOf('mdConfigLocation') > -1) {
                      if (response.length > 0) {
                        const responseValues = response[0]
                        for (let valueKey in tenantConfig.values.locationConfig) {
                          if (tenantConfig.values.locationConfig.hasOwnProperty(valueKey) && responseValues.hasOwnProperty(valueKey)) {
                            tenantConfig.values.locationConfig[valueKey] = responseValues[valueKey]
                          }
                        }
                      }
                    }
                    if (key.indexOf('tdActiveEvacuations') > -1) {
                      if (response.length > 0) {
                        const responseValues = response[0]
                        for (let valueKey in tenantConfig.values.evacuation) {
                          if (tenantConfig.values.evacuation.hasOwnProperty(valueKey) && responseValues.hasOwnProperty(valueKey)) {
                            tenantConfig.values.evacuation[valueKey] = responseValues[valueKey]
                          }
                        }
                      }
                    }
                  }
                }
              }
              if (tenantConfig.values.images.lmt > 0) {
                tenantConfig.values.images.logoImage = await this.getPresignedS3URLForLogoImageGet()
              }

              resolve(tenantConfig)
            }
          })
        } else {
          reject('No hierarchy selected')
        }
      } catch (e) {
        reject(e)
      }
    })
  }

  saveConfigTexts(texts) {
    return new Promise((resolve, reject) => {
      const textParams = {
        Item: {
          EntityHierarchy: AccessManager.selectedHierarchy.hierarchyStructure,
          ...(texts.companyName.length > 0 && {
            companyName: texts.companyName,
          }),
          ...(texts.welcomeMessage.length > 0 && {
            welcomeMessage: texts.welcomeMessage,
          }),
          showTextsOnLanding: texts.showTextsOnLanding,
        },
        TableName: getTableNameFromAwsExports('mdConfigTexts'),
        ReturnValues: 'NONE',
      }
      const ddbDocumentClient = getCredentialedDDBDocumentClientForMutate(AccessManager.selectedHierarchy.hierarchyStructure)
      ddbDocumentClient.put(textParams, function (errProfile, dataProfile) {
        if (errProfile) {
          reject(errProfile)
        } else {
          resolve(dataProfile)
        }
      })
    })
  }

  saveConfigFeatures(features) {
    return new Promise((resolve, reject) => {
      const featureParams = {
        Item: {
          EntityHierarchy: AccessManager.selectedHierarchy.hierarchyStructure,
          badgePrintingEnabled: features.badgePrintingEnabled,
          badgePrintingAutoCut: features.badgePrintingAutoCut,
          badgeReprintAllowed: features.badgeReprintAllowed,
          voiceFeedbackEnabled: features.voiceFeedbackEnabled,
        },
        TableName: getTableNameFromAwsExports('mdConfigFeatures'),
        ReturnValues: 'NONE',
      }
      const ddbDocumentClient = getCredentialedDDBDocumentClientForMutate(AccessManager.selectedHierarchy.hierarchyStructure)
      ddbDocumentClient.put(featureParams, function (errProfile, dataProfile) {
        if (errProfile) {
          reject(errProfile)
        } else {
          resolve(dataProfile)
        }
      })
    })
  }

  saveLogoImage(logoImage) {
    return new Promise((resolve, reject) => {
      const objectKey = `companylogos/${AccessManager.selectedHierarchy.hierarchyStructure.replace(/#/g, '_')}.png`
      const imgBuffer = new Buffer(logoImage.replace(/^data:image\/png;base64,/, ''), 'base64')
      var params = {
        Body: imgBuffer,
        Bucket: aws_exports.aws_entity_binary_content_s3_bucket,
        Key: objectKey,
        Metadata: {
          EntityHierarchy: AccessManager.selectedHierarchy.hierarchyStructure,
        },
        Tagging: `EntityHierarchy=${AccessManager.selectedHierarchy.hierarchyStructure.replace(/#/g, '/')}`,
      }
      const s3 = getCredentialedS3(AccessManager.selectedHierarchy.hierarchyStructure)
      s3.putObject(params, function (err) {
        if (err) {
          reject(err)
        } else {
          resolve()
        }
      })
    })
  }

  saveConfigImages(dn) {
    return new Promise((resolve, reject) => {
      const imageParams = {
        Item: {
          EntityHierarchy: AccessManager.selectedHierarchy.hierarchyStructure,
          lmt: dn,
        },
        TableName: getTableNameFromAwsExports('mdConfigImages'),
        ReturnValues: 'NONE',
      }
      const ddbDocumentClient = getCredentialedDDBDocumentClientForMutate(AccessManager.selectedHierarchy.hierarchyStructure)
      ddbDocumentClient.put(imageParams, function (errProfile, dataProfile) {
        if (errProfile) {
          reject(errProfile)
        } else {
          resolve(dataProfile)
        }
      })
    })
  }

  saveConfigBrandingColors(brandingColors) {
    return new Promise((resolve, reject) => {
      const brandingColorsParams = {
        Item: {
          EntityHierarchy: AccessManager.selectedHierarchy.hierarchyStructure,
          primary: brandingColors.primary,
          tertiary: brandingColors.tertiary,
          secondary: brandingColors.secondary,
          text: brandingColors.text,
        },
        TableName: getTableNameFromAwsExports('mdConfigBrandingColors'),
        ReturnValues: 'NONE',
      }
      const ddbDocumentClient = getCredentialedDDBDocumentClientForMutate(AccessManager.selectedHierarchy.hierarchyStructure)
      ddbDocumentClient.put(brandingColorsParams, function (errProfile, dataProfile) {
        if (errProfile) {
          reject(errProfile)
        } else {
          resolve(dataProfile)
        }
      })
    })
  }

  saveConfigLocation(locations) {
    return new Promise((resolve, reject) => {
      const locationsParams = {
        Item: {
          EntityHierarchy: AccessManager.selectedHierarchy.hierarchyStructure,
          tododesign: locations.tododesign,
        },
        TableName: getTableNameFromAwsExports('mdConfigLocation'),
        ReturnValues: 'NONE',
      }
      const ddbDocumentClient = getCredentialedDDBDocumentClientForMutate(AccessManager.selectedHierarchy.hierarchyStructure)
      ddbDocumentClient.put(locationsParams, function (errProfile, dataProfile) {
        if (errProfile) {
          reject(errProfile)
        } else {
          resolve(dataProfile)
        }
      })
    })
  }

  // deactivateEvacuation(activationHierarchies, ) {
  //   return new Promise(async (resolve, reject) => {
  //     try {
  //       const asyncOps = []
  //       const activationResult = {
  //         success: [],
  //         failed: [],
  //       }
  //       for (const activationHierarchy in activationHierarchies) {
  //         const update_Evac = {
  //           TableName: getTableNameFromAwsExports('tdActiveEvacuations'),
  //           Key: {
  //             EntityHierarchy: activationHierarchy,
  //           },
  //           UpdateExpression: 'set active = :active, lmt = :lmt',
  //           ExpressionAttributeValues: {
  //             ':active': false,
  //             ':lmt': Date.now(),
  //             ':currentlmt': activationHierarchies[activationHierarchy].lmt,
  //             ':initiator': AccessManager.tokenDetails.sub,
  //           },
  //           ReturnValues: 'ALL_NEW',
  //           ConditionExpression: 'initiator = :initiator AND lmt = :currentlmt',
  //         }
  //         const ddbDocumentClient = getCredentialedDDBDocumentClientForMutate(activationHierarchy)
  //         asyncOps.push(
  //           new Promise((resolve) => {
  //             ddbDocumentClient.update(update_Evac, function (err, updateItem) {
  //               if (err) {
  //                 activationResult.failed.push({
  //                   EntityHierarchy: activationHierarchy,
  //                   error: err,
  //                 })
  //               } else {
  //                 activationResult.success.push(updateItem)
  //               }
  //               resolve()
  //             })
  //           })
  //         )
  //       }
  //       await Promise.all(asyncOps)
  //       resolve(activationResult)
  //     } catch (e) {
  //       reject(e)
  //     }
  //   })
  // }

  getVisitorlogEvents(beginTime, endTime, entityHierarchy, entityHierarchyConfiguration, ProjectionExpression = undefined) {
    return new Promise(async (resolve) => {
      let hierarchies = []
      if (entityHierarchy === 'ALL' && entityHierarchyConfiguration && entityHierarchyConfiguration.tenantFlags) {
        hierarchies = entityHierarchyConfiguration.tenantFlags.map((availableEntity) => availableEntity.EntityHierarchy)
      } else if (
        entityHierarchyConfiguration &&
        entityHierarchyConfiguration.hierarchy.flatStructure[entityHierarchy].children.length > 0
      ) {
        const addRecursive = (additionalChildren) => {
          for (const prop of additionalChildren) {
            hierarchies.push(prop.hierarchyStructure)
            if (prop.children && prop.children.length > 0) {
              addRecursive(prop.children)
            }
          }
        }
        hierarchies.push(entityHierarchy)
        addRecursive(entityHierarchyConfiguration.hierarchy.flatStructure[entityHierarchy].children)
      } else {
        hierarchies.push(entityHierarchy)
      }
      let options = {
        headers: {
          Authorization: `Bearer ${AccessManager.idToken}`,
        },
        body: {
          query: {
            hierarchies,
            beginTime,
            endTime,
            ProjectionExpression,
          },
        },
      }
      try {
        let response = await AmplifyAPI.post('visitorLogOperations', '/retrieveVisitorLog', options)
        if (response.key === 'ASYNC_RESPONSE_PAYLOAD') {
          const asyncResponsePayload = await fetch(response.s3PresignedURL)
          response = await asyncResponsePayload.json()
        }
        resolve(response)
      } catch (e) {
        resolve({ Items: [] })
      }
    })
  }

  getSelectedVisitorlogs(itemsToRetrieve) {
    return new Promise(async (resolve) => {
      try {
        let options = {
          headers: {
            Authorization: `Bearer ${AccessManager.idToken}`,
          },
          body: {
            query: {
              getItemKeys: itemsToRetrieve,
            },
          },
        }
        try {
          let response = await AmplifyAPI.post('visitorLogOperations', '/retrieveVisitorLog', options)
          if (response.key === 'ASYNC_RESPONSE_PAYLOAD') {
            const asyncResponsePayload = await fetch(response.s3PresignedURL)
            response = await asyncResponsePayload.json()
          }
          resolve(response)
        } catch (e) {
          resolve({ Items: [] })
        }
      } catch (e) {
        resolve({ Items: [] })
      }
    })
  }

  getUserlogEvents(beginTime, endTime, entityHierarchy) {
    return new Promise(async (resolve) => {
      // let hierarchies = []
      // hierarchies.push(entityHierarchy)
      // const dynamodbDocumentClient = new DynamoDB.DocumentClient({
      //   region: aws_exports.aws_cognito_region,
      // })
      // const asyncOps = []
      // hierarchies.forEach(hierarchy => {
      //   const paramsEvent = {
      //     ExpressionAttributeValues: {
      //       ":e": hierarchy,
      //       ":bt": beginTime,
      //       ":et": endTime,
      //     },
      //     KeyConditionExpression: "EntityHierarchy = :e AND evtTimeStamp BETWEEN :bt AND :et",
      //     ProjectionExpression: "EntityHierarchy, eventType, evtTimeStamp, userEmail, userTerminalId",
      //     IndexName: "EntityHierarchyHASH-evtTimeStampRANGE",
      //     TableName: getTableNameFromAwsExports("tdUserLog"),
      //   }
      //   asyncOps.push(dynamodbDocumentClient.query(paramsEvent).promise())
      // })
      // const asyncResults = await Promise.all(asyncOps)
      // let allResults = []
      // asyncResults.forEach(asyncResult => {
      //   allResults = allResults.concat(asyncResult.Items)
      // })
      // resolve(allResults)
      resolve()
    })
  }

  logUserlogEvent(event, logTime, terminalId, sub, email, OrganizationId, MTHA) {
    return new Promise((resolve, reject) => {
      const userlogParams = {
        Item: {
          OrganizationId,
          evtTimeStampEmail: `${logTime}#${email}`,
          evtTimeStamp: logTime,
          email,
          event,
          terminalId,
          sub,
          MTHA,
        },
        TableName: getTableNameFromAwsExports('tdOrganizationUserLog'),
        ReturnValues: 'NONE',
      }
      const dynamodbDocumentClient = getCredentialedDDBDocumentClientGeneric('organization')
      dynamodbDocumentClient.put(userlogParams, function (errUserlog, dataUserlog) {
        if (errUserlog) {
          reject(errUserlog)
        } else {
          resolve(dataUserlog)
        }
      })
    })
  }

  getPresignedS3URLForLogoImageGet() {
    return new Promise((resolve, reject) => {
      if (AccessManager.selectedHierarchy.hierarchyStructure !== 'ALL') {
        const paramsLogoImage = {
          Bucket: aws_exports.aws_entity_binary_content_s3_bucket,
          Key: `companylogos/${AccessManager.selectedHierarchy.hierarchyStructure.replace(/#/g, '_')}.png`,
        }
        const s3 = getCredentialedS3(AccessManager.selectedHierarchy.hierarchyStructure)
        s3.getSignedUrl('getObject', paramsLogoImage, (err, url) => {
          if (err) {
            reject(err)
          } else {
            resolve(url)
          }
        })
      } else {
        resolve('')
      }
    })
  }

  getPresignedS3URLForUserfilesGet(path, key, entityHierarchy) {
    return new Promise((resolve, reject) => {
      const imagePath = `${path}${key}`
      const paramsImg = {
        Bucket: aws_exports.aws_user_files_s3_bucket,
        Key: imagePath,
      }
      const s3 = getCredentialedS3(entityHierarchy)
      s3.getSignedUrl('getObject', paramsImg, (err, url) => {
        if (err) {
          reject(err)
        } else {
          resolve(url)
        }
      })
    })
  }

  getPresignedS3URLForInductionVideoGet(channel, version) {
    return new Promise((resolve, reject) => {
      const paramsVideo = {
        Bucket: aws_exports.aws_entity_binary_content_s3_bucket,
        Key: `inductionvideos/${AccessManager.selectedHierarchy.hierarchyStructure.replace(/#/g, '_')}_${channel}_${version}.mp4`,
      }
      const s3 = getCredentialedS3(AccessManager.selectedHierarchy.hierarchyStructure)
      s3.getSignedUrl('getObject', paramsVideo, (err, url) => {
        if (err) {
          reject(err)
        } else {
          resolve(url)
        }
      })
    })
  }

  getPresignedS3driversLicensePhotoURLForGet(path, key, entityHierarchy) {
    return new Promise((resolve, reject) => {
      const imagePath = `${path}${key}`
      const paramsImg = {
        Bucket: aws_exports.aws_parking_files_s3_bucket,
        Key: imagePath,
      }
      const s3 = getCredentialedParentS3(entityHierarchy)
      s3.getSignedUrl('getObject', paramsImg, (err, url) => {
        if (err || !url || typeof url !== 'string') {
          reject(err)
        } else {
          resolve(url)
        }
      })
    })
  }

  signOutVisitors(userData) {
    return new Promise((resolve, reject) => {
      const eventTiming = getEventTiming()
      let options = {
        headers: {
          Authorization: `Bearer ${AccessManager.idToken}`,
        },
        body: {
          userData,
          signOutSource: 'DB',
          evtTimeStampOut: eventTiming.timeSinceEpoch,
          evtTZOffsetOut: eventTiming.timezoneOffset,
          evtTimeZoneOut: eventTiming.timeZone,
        },
      }
      AmplifyAPI.post('visitorLogOperations', '/signOutVisitors', options)
        .then((data) => {
          resolve(data)
        })
        .catch((err) => {
          if (err && err.response && err.response.data) {
            reject(err.response)
          } else {
            reject(err)
          }
        })
    })
  }

  revertSignOut(EntityHierarchy, evtTimeStampProfileID) {
    return new Promise((resolve, reject) => {
      let options = {
        headers: {
          Authorization: `Bearer ${AccessManager.idToken}`,
        },
        body: {
          EntityHierarchy,
          evtTimeStampProfileID,
        },
      }
      AmplifyAPI.post('visitorLogOperations', '/revertSignOut', options)
        .then((data) => {
          resolve(data)
        })
        .catch((err) => {
          if (err && err.response && err.response.data) {
            reject(err.response)
          } else {
            reject(err)
          }
        })
    })
  }

  deleteUsers(logEntries) {
    return new Promise((resolve, reject) => {
      let options = {
        headers: {
          Authorization: `Bearer ${AccessManager.idToken}`,
        },
        body: {
          logEntries,
        },
      }
      AmplifyAPI.post('visitorLogOperations', '/deleteLogEntries', options)
        .then((data) => {
          resolve(data)
        })
        .catch((err) => {
          if (err && err.response && err.response.data) {
            reject(err.response)
          } else {
            reject(err)
          }
        })
    })
  }

  generateVisitorAgreementRequest(EntityHierarchy, ProfileId, AgreementVersion, signatureTimeStamp) {
    return new Promise((resolve, reject) => {
      let options = {
        headers: {
          Authorization: `Bearer ${AccessManager.idToken}`,
        },
        body: {
          responseType: 'PDF',
          EntityHierarchy,
          ProfileId,
          AgreementVersion: `${AgreementVersion}`,
          ...(signatureTimeStamp ? { signatureTimeStamp } : {}),
        },
      }
      AmplifyAPI.post('profileOperations', '/generateVisitorAgreement', options)
        .then((data) => {
          resolve(data)
        })
        .catch((err) => {
          if (err && err.response && err.response.data) {
            reject(err.response)
          } else {
            reject(err)
          }
        })
    })
  }

  updateVisitorCustomFieldValue(customFieldUpdate) {
    return new Promise((resolve, reject) => {
      let options = {
        headers: {
          Authorization: `Bearer ${AccessManager.idToken}`,
        },
        body: {
          eventTiming: getEventTiming(),
          customFieldUpdate,
        },
      }
      AmplifyAPI.post('visitorLogOperations', '/updateVisitorCustomFieldValue', options)
        .then((data) => {
          resolve(data)
        })
        .catch((err) => {
          if (err && err.response && err.response.data) {
            reject(err.response)
          } else {
            reject(err)
          }
        })
    })
  }

  captureVisitorBadgeNumber(badgeData) {
    return new Promise((resolve, reject) => {
      let options = {
        headers: {
          Authorization: `Bearer ${AccessManager.idToken}`,
        },
        body: {
          ...badgeData,
        },
      }
      AmplifyAPI.post('visitorLogOperations', '/captureVisitorBadgeNumber', options)
        .then((data) => {
          resolve(data)
        })
        .catch((err) => {
          if (err && err.response && err.response.data) {
            reject(err.response)
          } else {
            reject(err)
          }
        })
    })
  }

  setInductionCompleteInScreeningRoom(inductionData) {
    return new Promise((resolve, reject) => {
      const eventTiming = getEventTiming()
      let options = {
        headers: {
          Authorization: `Bearer ${AccessManager.idToken}`,
        },
        body: {
          inductionData,
          eventTiming,
        },
      }
      AmplifyAPI.post('visitorLogOperations', '/setInductionCompleteInScreeningRoom', options)
        .then((data) => {
          resolve({
            ...data,
            eventTiming,
          })
        })
        .catch((err) => {
          if (err && err.response && err.response.data) {
            reject(err.response)
          } else {
            reject(err)
          }
        })
    })
  }

  updatePersonalIdentificationNr(idUpdate) {
    return new Promise((resolve, reject) => {
      const eventTiming = getEventTiming()
      let options = {
        headers: {
          Authorization: `Bearer ${AccessManager.idToken}`,
        },
        body: {
          idUpdate,
          eventTiming,
        },
      }
      AmplifyAPI.post('visitorLogOperations', '/updatePersonalIdentificationNr', options)
        .then((data) => {
          resolve(data)
        })
        .catch((err) => {
          if (err && err.response && err.response.data) {
            reject(err.response)
          } else {
            reject(err)
          }
        })
    })
  }

  updateAssetSerialNumber(assetSerialNumberUpdate) {
    return new Promise((resolve, reject) => {
      const eventTiming = getEventTiming()
      let options = {
        headers: {
          Authorization: `Bearer ${AccessManager.idToken}`,
        },
        body: {
          assetSerialNumberUpdate,
          eventTiming,
        },
      }
      AmplifyAPI.post('visitorLogOperations', '/updateAssetSerialNumber', options)
        .then((data) => {
          resolve(data)
        })
        .catch((err) => {
          if (err && err.response && err.response.data) {
            reject(err.response)
          } else {
            reject(err)
          }
        })
    })
  }

  retrieveEntityHierarchyAccessCredential(service) {
    return new Promise((resolve, reject) => {
      let options = {
        headers: {
          Authorization: `Bearer ${AccessManager.idToken}`,
        },
        body: {
          service,
          source: 'dashboard',
        },
      }
      AmplifyAPI.post('userOperations', '/retrieveEntityHierarchyAccessCredential', options)
        .then((data) => {
          resolve(data)
        })
        .catch((err) => {
          if (err && err.response && err.response.data) {
            reject(err.response)
          } else {
            reject(err)
          }
        })
    })
  }

  retrieveSubscribeCredential(topics, clientId) {
    return new Promise((resolve, reject) => {
      let options = {
        headers: {
          Authorization: `Bearer ${AccessManager.idToken}`,
        },
        body: {
          topics,
          clientId,
        },
      }

      AmplifyAPI.post('userOperations', '/retrieveSubscribeCredential', options)
        .then((data) => {
          resolve(data)
        })
        .catch((err) => {
          if (err && err.response && err.response.data) {
            reject(err.response)
          } else {
            reject(err)
          }
        })
    })
  }

  getEventInvitationInstances(beginTime, endTime, entityHierarchy, entityHierarchyConfiguration, readFromArchive = false) {
    return new Promise(async (resolve) => {
      let hierarchies = []
      if (entityHierarchy === 'ALL') {
        hierarchies = entityHierarchyConfiguration.tenantFlags.map((availableEntity) => availableEntity.EntityHierarchy)
      } else if (entityHierarchyConfiguration.hierarchy.flatStructure[entityHierarchy].children.length > 0) {
        const addRecursive = (additionalChildren) => {
          for (const prop of additionalChildren) {
            hierarchies.push(prop.hierarchyStructure)
            if (prop.children.length > 0) {
              addRecursive(prop.children)
            }
          }
        }
        hierarchies.push(entityHierarchy)
        addRecursive(entityHierarchyConfiguration.hierarchy.flatStructure[entityHierarchy].children)
      } else {
        hierarchies.push(entityHierarchy)
      }
      let options = {
        headers: {
          Authorization: `Bearer ${AccessManager.idToken}`,
        },
        body: {
          query: {
            hierarchies,
            beginTime,
            endTime,
            readFromArchive,
          },
        },
      }
      try {
        let response = await AmplifyAPI.post('inviteOperations', '/retrieveInviteLog', options)
        if (response.key === 'ASYNC_RESPONSE_PAYLOAD') {
          const asyncResponsePayload = await fetch(response.s3PresignedURL)
          response = await asyncResponsePayload.json()
        }
        resolve(response)
      } catch (e) {
        resolve({ Items: [] })
      }
    })
  }

  markArrivedInvitee(inviteeData, dn) {
    return new Promise((resolve, reject) => {
      const dynamodbDocumentClient = getCredentialedDDBDocumentClientForMutate(inviteeData.EntityHierarchy)
      const eventTiming = getEventTiming()
      var updateParams = {
        TableName: getTableNameFromAwsExports('tdEventInvitationInstances'),
        Key: { EntityHierarchy: inviteeData.EntityHierarchy, EventUidEmail: inviteeData.EventUidEmail },
        UpdateExpression: 'set #a = :a, #b = :b, #c = :c, #d = :d, #e = :e',
        ExpressionAttributeNames: {
          '#a': 'lmt',
          '#b': 'evtTimeStampArrival',
          '#c': 'evtTZOffsetArrival',
          '#d': 'evtTimeZoneArrival',
          '#e': 'arrivalSource',
        },
        ExpressionAttributeValues: {
          ':a': dn,
          ':b': eventTiming.timeSinceEpoch,
          ':c': eventTiming.timezoneOffset,
          ':d': eventTiming.timeZone,
          ':e': 'DASHBOARD',
          ':f': inviteeData.lmt,
        },
        ReturnValues: 'NONE',
        ConditionExpression: 'attribute_exists(EntityHierarchy) AND attribute_exists(EventUidEmail) AND (lmt = :f)',
      }
      dynamodbDocumentClient.update(updateParams, function (err) {
        if (err) {
          reject(err)
        } else {
          resolve({
            key: 'MARK_ARRIVED_SUCCESS',
            eventTiming,
          })
        }
      })
    })
  }

  unmarkArrivedInvitee(inviteeData, dn) {
    return new Promise((resolve, reject) => {
      const dynamodbDocumentClient = getCredentialedDDBDocumentClientForMutate(inviteeData.EntityHierarchy)
      const eventTiming = getEventTiming()
      var updateParams = {
        TableName: getTableNameFromAwsExports('tdEventInvitationInstances'),
        Key: { EntityHierarchy: inviteeData.EntityHierarchy, EventUidEmail: inviteeData.EventUidEmail },
        UpdateExpression: 'set #a = :a remove #b, #c, #d, #e',
        ExpressionAttributeNames: {
          '#a': 'lmt',
          '#b': 'evtTimeStampArrival',
          '#c': 'evtTZOffsetArrival',
          '#d': 'evtTimeZoneArrival',
          '#e': 'arrivalSource',
        },
        ExpressionAttributeValues: {
          ':a': dn,
          ':b': 'DASHBOARD',
          ':c': inviteeData.lmt,
        },
        ReturnValues: 'NONE',
        ConditionExpression:
          'attribute_exists(EntityHierarchy) AND attribute_exists(EventUidEmail) AND (arrivalSource = :b) AND (lmt = :c)',
      }
      dynamodbDocumentClient.update(updateParams, function (err) {
        if (err) {
          reject(err)
        } else {
          resolve({
            key: 'UNMARK_ARRIVED_SUCCESS',
            eventTiming,
          })
        }
      })
    })
  }

  resendInvite(inviteKey) {
    return new Promise((resolve, reject) => {
      let options = {
        headers: {
          Authorization: `Bearer ${AccessManager.idToken}`,
        },
        body: {
          inviteKey,
        },
      }
      AmplifyAPI.post('inviteOperations', '/resendInvite', options)
        .then((data) => {
          resolve(data)
        })
        .catch((err) => {
          if (err && err.response && err.response.data) {
            reject(err.response)
          } else {
            reject(err)
          }
        })
    })
  }

  async deleteInvite(inviteKey) {
    try {
      return AmplifyAPI.post('inviteOperations', '/deleteInvite', {
        headers: {
          Authorization: `Bearer ${AccessManager.idToken}`,
        },
        body: {
          inviteKey,
        },
      })
    } catch (error) {
      if (error && error.response && error.response.data) {
        throw error.response
      }
      throw error
    }
  }

  manageAPIKeys(keyType, operation, currentUserCredentials) {
    return new Promise((resolve, reject) => {
      let options = {
        headers: {
          Authorization: `Bearer ${currentUserCredentials.idToken}`,
        },
        body: {
          keyType,
          operation,
        },
      }
      AmplifyAPI.post('userOperations', '/manageAPIKeys', options)
        .then((data) => {
          resolve(data)
        })
        .catch((err) => {
          if (err && err.response && err.response.data) {
            reject(err.response)
          } else {
            reject(err)
          }
        })
    })
  }

  getHostsDirectory(requestedHierarchy) {
    const createBatch = (batchHierarchy, batchUniqueAttributeValue) => {
      const paramsEvent = {
        ExpressionAttributeValues: {
          ':hk': batchHierarchy,
          ':rk': batchUniqueAttributeValue,
        },
        ConsistentRead: true,
        KeyConditionExpression: 'EntityHierarchy = :hk AND uniqueAttributeValue > :rk',
        TableName: getTableNameFromAwsExports('mdSearchSourceHostLinking'),
      }
      const dynamodbDocumentClient = getCredentialedDDBDocumentClientForRead(batchHierarchy)
      return dynamodbDocumentClient.query(paramsEvent).promise()
    }

    const processBatch = (batch, depth) => {
      return new Promise(async (resolve) => {
        let batchResults = []
        let nextBatch = []
        const asyncResults = await Promise.all(batch)
        asyncResults.forEach((asyncResult) => {
          batchResults = batchResults.concat(asyncResult.Items)
          if (asyncResult.hasOwnProperty('LastEvaluatedKey')) {
            const { EntityHierarchy, uniqueAttributeValue } = asyncResult.LastEvaluatedKey
            nextBatch.push(createBatch(EntityHierarchy, uniqueAttributeValue))
          }
        })
        if (nextBatch.length > 0) {
          const nextBatchResults = await processBatch(nextBatch, depth + 1)
          batchResults = batchResults.concat(nextBatchResults)
        }

        resolve(batchResults)
      })
    }

    return new Promise(async (resolve) => {
      const initialBatch = []
      initialBatch.push(createBatch(requestedHierarchy, '0'))
      let allResults = await processBatch(initialBatch, 1)
      resolve(allResults)
    })
  }

  addHosts(EntityHierarchy, hostsToAdd) {
    return new Promise((resolve, reject) => {
      let options = {
        headers: {
          Authorization: `Bearer ${AccessManager.idToken}`,
        },
        body: {
          operations: {
            EntityHierarchy,
            upserts: LZUTF8.compress(JSON.stringify(hostsToAdd), {
              outputEncoding: 'StorageBinaryString',
            }),
          },
        },
      }
      AmplifyAPI.post('directoryOperations', '/updateHostDirectory', options)
        .then((data) => {
          resolve(data)
        })
        .catch((err) => {
          if (err && err.response && err.response.data) {
            reject(err.response)
          } else {
            reject(err)
          }
        })
    })
  }

  deleteHosts(EntityHierarchy, hostsToDelete) {
    return new Promise((resolve, reject) => {
      let options = {
        headers: {
          Authorization: `Bearer ${AccessManager.idToken}`,
        },
        body: {
          operations: {
            EntityHierarchy,
            deletes: LZUTF8.compress(JSON.stringify(hostsToDelete), {
              outputEncoding: 'StorageBinaryString',
            }),
          },
        },
      }
      AmplifyAPI.post('directoryOperations', '/updateHostDirectory', options)
        .then((data) => {
          resolve(data)
        })
        .catch((err) => {
          if (err && err.response && err.response.data) {
            reject(err.response)
          } else {
            reject(err)
          }
        })
    })
  }

  deleteEntityHostList(EntityHierarchy) {
    return new Promise((resolve, reject) => {
      let options = {
        headers: {
          Authorization: `Bearer ${AccessManager.idToken}`,
        },
        body: {
          operations: {
            EntityHierarchy,
            deleteEntityHostList: true,
          },
        },
      }
      AmplifyAPI.post('directoryOperations', '/updateHostDirectory', options)
        .then((data) => {
          resolve(data)
        })
        .catch((err) => {
          if (err && err.response && err.response.data) {
            reject(err.response)
          } else {
            reject(err)
          }
        })
    })
  }

  resetHostSyncStatus(EntityHierarchy, uniqueAttributeValue) {
    return new Promise((resolve, reject) => {
      let options = {
        headers: {
          Authorization: `Bearer ${AccessManager.idToken}`,
        },
        body: {
          operations: {
            EntityHierarchy,
            resetHostSyncStatus: uniqueAttributeValue,
          },
        },
      }
      AmplifyAPI.post('directoryOperations', '/updateHostDirectory', options)
        .then((data) => {
          resolve(data)
        })
        .catch((err) => {
          if (err && err.response && err.response.data) {
            reject(err.response)
          } else {
            reject(err)
          }
        })
    })
  }

  getParkingLog(beginTime, endTime, entityHierarchy, entityHierarchyConfiguration) {
    const createBatch = (batchBeginTime, batchEndTime, batchHierarchy) => {
      try {
        const paramsEvent = {
          ExpressionAttributeValues: {
            ':e': batchHierarchy,
            ':bt': `${batchBeginTime}`,
            ':et': `${batchEndTime}`,
          },
          KeyConditionExpression: 'EntityHierarchy = :e AND evtTimeStampUniqueAttributeValue BETWEEN :bt AND :et',
          TableName: getTableNameFromAwsExports('tdParkingEvents'),
        }
        const dynamodbDocumentClient = getCredentialedDDBDocumentClientForRead(batchHierarchy)
        return dynamodbDocumentClient.query(paramsEvent).promise()
      } catch (e) {
        if (e.message === 'Not Authorized') {
          return { Items: [] }
        } else {
          throw e
        }
      }
    }

    const processBatch = (batch, depth) => {
      return new Promise(async (resolve, reject) => {
        try {
          let batchResults = []
          let nextBatch = []
          const asyncResults = await Promise.all(batch)
          asyncResults.forEach((asyncResult) => {
            batchResults = batchResults.concat(asyncResult.Items)
            if (asyncResult.hasOwnProperty('LastEvaluatedKey')) {
              const hierarchy = asyncResult.LastEvaluatedKey.EntityHierarchy
              const lastTimeStamp = parseInt(asyncResult.LastEvaluatedKey.evtTimeStampUniqueAttributeValue.split('#')[0], 10) + 1
              nextBatch.push(createBatch(lastTimeStamp, endTime, hierarchy))
            }
          })
          if (nextBatch.length > 0) {
            const nextBatchResults = await processBatch(nextBatch, depth + 1)
            batchResults = batchResults.concat(nextBatchResults)
          }
          resolve(batchResults)
        } catch (e) {
          reject(e)
        }
      })
    }

    return new Promise(async (resolve, reject) => {
      try {
        let hierarchies = []
        if (entityHierarchy === 'ALL') {
          hierarchies = entityHierarchyConfiguration.tenantFlags.map((availableEntity) => availableEntity.EntityHierarchy)
        } else if (entityHierarchyConfiguration.hierarchy.flatStructure[entityHierarchy].children.length > 0) {
          const addRecursive = (additionalChildren) => {
            for (const prop of additionalChildren) {
              hierarchies.push(prop.hierarchyStructure)
              if (prop.children.length > 0) {
                addRecursive(prop.children)
              }
            }
          }
          hierarchies.push(entityHierarchy)
          addRecursive(entityHierarchyConfiguration.hierarchy.flatStructure[entityHierarchy].children)
        } else {
          hierarchies.push(entityHierarchy)
        }
        const initialBatch = []
        const uniqueHierarchies = [...new Set(hierarchies)]
        uniqueHierarchies.forEach((hierarchy) => {
          initialBatch.push(createBatch(beginTime, endTime, hierarchy))
        })
        let allResults = await processBatch(initialBatch, 1)
        resolve(allResults)
      } catch (e) {
        reject(e)
      }
    })
  }

  getSelectedParkinglogs(itemsToRetrieve) {
    const createBatch = (batchETUAV, batchHierarchy) => {
      const paramsEvent = {
        Key: {
          EntityHierarchy: batchHierarchy,
          evtTimeStampUniqueAttributeValue: `${batchETUAV}`,
        },
        TableName: getTableNameFromAwsExports('tdParkingEvents'),
      }
      const dynamodbDocumentClient = getCredentialedDDBDocumentClientForRead(batchHierarchy)
      return dynamodbDocumentClient.get(paramsEvent).promise()
    }

    const processBatch = (batch) => {
      return new Promise(async (resolve) => {
        let batchResults = []
        const asyncResults = await Promise.all(batch)
        asyncResults.forEach((asyncResult) => {
          batchResults = batchResults.concat(asyncResult.Item)
        })
        resolve(batchResults)
      })
    }

    return new Promise(async (resolve) => {
      const initialBatch = []
      itemsToRetrieve.forEach((item) => {
        initialBatch.push(createBatch(item.evtTimeStampUniqueAttributeValue, item.EntityHierarchy))
      })
      let allResults = await processBatch(initialBatch)
      resolve(allResults)
    })
  }

  searchHosts(hostField, hostValue, EntityHierarchy) {
    return new Promise((resolve, reject) => {
      let options = {
        headers: {
          Authorization: `Bearer ${AccessManager.idToken}`,
        },
        body: {
          field: hostField,
          value: hostValue,
          EntityHierarchy,
        },
      }
      AmplifyAPI.post('searchOperations', '/searchHosts/hostlinking', options)
        .then((data) => {
          resolve(data)
        })
        .catch((err) => {
          if (err && err.response && err.response.data) {
            reject(err.response)
          } else {
            reject(err)
          }
        })
    })
  }

  retrieveParkingBooking(bookingKey) {
    const paramsEvent = {
      Key: bookingKey,
      TableName: getTableNameFromAwsExports('tdParkingBookings'),
    }
    const dynamodbDocumentClient = getCredentialedDDBDocumentClientForRead(bookingKey.ManagementHierarchy)
    return dynamodbDocumentClient.get(paramsEvent).promise()
  }

  getSelectedParkingBookings(itemsToRetrieve) {
    const createBatch = (batchETUAV, batchHierarchy) => {
      const paramsEvent = {
        Key: {
          ManagementHierarchy: batchHierarchy,
          BookingRefCode: `${batchETUAV}`,
        },
        TableName: getTableNameFromAwsExports('tdParkingBookings'),
      }
      const dynamodbDocumentClient = getCredentialedDDBDocumentClientForRead(batchHierarchy)
      return dynamodbDocumentClient.get(paramsEvent).promise()
    }

    const processBatch = (batch) => {
      return new Promise(async (resolve) => {
        let batchResults = []
        const asyncResults = await Promise.all(batch)
        asyncResults.forEach((asyncResult) => {
          batchResults = batchResults.concat(asyncResult.Item)
        })
        resolve(batchResults)
      })
    }

    return new Promise(async (resolve) => {
      const initialBatch = []
      itemsToRetrieve.forEach((item) => {
        initialBatch.push(createBatch(item.BookingRefCode, item.ManagementHierarchy))
      })
      let allResults = await processBatch(initialBatch)
      resolve(allResults)
    })
  }

  employeeApi = employeeApi
}
