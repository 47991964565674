import React, { createContext, ReactNode, useMemo } from 'react'
import { useEntityHierarchyConfig } from 'src/hooks/use-entity-hierarchy-config'
import { getEvacuationStatusConfig } from './utils/get-evacuation-status-config'

type EvacuationConfig = ReturnType<typeof getEvacuationStatusConfig>

type IEvacuationContext = {
  [key: string]: EvacuationConfig
  default: EvacuationConfig
}

const Context = createContext<IEvacuationContext>({} as IEvacuationContext)

export const useEvacuationStatus = (EntityHierarchy: string) => {
  const context = React.useContext(Context)
  if (!context) {
    throw new Error('useEvacuationStatus must be used within a EvacuationProvider')
  }

  return context[EntityHierarchy] || context.default
}

const EvacuationProvider = ({ children }: { children: ReactNode }) => {
  const ehConfig = useEntityHierarchyConfig()

  const evacuationConfig = useMemo(() => {
    return Object.keys(ehConfig.additionalConfigs.evacuation).reduce(
      (acc, key) => {
        const config = getEvacuationStatusConfig(ehConfig.additionalConfigs.evacuation, key)
        return {
          ...acc,
          [key]: config,
        }
      },
      {
        default: getEvacuationStatusConfig(ehConfig.additionalConfigs.evacuation, 'default'),
      } as IEvacuationContext
    )
  }, [ehConfig.additionalConfigs.evacuation])

  return <Context.Provider value={evacuationConfig}>{children}</Context.Provider>
}

export default EvacuationProvider
