import defaultAntLocale from 'antd/es/locale/en_GB'
import { Locale as AntLocales } from 'antd/lib/locale-provider'
import moment from 'moment'
// import af_ZA from './af_ZA.json'
import en_GB from './en_GB.json'
// import fr_FR from './fr_FR.json'
// import pt_PT from './pt_PT.json'
// import zu_ZA from './zu_ZA.json'

moment.locale('en_GB')
// moment.locale('af_ZA')
// moment.locale('fr')
// moment.locale('pt_PT')
// moment.locale('zu_ZA')

const messages = {
  en_GB,
  // af_ZA,
  // fr_FR,
  // pt_PT,
  // zu_ZA,
} as const

export const antdLocales: Record<keyof typeof messages, AntLocales> = {
  en_GB: defaultAntLocale,
  // af_ZA: defaultAntLocale,
  // fr_FR: require('antd/es/locale/fr_FR'),
  // pt_PT: require('antd/es/locale/pt_PT'),
  // zu_ZA: defaultAntLocale,
}

export const defaultLocale = 'en_GB' as const

export const languageNames = {
  en_GB: 'English',
  // af_ZA: 'Afrikaans',
  // fr_FR: 'Français',
  // pt_PT: 'Portuguesa',
  // zu_ZA: 'IsiZulu',
} as const

export default messages
