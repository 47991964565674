import { ArrowLeftOutlined } from '@ant-design/icons'
import { Button, Form, Input, message, Select, Space, Tag } from 'antd'
import { Store } from 'antd/lib/form/interface'
import { isEqual } from 'lodash'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import Guard from 'src/components/guard'
import Page from 'src/components/page'
import PhoneNumberInput from 'src/components/phone-number-input'
import config from 'src/config'
import useHierarchyEnabledFlags from 'src/hooks/use-hierarchy-enabled-flags'
import { useRouter } from 'src/hooks/use-router'
import getIntl from 'src/i18n'
import { getEntityHierarchyConfiguration } from 'src/redux/store/credentials'
import { EmployeeDetails } from 'src/redux/store/employees/types/directory'
import AccessManager from 'src/utils/AccessManager'
import { checkValidEmail, phoneValidator } from 'src/utils/validation'
// fields that are not in use but can easily be added to view
// import PhoneNumberInput from 'src/components/PhoneNumberInput'
// import { phoneValidator } from 'src/utils/validation'
import { EmployeeSagaActions } from './saga'
import { normalizeToUpperCase, requiredRule } from './utils/form'
import { tenantFlagHelper } from 'src/redux/store/features/tenant-flag-helper'

const layout = {
  labelCol: { xs: 24, sm: 6 },
  wrapperCol: { xs: 24, sm: 18 },
}

const actionWrapperCol = {
  xs: 24,
  sm: {
    offset: 6,
    span: 18,
  },
}

const getInitialValues = (employee?: EmployeeDetails) => {
  if (!employee) return undefined
  const {
    firstName = '',
    lastName = '',
    email = '',
    organizationIdValue = '',
    personalIdentificationNr = '',
    alternatePersonalIdNr = '',
    flowTypeRef = { flowId: '' },
    phoneNumber = '',
    lineManagers = [],
    tags = [],
  } = employee
  return {
    firstName,
    lastName,
    organizationIdValue,
    personalIdentificationNr,
    alternatePersonalIdNr,
    flowId: flowTypeRef?.flowId || '',
    email,
    phoneNumber,
    lineManagers,
    tags,
  }
}

const parseData = (data: Record<string, string>) => {
  const values = {}
  Object.keys(data).forEach((key) => {
    if (data[key]) {
      if (key === 'email') {
        values[key] = data[key].toLowerCase()
      } else {
        values[key] = data[key]
      }
    }
  })
  return values
}

interface EditEmployee {
  goBack: () => void
  employee?: EmployeeDetails
  dataSource: EmployeeDetails[]
}
export default function ManageEmployee(props: EditEmployee) {
  const [form] = Form.useForm()
  const [guardVisible, setGuardVisible] = useState(false)
  const intl = getIntl()
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [selectedFlowType, setSelectedFlowType] = useState('')
  const isAdd = !props.employee
  const entityHierarchyConfig = useSelector(getEntityHierarchyConfiguration)
  const hierarchyEnabledFlags = useHierarchyEnabledFlags()
  const router = useRouter()

  const duplicateMetaFieldsAllowed = useMemo(() => {
    if (!selectedFlowType) return false
    return Boolean(
      tenantFlagHelper.getEmployeeDuplicateMetaFieldsAllowed(
        entityHierarchyConfig,
        AccessManager.selectedHierarchy.hierarchyStructure,
        selectedFlowType
      )
    )
  }, [entityHierarchyConfig, selectedFlowType])

  const flowTypes = useMemo(() => {
    const flows = entityHierarchyConfig.additionalConfigs.flowTypes[
      props.employee?.EntityHierarchy || AccessManager.selectedHierarchy.hierarchyStructure
    ]
      .filter((flow) => flow.disabled !== true && flow.flowNature === 1)
      .sort((a, b) => a.sortIndex - b.sortIndex)
      .map((flowtype) => ({
        label: intl.formatMessage({ id: flowtype.texts.flowDescriptionTextId }),
        flowId: flowtype.flowId,
        flowNature: flowtype.flowNature,
      }))
    return flows
  }, [entityHierarchyConfig, intl, props.employee])

  const duplicates = useMemo(() => {
    return props.dataSource.reduce<any>(reduceDuplicatesChecklist, {}) as ReturnType<typeof reduceDuplicatesChecklist>
  }, [props.dataSource])

  useEffect(() => {
    const initialValues = getInitialValues(props.employee)
    if (initialValues) form.setFieldsValue(initialValues)
    return () => form.resetFields()
  }, [form, props.employee])

  const handleSave = async ({ flowId, ...data }: Store) => {
    setLoading(true)
    const flowTypeRef = flowTypes.find((flow) => flow.flowId === flowId)
    const payload = {
      flowTypeRef: {
        flowId: flowTypeRef?.flowId,
        flowNature: flowTypeRef?.flowNature,
      },
      ...parseData(data),
    } as EmployeeDetails

    const dispatchEmployeeAdd = EmployeeSagaActions.SagaAddSingleEmployee.withCallback({
      payload,
      callback: (res) => {
        setLoading(false)
        props.goBack()
        if (res.status !== 'success') message.error(res.message || 'We were unable to add the employee')
      },
    })

    const dispatchEmployeeEdit = EmployeeSagaActions.SagaEditEmployee.withCallback({
      payload,
      callback: (res) => {
        setLoading(false)
        props.goBack()
        if (res.status === 'success' && res.isAsyncProcessing)
          message.warn(
            'The directory is being updated asyncronously due to the large upload size. Please check back in a few minutes to confirm complete processing.',
            5
          )
        if (res.status !== 'success') message.error(res.message || 'We were unable to update the employee')
      },
    })

    dispatch(isAdd ? dispatchEmployeeAdd : dispatchEmployeeEdit)
  }

  const handleSetFlowType = useCallback(
    (value) => {
      const flowType = flowTypes.find((flow) => flow.flowId === value)
      if (flowType) {
        const { flowId, flowNature } = flowType
        form.setFieldsValue({
          flowId,
          flowNature,
        })
      }
    },
    [form, flowTypes]
  )

  const handleBack = () => {
    if (form.isFieldsTouched()) setGuardVisible(true)
    else props.goBack()
  }

  const initialValues = getInitialValues(props.employee)

  return (
    <Page
      title={`${isAdd ? 'Add' : 'Modify'} ${intl
        .formatMessage({ id: `common.${hierarchyEnabledFlags.employeeRouteType}-singular` })
        .toLowerCase()}`}
      extra={
        <Button type='primary' ghost icon={<ArrowLeftOutlined />} onClick={handleBack}>
          Directory
        </Button>
      }
    >
      {config.helpCenter && (
        <p className='max-w-5xl pt-2'>
          <FormattedMessage id='kenai.visitors.invites.manage.header.body' />{' '}
          <a href={`https://kenai.co.za/help?lang=${intl.locale}`}>Learn more</a>
        </p>
      )}
      <div className='max-w-2xl pt-8'>
        <Form
          form={form}
          initialValues={initialValues}
          {...layout}
          labelAlign='left'
          size='large'
          onFinish={handleSave}
          onFinishFailed={({ errorFields }) => form.scrollToField(errorFields[0].name)}
        >
          <Form.Item
            name='flowId'
            label={`${intl.formatMessage({ id: `common.${hierarchyEnabledFlags.employeeRouteType}-singular` })} Type`}
          >
            <Select onChange={handleSetFlowType} className='cursor-pointer' onSelect={(val) => setSelectedFlowType(val)}>
              {flowTypes.map((flowType, i) => (
                <Select.Option key={i} value={flowType.flowId}>
                  {flowType.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item name='firstName' label={intl.formatMessage({ id: 'common.firstName' })} rules={[requiredRule('First name')]}>
            <Input className='data-hj-suppress rr-block ' />
          </Form.Item>
          <Form.Item name='lastName' rules={[requiredRule('Last name')]} label={intl.formatMessage({ id: 'common.lastName' })}>
            <Input className='data-hj-suppress rr-block ' />
          </Form.Item>
          <Form.Item
            name='personalIdentificationNr'
            label={intl.formatMessage({ id: 'personIDValue' })}
            rules={[
              requiredRule('Personal identification number'),
              {
                validator(_rule, value) {
                  if (
                    value !== initialValues?.personalIdentificationNr &&
                    duplicates.personalIdentificationNr?.includes((value || '')?.toLowerCase?.())
                  ) {
                    return Promise.reject(`The ${intl.formatMessage({ id: 'personIDValue' })} is already in use`)
                  }
                  return Promise.resolve()
                },
              },
            ]}
            normalize={normalizeToUpperCase}
          >
            <Input disabled={!!props.employee} className='data-hj-suppress rr-block ' />
          </Form.Item>
          {hierarchyEnabledFlags.employeeAlternatePersonalIdNr && (
            <Form.Item
              name='alternatePersonalIdNr'
              label={intl.formatMessage({ id: 'alternatePersonalIdNr' })}
              rules={[
                requiredRule('Alternate Personal identification number'),
                {
                  validator(_rule, value) {
                    if (
                      value !== initialValues?.alternatePersonalIdNr &&
                      duplicates.alternatePersonalIdNr?.includes((value || '')?.toLowerCase?.())
                    ) {
                      return Promise.reject(`The ${intl.formatMessage({ id: 'alternatePersonalIdNr' })} is already in use`)
                    }
                    return Promise.resolve()
                  },
                },
              ]}
              normalize={normalizeToUpperCase}
            >
              <Input disabled={!!props.employee} className='data-hj-suppress rr-block ' />
            </Form.Item>
          )}
          <Form.Item
            name='organizationIdValue'
            label={intl.formatMessage({ id: 'organizationIdValue' })}
            rules={[
              requiredRule('The organization id is a required field'),
              {
                validator(_rule, value) {
                  if (
                    value !== initialValues?.organizationIdValue &&
                    duplicates.organizationIdValue?.includes((value || '').toLowerCase())
                  ) {
                    return Promise.reject(`The ${intl.formatMessage({ id: 'organizationIdValue' })} is already in use`)
                  }
                  return Promise.resolve()
                },
              },
            ]}
            normalize={normalizeToUpperCase}
          >
            <Input disabled={!!props.employee} className='data-hj-suppress rr-block ' />
          </Form.Item>
          <Form.Item
            name='email'
            label={intl.formatMessage({ id: 'common.email' })}
            rules={[
              { type: 'email', message: 'The email is not a valid format (eg; handler@domain.com)' },
              ...(!duplicateMetaFieldsAllowed
                ? [
                    {
                      validator(_rule, value) {
                        if (value !== initialValues?.email && duplicates.email?.includes((value || '')?.toLowerCase?.())) {
                          return Promise.reject(`This email is already in use.`)
                        }
                        return Promise.resolve()
                      },
                    },
                  ]
                : []),
            ]}
            normalize={(val) => val?.toLowerCase().replace(/\s/g, '')}
          >
            <Input className='lowercase data-hj-suppress rr-block ' />
          </Form.Item>
          <Form.Item
            name='phoneNumber'
            label={intl.formatMessage({ id: 'common.phoneNumber' })}
            rules={[
              { validator: phoneValidator },
              ...(!duplicateMetaFieldsAllowed
                ? [
                    {
                      validator(_rule, value) {
                        if (value !== initialValues?.phoneNumber && duplicates.phoneNumber?.includes((value || '')?.toLowerCase?.())) {
                          return Promise.reject(`The ${intl.formatMessage({ id: 'common.phoneNumber' })} is already in use`)
                        }
                        return Promise.resolve()
                      },
                    },
                  ]
                : []),
            ]}
          >
            <PhoneNumberInput />
          </Form.Item>

          {hierarchyEnabledFlags.employeeTags && (
            <Form.Item
              name='tags'
              label='Employee Tags'
              help='You can separate tags by commas or semicolons'
              normalize={(tags: string[]) => tags.map((val) => (val || '').toUpperCase())}
            >
              <Select
                mode='tags'
                dropdownClassName='data-hj-suppress rr-block '
                className='data-hj-suppress rr-block '
                tokenSeparators={[',', ';']}
                notFoundContent={null}
              />
            </Form.Item>
          )}

          {hierarchyEnabledFlags.employeeLineManagers && (
            <Form.Item
              name='lineManagers'
              label='Line Managers'
              rules={[
                {
                  validator: (_, value?: string[]) => {
                    if (!value || !value?.[0]) return Promise.resolve()
                    const valid = value.map((val) => !checkValidEmail(val || '')).filter(Boolean)?.length === 0
                    if (value.some((val, i) => value.indexOf(val) !== i)) return Promise.reject('Duplicate emails found')
                    if (valid) return Promise.resolve()
                    else return Promise.reject('One or more emails are not valid')
                  },
                  validateTrigger: 'onChange',
                },
              ]}
              // help='You can separate line managers by commas or semicolons'
            >
              <Select
                mode='tags'
                dropdownClassName='data-hj-suppress rr-block '
                className='data-hj-suppress rr-block '
                notFoundContent={null}
                tokenSeparators={[',', ';']}
              />
            </Form.Item>
          )}

          <Form.Item shouldUpdate wrapperCol={actionWrapperCol}>
            {({ isFieldsTouched, getFieldsError, getFieldsValue }) => {
              const hasErrors = getFieldsError().filter((field) => field.errors.length > 0).length > 0
              const fieldValues = getFieldsValue([
                'flowId',
                'firstName',
                'lastName',
                'personalIdentificationNr',
                'organizationIdValue',
                ...(hierarchyEnabledFlags.employeeAlternatePersonalIdNr ? ['alternatePersonalIdNr'] : []),
              ])

              let requiredFieldsFilled = true
              for (const key in fieldValues) {
                if ((fieldValues.hasOwnProperty(key) && !fieldValues[key]) || fieldValues[key]?.length === 0) {
                  requiredFieldsFilled = false
                  break
                }
              }

              const optionFieldValues = getFieldsValue(['phoneNumber', 'email', 'lineManagers'])

              const formHasChanged = !isAdd ? !isEqual(getInitialValues(props.employee), { ...fieldValues, ...optionFieldValues }) : true

              const isDirty = isFieldsTouched([
                'flowId',
                'firstName',
                'lastName',
                'personalIdentificationNr',
                'alternatePersonalIdNr',
                'organizationIdValue',
                'email',
                'phoneNumber',
                'lineManagers',
                'tags',
              ])

              return (
                <>
                  <div className='flex'>
                    <div className='grid place-center'>
                      <Space direction='vertical'>
                        {!isDirty && <Tag className='text-vars-textColor'>No changes to submit</Tag>}
                        {hasErrors && <Tag className='text-vars-textColor'>Cannot submit due to field errors</Tag>}
                        {isDirty && !requiredFieldsFilled && (
                          <Tag className='text-vars-textColor'>One or more required fields have not been filled</Tag>
                        )}
                      </Space>
                    </div>
                    <Space className='flex justify-end flex-grow'>
                      <Button danger className='no-border' onClick={handleBack} disabled={loading} size='middle'>
                        <FormattedMessage id='common.cancel' />
                      </Button>
                      <Button
                        type='primary'
                        disabled={hasErrors || !isDirty || !formHasChanged || !requiredFieldsFilled}
                        loading={loading}
                        size='middle'
                        htmlType='submit'
                      >
                        {isAdd ? 'Add' : 'Save'}
                      </Button>
                    </Space>
                  </div>
                  <Guard.Route
                    when={isDirty || formHasChanged}
                    navigate={(path) => {
                      if (path === router.location.pathname) {
                        props.goBack()
                      } else {
                        router.push(path)
                      }
                    }}
                    shouldBlockNavigation={() => true}
                  />
                </>
              )
            }}
          </Form.Item>
        </Form>
      </div>

      <Guard.Modal visible={guardVisible} onCancel={() => setGuardVisible(false)} onOk={() => props.goBack()} />
    </Page>
  )
}

const reduceDuplicatesChecklist = (acc, val) => {
  Object.keys(val).forEach((key) => {
    if (typeof acc[key] === 'undefined') {
      acc[key] = []
    }
    acc[key].push(val[key]?.toLowerCase?.())
  })
  return acc as Record<keyof EmployeeDetails, string[]>
}
