import { TableColumnFilterIcon } from './table-column'
import { uniqueFilterArray } from './utils'
import React from 'react'
import { ColumnType } from 'antd/lib/table'

const TableColumnFilter = () => {
  // TODO custom component?
  return null
}

export const filterColumnOptions = <T extends any>(options: NonNullable<Pick<ColumnType<T>, 'onFilter' | 'filters'>>) => {
  return {
    filterIcon: (filtered: boolean) => <TableColumnFilterIcon type={'filter'} filtered={filtered} />,
    onFilter: options.onFilter,
    filters: uniqueFilterArray(options.filters),
  }
}

TableColumnFilter.columnOptions = filterColumnOptions

export default TableColumnFilter
