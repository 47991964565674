import { cloneDeep } from 'lodash'
import { calculateInviteStringForItem } from 'src/redux/sagas/reporting/helper/generateInviteSearchString'
import { ActionCreator } from '../store-action-creator'

// Action Creators
export const ActionCreators = {
  StoreSetUserlogData: new ActionCreator('StoreSetUserlogData'),
  StoreSetInvitelogData: new ActionCreator('[deprecated]_StoreSetInvitelogData'),
  StoreClearAllReportingData: new ActionCreator('StoreClearAllReportingData'),
  UpdateInviteMarkArrivedlogData: new ActionCreator('[deprecated]_UpdateInviteMarkArrivedlogData'),
  UpdateInviteUnmarkArrivedlogData: new ActionCreator('[deprecated]_UpdateInviteUnmarkArrivedlogData'),
  UpdateLogStates: new ActionCreator('UpdateLogStates'),
  ClearAllStates: new ActionCreator('ClearAllStates'),
  UpdateInductionData: new ActionCreator('UpdateInductionData'),
}

export const initialState = () => {
  return {
    userlogItems: undefined,
    inviteShouldSubscribeToUpdates: false,
    invitelogItems: undefined,
    invitelogStates: {
      arrival: { loading: false, result: '' },
      arrivalrevert: { loading: false, result: '' },
    },
  }
}

// Reducer
export default function reducer(state = initialState(), action) {
  let partialState = {}
  ///////////
  // SET LOGS
  ///////////
  // SET USERS LOG
  if (action.type === ActionCreators.StoreSetUserlogData.type) {
    partialState = { userlogItems: action.payload }
  }
  // SET INVITE LOG
  if (action.type === ActionCreators.StoreSetInvitelogData.type) {
    partialState = {
      invitelogItems: action.payload,
    }
  }
  //////////////////////
  // UPDATE LOGS STATES
  ////////////////////
  if (action.type === ActionCreators.UpdateLogStates.type) {
    partialState = {
      [`${action.payload.logType}States`]: {
        ...state[`${action.payload.logType}States`],
        [action.payload.state]: {
          loading: action.payload.loading,
          result: action.payload.result,
        },
      },
    }
  }
  //////////////
  // UPDATE DATA
  //////////////
  if (action.type === ActionCreators.UpdateInviteMarkArrivedlogData.type) {
    console.log('%c MOVE THIS TO SAGA: reporting', 'color:orange')
    let clonedInviteLogItems = cloneDeep(state.invitelogItems)
    const updatedData = action.payload
    const updatedLogs = clonedInviteLogItems.map((logitem) => {
      if (logitem.EventUidEmail === updatedData.EventUidEmail) {
        logitem.evtTimeStampArrival = updatedData.evtTimeStampArrival
        logitem.evtTZOffsetArrival = updatedData.evtTZOffsetArrival
        logitem.evtTimeZoneArrival = updatedData.evtTimeZoneArrival
        logitem.searchString = calculateInviteStringForItem(logitem)
        logitem.arrivalSource = updatedData.arrivalSource
        logitem.lmt = updatedData.lmt
      }
      return logitem
    })
    partialState = {
      invitelogItems: updatedLogs,
    }
  }
  if (action.type === ActionCreators.UpdateInviteUnmarkArrivedlogData.type) {
    console.log('%c MOVE THIS TO SAGA: reporting', 'color:orange')
    let clonedInviteLogItems = cloneDeep(state.invitelogItems)
    const updatedData = action.payload
    const updatedLogs = clonedInviteLogItems.map((logitem) => {
      if (logitem.EventUidEmail === updatedData.EventUidEmail) {
        delete logitem['evtTimeStampArrival']
        delete logitem['evtTZOffsetArrival']
        delete logitem['evtTimeZoneArrival']
        logitem.searchString = calculateInviteStringForItem(logitem)
        logitem.lmt = updatedData.lmt
      }
      return logitem
    })
    partialState = {
      invitelogItems: updatedLogs,
    }
  }
  ////////////////////////
  // RESET DATA AND STATES
  ////////////////////////
  // CLEAR ALL REPORTING DATA
  if (action.type === ActionCreators.StoreClearAllReportingData.type) {
    partialState = initialState()
  }
  // CLEAR ALL STATES
  if (action.type === ActionCreators.ClearAllStates.type) {
    partialState = initialState()
  }

  return { ...state, ...partialState }
}
