import { Button, Modal as AntModal, Typography } from 'antd'
import { ModalProps as AntModalProps } from 'antd/lib/modal'
import React from 'react'

export interface ModalProps extends Omit<AntModalProps, 'centered' | 'closable'> {
  children?: React.ReactNode
}

const Modal = (props: Omit<ModalProps, 'action'>) => {
  const { title, children, footer, okButtonProps, cancelButtonProps, okText, cancelText, onOk, onCancel, ...modalProps } = props

  const hasFooter = footer !== null && okButtonProps?.hidden !== true && cancelButtonProps?.hidden !== true

  return (
    <AntModal {...modalProps} centered footer={null} closable={false} onOk={onOk} onCancel={onCancel}>
      <div className={'px-8 py-4'} style={{ textAlign: 'center' }}>
        {title && (
          <Typography.Title level={4} className='text-center font-normal'>
            {title}
          </Typography.Title>
        )}
        {children}
      </div>
      {hasFooter && (
        <footer className='flex space-x-4'>
          {!cancelButtonProps?.hidden && (
            <Button block size='large' type='default' onClick={onCancel} {...cancelButtonProps}>
              {cancelText || 'Cancel'}
            </Button>
          )}
          {!okButtonProps?.hidden && (
            <Button block size='large' type='primary' onClick={onOk} {...okButtonProps}>
              {okText || 'Ok'}
            </Button>
          )}
        </footer>
      )}
    </AntModal>
  )
}

export default Modal
