import { Signer } from '@aws-amplify/core'
import moment from 'moment'
import mqtt from 'mqtt'
import React, { useEffect, useMemo, useRef, useState, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { OutputSelector } from 'reselect'
import aws_exports from 'src/aws-exports'
import SagaActionCreator from 'src/redux/sagas/saga-action-creator'
import StoreActionCreator from 'src/redux/store/store-action-creator'
import { getEntityHierarchyConfiguration } from 'src/redux/store/credentials'
import uuid from 'uuid'
import usePageVisibility from 'src/hooks/use-page-visibility'
import { SubscriptionCredentials } from 'src/redux/store/credentials/types/credentials'

const shouldUseCurrentCredentials = (currentSubscriptionCredential?: SubscriptionCredentials) => {
  let shouldUseCredentials = true
  if (currentSubscriptionCredential && currentSubscriptionCredential.Expiration) {
    const expiry = moment(currentSubscriptionCredential.Expiration)
    const now = moment()
    const diff = expiry.diff(now)
    if (diff / 1000 <= 60 * 5) {
      //allow for 5 minute clock skew
      shouldUseCredentials = false
    }
  } else shouldUseCredentials = false
  return shouldUseCredentials
}

export interface SubscriptionHandlerProps {
  topic: string
  refresh: typeof SagaActionCreator.prototype.create | typeof StoreActionCreator.prototype.create
  update: typeof SagaActionCreator.prototype.create | typeof StoreActionCreator.prototype.create
  connect?: typeof SagaActionCreator.prototype.create | typeof StoreActionCreator.prototype.create
  postUpdate?: typeof SagaActionCreator.prototype.create | typeof StoreActionCreator.prototype.create
  errorCallback?: () => void
  offlineCallback?: () => void
  refreshContextCallback?: () => void
  getSubscriptionCredentials: OutputSelector<
    any,
    SubscriptionCredentials | undefined,
    (res: SubscriptionCredentials | undefined) => SubscriptionCredentials | undefined
  >
}

export const validateProps = (props: SubscriptionHandlerProps) => {
  if (!props.getSubscriptionCredentials) throw new Error('Expected to have getSubscriptionCredentials in props')
  if (!props.refresh) throw new Error('Expected a refresh saga action in props')
  if (!props.update) throw new Error('Expected a update saga action in props')
  if (!props.topic) throw new Error('Expected a subscription topic in props')
}

const SubscriptionHandler = (props: SubscriptionHandlerProps) => {
  validateProps(props)

  const dispatch = useDispatch()
  const entityHierarchyConfiguration = useSelector(getEntityHierarchyConfiguration)
  const subscriptionCredential = useSelector(props.getSubscriptionCredentials)
  const [currentSubscriptionCredential, setCurrentSubscriptionCredential] = useState<SubscriptionCredentials | undefined>(undefined)
  const [hasInitialisedSubscription, setHasInitialisedSubscription] = useState(false)
  const [lastReconnectAttemptTs, setLastReconnectAttemptTs] = useState(Date.now())
  const [pageInvisibleTs, setPageInvisibleTs] = useState(Date.now())
  const messageBatchDetection = useRef({
    firstBatchMessageTs: 0,
    lastReceivedMessageTs: 0,
    messageCounter: 0,
  })

  const isPageVisible = usePageVisibility()
  const mqttClient = useRef<mqtt.MqttClient>(undefined)
  const mqttClientCredentials = useRef<SubscriptionCredentials | undefined>(undefined)
  const mounted = useRef(false)
  const clientId = useRef(uuid.v4())

  const topics = useMemo(() => {
    const t: string[] = []
    for (const hierarchy of entityHierarchyConfiguration.hierarchy.deepStructure) {
      if (t.length < 8) {
        const topicWithTrailingSlash = `${props.topic}/${hierarchy.hierarchyStructure.replace(/#/g, '/')}/`
        t.push(topicWithTrailingSlash)
      } else {
        break
      }
    }
    return t
  }, [entityHierarchyConfiguration, props.topic])

  useEffect(() => {
    mounted.current = true
    return () => {
      if (mqttClient.current) {
        mqttClient.current.end(true)
        mqttClient.current = undefined
      }
      mounted.current = false
    }
  }, [])

  useEffect(() => {
    if (mounted.current) {
      dispatch(props.refresh(clientId.current))
    }
  }, [dispatch, props])

  useEffect(() => {
    if (
      mounted.current &&
      (!currentSubscriptionCredential ||
        !subscriptionCredential ||
        (subscriptionCredential &&
          currentSubscriptionCredential &&
          subscriptionCredential.SessionToken !== currentSubscriptionCredential.SessionToken))
    ) {
      setCurrentSubscriptionCredential(subscriptionCredential)
    }
  }, [subscriptionCredential, currentSubscriptionCredential])

  const onConnect = useCallback(
    (connack: mqtt.IConnackPacket) => {
      if (mounted.current && connack) {
        if (!connack.sessionPresent) {
          console.log('%conConnect', 'color:green', ' subscribing to topics', topics)
          const topicsObject = {}
          topics.forEach((topic) => {
            topicsObject[topic] = { qos: 1 }
          })
          mqttClient.current.subscribe(topicsObject)
        } else {
          console.log('%conConnect', 'color:green', ' restoring previoius subscription')
        }
        if (props.connect) {
          dispatch(props.connect())
        }
      }
    },
    [topics, mqttClient, dispatch, props]
  )

  const onError = useCallback(
    (error) => {
      if (mounted.current) {
        console.log('onError', error)
        //retry credentials if we get an error and set to initial state
        setHasInitialisedSubscription(false)
        mqttClientCredentials.current = undefined
        dispatch(props.refresh(clientId.current))
        props.errorCallback?.()
      }
    },
    [dispatch, props]
  )

  const onReconnect = useCallback(() => {
    if (mounted.current) {
      console.log('onReconnect')
      const shouldUseCredentials = shouldUseCurrentCredentials(currentSubscriptionCredential)
      if (!shouldUseCredentials) {
        dispatch(props.refresh(clientId.current))
      }
      setLastReconnectAttemptTs(Date.now())
    }
  }, [currentSubscriptionCredential, props, dispatch])

  const onMessage = useCallback(
    (_topic, payload) => {
      if (mounted.current) {
        let shouldDispatch = true
        const dn = Date.now()
        const newMessageBatchDetection = {
          ...messageBatchDetection.current,
        }
        if (!newMessageBatchDetection.firstBatchMessageTs) {
          newMessageBatchDetection.firstBatchMessageTs = dn
        }
        newMessageBatchDetection.lastReceivedMessageTs = dn
        newMessageBatchDetection.messageCounter++
        if (newMessageBatchDetection.lastReceivedMessageTs - newMessageBatchDetection.firstBatchMessageTs < 15 * 1000) {
          if (newMessageBatchDetection.messageCounter > 80) {
            //we have more than 80 batch messages in 15 second window - assume this is an inactive tab waking up
            newMessageBatchDetection.firstBatchMessageTs = 0
            newMessageBatchDetection.messageCounter = 0
            shouldDispatch = false
            //trigger refresh
            props.refreshContextCallback?.()
          }
        } else if (newMessageBatchDetection.firstBatchMessageTs) {
          newMessageBatchDetection.firstBatchMessageTs = 0
          newMessageBatchDetection.messageCounter = 0
        }
        messageBatchDetection.current = newMessageBatchDetection
        if (shouldDispatch) {
          dispatch(props.update({ events: JSON.parse(payload.toString()), messageReceivedTimeStamp: dn }))
          if (props.postUpdate) {
            dispatch(props.postUpdate(JSON.parse(payload.toString())))
          }
        }
      }
    },
    [messageBatchDetection, dispatch, props]
  )

  const onOffline = useCallback(() => {
    if (mounted.current) {
      console.log('onOffline')
      props.offlineCallback?.()
    }
  }, [props])

  const processConnection = useCallback(() => {
    if (mounted.current && lastReconnectAttemptTs && currentSubscriptionCredential) {
      const shouldUseCredentials = shouldUseCurrentCredentials(currentSubscriptionCredential)
      if (shouldUseCredentials) {
        const { AccessKeyId: access_key, SecretAccessKey: secret_key, SessionToken: session_token } = currentSubscriptionCredential
        const serviceInfo = {
          service: 'iotdevicegateway',
          region: aws_exports.aws_cognito_region,
        }
        const endpoint = Signer.signUrl(aws_exports.aws_iot_pubsub_endpoint, { access_key, secret_key, session_token }, serviceInfo)

        if (mqttClient.current) {
          mqttClient.current.end(true)
          mqttClient.current = undefined
        }
        // Create a client instance
        mqttClient.current = mqtt.connect(endpoint, {
          keepalive: 60,
          clientId: clientId.current,
          reconnectPeriod: 15000,
          clean: false,
        })
        mqttClient.current.on('connect', onConnect)
        mqttClient.current.on('message', onMessage)
        mqttClient.current.on('error', onError)
        mqttClient.current.on('offline', onOffline)
        mqttClient.current.on('reconnect', onReconnect)
        mqttClientCredentials.current = currentSubscriptionCredential
        setHasInitialisedSubscription(true)
      } else {
        //trigger retrieval of new credentials
        mqttClientCredentials.current = undefined
        dispatch(props.refresh(clientId.current))
      }
    }
  }, [
    currentSubscriptionCredential,
    mqttClient,
    mqttClientCredentials,
    lastReconnectAttemptTs,
    onConnect,
    onError,
    onMessage,
    onOffline,
    onReconnect,
    dispatch,
    props,
  ])

  useEffect(() => {
    if (
      mounted.current &&
      lastReconnectAttemptTs &&
      isPageVisible &&
      currentSubscriptionCredential &&
      (!hasInitialisedSubscription ||
        !mqttClientCredentials.current ||
        (mqttClientCredentials.current && mqttClientCredentials.current.SessionToken !== currentSubscriptionCredential.SessionToken))
    ) {
      processConnection()
    } else if (!isPageVisible) {
      if (mqttClient.current) {
        mqttClient.current.end(true)
        mqttClient.current = undefined
        mqttClientCredentials.current = undefined
        setHasInitialisedSubscription(false)
      }
    }
  }, [
    currentSubscriptionCredential,
    mqttClientCredentials,
    hasInitialisedSubscription,
    isPageVisible,
    lastReconnectAttemptTs,
    processConnection,
  ])

  useEffect(() => {
    if (isPageVisible && pageInvisibleTs) {
      if (Date.now() - pageInvisibleTs >= 60 * 60 * 1000) {
        props.refreshContextCallback?.()
      }
      setPageInvisibleTs(0)
    } else if (!isPageVisible && !pageInvisibleTs) {
      setPageInvisibleTs(Date.now())
    }
  }, [isPageVisible, pageInvisibleTs, props])

  return null
}

export default React.memo(SubscriptionHandler)
