import { faAlignLeft, faLayerGroup, faScroll, faTerminal } from '@fortawesome/pro-duotone-svg-icons'
import { Layout, Menu } from 'antd'
import React from 'react'
import { Route, Switch } from 'react-router-dom'
import CardsExample from 'src/containers/Development/Cards'
import FormsExample from 'src/containers/Development/Forms'
import PageExample from 'src/containers/Development/Page'
import ButtonExample from 'src/containers/Development/Button'
import ThemeSwitch from 'src/components/theme/ThemeSwitch'
import { useRouter } from 'src/hooks/use-router'

const { Content, Sider } = Layout

export const devComponentRoutes: any = {
  type: 'parent-with-child',
  collapsedIcon: faTerminal,
  label: 'Development',
  key: 'components',
  sidebar: true,
  children: [
    {
      type: 'child',
      path: '/components/page',
      label: 'Page',
      leftIcon: faScroll,
      component: PageExample,
      sidebar: true,
    },
    {
      type: 'child',
      path: '/components/card',
      label: 'Card',
      leftIcon: faLayerGroup,
      component: CardsExample,
      sidebar: true,
    },
    {
      type: 'child',
      path: '/components/forms',
      label: 'Forms',
      leftIcon: faAlignLeft,
      component: FormsExample,
      sidebar: true,
    },
    {
      type: 'child',
      path: '/components/button',
      label: 'Button',
      leftIcon: faAlignLeft,
      component: ButtonExample,
      sidebar: true,
    },
  ],
}

export default function Development() {
  const { history, location } = useRouter()

  return (
    <Layout className='min-h-screen bg-vars-bodyBackground'>
      <Sider
        width={200}
        style={{ maxHeight: 'calc(100vh - 40px)' }}
        className='h-screen border-r border-t-0 border-l-0  border-b-0 border-solid border-vars-bodyBackground'
      >
        <Menu mode='inline' style={{ height: '100%', borderRight: 0 }}>
          {devComponentRoutes.children.map((route, i) => (
            <Menu.Item
              key={i}
              className={
                location.pathname.replace(/\//g, '') === route.path.replace(/\//g, '') ? 'text-primary' : 'text-vars-secondaryColor'
              }
              onClick={() => history.push(route.path)}
            >
              {route.label}
            </Menu.Item>
          ))}
        </Menu>
        <ThemeSwitch className='bg-white' />
      </Sider>
      <Layout>
        <Content
          className='bg-vars-componentBackground'
          style={{
            padding: 24,
            margin: 0,
            minHeight: 280,
          }}
        >
          <Switch>
            {devComponentRoutes.children.map((route, i) => (
              <Route key={i} exact path={route.path} component={route.component} />
            ))}
          </Switch>
        </Content>
      </Layout>
    </Layout>
  )
}
