import update from 'immutability-helper'
import { createSelector } from 'reselect'
import { RootState } from '../rootReducer'
import { ActionCreator } from '../store-action-creator'
import { ParkingBookingLogItem } from '../../../typings/kenai/parking/booking'
// import { getTimeRange } from 'src/utils/getTimeRange'
// import { RangePickerNumberValue } from 'src/typings/vendor/rangepicker'

// Action Creators
export const ActionCreators = {
  StoreSetParkingBookingData: new ActionCreator<'StoreSetParkingBookingData', State['data']>('StoreSetParkingBookingData'),
  // StoreSetParkingBookingDateRange: new ActionCreator<'StoreSetParkingBookingDateRange', State['dateRange']>(
  //   'StoreSetParkingBookingDateRange'
  // ),
  StoreClearAllParkingBookingData: new ActionCreator<'StoreClearAllParkingBookingData', void>('StoreClearAllParkingBookingData'),
  ClearAllStates: new ActionCreator<'ClearAllStates', void>('ClearAllStates'),
}

export type Action = typeof ActionCreators[keyof typeof ActionCreators]

export type State = {
  data: ParkingBookingLogItem[]
  // dateRange: RangePickerNumberValue
}

export const initialState = (): State => ({
  data: [],
  // dateRange: getTimeRange('today'),
})

// Reducer
export default function reducer(state: State = initialState(), action: Action) {
  switch (action.type) {
    case ActionCreators.StoreSetParkingBookingData.type:
      return update(state, {
        data: { $set: action.payload },
      })
    // case ActionCreators.StoreSetParkingBookingDateRange.type:
    //   return update(state, {
    //     dateRange: { $set: action.payload },
    //   })
    case ActionCreators.StoreClearAllParkingBookingData.type || ActionCreators.ClearAllStates.type:
      return initialState()
    default:
      return state
  }
}

// Selectors
const getParkingBookingState = (state: RootState) => state.parking.booking

export const getParkingBookingData = createSelector<any, State, State['data']>([getParkingBookingState], (state) => {
  return state.data
})

// export const getParkingBookingDateRange = createSelector<any, State, State['dateRange']>([getParkingBookingState], (state) => {
//   return state.dateRange
// })
