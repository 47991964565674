import { ActionCreator } from 'src/redux/store/store-action-creator'
import { RootState } from 'src/redux/store/rootReducer'
import { createSelector } from 'reselect'
import update from 'immutability-helper'

// Action Creators
export const ActionCreators = {
  StoreSetSettingsCustomNotifications: new ActionCreator<
    'StoreSetSettingsCustomNotifications',
    { key: NOTIFICATION_EVENT_TYPES; data: ConfigValueCustomNotification[] }
  >('StoreSetSettingsCustomNotifications'),
  StoreClearSettingsCustomNotifications: new ActionCreator<'StoreClearSettingsCustomNotifications', undefined>(
    'StoreClearSettingsCustomNotifications'
  ),
  ClearAllStates: new ActionCreator<'ClearAllStates', Partial<void>>('ClearAllStates'),
}

export type Action = typeof ActionCreators[keyof typeof ActionCreators]
export type State = {
  data: Record<NOTIFICATION_EVENT_TYPES, ConfigValueCustomNotification[]>
  loading: boolean
}

export const initialState = (): State => ({
  data: {
    EMPLOYEE_ASSISTED_CHECKIN: [],
    EMPLOYEE_PRE_SCREENING: [],
    PARKING_SCANNER: [],
  },
  loading: false,
})

// Reducer
export default function reducer(state: State = initialState(), action: Action) {
  switch (action.type) {
    case ActionCreators.StoreSetSettingsCustomNotifications.type:
      return update(state, {
        data: { [action.payload.key]: { $set: action.payload.data } },
      })
    case ActionCreators.StoreClearSettingsCustomNotifications.type:
      return initialState()
    case ActionCreators.ClearAllStates.type:
      return initialState()
    default:
      return state
  }
}

// Selectors
const getSettingsCustomNotificationsState = (state: RootState) => state.settings.general.notifications.data
export const getSettingsCustomNotifications = createSelector<RootState, State['data'], State['data']>(
  [getSettingsCustomNotificationsState],
  (state) => state
)
