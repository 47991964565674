import { CaretLeftOutlined } from '@ant-design/icons'
import { Breadcrumb, BreadcrumbItemProps, BreadcrumbProps, Button } from 'antd'
import React, { ReactNode, useMemo } from 'react'
import { Link } from 'react-router-dom'
import { cn } from 'src/utils/classnames'
import { hasChild } from 'src/utils/collections'
import withDefaults from 'src/utils/with-defaults'
import PageSubtitle from './page-subtitle'
import PageTitle from './page-title'

interface Props {
  className?: string
  title?: string | ReactNode
  subtitle?: string | ReactNode
  extra?: ReactNode
  back?: {
    action: () => void
    text: string
  }
  breadcrumbs?: {
    items: {
      text: string
      href?: string
    }[]
    itemProps?: BreadcrumbItemProps
  } & BreadcrumbProps
}

const defaultProps = {
  className: '',
}

type NativeAttrs = Omit<React.HTMLAttributes<HTMLHeadingElement>, keyof Props>
export type PageHeaderProps = Props & typeof defaultProps & NativeAttrs

const PageHeader: React.FC<PageHeaderProps> = ({ className, children, extra, title, subtitle, back, breadcrumbs, ...props }) => {
  if (breadcrumbs && back) {
    throw new Error('Only `breadcrumbs` or `back` can be defined, found both.')
  }

  const hasTitle = hasChild(children, PageTitle)
  const propHasTitle = hasChild([title], PageTitle)
  const hasSubtitle = hasChild(children, PageSubtitle)
  const propHasSubtitle = hasChild(subtitle, PageSubtitle)

  const pageTitle = useMemo(() => (!hasTitle && title ? propHasTitle ? title : <PageTitle>{title}</PageTitle> : null), [
    hasTitle,
    propHasTitle,
    title,
  ])
  const pageSubTitle = useMemo(
    () => (!hasSubtitle && subtitle ? propHasSubtitle ? subtitle : <PageSubtitle>{subtitle}</PageSubtitle> : null),
    [hasSubtitle, propHasSubtitle, subtitle]
  )

  return (
    <div className={cn('page-header mb-6', className)} {...props}>
      {back && (
        <Button icon={<CaretLeftOutlined />} onClick={back.action} type='link' className='p-0 mb-2 -mt-2' size='small'>
          {back.text}
        </Button>
      )}
      {breadcrumbs && (
        <Breadcrumb {...breadcrumbs}>
          {breadcrumbs.items.map((breadcrumb, i) => {
            if (breadcrumb.href) {
              return (
                <Breadcrumb.Item key={i} {...breadcrumbs.itemProps}>
                  <Link to={breadcrumb.href}>{breadcrumb.text}</Link>
                </Breadcrumb.Item>
              )
            }

            return (
              <Breadcrumb.Item key={i}>
                <span>{breadcrumb.text}</span>
              </Breadcrumb.Item>
            )
          })}
        </Breadcrumb>
      )}
      <div className='flex items-center justify-between'>
        <div>{pageTitle}</div>
        {extra && <div>{extra}</div>}
      </div>
      <div className='pt-2'>{pageSubTitle}</div>
    </div>
  )
}

const MemoPageHeader = React.memo(PageHeader)

export default withDefaults(MemoPageHeader, defaultProps)
