import React from 'react'
import { Button, Space, Dropdown, Menu, message } from 'antd'
import { DownOutlined, UserOutlined } from '@ant-design/icons'
import DropdownButton from 'src/components/dropdown-button'

function handleButtonClick(e) {
  message.info('Click on left button.')
  console.log('click left button', e)
}

function handleMenuClick(e) {
  message.info('Click on menu item.')
  console.log('click', e)
}

const menu = (
  <Menu onClick={handleMenuClick}>
    <Menu.Item key='1'>
      <UserOutlined />
      1st menu item
    </Menu.Item>
    <Menu.Item key='2'>
      <UserOutlined />
      2nd menu item
    </Menu.Item>
    <Menu.Item key='3'>
      <UserOutlined />
      3rd item
    </Menu.Item>
  </Menu>
)

const dropdown = {
  desc: 'These are dropdown buttons buttons',
  component: (
    <Space>
      <Dropdown overlay={menu}>
        <Button>
          Button <DownOutlined />
        </Button>
      </Dropdown>
      <Dropdown.Button onClick={handleButtonClick} overlay={menu}>
        Dropdown
      </Dropdown.Button>
      <Dropdown overlay={menu}>
        <DropdownButton>Custom Button</DropdownButton>
      </Dropdown>
    </Space>
  ),
}

export default dropdown
