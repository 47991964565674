import React from 'react'
import { Button } from 'antd'
import { cn } from 'src/utils/classnames'

interface ClearAllTableFiltersButtonProps {
  hasFilter: boolean
  clearAll: () => void
}

export default function ClearAllTableFiltersButton(props: ClearAllTableFiltersButtonProps) {
  return (
    <div className={cn('transition-all', !props.hasFilter ? 'h-0' : 'h-6')}>
      <Button type='link' size='small' onClick={props.clearAll} className={cn('h-full', { hidden: !props.hasFilter })}>
        Clear all filters
      </Button>
    </div>
  )
}
