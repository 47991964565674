import { Dropdown, Menu } from 'antd'
import React from 'react'
import config from 'src/config'
import { useLocale } from 'src/i18n/locale-provider'
import { languageNames } from 'src/i18n/locales'
import DropdownButton from 'src/components/dropdown-button'

const LocaleSwitch = () => {
  const { locale, setLocale } = useLocale()
  if (!config.localeEnabled) return null

  return (
    <Dropdown
      overlay={
        <Menu>
          {Object.keys(languageNames).map((key: any) => (
            <Menu.Item key={key} onClick={() => setLocale(key)}>
              <span>{languageNames[key]}</span>
            </Menu.Item>
          ))}
        </Menu>
      }
    >
      <DropdownButton>{languageNames[locale]}</DropdownButton>
    </Dropdown>
  )
}

export default LocaleSwitch
