import update from 'immutability-helper'
import { createSelector } from 'reselect'
import { InductionLogData } from 'src/typings/kenai/induction-log'
import { RootState } from '../rootReducer'
import { ActionCreator } from '../store-action-creator'

// Action Creators
export const ActionCreators = {
  StoreSetInductionLogData: new ActionCreator<'StoreSetInductionLogData', State>('StoreSetInductionLogData'),
  StoreClearAllInductionLogData: new ActionCreator<'StoreClearAllInductionLogData', void>('StoreClearAllInductionLogData'),
  ClearAllStates: new ActionCreator<'ClearAllStates', void>('ClearAllStates'),
}

export type Action = typeof ActionCreators[keyof typeof ActionCreators]

export type State = InductionLogData[]

export const initialState = (): State => []

// Reducer
export default function reducer(state: State = initialState(), action: Action) {
  switch (action.type) {
    case ActionCreators.StoreSetInductionLogData.type:
      return update(state, { $set: action.payload })
    case ActionCreators.StoreClearAllInductionLogData.type || ActionCreators.ClearAllStates.type:
      return initialState()
    default:
      return state
  }
}

// Selectors
const getInductionLogState = (state: RootState) => state.induction.inductionLog
export const getInductionLog = createSelector<any, State, State>([getInductionLogState], (state) => {
  return state
})
