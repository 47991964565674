import { EntityHierarchyConfig } from 'src/typings/kenai/configuration/entity-hierarchy'

const recursiveFlagValue = (base, flagParts, level) => {
  if (flagParts.length > level) {
    const lookupProp = flagParts[level]
    if (lookupProp.indexOf('[@') > -1) {
      // array lookup with prop key filter
      const arrayLookupKey = lookupProp.split('[@')[0]
      const arrayLookupKeyArrayFilter = lookupProp.split('[@')[1].replace(']', '').split('=')
      if (base.hasOwnProperty(arrayLookupKey)) {
        const arrayValue = base[arrayLookupKey].find(
          (arrayEntry) => `${arrayEntry[arrayLookupKeyArrayFilter[0]]}` === `${arrayLookupKeyArrayFilter[1]}`
        )
        if (arrayValue) {
          if (flagParts.length - 1 === level) {
            return arrayValue
          } else {
            return recursiveFlagValue(arrayValue, flagParts, level + 1)
          }
        } else {
          return undefined
        }
      } else {
        return undefined
      }
    } else if (base.hasOwnProperty(lookupProp)) {
      if (flagParts.length - 1 === level) {
        return base[lookupProp]
      } else {
        return recursiveFlagValue(base[lookupProp], flagParts, level + 1)
      }
    } else {
      return undefined
    }
  } else {
    return undefined
  }
}

//Entity flags - this is for overall view display - not line item dependant - across all entities - typically for showing a column if at least one entity has config for that column
export function getHierarchicalEntityFlagValue<T = any>(
  entityHierarchyConfiguration: EntityHierarchyConfig,
  flagString: string,
  lookupEntity
): T {
  let flagValue = false
  const flagParts = flagString.split('.')

  const searchStructureRecursive = (parent) => {
    const ehFlags = entityHierarchyConfiguration?.tenantFlags?.find?.((flag) => flag?.EntityHierarchy === parent?.hierarchyStructure)
    if (ehFlags) {
      const lookupFlagValue = recursiveFlagValue(ehFlags, flagParts, 0)

      if (typeof lookupFlagValue !== 'undefined' && flagValue !== true) {
        flagValue = lookupFlagValue
      }
    }
    if (flagValue === false && parent.children.length > 0) {
      for (const child of parent.children) {
        searchStructureRecursive(child)
      }
    }
  }
  const ehStructure: any[] = []
  if (lookupEntity === 'ALL') {
    ehStructure.push(...entityHierarchyConfiguration.hierarchy.deepStructure)
  } else {
    ehStructure.push(entityHierarchyConfiguration.hierarchy.flatStructure[lookupEntity])
  }

  for (const child of ehStructure) {
    searchStructureRecursive(child)
  }

  return flagValue as any
}

//Entity flags - this is for overall view display - not line item dependant - across all entities - typically for showing a column if at least one entity has config with a length bigger that length specified for that column
export const getHierarchicalEntityFlagLengthValue = (
  entityHierarchyConfiguration: EntityHierarchyConfig,
  flagString: string,
  lookupEntity: string,
  length = 0
) => {
  let flagValue = false
  const flagParts = flagString.split('.')
  const searchStructureRecursive = (parent) => {
    const ehFlags = entityHierarchyConfiguration?.tenantFlags?.find((flag) => flag?.EntityHierarchy === parent?.hierarchyStructure)
    if (ehFlags) {
      const lookupFlagValue = recursiveFlagValue(ehFlags, flagParts, 0)
      if (typeof lookupFlagValue !== 'undefined' && flagValue !== true && typeof lookupFlagValue === 'object' && lookupFlagValue.length) {
        flagValue = lookupFlagValue.length > length
      }
    }
    if (flagValue === false && parent.children.length > 0) {
      for (const child of parent.children) {
        searchStructureRecursive(child)
      }
    }
  }
  const ehStructure: any[] = []
  if (lookupEntity === 'ALL') {
    ehStructure.push(...entityHierarchyConfiguration?.hierarchy?.deepStructure)
  } else {
    ehStructure.push(entityHierarchyConfiguration?.hierarchy?.flatStructure?.[lookupEntity])
  }

  for (const child of ehStructure) {
    searchStructureRecursive(child)
  }

  return flagValue as any
}

//Entity flags - this is for overall view display - not line item dependant - across all entities - typically for returning a array if at least one entity has config with a bigger that length specified for that column
export const getHierarchicalEntityFlagArrayValue = <T = any>(
  entityHierarchyConfiguration: EntityHierarchyConfig,
  flagString: string,
  lookupEntity
) => {
  const flagValue: T[] = []
  const flagParts = flagString.split('.')
  const searchStructureRecursive = (parent) => {
    const ehFlags = entityHierarchyConfiguration?.tenantFlags?.find?.((flag) => flag?.EntityHierarchy === parent?.hierarchyStructure)
    if (ehFlags) {
      const lookupFlagValue = recursiveFlagValue(ehFlags, flagParts, 0)
      if (typeof lookupFlagValue !== 'undefined' && typeof lookupFlagValue === 'object' && lookupFlagValue.length) {
        flagValue.push(lookupFlagValue)
      }
    }
    if (parent.children.length > 0) {
      for (const child of parent.children) {
        searchStructureRecursive(child)
      }
    }
  }
  const ehStructure: any[] = []
  if (lookupEntity === 'ALL') {
    ehStructure.push(...entityHierarchyConfiguration.hierarchy.deepStructure)
  } else {
    ehStructure.push(entityHierarchyConfiguration.hierarchy.flatStructure[lookupEntity])
  }

  for (const child of ehStructure) {
    searchStructureRecursive(child)
  }

  return flagValue
}
//Entity flags - this is for individual line item display - line item dependant
export const getFullySpecifiedEntityFlagValue = (
  entityHierarchyConfiguration: EntityHierarchyConfig,
  flagString: string,
  lookupEntity: string
) => {
  let flagValue = false
  const ehFlags = entityHierarchyConfiguration?.tenantFlags?.find?.((flag) => flag?.EntityHierarchy === lookupEntity)
  const flagParts = flagString.split('.')
  const lookupFlagValue = ehFlags ? recursiveFlagValue(ehFlags, flagParts, 0) : false
  if (typeof lookupFlagValue !== 'undefined') {
    flagValue = lookupFlagValue
  }
  return flagValue as any
}

type FlagValue = string | boolean
//Entity end user configs - this is for overall view display - not line item dependant
export const getHierarchicalEntityConfigFlagValue = (
  entityHierarchyConfiguration: EntityHierarchyConfig,
  flagString: string,
  lookupEntity: string,
  configCollection
) => {
  let flagValue: FlagValue = false
  if (configCollection) {
    const searchStructureRecursive = (parent) => {
      const entityFlagString = flagString.replace('{EntityHierarchy}', parent.hierarchyStructure)
      const flagParts = entityFlagString.split('.')
      for (const prop in configCollection) {
        if (configCollection.hasOwnProperty(prop)) {
          const lookupFlagValue = recursiveFlagValue(configCollection[prop], flagParts, 1)
          if (typeof lookupFlagValue !== 'undefined' && flagValue !== true) {
            flagValue = lookupFlagValue
          }
        }
      }
      if (flagValue === false && parent.children.length > 0) {
        for (const child of parent.children) {
          searchStructureRecursive(child)
        }
      }
    }
    const ehStructure: any[] = []
    if (lookupEntity === 'ALL') {
      ehStructure.push(...entityHierarchyConfiguration.hierarchy.deepStructure)
    } else {
      ehStructure.push(entityHierarchyConfiguration.hierarchy.flatStructure[lookupEntity])
    }

    for (const child of ehStructure) {
      searchStructureRecursive(child)
    }
  }
  return flagValue as FlagValue
}

//Entity end user configs - this is for individual line item display - line item dependant
export const getFullySpecifiedEntityConfigFlagValue = (flagString: string, lookupEntity: string, configCollection) => {
  let flagValue: any = false
  if (configCollection) {
    const entityFlagString = flagString.replace('{EntityHierarchy}', lookupEntity)
    const flagParts = entityFlagString.split('.')
    for (const prop in configCollection) {
      if (configCollection.hasOwnProperty(prop) && configCollection[prop].hasOwnProperty(lookupEntity)) {
        //this assumes the same structure for tracking config collections e.g. configKey.entityHierarchy.values. In this check we confirm there is a config for this flag and within this config there is a config for the record's entity and thus a valid case for checking the flag setting
        const retrievedFlag = recursiveFlagValue(configCollection[prop], flagParts, 1)
        if (retrievedFlag) {
          flagValue = retrievedFlag
        }
      }
    }
  }
  return flagValue
}
