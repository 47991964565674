import React from 'react'
import { getEntityHierarchyConfiguration } from 'src/redux/store/credentials'
import { useSelector } from 'react-redux'
import { find as dashFind } from 'lodash'
import { EntityHierarchyConfig } from 'src/typings/kenai/configuration/entity-hierarchy'

export const locationString = (EntityHierarchy, entityHierarchyConfig: EntityHierarchyConfig) => {
  let location = 'N/A'
  if (EntityHierarchy && entityHierarchyConfig.tenantFlags) {
    const ehFlag = dashFind(entityHierarchyConfig.tenantFlags, { EntityHierarchy })
    if (ehFlag) {
      if (ehFlag.entityLocationData) {
        location = `${ehFlag.entityLocationData.companyName} - ${ehFlag.entityLocationData.locationShortDescription}`
      } else {
        location = ehFlag.EntityHierarchy
      }
    }
  }
  return location
}

function LocationCell({ EntityHierarchy }) {
  const entityHierarchyConfig = useSelector(getEntityHierarchyConfiguration)
  return <span>{locationString(EntityHierarchy, entityHierarchyConfig)}</span>
}

LocationCell.locationString = locationString

export default LocationCell
