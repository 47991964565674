import messages, { defaultLocale } from './locales'
import config from 'src/config'

export type Locale = keyof typeof messages
export type MessagesKeys = keyof typeof messages['en_GB']

function getLocaleKey(locale: Locale) {
  return locale?.split('_')?.shift()?.split('-')?.shift()?.toLowerCase()
}

function getLocaleCountry(locale: Locale) {
  return locale?.split('_')?.pop()?.split('-')?.pop()?.toLowerCase()
}

export function parseIntlLocale(locale: string): string {
  return locale?.replace('_', '-') ?? defaultLocale.replace('_', '-')
}

export function parseLocale(locale: string): Locale {
  if (!locale.includes('_') && !locale.includes('-')) {
    console.error('not a valid locale string')
    return defaultLocale
  }
  return (locale?.replace('-', '_') ?? defaultLocale) as Locale
}

export function isLocale(tested: string): tested is Locale {
  return Object.keys(messages).some((locale) => locale === tested)
}

export function localeParser(locale: any) {
  return {
    country: getLocaleCountry(locale),
    key: getLocaleKey(locale),
    locale,
  }
}

export default function getInitialLocale() {
  if (!config.localeEnabled) return defaultLocale
  const localStorageLocale = localStorage.getItem('kenai-dashboard-locale')
  if (localStorageLocale && isLocale(localStorageLocale)) {
    return localStorageLocale
  }
  return defaultLocale
}
