import { API as AmplifyAPI } from 'aws-amplify'

import AccessManager from 'src/utils/AccessManager'
import { EmployeePreScreeningDBItem, EmployeePreScreeningBaseLogItem } from 'src/redux/store/employees/types/screening-log'
import { EntityHierarchyConfig } from 'src/typings/kenai/configuration/entity-hierarchy'
import { SCREENING_STATUS } from 'src/typings/kenai/enums/enums'

type APIResponse<T extends Record<any, any>, K = any> =
  | (T & {
      key: K
    })
  | undefined

export enum PRE_SCREENING_LOG_EVENTS {
  'LOG_RETRIEVED' = 'LOG_RETRIEVED',
}

export type API_PRE_SCREENING_LOG_EVENTS = APIResponse<{ logItems?: EmployeePreScreeningDBItem[] }, 'LOG_RETRIEVED'>
export type API_PRE_SCREENING_ADMIN_RESET = APIResponse<
  { logItems?: EmployeePreScreeningDBItem[] },
  'ADMIN_DELETE_SCREENING_SUCCESSFUL' | 'ADMIN_RESET_SCREENING_SUCCESSFUL' | 'PROCESS_INSTANCE_OBSOLETE'
>

async function getPreScreeningLogEvents(
  beginTime: number,
  endTime: number,
  entityHierarchy: string,
  entityHierarchyConfiguration: EntityHierarchyConfig,
  ProjectionExpression: any = undefined
): Promise<APIResponse<{ logItems?: EmployeePreScreeningDBItem[] }, PRE_SCREENING_LOG_EVENTS>> {
  let hierarchies: string[] = []
  if (entityHierarchy === 'ALL' && entityHierarchyConfiguration && entityHierarchyConfiguration.tenantFlags) {
    hierarchies = entityHierarchyConfiguration.tenantFlags.map((availableEntity) => availableEntity.EntityHierarchy)
  } else if (entityHierarchyConfiguration && entityHierarchyConfiguration.hierarchy.flatStructure[entityHierarchy].children.length > 0) {
    const addRecursive = (additionalChildren) => {
      for (const prop of additionalChildren) {
        hierarchies.push(prop.hierarchyStructure)
        if (prop.children.length > 0) {
          addRecursive(prop.children)
        }
      }
    }
    hierarchies.push(entityHierarchy)
    addRecursive(entityHierarchyConfiguration.hierarchy.flatStructure[entityHierarchy].children)
  } else {
    hierarchies.push(entityHierarchy)
  }
  const options = {
    headers: {
      Authorization: `Bearer ${AccessManager.idToken}`,
    },
    body: {
      query: {
        hierarchies,
        beginTime,
        endTime,
        ProjectionExpression,
      },
    },
  }
  try {
    let response = await AmplifyAPI.post('preScreeningOperations', '/preScreeningLogRetrieval', options)
    if (response.key === 'ASYNC_RESPONSE_PAYLOAD') {
      const asyncResponsePayload = await fetch(response.s3PresignedURL)
      response = await asyncResponsePayload.json()
    }
    return response
  } catch (e) {
    console.error('[API] employee api returned an error', e)
    return
  }
}

async function getSelectedPreScreeningLogs(
  itemsToRetrieve: Pick<EmployeePreScreeningBaseLogItem, 'EntityHierarchy' | 'preScreeningDateProfileID'>[]
): Promise<API_PRE_SCREENING_LOG_EVENTS> {
  try {
    const options = {
      headers: {
        Authorization: `Bearer ${AccessManager.idToken}`,
      },
      body: {
        query: {
          getItemKeys: itemsToRetrieve,
        },
      },
    }
    try {
      let response = await AmplifyAPI.post('preScreeningOperations', '/preScreeningLogRetrieval', options)
      if (response.key === 'ASYNC_RESPONSE_PAYLOAD') {
        const asyncResponsePayload = await fetch(response.s3PresignedURL)
        response = await asyncResponsePayload.json()
      }
      return response
    } catch (e) {
      console.error('[API] employee api returned an error', e)
      return
    }
  } catch (e) {
    console.error('[API] employee api returned an error', e)
    return
  }
}

async function adminResetScreening(
  EntityHierarchy: string,
  preScreeningDateProfileID: string,
  lmt: number,
  resetNote: string,
  shouldDeleteInstance: boolean,
  screeningStatus: SCREENING_STATUS,
  shouldDeleteToken: boolean
) {
  try {
    const options = {
      headers: {
        Authorization: `Bearer ${AccessManager.idToken}`,
      },
      body: {
        resetOperation: {
          EntityHierarchy,
          preScreeningDateProfileID,
          lmt,
          resetNote,
          shouldDeleteInstance,
          screeningStatus,
          shouldDeleteToken,
        },
      },
    }
    try {
      return await AmplifyAPI.post('preScreeningOperations', '/preScreeningAdminResetFailedScreening', options)
    } catch (e) {
      console.error('[API] employee api returned an error', e)
      return
    }
  } catch (e) {
    console.error('[API] employee api returned an error', e)
    return
  }
}

const screeningLogAPI = {
  getPreScreeningLogEvents,
  getSelectedPreScreeningLogs,
  adminResetScreening,
}

export const employeeScreeningApi = screeningLogAPI

export default screeningLogAPI
