import { Button, Form, Input, Switch } from 'antd'
import React from 'react'
import Fieldset from 'src/components/fieldset'
import FormField from 'src/components/r-form/form-field'
import Page from 'src/components/page'

const ExampleView = () => {
  return (
    <Page title='Forms Components'>
      <FormField label='Form Title'>
        <Form.Item noStyle>
          <Input placeholder='Place holder text' />
        </Form.Item>
      </FormField>

      <FormField
        label='Form Title:'
        heading='Hello'
        description='Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello'
      >
        <Form.Item noStyle>
          <Input placeholder='Place holder text' disabled />
        </Form.Item>
      </FormField>

      <FormField
        label='Form Title:'
        heading='Hello'
        description='Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello'
      >
        <Form.Item noStyle>
          <Input value='Disabled with a value' placeholder='Place holder text' disabled />
        </Form.Item>
      </FormField>

      <FormField
        label='Form Title:'
        subTitle='form subtitle'
        heading='Hello'
        description='Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello HelloHello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello'
      >
        <Form.Item noStyle>
          <div className='flex justify-between'>
            <Input placeholder='Place holder text' className='mr-2' />
            <Button type='primary'>Submit</Button>
          </div>
        </Form.Item>
      </FormField>

      <FormField
        label='Form Title:'
        help={
          <span>
            Field added by <span className='text-primary hover:text-primary-600'>Integration with Kenai Kiosk</span>
          </span>
        }
      >
        <Form.Item noStyle>
          <Input placeholder='Place holder text' />
        </Form.Item>
      </FormField>

      <FormField label='Required:'>
        <Form.Item noStyle>
          <Switch />
        </Form.Item>
      </FormField>

      <Fieldset
        title='Screening room induction'
        extra={<Button type='primary'>Edit</Button>}
        subtitle={
          <span>
            Define which modalities you want to allow with the Kenai Scanner. You can use the scanner to capture pedestrians, vehicles or
            both. <span className='cursor-pointer text-primary'>Learn more</span>
          </span>
        }
      >
        <FormField
          label='Form Title:'
          subTitle='form subtitle'
          heading='Hello'
          description='Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello HelloHello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello Hello'
        >
          <Form.Item noStyle>
            <div className='flex justify-between'>
              <Input placeholder='Place holder text' className='mr-2' />
              <Button type='primary'>Submit</Button>
            </div>
          </Form.Item>
        </FormField>
      </Fieldset>
    </Page>
  )
}

export default ExampleView
