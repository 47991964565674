import { combineReducers } from 'redux'
import parkingLog, { State as parkingLogState } from './log'
import booking, { State as bookingState } from './booking'

export interface State {
  parkingLog: parkingLogState
  booking: bookingState
}

export default combineReducers<State>({ parkingLog, booking })
