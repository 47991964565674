import { ActionCreator } from '../store-action-creator'
import { HostDetails } from './types/hosts'
import { RootState } from '../rootReducer'
import { createSelector } from 'reselect'
import update from 'immutability-helper'

// Action Creators
export const ActionCreators = {
  StoreSetHostDirectoryData: new ActionCreator<'StoreSetHostDirectoryData', Partial<HostDetails[]>>('StoreSetHostDirectoryData'),
  StoreSetFetchingHostData: new ActionCreator<'StoreSetFetchingHostData', Partial<boolean>>('StoreSetFetchingHostData'),
  StoreSetAddingHosts: new ActionCreator<'StoreSetAddingHosts', Partial<boolean>>('StoreSetAddingHosts'),
  StoreSetDeletingHosts: new ActionCreator<'StoreSetDeletingHosts', Partial<boolean>>('StoreSetDeletingHosts'),
  StoreClearAllHostData: new ActionCreator<'StoreClearAllHostData', Partial<void>>('StoreClearAllHostData'),
  ClearAllStates: new ActionCreator<'ClearAllStates', Partial<void>>('ClearAllStates'),
}

export type Action = typeof ActionCreators[keyof typeof ActionCreators]

export type State = {
  data: HostDetails[]
  isFetching: boolean
  isAdding: boolean
  isDeleting: boolean
}

export const initialState = (): State => ({
  data: [],
  isFetching: false,
  isAdding: false,
  isDeleting: false,
})

// Reducer
export default function reducer(state: State = initialState(), action) {
  switch (action.type) {
    case ActionCreators.StoreSetHostDirectoryData.type:
      return update(state, {
        data: { $set: action.payload },
      })
    case ActionCreators.StoreSetFetchingHostData.type:
      return update(state, {
        isFetching: { $set: action.payload },
      })
    case ActionCreators.StoreSetAddingHosts.type:
      return update(state, {
        isAdding: { $set: action.payload },
      })
    case ActionCreators.StoreSetDeletingHosts.type:
      return update(state, {
        isDeleting: { $set: action.payload },
      })
    case ActionCreators.StoreClearAllHostData.type || ActionCreators.ClearAllStates.type:
      return initialState()
    default:
      return state
  }
}

// Selectors
const getHostDirectoryState = (state: RootState) => state.visitors.hosts.data
export const getHostDirectory = createSelector<any, any, HostDetails[]>([getHostDirectoryState], (state: HostDetails[]) => {
  return state
})

const getHostFetchingDataState = (state: RootState) => state.visitors.hosts.isFetching
export const getHostFetchingData = createSelector<any, any, boolean>([getHostFetchingDataState], (state: boolean) => {
  return state
})

const getHostAddingState = (state: RootState) => state.visitors.hosts.isAdding
export const getHostAdding = createSelector<any, any, boolean>([getHostAddingState], (state: boolean) => {
  return state
})
const getHostDeletingState = (state: RootState) => state.visitors.hosts.isDeleting
export const getHostDeleting = createSelector<any, any, boolean>([getHostDeletingState], (state: boolean) => {
  return state
})
