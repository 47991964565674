import { ReloadOutlined } from '@ant-design/icons'
import { Button, Modal, Result } from 'antd'
import { ModalProps } from 'antd/lib/modal'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { ActionCreators as SagaActionCreators } from 'src/redux/sagas/settings/general-resources/custom-fields-saga'

const getMessage = (errorKey?: string) => {
  if (errorKey === 'FIELD_INSTANCE_OBSOLETE') {
    return 'The field does not match the latest version, refresh your fields and try again.'
  }
  if (errorKey === 'EDIT_NON_EXISTING_FIELD') {
    return 'This field has been deleted since your last operation, refresh your fields to receive the latest updates'
  }
  if (errorKey === 'NO_CONSUMERS_FOUND') {
    return 'The field does not match the latest version, refresh your fields and try again.'
  }
  if (errorKey === 'CONSUMED_FIELD_CHANGE_WITHOUT_UNLOCK') {
    return 'The field has been consumed by a new flow or notification, refresh your fields to resolve this warning.'
  }
  return 'The custom fields, flows or notifications have changed since your last operation, you should reload the data to ensure you have the latest version before editing'
}

interface ObsoleteRefreshProps extends ModalProps {
  EntityHierarchy: string
  errorKey?: string
}

const showObsolete = (key?: string) => {
  if (!key) return false
  return ['FIELD_INSTANCE_OBSOLETE', 'EDIT_NON_EXISTING_FIELD', 'NO_CONSUMERS_FOUND', 'CONSUMED_FIELD_CHANGE_WITHOUT_UNLOCK'].includes(key)
}

const ObsoleteRefresh = ({ EntityHierarchy, errorKey, ...props }: ObsoleteRefreshProps) => {
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()

  const handleRefresh = (e) => {
    setLoading(true)
    dispatch(
      SagaActionCreators.SagaRefreshCustomFieldConfigs.withCallback({
        payload: EntityHierarchy,
        callback: () => {
          setLoading(false)
          if (props.onCancel) props.onCancel(e)
          Modal.destroyAll()
        },
      })
    )
  }

  return (
    <Modal visible={!!errorKey} width={550} maskClosable centered footer={null} {...props}>
      <Result
        className='py-5 px-2'
        status='warning'
        title='There was a problem with your operation.'
        subTitle={getMessage(errorKey)}
        extra={[
          <Button type='primary' key='update' size='large' icon={<ReloadOutlined />} onClick={handleRefresh} loading={loading} ghost>
            Refresh Fields
          </Button>,
        ]}
      />
    </Modal>
  )
}

ObsoleteRefresh.showObsolete = showObsolete
export default ObsoleteRefresh
