import update from 'immutability-helper'
import { createSelector } from 'reselect'
import { ActionCreator } from 'src/redux/store/store-action-creator'

import { getTimeRange } from 'src/utils/getTimeRange'
import { RootState } from '../rootReducer'
import { AllVisitDataType, EmployeeDataType, VisitorAnalyticsData } from './types/analytics'
import { RangePickerNumberValue } from 'src/typings/vendor/rangepicker'

// Action Creators
export const ActionCreators = {
  StoreSetVisitData: new ActionCreator<'StoreSetVisitData', Partial<State['allVisitData']>>('StoreSetVisitData'),
  StoreSetRangeSelection: new ActionCreator<'StoreSetRangeSelection', RangePickerNumberValue>('StoreSetRangeSelection'),
  StoreSetEmployeeData: new ActionCreator<'StoreSetEmployeeData', Partial<State['employeeData']>>('StoreSetEmployeeData'),
  StoreSetLoading: new ActionCreator<'StoreSetLoading', Partial<boolean>>('StoreSetLoading'),
  StoreDateRanges: new ActionCreator<'StoreDateRanges', RangePickerNumberValue>('StoreDateRanges'),
  StoreClearAnalyticData: new ActionCreator<'StoreClearAnalyticData', Partial<void>>('StoreClearAnalyticData'),
}

export type Action = typeof ActionCreators[keyof typeof ActionCreators]

export type State = VisitorAnalyticsData

export const initialState = (): State => ({
  allVisitData: [],
  rangeSelection: [],
  employeeData: [],
  dateAverageData: [],
  loading: false,
  dateRanges: getTimeRange(30),
})

// Reducer
export default function reducer(state: State = initialState(), action) {
  switch (action.type) {
    case ActionCreators.StoreSetVisitData.type:
      return update(state, {
        allVisitData: { $set: action.payload },
        rangeSelection: { $set: [] },
        loading: { $set: false },
      })
    case ActionCreators.StoreSetRangeSelection.type:
      return update(state, {
        rangeSelection: { $set: action.payload },
      })
    case ActionCreators.StoreSetEmployeeData.type:
      return update(state, {
        employeeData: { $set: action.payload },
      })
    case ActionCreators.StoreSetLoading.type:
      return update(state, {
        loading: { $set: action.payload },
      })
    case ActionCreators.StoreDateRanges.type:
      return update(state, {
        dateRanges: { $set: action.payload },
      })
    case ActionCreators.StoreClearAnalyticData.type:
      return initialState()
    default:
      return state
  }
}

// Selectors
const getAnalyticsState = (state: RootState) => state.visitors.analytics
export const getAnalytics = createSelector<RootState, any, State>([getAnalyticsState], (state: State) => {
  return state
})

const getAllVisitDataState = (state: RootState) => state.visitors.analytics.allVisitData
export const getAllVisitData = createSelector<RootState, any, AllVisitDataType[]>([getAllVisitDataState], (state: AllVisitDataType[]) => {
  return state
})

const getRangeSelectionState = (state: RootState) => state.visitors.analytics.rangeSelection
export const getRangeSelectionData = createSelector<RootState, any, [number, number]>(
  [getRangeSelectionState],
  (state: [number, number]) => {
    return state
  }
)

const getEmployeeDataState = (state: RootState) => state.visitors.analytics.employeeData
export const getEmployeeAnalyticsData = createSelector<RootState, any, EmployeeDataType[]>(
  [getEmployeeDataState],
  (state: EmployeeDataType[]) => {
    return state
  }
)

const getDateAverageDataState = (state: RootState) => state.visitors.analytics.dateAverageData
export const getDateAverageData = createSelector<RootState, any, number[]>([getDateAverageDataState], (state: number[]) => {
  return state
})

const getAnalyticsLoadingState = (state: RootState) => state.visitors.analytics.loading
export const getAnalyticsLoading = createSelector<RootState, any, boolean>([getAnalyticsLoadingState], (state: boolean) => {
  return state
})

const getDateRangesState = (state: RootState) => state.visitors.analytics.dateRanges
export const getDateRanges = createSelector<RootState, any, RangePickerNumberValue>(
  [getDateRangesState],
  (state: RangePickerNumberValue) => {
    return state
  }
)
