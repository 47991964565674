import update from 'immutability-helper'
import { createSelector } from 'reselect'
import config from 'src/config'
import { RootState } from '../rootReducer'
import { ActionCreator } from '../store-action-creator'

// Action Creators
export const ActionCreators = {
  StoreSetBranding: new ActionCreator<'StoreSetBranding', State>('StoreSetBranding'),
  StoreDisableBranding: new ActionCreator<'StoreDisableBranding', void>('StoreDisableBranding'),
  ClearAllStates: new ActionCreator<'ClearAllStates', Partial<void>>('ClearAllStates'),
}

export type Action = typeof ActionCreators[keyof typeof ActionCreators]

export type State = {
  enabled: boolean
  primaryColor: string
  name: string
  logo: string
  logoSmall: string
}

export const initialState = (): State => ({
  enabled: false,
  primaryColor: config.primaryColor,
  name: 'Kenai',
  logo: '',
  logoSmall: '',
})

// Reducer
export default function reducer(state: State = initialState(), action: Action) {
  switch (action.type) {
    case ActionCreators.StoreSetBranding.type:
      return update(state, { $set: action.payload })
    case ActionCreators.ClearAllStates.type || ActionCreators.StoreDisableBranding.type:
      return initialState()
    default:
      return state
  }
}

// Selectors
const getBrandingState = (state: RootState) => state.dashboard.branding
export const getBranding = createSelector<RootState, any, State>([getBrandingState], (state: State) => {
  return state
})
