import slugify from 'slugify'
import config from 'src/config'
import { css } from 'styled-components'
import { darkTheme, lightTheme } from './themes'

const theme = lightTheme(config?.primaryColor || '#4CB75C')

const varkeys = Object.keys(theme)

const cssvars = varkeys.reduce((acc, item) => ({ ...acc, [item]: `var(--${slugify(item)})` }), {}) as typeof theme

const styledVars = css`
  ${({ theme }) => css`
    [data-theme='light'] {
      ${varkeys.map((key) => ` --${key}: ${lightTheme(theme.primaryColor)[key]};`)}
    }
    [data-theme='dark'] {
      ${varkeys.map((key) => ` --${key}: ${darkTheme(theme.primaryColor)[key]};`)};
    }
    :root {
      --antd-wave-shadow-color: ${theme.primaryColor};
    }
  `}
`

export default cssvars

export { styledVars }
