import React from 'react'
import { SearchOutlined, FilterFilled, FilterOutlined } from '@ant-design/icons'
import { cssvars } from 'src/styles'

interface FilterIcon {
  type: 'search' | 'filter'
  filtered: boolean
}

export default function FilterIcon({ type, filtered }: FilterIcon) {
  if (type === 'search') return <SearchOutlined style={{ color: filtered ? cssvars.primaryColor : 'inherit' }} />
  if (filtered) return <FilterFilled className='text-vars-primaryColor' />
  return <FilterOutlined />
}
