import { EmployeeImportResponse } from './import'
import AccessManager from 'src/utils/AccessManager'
import { FLOW_NATURES_ENUM } from 'src/typings/kenai/enums'
type AddEntry = {
  EntityHierarchy: string
} & EmployeeImportResponse['validData'][0]['data']

export type UpdateEntry = {
  new: AddEntry
  old: AddEntry
}

export function buildUpsertLists(data: EmployeeImportResponse['validData'], flowTypeRef: string) {
  const addList: AddEntry[] = []
  const updateList: UpdateEntry[] = []
  data.forEach((entry) => {
    if (!entry.update) {
      addList.push({
        EntityHierarchy: AccessManager.selectedHierarchy.hierarchyStructure,
        ...entry.data,
        flowTypeRef: {
          flowId: flowTypeRef,
          flowNature: FLOW_NATURES_ENUM.WORKER,
        },
      })
    } else {
      updateList.push({
        new: {
          EntityHierarchy: AccessManager.selectedHierarchy.hierarchyStructure,
          ...entry.data,
          flowTypeRef: {
            flowId: flowTypeRef,
            flowNature: FLOW_NATURES_ENUM.WORKER,
          },
        },
        old: {
          EntityHierarchy: AccessManager.selectedHierarchy.hierarchyStructure,
          ...entry.update,
        },
      })
    }
  })

  return {
    addList,
    updateList,
  }
}
