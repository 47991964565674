import { Alert, Button, Empty, Form, Radio, Select, Space, Tabs, UploadProps, message, notification } from 'antd'
import { AlertProps } from 'antd/lib/alert'
import { motion } from 'framer-motion'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import employeeDirectoryAPI from 'src/api/workerLogOperations/directory-api'
import { TemplateUpload } from 'src/components/TemplateUpload'
import useFlowTypes from 'src/hooks/use-flow-types'
import useHierarchyEnabledFlags from 'src/hooks/use-hierarchy-enabled-flags'
import getIntl from 'src/i18n'
import { getEntityHierarchyConfiguration } from 'src/redux/store/credentials'
import { getEmployeesDirectory } from 'src/redux/store/employees/directory-store'
import { getUserProfile } from 'src/redux/store/profile'
import AccessManager from 'src/utils/AccessManager'
import { conditionalArrayItem } from 'src/utils/conditionalReturn'
import { getTenantLocationName } from 'src/utils/getLocationName'
import { getUniqueRecursiveStructure } from 'src/utils/getUniqueRecursiveStructure'
import { EmployeeSagaActions } from '../../saga'
import { parseImport } from '../../utils/import'
import { tenantFlagHelper } from 'src/redux/store/features/tenant-flag-helper'

const ImportStepView = (props: {
  setProcessType
  setFlowTypeRef: (val: string) => void
  flowTypeRef: string
  processType: 'replace' | 'update'
  setData
  onFinish
  // hasData
}) => {
  const intl = getIntl()
  const [error, setError] = useState('')
  const { setData } = props
  const { employeeRouteType } = useHierarchyEnabledFlags()
  const translatedRouteType = intl.formatMessage({ id: `common.${employeeRouteType}` })
  const entityHierarchyConfig = useSelector(getEntityHierarchyConfiguration)
  const profile = useSelector(getUserProfile)

  const locationData = useMemo(() => {
    const entityLocationTree: Array<{ EntityHierarchy: string; title: string }> = []

    const uniqueStructure = getUniqueRecursiveStructure(entityHierarchyConfig.hierarchy.deepStructure)
    uniqueStructure.forEach((hierarchyStructure) => {
      const tf = entityHierarchyConfig.tenantFlags.find((tf) => tf.EntityHierarchy === hierarchyStructure)
      if (tf && tenantFlagHelper.getEmployeeMaintenanceEnabled(profile, entityHierarchyConfig, tf.EntityHierarchy)) {
        entityLocationTree.push({
          EntityHierarchy: tf.EntityHierarchy,
          title: getTenantLocationName(tf),
        })
      }
    })

    return entityLocationTree
  }, [profile, entityHierarchyConfig])

  const uploadMethods = [
    {
      label: 'Update Directory',
      value: 'update',
    },
    {
      label: 'Replace Directory',
      value: 'replace',
    },
  ]

  const notifications = [
    ...conditionalArrayItem(error, { type: 'error' as AlertProps['type'], message: error }),
    ...conditionalArrayItem(props.processType === 'replace', {
      type: 'warning' as AlertProps['type'],
      message: `By selecting this method, all current ${translatedRouteType} in your directory will be deleted, to avoid this either add them to your current import sheet, or change the method to “Update Directory”`,
    }),
  ]

  return (
    <div>
      <Space direction='vertical' size='middle' className='w-full'>
        <div>
          <h2>Upload method:</h2>
          <Radio.Group
            options={uploadMethods}
            defaultValue={uploadMethods[0].value}
            onChange={(e) => props.setProcessType(e.target.value)}
          />
        </div>

        <div>
          <h2>Choose an {intl.formatMessage({ id: `common.${employeeRouteType}-singular` })} type for this upload:</h2>
          <FlowTypesSelection
            value={props?.flowTypeRef}
            onChange={props.setFlowTypeRef}
            selectedHierarchy={AccessManager.selectedHierarchy.hierarchyStructure}
          />
        </div>

        {notifications.map((alert, i) => (
          <motion.li key={i} positionTransition initial={{ translateY: 200, opacity: 0 }} animate={{ translateY: 0, opacity: 1 }}>
            <Alert showIcon key={i} {...alert} className={i !== notifications.length - 1 ? 'mb-1' : ''} closable />
          </motion.li>
        ))}

        <Tabs defaultActiveKey='1' className='border border-solid rounded-md border-vars-borderColor'>
          <Tabs.TabPane tab={<span className='px-6'>Template Upload</span>} key='1' className='text-center'>
            <div className='-mt-4'>
              <Uploader
                entityHierarchyConfig={entityHierarchyConfig}
                setData={setData}
                setError={setError}
                flowTypeRef={props?.flowTypeRef}
              />
            </div>
          </Tabs.TabPane>

          <Tabs.TabPane tab={<span className='px-6'>Copy From Source</span>} key='2' className='mb-6' disabled={!locationData?.[0]}>
            <CopyFormSource
              destinationFlowRef={props?.flowTypeRef}
              locationData={locationData}
              processType={props.processType}
              onFinish={props.onFinish}
            />
          </Tabs.TabPane>
        </Tabs>
      </Space>
    </div>
  )
}

const CopyFormSource = ({ locationData, destinationFlowRef, processType, onFinish }) => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)

  if (locationData?.[0]) {
    return (
      <Form
        layout='vertical'
        className='px-4'
        onFinish={async (values) => {
          // TODO - link with api to copy flow type directory
          // TODO - add a popconfirm before starting the copy
          setLoading(true)
          try {
            const body = {
              source: {
                EntityHierarchy: values.selectedHierarchy,
                flowId: values.sourceFlowRef,
              },
              destination: {
                EntityHierarchy: AccessManager.selectedHierarchy.hierarchyStructure,
                flowId: destinationFlowRef,
              },
            }

            const result = (res) => {
              if (res.key === 'OPERATIONS_PROCESSED') {
                if (res.isAsyncProcessing) {
                  notification.info({
                    message:
                      'The directory is being updated asynchronously due to the large upload size. Please check back in a few minutes to confirm complete processing.',
                  })
                  onFinish()
                } else {
                  dispatch(
                    EmployeeSagaActions.SagaRetrieveEmployeesDirectoryData.withCallback({
                      callback: () => {
                        notification.success({ message: 'The directory has been successfully copied from the source.' })
                        onFinish()
                      },
                    })
                  )
                }
              } else {
                console.error(res)
                notification.error({
                  message: 'We ran into an issue while trying to copy from the source, if the problem persists, please get in touch.',
                })
              }
            }

            if (processType === 'replace') {
              dispatch(
                EmployeeSagaActions.SagaDeleteEntityEmployees.withCallback({
                  payload: destinationFlowRef,
                  callback: async (res) => {
                    if (res.status !== 'success' && res.message) message.error(res.message)
                    else {
                      await employeeDirectoryAPI.copyDirectoryFromEntity(body).then(result)
                    }
                    setLoading(false)
                  },
                })
              )
            } else {
              await employeeDirectoryAPI.copyDirectoryFromEntity(body).then(result)
            }
          } catch (error) {
            console.error(error)
            message.error(error?.message || 'An error occurred while trying to copy from the source.')
            setLoading(false)
          } finally {
            setLoading(false)
          }
        }}
      >
        <Form.Item name='selectedHierarchy' label='Location' initialValue={locationData?.[0].EntityHierarchy}>
          <Select dropdownStyle={{ maxHeight: 400, overflow: 'auto' }} placeholder='Choose location'>
            {locationData.map((location) => (
              <Select.Option key={location.EntityHierarchy} value={location.EntityHierarchy}>
                {location.title}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item label={`Source Type`} shouldUpdate>
          {({ getFieldValue }) => {
            const selectedHierarchy = getFieldValue('selectedHierarchy')
            return (
              <Form.Item name='sourceFlowRef' noStyle>
                <FlowTypesSelection selectedHierarchy={selectedHierarchy} />
              </Form.Item>
            )
          }}
        </Form.Item>

        <Form.Item shouldUpdate noStyle>
          {({ getFieldsValue }) => {
            const { selectedHierarchy, sourceFlowRef } = getFieldsValue()
            const disabled = !selectedHierarchy || !sourceFlowRef || !destinationFlowRef
            return (
              <Button type='primary' disabled={disabled} htmlType='submit' loading={loading}>
                Copy Directory
              </Button>
            )
          }}
        </Form.Item>
      </Form>
    )
  } else {
    return <Empty />
  }
}

const FlowTypesSelection = (props: { value?: string; onChange?: (value: string) => void; selectedHierarchy: string }) => {
  const [selectedFlowType, setSelectedFlowType] = useState(props?.value ?? '')
  const { selectedHierarchy, onChange } = props
  const flowTypes = useFlowTypes(selectedHierarchy)

  const handleChange = useCallback(
    (val: string) => {
      if (onChange) onChange(val)
      setSelectedFlowType(val)
    },
    [onChange]
  )

  useEffect(() => {
    handleChange((flowTypes?.[0]?.value as string) || '')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flowTypes])

  if (flowTypes?.[0]) {
    return <Radio.Group name='flowType' options={flowTypes} value={selectedFlowType} onChange={(e) => handleChange(e.target.value)} />
  } else return <div className='text-vars-disabledColor'>No types for selected location</div>
}

const getTemplateLinkFromConfig = (flags: ReturnType<typeof useHierarchyEnabledFlags>) => {
  const employeeLabel =
    flags.employeeRouteType === 'students' ? 'student' : flags.employeeRouteType === 'candidates' ? 'candidate' : 'employee'
  const prefix = `${window.location.origin}/templates/kenai_${employeeLabel}_import_template`
  let suffix = ''
  const extension = '.xlsx'

  // see table https://kenai.atlassian.net/browse/KF-354?focusedCommentId=11831
  // TODO: dynamically generate spreadsheet based on configs. For now, we'll just use the manual spreadsheets
  if (flags.employeeLineManagers && !flags.employeeAlternatePersonalIdNr) {
    if (flags.employeeTags) {
      suffix = '_wlt_wt'
    } else {
      suffix = '_wlt'
    }
  } else if (flags.employeeLineManagers && flags.employeeAlternatePersonalIdNr) {
    if (flags.employeeTags) {
      suffix = '_wlt_waid_wt'
    } else {
      suffix = '_wlt_waid'
    }
  } else if (!flags.employeeLineManagers && flags.employeeAlternatePersonalIdNr) {
    suffix = '_waid'
  }

  return prefix + suffix + extension
}

const Uploader = ({ entityHierarchyConfig, setData, setError, flowTypeRef }) => {
  const [parsing, setParsing] = useState(false)
  const currentData = useSelector(getEmployeesDirectory)
  const entityHierarchyFlags = useHierarchyEnabledFlags()

  const handleImportRequest = useCallback(
    async ({ file }: Parameters<UploadProps['customRequest']>[0]) => {
      setParsing(true)
      const duplicateMetaFieldsAllowed = tenantFlagHelper.getEmployeeDuplicateMetaFieldsAllowed(
        entityHierarchyConfig,
        AccessManager.selectedHierarchy.hierarchyStructure,
        flowTypeRef ?? ''
      )
      const employeeAlternatePersonalIdNr = tenantFlagHelper.getEmployeeAlternatePersonalIdNrEnabledMaintenanceEnabled(
        entityHierarchyConfig,
        AccessManager.selectedHierarchy.hierarchyStructure
      )
      const importData = await parseImport(file, currentData, duplicateMetaFieldsAllowed, employeeAlternatePersonalIdNr)
      setParsing(false)
      if (importData.error) {
        setError(importData.error)
      } else {
        setError('')
        setData(importData)
      }
    },
    [entityHierarchyConfig, flowTypeRef, currentData, setError, setData]
  )

  return (
    <TemplateUpload
      customRequest={handleImportRequest}
      beforeUpload={() => {
        setParsing(true)
        return true
      }}
      disabled={parsing}
      downloadLink={getTemplateLinkFromConfig(entityHierarchyFlags)}
      border={false}
    />
  )
}

export default ImportStepView
