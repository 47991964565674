import { ActionCreator } from 'src/redux/store/store-action-creator'
import { RootState } from 'src/redux/store/rootReducer'
import { createSelector } from 'reselect'
import update from 'immutability-helper'
import { ActiveEvacuationEvent, ArchivedEvacuationEvent } from 'src/containers/Messaging/Evacuation'

// Action Creators
export const ActionCreators = {
  StoreSetEvacuationLog: new ActionCreator<'StoreSetEvacuationLog', State['data']>('StoreSetEvacuationLog'),
  StoreSetResponseLog: new ActionCreator<'StoreSetResponseLog', State['responseLog']>('StoreSetResponseLog'),
  ClearAllStates: new ActionCreator<'ClearAllStates', void>('ClearAllStates'),
}

export type Action = typeof ActionCreators[keyof typeof ActionCreators]
export type State = {
  data: {
    active: ActiveEvacuationEvent[]
    archive: ArchivedEvacuationEvent[]
  }
  responseLog: ActiveEvacuationEvent | ArchivedEvacuationEvent
}

export const initialState = (): State => ({
  data: {
    active: [],
    archive: [],
  },
  responseLog: {} as any,
})

// Reducer
export default function reducer(state: State = initialState(), action: Action) {
  switch (action.type) {
    case ActionCreators.StoreSetEvacuationLog.type:
      return update(state, {
        data: { $set: action.payload },
      })
    case ActionCreators.StoreSetResponseLog.type:
      return update(state, {
        responseLog: { $set: action.payload },
      })
    case ActionCreators.ClearAllStates.type:
      return initialState()
    default:
      return state
  }
}

export const getEvacuationMessagingLog = createSelector<RootState, State, State['data']>(
  [(state) => state.messaging.evacuation],
  (state) => state.data
)

export const getEvacuationMessagingResponseLog = createSelector<RootState, State, State['responseLog']>(
  [(state) => state.messaging.evacuation],
  (state) => state.responseLog
)
