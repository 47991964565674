import { faCog } from '@fortawesome/pro-light-svg-icons'
import React, { lazy } from 'react'
import { FormattedMessage } from 'react-intl'
import { HierarchyEnabledFlags } from 'src/hooks/use-hierarchy-enabled-flags'
import { ROUTE } from 'src/typings/kenai/dashboard-router'
import { conditionalArrayItem } from 'src/utils/conditionalReturn'

const Account = lazy(() => import('src/containers/Settings/General/account__rn/legacy_index'))
const Resources = lazy(() => import('src/containers/Settings/General/resources'))

const Notifications = lazy(() => import('src/containers/Settings/Notifications'))

export default function getSettingsRoutes(hierarchyEnabledFlags: HierarchyEnabledFlags) {
  const enabled: Record<string, (val: ROUTE) => ROUTE[]> = {
    settingsResources: (val) => conditionalArrayItem(hierarchyEnabledFlags.settingsResources, val),
    settingsNotifications: (val) => conditionalArrayItem(hierarchyEnabledFlags.settingsNotifications, val),
  }

  const settingsRoutes: ROUTE = {
    icon: faCog,
    label: <FormattedMessage id='common.settings' />,
    path: 'settings',
    sidebar: true,
    children: [
      // {
      //   path: 'settings/general',
      //   icon: faCog,
      //   label: 'General',
      //   sidebar: true,
      //   children: [
      //     {
      //       path: 'settings/general/account',
      //       icon: faCog,
      //       label: 'Account',
      //       sidebar: true,
      //       component: Account,
      //     },
      //     ...(hierarchyEnabledFlags.settingsResources
      //       ? [
      //           {
      //             path: 'settings/general/resources',
      //             icon: faCog,
      //             label: 'Resources',
      //             sidebar: true,
      //             component: Resources,
      //           },
      //         ]
      //       : []),
      //     ],
      //   },
      {
        path: 'settings/account',
        icon: faCog,
        label: 'Account',
        sidebar: true,
        component: Account,
      },
      ...enabled.settingsResources({
        path: 'settings/resources',
        icon: faCog,
        label: 'Resources',
        sidebar: true,
        component: Resources,
      }),
      ...enabled.settingsNotifications({
        path: 'settings/notifications',
        icon: faCog,
        label: 'Notifications',
        sidebar: true,
        component: Notifications,
        exact: true,
      }),
    ],
  }
  return settingsRoutes
}
