import { Button, Space } from 'antd'
import React from 'react'
import { useIntl } from 'react-intl'
import useHierarchyEnabledFlags from 'src/hooks/use-hierarchy-enabled-flags'
import Action from '../action'

export default function ActionCell({ record, onClick }) {
  const hierarchyEnabledFlags = useHierarchyEnabledFlags()
  const intl = useIntl()
  return (
    <Space>
      <Button type='link' className='p-0' onClick={onClick}>
        Edit
      </Button>
      |
      <Action.Delete intl={intl} hierarchyEnabledFlags={hierarchyEnabledFlags} link data={[record]} className='p-0' />
    </Space>
  )
}
