import {
  all,
  fork,
  takeLeading,
  call,
  put,
  select,
  take,
  actionChannel, // call, take, select
} from 'redux-saga/effects'
import ActionCreator from '../../saga-action-creator'
import moment from 'moment'
import { ActionCreators as AuthenticationActionCreators } from 'src/redux/sagas/authentication'
import {
  ActionCreators as CredentialsStoreActionCreators,
  getEntityHierarchyConfiguration,
  getGlobalEventSubscriptionCredential,
} from 'src/redux/store/credentials'
import { ActionCreators as EvacuationActionCreators } from 'src/redux/sagas/evacuation/evacuation'
import { ActionCreators as DashboardActionCreators } from 'src/redux/store/dashboard/globalevents'
import API from 'src/api'
import {
  getEvacuationMessagingLog,
  ActionCreators as EvacMessagingLogActions,
  getEvacuationMessagingResponseLog,
} from 'src/redux/store/messaging/evacuation'
import { cloneDeep } from 'lodash'
import { ArchivedEvacuationEvent, ActiveEvacuationEvent } from 'src/containers/Messaging/Evacuation'

// Action Creators
export const ActionCreators = {
  SagaRefreshGlobalSubscriptionCredential: new ActionCreator<'SagaRefreshGlobalSubscriptionCredential', string>(
    'SagaRefreshGlobalSubscriptionCredential'
  ),
  SagaGlobalLiveUpdateHandler: new ActionCreator<'SagaGlobalLiveUpdateHandler', any>('SagaGlobalLiveUpdateHandler'),
  SagaGlobalSubscriptionErrors: new ActionCreator<'SagaGlobalSubscriptionError', string[]>('SagaGlobalSubscriptionError'),
}

const AWSHelper = new API.AWSHelpers()

function* processRefreshGlobalSubscriptionCredential(action) {
  try {
    yield put(AuthenticationActionCreators.SagaRefreshApiCredentials.create())
    yield take(AuthenticationActionCreators.SagaApiCredentialsRefreshUpdate.type)
    const currentSubscriptionCredential = yield select(getGlobalEventSubscriptionCredential)
    const clientId = action.payload
    let shouldRetrieveNewCredentials = true
    if (currentSubscriptionCredential && currentSubscriptionCredential.Expiration) {
      const expiry = moment(currentSubscriptionCredential.Expiration)
      const now = moment()
      const diff = expiry.diff(now)
      if (diff / 1000 > 60 * 5) {
        //allow for 5 minute clock skew
        shouldRetrieveNewCredentials = false
      }
    }
    if (shouldRetrieveNewCredentials) {
      const entityHierarchyConfiguration = yield select(getEntityHierarchyConfiguration)
      const topics: Array<string> = []
      entityHierarchyConfiguration.hierarchy.deepStructure.forEach((hierarchy) => {
        //just subscribe to the root
        const topicWithOutTrailingSlash = `globalEvents/${hierarchy.hierarchyStructure.replace(/#/g, '/')}`
        if (topics.length < 8) {
          //never subscribe to more than 8
          topics.push(topicWithOutTrailingSlash)
        }
      })

      const subscriptionCredential = yield call([AWSHelper, AWSHelper.retrieveSubscribeCredential], topics, clientId)
      if (subscriptionCredential.key === 'CREDENTIAL_RETRIEVED') {
        yield put(
          CredentialsStoreActionCreators.StoreSetGlobalEventSubscriptionCredential.create({
            ...subscriptionCredential.credential,
            ts: Date.now(),
          })
        )
      } else {
        yield put(CredentialsStoreActionCreators.StoreSetGlobalEventSubscriptionCredential.create(undefined))
      }
    } else if (!shouldRetrieveNewCredentials && currentSubscriptionCredential) {
      yield put(
        CredentialsStoreActionCreators.StoreSetGlobalEventSubscriptionCredential.create({
          ...currentSubscriptionCredential,
          ts: Date.now(),
        })
      ) //triggers gDSfP in subscription component
    }
  } catch (e) {
    if (e) {
      console.error(e)
    }
    yield put(CredentialsStoreActionCreators.StoreSetGlobalEventSubscriptionCredential.create(undefined))
  }
}

function* processGlobalEventsLiveUpdateHandler(action) {
  try {
    const eventPayload = action.payload
    const evacuationEvents: (
      | {
          eventType: 'REMOVE'
          payload: ArchivedEvacuationEvent
        }
      | {
          eventType: 'INSERT'
          payload: ActiveEvacuationEvent
        }
    )[] = []
    //we don't track live update earliest timestamp for global channels thus not implemented here
    for (let i = 0; i < eventPayload.events.length; i++) {
      if (eventPayload.events[i].notificationType === 'EVACUATION') {
        evacuationEvents.push(eventPayload.events[i])
      }
    }

    const evacMessagingLog: ReturnType<typeof getEvacuationMessagingLog> = cloneDeep(yield select(getEvacuationMessagingLog))
    let updatedEvacResponseLog: ReturnType<typeof getEvacuationMessagingResponseLog> = cloneDeep(
      yield select(getEvacuationMessagingResponseLog)
    )
    let active = evacMessagingLog.active
    const archive = evacMessagingLog.archive

    evacuationEvents.forEach((evacEvent) => {
      if (evacEvent.eventType === 'REMOVE') {
        active = active.filter(
          (activeEvent) =>
            activeEvent.EntityHierarchy !== evacEvent.payload.EntityHierarchy &&
            activeEvent.activatedTimestamp !== evacEvent.payload.activatedTimestamp
        )

        if (
          evacEvent.payload.EntityHierarchy === updatedEvacResponseLog.EntityHierarchy &&
          evacEvent.payload.activatedTimestamp === updatedEvacResponseLog.activatedTimestamp
        ) {
          updatedEvacResponseLog = evacEvent.payload
        }
      }
      const alreadyHasEvent = active.some(
        (activeEvent) =>
          activeEvent.EntityHierarchy === evacEvent.payload.EntityHierarchy &&
          activeEvent.activatedTimestamp === evacEvent.payload.activatedTimestamp
      )
      if (evacEvent.eventType === 'INSERT' && !alreadyHasEvent) {
        active.push(evacEvent.payload)
      }
    })
    yield put(EvacMessagingLogActions.StoreSetResponseLog.create(updatedEvacResponseLog))
    yield put(EvacMessagingLogActions.StoreSetEvacuationLog.create({ active, archive }))
    yield put(EvacuationActionCreators.SagaEvacuationLiveUpdateHandler.create(evacuationEvents))
  } catch (e) {
    console.log(e)
  }
}

function* processGlobalSubscriptionErrors(action) {
  try {
    //for now switch this off until we are confident in stability and have updated subs handler with timeout support so we can delay raising of errors with 30 seconds
    // if (action.payload.length > 0) {
    if (action.payload.length < 0) {
      yield put(DashboardActionCreators.StoreSetErrors.create(action.payload))
    }
  } catch (e) {
    console.log(e)
  }
}

// Saga triggers
function* watchGlobalEventsSagas() {
  yield takeLeading(ActionCreators.SagaRefreshGlobalSubscriptionCredential.type, processRefreshGlobalSubscriptionCredential)
  yield takeLeading(ActionCreators.SagaGlobalSubscriptionErrors.type, processGlobalSubscriptionErrors)
  yield null
}

function* watchGlobalEventsLiveUpdateChannel() {
  const SagaGlobalLiveUpdateHandlerChannel = yield actionChannel(ActionCreators.SagaGlobalLiveUpdateHandler.type)
  while (true) {
    const action = yield take(SagaGlobalLiveUpdateHandlerChannel)
    yield call(processGlobalEventsLiveUpdateHandler, action)
  }
}

// Saga hooks
export default function* globalEventsSagas() {
  yield all([fork(watchGlobalEventsSagas), fork(watchGlobalEventsLiveUpdateChannel)])
}
