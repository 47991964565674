/* eslint-disable no-unused-expressions */
/* eslint-disable no-sequences */
import { hotjar } from 'react-hotjar'
import CONSTANTS from './constants'

// TODO - ANALYTICS - Configure and setup heap
// current setup adds no value
// const bsHeap = (heapAppId, heapUserId) => {
// ;(window.heap = window.heap || []),
//   (window.heap.load = function (e, t) {
//     ;(window.heap.appid = e), (window.heap.config = t = t || {})
//     var r = t.forceSSL || 'https:' === document.location.protocol,
//       a = document.createElement('script')
//     ;(a.type = 'text/javascript'), (a.async = !0), (a.src = (r ? 'https:' : 'http:') + '//cdn.heapanalytics.com/js/heap-' + e + '.js')
//     var n = document.getElementsByTagName('script')[0]
//     if (n.parentNode) n.parentNode.insertBefore(a, n)
//     for (
//       var o = function (e) {
//           return function () {
//             window.heap.push([e].concat(Array.prototype.slice.call(arguments, 0)))
//           }
//         },
//         p = [
//           'addEventProperties',
//           'addUserProperties',
//           'clearEventProperties',
//           'identify',
//           'resetIdentity',
//           'removeEventProperty',
//           'setEventProperties',
//           'track',
//           'unsetEventProperty',
//         ],
//         c = 0;
//       c < p.length;
//       c++
//     )
//       window.heap[p[c]] = o(p[c])
//   })
// window.heap.load(heapAppId)
// const HI = setInterval(() => {
//   if (window.heap && window.heap.identify) {
//     clearInterval(HI)
//     window.heap.identify(heapUserId)
//   }
// }, 500)
// }

const configureAnalytics = (_token?: string) => {
  if (process.env.NODE_ENV === 'production') {
    hotjar.initialize(CONSTANTS.ANALYTICS.HOTJAR_APP_ID, 6)
    // bsHeap(CONSTANTS.ANALYTICS.HEAP_APP_ID, token)
  }
}

export default configureAnalytics
