import * as Sentry from '@sentry/react'
// import SentryRRWeb from '@sentry/rrweb'
import { Integrations } from '@sentry/tracing'
import config from '../config'

let configured = false

const configureSentry = () => {
  if (!configured) {
    Sentry.init({
      enabled: process.env.NODE_ENV !== 'development',
      debug: process.env.NODE_ENV === 'development',
      dsn: 'https://66781e226d7944adadf99851baf3f96f@o374332.ingest.sentry.io/5192171',
      integrations: [
        new Integrations.BrowserTracing(),
        // ...(config.staging ? [new SentryRRWeb()] : [])
      ],
      release: process.env.RELEASE_VERSION,
      environment: config.staging ? 'staging' : process.env.NODE_ENV,
      // We recommend adjusting this value in production, or using tracesSampler
      // for finer control
      tracesSampleRate: 1.0,
      // debug: process.env.NODE_ENV === 'development',
      ignoreErrors: ['ResizeObserver loop limit exceeded', 'Not Authorized', 'NetworkingError'],
    })

    // if (config.staging) {
    //   Sentry.setTag('rrweb.active', 'yes')
    // }

    configured = true
  }
}

export default configureSentry
