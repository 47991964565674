import { DownloadOutlined, ExportOutlined, PlusOutlined } from '@ant-design/icons'
import { Button, Input, Table } from 'antd'
import { ColumnType } from 'antd/lib/table'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import ExportButton from 'src/components/export-button'
import Page from 'src/components/page'
import Toolbar from 'src/components/r-toolbar'
import RefreshButton from 'src/components/refresh-button'
import Suppress from 'src/components/Suppress'
import ClearAllTableFiltersButton from 'src/components/table/ClearAllTableFiltersButton'
import TagsCell from 'src/components/table/table-cell-tags'
import { filterColumnOptions, searchColumnOptions } from 'src/components/table/table-column'
import { useTableFilters } from 'src/components/table/useTableFilters'
import { filterByValue } from 'src/components/table/utils'
// import { TableColumnResizeableTitle } from 'src/components/table/table-column'
import useHierarchyEnabledFlags from 'src/hooks/use-hierarchy-enabled-flags'
import { useProfile } from 'src/hooks/use-profile'
import getIntl from 'src/i18n'
import { ActionCreators as SagaActions } from 'src/redux/sagas/employees/directory-saga'
import { getEntityHierarchyConfiguration } from 'src/redux/store/credentials'
import {
  ActionCreators as StoreActionCreators,
  getEmployeesDirectory,
  getEmployeesDirectoryLoading,
} from 'src/redux/store/employees/directory-store'
import { EmployeeDetails } from 'src/redux/store/employees/types/directory'
import { conditionalTableColumn } from 'src/utils/conditionalReturn'
import Action from './action'
import DeleteAction from './action/action-delete'
import Cell from './cell'
import { filterEmployeeSearch } from './filters/search'
import ManageEmployee from './manage'
import exportDirectory from './utils/export'
import { getEmployeeFlowType } from './utils/getEmployeeFlowType'
import { EntityHierarchyConfig } from 'src/typings/kenai/configuration/entity-hierarchy'
import { EvacuationButton } from 'src/components/evacuation'

const buildColumns = (
  data: EmployeeDetails[],
  ehConfig: EntityHierarchyConfig,
  hierarchyEnabledFlags: ReturnType<typeof useHierarchyEnabledFlags>,
  intl: ReturnType<typeof getIntl>,
  actions: { setEditView: (record: EmployeeDetails) => void }
): ColumnType<EmployeeDetails>[] => {
  const conditionalColumn = (condition, element: ColumnType<EmployeeDetails>, fallback = undefined) => {
    return conditionalTableColumn<EmployeeDetails>(condition, element, fallback)
  }

  return [
    {
      title: 'Name',
      key: 'fullName',
      width: 200,
      render: (record: EmployeeDetails) => {
        return <Suppress>{`${record.firstName} ${record.lastName}`}</Suppress>
      },
      ...searchColumnOptions({
        onFilter: (value, record) => {
          const filters = [filterByValue(value, record.firstName), filterByValue(value, record.lastName)].filter(Boolean)
          return Boolean(filters.length)
        },
      }),
    },
    {
      title: intl.formatMessage({ id: 'personIDValue' }),
      width: 200,
      dataIndex: 'personalIdentificationNr',
      render: (val) => <Suppress>{val}</Suppress>,
      ...searchColumnOptions({
        onFilter: (value, record) => filterByValue(value, record.personalIdentificationNr),
      }),
    },
    ...conditionalColumn(hierarchyEnabledFlags.employeeAlternatePersonalIdNr, {
      title: intl.formatMessage({ id: 'alternatePersonalIdNr' }),
      width: 200,
      dataIndex: 'alternatePersonalIdNr',
      render: (val) => <Suppress>{val}</Suppress>,
      ...searchColumnOptions({
        onFilter: (value, record) => filterByValue(value, record.alternatePersonalIdNr),
      }),
    }),
    {
      title: intl.formatMessage({ id: 'organizationIdValue' }),
      width: 200,
      dataIndex: 'organizationIdValue',
      render: (val) => <Suppress>{val}</Suppress>,
      ...searchColumnOptions({
        onFilter: (value, record) => filterByValue(value, record.organizationIdValue),
      }),
    },
    {
      title: `${intl.formatMessage({ id: `common.${hierarchyEnabledFlags.employeeRouteType}-singular` })} Type`,
      dataIndex: ['flowTypeRef', 'flowId'],
      width: 150,
      render: (flowId, record) => getEmployeeFlowType(ehConfig, record.EntityHierarchy, flowId, intl),

      ...filterColumnOptions({
        onFilter: (value, record) => {
          return filterByValue(value, getEmployeeFlowType(ehConfig, record.EntityHierarchy, record?.flowTypeRef?.flowId, intl))
        },

        filters: data.map((record) => {
          const value = getEmployeeFlowType(ehConfig, record.EntityHierarchy, record?.flowTypeRef?.flowId, intl)
          return {
            text: value,
            value,
          }
        }),
      }),
    },
    {
      title: intl.formatMessage({ id: 'common.email' }),
      width: 200,
      dataIndex: 'email',
      render: (val) => <Suppress>{val}</Suppress>,
      ...searchColumnOptions({
        onFilter: (value, record) => filterByValue(value, record.email),
      }),
    },
    {
      title: intl.formatMessage({ id: 'common.phoneNumber' }),
      width: 100,
      dataIndex: 'phoneNumber',
      render: (val) => <Suppress>{val}</Suppress>,
      ...searchColumnOptions({
        onFilter: (value, record) => filterByValue(value, record.phoneNumber),
      }),
    },
    ...conditionalColumn(hierarchyEnabledFlags.employeeLineManagers, {
      title: 'Line Managers',
      width: 150,
      dataIndex: 'lineManagers',
      render: (value) => (
        <div className='-ml-2'>
          <Cell.LineManagers value={value} />
        </div>
      ),
      filterSearch: true,
      ...filterColumnOptions({
        onFilter: (value: string, record) => record?.lineManagers?.includes?.(value),
        filters: data
          .flatMap((record) => record?.lineManagers)
          .filter(Boolean)
          .map((lineManager) => {
            return {
              text: lineManager,
              value: lineManager,
            }
          }),
      }),
    }),
    ...conditionalColumn(hierarchyEnabledFlags.employeeTags, {
      title: 'Employee Tags',
      width: 150,
      dataIndex: 'tags',
      key: 'tags',
      render: (value?: string[]) => (value?.[0] ? <TagsCell tags={value || []} width={200} title='Show Tags' /> : '—'),
      sorter: (a, b) => {
        return `${a?.tags?.join?.('')}`.toLowerCase().localeCompare(`${b?.tags?.join?.('')}`.toLowerCase())
      },
      filterSearch: true,
      ...filterColumnOptions({
        onFilter: (value: string, record) => record?.tags?.includes?.(value),
        filters: data
          .flatMap((record) => record?.tags)
          .filter(Boolean)
          .map((tag) => {
            return {
              text: tag,
              value: tag,
            }
          }),
      }),
    }),
    {
      title: 'Actions',
      key: 'actions',
      width: 100,
      className: 'text-center title-center',
      render: (record) => <Cell.Actions onClick={() => actions.setEditView(record)} record={record} />,
    },
  ]
}

export default function EmployeeDirectory() {
  const intl = getIntl()
  const dispatch = useDispatch()
  const dataSource = useSelector(getEmployeesDirectory)
  const loading = useSelector(getEmployeesDirectoryLoading)
  const [selectedRows, setSelectedRows] = useState<EmployeeDetails[]>([])
  const [searchValue, setSearchValue] = useState('')
  const [importView, setImportView] = useState(false)
  const [addView, setAddView] = useState(false)
  const [editView, setEditView] = useState<EmployeeDetails | undefined>(undefined)
  const entityHierarchyConfig = useSelector(getEntityHierarchyConfiguration)
  const containerRef = useRef<HTMLDivElement>(null)
  const hierarchyEnabledFlags = useHierarchyEnabledFlags()
  const profile = useProfile()
  const { applyFiltersToColumns, onChange, applyFiltersToData, hasFilters, clearAll } = useTableFilters<EmployeeDetails>()

  const columnSource = buildColumns(dataSource, entityHierarchyConfig, hierarchyEnabledFlags, intl, { setEditView })

  useEffect(() => {
    return () => {
      if (process.env.NODE_ENV !== 'development') dispatch(StoreActionCreators.StoreClearAllEmployeeDirectoryData.create())
    }
  }, [dispatch])

  useEffect(() => {
    dispatch(SagaActions.SagaRetrieveEmployeesDirectoryData.create())
    return () => {
      // clean state
      setSelectedRows([])
      setSearchValue('')
      // setImportView(false)
      // setAddView(false)
      // setEditView(undefined)
    }
  }, [dispatch])

  useEffect(() => {
    // clean state
    setSelectedRows([])
    setSearchValue('')
  }, [dataSource])

  const handleRefresh = React.useCallback(() => {
    dispatch(SagaActions.SagaRetrieveEmployeesDirectoryData.create())
  }, [dispatch])

  const handleSetSelect = React.useCallback((_selectedRowKeys, selectedRows: EmployeeDetails[]) => {
    setSelectedRows(selectedRows.filter((row) => row))
  }, [])

  const handleSearch = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    setSearchValue(e.target.value || '')
  }, [])

  const handleGoBack = React.useCallback(() => {
    setImportView(false)
    setAddView(false)
    setEditView(undefined)
  }, [])

  const data = React.useMemo(() => {
    return filterEmployeeSearch(dataSource, searchValue)
  }, [dataSource, searchValue])

  const filteredData = applyFiltersToData(data)

  const filteredColumns = useMemo(() => {
    return applyFiltersToColumns(
      columnSource.map((col, _index) => {
        const column = {
          ...col,
          onHeaderCell: (column) => {
            return {
              width: column?.width || 100,
              // onResize: handleResize(index),
              onReset: () => null,
            }
          },
        }

        return column
      })
    )
  }, [columnSource, applyFiltersToColumns])

  if (addView || editView) {
    return <ManageEmployee goBack={handleGoBack} dataSource={dataSource} employee={addView ? undefined : editView} />
  }

  const multipleSelectionActive = selectedRows.length > 0 && filteredData.length > 0

  const handleExport = (itemsToExport: EmployeeDetails[]) => {
    exportDirectory(applyFiltersToData(itemsToExport), entityHierarchyConfig, hierarchyEnabledFlags)
  }

  const exportEnabled = !profile.amDisableGlobalExport && !profile.amDisableEmployeeExport

  const clearAllFilters = () => {
    clearAll()
    setSearchValue('')
  }

  const hasActiveFilters = Boolean(searchValue?.length || hasFilters)

  return (
    <Page
      title={intl.formatMessage({ id: `kenai.${hierarchyEnabledFlags.employeeRouteType}.directory.title` })}
      extra={<EvacuationButton evacuationConfig={entityHierarchyConfig.additionalConfigs.evacuation} audience='employeeDirectory' />}
    >
      <Toolbar
        left={[<Input.Search placeholder='Search the directory' key='search' allowClear disabled={loading} onChange={handleSearch} />]}
      >
        {multipleSelectionActive && <DeleteAction data={selectedRows} intl={intl} hierarchyEnabledFlags={hierarchyEnabledFlags} />}

        {exportEnabled && (
          <ExportButton
            disabled={loading || !data.length}
            icon={<ExportOutlined />}
            onClick={() => handleExport(multipleSelectionActive ? selectedRows : filteredData)}
          />
        )}

        <Button icon={<DownloadOutlined />} onClick={() => setImportView(true)}>
          <FormattedMessage id='common.import' tagName='span' />
        </Button>
        <Button icon={<PlusOutlined />} onClick={() => setAddView(true)}>
          <FormattedMessage id='common.add' tagName='span' />
        </Button>
        <RefreshButton onClick={handleRefresh} loading={loading} />
      </Toolbar>

      <ClearAllTableFiltersButton hasFilter={hasActiveFilters} clearAll={clearAllFilters} />

      <div ref={containerRef}>
        <Table
          columns={filteredColumns}
          dataSource={data}
          bordered
          components={{
            header: {
              // cell: TableColumnResizeableTitle,
            },
          }}
          rowKey={(record) => record.personalIdentificationNr}
          loading={loading}
          rowSelection={{
            onChange: handleSetSelect,
          }}
          onChange={onChange}
          scroll={{ x: containerRef?.current?.offsetWidth ?? '100vw' }}
        />
      </div>
      <Action.Import goBack={handleGoBack} visible={importView} />
    </Page>
  )
}
