import styled from 'styled-components'
import cssvars from 'src/styles/cssvars'
// import { transition } from 'src/utils/style-util'

const dashboardStyle = (Component: any) => styled(Component)`
  && {
    .trigger {
      font-size: 18px;
      line-height: 64px;
      padding: 0 24px;
      cursor: pointer;
      transition: color 0.3s;
    }

    .trigger:hover {
      color: ${cssvars.primaryColor};
    }

    &.dashboard-container {
      height: 100vh;
    }

    .dashboard-header {
      box-shadow: 0px -6px 12px rgba(0, 0, 0, 0.25);
      z-index: 1;
    }
    .dashboard-sidebar {
      z-index: 20;
    }

    .dashboard-layout {
      overflow: hidden;
      max-height: 100vh;

      &.collapsed {
        @media only screen and (min-width: 768px) and (max-width: 1220px) {
          width: calc(100% - 80px);
          flex-shrink: 0;
        }
        @media only screen and (max-width: 767px) {
          width: 100%;
          flex-shrink: 0;
        }
      }
    }

    .dashboard-content {
      margin: 16px 16px;
      padding: 24px;
      min-height: 280;
    }
  }
`

export default dashboardStyle
