import { useEntityHierarchyConfig } from './use-entity-hierarchy-config'
import { useMemo } from 'react'
import AccessManager from 'src/utils/AccessManager'
import getNestedHierarchyTree from 'src/utils/getNestedHierarchyTree'

export const useNestedLocationData = (EntityHierarchy?: string) => {
  const entityHierarchyConfig = useEntityHierarchyConfig()

  return useMemo(() => {
    return getNestedHierarchyTree(entityHierarchyConfig, EntityHierarchy || AccessManager.selectedHierarchy.hierarchyStructure)
  }, [entityHierarchyConfig, EntityHierarchy])
}
