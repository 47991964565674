import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import getIntl from 'src/i18n'
import { getEntityHierarchyConfiguration } from 'src/redux/store/credentials'
import { FLOW_NATURES_ENUM } from 'src/typings/kenai/enums'
import AccessManager from 'src/utils/AccessManager'
import { EntityHierarchyConfig } from 'src/typings/kenai/configuration/entity-hierarchy'

export const getFlowTypesForHierarchy = (ehConfig: EntityHierarchyConfig, selectedHierarchy: string) => {
  const intl = getIntl()
  const selectedHierarchyFlowTypes = ehConfig.additionalConfigs.flowTypes?.[selectedHierarchy]
  if (selectedHierarchyFlowTypes) {
    const flows = selectedHierarchyFlowTypes
      ?.filter((flow) => flow.disabled !== true && flow.flowNature === FLOW_NATURES_ENUM.WORKER)
      ?.sort((a, b) => a.sortIndex - b.sortIndex)
      ?.map((flowtype) => ({
        label: intl.formatMessage({ id: flowtype.texts.flowDescriptionTextId }),
        value: flowtype.flowId,
      }))
    return flows || []
  } else {
    console.log('no flow types for selected hierarchy')
    return []
  }
}

export default function useFlowTypes(selectedHierarchy?: string) {
  const [flowTypes, setFlowTypes] = useState<Array<{ label: string; value: string }>>([])
  const entityHierarchyConfig = useSelector(getEntityHierarchyConfiguration)

  useEffect(() => {
    setFlowTypes(getFlowTypesForHierarchy(entityHierarchyConfig, selectedHierarchy || AccessManager.selectedHierarchy.hierarchyStructure))
  }, [selectedHierarchy, entityHierarchyConfig])

  return flowTypes
}
