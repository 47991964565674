import { Alert, Layout, Skeleton } from 'antd'
import React, { Suspense } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { GlobalChannelSagaActionCreators } from 'src/redux/sagas/subscriptions/globalEvent'
import { getEntityHierarchyConfiguration } from 'src/redux/store/credentials'
import { getGlobalEventsErrors } from 'src/redux/store/dashboard/globalevents'
import ErrorBoundary from 'src/components/error-boundary'
import { useLegacyLayout } from 'src/components/legacy-layout-provider'
import KenaiLoadingScreen from 'src/components/kenai-loading-screen'
import AccessManager from 'src/utils/AccessManager'
import configureAnalytics from 'src/utils/configureAnalytics'
import DashboardRouter from './Dashboard.router'
import style from './Dashboard.style'
import Header from './Header'
// import Sidebar from './Sidebar'
import useRoutes from './routes/useRoutes'
import { cn } from 'src/utils/classnames'

if (process.env.NODE_ENV === 'production') {
  configureAnalytics((AccessManager.tokenDetails && AccessManager.tokenDetails.sub) || 'no-identity')
}

const Sidebar = React.lazy(() => import('./Sidebar'))
const LegacySidebar = React.lazy(() => import('./Sidebar.legacy'))

const SidebarPreloader = () => (
  <Layout.Sider className='p-4'>
    <div className='w-full'>
      <Skeleton.Button className='w-full h-10 px-4' active />
      <Skeleton className='w-full mt-8' active title={false} paragraph={{ rows: 6, className: 'loading-menu-items' }} />
      <style jsx>{`
        :global(.loading-menu-items > li) {
          height: 20px !important;
          width: 100% !important;
        }
        :global(.loading-menu-items > li + li) {
          margin-top: 20px !important;
        }
      `}</style>
    </div>
  </Layout.Sider>
)

const Dashboard = (props) => {
  const entityHierarchyConfiguration = useSelector(getEntityHierarchyConfiguration, shallowEqual)
  const globalEventErrors = useSelector(getGlobalEventsErrors)
  const dispatch = useDispatch()
  const routes = useRoutes()
  const { isLegacyLayout } = useLegacyLayout()

  const isNotIframe = !window['KENAIFramePath']

  const loaded = entityHierarchyConfiguration.hasBeenLoaded
  if (!loaded) {
    return <KenaiLoadingScreen />
  }

  const onCloseAlert = (errorToFilter: string) => {
    const errors = globalEventErrors.filter((error) => error !== errorToFilter)
    dispatch(GlobalChannelSagaActionCreators.SagaGlobalSubscriptionErrors.create(errors))
  }

  return (
    <>
      {globalEventErrors.length > 0 &&
        globalEventErrors.map((error, i) => (
          <Alert key={i} type='error' message={error} banner closable onClose={() => onCloseAlert(error)} />
        ))}
      <Layout className={`dashboard-container ${props.className}`}>
        {isNotIframe ? (
          <ErrorBoundary>
            <Suspense fallback={<SidebarPreloader />}>
              {isLegacyLayout ? <LegacySidebar className='dashboard-sidebar' /> : <Sidebar routes={routes} />}
            </Suspense>
          </ErrorBoundary>
        ) : null}
        <Layout
          className={cn('dashboard-layout', {
            'bg-vars-componentBackground': !isLegacyLayout,
          })}
        >
          {isNotIframe ? (
            <ErrorBoundary>
              <Header className='px-0 dashboard-header' />
            </ErrorBoundary>
          ) : null}
          <div className='h-full overflow-y-auto dashboard-body'>
            <ErrorBoundary>
              <DashboardRouter />
            </ErrorBoundary>
          </div>
        </Layout>
      </Layout>
    </>
  )
}

export default style(React.memo(Dashboard))
