import CONSTANTS from './constants'
import { LogLevel } from '@azure/msal-browser'
const getMsalConfig = () => {
  return {
    auth: {
      clientId: CONSTANTS.AADB2CAUTH.CLIENTID_ACCESS,
      authority: `https://${CONSTANTS.AADB2CAUTH.DOMAIN}/${CONSTANTS.AADB2CAUTH.TENANT}/${CONSTANTS.AADB2CAUTH.POLICY_ACCESS}`,
      knownAuthorities: [CONSTANTS.AADB2CAUTH.DOMAIN],
      redirectUri: `${window.location.origin}/authenticate`,
      postLogoutRedirectUri: `${window.location.origin}/authenticate`,
      navigateToLoginRequestUrl: false,
    },
    cache: {
      cacheLocation: 'localStorage',
      storeAuthStateInCookie: false,
    },
    system: {
      loggerOptions: {
        loggerCallback: (level, message, containsPii) => {
          if (containsPii) {
            return
          }
          switch (level) {
            case LogLevel.Error:
              console.error(message)
              return
            case LogLevel.Info:
              if (process.env.NODE_ENV === 'development') {
                console.info(message)
              }
              return
            case LogLevel.Verbose:
              if (process.env.NODE_ENV === 'development') {
                console.debug(message)
              }
              return
            case LogLevel.Warning:
              if (process.env.NODE_ENV === 'development') {
                console.warn(message)
              }
              return
          }
        },
      },
    },
  }
}
export default getMsalConfig
