import { Moment } from 'moment'
import momentTZ from 'moment-timezone'

export default function getEventTiming(date = new Date()) {
  const timeSinceEpoch = date.valueOf()
  const timezoneOffset = date.getTimezoneOffset()
  const timeZone = momentTZ.tz.guess()
  return {
    timeSinceEpoch,
    timezoneOffset,
    timeZone,
  }
}

// PRE-MIGRATION START
export type EventTiming = ReturnType<typeof getEventTiming>

export const getTimezoneDate = (date: number | string, timezone: string = getEventTiming(new Date(date)).timeZone): Moment => {
  return momentTZ.utc(date).tz(timezone)
}
// PRE-MIGRATION END
