/* eslint-disable no-async-promise-executor */
import { AWSError, DynamoDB, S3 } from 'aws-sdk'
import aws_exports from 'src/aws-exports'
import { DocumentClient, QueryOutput } from 'aws-sdk/clients/dynamodb'
import { PromiseResult } from 'aws-sdk/lib/request'

// const tableNameKeys = aws_exports.aws_dynamodb_table_schemas.reduce(i => ({ tableName: i.tableName })) // better method to extract this?
// export type AWS_TABLE_NAMES = typeof tableNameKeys.tableName

let currentServiceCredentials: any = undefined

export const getCurrentServiceCredentials = () => {
  return new Promise<void>((resolve) => {
    resolve(currentServiceCredentials)
  })
}

export const setCurrentServiceCredentials = (serviceCredentials) => {
  return new Promise<void>((resolve) => {
    currentServiceCredentials = serviceCredentials
    resolve()
  })
}

export const getTableNameFromAwsExports = (requestedTable: string) => {
  let tableName = ''
  aws_exports.aws_dynamodb_table_schemas.map((table) => {
    if (table.tableName.indexOf(requestedTable) > -1) {
      tableName = table.tableName
    }
    return tableName
  })
  if (tableName.length === 0) {
    throw new Error('Table not found')
  }
  return tableName
}

export const getCredentialedDDBDocumentClientGeneric = (eh: string) => {
  const relevantCredential = currentServiceCredentials.ddb.find((cred) => cred.includedEH.indexOf(eh) > -1)
  if (relevantCredential) {
    return new DynamoDB.DocumentClient({
      region: aws_exports.aws_cognito_region,
      credentials: relevantCredential.credential,
    })
  } else {
    throw new Error('Not Authorized')
  }
}

export const getCredentialedDDBDocumentClientForRead = (eh: string) => {
  const relevantCredential = currentServiceCredentials.ddb.find((cred) => cred.includedEH.indexOf(`read-${eh}`) > -1)
  if (relevantCredential) {
    return new DynamoDB.DocumentClient({
      region: aws_exports.aws_cognito_region,
      credentials: relevantCredential.credential,
    })
  } else {
    throw new Error('Not Authorized')
  }
}

export const getCredentialedDDBDocumentClientForMutate = (eh: string) => {
  const relevantCredential = currentServiceCredentials.ddb.find((cred) => cred.includedEH.indexOf(`mutate-${eh}`) > -1)
  if (relevantCredential) {
    return new DynamoDB.DocumentClient({
      region: aws_exports.aws_cognito_region,
      credentials: relevantCredential.credential,
    })
  } else {
    throw new Error('Not Authorized')
  }
}

export const getCredentialedS3 = (eh: string) => {
  const relevantCredential = currentServiceCredentials.s3.find((cred) => cred.includedEH.indexOf(eh) > -1)
  if (relevantCredential) {
    return new S3({
      region: aws_exports.aws_cognito_region,
      signatureVersion: 'v4',
      credentials: relevantCredential.credential,
      useAccelerateEndpoint: true,
    })
  } else {
    throw new Error('Not Authorized')
  }
}

export const getCredentialedParentS3 = (eh: string) => {
  const hierarchyParts = eh.split('#')
  let parentHierarchy = ''
  if (hierarchyParts.length === 1) {
    parentHierarchy = hierarchyParts[0] + '*'
  } else {
    for (let i = 0; i < hierarchyParts.length - 1; i++) {
      parentHierarchy = parentHierarchy + hierarchyParts[i]
      if (i < hierarchyParts.length - 2) {
        parentHierarchy = parentHierarchy + '#'
      }
    }
    parentHierarchy = parentHierarchy + '*'
  }
  let relevantCredential = currentServiceCredentials.s3.find((cred) => cred.includedEH.indexOf(parentHierarchy) > -1)
  if (!relevantCredential) {
    //in case we are already at a parent level selection, let us check for a wildcard credential at this level
    parentHierarchy = eh + '*'
    relevantCredential = currentServiceCredentials.s3.find((cred) => cred.includedEH.indexOf(parentHierarchy) > -1)
  }

  if (relevantCredential) {
    return new S3({
      region: aws_exports.aws_cognito_region,
      signatureVersion: 'v4',
      credentials: relevantCredential.credential,
      useAccelerateEndpoint: true,
    })
  } else {
    throw new Error('Not Authorized')
  }
}

export async function queryDDBWithBatchLimits<T extends Record<any, any>>(
  requestedHierarchy: string,
  params: (...args: any[]) => DocumentClient.QueryInput
) {
  const createBatch = (...args) => {
    const dynamodbDocumentClient = getCredentialedDDBDocumentClientForRead(args[0])
    return dynamodbDocumentClient.query(params(...args)).promise()
  }
  const processBatch = (batch, depth): Promise<T[]> => {
    return new Promise(async (resolve) => {
      let batchResults: T[] = []
      const nextBatch: Promise<PromiseResult<QueryOutput, AWSError>>[] = []
      const asyncResults: PromiseResult<DocumentClient.QueryOutput, AWSError>[] = await Promise.all(batch)
      asyncResults.forEach((asyncResult) => {
        batchResults = batchResults.concat(asyncResult.Items as T[])
        if (asyncResult.hasOwnProperty('LastEvaluatedKey')) {
          nextBatch.push(createBatch(requestedHierarchy, asyncResult.LastEvaluatedKey))
        }
      })
      if (nextBatch.length > 0) {
        const nextBatchResults = await processBatch(nextBatch, depth + 1)
        batchResults = batchResults.concat(nextBatchResults)
      }
      resolve(batchResults)
    })
  }
  const initialBatch: Promise<PromiseResult<QueryOutput, AWSError>>[] = []
  initialBatch.push(createBatch(requestedHierarchy))
  const allResults = await processBatch(initialBatch, 1)
  return allResults
}
