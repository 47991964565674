import { API as AmplifyAPI } from 'aws-amplify'
import AccessManager from 'src/utils/AccessManager'
import { RequireExactlyOne } from 'type-fest'
import { ConfigValueCustomField } from 'src/typings/kenai/configuration/custom-fields'

type eventKeys = 'OPERATION_PROCESSED' | 'OPERATION_FAILED'

export type errorKeys =
  | 'DUPLICATE_DISABLED_FIELD'
  | 'DUPLICATE_ACTIVE_FIELD'
  | 'FIELD_INSTANCE_OBSOLETE'
  | 'CONSUMED_FIELD_CHANGE_WITHOUT_UNLOCK'
  | 'EDIT_NON_EXISTING_FIELD'
  | 'NO_UPDATE_RECEIVED'
  | 'NO_CONSUMERS_FOUND'
  | 'unknown'

type APIResponse<T extends Record<string, unknown>, K = any> =
  | (T & {
      key: K
    })
  | undefined

type ResponseBody = RequireExactlyOne<
  {
    error?: errorKeys
    fieldResult?: ConfigValueCustomField
    consumerUpdateResult?: 'ALL_CONSUMERS_UPDATED' | 'FAILED_TO_UPDATE_ALL_CONSUMERS'
  },
  'error' | 'fieldResult'
>

type RESPONSE = APIResponse<ResponseBody, eventKeys>
export type operations =
  | 'addField'
  | 'editField'
  | 'disableField'
  | 'enableField'
  | 'deleteField'
  | 'updateLockedFieldLabel'
  | 'updateLockedSDDListItem'
type fieldDetails = { EntityHierarchy: string; fieldId?: string } & Partial<Omit<ConfigValueCustomField, 'fieldId'>>

export async function customFieldProcessor(operation: operations, fieldDetails: fieldDetails) {
  try {
    return (await AmplifyAPI.post('provisioningOperations', '/customFieldProcessor', {
      headers: {
        Authorization: `Bearer ${AccessManager.idToken}`,
      },
      body: {
        operation,
        fieldDetails,
      },
    })) as RESPONSE
  } catch (err) {
    console.error(err)
    return undefined
  }
}
