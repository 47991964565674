import moment from 'moment'
import { all, call, fork, put, select, take, takeLeading } from 'redux-saga/effects'
import API from 'src/api'
import { ActionCreators as AuthenticationActionCreators } from 'src/redux/sagas/authentication'
import {
  ActionCreators as CredentialsStoreActionCreators,
  getEntityHierarchyConfiguration,
  getEvacResponsesSubscriptionCredential,
} from 'src/redux/store/credentials'
import { ActionCreators as DashboardActionCreators } from 'src/redux/store/dashboard/globalevents'
import ActionCreator from '../../saga-action-creator'

// Action Creators
export const ActionCreators = {
  SagaRefreshEvacResponsesSubscriptionCredential: new ActionCreator<'SagaRefreshEvacResponsesSubscriptionCredential', string>(
    'SagaRefreshEvacResponsesSubscriptionCredential'
  ),
  SagaEvacResponsesLiveUpdateHandler: new ActionCreator<'SagaEvacResponsesLiveUpdateHandler', any>('SagaEvacResponsesLiveUpdateHandler'),
  SagaEvacResponsesSubscriptionErrors: new ActionCreator<'SagaEvacResponsesSubscriptionErrors', string[]>(
    'SagaEvacResponsesSubscriptionErrors'
  ),
}

const AWSHelper = new API.AWSHelpers()

function* processRefreshSubscriptionCredential(action) {
  try {
    yield put(AuthenticationActionCreators.SagaRefreshApiCredentials.create())
    yield take(AuthenticationActionCreators.SagaApiCredentialsRefreshUpdate.type)
    const currentSubscriptionCredential = yield select(getEvacResponsesSubscriptionCredential)
    const clientId = action.payload
    let shouldRetrieveNewCredentials = true
    if (currentSubscriptionCredential && currentSubscriptionCredential.Expiration) {
      const expiry = moment(currentSubscriptionCredential.Expiration)
      const now = moment()
      const diff = expiry.diff(now)
      if (diff / 1000 > 60 * 5) {
        //allow for 5 minute clock skew
        shouldRetrieveNewCredentials = false
      }
    }
    if (shouldRetrieveNewCredentials) {
      const entityHierarchyConfiguration = yield select(getEntityHierarchyConfiguration)
      const topics: Array<string> = []
      entityHierarchyConfiguration.hierarchy.deepStructure.forEach((hierarchy) => {
        //just subscribe to the root
        const topicWithOutTrailingSlash = `evacResponses/${hierarchy.hierarchyStructure.replace(/#/g, '/')}`
        if (topics.length < 8) {
          //never subscribe to more than 8
          topics.push(topicWithOutTrailingSlash)
        }
      })

      const subscriptionCredential = yield call([AWSHelper, AWSHelper.retrieveSubscribeCredential], topics, clientId)
      if (subscriptionCredential.key === 'CREDENTIAL_RETRIEVED') {
        yield put(
          CredentialsStoreActionCreators.StoreSetEvacResponsesSubscriptionCredential.create({
            ...subscriptionCredential.credential,
            ts: Date.now(),
          })
        )
      } else {
        yield put(CredentialsStoreActionCreators.StoreSetEvacResponsesSubscriptionCredential.create(undefined))
      }
    } else if (!shouldRetrieveNewCredentials && currentSubscriptionCredential) {
      yield put(
        CredentialsStoreActionCreators.StoreSetEvacResponsesSubscriptionCredential.create({
          ...currentSubscriptionCredential,
          ts: Date.now(),
        })
      ) //triggers gDSfP in subscription component
    }
  } catch (e) {
    if (e) {
      console.error(e)
    }
    yield put(CredentialsStoreActionCreators.StoreSetEvacResponsesSubscriptionCredential.create(undefined))
  }
}

// function* processLiveUpdateHandler(action) {
//   try {
//     const eventPayload = action.payload
//     const evacuationEvents: any = []
//     //we don't track live update earliest timestamp for global channels thus not implemented here
//     for (let i = 0; i < eventPayload.events.length; i++) {
//       if (eventPayload.events[i].notificationType === 'EVACUATION') {
//         evacuationEvents.push(eventPayload.events[i])
//       }
//     }

//     yield put(EvacuationActionCreators.SagaEvacuationLiveUpdateHandler.create(evacuationEvents))
//   } catch (e) {
//     console.log(e)
//   }
// }

function* processSubscriptionErrors(action) {
  try {
    //for now switch this off until we are confident in stability and have updated subs handler with timeout support so we can delay raising of errors with 30 seconds
    // if (action.payload.length > 0) {
    if (action.payload.length < 0) {
      yield put(DashboardActionCreators.StoreSetErrors.create(action.payload))
    }
  } catch (e) {
    console.log(e)
  }
}

// Saga triggers
function* watchSagas() {
  yield takeLeading(ActionCreators.SagaRefreshEvacResponsesSubscriptionCredential.type, processRefreshSubscriptionCredential)
  yield takeLeading(ActionCreators.SagaEvacResponsesSubscriptionErrors.type, processSubscriptionErrors)
  yield null
}

function* watchLiveUpdateChannel() {
  // const SagaGlobalLiveUpdateHandlerChannel = yield actionChannel(ActionCreators.SagaEvacResponsesLiveUpdateHandler.type)
  // while (true) {
  // const action = yield take(SagaGlobalLiveUpdateHandlerChannel)
  // yield call(processLiveUpdateHandler, action)
  // }
}

// Saga hooks
export default function* globalEventsSagas() {
  yield all([fork(watchSagas), fork(watchLiveUpdateChannel)])
}
