import { Button, Input, Modal } from 'antd'
import React, { useState, useEffect, ReactNode } from 'react'
import { FormattedMessage } from 'react-intl'
import FormField from 'src/components/r-form/form-field'
import { faSpinnerThird } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { genericLabel } from 'src/utils/generic-label'

const hideKeys = [
  'uniqueAttributeValue',
  'shouldSendNotification',
  'onBehalfOf',
  'isLoadingAdditional',
  'parkingBookingKey',
  'loadIncomplete',
]

type DetailViewProps = { [key: string]: any }

const DetailedView = (props: DetailViewProps) => {
  const details = Object.entries(props).filter((key) => !hideKeys.includes(key[0]))
  return (
    <div className='space-y-3'>
      {details.map(([key, value], i) => {
        let label
        if (key === 'ProfileId') label = <FormattedMessage id='common.profile-id' />
        else if (key === 'name') label = <FormattedMessage id='common.host-name' />
        else if (key === 'firstName') label = <FormattedMessage id='common.first-name' />
        else if (key === 'lastName') label = <FormattedMessage id='common.last-name' />
        else if (key === 'personalIdentificationNr') label = <FormattedMessage id='common.personalIdentificationNr' />
        else if (key === 'phone_number') label = <FormattedMessage id='common.phoneNumber' />
        else if (key === 'EntityHierarchy') label = <FormattedMessage id='common.titles.location' />
        else if (key === 'company') label = <FormattedMessage id='common.titles.company' />
        else if (key === 'email') label = <FormattedMessage id='common.email' />
        else if (key === 's3ImageKey') label = <FormattedMessage id='common.image-key' />
        else if (key === 's3ImagePath') label = <FormattedMessage id='common.image-path' />
        else if (key === 'parkingBookingRefCode') label = <FormattedMessage id='common.parking-booking-reference' />
        else if (key === 'parkingBookingStartDTms') label = <FormattedMessage id='common.parking-booking-starttime' />
        else if (key === 'parkingBookingEndDTms') label = <FormattedMessage id='common.parking-booking-endtime' />
        else if (key === 'assignedParkingSectionBayGroup') label = <FormattedMessage id='common.parking-bay-group' />
        else if (key === 'assignedParkingLot') label = <FormattedMessage id='common.parking-lot' />
        else if (key === 'assignedParkingSectionParkingArea') label = <FormattedMessage id='common.parking-area' />
        else if (key === 'assignedParkingSectionClassification') label = <FormattedMessage id='common.parking-classification' />
        else if (key === 'assignedParkingAllocationGroup') label = <FormattedMessage id='common.parking-allocationgroup' />
        else {
          // console.log(key, 'label missing')
          label = genericLabel(key)
        }

        return (
          <FormField key={i} label={label} inline={false}>
            <Input
              readOnly
              value={value}
              className={'data-hj-suppress rr-block hover:border-vars-borderColor focus:border-vars-borderColor'}
            />
          </FormField>
        )
      })}
      {props.isLoadingAdditional ? (
        <div className={`loading`}>
          <FontAwesomeIcon icon={faSpinnerThird} className='grid-icon loading anticon anticon-link anticon-spin' />
          <span className='grid-text meta editable text'>
            <span>
              <FormattedMessage id='kenai.table.helper-cells.details.loading-additional-data' />
            </span>
          </span>
        </div>
      ) : null}
    </div>
  )
}

interface DetailsCell {
  buttonText?: string | ReactNode
  title: string
  details: DetailViewProps
  additionalDataLoader?: () => Promise<{ [key: string]: any }>
  className?: string
}

export default function DetailsCell({ buttonText = 'View', details, title, additionalDataLoader, className = '', ...other }: DetailsCell) {
  const [visible, setVisible] = useState(false)
  const [isLoadingAdditional, setIsLoadingAdditional] = useState(false)
  const [hasTriggeredAdditionalLoad, setHasTriggeredAdditionalLoad] = useState(false)
  const [additionalData, setAdditionalData] = useState({})
  useEffect(() => {
    if (additionalDataLoader && visible && !hasTriggeredAdditionalLoad) {
      const executeLoader = async () => {
        setHasTriggeredAdditionalLoad(true)
        const additionalDataResolved = await additionalDataLoader()
        if (!additionalDataResolved.loadIncomplete) {
          setAdditionalData(additionalDataResolved)
        }
        setIsLoadingAdditional(false)
      }
      setIsLoadingAdditional(true)
      executeLoader()
    }
  }, [visible, hasTriggeredAdditionalLoad, additionalDataLoader])

  const toggleModal = () => {
    setVisible((flag) => !flag)
  }

  return (
    <div className={className}>
      <Button type='link' onClick={toggleModal} className='p-0 m-0 h-auto'>
        {buttonText}
      </Button>
      <Modal title={<FormattedMessage id={title} />} visible={visible} onOk={toggleModal} onCancel={toggleModal} footer={null} centered>
        <DetailedView {...{ isLoadingAdditional, ...details, ...other, ...additionalData }} />
      </Modal>
    </div>
  )
}
