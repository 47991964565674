import { faBell, faCheckCircle, faTriangleExclamation } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Form, Tag, Tooltip } from 'antd'
import React from 'react'
import { useSelector } from 'react-redux'
import { EvacUpdateType } from 'src/api/evacOperations'
import { getEvacuationStatusConfig } from 'src/components/evacuation'
import { TableCellDate } from 'src/components/table/table-cell'
import { getEvacuationConfig } from 'src/redux/store/credentials'
import { EvacResponse, EvacuationStatus } from 'src/typings/kenai/configuration/entity-hierarchy'
import classnames from 'src/utils/classnames'
import { EvacuationUpdate } from './evacuation-update'
import { getSignedOutForEvac } from './utils/get-signed-out-for-evac'

interface EvacuationProps {
  EntityHierarchy: string
  currentEvacResponse?: EvacResponse
  evtTimeStampOut?: number | 'still on premises'
  isOutOfScope?: boolean
}

interface EvacuationCellProps extends EvacuationProps {
  /**
   * The unique id for the entry
   * - `evtTimeStampProfileID` for EMPLOYEES or VISITORS
   * - `evtTimeStampUniqueAttributeValue` for PARKING
   */
  id: string
  type: EvacUpdateType
}

type EvacuationStatusWithNA = EvacuationStatus | 'N/A'

export const getEvacuationStatus = (
  evacResponse?: EvacResponse,
  eventTimeStampOut?: number | 'still on premises',
  evacTimeStamp?: number | null,
  isOutOfScope?: boolean
): EvacuationStatusWithNA => {
  if (isOutOfScope) {
    return 'N/A'
  }
  const isSignedOut = getSignedOutForEvac(evacTimeStamp, eventTimeStampOut)
  if (isSignedOut) {
    return 'SIGNED_OUT'
  }

  const { status } = evacResponse || {}

  switch (status) {
    case 'SAFE':
    case 'NOT_SAFE':
      return status
    case undefined:
      return evacTimeStamp ? 'NOTIFIED' : 'N/A'
    case 'NOTIFIED':
      return evacTimeStamp ? 'NOTIFIED' : 'N/A'
    default:
      return 'UNKNOWN'
  }
}
type Status = ReturnType<typeof getEvacuationStatus>

export const getStatusTime = (props: EvacuationProps, status: Status) => {
  let statusTime = 0
  if (status === 'SAFE' || status === 'NOT_SAFE') {
    statusTime = props.currentEvacResponse.responseTimestamp
  } else if (status === 'SIGNED_OUT' && props.evtTimeStampOut !== 'still on premises') {
    statusTime = props.evtTimeStampOut
  }
  return statusTime
}

const getStatusChannel = (props: EvacuationProps, status: Status) => {
  return status === 'SAFE' || status === 'NOT_SAFE' ? props.currentEvacResponse.responseChannel.toUpperCase() : undefined
}

export const evacStatusTexts = {
  'N/A': 'N/A',
  NOT_SAFE: 'Not Safe',
  NOTIFIED: 'Notified',
  SAFE: 'Safe',
  SIGNED_OUT: 'Signed-out',
  UNKNOWN: 'Unknown',
}

const StatusComponent = (props: { status: EvacuationStatusWithNA; date?: number; channel?: string }) => {
  const statusText = evacStatusTexts[props.status]

  const colorClass = classnames({
    'text-green-400': props.status === 'SIGNED_OUT' || props.status === 'SAFE',
    'text-error': props.status === 'NOT_SAFE',
    'text-warning': props.status === 'NOTIFIED',
  })

  const icons = {
    SIGNED_OUT: faCheckCircle,
    SAFE: faCheckCircle,
    NOT_SAFE: faTriangleExclamation,
    'N/A': null,
    NOTIFIED: faBell,
    UNKNOWN: null,
  }

  const blinker = classnames({
    'blinker 2s linear infinite': props.status === 'NOT_SAFE' || props.status === 'UNKNOWN' || props.status === 'NOTIFIED',
  })

  const icon = icons[props.status]

  return (
    <div className='flex items-center gap-2 text-left'>
      {icon && (
        <FontAwesomeIcon icon={icons[props.status]} title={status} className={`text-3xl ${colorClass}`} style={{ animation: blinker }} />
      )}

      <div>
        <div className='flex space-x-2 items-center justify-between'>
          <span>{statusText}</span> {props.channel && <span className='text-xs text-gray-400'>{props.channel}</span>}
        </div>
        {!!props.date && <div className='text-xs text-gray-400'>{TableCellDate.format(props.date)}</div>}
      </div>
    </div>
  )
}

const EvacuationCell: React.FC<EvacuationCellProps> = (props) => {
  const { EntityHierarchy, currentEvacResponse, evtTimeStampOut, isOutOfScope } = props
  const evacuationConfig = useSelector(getEvacuationConfig)
  const { evacuationActive, evacuationMutable, evacuationEvent } = getEvacuationStatusConfig(
    evacuationConfig,
    EntityHierarchy,
    undefined,
    isOutOfScope
  )
  const status = getEvacuationStatus(currentEvacResponse, evtTimeStampOut, evacuationEvent?.activatedTimestamp, isOutOfScope)
  const time = getStatusTime(props, status)
  const channel = getStatusChannel(props, status)

  const content = (
    <Tooltip
      overlayClassName='invert rounded-md no-padding'
      overlayStyle={{ maxWidth: '300px', width: '100%' }}
      overlay={
        currentEvacResponse?.comment ? (
          <div
            className='px-4 py-2'
            onClick={(e) => {
              e.preventDefault()
              e.stopPropagation()
            }}
          >
            <Form layout='vertical' size='small'>
              {currentEvacResponse?.comment && (
                <Form.Item label='Comment'>
                  <Tag className='w-full whitespace-normal p-2'>{currentEvacResponse.comment}</Tag>
                </Form.Item>
              )}
            </Form>
          </div>
        ) : undefined
      }
    >
      <div>
        <StatusComponent status={status} date={time} channel={channel} />
      </div>
    </Tooltip>
  )

  if (!evacuationMutable || !evacuationActive) {
    return content
  }

  return (
    <>
      <EvacuationUpdate userData={{ EntityHierarchy: props.EntityHierarchy, id: props.id, type: props.type }}>
        <Button type='link' className='p-0'>
          {content}
        </Button>
      </EvacuationUpdate>
    </>
  )
}

export default EvacuationCell
