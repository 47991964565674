import update from 'immutability-helper'
import { ActionCreator } from '../store-action-creator'
import { createSelector } from 'reselect'
import { RootState } from '../rootReducer'

// Action Creators
export const ActionCreators = {
  StoreSetLoadingState: new ActionCreator<'StoreSetLoadingState', Partial<ConfigurationState['loadingState']>>('StoreSetLoadingState'),
  StoreSetFullValueConfiguration: new ActionCreator<'StoreSetFullValueConfiguration', Partial<ConfigurationState['values']>>(
    'StoreSetFullValueConfiguration'
  ),
  StoreSetFullConfigurabilityConfiguration: new ActionCreator<
    'StoreSetFullConfigurabilityConfiguration',
    Partial<ConfigurationState['configurability']>
  >('StoreSetFullConfigurabilityConfiguration'),
  StoreSetTextsConfiguration: new ActionCreator<'StoreSetTextsConfiguration', Partial<ConfigurationState['values']['texts']>>(
    'StoreSetTextsConfiguration'
  ),
  StoreSetFeaturesConfiguration: new ActionCreator<'StoreSetFeaturesConfiguration', Partial<ConfigurationState['values']['featureConfig']>>(
    'StoreSetFeaturesConfiguration'
  ),
  StoreSetImagesConfiguration: new ActionCreator<'StoreSetImagesConfiguration', Partial<ConfigurationState['values']['images']>>(
    'StoreSetImagesConfiguration'
  ),
  StoreSetInductionConfiguration: new ActionCreator<'StoreSetInductionConfiguration', Partial<ConfigurationState['values']['induction']>>(
    'StoreSetInductionConfiguration'
  ),
  StoreSetBrandingColorsConfiguration: new ActionCreator<
    'StoreSetBrandingColorsConfiguration',
    Partial<ConfigurationState['values']['brandingColors']>
  >('StoreSetBrandingColorsConfiguration'),
  StoreSetLocationConfiguration: new ActionCreator<
    'StoreSetLocationConfiguration',
    Partial<ConfigurationState['values']['locationConfig']>
  >('StoreSetLocationConfiguration'),
  StoreClearAllConfiguration: new ActionCreator<'StoreClearAllConfiguration', void>('StoreClearAllConfiguration'),
  StoreSetErrorState: new ActionCreator<'StoreSetErrorState', Partial<ConfigurationState['errorState']>>('StoreSetErrorState'),
  ClearAllStates: new ActionCreator<'v', void>('v'),
}

export type Action = typeof ActionCreators[keyof typeof ActionCreators]

export type State = ConfigurationState

// State
export const initialState = (): State => {
  return {
    values: {
      texts: {
        companyName: '',
        welcomeMessage: '',
        showTextsOnLanding: false,
      },
      featureConfig: {
        badgePrintingAutoCut: false,
        badgePrintingEnabled: false,
        badgeReprintAllowed: false,
        voiceFeedbackEnabled: false,
      },
      images: {
        lmt: 0,
        logoImage: '',
      },

      induction: {
        inductionEnabled: false,
        channels: {
          kiosk: {
            enabled: false,
            validityType: {
              visitor: 100,
              employee: 100,
            },
            video: {
              duration: 0,
              version: 0,
              state: 'INITIAL',
            },
            questions: {
              enabled: false,
              randomOrder: false,
              amountToAsk: 0,
              minimumPassCount: 0,
              maximumRetryBeforeRewatch: 0,
            },
          },
          preRegistration: {
            enabled: false,
            validity: 100,
            video: {
              duration: 0,
              version: 0,
              state: 'INITIAL',
            },
            questions: {
              enabled: false,
              randomOrder: false,
              amountToAsk: 0,
              minimumPassCount: 0,
              maximumRetryBeforeRewatch: 0,
            },
          },
          employeeLink: {
            enabled: false,
            validity: 100,
            video: {
              duration: 0,
              version: 0,
              state: 'INITIAL',
            },
            questions: {
              enabled: false,
              randomOrder: false,
              amountToAsk: 0,
              minimumPassCount: 0,
              maximumRetryBeforeRewatch: 0,
            },
          },
          screeningRoom: {
            enabled: false,
            validityType: {
              visitor: 365,
              employee: 365,
            },
            version: 0,
          },
        },
      },
      brandingColors: {
        primary: 'rgba(106,180,100,1)',
        secondary: 'rgba(18,39,56,1)',
        tertiary: 'rgba(18,39,56,1)',
        text: 'rgba(255,255,255,1)',
      },
      locationConfig: {
        tododesign: '',
      },
    },
    configurability: {
      texts: {
        editing: false,
        enabled: true,
      },
      featureConfig: {
        editing: false,
        enabled: true,
      },
      images: {
        editing: false,
        enabled: true,
      },
      induction: {
        editing: false,
        enabled: true,
      },
      brandingColors: {
        editing: false,
        enabled: true,
      },
      locationConfig: {
        editing: false,
        enabled: false,
      },
    },
    loadingState: {
      texts: true,
      featureConfig: true,
      images: true,
      induction: true,
      brandingColors: true,
      locationConfig: true,
      transactionProcessing: false,
    },
    errorState: {
      texts: false,
      featureConfig: false,
      images: false,
      induction: false,
      brandingColors: false,
      locationConfig: false,
      transactionProcessing: false,
    },
  }
}

// Reducer
export default function reducer(state: State = initialState(), action: any) {
  let partialState = state

  if (action.type === ActionCreators.StoreSetLoadingState.type) {
    partialState = update(state, {
      loadingState: {
        $merge: action.payload,
      },
    })
  }

  if (action.type === ActionCreators.StoreSetFullValueConfiguration.type) {
    partialState = update(state, {
      values: {
        $set: action.payload,
      },
    })
  }

  if (action.type === ActionCreators.StoreSetFullConfigurabilityConfiguration.type) {
    partialState = update(state, {
      configurability: {
        $set: action.payload,
      },
    })
  }

  if (action.type === ActionCreators.StoreSetTextsConfiguration.type) {
    partialState = update(state, {
      values: {
        texts: {
          $set: action.payload,
        },
      },
    })
  }

  if (action.type === ActionCreators.StoreSetFeaturesConfiguration.type) {
    partialState = update(state, {
      values: {
        featureConfig: {
          $set: action.payload,
        },
      },
    })
  }

  if (action.type === ActionCreators.StoreSetImagesConfiguration.type) {
    partialState = update(state, {
      values: {
        images: {
          $set: action.payload,
        },
      },
    })
  }

  if (action.type === ActionCreators.StoreSetInductionConfiguration.type) {
    partialState = update(state, {
      values: {
        induction: {
          $set: action.payload,
        },
      },
    })
  }

  if (action.type === ActionCreators.StoreSetBrandingColorsConfiguration.type) {
    partialState = update(state, {
      values: {
        brandingColors: {
          $set: action.payload,
        },
      },
    })
  }

  if (action.type === ActionCreators.StoreSetLocationConfiguration.type) {
    partialState = update(state, {
      values: {
        locationConfig: {
          $set: action.payload,
        },
      },
    })
  }

  if (action.type === ActionCreators.StoreSetErrorState.type) {
    partialState = update(state, {
      errorState: {
        $merge: action.payload,
      },
    })
  }

  if (action.type === ActionCreators.StoreClearAllConfiguration.type) {
    partialState = initialState()
  }

  if (action.type === ActionCreators.ClearAllStates.type) {
    partialState = initialState()
  }

  return partialState != null ? { ...state, ...partialState } : state
}

//selectors

const getBrandColorsState = (state: RootState) => state.configuration.values.brandingColors
export const getBrandColors = createSelector<
  RootState,
  ConfigurationState['values']['brandingColors'],
  ConfigurationState['values']['brandingColors']
>([getBrandColorsState], (state) => state)

const getConfigurationState = (state: RootState) => state.configuration

export const getConfiguration = createSelector<RootState, ConfigurationState, ConfigurationState>([getConfigurationState], (state) => state)

const getLogoConfigState = (state: RootState) => state.configuration.values.images

export const getLogoConfig = createSelector<RootState, ConfigurationState['values']['images'], ConfigurationState['values']['images']>(
  [getLogoConfigState],
  (state) => state
)

const getConfigValuesState = (state: RootState) => state.configuration.values
export const getConfigValues = createSelector<RootState, ConfigurationState['values'], ConfigurationState['values']>(
  [getConfigValuesState],
  (state) => state
)
