import update from 'immutability-helper'
import { createSelector } from 'reselect'
import { getTimeRange } from 'src/utils/getTimeRange'
import { RootState } from '../rootReducer'
import { ActionCreator } from '../store-action-creator'
import { ParkingLogSearchableEventSet } from '../../../typings/kenai/parking/log'
import { RangePickerNumberValue } from 'src/typings/vendor/rangepicker'

// Action Creators
export const ActionCreators = {
  StoreSetFetchingParkingLog: new ActionCreator<'StoreSetFetchingParkingLog', State['isFetching']>('StoreSetFetchingParkingLog'),
  StoreSetParkingLogData: new ActionCreator<'StoreSetParkingLogData', State['data']>('StoreSetParkingLogData'),
  StoreSetParkingLogRawData: new ActionCreator<'StoreSetParkingLogRawData', State['rawData']>('StoreSetParkingLogRawData'),
  StoreSetParkingDateRanges: new ActionCreator<'StoreSetParkingDateRanges', State['dateRanges']>('StoreSetParkingDateRanges'),
  StoreSetParkingActiveKeys: new ActionCreator<'StoreSetParkingActiveKeys', State['activeKeys']>('StoreSetParkingActiveKeys'),
  StoreClearAllParkingLogData: new ActionCreator<'StoreClearAllParkingLogData', void>('StoreClearAllParkingLogData'),
  ClearAllStates: new ActionCreator<'ClearAllStates', void>('ClearAllStates'),
}

export type Action = typeof ActionCreators[keyof typeof ActionCreators]

export type State = {
  data: ParkingLogSearchableEventSet[]
  rawData: any
  isFetching: boolean
  dateRanges: RangePickerNumberValue
  activeKeys: string[]
}

export function initialParkingLogActivekeys() {
  // check if filtering has been done and saved to localStorage else falls back to available keys in enum
  // should also be able to pass in 'dynamic' keys once we integrate the log config
  const storedActiveKeys = localStorage.getItem('parkinglog-column-filter')
  let activeKeys: string[] = []
  if (storedActiveKeys) {
    activeKeys = JSON.parse(storedActiveKeys)
  }

  return activeKeys
}

export const initialState = (): State => ({
  data: [],
  rawData: [],
  isFetching: false,
  dateRanges: getTimeRange('today'),
  activeKeys: initialParkingLogActivekeys(),
})

// Reducer
export default function reducer(state: State = initialState(), action: Action) {
  switch (action.type) {
    case ActionCreators.StoreSetFetchingParkingLog.type:
      return update(state, {
        isFetching: { $set: action.payload },
      })
    case ActionCreators.StoreSetParkingLogData.type:
      return update(state, {
        data: { $set: action.payload },
      })
    case ActionCreators.StoreSetParkingLogRawData.type:
      return update(state, {
        rawData: { $set: action.payload },
      })
    case ActionCreators.StoreSetParkingDateRanges.type:
      return update(state, {
        dateRanges: { $set: action.payload },
      })
    case ActionCreators.StoreSetParkingActiveKeys.type:
      return update(state, {
        activeKeys: { $set: action.payload },
      })
    case ActionCreators.StoreClearAllParkingLogData.type || ActionCreators.ClearAllStates.type:
      return initialState()
    default:
      return state
  }
}

// Selectors
const getParkingLogState = (state: RootState) => state.parking.parkingLog.data
export const getParkingLog = createSelector<any, any, State['data']>([getParkingLogState], (state: State['data']) => {
  return state
})

const getParkingLogRawEventItemsState = (state: RootState) => state.parking.parkingLog.rawData
export const getParkingLogRawEventItems = createSelector<any, any, State['rawData']>(
  [getParkingLogRawEventItemsState],
  (state: State['rawData']) => {
    return state
  }
)

const getParkingLogFetchingDataState = (state: RootState) => state.parking.parkingLog.isFetching
export const getParkingFetchingData = createSelector<any, any, State['isFetching']>(
  [getParkingLogFetchingDataState],
  (state: State['isFetching']) => {
    return state
  }
)

const getParkingDateRangesState = (state: RootState) => state.parking.parkingLog.dateRanges
export const getParkingDateRanges = createSelector<any, any, State['dateRanges']>(
  [getParkingDateRangesState],
  (state: State['dateRanges']) => {
    return state
  }
)

const getParkingActiveKeysState = (state: RootState) => state.parking.parkingLog.activeKeys
export const getParkingActiveKeys = createSelector<any, any, State['activeKeys']>(
  [getParkingActiveKeysState],
  (state: State['activeKeys']) => {
    return state
  }
)
