import { Modal, Typography } from 'antd'
import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import useHierarchyEnabledFlags from 'src/hooks/use-hierarchy-enabled-flags'
import { notification } from 'src/utils'
import { EmployeeImportResponse } from '../../utils/import'
import ViewImport from './view-import'
import ViewValidate from './view-validate'

interface ImportEmployees {
  goBack: () => void
  visible: boolean
}

export default function ImportEmployees({ goBack, visible }: ImportEmployees) {
  const [processType, setProcessType] = useState<'update' | 'replace'>('update')
  const [flowTypeRef, setFlowTypeRef] = useState('')
  const [currentStep, setStep] = useState<'import' | 'validate' | 'upload'>('import')
  const [data, setData] = useState<EmployeeImportResponse>()
  const intl = useIntl()
  const { employeeRouteType } = useHierarchyEnabledFlags()
  const translatedRouteType = intl.formatMessage({ id: `common.${employeeRouteType}` })
  const translatedRouteTypeSingular = intl.formatMessage({ id: `common.${employeeRouteType}-singular` })

  useEffect(() => {
    if (data) {
      if (flowTypeRef && processType) setStep('validate')
      else {
        notification(
          'warning',
          !flowTypeRef
            ? `You need to select a ${translatedRouteTypeSingular} type`
            : !processType
            ? 'You need to select an upload method'
            : 'No valid data found to upload'
        )
      }
    }
  }, [flowTypeRef, processType, data, translatedRouteTypeSingular])

  const handleSetData = (newData: typeof data) => {
    setData(newData)
  }

  const handleClearData = () => {
    setProcessType('update')
    setStep('import')
    setFlowTypeRef('')
    setData(undefined)
  }

  const handleClose = () => {
    handleClearData()
    goBack()
  }

  return (
    <Modal
      title={<Typography.Text className='ant-typography mb-4 text-3xl font-light'>Bulk {translatedRouteType} Import</Typography.Text>}
      // width='50vw'
      visible={visible}
      onCancel={handleClose}
      cancelButtonProps={{ danger: true }}
      footer={null}
      destroyOnClose
      className='min-w-screen-sm'
    >
      {currentStep === 'import' && (
        <ViewImport
          setProcessType={setProcessType}
          setFlowTypeRef={setFlowTypeRef}
          flowTypeRef={flowTypeRef}
          processType={processType}
          setData={handleSetData}
          // hasData={data}
          onFinish={handleClose}
        />
      )}
      {currentStep === 'validate' && flowTypeRef && data && (
        <ViewValidate
          data={data}
          setData={handleSetData}
          processType={processType}
          goBack={handleClose}
          clearData={handleClearData}
          flowTypeRef={flowTypeRef}
        />
      )}
    </Modal>
  )
}
