import { faTags } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Popover, Tag } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import { calculateTextWidth } from 'src/utils/calculateTextWidth'

export default function TagsCell(props: { tags: string[]; width?: number; title?: string }) {
  const [showSplit, setShowSplit] = useState(getTagsBiggerThanWidth({ ...props, width: props.width || 100 }))
  const tagsContainer = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (tagsContainer.current) {
      const { width = 100 } = tagsContainer.current.getBoundingClientRect()
      setShowSplit(getTagsBiggerThanWidth({ tags: props.tags, width }))
    }
  }, [props.tags])

  const Tags = props.tags.map((tag, i) => (
    <Tag color='processing' key={i} className='my-1'>
      {tag.toUpperCase()}
    </Tag>
  ))

  return (
    <div className='w-full' ref={tagsContainer}>
      {showSplit ? (
        <Popover content={<>{Tags}</>} trigger={['hover']} placement='top' overlayClassName='max-w-md'>
          <Button size='small' icon={<FontAwesomeIcon icon={faTags} />} ghost className='border-none shadow-none'>
            {props?.title}
          </Button>
        </Popover>
      ) : (
        Tags
      )}
    </div>
  )
}

function getTagsBiggerThanWidth(props: { tags: string[]; width: number }) {
  const tagsWidth = calculateTextWidth(
    props.tags.join(', '),
    "tabular-nums 12px Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'"
  )
  const margin = 8 * props.tags.length
  const padding = 7 * 2 * props.tags.length
  return tagsWidth + margin + padding > props.width - 6 * props.tags.length
}
