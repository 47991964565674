import { faMessage, faLightEmergencyOn } from '@fortawesome/pro-light-svg-icons'
import { lazy } from 'react'
import { HierarchyEnabledFlags } from 'src/hooks/use-hierarchy-enabled-flags'
import { ROUTE } from 'src/typings/kenai/dashboard-router'
import { conditionalArrayItem } from 'src/utils/conditionalReturn'

const EvacuationEvents = lazy(() => import('src/containers/Messaging/Evacuation'))
const EvacuationLog = lazy(() => import('src/containers/Messaging/Evacuation/EvacuationResponseLog'))
const DisabledView = lazy(() => import('src/components/disabled-view'))

function join(...segments) {
  // Use Array.map to remove leading and trailing slashes from each segment (except spaces or empty strings)
  const cleanedSegments = segments.map((segment) => segment.replace(/^\/+|\/+$/g, ''))

  // Use Array.filter to remove any empty strings that might result from the cleaning process
  const nonEmptySegments = cleanedSegments.filter((segment) => segment.length > 0)

  // Join the cleaned and filtered segments with a single slash
  return nonEmptySegments.join('/')
}

export default function getMessagingRoutes(hierarchyEnabledFlags: HierarchyEnabledFlags): ROUTE {
  const flags = hierarchyEnabledFlags.messaging

  const hasChildren = Boolean(Object.values(flags).filter(Boolean).length)

  const basePath = 'messaging'

  return {
    icon: faMessage,
    label: 'Messaging',
    path: basePath,
    sidebar: true,
    component: hasChildren ? undefined : DisabledView,
    children: !hasChildren
      ? undefined
      : [
          ...conditionalArrayItem<ROUTE>(flags.evacuation, {
            icon: faLightEmergencyOn,
            path: join(basePath, 'evacuation'),
            sidebar: true,
            label: 'Evacuation',
            component: EvacuationEvents,
            exact: true,
          }),
          ...conditionalArrayItem<ROUTE>(flags.evacuation, {
            path: join(basePath, 'evacuation', ':entry'),
            component: EvacuationLog,
            sidebar: false,
            exact: true,
          }),
        ],
  }
}
