import { faBars, faChevronRight } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Layout, Space } from 'antd'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ErrorBoundary from 'src/components/error-boundary'
import { useLegacyLayout } from 'src/components/legacy-layout-provider'
import { ActionCreators, getSidebar } from 'src/redux/store/dashboard/sidebar'
import { cn } from 'src/utils/classnames'
import HierarchySelect from './HierarchySelect'
import User from './User'

const { Header } = Layout

interface HeaderProps {
  className?: string
}

const LayoutHeader: React.FC<HeaderProps> = ({ className = '' }) => {
  const { collapsed } = useSelector(getSidebar)
  const dispatch = useDispatch()
  const { isLegacyLayout } = useLegacyLayout()

  const handleToggleSidebar = () => {
    dispatch({ type: ActionCreators.StoreToggleSidebarCollapse.type })
    dispatch({ type: ActionCreators.StoreToggleSidebarAutoCollapse.type })
  }

  return (
    <Header style={{ zIndex: 1 }} className={cn('bg-red', className)}>
      <div className='flex items-center justify-between px-8'>
        <div className='text-lg cursor-pointer text-vars-textColor hover:text-vars-primaryColor transition-color'>
          <div onClick={handleToggleSidebar} className='hamburger-button'>
            <FontAwesomeIcon icon={collapsed ? faChevronRight : faBars} />
          </div>
        </div>
        <ErrorBoundary message='Location Selector Error'>
          <HierarchySelect />
        </ErrorBoundary>
        {isLegacyLayout ? (
          <Space>
            {/* <Popover
              trigger='hover'
              content={
                <div className='max-w-xs'>
                  <Space align='start'>
                    <span role='img' aria-label='innovation' className='text-3xl'>
                      🚀
                    </span>
                    <Typography.Paragraph>
                      <Typography.Link onClick={() => setCurrentLayout('latest')}>Try the new layout</Typography.Link> and {"don't"}{' '}
                      hesitate to send us your feedback.
                    </Typography.Paragraph>
                  </Space>
                  <div className='text-center'>
                    <small className='text-vars-disabledColor'>you can switch back to the legacy layout via the account settings.</small>
                  </div>
                </div>
              }
              placement='bottomRight'
            >
              <div className='flex w-8 h-full text-center'>
                <Badge count='new' color='red' className='leading-relaxed'>
                  <NotificationOutlined className='text-3xl' />
                </Badge>
              </div>
            </Popover> */}
            <User className='text-4xl text-vars-textColor' />
          </Space>
        ) : (
          <div />
        )}
      </div>
      <style jsx global>{`
        body:not(.legacy-layout-enabled) .hamburger-hidden .hamburger-button {
          display: none;
        }
      `}</style>
    </Header>
  )
}

export default LayoutHeader
