import { config as AWSConfig } from 'aws-sdk'
import CONSTANTS from './constants'
import { string } from 'prop-types'
import { idTokenPayload, AuthCredentials } from 'src/redux/store/credentials/types/credentials'

export interface TokenDetails extends idTokenPayload {
  isValid: boolean
}

const DefaultTokenValues = () => {
  return {
    nonce: '',
    email: '',
    aud: '',
    iss: '',
    exp: 0,
    iat: 0,
    'https://app.kenai.co.za/app_metadata': {
      umEmail: '',
      amMTHA: '',
      amOrganizationId: '',
    },
    'https://app.kenai.co.za/user_metadata': {
      umName: '',
    },
    isValid: false,
    kna_Authorizations: string,
    kna_OrganizationId: string,
    kna_DisplayName: string,
  }
}

const decodeToken = (token) => {
  try {
    if (token) {
      return JSON.parse(atob(token.split('.')[1]))
    } else {
      throw Error
    }
  } catch (e) {
    throw Error(e)
  }
}

const verifyTimeAndDate = (exp, iat) => {
  const now = new Date()
  const expDate = new Date(0)
  const iatDate = new Date(0)

  expDate.setUTCSeconds(exp - 60 * 5) // always check 5 mins back so we early cycle

  if (now > expDate) {
    return false
  }

  iatDate.setUTCSeconds(iat - 120) // always check 2 mins back for IAT

  if (now < iatDate) {
    return false
  }
  return true
}

export default function verifyTokenSync(currentUserCredentials: Partial<AuthCredentials>) {
  let token
  try {
    if (currentUserCredentials.idToken) {
      // token = jwt_decode<TokenDetails>(credentials.idToken)
      token = decodeToken(currentUserCredentials.idToken)
      if (
        (CONSTANTS.AUTH_PROVIDER === 'AUTH0' &&
          (token.nonce !== currentUserCredentials.nonce || token.aud !== CONSTANTS.AUTH.AUD || token.iss !== CONSTANTS.AUTH.ISS)) ||
        (CONSTANTS.AUTH_PROVIDER === 'AADB2C' &&
          (token.nonce !== currentUserCredentials.nonce ||
            token.aud !== CONSTANTS.AADB2CAUTH.AUD ||
            token.iss !== CONSTANTS.AADB2CAUTH.ISS)) ||
        verifyTimeAndDate(token.exp, token.iat) === false
      ) {
        token = DefaultTokenValues()
        token.isValid = false
        AWSConfig.update({
          // set aws config invalid upon bad token
          credentials: undefined,
        })
      } else {
        if (
          CONSTANTS.AUTH_PROVIDER === 'AUTH0' &&
          token['https://app.kenai.co.za/app_metadata'] &&
          token['https://app.kenai.co.za/app_metadata'].hasOwnProperty('amMTHA')
        ) {
          token.isValid = true
        } else if (CONSTANTS.AUTH_PROVIDER === 'AADB2C' && token.kna_Authorizations) {
          token.isValid = true
        } else {
          token.isValid = false
        }
      }
    } else {
      token = DefaultTokenValues()
      token.isValid = false
      AWSConfig.update({
        // set aws config invalid upon bad token
        credentials: undefined,
      })
    }
  } catch (e) {
    token = DefaultTokenValues()
    token.isValid = false
    AWSConfig.update({
      // set aws config invalid upon bad token
      credentials: undefined,
    })
  }
  return token as TokenDetails
}
