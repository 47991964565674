import { getTableNameFromAwsExports, queryDDBWithBatchLimits } from '../utils'
import { Key } from 'aws-sdk/clients/dynamodb'

export interface DeviceData {
  badgePrinterConnected: boolean
  ConnectionId: string
  deviceUID: string
  ect: number
  EntityHierarchy: string
  isInUse: boolean
  lmt: string
  queueId: string
  queueURL: string
}

export async function retrieveEntityDevices(requestedHierarchy: string) {
  const params = (batchHierarchy: string, ExclusiveStartKey?: Key) => ({
    ExpressionAttributeValues: {
      ':hk': batchHierarchy,
    },
    KeyConditionExpression: 'EntityHierarchy = :hk',
    TableName: getTableNameFromAwsExports('tdDeviceConnectionState'),
    ExclusiveStartKey,
  })
  return await queryDDBWithBatchLimits<DeviceData>(requestedHierarchy, params)
}
