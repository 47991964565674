import {
  all,
  fork,
  takeLeading,
  call,
  put,
  select,
  delay,
  take, // call, take, select
} from 'redux-saga/effects'
import { ActionCreators as HostsActionCreators, getHostDirectory } from 'src/redux/store/visitors/hosts'
import ActionCreator from '../saga-action-creator'
import API from 'src/api'
import { HostDetails, HostKeyRef } from 'src/redux/store/visitors/types/hosts'
import AccessManager from 'src/utils/AccessManager'
import { unionBy, cloneDeep } from 'lodash'
import { ActionCreators as AuthenticationActionCreators } from 'src/redux/sagas/authentication'
import { HOST_DATA_SOURCE_ENUM } from 'src/typings/kenai/enums'

// Action Creators
export const ActionCreators = {
  SagaRetrieveHostDirectoryData: new ActionCreator<'SagaRetrieveHostDirectoryData', void>('SagaRetrieveHostDirectoryData'),
  SagaAddSingleHost: new ActionCreator<'SagaAddSingleHost', HostDetails>('SagaAddSingleHost'),
  SagaEditHost: new ActionCreator<'SagaEditHost', HostDetails>('SagaEditHost'),
  SagaUpsertMultipleHost: new ActionCreator<'SagaUpsertMultipleHost', HostDetails[]>('SagaUpsertMultipleHost'),
  SagaUpdateMultipleHost: new ActionCreator<'SagaUpdateMultipleHost', HostDetails[]>('SagaUpdateMultipleHost'),
  SagaDeleteHost: new ActionCreator<'SagaDeleteHost', HostDetails[]>('SagaDeleteHost'),
  SagaDeleteEntityHostList: new ActionCreator<'SagaDeleteEntityHostList', HostDetails[]>('SagaDeleteEntityHostList'),
  SagaResetSyncStatus: new ActionCreator<'SagaResetSyncStatus', HostKeyRef>('SagaResetSyncStatus'),
}

const AWSHelper = new API.AWSHelpers()

function* processRetrieveHostDirectoryData() {
  try {
    yield put(HostsActionCreators.StoreSetFetchingHostData.create(true))
    yield put(AuthenticationActionCreators.SagaRefreshApiCredentials.create())
    yield take(AuthenticationActionCreators.SagaApiCredentialsRefreshUpdate.type)
    const entityHierarchy = AccessManager.selectedHierarchy.hierarchyStructure
    const hostDirectory: HostDetails[] = yield call([AWSHelper, AWSHelper.getHostsDirectory], entityHierarchy)
    yield delay(0)
    yield put(HostsActionCreators.StoreSetHostDirectoryData.create(hostDirectory))
    yield put(HostsActionCreators.StoreSetFetchingHostData.create(false))
  } catch (e) {
    yield put(HostsActionCreators.StoreSetFetchingHostData.create(false))
    if (e) {
      console.error(e)
    }
  }
}

function* processSagaAddSingleHost(action) {
  try {
    yield put(HostsActionCreators.StoreSetAddingHosts.create(true))
    yield put(AuthenticationActionCreators.SagaRefreshApiCredentials.create())
    yield take(AuthenticationActionCreators.SagaApiCredentialsRefreshUpdate.type)
    const EntityHierarchy = AccessManager.selectedHierarchy.hierarchyStructure
    const hostDetails = action.payload
    const singleHost = {
      EntityHierarchy,
      uniqueAttributeValue: hostDetails.email,
      ...hostDetails,
    }
    const result = yield call([AWSHelper, AWSHelper.addHosts], EntityHierarchy, [singleHost])
    if (result.key === 'OPERATIONS_PROCESSED') {
      const hostDirectoryData: HostDetails[] = yield select(getHostDirectory)
      const updatedHosts: HostDetails[] = unionBy([singleHost], hostDirectoryData, 'uniqueAttributeValue')
      yield put(HostsActionCreators.StoreSetHostDirectoryData.create(updatedHosts))
      action.callback({ status: 'success' })
      yield put(HostsActionCreators.StoreSetAddingHosts.create(false))
    } else {
      throw new Error(`Error while adding host: ${result.key}`)
    }
  } catch (e) {
    yield put(HostsActionCreators.StoreSetAddingHosts.create(false))
    if (e) {
      console.error(e)
      action.callback({ status: 'error' })
    } else {
      action.callback({ status: 'error' })
    }
  }
}

function* processSagaUpsertMultipleHost(action) {
  try {
    yield put(HostsActionCreators.StoreSetAddingHosts.create(true))
    yield put(AuthenticationActionCreators.SagaRefreshApiCredentials.create())
    yield take(AuthenticationActionCreators.SagaApiCredentialsRefreshUpdate.type)
    const EntityHierarchy = AccessManager.selectedHierarchy.hierarchyStructure
    const hosts = action.payload

    const hostsToAdd = hosts.map((host) => {
      const addHost = {
        EntityHierarchy: EntityHierarchy,
        uniqueAttributeValue: host.email.toLowerCase(),
        email: host.email.toLowerCase(),
        name: host.name,
        ...(host.phone_number ? { phone_number: host.phone_number } : {}),
        assistants: host.assistants,
        disableEmailNotification: host.disableEmailNotification,
        disablePhoneNotification: host.disablePhoneNotification,
      }
      return addHost
    })

    const result = yield call([AWSHelper, AWSHelper.addHosts], EntityHierarchy, hostsToAdd)
    if (result.key === 'OPERATIONS_PROCESSED') {
      const hostDirectoryData: HostDetails[] = yield select(getHostDirectory)
      const updatedHosts: HostDetails[] = unionBy(hostsToAdd, hostDirectoryData, 'uniqueAttributeValue')
      yield put(HostsActionCreators.StoreSetHostDirectoryData.create(updatedHosts))
      action.callback({ status: 'success', isAsyncProcessing: result.isAsyncProcessing })
      yield put(HostsActionCreators.StoreSetAddingHosts.create(false))
    } else {
      throw new Error(`Error while upserting multiple hosts: ${result.key}`)
    }
  } catch (e) {
    yield put(HostsActionCreators.StoreSetAddingHosts.create(false))
    if (e) {
      action.callback({ status: 'error', error: e.message })
      console.error(e)
    } else {
      action.callback({ status: 'error', error: 'Failed to add host' })
    }
  }
}

function* processSagaEditHost(action) {
  try {
    yield put(HostsActionCreators.StoreSetAddingHosts.create(true))
    yield put(AuthenticationActionCreators.SagaRefreshApiCredentials.create())
    yield take(AuthenticationActionCreators.SagaApiCredentialsRefreshUpdate.type)
    const EntityHierarchy = AccessManager.selectedHierarchy.hierarchyStructure
    const hostDetails = action.payload
    const singleHost = {
      EntityHierarchy,
      uniqueAttributeValue: hostDetails.email,
      ...hostDetails,
    }
    const result = yield call([AWSHelper, AWSHelper.addHosts], EntityHierarchy, [singleHost])
    if (result.key === 'OPERATIONS_PROCESSED') {
      const hostDirectoryData: HostDetails[] = yield select(getHostDirectory)
      const hostChangeIndex = hostDirectoryData.findIndex(
        (host) => host.EntityHierarchy === singleHost.EntityHierarchy && host.uniqueAttributeValue === singleHost.uniqueAttributeValue
      )
      const updatedHosts: HostDetails[] = cloneDeep(hostDirectoryData)
      updatedHosts[hostChangeIndex] = {
        ...singleHost,
      }
      yield put(HostsActionCreators.StoreSetHostDirectoryData.create(updatedHosts))
      action.callback({ status: 'success', isAsyncProcessing: result.isAsyncProcessing })
      yield put(HostsActionCreators.StoreSetAddingHosts.create(false))
    } else {
      throw new Error(`Error while editing host: ${result.key}`)
    }
  } catch (e) {
    yield put(HostsActionCreators.StoreSetAddingHosts.create(false))
    if (e) {
      console.error(e)
      action.callback({ status: 'error', error: e.message })
    } else {
      action.callback({ status: 'error', error: 'Failed to edit host' })
    }
  }
}

function* processSagaDeleteHost(action) {
  try {
    yield put(HostsActionCreators.StoreSetDeletingHosts.create(true))
    yield put(AuthenticationActionCreators.SagaRefreshApiCredentials.create())
    yield take(AuthenticationActionCreators.SagaApiCredentialsRefreshUpdate.type)
    const EntityHierarchy = AccessManager.selectedHierarchy.hierarchyStructure
    const hosts = action.payload

    const hostsToDelete = hosts.map((host) => ({
      EntityHierarchy: host.EntityHierarchy,
      uniqueAttributeValue: host.uniqueAttributeValue,
    }))
    const result = yield call([AWSHelper, AWSHelper.deleteHosts], EntityHierarchy, hostsToDelete)
    if (result.key === 'OPERATIONS_PROCESSED') {
      const hostDirectoryData = yield select(getHostDirectory)
      const updatedHosts = cloneDeep(hostDirectoryData).filter(
        (host) =>
          hostsToDelete.findIndex(
            (hostToDelete) =>
              hostToDelete.EntityHierarchy === host.EntityHierarchy && hostToDelete.uniqueAttributeValue === host.uniqueAttributeValue
          ) === -1
      )
      yield put(HostsActionCreators.StoreSetHostDirectoryData.create(updatedHosts))
      action.callback({ status: 'success', isAsyncProcessing: result.isAsyncProcessing })
      yield put(HostsActionCreators.StoreSetDeletingHosts.create(false))
    } else {
      throw new Error(`Error while deleting host: ${result.key}`)
    }
  } catch (e) {
    yield put(HostsActionCreators.StoreSetDeletingHosts.create(false))
    if (e) {
      console.error(e)
      action.callback({ status: 'error', error: e.message })
    } else {
      action.callback({ status: 'error', error: 'Failed to delete host' })
    }
  }
}

function* processSagaDeleteEntityHostList(action) {
  try {
    yield put(HostsActionCreators.StoreSetDeletingHosts.create(true))
    yield put(AuthenticationActionCreators.SagaRefreshApiCredentials.create())
    yield take(AuthenticationActionCreators.SagaApiCredentialsRefreshUpdate.type)
    const EntityHierarchy = AccessManager.selectedHierarchy.hierarchyStructure
    const result = yield call([AWSHelper, AWSHelper.deleteEntityHostList], EntityHierarchy)
    if (result.key === 'OPERATIONS_PROCESSED') {
      yield put(HostsActionCreators.StoreSetHostDirectoryData.create([]))
      action.callback({ status: 'success' })
      yield put(HostsActionCreators.StoreSetDeletingHosts.create(false))
    } else {
      throw new Error(`Error while deleting host list: ${result.key}`)
    }
  } catch (e) {
    yield put(HostsActionCreators.StoreSetDeletingHosts.create(false))
    if (e) {
      console.error(e)
      action.callback({ status: 'error', error: e.message })
    } else {
      action.callback({ status: 'error', error: 'Failed to delete host' })
    }
  }
}

function* processSagaResetSyncStatus(action) {
  try {
    yield put(AuthenticationActionCreators.SagaRefreshApiCredentials.create())
    yield take(AuthenticationActionCreators.SagaApiCredentialsRefreshUpdate.type)
    const { EntityHierarchy, uniqueAttributeValue } = action.payload
    const result = yield call([AWSHelper, AWSHelper.resetHostSyncStatus], EntityHierarchy, uniqueAttributeValue)
    if (result.key === 'OPERATIONS_PROCESSED') {
      const hostDirectoryData: HostDetails[] = yield select(getHostDirectory)
      const hostChangeIndex = hostDirectoryData.findIndex(
        (host) => host.EntityHierarchy === EntityHierarchy && host.uniqueAttributeValue === uniqueAttributeValue
      )
      const updatedHosts: HostDetails[] = cloneDeep(hostDirectoryData)
      updatedHosts[hostChangeIndex].dataSource = HOST_DATA_SOURCE_ENUM.ADHOSTSYNC
      yield put(HostsActionCreators.StoreSetHostDirectoryData.create(updatedHosts))
      action.callback({ status: 'success' })
    } else {
      throw new Error(`Error while resetting sync status: ${result.key}`)
    }
  } catch (e) {
    if (e) {
      console.error(e)
      action.callback({ status: 'error', error: e.message })
    } else {
      action.callback({ status: 'error', error: 'Failed to reset sync status' })
    }
  }
}

// Saga triggers
function* watchReportingSagas() {
  yield takeLeading(ActionCreators.SagaRetrieveHostDirectoryData.type, processRetrieveHostDirectoryData)
  yield takeLeading(ActionCreators.SagaAddSingleHost.type, processSagaAddSingleHost)
  yield takeLeading(ActionCreators.SagaUpsertMultipleHost.type, processSagaUpsertMultipleHost)
  yield takeLeading(ActionCreators.SagaEditHost.type, processSagaEditHost)
  yield takeLeading(ActionCreators.SagaDeleteHost.type, processSagaDeleteHost)
  yield takeLeading(ActionCreators.SagaDeleteEntityHostList.type, processSagaDeleteEntityHostList)
  yield takeLeading(ActionCreators.SagaResetSyncStatus.type, processSagaResetSyncStatus)

  yield null
}

// Saga hooks
export default function* reportingSagas() {
  yield all([fork(watchReportingSagas)])
}
