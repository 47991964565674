import { InductionLogData } from 'src/typings/kenai/induction-log'
import { getTableNameFromAwsExports, queryDDBWithBatchLimits } from '../utils'
import { Key } from 'aws-sdk/clients/dynamodb'
import { EntityHierarchyConfig } from 'src/typings/kenai/configuration/entity-hierarchy'

export async function inductionLogRetrieval(beginTime: number, endTime: number, entityHierarchy: string, ehConfig: EntityHierarchyConfig) {
  let hierarchies: string[] = []
  if (entityHierarchy === 'ALL' && ehConfig && ehConfig.tenantFlags) {
    hierarchies = ehConfig.tenantFlags.map((availableEntity) => availableEntity.EntityHierarchy)
  } else if (ehConfig && ehConfig.hierarchy.flatStructure[entityHierarchy].children.length > 0) {
    const addRecursive = (additionalChildren) => {
      for (const prop of additionalChildren) {
        hierarchies.push(prop.hierarchyStructure)
        if (prop.children && prop.children.length > 0) {
          addRecursive(prop.children)
        }
      }
    }
    hierarchies.push(entityHierarchy)
    addRecursive(ehConfig.hierarchy.flatStructure[entityHierarchy].children)
  } else {
    hierarchies.push(entityHierarchy)
  }

  const params = (batchHierarchy: string, ExclusiveStartKey?: Key) => ({
    ExpressionAttributeValues: {
      ':e': batchHierarchy,
      ':bt': `${beginTime}`,
      ':et': `${endTime}`,
    },
    KeyConditionExpression: 'EntityHierarchy = :e AND evtTimeStampProfileID BETWEEN :bt AND :et',
    TableName: getTableNameFromAwsExports('tdCompletedInductions'),
    ExclusiveStartKey,
  })
  const asyncOps: Promise<InductionLogData[]>[] = []
  hierarchies.forEach((hierarchy) => asyncOps.push(queryDDBWithBatchLimits<InductionLogData>(hierarchy, params)))
  const results = await Promise.all(asyncOps)
  return results.flat()
}
