import { SizeType } from 'antd/lib/config-provider/SizeContext'
import React, { useEffect, useState } from 'react'
import PhoneInput, { PhoneInputProps } from 'react-phone-number-input'
import 'react-phone-number-input/style.css' //should import or require with component
import { cn } from 'src/utils/classnames'

const lsKey = 'kenai-dashboard-country'

interface PhoneNumberInputProps extends Partial<Omit<PhoneInputProps, 'size'>> {
  size?: SizeType
}

const PhoneNumberInput = ({ onChange = () => null, className = '', disabled, size = 'middle', ...other }: PhoneNumberInputProps) => {
  const [country, setCountry] = useState(localStorage.getItem(lsKey) || 'ZA')

  useEffect(() => {
    async function getDefaultCountry() {
      const res = await fetch('https://ipinfo.kenai.co.za')
      if (res.ok) {
        const info = await res.json()
        if (info.country_code) {
          localStorage.setItem(lsKey, info.country_code)
          setCountry(info.country_code)
        }
      }
    }
    const localSavedCountry = localStorage.getItem(lsKey)
    if (localSavedCountry) {
      setCountry(localSavedCountry)
    } else {
      getDefaultCountry()
    }
  }, [])

  return (
    <PhoneInput
      defaultCountry={country}
      className={cn('default-input ant-input data-hj-suppress rr-block ', className, {
        'ant-input-lg': size === 'large',
        'ant-input-sm': size === 'small',
        'ant-input-disabled': disabled,
      })}
      onChange={onChange}
      disabled={disabled}
      {...other}
    />
  )
}

export default PhoneNumberInput
