import { all, fork, put, take, takeLeading, takeEvery } from 'redux-saga/effects'
import { customFieldProcessor, errorKeys as fieldProcessorErrorKeys } from 'src/api/provisioningOperations/customFieldProcessor'
import { getSettingsCustomFields } from 'src/api/settings/settings-general-resources'
import ObsoleteRefresh from 'src/containers/Settings/General/resources-custom-fields/utils/obsolete-refresh'
import { refreshApiCredentials } from 'src/redux/sagas/authentication/refreshApiCredentials'
import {
  ActionCreators as SagaCredentialActionCreators,
  ActionCreators as SagaCredentialsActionCreators,
} from 'src/redux/sagas/credentials'
import ActionCreator from 'src/redux/sagas/saga-action-creator'
import { ActionCreators as StoreActionCreators, getCustomFields } from 'src/redux/store/settings/general-resources/custom-fields'
import { notification } from 'src/utils'
import AccessManager from 'src/utils/AccessManager'
import { call, select } from 'typed-redux-saga'
import { withInputType } from '../../utils/with-input-type'
import { ConfigValueCustomField } from 'src/typings/kenai/configuration/custom-fields'

type BasePayload = { EntityHierarchy: string }

type UpsertPayload = BasePayload & Partial<Omit<ConfigValueCustomField, 'fieldId'>>
type RemovePayload = BasePayload & { lmt: number; fieldId: string; hasConfirmedConsumptionRemoval?: boolean }
type EditPayload = BasePayload & { operation: 'editField' | 'updateLockedFieldLabel' | 'updateLockedSDDListItem' } & Partial<
    ConfigValueCustomField
  >
type EnablePayload = BasePayload & { fieldId: string; lmt: number; enabled: boolean }
// Action Creators
export const ActionCreators = {
  SagaRetrieveCustomFields: new ActionCreator<'SagaRetrieveCustomFields', string>('SagaRetrieveCustomFields'),
  SagaAddCustomFields: new ActionCreator<'SagaAddCustomFields', UpsertPayload>('SagaAddCustomFields'),
  SagaRemoveCustomFields: new ActionCreator<'SagaRemoveCustomFields', RemovePayload>('SagaRemoveCustomFields'),
  SagaEditCustomFields: new ActionCreator<'SagaEditCustomFields', EditPayload>('SagaEditCustomFields'),
  SagaEnabledCustomFields: new ActionCreator<'SagaEnabledCustomFields', EnablePayload>('SagaEnabledCustomFields'),
  SagaRefreshCustomFieldConfigs: new ActionCreator<'SagaRefreshCustomFieldConfigs', string>('SagaRefreshCustomFieldConfigs'),
  SagaRefreshCustomFieldConfigsUpdate: new ActionCreator<'SagaRefreshCustomFieldConfigsUpdate', any>('SagaRefreshCustomFieldConfigsUpdate'),
}

const getErrorMessage = (key: fieldProcessorErrorKeys) => {
  if (key === 'DUPLICATE_DISABLED_FIELD') {
    return 'There is an existing disabled field with this field name, delete or re-enable the existing field to continue.'
  }
  if (key === 'DUPLICATE_ACTIVE_FIELD') return 'The field already exists.'
  if (key === 'FIELD_INSTANCE_OBSOLETE') return 'The field has changed since last operation.'
  if (key === 'CONSUMED_FIELD_CHANGE_WITHOUT_UNLOCK') return 'The field has been consumed since last operation.'
  if (key === 'EDIT_NON_EXISTING_FIELD') return 'The field has been delete since last operation.'
  if (key === 'NO_UPDATE_RECEIVED') return 'Nothing has changed.'
  if (key === 'NO_CONSUMERS_FOUND') return 'The field was unlocked but not consumed by any flows or notifications.'
  return key
}

function* processSagaRetrieveCustomFields(action: typeof ActionCreators['SagaRetrieveCustomFields']) {
  try {
    yield put(StoreActionCreators.StoreSetCustomFieldsLoading.create(true))
    yield refreshApiCredentials()
    const selectedHierarchy = action.payload || AccessManager.selectedHierarchy.hierarchyStructure
    const customFields = yield call(getSettingsCustomFields, selectedHierarchy)
    // @ts-ignore
    yield put(StoreActionCreators.StoreSetCustomFields.create(withInputType(customFields)))
    yield put(StoreActionCreators.StoreSetCustomFieldsLoading.create(false))
    if (action.callback) action.callback({ status: 'success' })
  } catch (e) {
    console.error(e)
    yield put(StoreActionCreators.StoreSetCustomFieldsLoading.create(false))
    notification('error', 'We could not load the custom fields, please try again')
    if (action.callback) action.callback({ status: 'failed' })
  }
}

function* processSagaAddCustomFields(action: typeof ActionCreators['SagaAddCustomFields']) {
  try {
    yield put(StoreActionCreators.StoreSetCustomFieldsLoading.create(true))

    yield refreshApiCredentials()

    const result = yield* call(customFieldProcessor, 'addField', action.payload)

    if (result?.key !== 'OPERATION_PROCESSED') throw new Error(result?.error ?? 'The operation failed')

    const customFields = [...(result.fieldResult ? [result.fieldResult] : []), ...(yield* select(getCustomFields))]

    if (result.fieldResult) {
      yield put(SagaCredentialActionCreators.SagaGetConfigI18nTexts.create(action.payload.EntityHierarchy))
      yield put(StoreActionCreators.StoreSetCustomFields.create(withInputType(customFields)))
    }

    yield put(StoreActionCreators.StoreSetCustomFieldsLoading.create(false))
    if (action.callback) action.callback({ status: 'success' })
  } catch (e) {
    console.error(e)
    yield put(StoreActionCreators.StoreSetCustomFieldsLoading.create(false))
    if (!ObsoleteRefresh.showObsolete(e.message)) {
      notification('error', getErrorMessage(e.key ?? e.message))
    }
    if (action.callback) action.callback({ status: 'failed' })
  }
}

function* processSagaRemoveCustomFields(action: typeof ActionCreators['SagaRemoveCustomFields']) {
  try {
    yield refreshApiCredentials()

    const result = yield* call(customFieldProcessor, 'deleteField', action.payload)

    if (result?.key !== 'OPERATION_PROCESSED') throw new Error(result?.error ?? 'The operation failed')

    const customFields = yield* select(getCustomFields)

    if (result.fieldResult) {
      yield put(
        StoreActionCreators.StoreSetCustomFields.create(
          withInputType(customFields.filter((field) => field.fieldId !== action.payload.fieldId))
        )
      )
    }

    if (action.callback) action.callback({ status: 'success' })
  } catch (e) {
    console.error(e)
    if (!ObsoleteRefresh.showObsolete(e.message)) {
      notification('error', getErrorMessage(e.key ?? e.message))
    }
    if (action.callback) action.callback({ status: 'failed' })
  }
}

function* processSagaEditCustomFields(action: typeof ActionCreators['SagaEditCustomFields']) {
  try {
    yield put(StoreActionCreators.StoreSetCustomFieldsLoading.create(true))
    yield refreshApiCredentials()

    const { operation, ...payload } = action.payload

    const result = yield* call(customFieldProcessor, operation, payload)

    if (result?.key !== 'OPERATION_PROCESSED') throw new Error(result?.error ?? 'The operation failed')

    const customFields = yield* select(getCustomFields)
    const { fieldResult } = result
    if (fieldResult) {
      yield put(ActionCreators.SagaRefreshCustomFieldConfigs.create(action.payload.EntityHierarchy))
      yield take(ActionCreators.SagaRefreshCustomFieldConfigsUpdate.type)
      yield put(
        StoreActionCreators.StoreSetCustomFields.create(
          withInputType(customFields.map((field) => (field.fieldId === action.payload.fieldId ? fieldResult : field)))
        )
      )
    }

    yield put(StoreActionCreators.StoreSetCustomFieldsLoading.create(false))
    if (action.callback) action.callback({ status: 'success' })
  } catch (e) {
    console.error(e)
    yield put(StoreActionCreators.StoreSetCustomFieldsLoading.create(false))

    if (!ObsoleteRefresh.showObsolete(e.message)) {
      notification('error', getErrorMessage(e.key ?? e.message))
    }

    if (action.callback) action.callback({ status: 'failed', message: e.key ?? e.message })
  }
}

function* processSagaEnableCustomFields(action: typeof ActionCreators['SagaEnabledCustomFields']) {
  try {
    yield refreshApiCredentials()

    const { enabled, ...fieldDetails } = action.payload

    const result = yield* call(customFieldProcessor, enabled ? 'enableField' : 'disableField', fieldDetails)

    if (result?.key !== 'OPERATION_PROCESSED') throw new Error(result?.error ?? 'The operation failed')

    const customFields = yield* select(getCustomFields)
    if (result.fieldResult) {
      yield put(
        StoreActionCreators.StoreSetCustomFields.create(
          withInputType(customFields?.map((field) => (field.fieldId === action.payload.fieldId ? result.fieldResult : field)))
        )
      )
    }

    if (action.callback) action.callback({ status: 'success' })
  } catch (e) {
    console.error(e)

    if (!ObsoleteRefresh.showObsolete(e.message)) {
      notification('error', getErrorMessage(e.key ?? e.message))
    }

    if (action.callback) action.callback({ status: 'failed', message: e.key ?? e.message })
  }
}

function* processSagaRefreshCustomFieldConfigs(action: typeof ActionCreators['SagaRefreshCustomFieldConfigs']) {
  try {
    yield refreshApiCredentials()

    yield put(SagaCredentialsActionCreators.SagaGetConfigCustomFields.create(action.payload))
    yield put(SagaCredentialsActionCreators.SagaGetConfigCustomNotifications.create(action.payload))
    yield put(SagaCredentialsActionCreators.SagaGetConfigFlowTypes.create(action.payload))
    yield put(SagaCredentialsActionCreators.SagaGetConfigI18nTexts.create(action.payload))
    yield put(ActionCreators.SagaRefreshCustomFieldConfigsUpdate.create())

    if (action.callback) action.callback({ status: 'success' })
  } catch (e) {
    console.error(e)
    notification('error', getErrorMessage(e.key ?? e.message))
    if (action.callback) action.callback({ status: 'failed', message: e.key ?? e.message })
  }
}

// Saga triggers
function* watchCustomFieldsSagas() {
  yield takeLeading(ActionCreators.SagaRetrieveCustomFields.type, processSagaRetrieveCustomFields)
  yield takeLeading(ActionCreators.SagaAddCustomFields.type, processSagaAddCustomFields)
  yield takeLeading(ActionCreators.SagaRemoveCustomFields.type, processSagaRemoveCustomFields)
  yield takeLeading(ActionCreators.SagaEditCustomFields.type, processSagaEditCustomFields)
  yield takeEvery(ActionCreators.SagaEnabledCustomFields.type, processSagaEnableCustomFields)
  yield takeLeading(ActionCreators.SagaRefreshCustomFieldConfigs.type, processSagaRefreshCustomFieldConfigs)
  yield null
}

// Saga hooks
export default function* customFieldsSaga() {
  yield all([fork(watchCustomFieldsSagas)])
}
