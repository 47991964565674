import React from 'react'
import { conditionalProps } from 'src/utils/conditionalReturn'

interface LinkCell {
  text: string
  href: string
  external?: boolean
  className?: string
}

export default function LinkCell({ text, href, external, className = '' }: LinkCell) {
  return (
    <a href={href} className={`link ${className}`} {...conditionalProps(external, { target: '_blank', rel: 'noopener noreferrer' })}>
      <span>{text}</span>
    </a>
  )
}
