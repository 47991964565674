import generalAccount, { State as generalAccountState } from './general-account'
import generalResources, { State as generalResourcesState } from './general-resources'
import generalNotifications, { State as generalNotificationsState } from './general-notifications'

import { combineReducers } from 'redux'

export interface State {
  general: {
    account: generalAccountState
    resources: generalResourcesState
    notifications: generalNotificationsState
  }
}

export default combineReducers<State>({
  general: combineReducers<State['general']>({
    account: generalAccount,
    resources: generalResources,
    notifications: generalNotifications,
  }),
})
