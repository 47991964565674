import { ExclamationCircleOutlined, FrownOutlined, SmileOutlined } from '@ant-design/icons'
import { Button, message, Modal, Result, Space, Steps } from 'antd'
import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch } from 'react-redux'
import useHierarchyEnabledFlags from 'src/hooks/use-hierarchy-enabled-flags'
import { ActionCreators as SagaActions } from 'src/redux/sagas/employees/directory-saga'
import { conditionalArrayItem } from 'src/utils/conditionalReturn'
import { EmployeeImportResponse } from '../../utils/import'
import { buildUpsertLists } from '../../utils/upload'
import InvalidEmployees from './import-list-invalid'
import UpdateList from './import-list-updates'
import ValidEmployees from './import-list-valid'

const { Step } = Steps

const UploadResult = ({ status, isAsyncProcessingResult = false }: { status?: 'error' | 'success'; isAsyncProcessingResult?: boolean }) => {
  if (!status) return null
  if (status === 'success') {
    return (
      <Result
        icon={<SmileOutlined className='text-vars-primaryColor' />}
        title={
          isAsyncProcessingResult
            ? 'The directory is being updated asyncronously due to the large upload size. Please check back in a few minutes to confirm complete processing.'
            : 'Great, we have updated the directory!'
        }
      />
    )
  }
  return <Result icon={<FrownOutlined className='text-error' />} title='Oops, we were not able to update the directory' />
}

const ViewValidate = (props: {
  data: EmployeeImportResponse
  goBack: () => void
  clearData: () => void
  processType: 'update' | 'replace'
  flowTypeRef: string
  setData: (data: EmployeeImportResponse) => void
}) => {
  const { data, goBack, clearData, processType } = props
  const [currentStep, setStep] = useState(0)
  const [uploading, setUploading] = useState(false)
  const [result, setResult] = useState<'error' | 'success' | undefined>(undefined)
  const [isAsyncProcessingResult, setIsAsyncProcessingResult] = useState<boolean | undefined>(false)
  const dispatch = useDispatch()
  const [modal, contextHolder] = Modal.useModal()
  const intl = useIntl()
  const { employeeRouteType } = useHierarchyEnabledFlags()
  const translatedRouteType = intl.formatMessage({ id: `common.${employeeRouteType}` })

  const steps = React.useMemo(
    () => [
      ...conditionalArrayItem(data.invalidData.length > 0, {
        subTitle: (
          <span className='whitespace-no-wrap'>
            Invalid {translatedRouteType} <small>({data.invalidData.length})</small>
          </span>
        ),
        component: <InvalidEmployees data={data} setData={props.setData} />,
        value: 'invalid',
      }),
      ...conditionalArrayItem(data.validData.length > 0, {
        subTitle: (
          <span className='whitespace-no-wrap'>
            Valid {translatedRouteType} <small>({data.validData.length})</small>
          </span>
        ),
        component: <ValidEmployees data={data.validData} />,
        value: 'valid',
      }),
      {
        subTitle: <>Upload</>,
        component: <UploadResult status={result} isAsyncProcessingResult={isAsyncProcessingResult} />,
        value: 'upload',
      },
    ],
    [data, translatedRouteType, props.setData, result, isAsyncProcessingResult]
  )

  const prevStep = React.useCallback(() => {
    if (currentStep > 0) setStep((current) => current - 1)
  }, [currentStep])

  const nextStep = React.useCallback(() => {
    if (currentStep < steps.length - 1) setStep((current) => ++current)
  }, [steps, currentStep])

  const handleCancel = () => {
    setUploading(false)
    clearData()
  }

  const handleUpload = async () => {
    setUploading(true)
    const { addList, updateList } = buildUpsertLists(data.validData, props.flowTypeRef)

    function handleResult(r: typeof result, isAsyncProcessing?: boolean) {
      setIsAsyncProcessingResult(isAsyncProcessing)
      setResult(r)
      nextStep()
      setUploading(false)
    }

    function upsertHosts() {
      // new + update
      const upsertList = [...addList, ...updateList.map((entry) => entry.new)]
      if (upsertList.length > 0) {
        dispatch(
          SagaActions.SagaUpsertMultipleEmployees.withCallback({
            payload: upsertList,
            callback: (res) => {
              handleResult(res.status === 'success' ? res.status : 'error', res.isAsyncProcessing)
              if (res.status !== 'success') message.error(res.message)
            },
          })
        )
      }
    }

    function uploadEmployees(data: typeof addList) {
      setUploading(true)
      // new only
      dispatch(
        SagaActions.SagaUpsertMultipleEmployees.withCallback({
          payload: data,
          callback: (res) => {
            handleResult(res.status === 'success' ? res.status : 'error', res.isAsyncProcessing)
            if (res.status !== 'success') message.error(res.message || `failed to upload new ${translatedRouteType}`)
          },
        })
      )
    }

    function convertUpdateToAdd(data: typeof updateList) {
      return data.map((entry) => entry.new)
    }

    if (updateList.length > 0 && processType !== 'replace') {
      setUploading(false)
      modal.confirm({
        title: 'Update Employees',
        width: '600px',
        icon: null,
        okText: intl.formatMessage({ id: 'common.continue' }),
        cancelText: addList.length > 0 ? intl.formatMessage({ id: 'common.skip' }) : intl.formatMessage({ id: 'common.cancel' }),
        cancelButtonProps: { danger: true, disabled: uploading },
        okButtonProps: { loading: uploading },
        content: <UpdateList list={updateList} />,
        onOk: () => uploadEmployees([...convertUpdateToAdd(updateList), ...addList]),
        onCancel: addList.length > 0 ? upsertHosts : handleCancel,
      })
    } else if (updateList.length > 0) {
      uploadEmployees([...convertUpdateToAdd(updateList), ...addList])
    } else {
      uploadEmployees(addList)
    }
  }

  const handleDelete = () => {
    function deleteDirectory() {
      setUploading(true)
      dispatch(
        SagaActions.SagaDeleteEntityEmployees.withCallback({
          payload: props.flowTypeRef,
          callback: (res) => {
            handleUpload()
            if (res.status !== 'success' && res.message) message.error(res.message)
          },
        })
      )
    }
    modal.confirm({
      title: `Replace ${translatedRouteType} directory`,
      icon: <ExclamationCircleOutlined />,
      cancelText: 'Cancel',
      cancelButtonProps: { danger: true },
      content: `You have chosen the replace method, if you continue the current ${translatedRouteType} directory will be replaced, are you sure you want to continue?`,
      onOk: deleteDirectory,
      onCancel: handleCancel,
    })

    // console.log('handle delete')
  }

  const canUpload = steps.findIndex((step) => step.value === 'valid') > -1
  return (
    <div>
      {canUpload && (
        <>
          <Steps labelPlacement='vertical' current={currentStep} className='pt-4' size='small'>
            {steps.map(({ component, ...step }, i) => (
              <Step key={i} {...step} />
            ))}
          </Steps>{' '}
          {/* <Divider /> */}
        </>
      )}

      <div>{steps[currentStep].component}</div>

      <div className='pt-4 text-right actions'>
        <Space align='end'>
          {steps[currentStep].value !== 'upload' && currentStep > 0 && (
            <Button onClick={prevStep} disabled={uploading}>
              Back
            </Button>
          )}
          {steps[currentStep].value === 'invalid' && (
            <>
              <Button type='link' onClick={clearData}>
                Restart
              </Button>
              <Button type='primary' onClick={nextStep} ghost>
                Next
              </Button>
            </>
          )}
          {steps[currentStep].value === 'valid' && (
            <Button type='primary' onClick={processType === 'replace' ? handleDelete : handleUpload} ghost loading={uploading}>
              {processType === 'replace' ? (uploading ? 'Replacing' : 'Replace') : uploading ? 'Uploading' : 'Upload'}
            </Button>
          )}
          {steps[currentStep].value === 'upload' && (
            <>
              <Button type='link' onClick={clearData}>
                Restart
              </Button>
              <Button danger onClick={goBack}>
                Close
              </Button>
            </>
          )}
        </Space>
      </div>
      {contextHolder}
    </div>
  )
}

export default ViewValidate
