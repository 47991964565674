import { createStore, applyMiddleware, compose, Store } from 'redux'
import { persistStore } from 'redux-persist'
import createSagaMiddleware from 'redux-saga'
import { routerMiddleware } from 'connected-react-router'
import { createBrowserHistory } from 'history'
import * as Sentry from '@sentry/react'

import createRootReducer, { RootState } from 'src/redux/store/rootReducer'
import SagaManager from 'src/redux/sagas/SagaManager'

export const history = createBrowserHistory()

let persistor
const development = process.env.NODE_ENV === `development`

const developmentMiddlewares = () => {
  const { createLogger } = require('redux-logger')
  const logger = createLogger({
    collapsed: true,
    level: 'warn',
  })

  return [applyMiddleware(logger), applyMiddleware(require('redux-immutable-state-invariant').default())]
}

const configureStore = (preloadedState?: any) => {
  const composeEnhancer: typeof compose = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
  const sagaMiddleware = createSagaMiddleware()
  const sentryReduxEnhancer = Sentry.createReduxEnhancer({
    // Optionally pass options listed below
  })

  const middleWares = [
    applyMiddleware(sagaMiddleware),
    applyMiddleware(routerMiddleware(history)),
    ...(development ? developmentMiddlewares() : []),
    ...(!development ? [sentryReduxEnhancer] : []),
  ]
  const store: Store<RootState> = createStore(createRootReducer(history), preloadedState, composeEnhancer(...middleWares))

  if (development && module.hot) {
    module.hot.accept('./rootReducer', () => {
      store.replaceReducer(createRootReducer(history))
    })
    module.hot.accept('../sagas/SagaManager', () => {
      SagaManager.cancelSagas(store)
      SagaManager.startSagas(sagaMiddleware)
    })
  }

  persistor = persistStore(store)
  SagaManager.startSagas(sagaMiddleware)
  return { store, persistor }
}

export const purgePersistor = async () => {
  try {
    if (persistor) await persistor.purge()
    return
  } catch (e) {
    console.log(e)
    return
  }
}

export default configureStore
