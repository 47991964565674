import { ActionCreator } from '../store-action-creator'
import { RootState } from '../rootReducer'
import { createSelector } from 'reselect'
import update from 'immutability-helper'
import { getView, ViewTypes } from 'src/redux/sagas/dashboard/sidebar'

// Action Creators
export const ActionCreators = {
  StoreToggleSidebarCollapse: new ActionCreator<'StoreToggleSidebarCollapse', { flag?: boolean }>('StoreToggleSidebarCollapse'),
  StoreToggleSidebarAutoCollapse: new ActionCreator<'StoreToggleSidebarAutoCollapse', { flag?: boolean }>('StoreToggleSidebarAutoCollapse'),
  ClearAllStates: new ActionCreator<'ClearAllStates', Partial<void>>('ClearAllStates'),
}

export type Action = typeof ActionCreators[keyof typeof ActionCreators]

export type State = {
  collapsed: boolean
  autoCollapse: boolean
  view: ViewTypes
}

export const initialState = (): State => ({
  collapsed: window.innerWidth > 1220 ? false : true,
  autoCollapse: window.innerWidth > 1220 ? false : true,
  view: getView(window.innerWidth),
})

// Reducer
export default function reducer(state: State = initialState(), action: Action) {
  switch (action.type) {
    case ActionCreators.StoreToggleSidebarCollapse.type:
      return update(state, { collapsed: { $set: !state.collapsed } })
    case ActionCreators.StoreToggleSidebarAutoCollapse.type:
      return update(state, { autoCollapse: { $set: !state.autoCollapse } })
    case ActionCreators.ClearAllStates.type:
      return initialState()
    default:
      return state
  }
}

// Selectors
const getSidebarState = (state: RootState) => state.dashboard.sidebar
export const getSidebar = createSelector<RootState, any, State>([getSidebarState], (state: State) => {
  return state
})
