import { cloneDeep, findIndex, orderBy } from 'lodash'
import moment from 'moment'
import { actionChannel, all, call, fork, put, select, take, takeLeading } from 'redux-saga/effects'
import API from 'src/api'
import { checkIfTimeExists } from 'src/redux/helpers/functions'
import { ActionCreators as AuthenticationActionCreators } from 'src/redux/sagas/authentication'
import { ActionCreators as CredentialsStoreActionCreators, getEntityHierarchyConfiguration } from 'src/redux/store/credentials'
import { ActionCreators as ParkingLogActionCreators, getParkingLogRawEventItems } from 'src/redux/store/parking/log'
import { ParkingLogSearchableEventSet } from 'src/typings/kenai/parking/log'
import { getParkingSubscriptionCredential } from 'src/redux/store/selectors'
import { RangePickerNumberValue } from 'src/typings/vendor/rangepicker'
import AccessManager from 'src/utils/AccessManager'
import ActionCreator from '../saga-action-creator'
import { generateSearchString } from './helpers/generateSearchString'
import { getLiveUpdateEarliestTimestamp } from 'src/redux/store/dashboard/globalevents'

// Action Creators
export const ActionCreators = {
  SagaRetrieveParkingLogData: new ActionCreator<'SagaRetrieveParkingLogData', { beginTime: string; endTime: string }>(
    'SagaRetrieveParkingLogData'
  ),
  SagaSetParkingDateRange: new ActionCreator<'SagaSetParkingDateRange', RangePickerNumberValue>('SagaSetParkingDateRange'),
  SagaRefreshParkingLogSubscriptionCredential: new ActionCreator<'SagaRefreshParkingLogSubscriptionCredential', string>(
    'SagaRefreshParkingLogSubscriptionCredential'
  ),
  SagaParkingLogLiveUpdateHandler: new ActionCreator<'SagaParkingLogLiveUpdateHandler', { beginTime: string; endTime: string }>(
    'SagaParkingLogLiveUpdateHandler'
  ),
}

const AWSHelper = new API.AWSHelpers()

type ProcessedRelatedEvents = Omit<ParkingLogSearchableEventSet, 'relatedEvents'> & {
  relatedEvents?: ParkingLogSearchableEventSet['relatedEvents'] // make this optional for processed events
}

function processRelatedEvents(data: ProcessedRelatedEvents[]) {
  data.forEach((item) => {
    if (item.relatedEvents) {
      if (item.relatedEvents.length === 0) {
        // @ts-ignore (REMOVE THIS LINE ON MERGE CONFLICT [TS UPDATE])
        delete item.relatedEvents
      } else {
        item.relatedEvents.forEach((relatedEvent) => {
          relatedEvent.parentEvtTimeStampUniqueAttributeValue = item.evtTimeStampUniqueAttributeValue
        })
      }
    }
  })
}

function* processRetrieveParkingLogData(action) {
  try {
    yield put(ParkingLogActionCreators.StoreSetFetchingParkingLog.create(true))
    yield put(AuthenticationActionCreators.SagaRefreshApiCredentials.create())
    yield take(AuthenticationActionCreators.SagaApiCredentialsRefreshUpdate.type)
    const beginTime = checkIfTimeExists(action, 'beginTime') ? action.payload.beginTime.valueOf() : moment().startOf('day').valueOf()
    const endTime = checkIfTimeExists(action, 'endTime') ? action.payload.endTime.valueOf() : 9999999999999

    const entityHierarchy: string = AccessManager.selectedHierarchy.hierarchyStructure

    const entityHierarchyConfiguration = yield select(getEntityHierarchyConfiguration)

    const parkingLogData: ParkingLogSearchableEventSet[] = yield call(
      [AWSHelper, AWSHelper.getParkingLog],
      beginTime,
      endTime,
      entityHierarchy,
      entityHierarchyConfiguration
    )

    const selectionLogs = parkingLogData.filter((log) => log.evtTimeStamp >= beginTime)
    const orderedEventPairs = orderBy(selectionLogs, ['evtTimeStamp'], ['desc'])
    generateSearchString(orderedEventPairs, entityHierarchyConfiguration)
    processRelatedEvents(orderedEventPairs)

    yield put(ParkingLogActionCreators.StoreSetParkingLogData.create(orderedEventPairs))
    yield put(ParkingLogActionCreators.StoreSetParkingLogRawData.create(selectionLogs))
    yield put(ParkingLogActionCreators.StoreSetFetchingParkingLog.create(false))
  } catch (e) {
    yield put(ParkingLogActionCreators.StoreSetFetchingParkingLog.create(false))
    if (e) {
      console.error(e)
    }
  }
}

function* processSetParkingDateRanges(action) {
  try {
    yield put(ParkingLogActionCreators.StoreSetParkingDateRanges.create(action.payload))
  } catch (e) {
    if (e) {
      console.error(e)
    }
  }
}

function* processRefreshParkingLogSubscriptionCredential(action) {
  try {
    yield put(AuthenticationActionCreators.SagaRefreshApiCredentials.create())
    yield take(AuthenticationActionCreators.SagaApiCredentialsRefreshUpdate.type)
    const currentSubscriptionCredential = yield select(getParkingSubscriptionCredential)
    const clientId = action.payload
    let shouldRetrieveNewCredentials = true
    if (currentSubscriptionCredential && currentSubscriptionCredential.Expiration) {
      const expiry = moment(currentSubscriptionCredential.Expiration)
      const now = moment()
      const diff = expiry.diff(now)
      if (diff / 1000 > 60 * 5) {
        //allow for 5 minute clock skew
        shouldRetrieveNewCredentials = false
      }
    }
    if (shouldRetrieveNewCredentials) {
      const entityHierarchyConfiguration = yield select(getEntityHierarchyConfiguration)
      const topics: Array<string> = []
      entityHierarchyConfiguration.hierarchy.deepStructure.forEach((hierarchy) => {
        //just subscribe to the root
        const topicWithOutTrailingSlash = `parkingEvents/${hierarchy.hierarchyStructure.replace(/#/g, '/')}`
        if (topics.length < 8) {
          //never subscribe to more than 8
          topics.push(topicWithOutTrailingSlash)
        }
      })

      const subscriptionCredential = yield call([AWSHelper, AWSHelper.retrieveSubscribeCredential], topics, clientId)
      if (subscriptionCredential.key === 'CREDENTIAL_RETRIEVED') {
        yield put(
          CredentialsStoreActionCreators.StoreSetParkingSubscriptionCredential.create({
            ...subscriptionCredential.credential,
            ts: Date.now(),
          })
        )
      } else {
        yield put(CredentialsStoreActionCreators.StoreSetParkingSubscriptionCredential.create(undefined))
      }
    } else if (!shouldRetrieveNewCredentials && currentSubscriptionCredential) {
      yield put(
        CredentialsStoreActionCreators.StoreSetParkingSubscriptionCredential.create({
          ...currentSubscriptionCredential,
          ts: Date.now(),
        })
      ) //triggers gDSfP in subscription component
    }
  } catch (e) {
    if (e) {
      console.error(e)
    }
    yield put(CredentialsStoreActionCreators.StoreSetParkingSubscriptionCredential.create(undefined))
  }
}

function* processParkingLogLiveUpdateHandler(action) {
  try {
    const eventPayload = action.payload
    const liveUpdateEarliestTimestamp = yield select(getLiveUpdateEarliestTimestamp)
    if (eventPayload.messageReceivedTimeStamp > liveUpdateEarliestTimestamp) {
      const beginTime = moment().startOf('day').valueOf()
      const entityHierarchyConfiguration = yield select(getEntityHierarchyConfiguration)
      const availableHierarchies = new Map()
      const addRecursive = (additionalChildren) => {
        for (const prop of additionalChildren) {
          availableHierarchies.set(prop.hierarchyStructure, true)
          if (prop.children.length > 0) {
            addRecursive(prop.children)
          }
        }
      }

      if (AccessManager.selectedHierarchy.hierarchyStructure === 'ALL') {
        addRecursive(entityHierarchyConfiguration.hierarchy.deepStructure)
      } else {
        addRecursive([entityHierarchyConfiguration.hierarchy.flatStructure[AccessManager.selectedHierarchy.hierarchyStructure]])
      }

      const deletions = new Map()
      const changes = new Map()
      const newEntries = new Map()
      eventPayload.events.forEach((logEvent) => {
        if (logEvent.eventType === 'REMOVE' && availableHierarchies.get(logEvent.EntityHierarchy) === true) {
          deletions.set(logEvent.evtTimeStampUniqueAttributeValue, logEvent)
        } else if (logEvent.eventType === 'MODIFY' && availableHierarchies.get(logEvent.EntityHierarchy) === true) {
          changes.set(logEvent.evtTimeStampUniqueAttributeValue, logEvent)
        } else if (logEvent.eventType === 'INSERT' && availableHierarchies.get(logEvent.EntityHierarchy) === true) {
          newEntries.set(logEvent.evtTimeStampUniqueAttributeValue, logEvent)
        }
      })

      const itemsToRetrieve: Array<{
        EntityHierarchy: string
        evtTimeStampUniqueAttributeValue: string
      }> = []
      let retrievedItems: ParkingLogSearchableEventSet[] = []
      changes.forEach((value) => {
        itemsToRetrieve.push({
          EntityHierarchy: value.EntityHierarchy,
          evtTimeStampUniqueAttributeValue: value.evtTimeStampUniqueAttributeValue,
        })
      })
      newEntries.forEach((value) => {
        itemsToRetrieve.push({
          EntityHierarchy: value.EntityHierarchy,
          evtTimeStampUniqueAttributeValue: value.evtTimeStampUniqueAttributeValue,
        })
      })
      if (itemsToRetrieve.length > 0) {
        yield put(AuthenticationActionCreators.SagaRefreshApiCredentials.create())
        yield take(AuthenticationActionCreators.SagaApiCredentialsRefreshUpdate.type)

        retrievedItems = yield call([AWSHelper, AWSHelper.getSelectedParkinglogs], itemsToRetrieve)
      }

      const currentLogData = yield select(getParkingLogRawEventItems)
      const parkinglogData = cloneDeep(currentLogData)

      deletions.forEach((value) => {
        const indexDelete = findIndex(parkinglogData, {
          evtTimeStampUniqueAttributeValue: value.evtTimeStampUniqueAttributeValue,
        })
        if (indexDelete !== -1) {
          parkinglogData.splice(indexDelete, 1)
        }
      })

      retrievedItems.forEach((retrievedItem) => {
        const changedEntry = changes.get(retrievedItem.evtTimeStampUniqueAttributeValue)
        const newEntry = newEntries.get(retrievedItem.evtTimeStampUniqueAttributeValue)
        if (changedEntry) {
          const indexCurrent = findIndex(parkinglogData, {
            evtTimeStampUniqueAttributeValue: retrievedItem.evtTimeStampUniqueAttributeValue,
          })
          if (indexCurrent !== -1) {
            parkinglogData.splice(indexCurrent, 1, retrievedItem)
          }
        } else if (newEntry) {
          const indexCurrent = findIndex(parkinglogData, {
            evtTimeStampUniqueAttributeValue: retrievedItem.evtTimeStampUniqueAttributeValue,
          })
          if (indexCurrent === -1) {
            parkinglogData.push(retrievedItem)
          }
        }
      })

      const selectionLogs = parkinglogData.filter((log) => log.evtTimeStamp >= beginTime)
      const orderedEventPairs = orderBy(selectionLogs, ['evtTimeStamp'], ['desc'])
      generateSearchString(orderedEventPairs, entityHierarchyConfiguration)
      processRelatedEvents(orderedEventPairs)

      yield put(ParkingLogActionCreators.StoreSetParkingLogData.create(orderedEventPairs))
      yield put(ParkingLogActionCreators.StoreSetParkingLogRawData.create(selectionLogs))
    }
  } catch (e) {
    console.log(e)
  }
}

// Saga triggers
function* watchParkingLogSagas() {
  yield takeLeading(ActionCreators.SagaRetrieveParkingLogData.type, processRetrieveParkingLogData)
  yield takeLeading(ActionCreators.SagaSetParkingDateRange.type, processSetParkingDateRanges)
  yield takeLeading(ActionCreators.SagaRefreshParkingLogSubscriptionCredential.type, processRefreshParkingLogSubscriptionCredential)
  yield null
}

function* watchParkingLogLiveUpdateChannel() {
  const SagaParkingLogLiveUpdateHandlerChannel = yield actionChannel(ActionCreators.SagaParkingLogLiveUpdateHandler.type)
  while (true) {
    const action = yield take(SagaParkingLogLiveUpdateHandlerChannel)
    yield call(processParkingLogLiveUpdateHandler, action)
  }
}

// Saga hooks
export default function* parkingLogSagas() {
  yield all([fork(watchParkingLogSagas), fork(watchParkingLogLiveUpdateChannel)])
}
