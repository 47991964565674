const coreHandlebars = ['companyName', 'locationShortDescription'] as const

const EMPLOYEE_ASSISTED_CHECKIN_TYPES = [
  'EMPLOYEE_DASHBOARD_LOG_ALERT',
  'ASSISTED_CHECKIN_BACKEND_DATA_RECEIVED_EMAIL',
  'ASSISTED_CHECKIN_BACKEND_DATA_RECEIVED_SMS',
  'ASSISTED_CHECKIN_DATA_CAPTURE_COMPLETE',
] as const

const EMPLOYEE_PRE_SCREENING_TYPES = [
  'EMPLOYEE_PRE_SCREENING_BACKEND_DATA_RECEIVED_EMAIL',
  'EMPLOYEE_PRE_SCREENING_BACKEND_DATA_RECEIVED_SMS',
  'EMPLOYEE_PRE_SCREENING_DASHBOARD_LOG_ALERT',
] as const

const EMPLOYEE_TYPES = [...EMPLOYEE_ASSISTED_CHECKIN_TYPES, ...EMPLOYEE_PRE_SCREENING_TYPES]

const PARKING_TYPES = [
  'PARKING_SCANNER_DASHBOARD_LOG_ALERT',
  'PARKING_SCANNER_BACKEND_DATA_RECEIVED_EMAIL',
  'PARKING_SCANNER_BACKEND_DATA_RECEIVED_SMS',
  'PARKING_SCANNER_DATA_CAPTURE_COMPLETE',
] as const

const DASHBOARD_TYPES = [
  'PARKING_SCANNER_DASHBOARD_LOG_ALERT',
  'EMPLOYEE_DASHBOARD_LOG_ALERT',
  'EMPLOYEE_PRE_SCREENING_DASHBOARD_LOG_ALERT',
] as const

export const notificationEventPairs: Record<
  NOTIFICATION_EVENT_TYPES,
  {
    notificationTypes: NOTIFICATION_TYPES[]
    channels: NOTIFICATION_CHANNEL_TYPES[]
    variables?: NOTIFICATION_VARIABLES[]
  }
> = {
  EMPLOYEE_ASSISTED_CHECKIN: {
    notificationTypes: EMPLOYEE_ASSISTED_CHECKIN_TYPES as any,
    channels: ['EMAIL', 'SMS', 'DATA_CAPTURE_COMPLETE'],
    variables: [...coreHandlebars, 'employeeName', 'phoneNumber', 'email'],
  },
  EMPLOYEE_PRE_SCREENING: {
    notificationTypes: EMPLOYEE_PRE_SCREENING_TYPES as any,
    channels: ['LOG_ALERT', 'EMAIL', 'SMS', 'DATA_CAPTURE_COMPLETE'],
    variables: [...coreHandlebars, 'employeeName', 'phoneNumber', 'email'],
  },
  PARKING_SCANNER: {
    notificationTypes: PARKING_TYPES as any,
    channels: ['LOG_ALERT', 'EMAIL', 'SMS', 'DATA_CAPTURE_COMPLETE'],
    variables: [...coreHandlebars, 'personName', 'processType', 'hostName'],
  },
}

export const notificationKeyPairs: Record<
  NOTIFICATION_TYPES,
  {
    notificationType: NOTIFICATION_TYPES
    eventType: NOTIFICATION_EVENT_TYPES
    channelType: NOTIFICATION_CHANNEL_TYPES
  }
> = {
  ASSISTED_CHECKIN_BACKEND_DATA_RECEIVED_EMAIL: {
    notificationType: 'ASSISTED_CHECKIN_BACKEND_DATA_RECEIVED_EMAIL',
    eventType: 'EMPLOYEE_ASSISTED_CHECKIN',
    channelType: 'EMAIL',
  },
  ASSISTED_CHECKIN_BACKEND_DATA_RECEIVED_SMS: {
    notificationType: 'ASSISTED_CHECKIN_BACKEND_DATA_RECEIVED_SMS',
    eventType: 'EMPLOYEE_ASSISTED_CHECKIN',
    channelType: 'SMS',
  },
  ASSISTED_CHECKIN_DATA_CAPTURE_COMPLETE: {
    notificationType: 'ASSISTED_CHECKIN_DATA_CAPTURE_COMPLETE',
    eventType: 'EMPLOYEE_ASSISTED_CHECKIN',
    channelType: 'DATA_CAPTURE_COMPLETE',
  },
  EMPLOYEE_DASHBOARD_LOG_ALERT: {
    notificationType: 'EMPLOYEE_DASHBOARD_LOG_ALERT',
    eventType: 'EMPLOYEE_ASSISTED_CHECKIN',
    channelType: 'LOG_ALERT',
  },
  EMPLOYEE_PRE_SCREENING_BACKEND_DATA_RECEIVED_EMAIL: {
    notificationType: 'EMPLOYEE_PRE_SCREENING_BACKEND_DATA_RECEIVED_EMAIL',
    eventType: 'EMPLOYEE_PRE_SCREENING',
    channelType: 'EMAIL',
  },
  EMPLOYEE_PRE_SCREENING_BACKEND_DATA_RECEIVED_SMS: {
    notificationType: 'EMPLOYEE_PRE_SCREENING_BACKEND_DATA_RECEIVED_SMS',
    eventType: 'EMPLOYEE_PRE_SCREENING',
    channelType: 'SMS',
  },
  EMPLOYEE_PRE_SCREENING_DASHBOARD_LOG_ALERT: {
    notificationType: 'EMPLOYEE_PRE_SCREENING_DASHBOARD_LOG_ALERT',
    eventType: 'EMPLOYEE_PRE_SCREENING',
    channelType: 'LOG_ALERT',
  },
  PARKING_SCANNER_BACKEND_DATA_RECEIVED_EMAIL: {
    notificationType: 'PARKING_SCANNER_BACKEND_DATA_RECEIVED_EMAIL',
    eventType: 'PARKING_SCANNER',
    channelType: 'EMAIL',
  },
  PARKING_SCANNER_BACKEND_DATA_RECEIVED_SMS: {
    notificationType: 'PARKING_SCANNER_BACKEND_DATA_RECEIVED_SMS',
    eventType: 'PARKING_SCANNER',
    channelType: 'SMS',
  },
  PARKING_SCANNER_DASHBOARD_LOG_ALERT: {
    notificationType: 'PARKING_SCANNER_DASHBOARD_LOG_ALERT',
    eventType: 'PARKING_SCANNER',
    channelType: 'LOG_ALERT',
  },
  PARKING_SCANNER_DATA_CAPTURE_COMPLETE: {
    notificationType: 'PARKING_SCANNER_DATA_CAPTURE_COMPLETE',
    eventType: 'PARKING_SCANNER',
    channelType: 'DATA_CAPTURE_COMPLETE',
  },
}

export const notificationLabels: Record<NOTIFICATION_EVENT_TYPES | NOTIFICATION_CHANNEL_TYPES, string> = {
  EMPLOYEE_ASSISTED_CHECKIN: 'Employee Assisted Check-in',
  DATA_CAPTURE_COMPLETE: 'Data Capture Completed',
  EMAIL: 'Email',
  EMPLOYEE_PRE_SCREENING: 'Employee Pre-screening',
  LOG_ALERT: 'Dashboard',
  PARKING_SCANNER: 'Parking Scanner',
  SMS: 'Sms',
}

export const notificationHelpers = {
  getAssistedCheckingEvents: function () {
    return Object.values(notificationKeyPairs).filter((entry) =>
      notificationEventPairs.EMPLOYEE_ASSISTED_CHECKIN.notificationTypes?.includes(entry.notificationType)
    )
  },
  getEmployeePrescreeningEvents: function () {
    return Object.values(notificationKeyPairs).filter((entry) =>
      notificationEventPairs.EMPLOYEE_PRE_SCREENING.notificationTypes?.includes(entry.notificationType)
    )
  },
  getParkingEvents: function () {
    return Object.values(notificationKeyPairs).filter((entry) =>
      notificationEventPairs.PARKING_SCANNER.notificationTypes?.includes(entry.notificationType)
    )
  },
  getEventsForType: function (eventType: NOTIFICATION_EVENT_TYPES) {
    if (eventType === 'EMPLOYEE_ASSISTED_CHECKIN') return notificationHelpers.getAssistedCheckingEvents()
    if (eventType === 'EMPLOYEE_PRE_SCREENING') return notificationHelpers.getEmployeePrescreeningEvents()
    if (eventType === 'PARKING_SCANNER') return notificationHelpers.getParkingEvents()
    return undefined
  },
  getEventTypes: function (filters?: NOTIFICATION_TYPES[]) {
    return Array.from(
      new Set(
        Object.values(notificationKeyPairs)
          .filter((e) => (filters && filters?.length > 0 ? filters?.includes?.(e.notificationType) : true))
          .map((entry) => entry.eventType)
      )
    )
  },
  getChannelTypes: function (filters?: NOTIFICATION_TYPES[]) {
    return Array.from(
      new Set(
        Object.values(notificationKeyPairs)
          .filter((e) => (filters && filters?.length > 0 ? filters?.includes?.(e.notificationType) : true))
          .map((entry) => entry.channelType)
      )
    )
  },
  getNotificationType: function (filters?: NOTIFICATION_TYPES[]) {
    return Array.from(
      new Set(
        Object.values(notificationKeyPairs)
          .filter((e) => (filters && filters?.length > 0 ? filters?.includes?.(e.notificationType) : true))
          .map((entry) => entry.notificationType)
      )
    )
  },
  getEventPairs: function (eventType: NOTIFICATION_EVENT_TYPES) {
    return notificationEventPairs[eventType]
  },
  eventType: function (eventType: NOTIFICATION_EVENT_TYPES) {
    const isParking = eventType === 'PARKING_SCANNER'
    const isAssistedChecking = eventType === 'EMPLOYEE_ASSISTED_CHECKIN'
    const isPrescreening = eventType === 'EMPLOYEE_PRE_SCREENING'
    return {
      isParking,
      isAssistedChecking,
      isPrescreening,
    }
  },
  getChannelType: function (notificationType: NOTIFICATION_TYPES) {
    const pair = notificationKeyPairs?.[notificationType]
    return pair?.channelType
  },
  isEmployeeAssistedCheck: function (
    notificationType: NOTIFICATION_TYPES
  ): notificationType is typeof EMPLOYEE_ASSISTED_CHECKIN_TYPES[number] {
    return EMPLOYEE_ASSISTED_CHECKIN_TYPES.includes(notificationType as any)
  },
  isEmployeeNotification: function (notificationType: NOTIFICATION_TYPES): notificationType is typeof EMPLOYEE_TYPES[number] {
    return EMPLOYEE_TYPES.includes(notificationType as any)
  },
  isParkingNotification: function (notificationType: NOTIFICATION_TYPES): notificationType is typeof PARKING_TYPES[number] {
    return PARKING_TYPES.includes(notificationType as any)
  },
  isDashboardNotification: function (notificationType: NOTIFICATION_TYPES): notificationType is typeof DASHBOARD_TYPES[number] {
    return DASHBOARD_TYPES.includes(notificationType as any)
  },
  isEmployeePrescreening: function (notificationType: NOTIFICATION_TYPES): notificationType is typeof EMPLOYEE_PRE_SCREENING_TYPES[number] {
    return EMPLOYEE_PRE_SCREENING_TYPES.includes(notificationType as any)
  },
}
