import localforage from 'localforage'

let initialized = false
let credentialStore: LocalForage | undefined = undefined
let imageStore: LocalForage | undefined = undefined

const getStorage = (): {
  credentialStore: LocalForage
  imageStore: LocalForage
  localforage: LocalForage
} => {
  if (!initialized) {
    credentialStore = localforage.createInstance({
      name: 'credentialStore',
    })
    imageStore = localforage.createInstance({
      name: 'imageStore',
    })
    initialized = true
  }
  return {
    credentialStore: credentialStore as LocalForage,
    imageStore: imageStore as LocalForage,
    localforage: localforage as LocalForage,
  }
}

export default getStorage
