import update from 'immutability-helper'
import { createSelector } from 'reselect'
import { getTimeRange } from 'src/utils/getTimeRange'
import { RootState } from '../rootReducer'
import { ActionCreator } from '../store-action-creator'
import { EmployeePreScreeningLogSearchableEventSet } from './types/screening-log'
import { RangePickerNumberValue } from 'src/typings/vendor/rangepicker'

// Action Creators
export const ActionCreators = {
  StoreSetEmployeeScreeningLogData: new ActionCreator<'StoreSetEmployeeScreeningLogData', State['data']>(
    'StoreSetEmployeeScreeningLogData'
  ),
  StoreSetShouldSubscribeToUpdates: new ActionCreator<'StoreSetShouldSubscribeToUpdates', boolean>('StoreSetShouldSubscribeToUpdates'),
  StoreSetEmployeeScreeningLogDateRange: new ActionCreator<'StoreSetEmployeeScreeningLogDateRange', State['dateRanges']>(
    'StoreSetEmployeeScreeningLogDateRange'
  ),
  StoreSetEmployeeScreeningLogLoading: new ActionCreator<'StoreSetEmployeeScreeningLogLoading', State['loading']>(
    'StoreSetEmployeeScreeningLogLoading'
  ),
  StoreClearAllEmployeeScreeningLogData: new ActionCreator<'StoreClearAllEmployeeScreeningLogData', void>(
    'StoreClearAllEmployeeScreeningLogData'
  ),
  ClearAllStates: new ActionCreator<'ClearAllStates', void>('ClearAllStates'),
}

export type Action = typeof ActionCreators[keyof typeof ActionCreators]

export type State = {
  data: EmployeePreScreeningLogSearchableEventSet[]
  loading: boolean
  dateRanges: RangePickerNumberValue
  shouldSubscribeToUpdates: boolean
}

export const initialState = (): State => ({
  data: [],
  loading: false,
  dateRanges: getTimeRange('today'),
  shouldSubscribeToUpdates: false,
})

// Reducer
export default function reducer(state = initialState(), action: Action) {
  switch (action.type) {
    case ActionCreators.StoreSetEmployeeScreeningLogData.type:
      return update(state, {
        data: { $set: action.payload },
      })
    case ActionCreators.StoreSetShouldSubscribeToUpdates.type:
      return update(state, {
        shouldSubscribeToUpdates: { $set: action.payload },
      })
    case ActionCreators.StoreSetEmployeeScreeningLogLoading.type:
      return update(state, {
        loading: { $set: action.payload },
      })
    case ActionCreators.StoreSetEmployeeScreeningLogDateRange.type:
      return update(state, {
        dateRanges: { $set: action.payload },
      })
    case ActionCreators.StoreClearAllEmployeeScreeningLogData.type:
      return initialState()
    //   return update(state, {
    //     data: { $set: initialState().data },
    //   })
    case ActionCreators.ClearAllStates.type:
      return initialState()

    default:
      return state
  }
}

// Selectors
const getEmployeeScreeningLogState = (state: RootState) => state.employees.screeningLog
export const getEmployeeScreeningLogStore = createSelector<any, State, State>([getEmployeeScreeningLogState], (state) => {
  return state
})

const getEmployeeScreeningLogDataState = (state: RootState) => state.employees.screeningLog.data
export const getEmployeeScreeningLogData = createSelector<any, State['data'], State['data']>(
  [getEmployeeScreeningLogDataState],
  (state) => {
    return state
  }
)

const getEmployeeScreeningLogDateRangesState = (state: RootState) => state.employees.screeningLog.dateRanges
export const getEmployeeScreeningLogDateRanges = createSelector<any, State['dateRanges'], State['dateRanges']>(
  [getEmployeeScreeningLogDateRangesState],
  (state) => {
    return state
  }
)

const getEmployeeScreeningLogLoadingState = (state: RootState) => state.employees.screeningLog.loading
export const getEmployeeScreeningLogLoading = createSelector<any, State['loading'], State['loading']>(
  [getEmployeeScreeningLogLoadingState],
  (state) => {
    return state
  }
)

const getEmployeeScreeningLogShouldSubscribeToUpdatesState = (state: RootState) => state.employees.screeningLog.shouldSubscribeToUpdates
export const getEmployeeScreeningLogShouldSubscribe = createSelector<
  any,
  State['shouldSubscribeToUpdates'],
  State['shouldSubscribeToUpdates']
>([getEmployeeScreeningLogShouldSubscribeToUpdatesState], (state) => {
  return state
})

export const EmployeeScreeningLogSelectors = {
  getEmployeeScreeningLogStore,
  getEmployeeScreeningLogData,
  getEmployeeScreeningLogDateRanges,
  getEmployeeScreeningLogLoading,
  getEmployeeScreeningLogShouldSubscribe,
}
