import { ReloadOutlined } from '@ant-design/icons'
import { Button, Modal, Result } from 'antd'
import { ModalProps } from 'antd/lib/modal'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import DotsLoading from 'src/components/dots-loading'
import { ActionCreators as SagaActionCreators } from 'src/redux/sagas/settings/notification-saga'

const getMessage = (errorKey?: string) => {
  if (errorKey === 'NOTIFICATION_INSTANCE_OBSOLETE') {
    return 'The notification does not match the latest version, refresh your notifications and try again.'
  }
  if (errorKey === 'NOTIFICATION_NOT_FOUND') {
    return 'The notification could not be found, your current list might be outdated, refresh and try again'
  }
  return ''
}

interface ObsoleteRefreshProps extends ModalProps {
  EntityHierarchy: string
  errorKey?: string
  eventType: NOTIFICATION_EVENT_TYPES
}

type AcceptedKeys = 'NOTIFICATION_INSTANCE_OBSOLETE' | 'NOTIFICATION_NOT_FOUND'

function showObsolete(tested?: string): tested is AcceptedKeys {
  if (!tested) return false
  return ['NOTIFICATION_INSTANCE_OBSOLETE', 'NOTIFICATION_NOT_FOUND'].includes(tested)
}

const ObsoleteRefresh = ({ EntityHierarchy, errorKey, ...props }: ObsoleteRefreshProps) => {
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()

  const handleRefresh = (e) => {
    setLoading(true)
    dispatch(
      SagaActionCreators.SagaRefreshNotificationConfigs.withCallback({
        payload: {
          EntityHierarchy,
          eventType: props.eventType,
        },
        callback: () => {
          setLoading(false)
          if (props.onCancel) props.onCancel(e)
          Modal.destroyAll()
        },
      })
    )
  }

  return (
    <Modal visible={showObsolete(errorKey)} width={550} maskClosable centered footer={null} {...props}>
      <Result
        className='py-5 px-2'
        status='warning'
        title='There was a problem with your operation.'
        subTitle={getMessage(errorKey) || <DotsLoading />}
        extra={[
          <Button type='primary' key='update' size='large' icon={<ReloadOutlined />} onClick={handleRefresh} loading={loading} ghost>
            Refresh Notifications
          </Button>,
        ]}
      />
    </Modal>
  )
}

ObsoleteRefresh.showObsolete = showObsolete
ObsoleteRefresh.getMessage = getMessage
export default ObsoleteRefresh
