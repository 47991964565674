import React, { useEffect, useState } from 'react'
import Cookies from 'js-cookie'

type layouts = 'legacy' | 'latest'

interface ContextProps {
  setCurrentLayout: (value: layouts) => void
  currentLayout
  isLegacyLayout: boolean
  isLatestLayout: boolean
}

export const LegacyLayoutContext = React.createContext<ContextProps>({
  setCurrentLayout: () => null,
  currentLayout: 'latest',
  isLatestLayout: true,
  isLegacyLayout: false,
})

export const useLegacyLayout = () => React.useContext<ContextProps>(LegacyLayoutContext)

const getInitialLayout = () => {
  if (Cookies.get('kenai-dashboard-legacy-layout') === 'false') {
    return 'latest' as layouts
  } else {
    return 'legacy' as layouts
  }
}

export const LegacyLayoutProvider: React.FC = ({ children }) => {
  const [currentLayout, setCurrentLayout] = useState<layouts>(getInitialLayout())

  useEffect(() => {
    if (currentLayout === 'legacy') {
      Cookies.set('kenai-dashboard-legacy-layout', 'true')
      document.body.classList.add('legacy-layout-enabled')
    } else {
      Cookies.set('kenai-dashboard-legacy-layout', 'false')
      document.body.classList.remove('legacy-layout-enabled')
    }
  }, [currentLayout])

  return (
    <LegacyLayoutContext.Provider
      value={{
        currentLayout,
        isLegacyLayout: currentLayout === 'legacy',
        isLatestLayout: currentLayout === 'latest',
        setCurrentLayout,
      }}
    >
      {children}
    </LegacyLayoutContext.Provider>
  )
}

export default LegacyLayoutProvider
