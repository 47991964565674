import { all, fork, put, take, takeLeading } from 'redux-saga/effects'
import { addNewApiKey, removeApiKey, retrieveApiKeys, updateApiKey } from 'src/api/userOperations/apiKeys'
import { ActionCreators as AuthenticationActionCreators } from 'src/redux/sagas/authentication'
import { ActionCreators as StoreActionCreators, getApiKeysData } from 'src/redux/store/integrations/api-keys-store'
import { ApiKeyTypes } from 'src/typings/kenai/types'
import { call, select } from 'typed-redux-saga'
import ActionCreator from '../saga-action-creator'

// Action Creators
export const ActionCreators = {
  SagaRetrieveApiKeysData: new ActionCreator<'SagaRetrieveApiKeysData', string>('SagaRetrieveApiKeysData'),
  SagaAddNewApiKey: new ActionCreator<
    'SagaAddNewApiKey',
    { label: string; includedHierarchies: string[]; EntityHierarchy: string; keyType: ApiKeyTypes }
  >('SagaAddNewApiKey'),
  SagaUpdateApiKey: new ActionCreator<'SagaUpdateApiKey', { label: string; includedHierarchies: string[]; keyId: string; lmt: number }>(
    'SagaUpdateApiKey'
  ),
  SagaRemoveApiKey: new ActionCreator<'SagaRemoveApiKey', { EntityHierarchy: string; keyId: string; lmt: number }>('SagaRemoveApiKey'),
}

function* processSagaRetrieveApiKeys(action: typeof ActionCreators.SagaRetrieveApiKeysData) {
  try {
    yield put(AuthenticationActionCreators.SagaRefreshApiCredentials.create())
    yield take(AuthenticationActionCreators.SagaApiCredentialsRefreshUpdate.type)

    const result = yield* call(retrieveApiKeys, action.payload)

    if (result && result.key === 'KEYS_RETRIEVED') {
      yield put(StoreActionCreators.StoreSetApiKeysData.create(result.apiKeyItems))
      if (action.callback) action.callback({ status: 'success' })
    } else {
      throw new Error('error while retrieving api keys')
    }
  } catch (e) {
    if (e) console.error(e)
    if (action.callback) action.callback({ status: 'failed' })
  }
}

function* processSagaAddNewApiKey(action: typeof ActionCreators.SagaAddNewApiKey) {
  try {
    yield put(AuthenticationActionCreators.SagaRefreshApiCredentials.create())
    yield take(AuthenticationActionCreators.SagaApiCredentialsRefreshUpdate.type)

    const result = yield* call(addNewApiKey, action.payload)

    if (result && result.key === 'OPERATION_PROCESSED') {
      const allApiKeys = yield* select(getApiKeysData)
      const { updatedLmt, apiKeyValue, keyId } = result
      yield put(StoreActionCreators.StoreSetApiKeysData.create([...allApiKeys, { lmt: updatedLmt, apiKeyValue, keyId, ...action.payload }]))
      if (action.callback) action.callback({ status: 'success' })
    } else {
      throw new Error('Could not add new api key')
    }
  } catch (e) {
    if (e) console.error(e)
    if (action.callback) action.callback({ status: 'failed' })
  }
}

function* processSagaUpdateApiKey(action: typeof ActionCreators.SagaUpdateApiKey) {
  try {
    yield put(AuthenticationActionCreators.SagaRefreshApiCredentials.create())
    yield take(AuthenticationActionCreators.SagaApiCredentialsRefreshUpdate.type)

    const result = yield* call(updateApiKey, action.payload)

    if (result && result.key === 'OPERATION_PROCESSED') {
      const allApiKeys = yield* select(getApiKeysData)
      yield put(
        StoreActionCreators.StoreSetApiKeysData.create(
          allApiKeys.map((key) => {
            if (key.keyId === action.payload.keyId) {
              return { ...key, ...action.payload, lmt: result.updatedLmt }
            } else {
              return key
            }
          })
        )
      )
      if (action.callback) action.callback({ status: 'success' })
    } else {
      throw new Error('Could not update api key')
    }
  } catch (e) {
    if (e) console.error(e)
    if (action.callback) action.callback({ status: 'failed' })
  }
}
function* processSagaRemoveApiKey(action: typeof ActionCreators.SagaRemoveApiKey) {
  try {
    yield put(AuthenticationActionCreators.SagaRefreshApiCredentials.create())
    yield take(AuthenticationActionCreators.SagaApiCredentialsRefreshUpdate.type)

    const result = yield* call(removeApiKey, action.payload)

    if (result && result.key === 'OPERATION_PROCESSED') {
      const allApiKeys = yield* select(getApiKeysData)
      yield put(StoreActionCreators.StoreSetApiKeysData.create(allApiKeys.filter((key) => key.keyId !== action.payload.keyId)))
      if (action.callback) action.callback({ status: 'success' })
    } else {
      throw new Error('Could not remove api key')
    }
  } catch (e) {
    if (e) console.error(e)
    if (action.callback) action.callback({ status: 'failed' })
  }
}

// Saga triggers
function* watchApiKeysSagas() {
  yield takeLeading(ActionCreators.SagaRetrieveApiKeysData.type, processSagaRetrieveApiKeys)
  yield takeLeading(ActionCreators.SagaAddNewApiKey.type, processSagaAddNewApiKey)
  yield takeLeading(ActionCreators.SagaUpdateApiKey.type, processSagaUpdateApiKey)
  yield takeLeading(ActionCreators.SagaRemoveApiKey.type, processSagaRemoveApiKey)
  yield null
}

// Saga hooks
export default function* apiKeysSagas() {
  yield all([fork(watchApiKeysSagas)])
}
