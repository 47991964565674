import { SearchOutlined } from '@ant-design/icons'
import { Button, Input } from 'antd'
import { ColumnType, FilterDropdownProps } from 'antd/lib/table/interface'
import React, { useEffect, useRef } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { antPrefix } from 'src/styles'

import { TableColumnFilterIcon } from './table-column'

interface ColumnSearchProps extends FilterDropdownProps {
  name?: string
}

const ColumnSearchFilter = ({ name, setSelectedKeys, selectedKeys, confirm, clearFilters, visible }: ColumnSearchProps) => {
  const input = useRef<Input>(null)
  const intl = useIntl()

  useEffect(() => {
    if (visible === true && input.current) {
      input.current.focus()
    }
  }, [visible])

  const handleReset = () => {
    clearFilters()
    confirm()
  }

  return (
    <div style={{ paddingBottom: 4 }}>
      <Input
        className='data-hj-suppress rr-block '
        ref={input}
        placeholder={`${intl.formatMessage({ id: 'common.search' })} ${name ? name : 'column'}`}
        value={(selectedKeys && selectedKeys[0]) || ''}
        onChange={(e) => setSelectedKeys && setSelectedKeys(e.target.value ? [e.target.value] : [])}
        onKeyPress={(e) => {
          if (e.key === 'Enter') {
            confirm()
          }
        }}
        style={{ width: 188, display: 'block', borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }}
        autoFocus
      />
      <div className={`${antPrefix}-table-filter-dropdown-btns`} style={{ padding: '4px 0px 0px' }}>
        <Button
          type='link'
          onClick={() => confirm()}
          icon={<SearchOutlined />}
          size='small'
          style={{ width: 90, marginRight: 8, paddingLeft: 0 }}
        >
          <FormattedMessage id='common.search' tagName='span' />
        </Button>
        <Button type='link' onClick={() => handleReset()} size='small' style={{ width: 90, paddingRight: 0 }}>
          <FormattedMessage id='common.reset' />
        </Button>
      </div>
    </div>
  )
}

export const searchColumnOptions = <T extends any>(options: NonNullable<Pick<ColumnType<T>, 'onFilter'>>) => {
  return {
    filterIcon: (filtered: boolean) => <TableColumnFilterIcon type={'search'} filtered={filtered} />,
    filterDropdown: (props: FilterDropdownProps) => <ColumnSearchFilter {...props} />,
    onFilter: (value: string, record: T) => options.onFilter(value, record),
  }
}

ColumnSearchFilter.columnOptions = searchColumnOptions

export default ColumnSearchFilter
