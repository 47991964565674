import AccessManager from 'src/utils/AccessManager'
import { ActionCreator } from '../store-action-creator'
import update from 'immutability-helper'
import { RootState } from '../rootReducer'
import { createSelector } from 'reselect'

// Action Creators
export const ActionCreators = {
  StoreSignIn: new ActionCreator<'StoreSignIn', void>('StoreSignIn'),
  StoreSignInSuccess: new ActionCreator<'StoreSignInSuccess', void>('StoreSignInSuccess'),
  StoreSignInFailure: new ActionCreator<'StoreSignInFailure', void>('StoreSignInFailure'),
  StoreSignOut: new ActionCreator<'StoreSignOut', void>('StoreSignOut'),
  StoreSignInHideLock: new ActionCreator<'StoreSignInHideLock', void>('StoreSignInHideLock'),
  StoreSignInSetSignedInStatus: new ActionCreator<'StoreSignInSetSignedInStatus', void>('StoreSignInSetSignedInStatus'),
  StoreClearAuthentication: new ActionCreator<'StoreClearAuthentication', void>('StoreClearAuthentication'),
  ClearAllStates: new ActionCreator<'ClearAllStates', void>('ClearAllStates'),
}

export type Action = typeof ActionCreators[keyof typeof ActionCreators]

export type State = {
  isSignedIn: boolean
  isLockVisible: boolean
  error?: any
}

export const initialState = (): State => ({
  isSignedIn: false,
  isLockVisible: false,
})

// Reducer
export default function reducer(state: State = initialState(), action) {
  switch (action.type) {
    case ActionCreators.StoreSignIn.type:
      return update(state, {
        isLockVisible: { $set: true },
        isSignedIn: { $set: false },
      })

    case ActionCreators.StoreSignInSuccess.type:
      return update(state, {
        isLockVisible: { $set: false },
        isSignedIn: { $set: true },
      })

    case ActionCreators.StoreSignInFailure.type:
      return update(state, {
        isLockVisible: { $set: false },
        isSignedIn: { $set: false },
        error: { $set: action.payload },
      })

    case ActionCreators.StoreSignOut.type:
      return update(state, {
        isLockVisible: { $set: false },
        isSignedIn: { $set: false },
      })

    case ActionCreators.StoreSignInHideLock.type:
      return update(state, {
        isLockVisible: { $set: false },
      })

    case ActionCreators.StoreSignInSetSignedInStatus.type:
      return update(state, {
        isSignedIn: { $set: action.payload },
      })

    case ActionCreators.ClearAllStates.type || ActionCreators.StoreClearAuthentication.type:
      AccessManager.clearAllStates()
      return initialState()

    default:
      return state
  }
}

// Selectors
const getAuthenticationState = (state: RootState) => state.authentication
export const getAuthentication = createSelector<RootState, any, State>([getAuthenticationState], (state: State) => {
  return state
})
