import React, { useEffect, useState } from 'react'
import { palette, lightTheme, darkTheme } from 'src/styles'
import { ThemeModes } from 'src/typings/vendor/styled'
import { ThemeProvider } from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { getBranding, State as BrandState, initialState as brandInitialState } from 'src/redux/store/dashboard/branding'
import config from 'src/config'
import Cookies from 'js-cookie'
import { ActionCreators as BrandingActions } from 'src/redux/sagas/dashboard/branding'
interface ContextProps {
  switchTheme: () => void
  mode: ThemeModes
  brand: BrandState
  theme: ReturnType<typeof lightTheme>
}

export const ThemeContext = React.createContext<ContextProps>({
  switchTheme: () => null,
  mode: 'light',
  brand: brandInitialState(),
  theme: lightTheme(),
})

export const useTheme = () => React.useContext<ContextProps>(ThemeContext)

interface ProviderProps {
  initialMode: ThemeModes
}

export const MyThemeProvider: React.FC<ProviderProps> = ({ children, initialMode }) => {
  const [mode, setMode] = useState<ThemeModes>(initialMode)
  const brand = useSelector(getBranding)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(
      BrandingActions.SagaSetBranding.withCallback({
        callback: () => {
          // console.log(res)
        },
      })
    )
  }, [dispatch])

  const switchTheme = () => {
    document.documentElement.setAttribute('data-transition', 'none') // prevent un-equal transitions
    const setDocumentClass = (theme) => {
      if (theme === 'dark') document.documentElement.classList.add('dark')
      else document.documentElement.classList.remove('dark')
    }

    setMode((flag) => {
      const nextMode = flag === 'dark' ? 'light' : 'dark'
      document.documentElement.setAttribute('data-theme', nextMode)
      setDocumentClass(nextMode)
      return nextMode
    })
  }

  useEffect(() => {
    if (brand.enabled) document.documentElement.setAttribute('data-whitelabel', 'true')
    else document.documentElement.setAttribute('data-whitelabel', 'false')
  }, [brand])

  useEffect(() => {
    setTimeout(() => document.documentElement.removeAttribute('data-transition'), [300])
  }, [mode])

  useEffect(() => {
    Cookies.set('kenai-dashboard-theme', mode)
  }, [mode])

  const primaryColor = brand.enabled ? brand.primaryColor : config.primaryColor
  const theme = mode === 'dark' ? darkTheme(primaryColor) : lightTheme(primaryColor)

  return (
    <div className='theme-provider'>
      <ThemeContext.Provider value={{ switchTheme, mode, brand, theme }}>
        <ThemeProvider theme={{ palette, mode, primaryColor }}>{children}</ThemeProvider>
      </ThemeContext.Provider>
      <style jsx>{`
        .theme-provider {
          background: red;
        }
      `}</style>
    </div>
  )
}

export default MyThemeProvider
