import { Avatar, List, Modal, Popover } from 'antd'
import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import ProfilePage from 'src/containers/Profile'
import { ActionCreators } from 'src/redux/sagas/authentication'
import { getUserProfile } from 'src/redux/store/profile'
import { antPrefix, cssvars } from 'src/styles'
import { transition } from 'src/utils/style-util'
import { useVisible } from 'src/hooks/use-visible'
import styled from 'styled-components'
import { faUserCircle } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Tooltip, { AbstractTooltipProps } from 'antd/lib/tooltip'

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  margin: -12px -16px;
  min-width: 200px;
  width: auto;
  flex-shrink: 0;

  ${transition()};
  overflow: hidden;
  cursor: default;

  .${antPrefix}-list {
    padding: 0 5px;
    &-item {
      padding-left: 10px;
      padding-right: 10px;
      cursor: pointer;
      &:hover {
        color: ${cssvars.primaryColor};
        border-color: ${cssvars.borderColor};
      }
      border-color: ${cssvars.borderColor}!important;
    }
    li:first-of-type {
      border-top: 1px solid ${cssvars.borderColor};
    }
  }
`

export const getProfileInitials = function (string) {
  const names = string.split(' ')
  let initials = names[0].substring(0, 1).toUpperCase()

  if (names.length > 1) {
    initials += names[names.length - 1].substring(0, 1).toUpperCase()
  }
  return initials
}

const Profile = ({ profile, handleSignOut, handleClose }) => {
  // const history = useHistory()
  const { visible, setVisible } = useVisible(false)
  return (
    <Container>
      <Modal
        visible={visible}
        closable
        onOk={() => setVisible(false)}
        onCancel={() => setVisible(false)}
        cancelText={null}
        okText={<FormattedMessage id='common.save' />}
        cancelButtonProps={{ style: { display: 'none' } }}
        className='no-padding'
        footer={null}
      >
        <ProfilePage />
      </Modal>
      <div className='flex p-3'>
        <div className='flex-grow'>
          {profile.umName && <strong>{profile.umName}</strong>}
          {profile.umEmail && <p style={{ marginBottom: 0 }}>{profile.umEmail}</p>}
        </div>
        <Avatar className='text-vars-primaryColor bg-vars-bodyBackground'>{getProfileInitials(profile.umName)}</Avatar>
      </div>
      <List bordered={false}>
        <List.Item
          onClick={() => {
            handleClose()
            setVisible(true)
          }}
        >
          Account settings
        </List.Item>
        <List.Item
          onClick={() => {
            handleClose()
            handleSignOut()
          }}
        >
          Log Out
        </List.Item>
      </List>
    </Container>
  )
}

const User = ({
  placement = 'bottomLeft',
  trigger = 'click',
  className = '',
  icon = faUserCircle,
}: {
  placement?: AbstractTooltipProps['placement']
  className?: string
  trigger?: string | string[]
  icon?: typeof faUserCircle
}) => {
  const [popoverVisible, setPopoverVisible] = useState(false)
  const profile = useSelector(getUserProfile)
  const dispatch = useDispatch()

  function togglePopover() {
    setPopoverVisible(!popoverVisible)
  }

  function _handleSignOut() {
    dispatch({ type: ActionCreators.SagaSignOut.type })
  }

  return (
    <Tooltip title='Account' trigger='hover' placement='right' {...(popoverVisible && { visible: false })}>
      <Popover
        content={<Profile profile={profile} handleSignOut={_handleSignOut} handleClose={() => setPopoverVisible(false)} />}
        trigger={trigger}
        visible={popoverVisible}
        onVisibleChange={togglePopover}
        arrowPointAtCenter
        placement={placement}
      >
        <div className={`flex items-center justify-center ${className}`} aria-label='Account and Settings'>
          <FontAwesomeIcon icon={icon} />
        </div>
      </Popover>
    </Tooltip>
  )
}

export default User
