import { useState } from 'react'

export const useVisible = (visible = false) => {
  const [isVisible, setIsShowing] = useState(visible)

  function toggleVisible() {
    setIsShowing(!isVisible)
  }

  function setVisible(visible: boolean) {
    setIsShowing(visible)
  }

  return {
    isVisible,
    visible: isVisible,
    toggleVisible,
    setVisible,
  }
}
