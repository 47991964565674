import { UploadProps } from 'antd'
import { cloneDeep } from 'lodash'
import XLSX from 'xlsx'

type FILE = Parameters<UploadProps['customRequest']>[0]['file']

function readFile(file: FILE) {
  const reader = new FileReader()
  return new Promise<Error | string | ArrayBuffer>((resolve, reject) => {
    reader.onerror = () => {
      reader.abort()
      reject(new Error('Problem parsing input file.'))
    }

    reader.onload = (readEvent: FileReaderEventMap['load']) => {
      if (readEvent && readEvent.target && readEvent.target.result) {
        resolve(readEvent.target.result)
      } else {
        reject(new Error('Problem parsing input file.'))
      }
    }

    if (file instanceof Blob) {
      reader.readAsBinaryString(file)
    } else {
      // eslint-disable-next-line no-debugger
      debugger // this should never happen
    }
  })
}

function readSheet<T = any>(binaryString: string | ArrayBuffer, sheetName: string, version: string) {
  const sheets = XLSX.read(binaryString, { type: 'binary' })?.Sheets

  const defaultSheetName = sheetName || Object.keys(sheets)?.[0]
  const rawSheet = sheets?.[defaultSheetName]

  if (!rawSheet) {
    throw new Error(`The sheet named ${defaultSheetName} could not be found in this document`)
  }
  /** update_sheet_range fixes export bux with .xlsx using excel (compressed .xls) */
  ;(function update_sheet_range(ws) {
    const range = { s: { r: 20000000, c: 20000000 }, e: { r: 0, c: 0 } }
    Object.keys(ws)
      .filter(function (x) {
        return x.charAt(0) !== '!'
      })
      .map(XLSX.utils.decode_cell)
      .forEach(function (x) {
        range.s.c = Math.min(range.s.c, x.c)
        range.s.r = Math.min(range.s.r, x.r)
        range.e.c = Math.max(range.e.c, x.c)
        range.e.r = Math.max(range.e.r, x.r)
      })
    ws['!ref'] = XLSX.utils.encode_range(range)
  })(rawSheet)

  const sheet = cloneDeep(XLSX.utils.sheet_to_json(rawSheet)) as T[]

  if (version) {
    const sheetVersion = rawSheet.A2.v
    if (sheetVersion !== version) {
      throw new Error(`You seem to have an old version of the sheet, please download it again to continue.`)
    }
  }

  return sheet.splice(version ? 5 : 4, sheet.length)
}

/** A function to help parse sheets from a upload file
 * @param file - The file to parse
 * @param sheetName - The name of the sheet to parse
 * @param version - The version of the sheet to parse for example `v1`
 */
export default async function parseSheet<T = any>(file: FILE, sheetName = '', version = '') {
  const binaryString = await readFile(file)
  if (binaryString instanceof Error) {
    throw binaryString
  } else {
    return readSheet<T>(binaryString, sheetName, version)
  }
}
