import { InviteLogItem } from 'src/redux/store/visitors/types/invites'

export const calculateInviteStringForItem = (item: InviteLogItem) => {
  let searchString = ''

  if (item.firstHostFullDetails) {
    for (const prop in item.firstHostFullDetails) {
      const value = item.firstHostFullDetails[prop]
      if (value !== 'boolean') {
        searchString += ` ${item.firstHostFullDetails[prop]}`
      }
    }
  }
  if (item.secondHostFullDetails) {
    for (const prop in item.secondHostFullDetails) {
      const value = item.secondHostFullDetails[prop]
      if (value !== 'boolean') {
        searchString += ` ${item.secondHostFullDetails[prop]}`
      }
    }
  }
  if (item.organizerInformation) {
    for (const prop in item.organizerInformation) {
      const value = item.organizerInformation[prop]
      if (value !== 'boolean') {
        searchString += ` ${item.organizerInformation[prop]}`
      }
    }
  }

  if (item.registeredProfileName) {
    searchString += ` ${item.registeredProfileName}`
  }
  if (item.registeredProfileFirstName) {
    searchString += ` ${item.registeredProfileFirstName}`
  } else if (item.firstName) {
    searchString += ` ${item.firstName}`
  }
  if (item.registeredProfileLastName) {
    searchString += ` ${item.registeredProfileLastName}`
  } else if (item.lastName) {
    searchString += ` ${item.lastName}`
  }

  if (item.email) {
    searchString += ` ${item.email}`
  }

  if (item.phoneNumber) {
    searchString += ` ${item.phoneNumber}`
  }

  if (item.company) {
    searchString += ` ${item.company}`
  }

  if (item.personalIdentificationNr) {
    searchString += ` ${item.personalIdentificationNr}`
  }

  if (item.dietaryRequirements) {
    searchString += ` ${item.dietaryRequirements}`
  }

  if (item.location && item.location !== 'not specified') {
    searchString += ` ${item.location}`
  }

  return searchString.toLowerCase()
}
export function generateInviteLogSearchString(data: InviteLogItem[]) {
  data.forEach((item) => {
    item.searchString = calculateInviteStringForItem(item)
  })
}
