import { lazy, useMemo } from 'react'
import { useLegacyLayout } from 'src/components/legacy-layout-provider'
import WelcomeScreen from 'src/containers/welcome-screen'
import useHierarchyEnabledFlags, { HierarchyEnabledFlags } from 'src/hooks/use-hierarchy-enabled-flags'
import AccessManager from 'src/utils/AccessManager'
import getEmployeeRoutes from './getEmployeeRoutes'
import getIntegrationRoutes from './getIntegrationRoutes'
import getParkingRoutes from './getParkingRoutes'
import getSettingsRoutes from './getSettingsRoutes'
import getVisitorRoutes from './getVisitorRoutes'
import getInductionRoutes from './getInductionRoutes'
import { showRoute } from './show-route'
import { ROUTE } from 'src/typings/kenai/dashboard-router'
import { faUserSecret } from '@fortawesome/pro-light-svg-icons'
import getMessagingRoutes from './getMessagingRoutes'

const Profile = lazy(() => import('src/containers/Profile'))

const getRoutes = (hierarchyEnabledFlags: HierarchyEnabledFlags, isLegacyLayout: boolean): ROUTE[] => {
  const role = AccessManager.selectedHierarchy.role
  const routes: ROUTE[] = [
    {
      path: '/',
      sidebar: false,
      component: WelcomeScreen,
    },

    {
      path: 'dashboard',
      sidebar: false,
      component: WelcomeScreen,
    },
    {
      path: 'profile',
      sidebar: false,
      exact: true,
      component: Profile,
    },
  ]

  if (hierarchyEnabledFlags.loading === false) {
    if (showRoute(hierarchyEnabledFlags.visitorLog, isLegacyLayout)) {
      // VISITOR ROUTES
      // routes.push(getVisitorRoutes(hierarchyEnabledFlags, false))
      routes.push(getVisitorRoutes(hierarchyEnabledFlags, hierarchyEnabledFlags.visitorLog, isLegacyLayout))
    }

    if (role !== 'HOST') {
      const employeesEnabled =
        hierarchyEnabledFlags.employeeLog || hierarchyEnabledFlags.employeeScreeningLog || hierarchyEnabledFlags.employeeDirectory
      if (showRoute(employeesEnabled, isLegacyLayout)) {
        // EMPLOYEE ROUTE
        routes.push(
          getEmployeeRoutes(
            hierarchyEnabledFlags.employeeLog,
            hierarchyEnabledFlags.employeeScreeningLog,
            hierarchyEnabledFlags.employeeDirectory,
            employeesEnabled,
            hierarchyEnabledFlags.dafLog,
            hierarchyEnabledFlags.employeeRouteType,
            isLegacyLayout
          )
        )
      }

      const parkingEnabled =
        hierarchyEnabledFlags.parkingLog || hierarchyEnabledFlags.parkingBookingLog || hierarchyEnabledFlags.parkingAnalytics
      if (showRoute(parkingEnabled, isLegacyLayout)) {
        // PARKING ROUTE
        routes.push(getParkingRoutes(hierarchyEnabledFlags, parkingEnabled, isLegacyLayout))
      }
    }

    // PRE-MIGRATION START
    if (hierarchyEnabledFlags.blocklist) {
      const blocklistRoutes: ROUTE[] = [
        {
          path: 'block-list',
          label: 'Block List',
          icon: faUserSecret,
          component: lazy(() => import('src/containers/BlockList')),
          sidebar: true,
          exact: true,
        },
      ]

      routes.push(...blocklistRoutes)
    }

    // PRE-MIGRATION END

    if (hierarchyEnabledFlags.inductionLog) {
      // INTEGRATION ROUTE
      const inductionRoutes = getInductionRoutes(hierarchyEnabledFlags.inductionLog)
      routes.push(inductionRoutes)
    }

    // If one of the messaging features is enabled, show the messaging routes
    if (Object.values(hierarchyEnabledFlags.messaging).filter(Boolean).length) {
      const messagingRoutes = getMessagingRoutes(hierarchyEnabledFlags)
      routes.push(messagingRoutes)
    }

    // ADMIN ROUTES
    if (role === 'ADMIN') {
      if (hierarchyEnabledFlags.integrations) {
        // INTEGRATION ROUTE
        const integrationRoutes = getIntegrationRoutes()
        routes.push(integrationRoutes)
      }

      if (hierarchyEnabledFlags.tenantConfiguration) {
        // SETTINGS ROUTES
        const settingsRoutes = getSettingsRoutes(hierarchyEnabledFlags)
        routes.push(settingsRoutes)
      }
    }
  }

  return routes
}

const useRoutes = (): ROUTE[] => {
  const hierarchyEnabledFlags = useHierarchyEnabledFlags()
  const { isLegacyLayout } = useLegacyLayout()
  const routes = useMemo(() => getRoutes(hierarchyEnabledFlags, isLegacyLayout), [hierarchyEnabledFlags, isLegacyLayout])
  return routes
}

export default useRoutes
