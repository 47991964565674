import getIntl from 'src/i18n'

import { ParkingLogSearchableEventSet, PersonDetails, VehicleDetails } from 'src/typings/kenai/parking/log'
import { EntityHierarchyConfig } from 'src/typings/kenai/configuration/entity-hierarchy'

// import flattenObject from 'src/utils/flattenObject'

function vehicleDetailsSearchString(vehicleDetails: VehicleDetails) {
  let vehicleDetailsString = ''
  vehicleDetailsString += ` ${vehicleDetails.vehicleMake} ${vehicleDetails.vehicleModel}`
  for (const prop in vehicleDetails) {
    if (prop === 'vehicleRawData') {
      // const rawData = JSON.parse(vehicleDetails[prop])
      // let rawDataString = ''
      // rawData.forEach((string: string) => {
      //   if (string.length > 0) {
      //     rawDataString += ` ${string}`
      //   }
      // })
      // vehicleDetailsString += ` ${rawDataString}`
    } else {
      vehicleDetailsString += ` ${vehicleDetails[prop]}`
    }
  }
  return vehicleDetailsString
}

function personDetailsSearchString(personDetails: PersonDetails) {
  let personDetailsString = ''
  personDetailsString += ` ${personDetails.firstName} ${personDetails.lastName}`
  for (const prop in personDetails) {
    if (prop === 'personRawData') {
      // const rawData = flattenObject(JSON.parse(personDetails[prop]))
      // for (let rawDataProp in rawData) {
      //   if (rawData[rawDataProp].length > 0) personDetailsString += ` ${rawData[rawDataProp]}`
      // }
    } else {
      personDetailsString += ` ${personDetails[prop]}`
    }
  }
  return personDetailsString
}

const calculateSearchStringForItem = (item: ParkingLogSearchableEventSet, entityHierarchyConfiguration: EntityHierarchyConfig) => {
  const intl = getIntl()
  let searchString = ''

  if (item.leadingEvent.hasOwnProperty('passengers')) {
    searchString += ` ${item.leadingEvent.passengers}`
  }

  if (item.leadingEvent.vehicleDetails) {
    searchString += ` ${vehicleDetailsSearchString(item.leadingEvent.vehicleDetails)}`
  }
  if (item.leadingEvent.hasOwnProperty('personDetails')) {
    searchString += ` ${personDetailsSearchString(item.leadingEvent.personDetails)}`
  }

  if (item.leadingEvent.hasOwnProperty('customFields')) {
    Object.keys(item.leadingEvent.customFields).forEach((fieldId) => {
      const fieldDefinition = entityHierarchyConfiguration.additionalConfigs.customFields[item.EntityHierarchy]?.find(
        (customField) => customField.fieldId === fieldId
      )
      if (fieldDefinition) {
        let value: any = item.leadingEvent.customFields[fieldId]
        if (fieldDefinition.inputType === 'staticDropDownListSingleSelect') {
          const staticItem = fieldDefinition?.staticDropDownListItems?.find((staticItem) => staticItem.itemId === value)
          if (staticItem) {
            value = ` ${intl.formatMessage({ id: staticItem.optionTextId })}`
          } else {
            value = ''
          }
        } else if (fieldDefinition.inputType === 'staticDropDownListMultiSelect') {
          const parts = value.split(';')
          const resolvedParts: any = []
          parts.forEach((part) => {
            const staticItem = fieldDefinition?.staticDropDownListItems?.find((staticItem) => staticItem.itemId === part)
            if (staticItem) {
              resolvedParts.push(intl.formatMessage({ id: staticItem.optionTextId }))
            }
          })
          if (resolvedParts.length) {
            value = resolvedParts.join(', ')
          } else {
            value = ''
          }
        }
        //catches reslved sddl items above as well as normal inputs
        if (value) {
          searchString += ` ${value}`
        }
      }
    })
  }

  searchString += ` ${item.vehicleStatus}`
  searchString += ` ${item.visitorStatus}`

  if (item.hasOwnProperty('relatedEvents') && item?.relatedEvents?.length > 0) {
    item?.relatedEvents?.forEach((relatedItem) => {
      searchString += calculateSearchStringForItem(relatedItem as ParkingLogSearchableEventSet, entityHierarchyConfiguration)
    })
  }

  if (item.leadingEvent.hasOwnProperty('parkingBookingDetails')) {
    const parkingBookingDetails = item.leadingEvent.parkingBookingDetails
    searchString += ` ${parkingBookingDetails.BookingRefCode}`
    if (parkingBookingDetails.parkingData?.parkingLicensePlate) {
      searchString += ` ${parkingBookingDetails.parkingData.parkingLicensePlate}`
    }
    if (parkingBookingDetails.parkingData?.secondaryParkingLicensePlates) {
      searchString += ` ${parkingBookingDetails.parkingData.secondaryParkingLicensePlates}`
    }
    if (parkingBookingDetails.parkingProviderRefs) {
      const parkingProviderRefs = parkingBookingDetails.parkingProviderRefs
      if (parkingProviderRefs.assignedParkingSectionBayGroup) {
        searchString += ` ${parkingProviderRefs.assignedParkingSectionBayGroup}`
      }
      if (parkingProviderRefs.assignedParkingSectionParkingArea) {
        searchString += ` ${parkingProviderRefs.assignedParkingSectionParkingArea}`
      }
      if (parkingProviderRefs.assignedParkingSectionClassification) {
        searchString += ` ${parkingProviderRefs.assignedParkingSectionClassification}`
      }
      if (parkingProviderRefs.parkingAccessIntegrationValue) {
        searchString += ` ${parkingProviderRefs.parkingAccessIntegrationValue}`
      }
      if (parkingProviderRefs.externalSystemUserRef) {
        searchString += ` ${parkingProviderRefs.externalSystemUserRef}`
      }
    }
  }

  return searchString.toLowerCase()
}

export function generateSearchString(data: ParkingLogSearchableEventSet[], entityHierarchyConfiguration: EntityHierarchyConfig) {
  data.forEach((item) => {
    item.searchString = calculateSearchStringForItem(item, entityHierarchyConfiguration)
  })
}
