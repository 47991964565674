import { notification as antNotification } from 'antd'
import { ArgsProps } from 'antd/lib/notification'
import { Map } from 'immutable'

export function clearToken() {
  localStorage.removeItem('id_token')
}

export function getToken() {
  try {
    const idToken = localStorage.getItem('id_token')
    return Map({ idToken })
  } catch (err) {
    clearToken()
    return Map()
  }
}

export function stringToInt(value, defValue = 0) {
  if (!value) {
    return 0
  } else if (!isNaN(value)) {
    return parseInt(value, 10)
  }
  return defValue
}

export function stringToPositiveInt(value, defValue = 0) {
  const val = stringToInt(value, defValue)
  return val > -1 ? val : defValue
}

export function downloadFile(content, fileName, mimeType) {
  const a = document.createElement('a')
  mimeType = mimeType || 'application/octet-stream'

  if (navigator.msSaveBlob) {
    // IE10
    navigator.msSaveBlob(
      new Blob([content], {
        type: mimeType,
      }),
      fileName
    )
  } else if (URL && 'download' in a) {
    //html5 A[download]
    a.href = URL.createObjectURL(
      new Blob([content], {
        type: mimeType,
      })
    )
    a.setAttribute('download', fileName)
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
  } else {
    location.href = 'data:application/octet-stream,' + encodeURIComponent(content) // eslint-disable-line
  }
}

type TypeKeys = 'success' | 'error' | 'info' | 'warn' | 'warning' | 'open'
function notification(type: TypeKeys, message: ArgsProps['message'], description?: ArgsProps['description']) {
  const resolvedMessage = message instanceof Error ? message.message : message
  antNotification[type]({
    message: resolvedMessage,
    description: description,
  })
}
notification.open = antNotification.open

export { notification }
/** ###3 inserts item into array
 * @type array : A
 * @index number
 * @elements type A[]
 */
export function insertAt<A>(array: A[], index, ...elementsArray: A[]) {
  array.splice(index, 0, ...elementsArray)
}

export const inputCursorPosition = (input: HTMLTextAreaElement | HTMLInputElement) => {
  let startPos = input?.value?.length ?? 0
  let endPos = input?.value?.length ?? 0
  if (input.selectionStart) {
    startPos = input.selectionStart
    endPos = input.selectionEnd || endPos
  }
  return { startPos, endPos }
}

/** ### Inserts text at current position in TextArea */
export const insertTextAtCursor = (field: HTMLTextAreaElement | HTMLInputElement, insertValue: string) => {
  //IE support
  if (document.selection) {
    field.focus()
    const sel = document.selection.createRange()
    sel.text = insertValue
  }
  //MOZILLA and others
  else if (field.selectionStart || field.selectionStart === 0) {
    const startPos = field.selectionStart
    const endPos = field.selectionEnd || 0
    field.value = field.value.substring(0, startPos) + insertValue + field.value.substring(endPos, field.value.length)
    // debugger
    field.selectionStart = startPos + insertValue.length
    field.selectionEnd = startPos + insertValue.length
    field.focus()
  } else {
    field.value += insertValue
    field.focus()
  }

  return field.value
}
