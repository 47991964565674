import update from 'immutability-helper'
import { createSelector } from 'reselect'
import { retrieveEntityDevices } from 'src/api/hierarchyOperations/retrieveEntityDevices'
import { PromiseValue } from 'type-fest'
import { RootState } from '../rootReducer'
import { ActionCreator } from '../store-action-creator'

export interface State {
  data: PromiseValue<ReturnType<typeof retrieveEntityDevices>>
}

// Action Creators
export const ActionCreators = {
  StoreSetDevices: new ActionCreator<'StoreSetDevices', State['data']>('StoreSetDevices'),
  ClearAllStates: new ActionCreator<'ClearAllStates', void>('ClearAllStates'),
}
export const InviteLogStoreActionCreators = ActionCreators

type Action = typeof ActionCreators[keyof typeof ActionCreators]

export const initialState = (): State => ({
  data: [],
})

// Reducer
export default function reducer(state: State = initialState(), action: Action) {
  switch (action.type) {
    case ActionCreators.StoreSetDevices.type:
      console.log('[DEVICE]: setting devices', action.payload)
      return update(state, {
        data: { $set: action.payload },
      })
    case ActionCreators.ClearAllStates.type:
      return initialState()
    default:
      return state
  }
}

const getDevicesState = (state: RootState) => state.visitors.devices.data
export const getDevices = createSelector<any, State['data'], State['data']>([getDevicesState], (value) => value)
