import { faFireExtinguisher } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Badge, Button, Popover, Space, Tooltip, Tree, Typography, Checkbox, Radio } from 'antd'
import React, { useState, useEffect, useMemo } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import TimeAgo from 'react-timeago'
import buildFormatter from 'react-timeago/lib/formatters/buildFormatter'
import useIsMultiTenant from 'src/hooks/use-is-multi-tenant'
import { useNestedLocationData } from 'src/hooks/use-nested-location-data'
import { useVisible } from 'src/hooks/use-visible'
import { EvacuationActionCreators } from 'src/redux/sagas/evacuation'
import { getUserProfile } from 'src/redux/store/profile'
import { EntityHierarchyConfig } from 'src/typings/kenai/configuration/entity-hierarchy'
import { notification } from 'src/utils'
import AccessManager from 'src/utils/AccessManager'
import { ReactComponent as EvacuationImage } from 'src/visual/image/evacuate.svg'
import { getEvacuationStatusConfig } from './utils/get-evacuation-status-config'
import classnames from 'src/utils/classnames'
import { getFeatureFlags } from 'src/redux/store/features'
import { EvacuationAudience } from 'src/containers/Messaging/Evacuation'
import useHierarchyEnabledFlags from 'src/hooks/use-hierarchy-enabled-flags'
import { useSelectedHierarchy } from 'src/hooks/use-selected-hierarchy'

const LocaleTimeAgo = ({ date }) => {
  const intl = useIntl()
  const locale = intl.locale.split('-')[0].split('_')[0]
  //todo locale
  const formatter = buildFormatter(require(`react-timeago/lib/language-strings/${locale}`).default)
  return (
    <Typography.Text style={{ opacity: 0.5 }}>
      (started <TimeAgo date={date} formatter={formatter} />)
    </Typography.Text>
  )
}

interface EvacuationButtonProps {
  evacuationConfig: EntityHierarchyConfig['additionalConfigs']['evacuation']
  audience?: (keyof EvacuationAudience)[] | keyof EvacuationAudience
}

const PopoverContent = (props: { canEndEvacuation: boolean; onNo: () => void; onYes: (audience?: EvacuationAudience) => void }) => {
  const nestedLocationData = useNestedLocationData()
  const isMultiTenant = useIsMultiTenant()
  const features = useHierarchyEnabledFlags()
  const { employeeDirectoryEvacEnabled, onSiteEmployeeEvacEnabled } = features.evacuation.audienceEnablement
  const showRadioSelection = employeeDirectoryEvacEnabled && onSiteEmployeeEvacEnabled

  const defaultState = useMemo(() => {
    return {
      onsiteVisitors: features.evacuation.audienceEnablement.onSiteVisitorsEvacEnabled,
      onsiteEmployees: features.evacuation.audienceEnablement.onSiteEmployeeEvacEnabled,
      employeeDirectory: showRadioSelection ? false : features.evacuation.audienceEnablement.employeeDirectoryEvacEnabled,
      onsiteParking: features.evacuation.audienceEnablement.onSiteParkingEvacEnabled,
    }
  }, [features.evacuation.audienceEnablement, showRadioSelection])

  const [audience, setAudience] = useState<EvacuationAudience>(defaultState)

  useEffect(() => {
    setAudience(defaultState)
  }, [defaultState])

  const selectedAudience = Object.keys(audience).filter((key) => audience[key])
  const yesEnabled = selectedAudience.length || props.canEndEvacuation

  const handleYes = (audience?: EvacuationAudience) => {
    setAudience(defaultState)

    props.onYes(audience)
  }

  const EmployeeAudience = () => {
    if (!showRadioSelection) {
      return null
    }

    let checkedValue = 'onsiteEmployees'

    if (audience.employeeDirectory) {
      checkedValue = 'employeeDirectory'
    }

    return (
      <Radio.Group
        defaultValue={checkedValue}
        onChange={({ target: { value } }) => {
          setAudience((prev) => {
            let onsiteEmployees = false
            let employeeDirectory = false

            if (value === 'onsiteEmployees') {
              onsiteEmployees = true
              employeeDirectory = false
            } else {
              onsiteEmployees = false
              employeeDirectory = true
            }

            return {
              ...prev,
              employeeDirectory,
              onsiteEmployees,
            }
          })
        }}
        options={[
          {
            label: 'On-site Employees',
            value: 'onsiteEmployees',
          },
          {
            label: 'Employee Directory',
            value: 'employeeDirectory',
          },
        ]}
      />
    )
  }

  return (
    <Space direction='vertical' className='w-sm max-w-screen text-center' size='middle'>
      <div className='rounded-sm flex justify-center' style={{ background: '#4cb75a' }}>
        <EvacuationImage className='text-primary w-40 h-40' />
      </div>
      <Typography.Title level={4} className={classnames('mb-0', { 'text-left': isMultiTenant })}>
        {props.canEndEvacuation
          ? 'Before you end the evacuation, ensure all relevant logs are exported. Ending will revert logs to their standard operation.'
          : `Are you sure you want to start the evacuation procedure${isMultiTenant ? ' at the following locations?' : '?'}`}
      </Typography.Title>
      {isMultiTenant && (
        <Tree
          treeData={nestedLocationData}
          defaultExpandAll
          checkable={false}
          selectable={false}
          className='mx-auto bg-transparent pointer-events-none'
        />
      )}

      {!props.canEndEvacuation && (
        <>
          <Typography.Title level={5} className='mb-0'>
            You are about to evacuate these audiences
          </Typography.Title>

          <Checkbox.Group value={selectedAudience}>
            {features.evacuation.audienceEnablement.onSiteVisitorsEvacEnabled && (
              <Checkbox value='onsiteVisitors' checked className='pointer-events-none'>
                On-site Visitors
              </Checkbox>
            )}
            {features.evacuation.audienceEnablement.onSiteParkingEvacEnabled && (
              <Checkbox value='onsiteParking' checked className='pointer-events-none'>
                On-site Parking
              </Checkbox>
            )}
            {!showRadioSelection && (
              <>
                {features.evacuation.audienceEnablement.onSiteEmployeeEvacEnabled && (
                  <Checkbox value='onsiteEmployees' checked className='pointer-events-none'>
                    On-site Employees
                  </Checkbox>
                )}
                {features.evacuation.audienceEnablement.employeeDirectoryEvacEnabled && (
                  <Checkbox value='employeeDirectory' checked className='pointer-events-none'>
                    Employee Directory
                  </Checkbox>
                )}
              </>
            )}
          </Checkbox.Group>
          <EmployeeAudience />
        </>
      )}

      <Space>
        <Button key='cancel' type='default' onClick={props.onNo} style={{ textTransform: 'uppercase' }}>
          {props.canEndEvacuation ? <FormattedMessage id='common.cancel' /> : <FormattedMessage id='common.no' />}
        </Button>
        <Button key='yes' type='primary' onClick={() => handleYes(audience)} style={{ textTransform: 'uppercase' }} disabled={!yesEnabled}>
          {props.canEndEvacuation ? <FormattedMessage id='kenai.visitors.log.evacuation.end' /> : <FormattedMessage id='common.yes' />}
        </Button>
      </Space>
    </Space>
  )
}

const useEvacEnabledForAudience = (audience: EvacuationButtonProps['audience']) => {
  const features = useHierarchyEnabledFlags()

  if (!audience) {
    return true
  }

  const audienceArray = Array.isArray(audience) ? audience : [audience]

  const audienceToFeatureMap = {
    onsiteVisitors: features.evacuation.audienceEnablement.onSiteVisitorsEvacEnabled,
    onsiteEmployees: features.evacuation.audienceEnablement.onSiteEmployeeEvacEnabled,
    employeeDirectory: features.evacuation.audienceEnablement.employeeDirectoryEvacEnabled,
    onsiteParking: features.evacuation.audienceEnablement.onSiteParkingEvacEnabled,
  }

  for (const type of audienceArray) {
    if (!audienceToFeatureMap[type]) {
      return false
    }
  }

  return true
}

const EvacuationButton = (props: EvacuationButtonProps) => {
  const dispatch = useDispatch()
  const profile = useSelector(getUserProfile)
  const intl = useIntl()
  const { visible, setVisible } = useVisible()
  const selectedHierarchy = useSelectedHierarchy()
  const { evacuationActive, evacuationEvent, evacuationMutable } = getEvacuationStatusConfig(props.evacuationConfig, selectedHierarchy)
  const [loading, setLoading] = useState(false)
  const isMultiTenant = useIsMultiTenant()
  const multiTenantEvacTriggerEnabled = useSelector(getFeatureFlags).multiTenantEvacTriggerEnabled
  const audienceEnabled = useEvacEnabledForAudience(props.audience)

  if (!audienceEnabled) {
    return null
  }

  if (isMultiTenant && !multiTenantEvacTriggerEnabled) {
    return null
  }

  if (!profile.amEvacAdmin || AccessManager.selectedHierarchy.role !== 'ADMIN') {
    return null
  }

  const handleStartEvacuation = (audience: EvacuationAudience) => {
    setVisible(false)
    setLoading(true)
    dispatch(
      EvacuationActionCreators.SagaSetEvacuationState.withCallback({
        payload: {
          activate: true,
          audience,
        },
        callback: () => {
          notification('info', 'Evacuation started')
          setLoading(false)
        },
      })
    )
  }

  const handleEndEvacuation = () => {
    setVisible(false)
    setLoading(true)
    dispatch(
      EvacuationActionCreators.SagaSetEvacuationState.withCallback({
        payload: { activate: false },
        callback: () => {
          notification('info', 'Evacuation ended')
          setLoading(false)
        },
      })
    )
  }

  const evacIntl = (key: string) => intl.formatMessage({ id: `kenai.visitors.log.evacuation.${key}` })

  const canEndEvacuation = evacuationActive && evacuationMutable

  return (
    <div>
      <Popover
        visible={visible}
        placement='bottomRight'
        content={
          <PopoverContent
            canEndEvacuation={canEndEvacuation}
            onNo={() => setVisible(false)}
            onYes={canEndEvacuation ? handleEndEvacuation : handleStartEvacuation}
          />
        }
      >
        <div onClick={(e) => e.preventDefault()}>
          <Tooltip title={evacuationActive && !evacuationMutable ? evacIntl('tooltip') : undefined} arrowPointAtCenter>
            <Button {...props} onClick={() => setVisible(true)} disabled={!evacuationMutable} loading={loading}>
              {evacuationActive ? <Badge dot status='error' /> : <FontAwesomeIcon icon={faFireExtinguisher} />}
              <span>
                {canEndEvacuation ? `${evacIntl('end')} ` : evacuationActive ? evacIntl('since') : evacIntl('start')}{' '}
                {evacuationActive && evacuationEvent.activatedTimestamp && <LocaleTimeAgo date={evacuationEvent.activatedTimestamp} />}
              </span>
            </Button>
          </Tooltip>
        </div>
      </Popover>
    </div>
  )
}

export default EvacuationButton
