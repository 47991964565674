import config from 'src/config'

const configureWindow = () => {
  if (process.env.NODE_ENV !== 'development' && window.location.search.includes('log=true') === false && !config.staging) {
    // @ts-ignore
    window.console = {
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      log: () => {},
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      error: () => {},
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      warn: () => {},
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      debug: () => {},
    }
  }

  if (process.env.NODE_ENV === 'development') {
    window.console.debug = (...args) => window.console.log('debug', ...args)
  }

  if (window.location.search && window.location.search.length > 0) {
    const queryStringVariables = window.location.search.replace('?', '').split('&')
    if (window.location.search.includes('root=')) {
      window['KENAIConfigRootEH'] = queryStringVariables
        .filter((part) => part.includes('root='))[0]
        .split('=')[1]
        .replace(/_/g, '#')
    }
    if (window.location.search.includes('primarycolor=')) {
      window['KENAIConfigPrimaryColor'] = '#' + queryStringVariables.filter((part) => part.includes('primarycolor='))[0].split('=')[1]
    }
    if (window.location.search.includes('logo=')) {
      window['KENAIConfigLogo'] = decodeURIComponent(queryStringVariables.filter((part) => part.includes('logo='))[0].split('=')[1])
    }
    if (window.location.search.includes('whitelabel=true')) {
      window['KENAIConfigWhiteLabel'] = !!queryStringVariables.filter((part) => part.includes('whitelabel='))[0].split('=')[1]
    }
  }
  if (window.location.hostname.indexOf('frames') > -1) {
    window['KENAIFramePath'] = window.location.pathname
    window['KENAIConfigWhiteLabel'] = true
  }
}

export default configureWindow
