import { all, fork, put, takeLeading } from 'redux-saga/effects'
import API from 'src/api'
import {
  retrieveConfigCustomFields,
  retrieveConfigCustomNotifications,
  retrieveConfigDAF,
  retrieveConfigFlowTypes,
  retrieveConfigI18nTexts,
} from 'src/api/hierarchyOperations'
import { ActionCreators as DashboardBrandingActionCreators } from 'src/redux/sagas/dashboard/branding'
// Action Creators
import {
  ActionCreators as CredentialsActionCreators,
  getEntityConfigCustomFields,
  getEntityConfigCustomNotifications,
  getEntityConfigFlowTypes,
  getEntityConfigI18nTexts,
  getEntityHierarchyConfiguration,
} from 'src/redux/store/credentials'
import { ActionCreators as DAFActionCreators } from 'src/redux/store/employees/daf-store'
import { ActionCreators as ProfileActionCreators } from 'src/redux/store/profile'
import { ActionCreators as VisitorsActionCreators } from 'src/redux/store/visitors/log'
import { ActionCreators as SearchInviteHostsStoreActionCreators } from 'src/redux/store/visitors/manageInvites'
import { EntityHierarchyConfig, HierarchyDeepStructure, TenantFlags } from 'src/typings/kenai/configuration/entity-hierarchy'
import AccessManager from 'src/utils/AccessManager'
import { getTenantLocationName } from 'src/utils/getLocationName'
import { call, select } from 'typed-redux-saga'
import { refreshApiCredentials } from '../authentication/refreshApiCredentials'
import ActionCreator from '../saga-action-creator'
import { withInputType } from '../utils/with-input-type'
import { FeaturesActionCreators } from 'src/redux/store/features'

export const ActionCreators = {
  SetEntityHierarchyConfiguration: new ActionCreator<'SetEntityHierarchyConfiguration', any>('SetEntityHierarchyConfiguration'),
  SagaGetConfigI18nTexts: new ActionCreator<'SagaGetConfigI18nTexts', string>('SagaGetConfigI18nTexts'),
  SagaGetConfigCustomFields: new ActionCreator<'SagaGetConfigCustomFields', string>('SagaGetConfigCustomFields'),
  SagaGetConfigFlowTypes: new ActionCreator<'SagaGetConfigFlowTypes', string>('SagaGetConfigFlowTypes'),
  SagaGetConfigCustomNotifications: new ActionCreator<'SagaGetConfigCustomNotifications', string>('SagaGetConfigCustomNotifications'),
}

// Functions
const AWSHelper = new API.AWSHelpers()

function getCachedHierarchy(hierarchy: string, deepStructure: HierarchyDeepStructure[]) {
  if (hierarchy === 'ALL') return hierarchy
  const cachedHHierarchyDS = deepStructure.find((ha) => ha.hierarchyStructure === hierarchy)
  if (cachedHHierarchyDS) return cachedHHierarchyDS
  return deepStructure[0]
}

export function* processSetEntityHierarchyConfiguration() {
  try {
    const entityHierarchyConfiguration = yield select(getEntityHierarchyConfiguration)
    if (
      !entityHierarchyConfiguration.hasBeenLoaded ||
      (AccessManager.tokenDetails && AccessManager.tokenDetails.nonce !== entityHierarchyConfiguration.loadingNonce)
    ) {
      //trigger config reload after every token refresh by checking current and loaded nonce
      const retrievedEntityHierarchyConfiguration: {
        tenantFlags: TenantFlags[]
        hierarchy: EntityHierarchyConfig['hierarchy']
        additionalConfigs: EntityHierarchyConfig['additionalConfigs']
      } = yield* call([AWSHelper, AWSHelper.retrieveHierarchyConfiguration])

      if (retrievedEntityHierarchyConfiguration) {
        const { tenantFlags, hierarchy, additionalConfigs } = retrievedEntityHierarchyConfiguration
        const cachedHierarchy = localStorage.getItem('cachedHierarchy')

        let initialEntityHierarchy: ReturnType<typeof getCachedHierarchy> = hierarchy.deepStructure[0]

        if (cachedHierarchy) {
          initialEntityHierarchy = getCachedHierarchy(cachedHierarchy, hierarchy.deepStructure)
        }
        if (window['KENAIConfigRootEH']) {
          initialEntityHierarchy = hierarchy.flatStructure[window['KENAIConfigRootEH']]
        }

        // if (process.env.NODE_ENV === 'development') {
        //   console.log('[REMOVE BEFORE DEPLOYMENT] initialEntityHierarchy', initialEntityHierarchy)
        //   initialEntityHierarchy = hierarchy.deepStructure[1]
        // }

        AccessManager.setSelectedEntityHierarchy(initialEntityHierarchy)

        yield put(DashboardBrandingActionCreators.SagaSetBranding.create()) //TODO - WHITELABELING : set dashboard white labeling if flag enabled

        yield put(
          VisitorsActionCreators.StoreSetVisitorLogConfigs.create({
            induction: additionalConfigs.induction,
            deltaProfileFields: additionalConfigs.deltaProfileFields,
          })
        )
        yield put(
          SearchInviteHostsStoreActionCreators.StoreInviteHostSearchSetAvailableHostLinkingConfig.create(
            additionalConfigs.hostLinkingFields
          )
        )

        const customFieldsWithTypes = { ...additionalConfigs.customFields }

        for (const key in additionalConfigs.customFields) {
          if (customFieldsWithTypes.hasOwnProperty(key)) {
            customFieldsWithTypes[key] = withInputType(customFieldsWithTypes[key])
          }
        }

        const entityHierarchyConfiguration: EntityHierarchyConfig = {
          hasBeenLoaded: true,
          loadingNonce: AccessManager.tokenDetails && AccessManager.tokenDetails.nonce,
          tenantFlags,
          hierarchy,
          additionalConfigs: { ...additionalConfigs, customFields: customFieldsWithTypes },
        }

        yield put(CredentialsActionCreators.StoreSetEntityHierarchyConfiguration.create(entityHierarchyConfiguration))

        yield put(FeaturesActionCreators.StoreSetFeatures.create({ ehConfig: entityHierarchyConfiguration }))

        if (window.Intercom) {
          try {
            window?.Intercom?.('update', {
              companies: tenantFlags?.map?.((tenantFlag) => ({ name: getTenantLocationName(tenantFlag), id: tenantFlag.EntityHierarchy })),
            })
          } catch (error) {
            console.error(error)
          }
        }
      } else {
        throw Error('missing EH access')
      }

      if (AccessManager.profileAccess) {
        yield put(ProfileActionCreators.StoreSetProfileValues.create(AccessManager.profileAccess))
      } else {
        throw Error('missing token')
      }

      try {
        const configDaf = yield* call(retrieveConfigDAF)
        yield put(DAFActionCreators.StoreSetConfigDAF.create(configDaf))
      } catch (error) {
        console.error(error)
      }
    }
  } catch (e) {
    console.error(e)
  }
}

function* processSetConfigI18nTexts(action: typeof ActionCreators['SagaGetConfigI18nTexts']) {
  try {
    yield refreshApiCredentials()
    const updates = yield* call(retrieveConfigI18nTexts, action.payload)
    const allTranslations = yield* select(getEntityConfigI18nTexts)
    const updatedTranslations = {
      ...allTranslations,
      [action.payload]: updates,
    }
    yield put(CredentialsActionCreators.StoreSetConfigI18nTexts.create(updatedTranslations))
  } catch (error) {
    console.error(error)
  }
}
function* processSetConfigCustomFields(action: typeof ActionCreators['SagaGetConfigCustomFields']) {
  try {
    yield refreshApiCredentials()
    const updates = yield* call(retrieveConfigCustomFields, action.payload)
    const allCustomFields = yield* select(getEntityConfigCustomFields)
    const updatedCustomFields = {
      ...allCustomFields,
      [action.payload]: withInputType(updates),
    }
    yield put(CredentialsActionCreators.StoreSetConfigCustomFields.create(updatedCustomFields))
  } catch (e) {
    console.error(e)
  }
}

function* processSetCustomNotifications(action: typeof ActionCreators['SagaGetConfigCustomNotifications']) {
  try {
    yield refreshApiCredentials()
    const updates = yield* call(retrieveConfigCustomNotifications, action.payload)
    const allCustomFields = yield* select(getEntityConfigCustomNotifications)
    const updatedTranslations = {
      ...allCustomFields,
      [action.payload]: updates,
    }
    yield put(CredentialsActionCreators.StoreSetConfigCustomNotifications.create(updatedTranslations))
  } catch (e) {
    console.error(e)
  }
}
function* processSetConfigFlowTypes(action: typeof ActionCreators['SagaGetConfigFlowTypes']) {
  try {
    yield refreshApiCredentials()
    const updates = yield* call(retrieveConfigFlowTypes, action.payload)
    const allCustomFields = yield* select(getEntityConfigFlowTypes)
    const updatedTranslations = {
      ...allCustomFields,
      [action.payload]: updates,
    }
    yield put(CredentialsActionCreators.StoreSetConfigFlowTypes.create(updatedTranslations))
  } catch (e) {
    console.error(e)
  }
}

// Saga triggers
function* watchCredentialSagas() {
  yield takeLeading(ActionCreators.SetEntityHierarchyConfiguration.type, processSetEntityHierarchyConfiguration)
  yield takeLeading(ActionCreators.SagaGetConfigI18nTexts.type, processSetConfigI18nTexts)
  yield takeLeading(ActionCreators.SagaGetConfigCustomFields.type, processSetConfigCustomFields)
  yield takeLeading(ActionCreators.SagaGetConfigCustomNotifications.type, processSetCustomNotifications)
  yield takeLeading(ActionCreators.SagaGetConfigFlowTypes.type, processSetConfigFlowTypes)
  yield null
}

// Saga hooks
export default function* credentialsSagas() {
  yield all([fork(watchCredentialSagas)])
}
