import { VisitorLogItem } from 'src/redux/store/visitors/types/log'

export enum VERIFICATION_TYPES {
  RECOGNISED = 'recognised',
  VERIFIED = 'verified',
  UNVERIFIED = 'unverified',
  TOUCHLESS_AP = 'touchless.accesspass',
  TOUCHLESS_UNVERIFIED = 'touchless.unverified',
  NA = 'unavailable',
  DASHBOARD = 'dashboard',
  EXTERNAL_EVENT = 'external_event',
}

export enum VERIFICATION_FLOW_TYPES {
  SINGLE_MATCH_EXACT_PROFILE_CONFIRMED = 'SINGLE_MATCH_EXACT_PROFILE_CONFIRMED',
  MULTI_MATCH_EXACT_PROFILE_CONFIRMED = 'MULTI_MATCH_EXACT_PROFILE_CONFIRMED',
  NEW_VERIFIED_PROFILE_CREATED = 'NEW_VERIFIED_PROFILE_CREATED',
  NEW_VERIFIED_PROFILE_FROM_GLOBAL_PROFILE_CREATED = 'NEW_VERIFIED_PROFILE_FROM_GLOBAL_PROFILE_CREATED',
  NEW_VERIFIED_PROFILE_FROM_TRANSFERABLE_PROFILE_CREATED = 'NEW_VERIFIED_PROFILE_FROM_TRANSFERABLE_PROFILE_CREATED',
  NEW_UNVERIFIED_PROFILE_CREATED = 'NEW_UNVERIFIED_PROFILE_CREATED',
  TOUCHLESS_ACCESS_PASS = 'TOUCHLESS_ACCESS_PASS',
  TOUCHLESS_UNVERIFIED = 'TOUCHLESS_UNVERIFIED',
  DASHBOARD = 'DASHBOARD',
  EXTERNAL_EVENT = 'EXTERNAL_EVENT',
}

export function returnVerificationString(value: VisitorLogItem['sourceOfIdentification']) {
  if (
    value === VERIFICATION_FLOW_TYPES.SINGLE_MATCH_EXACT_PROFILE_CONFIRMED ||
    value === VERIFICATION_FLOW_TYPES.MULTI_MATCH_EXACT_PROFILE_CONFIRMED
  ) {
    return VERIFICATION_TYPES.RECOGNISED
  } else if (
    value === VERIFICATION_FLOW_TYPES.NEW_VERIFIED_PROFILE_CREATED ||
    value === VERIFICATION_FLOW_TYPES.NEW_VERIFIED_PROFILE_FROM_GLOBAL_PROFILE_CREATED ||
    value === VERIFICATION_FLOW_TYPES.NEW_VERIFIED_PROFILE_FROM_TRANSFERABLE_PROFILE_CREATED
  ) {
    return VERIFICATION_TYPES.VERIFIED
  } else if (value === VERIFICATION_FLOW_TYPES.NEW_UNVERIFIED_PROFILE_CREATED) {
    return VERIFICATION_TYPES.UNVERIFIED
  } else if (value === VERIFICATION_FLOW_TYPES.TOUCHLESS_ACCESS_PASS) {
    return VERIFICATION_TYPES.TOUCHLESS_AP
  } else if (value === VERIFICATION_FLOW_TYPES.TOUCHLESS_UNVERIFIED) {
    return VERIFICATION_TYPES.TOUCHLESS_UNVERIFIED
  } else if (value === VERIFICATION_FLOW_TYPES.DASHBOARD) {
    return VERIFICATION_TYPES.DASHBOARD
  } else if (value === VERIFICATION_FLOW_TYPES.EXTERNAL_EVENT) {
    return VERIFICATION_TYPES.EXTERNAL_EVENT
  }
  return VERIFICATION_TYPES.NA
}

export const verificationString = (value: VisitorLogItem['sourceOfIdentification'] | VisitorLogItem) => {
  if (value && value.hasOwnProperty('sourceOfIdentification')) {
    // value is the log item so get the sourceOfIdentification
    return returnVerificationString(value['sourceOfIdentification'])
  } else {
    // value is sourceOfIdentification
    return returnVerificationString(value as VisitorLogItem['sourceOfIdentification'])
  }
}

export const verificationStringTranslation = (type: VERIFICATION_TYPES) => {
  return 'common.' + type
}
