import TableCellAgreement from './table-cell-agreement'
import TableCellDate from './table-cell-date'
import TableCellDetails from './table-cell-details'
import TableCellLink from './table-cell-link'
import TableCellLocation from './table-cell-location'
import TableCellTags from './table-cell-tags'
import TableCellNA from './table-cell-na'

export { TableCellAgreement, TableCellDate, TableCellDetails, TableCellLink, TableCellLocation, TableCellTags, TableCellNA }

export default {
  TableCellAgreement,
  TableCellDate,
  TableCellDetails,
  TableCellLink,
  TableCellLocation,
  TableCellTags,
  TableCellNA,
}
