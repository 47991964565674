import { createSelector } from 'reselect'
import { RootState } from './rootReducer'

const getIsLockVisibleState = (state: RootState) => state.credentials.authCredentials
export const getIsLockVisible = createSelector<any, ReturnType<typeof getIsLockVisibleState>, ReturnType<typeof getIsLockVisibleState>>(
  [getIsLockVisibleState],
  (state) => state
)

const getServiceCredentialsReduxState = (state: RootState) => state.credentials.serviceCredentials
export const getServiceCredentials = createSelector<
  any,
  ReturnType<typeof getServiceCredentialsReduxState>,
  ReturnType<typeof getServiceCredentialsReduxState>
>([getServiceCredentialsReduxState], (state) => state)

const getWorkerSubscriptionCredentialState = (state: RootState) => state.credentials.workerSubscriptionCredential
export const getWorkerSubscriptionCredential = createSelector<
  any,
  ReturnType<typeof getWorkerSubscriptionCredentialState>,
  ReturnType<typeof getWorkerSubscriptionCredentialState>
>([getWorkerSubscriptionCredentialState], (state) => state)

const getParkingSubscriptionCredentialState = (state: RootState) => state.credentials.parkingSubscriptionCredential
export const getParkingSubscriptionCredential = createSelector<
  any,
  ReturnType<typeof getParkingSubscriptionCredentialState>,
  ReturnType<typeof getParkingSubscriptionCredentialState>
>([getParkingSubscriptionCredentialState], (state) => state)

const getParkingBookingSubscriptionCredentialState = (state: RootState) => state.credentials.parkingBookingSubscriptionCredential
export const getParkingBookingSubscriptionCredential = createSelector<
  any,
  ReturnType<typeof getParkingBookingSubscriptionCredentialState>,
  ReturnType<typeof getParkingBookingSubscriptionCredentialState>
>([getParkingBookingSubscriptionCredentialState], (state) => state)

const getEmployeePrescreeningSubscriptionCredentialState = (state: RootState) => state.credentials.preScreeningSubscriptionCredential
export const getEmployeePreScreeningSubscriptionCredential = createSelector<
  any,
  ReturnType<typeof getEmployeePrescreeningSubscriptionCredentialState>,
  ReturnType<typeof getEmployeePrescreeningSubscriptionCredentialState>
>([getEmployeePrescreeningSubscriptionCredentialState], (state) => state)

const getTransactionProcessingStateState = (state: RootState) => state.configuration.loadingState.transactionProcessing
export const getTransactionProcessingState = createSelector<any, boolean | undefined, boolean | undefined>(
  [getTransactionProcessingStateState],
  (state) => state
)

const getShouldSubscribeToWorkerUpdatesState = (state: RootState) => state.reporting.workerShouldSubscribeToUpdates
export const getShouldSubscribeToWorkerUpdates = createSelector<
  any,
  ReturnType<typeof getShouldSubscribeToWorkerUpdatesState>,
  ReturnType<typeof getShouldSubscribeToWorkerUpdatesState>
>([getShouldSubscribeToWorkerUpdatesState], (state) => state)

const getWorkerslogEventItemsState = (state: RootState) => state.reporting.workerslogItems
export const getWorkerslogEventItems = createSelector<
  any,
  ReturnType<typeof getWorkerslogEventItemsState>,
  ReturnType<typeof getWorkerslogEventItemsState>
>([getWorkerslogEventItemsState], (state) => state)

const getWorkerslogRawEventItemsState = (state: RootState) => state.reporting.workerslogRawItems
export const getWorkerslogRawEventItems = createSelector<
  any,
  ReturnType<typeof getWorkerslogRawEventItemsState>,
  ReturnType<typeof getWorkerslogRawEventItemsState>
>([getWorkerslogRawEventItemsState], (state) => state)

const getWorkerslogStatesState = (state: RootState) => state.reporting.workerslogStates
export const getWorkerslogStates = createSelector<
  any,
  ReturnType<typeof getWorkerslogStatesState>,
  ReturnType<typeof getWorkerslogStatesState>
>([getWorkerslogStatesState], (state) => state)

const getWorkerslogSettingsState = (state: RootState) => state.reporting.workerslogSettings
export const getWorkerslogSettings = createSelector<
  any,
  ReturnType<typeof getWorkerslogSettingsState>,
  ReturnType<typeof getWorkerslogSettingsState>
>([getWorkerslogSettingsState], (state) => state)

const getBioModalState = (state: RootState) => state.reporting.bioModal
export const getBioModalData = createSelector<any, ReturnType<typeof getBioModalState>, ReturnType<typeof getBioModalState>>(
  [getBioModalState],
  (state) => state
)

const getEnititySettingsState = (state: RootState) => state.configuration.values
export const getEntitySettings = createSelector<
  any,
  ReturnType<typeof getEnititySettingsState>,
  ReturnType<typeof getEnititySettingsState>
>([getEnititySettingsState], (state) => state)

const getUserlogEventItemsState = (state: RootState) => state.reporting.userlogItems
export const getUserlogEventItems = createSelector<
  any,
  ReturnType<typeof getUserlogEventItemsState>,
  ReturnType<typeof getUserlogEventItemsState>
>([getUserlogEventItemsState], (state) => state)

const getNotificationState = (state: RootState) => state.visitors.log.notifications
export const getNotificationData = createSelector<any, ReturnType<typeof getNotificationState>, ReturnType<typeof getNotificationState>>(
  [getNotificationState],
  (state) => state
)

const getInvitelogEventItemsState = (state: RootState) => state.reporting.invitelogItems
export const getInvitelogEventItems = createSelector<
  any,
  ReturnType<typeof getInvitelogEventItemsState>,
  ReturnType<typeof getInvitelogEventItemsState>
>([getInvitelogEventItemsState], (state) => state)

const getRouterState = (state: RootState) => state.router
export const getRouter = createSelector<RootState, ReturnType<typeof getRouterState>, ReturnType<typeof getRouterState>>(
  [getRouterState],
  (state) => state
)
