import { all, fork } from 'redux-saga/effects'
import sidebar, { ActionCreators as SidebarActionCreators } from './sidebar'
import branding, { ActionCreators as BrandingActionCreators } from './branding'

export const ActionCreators = {
  ...SidebarActionCreators,
  ...BrandingActionCreators,
}

export default function* dashboard() {
  yield all([fork(sidebar), fork(branding)])
}
