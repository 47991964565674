import { ActionCreator } from '../store-action-creator'
import { EmployeeDetails } from './types/directory'
import { RootState } from '../rootReducer'
import { createSelector } from 'reselect'
import update from 'immutability-helper'

// Action Creators
export const ActionCreators = {
  StoreSetEmployeeDirectoryData: new ActionCreator<'StoreSetEmployeeDirectoryData', Partial<EmployeeDetails[]>>(
    'StoreSetEmployeeDirectoryData'
  ),
  StoreSetLoading: new ActionCreator<'StoreSetLoading', Partial<boolean>>('StoreSetLoading'),

  StoreClearAllEmployeeDirectoryData: new ActionCreator<'StoreClearAllEmployeeDirectoryData', Partial<void>>(
    'StoreClearAllEmployeeDirectoryData'
  ),
  ClearAllStates: new ActionCreator<'ClearAllStates', Partial<void>>('ClearAllStates'),
}

export type Action = typeof ActionCreators[keyof typeof ActionCreators]

export type State = {
  data: EmployeeDetails[]
  loading: boolean
}

export const initialState = (): State => ({
  data: [],
  loading: false,
})

// Reducer
export default function reducer(state: State = initialState(), action) {
  switch (action.type) {
    case ActionCreators.StoreSetEmployeeDirectoryData.type:
      return update(state, {
        data: { $set: action.payload },
      })
    case ActionCreators.StoreSetLoading.type:
      return update(state, {
        loading: { $set: action.payload },
      })

    case ActionCreators.StoreClearAllEmployeeDirectoryData.type || ActionCreators.ClearAllStates.type:
      return initialState()
    default:
      return state
  }
}

// Selectors

const getEmployeesDirectoryState = (state: RootState) => state.employees.directory.data
export const getEmployeesDirectory = createSelector<any, EmployeeDetails[], EmployeeDetails[]>([getEmployeesDirectoryState], (state) => {
  return state
})

const getLoading = (state: RootState) => state.employees.directory.loading
export const getEmployeesDirectoryLoading = createSelector<any, any, boolean>([getLoading], (state: boolean) => {
  return state
})
