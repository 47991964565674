import React from 'react'
import Page from 'src/components/page'
import Fieldset from 'src/components/fieldset'
import basic from './basic'
import disabled from './disabled'
import ghost from './ghost'
import dropdown from './dropdown'
import { Space } from 'antd'

export default function DevButton() {
  return (
    <Page>
      <Space direction='vertical'>
        <Fieldset title='Basic' subtitle={basic.desc}>
          {basic.component}
        </Fieldset>
        <Fieldset title='Disabled' subtitle={disabled.desc}>
          {disabled.component}
        </Fieldset>
        <Fieldset title='Ghost' subtitle={ghost.desc}>
          {ghost.component}
        </Fieldset>
        <Fieldset title='Dropdown' subtitle={dropdown.desc}>
          {dropdown.component}
        </Fieldset>
      </Space>
    </Page>
  )
}
