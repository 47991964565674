import { useSelector } from 'react-redux'
import { getEntityHierarchyConfiguration } from 'src/redux/store/credentials'
import AccessManager from 'src/utils/AccessManager'
import { EntityHierarchyConfig } from 'src/typings/kenai/configuration/entity-hierarchy'

export const getIsMultiTenant = (entityHierarchyConfig: EntityHierarchyConfig, selectedHierarchy: string) => {
  if (selectedHierarchy === 'ALL') {
    return true
  } else {
    return entityHierarchyConfig?.hierarchy?.flatStructure?.[selectedHierarchy]?.children.length > 0
  }
}

export default function useIsMultiTenant(selectedHierarchy?: string) {
  const ehConfig = useSelector(getEntityHierarchyConfiguration)
  return getIsMultiTenant(ehConfig, selectedHierarchy || AccessManager.selectedHierarchy.hierarchyStructure)
}
