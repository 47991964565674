import { cloneDeep, groupBy } from 'lodash'
import { actionChannel, all, call, fork, put, select, take, takeLeading } from 'redux-saga/effects'
import { evacOperations, EvacUpdateStatus, EvacUpdateUserData } from 'src/api/evacOperations'
import { ActiveEvacuationEvent, ArchivedEvacuationEvent, EvacuationAudience } from 'src/containers/Messaging/Evacuation'
import { ActionCreators as ACAuthentication } from 'src/redux/sagas/authentication'
import { ActionCreators as ACCredentials, getEntityHierarchyConfiguration, getEvacuationConfig } from 'src/redux/store/credentials'
import {
  ActionCreators as EmployeeLogActionCreators,
  getEmployeeEventPairData,
  getEmployeeLog,
  State as EmployeeLogState,
} from 'src/redux/store/employees/employee-log-store'
import {
  ActionCreators as MessagingEvacActions,
  getEvacuationMessagingLog,
  State as MessagingEvacLogsState,
  getEvacuationMessagingResponseLog,
} from 'src/redux/store/messaging/evacuation'
import { ActionCreators as VisitorLogActionCreators, getVisitorsLogData } from 'src/redux/store/visitors/log'
import { VisitorLogItem } from 'src/redux/store/visitors/types/log'
import { EntityHierarchyConfig, EvacuationEntry } from 'src/typings/kenai/configuration/entity-hierarchy'
import AccessManager from 'src/utils/AccessManager'
import { PromiseValue } from 'type-fest'
import { generateEmployeeLogPairSearchString, updateEmployeeLogItem } from '../employees/helpers/employee-log'
import ActionCreator from '../saga-action-creator'

// Action Creators
type UpdateEvacuationStatusPayload = {
  userData: EvacUpdateUserData[]
  status: EvacUpdateStatus
  comment: string
}

export const ActionCreators = {
  SagaSetEvacuationState: new ActionCreator<
    'SagaSetEvacuationState',
    { activate: true; audience: EvacuationAudience } | { activate: false; audience?: undefined }
  >('SagaSetEvacuationState'),
  SagaUpdateEvacuationStatus: new ActionCreator<'SagaUpdateEvacuationStatus', UpdateEvacuationStatusPayload>('SagaUpdateEvacuationStatus'),
  SagaEvacuationLiveUpdateHandler: new ActionCreator<'SagaEvacuationLiveUpdateHandler', any>('SagaEvacuationLiveUpdateHandler'),
}

function* activateEvacuation(entityHierarchyConfiguration: EntityHierarchyConfig, audience: EvacuationAudience) {
  const evacuationConfig = entityHierarchyConfiguration.additionalConfigs.evacuation

  const hierarchiesToActivate: string[] = []
  const traverseStructRecursive = (structObject) => {
    hierarchiesToActivate.push(structObject.hierarchyStructure)
    structObject.children.forEach((childStructObject) => {
      traverseStructRecursive(childStructObject)
    })
  }
  if (AccessManager.selectedHierarchy.hierarchyStructure === 'ALL') {
    entityHierarchyConfiguration.hierarchy.deepStructure.forEach((structObject) => {
      traverseStructRecursive(structObject)
    })
  } else {
    traverseStructRecursive(entityHierarchyConfiguration.hierarchy.flatStructure[AccessManager.selectedHierarchy.hierarchyStructure])
  }

  if (!hierarchiesToActivate.length) {
    throw new Error('Hierarchy not found')
  }

  const evacuationUpdate: ActiveEvacuationEvent[] = yield evacOperations.activateEvacuation({
    audience,
    hierarchies: hierarchiesToActivate,
  })

  const updatedEvacuationConfig = cloneDeep(evacuationConfig)
  const messagingEvacuationLogs: MessagingEvacLogsState['data'] = yield select(getEvacuationMessagingLog)
  const updatedActiveEvacsMessagingLogs = cloneDeep(messagingEvacuationLogs.active)
  evacuationUpdate.forEach((updateObject) => {
    updatedEvacuationConfig[updateObject.EntityHierarchy] = updateObject

    // We want to ensure that the messaging log updates
    // this is in case the stream is blocked, so we also check
    // if the entry does not already exist, otherwise it would cause
    // duplicated with the event stream
    const hasMatchingEntry = updatedActiveEvacsMessagingLogs.some((entry) => {
      return entry.EntityHierarchy === updateObject.EntityHierarchy && entry.activatedTimestamp === updateObject.activatedTimestamp
    })

    if (!hasMatchingEntry) {
      updatedActiveEvacsMessagingLogs.push(updateObject)
    }
  })

  yield put(ACCredentials.StoreSetConfigEvacuation.create(updatedEvacuationConfig))

  yield put(
    MessagingEvacActions.StoreSetEvacuationLog.create({
      active: updatedActiveEvacsMessagingLogs,
      archive: messagingEvacuationLogs.archive,
    })
  )
}

function* deactivateEvacuation(entityHierarchyConfiguration: EntityHierarchyConfig) {
  const evacuationConfig = entityHierarchyConfiguration.additionalConfigs.evacuation
  const deActivationHierarchies: Record<string, EvacuationEntry> = {}
  const traverseStructRecursive = (structObject) => {
    if (evacuationConfig[structObject.hierarchyStructure]) {
      deActivationHierarchies[structObject.hierarchyStructure] = evacuationConfig[structObject.hierarchyStructure]
    }
    structObject.children.forEach((childStructObject) => {
      traverseStructRecursive(childStructObject)
    })
  }
  if (AccessManager.selectedHierarchy.hierarchyStructure === 'ALL') {
    entityHierarchyConfiguration.hierarchy.deepStructure.forEach((structObject) => {
      traverseStructRecursive(structObject)
    })
  } else {
    traverseStructRecursive(entityHierarchyConfiguration.hierarchy.flatStructure[AccessManager.selectedHierarchy.hierarchyStructure])
  }

  const hierarchies = Object.entries(deActivationHierarchies).map(([EntityHierarchy, { activatedTimestamp }]) => ({
    EntityHierarchy,
    activatedTimestamp,
  }))

  const evacuationUpdate: ArchivedEvacuationEvent[] = yield evacOperations.deactivateEvacuation({
    hierarchies,
  })

  const updatedEvacuationConfig = cloneDeep(evacuationConfig)
  const messagingEvacuationLogs: MessagingEvacLogsState['data'] = yield select(getEvacuationMessagingLog)
  let updatedActiveEvacsMessagingLogs = cloneDeep(messagingEvacuationLogs.active)
  const updatedArchivedEvacsMessagingLogs = cloneDeep(messagingEvacuationLogs.archive)
  let updatedResponseLog: MessagingEvacLogsState['responseLog'] = cloneDeep(yield select(getEvacuationMessagingResponseLog))
  evacuationUpdate.forEach((updatedEvacs) => {
    delete updatedEvacuationConfig[updatedEvacs.EntityHierarchy]

    // We want to ensure that the messaging log updates
    // this is in case the stream is blocked, so we also check
    // if the entry does not already exist, otherwise it would cause
    // duplicated with the event stream
    const hasMatchingArchiveEntry = updatedArchivedEvacsMessagingLogs.some((entry) => {
      return entry.EntityHierarchy === updatedEvacs.EntityHierarchy && entry.activatedTimestamp === updatedEvacs.activatedTimestamp
    })
    const hasMatchingActiveEntry = updatedActiveEvacsMessagingLogs.some((entry) => {
      return entry.EntityHierarchy === updatedEvacs.EntityHierarchy && entry.activatedTimestamp === updatedEvacs.activatedTimestamp
    })

    if (!hasMatchingArchiveEntry) {
      updatedArchivedEvacsMessagingLogs.push(updatedEvacs)
    }

    if (hasMatchingActiveEntry) {
      updatedActiveEvacsMessagingLogs = updatedActiveEvacsMessagingLogs.filter((entry) => {
        return entry.EntityHierarchy !== updatedEvacs.EntityHierarchy && entry.activatedTimestamp !== updatedEvacs.activatedTimestamp
      })
    }

    if (
      updatedEvacs.EntityHierarchy === updatedResponseLog.EntityHierarchy &&
      updatedEvacs.activatedTimestamp === updatedResponseLog.activatedTimestamp
    ) {
      updatedResponseLog = updatedEvacs
    }
  })

  yield put(ACCredentials.StoreSetConfigEvacuation.create(updatedEvacuationConfig))
  yield put(
    MessagingEvacActions.StoreSetEvacuationLog.create({
      active: updatedActiveEvacsMessagingLogs,
      archive: updatedArchivedEvacsMessagingLogs,
    })
  )
  yield put(MessagingEvacActions.StoreSetResponseLog.create(updatedResponseLog))
}

function* processActivateEvacuation(action: typeof ActionCreators['SagaSetEvacuationState']) {
  try {
    yield put(ACAuthentication.SagaRefreshApiCredentials.create())
    yield take(ACAuthentication.SagaApiCredentialsRefreshUpdate.type)
    const entityHierarchyConfiguration = yield select(getEntityHierarchyConfiguration)

    if (action.payload.activate) {
      yield activateEvacuation(entityHierarchyConfiguration, action.payload.audience)
    } else {
      yield deactivateEvacuation(entityHierarchyConfiguration)
    }

    if (action.callback) action.callback({ status: 'success' })
  } catch (e) {
    //add error handling
    console.log(e)
    if (action.callback) action.callback({ status: 'failed', message: e.message })
  }
}

function* processEvacuationLiveUpdateHandler(action) {
  try {
    // TODO refactor this to target ehconfig.additionalConfigs.evacuation instead of visitor log data
    const evacuationConfig = yield select(getEvacuationConfig)
    const updatedEvacuationConfig = cloneDeep(evacuationConfig)
    action.payload.forEach((event) => {
      if (event.eventType === 'REMOVE') {
        delete updatedEvacuationConfig?.[event.payload.EntityHierarchy]
      } else {
        const evacuationEvent = updatedEvacuationConfig?.[event.payload.EntityHierarchy]
        if (evacuationEvent && 'lmt' in evacuationEvent) {
          if (event.payload.lmt > evacuationEvent.lmt) {
            updatedEvacuationConfig[event.payload.EntityHierarchy] = {
              ...event.payload,
              activatedTimestamp: parseInt(event.payload.activatedTimestamp),
            }
          }
        } else {
          updatedEvacuationConfig[event.payload.EntityHierarchy] = {
            ...event.payload,
            activatedTimestamp: parseInt(event.payload.activatedTimestamp),
          }
        }
      }
    })

    yield put(ACCredentials.StoreSetConfigEvacuation.create(updatedEvacuationConfig))
  } catch (e) {
    console.log(e)
  }
}

function* updateEvacuationStatus(action: typeof ActionCreators['SagaUpdateEvacuationStatus']) {
  try {
    const result: PromiseValue<ReturnType<typeof evacOperations.updateEvacStatus>> = yield evacOperations.updateEvacStatus(
      action.payload.userData,
      action.payload.status,
      action.payload.comment
    )
    if (result.key !== 'EVAC_UPDATED') {
      throw new Error('Evacuation update failed')
    }

    const { VISITOR = [], EMPLOYEE = [], PARKING = [] } = groupBy(action.payload.userData, 'type')

    if (VISITOR.length) {
      const _visitorLogItems: VisitorLogItem[] = yield select(getVisitorsLogData)
      const visitorLogItems = cloneDeep(_visitorLogItems)

      const updatedLogs = visitorLogItems.map((record) => {
        const actionData = action.payload.userData.find(
          (data) => data.id === record.evtTimeStampProfileID && data.EntityHierarchy === record.EntityHierarchy
        )

        if (actionData) {
          if (!record.currentEvacResponse) {
            // @ts-ignore
            record.currentEvacResponse = {}
          }
          record.currentEvacResponse.responseTimestamp = result.evtTimeStampOut
          record.currentEvacResponse.responseChannel = 'dashboard'
          record.currentEvacResponse.status = action.payload.status
          record.currentEvacResponse.comment = action.payload.comment
        }

        return record
      })

      yield put(VisitorLogActionCreators.StoreSetVisitorLogData.create(updatedLogs))
    }

    if (EMPLOYEE.length) {
      const state: EmployeeLogState = yield select(getEmployeeLog)
      const employeeLogItems = cloneDeep(state.data)

      employeeLogItems.map((record) => {
        const actionData = action.payload.userData.find(
          (data) => data.id === record.evtTimeStampProfileID && data.EntityHierarchy === record.EntityHierarchy
        )

        if (actionData) {
          if (!record.currentEvacResponse) {
            // @ts-ignore
            record.currentEvacResponse = {}
          }
          record.currentEvacResponse.responseTimestamp = result.evtTimeStampOut
          record.currentEvacResponse.responseChannel = 'dashboard'
          record.currentEvacResponse.status = action.payload.status
          record.currentEvacResponse.comment = action.payload.comment
        }

        return record
      })

      const oldEventPairs: ReturnType<typeof getEmployeeEventPairData> = yield select(getEmployeeEventPairData)
      const entityHierarchyConfiguration = yield select(getEntityHierarchyConfiguration)
      const updatedEventPairs = generateEmployeeLogPairSearchString(
        updateEmployeeLogItem(oldEventPairs, employeeLogItems),
        entityHierarchyConfiguration
      )
      yield put(EmployeeLogActionCreators.StoreSetEmployeeLogEventPairData.create({ data: updatedEventPairs }))
      yield put(EmployeeLogActionCreators.StoreSetEmployeeLogData.create(employeeLogItems))
    }

    if (PARKING.length) {
      // TODO implement log update
    }

    if (action.callback) action.callback({ status: 'success' })
  } catch (error) {
    console.log(error)
    if (action.callback) action.callback({ status: 'failed' })
  }
}

// Saga triggers
function* watchEvacuationSagas() {
  yield takeLeading(ActionCreators.SagaSetEvacuationState.type, processActivateEvacuation)
  yield takeLeading(ActionCreators.SagaUpdateEvacuationStatus.type, updateEvacuationStatus)
  yield null
}

function* watchEvacuationLiveUpdateChannel() {
  const SagaEvacuationLiveUpdateChannel = yield actionChannel(ActionCreators.SagaEvacuationLiveUpdateHandler.type)
  while (true) {
    const action = yield take(SagaEvacuationLiveUpdateChannel)
    yield call(processEvacuationLiveUpdateHandler, action)
  }
}

// Saga hooks
export default function* evacuationSagas() {
  yield all([fork(watchEvacuationSagas), fork(watchEvacuationLiveUpdateChannel)])
}
