import { getTableNameFromAwsExports, queryDDBWithBatchLimits } from '../utils'
import { Key } from 'aws-sdk/clients/dynamodb'

export async function retrieveConfigI18nTexts(requestedHierarchy: string) {
  const params = (batchHierarchy: string, ExclusiveStartKey?: Key) => ({
    ExpressionAttributeValues: {
      ':hk': batchHierarchy,
    },
    ConsistentRead: true,
    KeyConditionExpression: 'EntityHierarchy = :hk',
    ProjectionExpression: 'textId, texts',
    TableName: getTableNameFromAwsExports('mdConfigI18NTexts'),
    ExclusiveStartKey,
  })

  return await queryDDBWithBatchLimits<ConfigValueI18NText>(requestedHierarchy, params)
}
