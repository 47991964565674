import { kenaiBrand } from 'src/styles'
import { useTheme } from 'src/components/theme/ThemeProvider'
import config from 'src/config'

const useBrand = () => {
  const { mode, brand } = useTheme()
  if (brand.enabled) return brand
  return {
    name: 'Kenai',
    primaryColor: config.primaryColor,
    logo: kenaiBrand.logo[mode],
    logoSmall: kenaiBrand.logoSmall[mode],
  } as typeof brand
}

export default useBrand
