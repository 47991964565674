import sidebarReducer, { State as SidebarState, ActionCreators as SidebarActionCreators } from './sidebar'
import brandingReducer, { State as BrandingState, ActionCreators as BrandingActionCreators } from './branding'
import globaleventsReducer, { State as GlobaleventsState, ActionCreators as GlobaleventsActionCreators } from './globalevents'
import { combineReducers } from 'redux'

export const ActionCreators = {
  sidebar: SidebarActionCreators,
  branding: BrandingActionCreators,
  globalevents: GlobaleventsActionCreators,
}
export interface State {
  sidebar: SidebarState
  branding: BrandingState
  globalevents: GlobaleventsState
}

export default combineReducers<State>({ sidebar: sidebarReducer, branding: brandingReducer, globalevents: globaleventsReducer })
