import React, { ReactNode } from 'react'
import withDefaults from 'src/utils/with-defaults'

interface Props {
  className?: string
  extra?: ReactNode
}

const defaultProps = {
  className: '',
}

type NativeAttrs = Omit<React.HTMLAttributes<HTMLHeadingElement>, keyof Props>
export type FieldsetTitleProps = Props & typeof defaultProps & NativeAttrs

const FieldsetTitle: React.FC<FieldsetTitleProps> = ({ className, children, extra, ...props }) => {
  return (
    <>
      <div className='flex items-center justify-between'>
        <h4 className={className} {...props}>
          {children}
        </h4>
        {extra}
      </div>
      <style jsx>{`
        h4 {
          font-size: 1.25rem;
          line-height: 1.5;
          margin: 0;
          display: inline-flex;
          word-break: break-word;
        }
      `}</style>
    </>
  )
}

const MemoFieldsetTitle = React.memo(FieldsetTitle)

export default withDefaults(MemoFieldsetTitle, defaultProps)
