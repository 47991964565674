import React from 'react'
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter'
import themeDark from 'react-syntax-highlighter/dist/esm/styles/prism/vs-dark'
import themeLight from 'react-syntax-highlighter/dist/esm/styles/prism/ghcolors'
import { useTheme } from './theme/ThemeProvider'
import ReactMarkdown, { ReactMarkdownProps } from 'react-markdown'
import { cssvars } from 'src/styles'
import styled from 'styled-components'
import 'github-markdown-css'

interface CodeBlockProps {
  value: string
  language?: string
}

export const CodeBlock: React.FC<CodeBlockProps> = React.memo(({ value, language }) => {
  const { mode } = useTheme()
  const theme = mode === 'dark' ? themeDark : themeLight

  return (
    <SyntaxHighlighter language={language} style={theme}>
      {value}
    </SyntaxHighlighter>
  )
})

const MarkdownBody = styled(ReactMarkdown)`
  && {
    color: ${cssvars.textColor};

    .highlight pre,
    pre {
      background-color: ${cssvars.bodyBackground}!important;
      * {
        text-shadow: none !important;
      }
    }
    table tr {
      background-color: ${cssvars.bodyBackground}!important;
    }
    table th {
      background-color: ${cssvars.contrastBackground}!important;
      font-weight: 600;
    }
    table td,
    table th {
      border-color: ${cssvars.componentBackground}!important;
    }
  }
`

interface MarkdownProps extends ReactMarkdownProps {
  markdown?: string
  className?: string
}
const Markdown: React.FC<MarkdownProps> = ({ markdown, className, ...other }) => (
  <MarkdownBody
    source={markdown}
    escapeHtml={false}
    className={`markdown-body ${className ? className : ''}`}
    renderers={{ code: CodeBlock }}
    {...other}
  />
)

export default Markdown
